import React, {useState} from 'react';

import {Paper, Button} from '@material-ui/core';

import {FacilityUpcomingVisitsTable} from 'ccm/components/VisitsTable';
import useStyles from './styles';
import FacilityVisitsCalendar from './FacilityVisitsCalendar';

function ViewSwitch({value, onChange}) {
    const classes = useStyles();
    const curView = value === 'table' ? 'visits' : 'providers';
    const newView = value === 'table' ? 'providers' : 'visits';
    const newValue = value === 'table' ? 'cal' : 'table';
    return (
        <div className={classes.scheduleSwitch}>
            <h6>Viewing {curView}</h6>
            <Button variant='outlined' onClick={() => onChange(newValue)}>
                Switch to {newView}
            </Button>
        </div>
    );
}

export default function FacilitySchedule({facility}) {
    const classes = useStyles();
    const [view, setView] = useState('table');
    return (
        <Paper className={classes.scheduleView}>
            <ViewSwitch value={view} onChange={setView} />
            {view === 'table' ? (
                <FacilityUpcomingVisitsTable title='' facility={facility} />
            ) : (
                <FacilityVisitsCalendar facility={facility} />
            )}
        </Paper>
    );
}
