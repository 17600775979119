import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {
    DialogContent,
    DialogActions,
    Button,
    Card,
    CardHeader,
    CardContent,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Print} from '@material-ui/icons';
import {ducks} from '@arborian/narrf';

import {Formik, Form, Field} from 'formik';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';

import {
    selectEHRConnections,
    selectTicketFaxOptions,
} from 'ccm/lib/selectors-ccm';
import {FaxOptionsFormikField} from 'ccm/components/fields/FaxOptions';
import ProgressNoteUploadFields from 'ccm/components/ProgressNoteUploadFields';
import * as h from 'ccm/lib/helpers';
import useStyles from './styles';

export default function ResolveStep({ticket, actions, onPrevious, onCancel}) {
    const classes = useStyles();
    const ehrConnections = useSelector(selectEHRConnections(ticket));
    const faxOptions = useSelector(selectTicketFaxOptions(ticket));
    const rel = useSelector(ducks.jsonapi.selectRelated(ticket));
    const hasStatEmailAddress = !fp.isEmpty(
        fp.get('practice.attributes.stat_scheduling_email.email', rel),
    );
    const facilityFaxAllowed = fp.get('facility.attributes.allow_fax', rel);
    const hasSignatureRequest = !!fp.get(
        'relationships.signature_form.data',
        ticket,
    );
    const initialValues = useMemo(() => {
        let progressNoteEHRs = {};
        let documentUploadEHRs = {};
        const uploadToPatient = fp.includes(
            // Ticket types that should upload to patient files
            fp.get('attributes.data.ticket_type', ticket),
            ['LABRESULTS', 'RADRESULT'],
        );

        for (let ehr of ehrConnections) {
            progressNoteEHRs[ehr.id] = documentUploadEHRs[ehr.id] = false;
            if (hasSignatureRequest) {
                if (ehr.attributes.canUploadTicketPdf) {
                    documentUploadEHRs[ehr.id] = true;
                } else if (ehr.attributes.canUploadProgress) {
                    progressNoteEHRs[ehr.id] = true;
                }
            } else {
                if (ehr.attributes.canUploadProgress) {
                    progressNoteEHRs[ehr.id] = true;
                } else if (ehr.attributes.canUploadTicketPdf) {
                    documentUploadEHRs[ehr.id] = true;
                }
            }
        }
        console.log({
            progressNoteEHRs,
            documentUploadEHRs,
            ehrConnections,
        });
        return {
            stat: false,
            orders: fp.get('attributes.data.orders', ticket),
            fax_number: fp.get('attributes.data.fax_number', ticket),
            sendFax: facilityFaxAllowed,
            upload: {
                progressNoteEHRs,
                documentUploadEHRs,
                patientFilesUpload: uploadToPatient ? true : false,
            },
        };
    }, [ticket, ehrConnections, facilityFaxAllowed, hasSignatureRequest]);

    const handleSubmit = async values => {
        const progressNoteEHRs = fp.pipe([
            fp.get('upload.progressNoteEHRs'),
            fp.toPairs,
            fp.filter(([k, v]) => v),
            fp.map(([k, v]) => k),
        ])(values);
        const documentUploadEHRs = fp.pipe([
            fp.get('upload.documentUploadEHRs'),
            fp.toPairs,
            fp.filter(([k, v]) => v),
            fp.map(([k, v]) => k),
        ])(values);
        const resolution = {
            ...values,
            upload: {
                progressNoteEHRs,
                documentUploadEHRs,
                patientFilesUpload: values.upload.patientFilesUpload,
            },
        };
        await actions.resolve(ticket, resolution);
        await actions.refresh(ticket);
        onCancel(); // Close dialog
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form className={classes.ResolveStep}>
                    <DialogContent dividers>
                        <Alert severity='info' icon={false}>
                            <p>
                                <b>Patient: </b>
                                {fp.get(
                                    'attributes.name.formatted',
                                    rel.patient,
                                )}
                            </p>
                            <p>
                                <b>DOB: </b>
                                {h.formatDateOnly(
                                    fp.get('attributes.dob', rel.patient),
                                )}
                            </p>
                            <p>
                                <b>Room: </b>
                                {fp.get('attributes.room', rel.patient)}
                            </p>
                            <p>
                                <b>Facility: </b>
                                {fp.get('facility.attributes.label', rel)}
                            </p>
                        </Alert>
                        <Card>
                            <CardHeader title='Ticket Orders' />
                            <CardContent>
                                <Field
                                    name='orders'
                                    component={TextField}
                                    multiline
                                    fullWidth
                                    label='Orders'
                                />
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title='Fax' />
                            <CardContent>
                                {!facilityFaxAllowed && (
                                    <Alert severity='warning'>
                                        This facility requests no faxing.
                                    </Alert>
                                )}
                                <Field
                                    id='static_fax_checkbox'
                                    component={CheckboxWithLabel}
                                    type='checkbox'
                                    size='small'
                                    name='sendFax'
                                    Label={{
                                        label: 'Send Fax',
                                    }}
                                />
                                <FaxOptionsFormikField
                                    name='fax_number'
                                    label='Fax number(s)'
                                    options={faxOptions}
                                    multiple
                                />
                            </CardContent>
                        </Card>
                        <ProgressNoteUploadFields
                            ehrConnections={ehrConnections}
                            ticket={ticket}
                        />
                        {hasStatEmailAddress && (
                            <Alert severity='info' icon={false}>
                                <strong>STAT / Priority</strong>
                                <br />
                                <Field
                                    id='static_stat_checkbox'
                                    component={CheckboxWithLabel}
                                    type='checkbox'
                                    size='small'
                                    name='stat'
                                    Label={{
                                        label: 'Mark ticket STAT: send to scheduling immediately',
                                    }}
                                />
                            </Alert>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={onCancel}>Close</Button>
                        <Button variant='contained' onClick={onPrevious}>
                            Back
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() =>
                                formikProps.handleSubmit(formikProps)
                            }
                            startIcon={<Print />}
                            name='submit'
                            value='button1'
                        >
                            Resolve Ticket
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    );
}
