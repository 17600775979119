import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

import {components} from '@arborian/narrf';
import * as h from 'ccm/lib/helpers';
import {useAllPatients} from 'ccm/dataSource';
import TelecomChips from 'ccm/components/TelecomChips';
import PatientTable from 'ccm/components/PatientTable';
import PatientEHRComponent from '../PatientEHRComponent';

const {dt2} = components;

const patientOptions = {include: ['ehr_connection']};

export default function PatientSelectDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const ds = useAllPatients({
        prefix: null,
        options: patientOptions,
    });

    const handleSelect = (ev, row) => {
        if (row) {
            resolve({action: 'select', value: row.data});
        } else {
            resolve({action: 'select', value: null});
        }
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    dialogRef.current = initialValues => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    function PatientActiveColumnComponent({row}) {
        return fp.get('data.attributes.active', row) ? 'Active' : 'Inactive';
    }

    function PatientEhrColumnComponent({row}) {
        return <PatientEHRComponent row={row} />;
    }

    return (
        <Dialog open={!!resolve} onClose={handleCancel} maxWidth='lg' fullWidth>
            <DialogTitle>Patient Selection</DialogTitle>
            <DialogContent>
                <PatientTable dataSource={ds}>
                    <dt2.Column
                        title='DOB'
                        field='attributes.dob'
                        type='date'
                        render={row =>
                            h.formatDateOnly(fp.get('data.attributes.dob', row))
                        }
                    />
                    <dt2.Column
                        id='active'
                        title='Status'
                        filtering={false}
                        sorting={false}
                        editable={false}
                        field='attributes.active'
                        component={PatientActiveColumnComponent}
                    />
                    <dt2.Column
                        id='ehr'
                        title='EHR'
                        filtering={false}
                        sorting={false}
                        editable={false}
                        component={PatientEhrColumnComponent}
                    />
                    <dt2.Column
                        title='Telecom'
                        field='attributes.telecom'
                        filtering={false}
                        component={TelecomChips}
                    />
                    <dt2.Action
                        name='select'
                        onClick={handleSelect}
                        tooltip='Select this patient'
                        icon='check'
                    />
                    <dt2.Action
                        free // free actions dont' send a row
                        name='deselect'
                        onClick={handleSelect}
                        tooltip='De-select any patient'
                        icon='cancel'
                    />
                </PatientTable>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
