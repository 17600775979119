import React, {createContext, useContext, useMemo} from 'react';

export const Context = createContext();
export const useDataSourceContext = () => useContext(Context);

export default function Provider({dataSource, children, valueRef, ...props}) {
    const value = useMemo(
        () => ({...dataSource, ...props}),
        [dataSource, props],
    );
    if (valueRef) valueRef.current = value;
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
