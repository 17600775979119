import React, {useCallback} from 'react';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {
    RadioGroup as MUIRadioGroup,
    Radio,
    FormControl,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    makeStyles,
    Grid,
} from '@material-ui/core';

import {fieldId} from 'ccm/lib/util';

const useStyles = makeStyles(theme => ({
    img: {
        maxWidth: '100%',
    },
    centered: {
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function RadioRow({
    field,
    form, // From Formik
    label,
    caption,
    options,
    required,
    row,
    image,
}) {
    const classes = useStyles();
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);

    let img = null;
    if (image) {
        try {
            const images = require.context('../../images', true);
            img = images('./' + image).default;
        } catch {}
    }

    return (
        <FormControl
            component='fieldset'
            className={classes.centered}
            error={!!(touched && errors)}
            required={required}
        >
            <FormLabel>{label}</FormLabel>
            <img className={classes.img} alt='' src={img} />
            {touched && errors && <FormHelperText>{errors}</FormHelperText>}
            <MUIRadioGroup
                id={`dyn-${field.name}`}
                className={classes.centered}
                row={row}
                value={field.value || null}
                onChange={useCallback(
                    (ev, newValue) => {
                        form.setFieldValue(field.name, newValue);
                    },
                    [form, field],
                )}
            >
                {fp.map(
                    option => (
                        <Grid item xs={2} md={1}>
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={
                                    <Radio
                                        id={fieldId(
                                            'dyn',
                                            field.name,
                                            option.value,
                                        )}
                                    />
                                }
                                label={option.label}
                                labelPlacement={row ? 'bottom' : 'end'}
                            />
                        </Grid>
                    ),
                    options,
                )}
            </MUIRadioGroup>
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </FormControl>
    );
}

const DynaformField = ({field}) => {
    return (
        <Field
            component={RadioRow}
            name={field.id}
            label={field.label}
            caption={field.caption}
            required={field.required}
            row={true}
            options={field.options}
            image={field.image}
        />
    );
};
export default DynaformField;
