import React from 'react';

import {
    Container,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    TextField,
} from '@material-ui/core';
import {Formik, Form, useFormikContext} from 'formik';

import PortalConfig from './PortalConfig';

function AlisCredentialsForm({config}) {
    const formik = useFormikContext();

    return (
        <Accordion>
            <AccordionSummary>Alis Credentials</AccordionSummary>
            <AccordionDetails>
                <Container>
                    <h6>ALIS API Credentials</h6>
                    <TextField
                        fullWidth
                        id='alis_api_url'
                        name='attributes.integration_config.api_endpoint'
                        label='ALIS API Endpoint'
                        value={
                            formik.values?.attributes.integration_config
                                .api_endpoint
                        }
                        onChange={formik.handleChange}
                        focused
                    />
                    <TextField
                        fullWidth
                        id='alis_api_username'
                        name='attributes.integration_config.username'
                        label='Username'
                        value={
                            formik.values?.attributes.integration_config
                                .username
                        }
                        onChange={formik.handleChange}
                        focused
                    />
                    <TextField
                        fullWidth
                        id='alis_api_password'
                        name='attributes.integration_config.password'
                        type='password'
                        label='Password'
                        value={
                            formik.values?.attributes.integration_config
                                .password
                        }
                        onChange={formik.handleChange}
                        focused
                    />
                </Container>
            </AccordionDetails>
        </Accordion>
    );
}

export default function AlisConfig({config, onSubmit}) {
    return (
        <Formik enableReinitialize initialValues={config} onSubmit={onSubmit}>
            {({touched, values, errors}) => (
                <Form>
                    <h6>Alis Configuration</h6>
                    <PortalConfig config={config} />
                    <AlisCredentialsForm config={config} />
                    <div>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            Save Configuration
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
