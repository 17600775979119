import {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {useApi} from '@arborian/narrf';

const defaultOptions = {};

export default function useWebsocket(path, options = defaultOptions) {
    const api = useApi();
    const dispatch = useDispatch();
    const server = api.api_root.split('://', 2)[1];
    const isAuthorized = api.isAuthorized();
    const [socket, setSocket] = useState(null);
    const websocketUrl = 'wss://' + server + path;
    const onOpen = options.onOpen;

    const openSocket = useCallback(() => {
        console.log('Opening websocket', {
            api,
            isAuthorized,
            websocketUrl,
            dispatch,
            options,
        });
        const newSocket = new WebSocket(websocketUrl);
        newSocket.addEventListener('open', ev => {
            if (isAuthorized) {
                const auth = {accessToken: api._accessToken, ...options};
                console.log('About to auth', {ev, api, isAuthorized, auth});
                newSocket.send(JSON.stringify(auth));
            } else {
                newSocket.send(JSON.stringify({accessToken: null, ...options}));
            }
            if (onOpen) onOpen(newSocket);
        });
        newSocket.addEventListener('message', ev => {
            const data = JSON.parse(ev.data);
            dispatch(data);
        });
        newSocket.addEventListener('error', ev => {
            if (!socket) return;
            console.log('Websocket Error. Will retry in 1 second...');
            setTimeout(() => {
                openSocket();
            }, 1000);
        });
        newSocket.addEventListener('close', ev => {
            if (!socket) return;
            console.log('Websocket Closed. Will reopen in 1 second...');
            setTimeout(() => {
                openSocket();
            }, 1000);
        });
        setSocket(newSocket);
        return newSocket;
    }, [api, socket, isAuthorized, websocketUrl, dispatch, onOpen, options]);

    useEffect(() => {
        const socket = openSocket();
        return () => {
            if (socket.readyState === WebSocket.OPEN) {
                console.log('Closing websocket');
                socket.close();
            }
            setSocket(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);
    return socket;
}
