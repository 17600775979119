import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TextField,
    MenuItem,
    makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';

import {components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';
import {usePatients, usePractices, useFacilities} from 'ccm/dataSource';
import PatientTable from 'ccm/components/PatientTable';
import PracticeTable from 'ccm/components/PracticeTable';
import FacilityTable from 'ccm/components/FacilityTable';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        '& .MuiDialogTitle-root .MuiIconButton-root': {
            position: 'absolute',
            right: theme.spacing(0.5),
            top: theme.spacing(0.5),
            color: theme.palette.grey[500],
        },
        '& .MuiTextField-root': {
            minWidth: theme.spacing(20),
        },
    },
}));

const AVAILABLE_ROLES = {
    practice: [
        {id: 'staff', label: 'Staff'},
        {id: 'provider', label: 'Provider'},
        {id: 'admin', label: 'Admin'},
        {id: 'noteprep', label: 'Note Preparer'},
    ],
    facility: [
        {id: 'staff', label: 'Staff'},
        {id: 'provider', label: 'Provider'},
        {id: 'admin', label: 'Admin'},
    ],
    patient: [
        {id: 'member', label: 'Member'},
        {id: 'admin', label: 'Admin'},
    ],
};

export default function GroupsDialog({dialogRef}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const [type, setType] = useState(null);
    const [role, setRole] = useState(null);
    const [roles, setRoles] = useState(null);
    const dsPatient = usePatients({
        prefix: null,
        initialFetchOptions: {
            sort: {field: 'attributes.name.last'},
            page: {number: 0, size: 5},
        },
    });
    const dsFacility = useFacilities({
        prefix: null,
        initialFetchOptions: {
            sort: {field: 'attributes.name'},
            page: {number: 0, size: 5},
        },
    });
    const dsPractice = usePractices({
        prefix: null,
        initialFetchOptions: {
            sort: {field: 'attributes.name'},
            page: {number: 0, size: 5},
        },
    });

    const handleCancel = () => {
        resolve(null);
        handleClose();
    };

    const handleClose = () => {
        setType(null);
        setRole(null);
        setResolve(null);
    };

    const handleSelectType = ev => {
        const newType = ev.target.value;
        const newRoles = fp.getOr([], ev.target.value, AVAILABLE_ROLES);
        const newRole = newRoles[0].id;
        setType(newType);
        setRoles(newRoles);
        setRole(newRole);
    };

    const handleSelectRole = ev => {
        setRole(ev.target.value);
    };

    const handleSelectValue = value => {
        const id = value.id;
        const groupId = `${type}:${id}/${role}`;
        resolve({id: groupId, type: 'Group'});
        console.log('Resolved', groupId);
        handleClose();
    };

    dialogRef.current = () => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog
            open={!!resolve}
            onClose={handleCancel}
            maxWidth='lg'
            fullWidth
            className={classes.dialogRoot}
        >
            <DialogTitle>
                Choose Group
                <IconButton onClick={handleCancel}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label='Group Type'
                    select
                    value={type}
                    onChange={handleSelectType}
                >
                    <MenuItem value='practice'>Practice</MenuItem>
                    <MenuItem value='facility'>Facility</MenuItem>
                    <MenuItem value='patient'>Patient</MenuItem>
                </TextField>
                <TextField
                    label='Role'
                    select
                    value={role}
                    InputLabelProps={{shrink: true}}
                    onChange={handleSelectRole}
                >
                    {fp.map(
                        r => (
                            <MenuItem key={r.id} value={r.id}>
                                {r.label}
                            </MenuItem>
                        ),
                        roles,
                    )}
                </TextField>
            </DialogContent>
            {type === 'practice' && (
                <PracticeTable dataSource={dsPractice}>
                    <dt2.Action
                        name='select'
                        onClick={(ev, row) => handleSelectValue(row.data)}
                        tooltip='Select this practice'
                        icon='check'
                    />
                </PracticeTable>
            )}
            {type === 'facility' && (
                <FacilityTable dataSource={dsFacility}>
                    <dt2.Action
                        name='select'
                        onClick={(ev, row) => handleSelectValue(row.data)}
                        tooltip='Select this facility'
                        icon='check'
                    />
                </FacilityTable>
            )}
            {type === 'patient' && (
                <PatientTable dataSource={dsPatient}>
                    <dt2.Column
                        id='dob'
                        title='DOB'
                        type='date'
                        field='attributes.dob'
                        filterProps={{timeZone: 'UTC'}}
                        render={row =>
                            h.formatDateOnly(fp.get('data.attributes.dob', row))
                        }
                    />
                    <dt2.Action
                        name='select'
                        onClick={(ev, row) => handleSelectValue(row.data)}
                        tooltip='Select this patient'
                        icon='check'
                    />
                </PatientTable>
            )}
        </Dialog>
    );
}
