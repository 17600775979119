import Tour from './tour_base';

const TourUserProfile = new Tour({
    id: 'userProfile',
    label: 'User Profile',
    location_context: '/profile',
    group_context: /.*/,
    steps: [
        {
            content: () => (
                <>
                    <p>
                        If you access SamePageMD using a device
                        that supports facial and/or fingerprint
                        recognition, you have the option to add
                        that to your login credentials. You can
                        then use it - or other credentials
                        linked to your SamePageMD account - as
                        a quick and easy way to log back into a
                        locked session. {' '}
                        <a
                            href='https://samepagemd.zendesk.com/hc/en-us/articles/13645417934227'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Click here to learn more!
                        </a>
                    </p>
                </>
            ),
        },
        {
            content: () => (
                <>
                    <p>
                        For the purposes of electronically
                        signing documents, you may choose to
                        upload, edit, and save an image of
                        your hand-written signature. {' '}
                        <a
                            href='https://samepagemd.zendesk.com/hc/en-us/articles/13441375939347'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Click here to learn more!
                        </a>
                    </p>
                </>
            ),
        },
    ],
});
export default TourUserProfile;