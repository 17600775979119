import {
    get,
    create,
    parseCreationOptionsFromJSON,
    parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill';

export async function register(api, provider, userinfo) {
    const regopt_url = api.url_for('webauthn.generate_registration_options', {
        provider_id: provider.id,
    });
    /*
    const reg_url = api.url_for('webauthn.create_registration', {
        provider_id: provider.id,
    });
    */
    const regopt_resp = await api.fetchJson(regopt_url, {
        method: 'POST',
        json: {
            data: {
                type: 'WebAuthNRegistrationOptionsRequest',
                attributes: {
                    user_name: userinfo.email,
                    user_display_name: userinfo.name,
                },
            },
        },
    });
    const options = parseCreationOptionsFromJSON({
        publicKey: regopt_resp,
    });
    const cred = await create(options);
    const credJson = JSON.stringify(cred);
    return credJson;
    /*
    const reg_resp = await api.fetchJson(reg_url, {
        method: 'POST',
        body: credJson,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return reg_resp;
    */
}

export async function authenticate(api, credentialId) {
    const authopt_url = api.url_for('webauthn.generate_authenticate_options');
    const authopt_resp = await api.fetchJson(authopt_url, {
        method: 'POST',
        json: {
            data: {
                type: 'WebAuthNAuthenticateOptionsRequest',
                attributes: {
                    client_id: api.client_id,
                    credential_id: credentialId,
                },
            },
        },
    });
    const options = parseRequestOptionsFromJSON({
        publicKey: authopt_resp,
    });
    const cred = await get(options);
    const credJson = JSON.stringify(cred);
    const token = await api.login({
        grant_type: 'webauthn',
        client_id: api.client_id,
        credJson,
    });
    return token;
}
