import React, {useState} from 'react';

import {Tab, Tabs} from '@material-ui/core';

import {ProviderUpcomingVisitsTable} from 'ccm/components/VisitsTable';
import ProviderVisitsCalendar from './ProviderVisitsCalendar';

export default function ProviderSchedule({provider}) {
    const [tab, setTab] = useState('cal');
    const handleChange = (ev, newValue) => {
        setTab(newValue);
    };
    return (
        <div>
            <Tabs value={tab} onChange={handleChange}>
                <Tab label='Facilities' value='cal' />
                <Tab label='Visits' value='table' />
            </Tabs>
            {tab === 'cal' && <ProviderVisitsCalendar provider={provider} />}
            {tab === 'table' && (
                <ProviderUpcomingVisitsTable provider={provider} />
            )}
        </div>
    );
}
