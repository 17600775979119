import React from 'react';

import {Tooltip, IconButton} from '@material-ui/core';
import {PostAdd} from '@material-ui/icons';

import {useActions} from 'ccm/lib/hooks';

export default function CreateTicketIconButton({patient}) {
    const actions = useActions();
    const handleClick = async ev => {
        actions.createTicket(patient);
    };
    return (
        <Tooltip title='Create ticket'>
            <IconButton onClick={handleClick}>
                <PostAdd />
            </IconButton>
        </Tooltip>
    );
}
