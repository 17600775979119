import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

const DEFAULT_OPTIONS = {
    title: 'Add Email',
    submitLabel: 'Send verification',
    initialValues: {
        email: '',
    },
};

export default function AddEmailDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    if (dialogRef) {
        dialogRef.current = (protectedResource, options = {}) => {
            return new Promise((resolve, reject) => {
                setOptions(fp.merge(DEFAULT_OPTIONS, options));
                setResolve(() => resolve);
            });
        };
    }

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={options.initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <p>
                                To add an email address to your account, enter
                                it below. Then click on the link in the email we
                                send you to verify your ownership of the
                                account.
                            </p>
                            <Field
                                component={TextField}
                                label='Email Address'
                                name='email'
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
