import React from 'react';
import {TourProvider as BaseTourProvider, components} from '@reactour/tour';
import fp from 'lodash/fp';

export {default as TourMenu} from './TourMenu';

function Content(props) {
    const {content, ...otherProps} = props;
    return fp.isString(content) ? (
        <components.Content content={content} {...otherProps} />
    ) : (
        content(props)
    );
}

const customComponents = {
    Content,
};

export function TourProvider(props) {
    return <BaseTourProvider components={customComponents} {...props} />;
}
