import React from 'react';
import fp from 'lodash/fp';

import {Grid, Paper, makeStyles} from '@material-ui/core';
import {Field} from 'formik';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';

const useStyles = makeStyles(theme => ({
    uploadCell: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

export default function UploadConfig({name, documentTypes, providers}) {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <Paper className={classes.uploadCell}>
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Document Type'
                        name={`${name}.documentType`}
                        options={documentTypes}
                        getOptionLabel={fp.get(
                            'attributes.data.documentTypeName',
                        )}
                    />
                </Paper>
            </Grid>
            <Grid item xs>
                <Paper className={classes.uploadCell}>
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Provider'
                        name={`${name}.provider`}
                        options={providers}
                        getOptionLabel={fp.get('attributes.data.providerName')}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}
