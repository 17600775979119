import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {
    Tab,
    Tabs,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    makeStyles,
    MenuItem,
    TextField as MUITextField,
} from '@material-ui/core';
import {Help} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import fp from 'lodash/fp';

import {useApi, ducks} from '@arborian/narrf';

import TelecomField from 'ccm/components/TelecomField';
import SecurityPolicyFields from 'ccm/components/SecurityPolicyFields';
import {selectPracticePairs} from 'ccm/lib/selectors-ccm';
import {selectAllUsers} from 'ccm/lib/selectors';
import {Autocomplete} from 'formik-material-ui-lab';

const useStyles = makeStyles(theme => ({
    cityField: {
        maxWidth: theme.spacing(40),
    },
    stateField: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
    postalCodeField: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
    primaryPracticeField: {
        width: theme.spacing(40),
    },
}));

const defaultOptions = {
    title: 'Facility',
    submitLabel: 'Save',
};

function CoreSettingsFields({facility}) {
    const classes = useStyles();
    const api = useApi();
    const ehr_connection_id = fp.get(
        'relationships.ehr_connection.data.id',
        facility,
    );
    const ehr = useSelector(
        ducks.jsonapi.selectObject(['EHRConnection', ehr_connection_id]),
    );
    const readOnly =
        !!ehr_connection_id &&
        fp.getOr(true, 'attributes.provides_organization_info', ehr);
    const practiceOptions = useSelector(selectPracticePairs);

    const facGroupsRegex = fp.get('meta.group_regexp', facility);

    useEffect(() => {
        // Fetch all users with any permissions for this facility
        api.fetchJsonApi(api.url_for('user.UserCollection'), {
            filter: {
                group_ids: JSON.stringify({
                    $regex: facGroupsRegex,
                }),
            },
        });
    }, [api, facGroupsRegex]);

    const allUsers = useSelector(selectAllUsers);
    const users = fp.filter(u => {
        const groupIds = fp.map('id', fp.get('relationships.groups.data', u));
        return fp.some(gid => gid.match(facGroupsRegex), groupIds);
    }, allUsers);
    const facUserIds = fp.map(u => `${u.id}`, users);
    const facUserLabels = fp.pipe([
        fp.map(u => [`${u.id}`, u.attributes.name]),
        fp.fromPairs,
    ])(users);
    if (!facility) return null;
    return (
        <>
            <Field
                component={TextField}
                label='Facility Name'
                name='name'
                fullWidth
                InputProps={{readOnly}}
            />
            <Field
                component={TextField}
                label='Facility Organization Name'
                name='org_name'
                fullWidth
                InputProps={{readOnly}}
            />
            <Field
                component={TextField}
                label='Street Address'
                name='address.line1'
                fullWidth
                InputProps={{readOnly}}
            />
            <Field
                component={TextField}
                label=''
                name='address.line2'
                fullWidth
                InputProps={{readOnly}}
            />
            <div>
                <Field
                    component={TextField}
                    label='City'
                    name='address.city'
                    className={classes.cityField}
                    InputProps={{readOnly}}
                />
                <Field
                    component={TextField}
                    label='State'
                    name='address.state'
                    className={classes.stateField}
                    InputProps={{readOnly}}
                />
                <Field
                    component={TextField}
                    label='Zip'
                    name='address.postalCode'
                    className={classes.postalCodeField}
                    InputProps={{readOnly}}
                />
            </div>
            <Field
                component={TelecomField}
                label='Telecom'
                name='telecom'
                readOnly={readOnly}
            />
            <br />
            <Field
                component={TextField}
                select
                label='Primary Practice'
                name='primaryPracticeId'
                helperText='Patients will be auto-assigned to this practice.'
                className={classes.primaryPracticeField}
            >
                {fp.map(
                    ([id, label]) => (
                        <MenuItem key={label} value={id}>
                            {label}
                        </MenuItem>
                    ),
                    practiceOptions,
                )}
            </Field>
            <Tooltip title='Note: This will NOT affect patients with an existing practice assignment. Only patients with no assigned practice will be updated.'>
                <IconButton>
                    <Help />
                </IconButton>
            </Tooltip>
            <br />
            <Field
                component={TextField}
                label='Automated Reports Recipient Email'
                name='reports_email.email'
                helperText='If not provided, automated reports will be sent to all facility admins.'
                fullWidth
            />
            {facUserIds && (
                <Field
                    component={Autocomplete}
                    multiple
                    name='cc_users'
                    options={facUserIds}
                    getOptionLabel={o => fp.get(o, facUserLabels, 'DEFAULT')}
                    renderInput={props => (
                        <MUITextField
                            {...props}
                            label='CC Users'
                            helperText='These users will receive copies of all messages to all patient charts in this facility.'
                        />
                    )}
                />
            )}
            <Field
                type='checkbox'
                component={CheckboxWithLabel}
                Label={{label: 'Allow Faxes'}}
                name='allow_fax'
                fullWidth
            />
        </>
    );
}

export default function FacilityDialog({dialogRef}) {
    const api = useApi();
    const [resolve, setResolve] = useState(null);
    const [tab, setTab] = useState(0);
    const [facility, setFacility] = useState();
    const [options, setOptions] = useState(defaultOptions);
    const ehr_connection_id = fp.get(
        'relationships.ehr_connection.data.id',
        facility,
    );
    const ehr = useSelector(
        ducks.jsonapi.selectObject(['EHRConnection', ehr_connection_id]),
    );
    useEffect(() => {
        if (api && facility && ehr_connection_id && !ehr) {
            const url = fp.get(
                'relationships.ehr_connection.links.related',
                facility,
            );
            api.fetchJson(url);
        }
    }, [api, facility, ehr_connection_id, ehr]);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
        setFacility(null);
    };

    const handleCancel = () => {
        resolve(null);
        setFacility(null);
        setResolve(null);
    };

    dialogRef.current = (facility, options = defaultOptions) => {
        return new Promise((resolve, reject) => {
            setTab(0);
            setFacility(facility);
            setOptions(options);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={fp.get('attributes', facility)}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <Tabs
                                value={tab}
                                onChange={(ev, index) => setTab(index)}
                            >
                                <Tab label='Core Settings' />
                                <Tab label='Security Policy' />
                            </Tabs>
                            {tab === 0 ? (
                                <CoreSettingsFields facility={facility} />
                            ) : tab === 1 ? (
                                <SecurityPolicyFields />
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
