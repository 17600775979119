import React from 'react';
import {Formik, Form, Field} from 'formik';
import * as yup from 'yup';

import {TextField} from 'formik-material-ui';

import {Button} from '@material-ui/core';

const validationSchema = yup.object().shape({
    email: yup.string().email().lowercase().required(),
});

const initialValues = {
    email: '',
};

export default function PasswordResetRequestForm({onSubmit}) {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {({touched, values, errors}) => (
                <Form>
                    <Field
                        component={TextField}
                        label='Email address'
                        name='email'
                        fullWidth
                    />
                    <br />
                    <br />

                    <Button type='submit' variant='contained' color='primary'>
                        Send Password Reset Email
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
