import React, {useEffect, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Tab, Tabs} from '@material-ui/core';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

import BasePage from 'ccm/components/BasePage';
import CmsFacilitiesTable from 'ccm/components/CmsFacilitiesTable';
import {useCMSFacilities} from 'ccm/dataSource';
import {createSelector} from '@reduxjs/toolkit';

const selectUserRelatedFacilities = createSelector(selectCurrentUser, user => {
    return fp.pipe([
        fp.get('relationships.facilities.data'),
        fp.map(facRel => facRel.id),
    ])(user);
});

const CMS_FACILITIES_DEFAULT_FETCH_OPTIONS = {
    filter: {'attributes.facility_id': {$ne: null}},
    page: {number: 0, size: 20},
};

export default function FacilitiesCmsPage() {
    const [tab, setTab] = useState(0);
    const userFacs = useSelector(selectUserRelatedFacilities);
    const userFacsFetchOptions = useMemo(
        () => ({
            ...CMS_FACILITIES_DEFAULT_FETCH_OPTIONS,
            filter: {'attributes.facility_id': {$in: userFacs}},
        }),
        [userFacs],
    );
    const dataSource = useCMSFacilities({
        initialFetchOptions: userFacsFetchOptions,
    });
    const setFetchOptions = dataSource.setFetchOptions;

    useEffect(() => {
        const fetchOptions =
            tab === 0
                ? userFacsFetchOptions
                : CMS_FACILITIES_DEFAULT_FETCH_OPTIONS;
        setFetchOptions(fetchOptions);
    }, [userFacsFetchOptions, setFetchOptions, tab]);

    if (!userFacs) return null;

    return (
        <BasePage wide title='CMS Facilities'>
            <Tabs value={tab} onChange={(_, index) => setTab(index)}>
                <Tab label='My Facilities' />
                <Tab label='All Facilities' />
            </Tabs>
            <CmsFacilitiesTable dataSource={dataSource} />
        </BasePage>
    );
}
