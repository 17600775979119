import React from 'react';
import * as R from 'react-router-dom';
import {url_for} from 'ccm/routes';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    makeStyles,
} from '@material-ui/core';

import {Date} from 'ccm/components/DateTime';
import InfoPopover from 'ccm/components/InfoPopover';

const useStyles = makeStyles(theme => ({
    cardHeader: {
        color: props => theme.palette.cf[props.color],
    },
    cardContent: {
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    popoverDiv: {
        padding: theme.spacing(1),
    },
}));

export default function VitalCard({
    title,
    subheader,
    color,
    children,
    Avatar,
    patient,
    // from Observation resource
    value,
    performer,
    effectiveDateTime,
    type = 'undefined',
}) {
    const classes = useStyles({color});
    if (!title) {
        title = value.valueString;
    }
    const detail = (
        <div className={classes.popoverDiv}>
            {`recorded by ${performer.display}`} on{' '}
            <Date value={effectiveDateTime} />
        </div>
    );
    if (!Avatar) {
        console.log('Bad avatar', title);
        return null;
    }
    const searchParams = new URLSearchParams();
    searchParams.set('endDate', effectiveDateTime);
    const url =
        url_for('observationHistory', {patientId: patient.id, type}) +
        '?' +
        searchParams;
    return (
        <Card>
            <CardHeader
                avatar={<Avatar color={color} />}
                disableTypography
                title={<h6>{title}</h6>}
                subheader={subheader}
                className={classes.cardHeader}
                action={<InfoPopover>{detail}</InfoPopover>}
            />
            <CardContent className={classes.cardContent}>
                {children}
            </CardContent>
            <CardActions>
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    component={R.Link}
                    to={url}
                >
                    View history
                </Button>
            </CardActions>
        </Card>
    );
}
