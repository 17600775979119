import React from 'react';
import fp from 'lodash/fp';
import {useDispatch} from 'react-redux';

import {Card, CardHeader, CardContent, IconButton} from '@material-ui/core';
import {Add} from '@material-ui/icons';

import {ducks, useApi} from '@arborian/narrf';
import {useCurrentUser, useUserTelecom} from 'ccm/dataSource/misc';

import {useDialog} from 'ccm/components/dialogs';
import AddContactPointDialog from 'ccm/components/dialogs/AddContactPointDialog';

import ContactPointChip from 'ccm/components/ContactPointChip';

function ContactPoint({user, cp}) {
    const api = useApi();
    const dispatch = useDispatch();
    const handleDelete = async ev => {
        await api.fetchJson(cp.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(cp));
    };
    return <ContactPointChip value={cp.attributes} onDelete={handleDelete} />;
}

export default function TelecomCard() {
    const api = useApi();
    // const user = useSelector(selectCurrentUser);
    // const rel = useSelector(ducks.jsonapi.selectRelated(user));
    const dialog = useDialog(AddContactPointDialog);
    const handleAdd = async ev => {
        let resp = await dialog();
        if (!resp) return;
        console.log('Add contact point', ev, resp);
        resp = await api.fetchJson(user.relationships.telecom.links.related, {
            method: 'POST',
            json: {data: {type: 'ContactPoint', attributes: resp}},
        });
        console.log('API response', resp);
    };
    const user = useCurrentUser();
    const userTelecom = useUserTelecom(user);
    /*
    useEffect(() => {
        if (user) {
            api.fetchAllJsonApi(user.relationships.telecom.links.related);
        }
    }, [user, api]);
    if (!user) return null;
    console.log('User rel is', rel);
    */
    return (
        <Card>
            <CardHeader title='My Phone Number(s)' />
            <CardContent>
                {fp.map(
                    cp => (
                        <ContactPoint key={cp.id} user={user} cp={cp} />
                    ),
                    userTelecom,
                    // rel.telecom,
                )}
                <IconButton onClick={handleAdd}>
                    <Add />
                </IconButton>
            </CardContent>
        </Card>
    );
}
