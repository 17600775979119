import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import * as yup from 'yup';

import {makeStyles, Button} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Formik, Form, Field} from 'formik';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';

import BasePage from 'ccm/components/BasePage';
import TicketNarrative from 'ccm/components/TicketNarrative';
import {useApi, ducks} from '@arborian/narrf';
import {url_for} from 'ccm/routes';
import {fieldId} from 'ccm/lib/util';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

const useStyles = makeStyles(theme => ({
    pageParent: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        textAlign: 'center',
        // width: '50%',
        '&  .MuiAlert-root': {
            flexDirection: 'column',
        },
    },
    buttons: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
    },
    timeEntryField: {
        marginTop: theme.spacing(4),
    },
}));

const schema = yup.object().shape({
    time_entry_minutes: yup
        .number()
        .typeError('Must be a number')
        .integer('Must be a whole number')
        .positive('Must be a positive number')
        .nullable(),
    urgent: yup.boolean().default(false),
});

function TicketSubmitForm({onSubmit}) {
    const classes = useStyles();
    const history = useHistory();
    const initialValues = {time_entry_minutes: null, urgent: false};
    const user = useSelector(selectCurrentUser);
    const userGroups = fp.get('relationships.groups.data', user);
    const isPracticeMember = fp.some(g => g.id.match(/^practice:/), userGroups);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schema}
        >
            {formikProps => (
                <Form>
                    <div styles={{flexDirection: 'column'}}>
                        {isPracticeMember && (
                            <Field
                                className={classes.timeEntryField}
                                id='TimeEntryMinutesField'
                                name='time_entry_minutes'
                                component={TextField}
                                label='Minutes'
                                helperText='Time spent on patient care and documentation'
                                variant='outlined'
                            />
                        )}
                        <div className={classes.buttons}>
                            <Button
                                id={fieldId('dyn', 'backButton')}
                                onClick={() => history.go(-1)}
                            >
                                Back
                            </Button>
                            <Button
                                id={fieldId('dyn', 'submitButton')}
                                style={{margin: 20}}
                                variant='contained'
                                onClick={formikProps.handleSubmit}
                                disabled={
                                    isPracticeMember &&
                                    !formikProps.values.time_entry_minutes
                                }
                            >
                                {formikProps.isSubmitting
                                    ? 'Submitting...'
                                    : 'Submit'}
                            </Button>
                        </div>
                    </div>
                    <Alert severity='warning' icon={false}>
                        <p>
                            This system is not designed to report medical
                            emergencies.
                        </p>
                        <p>
                            <strong>
                                If this is in regards to a medical emergency,
                                please call 911.
                            </strong>
                        </p>
                        <Field
                            id='PriorityCheckbox'
                            name='urgent'
                            component={CheckboxWithLabel}
                            type='checkbox'
                            Label={{
                                label: 'This ticket requires an urgent response',
                            }}
                        />
                    </Alert>
                </Form>
            )}
        </Formik>
    );
}

export default function SubmitPage({match}) {
    const api = useApi();
    const classes = useStyles();
    const history = useHistory();
    const sessionid = match.params.sessionid;

    const session = useSelector(
        ducks.jsonapi.selectObject(['TicketSession', sessionid]),
    );

    useEffect(() => {
        // Ensure narrative is available (on page refreshes, etc.)
        api.fetchJsonApi(
            api.url_for('ticketsession.TicketSession', {
                session_id: sessionid,
            }),
        );
    }, [api, sessionid]);

    useEffect(() => {
        if (session) {
            api.fetchJson(
                fp.get('relationships.narrative.links.related', session),
            );
        }
    }, [api, session]);

    const narrative = useSelector(
        ducks.jsonapi.selectObject(['Narrative', sessionid]),
    );

    const handleSubmit = async values => {
        const submitUrl = api.url_for('ticket.TicketCollection');
        const successUrl = url_for('formSuccess', {sessionid});
        await api.fetchJsonApi(submitUrl, {
            method: 'POST',
            json: {
                data: {
                    type: 'NewTicket',
                    attributes: {
                        priority: values.urgent ? 'urgent' : 'normal',
                        time_entry_minutes: values.time_entry_minutes,
                    },
                    relationships: {
                        session: {
                            data: {
                                type: 'TicketSession',
                                id: sessionid,
                            },
                        },
                    },
                },
            },
        });
        history.push(successUrl);
    };

    return (
        <BasePage>
            <div className={classes.pageParent}>
                <h4>Review</h4>
                <p>The following ticket will be submitted</p>
                <TicketNarrative value={narrative} />
                <TicketSubmitForm onSubmit={handleSubmit} />
            </div>
        </BasePage>
    );
}
