import React from 'react';
// import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

// // import {makeStyles} from '@material-ui/core';

// import {ducks} from '@arborian/narrf';
// import BasePage from 'ccm/components/BasePage';
// import {formatShortDateTime} from 'ccm/lib/helpers';
// import useWebsocket from 'ccm/lib/websocket';

// const useStyles = makeStyles(theme => ({}));

export default function TestPage() {
    window.fp = fp;
    return <div>Hi</div>;
    // const classes = useStyles();
    // const ticketId = '65570741c6e813bbbb14c286';
    // const ws = useWebsocket(`/ticket/${ticketId}/chat`);
    // const ticket = useSelector(
    //     ducks.jsonapi.selectObject(['Ticket', ticketId]),
    // );
    // const chat = fp.get('attributes.data.chat', ticket);
    // console.log('ws is', ws);
    // return null;
    // return (
    //     <BasePage title='Test Page'>
    //         <ul>
    //             {fp.map(
    //                 c => (
    //                     <li>
    //                         {formatShortDateTime(c.created)} {c.user_name}:{' '}
    //                         {c.message}
    //                     </li>
    //                 ),
    //                 chat,
    //             )}
    //         </ul>
    //     </BasePage>
    // );
}
