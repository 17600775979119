import React from 'react';
import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    makeStyles,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';

import {startOfDay} from 'date-fns';
import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';

import * as h from 'ccm/lib/helpers';
import {TicketTypeSelect} from 'ccm/components/formikFields';

const useStyles = makeStyles(theme => ({
    root: {
        '& form': {
            width: '100%',
        },
    },
    filters: {
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
        '& form': {
            width: '100%',
        },
    },
    fieldActions: {
        display: 'flex',
    },
    formActions: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const INITIAL_VALUES = {
    since: new Date(),
    ofType: [],
};

export default function PatientFilters({dataSource, filterPrefix}) {
    const classes = useStyles();
    const {fetchOptions, setFetchOptions} = dataSource;
    const sinceFilterName = filterPrefix + 'since';
    const ofTypeFilterName = filterPrefix + 'ofType';
    const since = fp.get(['filter', sinceFilterName, '$date'], fetchOptions);
    const allTicketTypes = useAllTicketTypesLookup();

    const handleSubmit = async (values, form) => {
        const ofType = fp.isEmpty(values.ofType) ? {$ne: 'MAR'} : values.ofType;
        const newFetchOptions = fp.pipe([
            fp.set(['filter', sinceFilterName], {
                $date: startOfDay(values.since),
            }),
            fp.set(['filter', ofTypeFilterName], ofType),
        ])(fetchOptions);

        console.log('Setting filters', {
            fetchOptions,
            newFetchOptions,
            since: startOfDay(values.since),
        });
        setFetchOptions(newFetchOptions);
    };

    const handleClearFilters = resetForm => {
        resetForm();
        const newFetchOptions = fp.pipe([
            fp.unset(['filter', sinceFilterName]),
            fp.unset(['filter', ofTypeFilterName]),
        ])(fetchOptions);
        dataSource.setFetchOptions(newFetchOptions);
        // dataSource.setFetchOptions(dataSource.initialFetchOptions);
    };

    const selectAllTicketTypes = ({values, setFieldValue, setFieldTouched}) => {
        setFieldValue('ofType', fp.keys(allTicketTypes));
    };
    const selectNoTicketTypes = ({values, setFieldValue}) => {
        setFieldValue('ofType', []);
    };

    return (
        <Accordion className={classes.root}>
            <AccordionSummary>
                {since ? (
                    <span>
                        Patients with tickets since {h.formatDateOnly(since)}
                    </span>
                ) : (
                    <span>Filter by recent tickets</span>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Formik
                    enableReinitialize
                    initialValues={INITIAL_VALUES}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <Form onSubmit={formikProps.handleSubmit}>
                            <Field
                                name='since'
                                label='Patients with tickets since'
                                component={KeyboardDatePicker}
                                format='yyyy-MM-dd'
                                fullWidth
                            />
                            <Field
                                name='ofType'
                                label='Ticket type(s)'
                                component={TicketTypeSelect}
                                multiple
                                fullWidth
                            />
                            <div className={classes.fieldActions}>
                                <Button
                                    onClick={ev =>
                                        selectAllTicketTypes(formikProps)
                                    }
                                >
                                    Select all
                                </Button>
                                <Button
                                    onClick={ev =>
                                        selectNoTicketTypes(formikProps)
                                    }
                                >
                                    Select none
                                </Button>
                            </div>

                            <div className={classes.formActions}>
                                <Button
                                    onClick={ev =>
                                        handleClearFilters(
                                            formikProps.resetForm,
                                        )
                                    }
                                >
                                    Clear Filters
                                </Button>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    onClick={formikProps.handleSubmit}
                                >
                                    Set Filters
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </AccordionDetails>
        </Accordion>
    );
}
