import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Paper, makeStyles} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {useApi, ducks} from '@arborian/narrf';
import PCCConfig from './PCCConfig';
import PFConfig from 'ccm/components/practiceFusion/Config';
import CollainConfig from './CollainConfig';
import ChartpathConfig from './ChartpathConfig';
import AlisConfig from './AlisConfig';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
}));

function NoEHRConnection({owner}) {
    return (
        <p>
            No EHR has been configured for your organization. Please contact
            SamePage, MD for help linking your EHR
        </p>
    );
}

function ConfigForm({ehrConnection, owner}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const configUrl = fp.get('links.config', ehrConnection);
        if (configUrl) {
            api.fetchJson(configUrl);
        }
    }, [api, ehrConnection]);
    const config = useSelector(
        ducks.jsonapi.selectObject(['EHRConfig', ehrConnection.id]),
    );
    const handleSubmit = async values => {
        const data = fp.assign(config, values);

        await api.fetchJson(config.links.self, {method: 'PUT', json: {data}});
        enqueueSnackbar('Configuration saved.', {
            variant: 'success',
            persist: false,
        });
    };
    return (
        <div>
            {ehrConnection.attributes.plugin_id === 'pcc' ? (
                <PCCConfig
                    owner={owner}
                    config={config}
                    onSubmit={handleSubmit}
                />
            ) : ehrConnection.attributes.plugin_id === 'collain' ? (
                <CollainConfig
                    owner={owner}
                    config={config}
                    onSubmit={handleSubmit}
                />
            ) : ehrConnection.attributes.plugin_id === 'pf' ? (
                <PFConfig
                    owner={owner}
                    config={config}
                    onSubmit={handleSubmit}
                />
            ) : ehrConnection.attributes.plugin_id === 'chartpath' ? (
                <ChartpathConfig
                    owner={owner}
                    config={config}
                    onSubmit={handleSubmit}
                />
            ) : ehrConnection.attributes.plugin_id === 'alis' ? (
                <AlisConfig
                    owner={owner}
                    config={config}
                    onSubmit={handleSubmit}
                />
            ) : (
                <h6>
                    Unknown EHR plugin type {ehrConnection.attributes.plugin_id}
                </h6>
            )}
        </div>
    );
}

export default function EHRConfig({owner}) {
    // TODO: can we pass in the EHRConnection + Config from wherever
    // this is used (we can for PFConfig)
    const api = useApi();
    const classes = useStyles();
    const relConnection = fp.get('relationships.ehr_connection', owner);

    useEffect(() => {
        const url = fp.get('links.related', relConnection);
        if (url) {
            api.fetchJson(url);
        }
    }, [api, relConnection]);
    const ehrConnection = useSelector(
        ducks.jsonapi.selectObject([
            fp.get('data.type', relConnection),
            fp.get('data.id', relConnection),
        ]),
    );

    return (
        <Paper className={classes.root}>
            {ehrConnection ? (
                <ConfigForm owner={owner} ehrConnection={ehrConnection} />
            ) : (
                <NoEHRConnection owner={owner} />
            )}
        </Paper>
    );
}
