import React, {useCallback} from 'react';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

import {GlobalContext} from './context';
export {useGlobals} from './context';

var _fetchStarted = {};

const defaultFetchOptions = {
    page: {
        limit: 200,
    },
};

const _fetchAll = async (api, route, fetchOptions) => {
    if (!api.hasDirectory) return;
    const fetchStarted = fp.get([route], _fetchStarted);
    if (fetchStarted) return;
    _fetchStarted[route] = true;
    try {
        const _fetchOptions = fp.merge(fetchOptions, defaultFetchOptions);
        api.fetchAllJsonApi(api.url_for(route), _fetchOptions);
    } catch (e) {
        _fetchStarted[route] = false;
        console.error('Error fetching', route, e);
    }
};

const _fetch = async (api, route, fetchOptions = {}) => {
    if (!api.hasDirectory) return;
    const fetchStarted = fp.get([route], _fetchStarted);
    if (fetchStarted) return;
    _fetchStarted[route] = true;
    try {
        api.fetchJsonApi(api.url_for(route), fetchOptions);
    } catch (e) {
        _fetchStarted[route] = false;
        console.error('Error fetching', route, e);
    }
};

export default function GlobalsProvider({children}) {
    const api = useApi();

    const fetchAll = useCallback(
        (route, fetchOptions) => _fetchAll(api, route, fetchOptions),
        [api],
    );
    const fetch = useCallback(
        (route, fetchOptions) => _fetch(api, route, fetchOptions),
        [api],
    );
    const value = {fetch, fetchAll};
    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
}
