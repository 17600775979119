import React from 'react';

import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Lock } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    locked: {
        marginLeft: 12
    },
}));

export default function ReadOnlyChip () {
    const classes = useStyles();
    return (
        <Tooltip title="Editing disabled for resolved tickets">
            <Chip className={classes.locked} avatar={<Lock/>} label="Read Only" size="small"/>
        </Tooltip>
    )
}
