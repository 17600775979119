import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import * as RC from 'react-color';

import TagChip from './TagChip';

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        '& > div': {
            margin: theme.spacing(1),
        },
    },
}));

function ColorField({field, form, ...other}) {
    const handleChange = value => {
        form.setFieldValue(field.name, value.hex);
    };
    return (
        <RC.CompactPicker color={field.value} onChangeComplete={handleChange} />
    );
}

export default function TagDialog({dialogRef}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState();

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = tag => {
        return new Promise((resolve, reject) => {
            console.log('Set initial values', tag);
            setInitialValues(tag);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>Tag Editor</DialogTitle>
                        <DialogContent>
                            <Field
                                component={TextField}
                                fullWidth
                                label='Name'
                                name='attributes.name'
                            />
                            <Field
                                component={TextField}
                                fullWidth
                                label='Value'
                                name='attributes.value'
                            />
                            <div className={classes.hbox}>
                                <div>
                                    <h3>Foreground (text) Color</h3>
                                    <Field
                                        component={ColorField}
                                        fullWidth
                                        label='Tag Color'
                                        name='attributes.color'
                                    />
                                </div>
                                <div>
                                    <h3>Background Color</h3>
                                    <Field
                                        component={ColorField}
                                        fullWidth
                                        label='Tag Color'
                                        name='attributes.backgroundColor'
                                    />
                                </div>
                            </div>
                            Preview:{' '}
                            <TagChip
                                tagAttrs={values.attributes}
                                onDelete={() => {}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Save Tag
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
