import React from 'react';

import {
    Paper,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {
    Delete,
    ArrowUpward,
    ArrowDownward,
    FlipToBack,
    FlipToFront,
} from '@material-ui/icons';

import useStyles from './styles';
import {useEditorContext} from './context';
import {
    menuDelete,
    menuRaise,
    menuLower,
    menuSendFront,
    menuSendBack,
} from './actions';

export default function ContextMenu() {
    const classes = useStyles();
    const ctx = useEditorContext();
    const {clickMode} = ctx;
    if (clickMode?.mode !== 'contextMenu') return null;
    const {location} = clickMode;

    return (
        <Paper
            className={classes.contextMenu}
            style={{
                ...location,
            }}
        >
            <MenuList>
                <MenuItem onClick={menuDelete(ctx)}>
                    <ListItemIcon>
                        <Delete fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>

                <MenuItem onClick={menuRaise(ctx)}>
                    <ListItemIcon>
                        <ArrowUpward fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Bring forward</ListItemText>
                </MenuItem>

                <MenuItem onClick={menuLower(ctx)}>
                    <ListItemIcon>
                        <ArrowDownward fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Send backwards</ListItemText>
                </MenuItem>

                <MenuItem onClick={menuSendFront(ctx)}>
                    <ListItemIcon>
                        <FlipToFront fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Bring to front</ListItemText>
                </MenuItem>

                <MenuItem onClick={menuSendBack(ctx)}>
                    <ListItemIcon>
                        <FlipToBack fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Send to back</ListItemText>
                </MenuItem>
            </MenuList>
        </Paper>
    );
}
