import React from 'react';
import fp from 'lodash/fp';

import {TextField} from '@material-ui/core';
import {Autocomplete} from 'formik-material-ui-lab';

export default function PCCDocumentTypeSelect({
    owner,
    label,
    name,
    touched,
    errors,
    ...props
}) {
    const options = fp.getOr(
        [],
        'attributes.raw.pickList.documentCategories',
        owner,
    );
    return (
        <Autocomplete
            options={options}
            getOptionLabel={fp.get('description')}
            getOptionsSelected={(option, value) => option.id === value.id}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    errors={touched && touched[name] && !!errors[name]}
                    helperText={errors && errors[name]}
                />
            )}
            {...props}
        />
    );
}
