import React, {useCallback} from 'react';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {
    FormGroup,
    Checkbox,
    FormControl,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    makeStyles,
} from '@material-ui/core';

import {fieldId} from 'ccm/lib/util';

const useStyles = makeStyles(theme => ({
    centered: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

function CheckboxGroup({
    field,
    form, // From Formik
    label,
    caption,
    options,
    required,
    row,
}) {
    const classes = useStyles();
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);
    return (
        <FormControl
            component='fieldset'
            className={classes.centered}
            error={!!(touched && errors)}
            required={required}
        >
            <FormLabel>{label}</FormLabel>
            {touched && errors && <FormHelperText>{errors}</FormHelperText>}
            <FormGroup
                row={row}
                value={field.value || null}
                onChange={useCallback(
                    ev => {
                        const newValue = {
                            ...field.value,
                            [ev.target.value]: ev.target.checked,
                        };
                        form.setFieldValue(field.name, newValue);
                    },
                    [form, field],
                )}
            >
                {fp.map(
                    option => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={
                                <Checkbox
                                    id={fieldId(
                                        'dyn',
                                        field.name,
                                        option.value,
                                    )}
                                />
                            }
                            label={option.label}
                            labelPlacement={row ? 'bottom' : 'end'}
                            checked={fp.getOr(false, option.value, field.value)}
                        />
                    ),
                    options,
                )}
            </FormGroup>
            {caption && <FormHelperText>{caption}</FormHelperText>}
        </FormControl>
    );
}

const DynaformField = ({field}) => {
    return (
        <Field
            component={CheckboxGroup}
            name={field.id}
            label={field.label}
            caption={field.caption}
            required={field.required}
            row={field.fieldDirection === 'row'}
            options={field.options}
        />
    );
};
export default DynaformField;
