import React from 'react';
import fp from 'lodash/fp';
import * as yup from 'yup';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    FormControl,
    FormLabel,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';
import {useFetchedRelationshipReverse} from 'ccm/dataSource/misc';

import PCCPortalConfig from './PCCPortalConfig';
import PCCProgressNoteTypesField from './PCCProgressNoteTypesField';
import PCCDocumentTypeSelect from './PCCDocumentTypeSelect';

const FLAGS = [
    {id: 'allergy', label: 'allergy added/changed'},
    {id: 'condition', label: 'condition added/changed'},
    {id: 'immunization', label: 'immunization added/changed'},
    {id: 'medication', label: 'medication added/changed'},
    {id: 'provider', label: 'health care provider added/changed'},
    {id: 'nutrition', label: 'nutrition added/changed'},
    {id: 'observation', label: 'vital(s) added/changed'},
    {id: 'observation_warn', label: 'vital(s) generated a warning'},
    {id: 'lab', label: 'lab(s) added/changed'},
    {id: 'lab_warn', label: 'lab(s) generated a warning'},
];

const validationSchema = yup.object().shape({
    relationships: yup.object().shape({
        progressNoteType: yup.object().shape({
            data: yup
                .object()
                .shape({
                    type: yup.string(),
                    id: yup.string(),
                })
                .typeError('You must select a progress note type'),
        }),
    }),
});

const useAllNoteTypes = config =>
    useFetchedRelationshipReverse(
        config,
        'progressNoteTypes',
        'PCCProgressNoteType',
        'ehr_connection',
    );

function Notifications({prefix, label, progressNoteTypes}) {
    const noteTypes = fp.map('attributes.noteType', progressNoteTypes);
    return (
        <FormControl control='fieldset'>
            <FormLabel control='legend'>{label}</FormLabel>
            {fp.map(
                f => (
                    <Field
                        key={f.id}
                        component={CheckboxWithLabel}
                        type='checkbox'
                        name={`attributes.${prefix}.${f.id}`}
                        Label={{label: f.label}}
                    />
                ),
                FLAGS,
            )}
            <Field
                component={PCCProgressNoteTypesField}
                label='Progress note type(s) added'
                name={`attributes.${prefix}.note_added_categories`}
                noteTypes={noteTypes}
            />
        </FormControl>
    );
}

export default function PCCConfig({owner, config, onSubmit}) {
    const progressNoteTypes = fp.sortBy(
        'attributes.noteType',
        useAllNoteTypes(config),
    );

    const uploadProgressNoteTypes = fp.filter(
        pnt => fp.size(pnt.attributes.sections) === 1,
        progressNoteTypes,
    );

    if (fp.isEmpty(progressNoteTypes)) {
        return (
            <div>
                <h6>PointClickCare Configuration</h6>
                Loading...
            </div>
        );
    }

    return (
        <Formik
            enableReinitialize
            initialValues={config}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {formikProps => (
                <Form>
                    <h6>PointClickCare Configuration</h6>
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Progress Note Type'
                        name='relationships.progressNoteType.data'
                        options={uploadProgressNoteTypes}
                        getOptionLabel={fp.get('attributes.noteType')}
                    />
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={PCCDocumentTypeSelect}
                        label='PDF Upload Document Type'
                        name='attributes.documentCategory'
                        owner={owner}
                    />
                    <Accordion>
                        <AccordionSummary>
                            Patient notifications
                        </AccordionSummary>
                        <AccordionDetails>
                            <Notifications
                                prefix='notify_patient_on'
                                label='Notify patient when...'
                                progressNoteTypes={progressNoteTypes}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Automatic tickets</AccordionSummary>
                        <AccordionDetails>
                            <Notifications
                                prefix='create_ticket_on'
                                label='Create provider ticket when...'
                                progressNoteTypes={progressNoteTypes}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <PCCPortalConfig config={config} />
                    <div>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            Save Configuration
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
