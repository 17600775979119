import React, {useState} from 'react';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

const DEFAULT_OPTIONS = {
    title: 'Please review the latest changes to SamePageMD',
    buttonLabel: 'Acknowledge',
    releaseNotes: 'No new changes.',
};

const useStyles = makeStyles(theme => ({
    header: {
        background: theme.palette.grey[200],
    },
}));

export default function ReleaseNotesDialog({dialogRef}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    if (!dialogRef) return null;
    dialogRef.current = (options = {}) => {
        return new Promise((resolve, reject) => {
            setOptions(fp.merge(DEFAULT_OPTIONS, options));
            setResolve(() => resolve);
        });
    };

    const handleClose = () => {
        resolve(true);
        setResolve(null);
    };

    return (
        <Dialog open={!!resolve} onClose={handleClose} maxWidth='md' fullWidth>
            <DialogTitle className={classes.header}>
                {options.title}
            </DialogTitle>
            <DialogContent>
                <div
                    dangerouslySetInnerHTML={{__html: options.releaseNotes}}
                ></div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    type='submit'
                    variant='contained'
                    color='primary'
                >
                    {options.buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
