import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Schedule, Delete} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TimePicker} from 'formik-material-ui-pickers';
import {parseISO} from 'date-fns';

export default function ShiftDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState({});

    const handleSubmit = values => {
        /*
        values.start = values.start.toJSON();
        values.end = values.end.toJSON();
        */
        resolve({action: 'save', values});
        setResolve(null);
    };

    const handleDelete = () => {
        resolve({action: 'delete'});
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    dialogRef.current = initialValues => {
        return new Promise((resolve, reject) => {
            console.log('Slot values', initialValues);
            setInitialValues({
                ...initialValues,
                start: parseISO(initialValues.start),
                end: parseISO(initialValues.end),
            });
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>Slot Editor</DialogTitle>
                        <DialogContent>
                            <Field
                                component={TimePicker}
                                label='Start time'
                                name='start'
                                fullWidth
                            />
                            <Field
                                component={TimePicker}
                                label='End time'
                                name='end'
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={handleDelete}
                                startIcon={<Delete />}
                            >
                                Delete slot
                            </Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                startIcon={<Schedule />}
                            >
                                Save Slot
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
