import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {ducks} from '@arborian/narrf';

import TagChip from 'ccm/components/PracticeManagement/tags/TagChip';

export default function PatientTags({patient}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(patient));
    return (
        <div>
            {fp.map(
                tag => (
                    <TagChip
                        tagAttrs={tag?.attributes}
                        size='small'
                        variant='outlined'
                    />
                ),
                rel.tags,
            )}
            {/* The following is likely to be unnecessary; 
           remove it after October 2024 */}
            {fp.map(
                tag => (
                    <TagChip tagAttrs={tag} size='small' variant='outlined' />
                ),
                fp.get('attributes.tags', patient),
            )}
        </div>
    );
}
