import React, {useState} from 'react';

import {
    AppBar,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    Toolbar,
    Tooltip,
    makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';

import ImageEditorProvider, {
    Canvas,
    ContextMenu,
    buttons,
} from 'ccm/components/ImageEditor';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiAppBar-root': {
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialogActions-root': {
            // Vertical Toolbar
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'end',
            gap: 4,
            position: 'fixed',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        },
    },
}));

export default function SignatureImageEditorDialog({dialogRef}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const [src, setSrc] = useState(null);

    const handleClose = () => {
        resolve({action: 'CANCEL'});
        setResolve(null);
        setSrc(null);
    };

    const handleSave = (blob, cObj, rotatedBlob) => {
        resolve({action: 'SAVE_CLOSE', blob, cObj, rotatedBlob});
        setResolve(null);
        setSrc(null);
    };

    dialogRef.current = src => {
        return new Promise((resolve, reject) => {
            setSrc(src);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog
            open={!!resolve}
            fullScreen
            disableEnforceFocus={true}
            className={classes.root}
            onClose={handleClose}
        >
            <AppBar>
                <Toolbar>
                    <Tooltip title='Cancel/discard changes'>
                        <IconButton edge='start' onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <ImageEditorProvider>
                <DialogContent>
                    <Canvas src={src} />
                    <ContextMenu />
                </DialogContent>
                <DialogActions>
                    <buttons.CropButton />
                    <buttons.RotateLeftButton />
                    <buttons.RotateRightButton />
                    <buttons.SaveButton
                        title='Save and close image'
                        onSave={handleSave}
                    />
                </DialogActions>
            </ImageEditorProvider>
        </Dialog>
    );
}
