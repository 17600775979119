import React, {useRef} from 'react';

import {components} from '@arborian/narrf';

import ThreadDetails from './ThreadDetails';
import {
    PatientNameColumnComponent,
    ParticipantsColumnComponent,
} from './columnComponents';

const {dt2} = components;

export default function TableView({
    href,
    children,
    dataSource,
    handleCreateThread,
    handleLockThread,
    handleAddParticipant,
    handleAddMessage,
    isPracticeUser,
    ...props
}) {
    const tableRef = useRef();

    return (
        <dt2.DataTable
            size='small'
            title='Internal Message Threads'
            dataSource={dataSource}
            tableRef={tableRef}
            options={{}}
            {...props}
        >
            <dt2.Column
                title='Patient'
                field='thread.attributes.patient_name_formatted'
                component={PatientNameColumnComponent}
                filtering={true}
                sorting={true}
            />
            <dt2.Column
                title='Subject'
                filtering={false}
                field='thread.attributes.subject'
            />
            <dt2.Column
                title='Participants'
                render={row => (
                    <ParticipantsColumnComponent
                        row={row}
                        handleAddParticipant={handleAddParticipant}
                    />
                )}
            />
            <dt2.Column title='Unread Count' filtering={false} field='unread' />
            <dt2.Column
                title='Total Messages'
                filtering={false}
                field='total'
            />

            <dt2.DetailPanel
                id='threadDetailPanel'
                render={row => {
                    return (
                        <ThreadDetails
                            tableRef={tableRef}
                            row={row}
                            handleLockThread={handleLockThread}
                            handleAddMessage={handleAddMessage}
                            isPracticeUser={isPracticeUser}
                        />
                    );
                }}
            />
            {isPracticeUser && (
                <dt2.Action
                    free
                    id='actionCreateThread'
                    onClick={handleCreateThread}
                    tooltip='Create New Thread'
                    icon='add'
                />
            )}
            <dt2.Action
                free
                id='actionRefresh'
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
