import React, {useMemo} from 'react';

import {useRelated} from 'ccm/dataSource';

import OrderTemplateTable from './OrderTemplateTable';
import OrderImporter from './OrderImporter';
import SuggestOrderTemplate from './SuggestOrderTemplate';

export default function OrderTemplates({practice, isAdmin}) {
    const dataSource = useRelated(practice, 'order_templates', {
        type: 'OrderTemplate',
    });

    const editable = useMemo(
        () => ({
            onRowAdd: dataSource.handleAdd,
            onRowUpdate: dataSource.handleUpdate,
            onRowDelete: dataSource.handleDelete,
        }),
        [dataSource],
    );
    return (
        <div>
            {isAdmin ? (
                <OrderImporter
                    title='Order Template Library'
                    practice={practice}
                    practiceDataSource={dataSource}
                />
            ) : (
                <SuggestOrderTemplate practice={practice} />
            )}
            <OrderTemplateTable
                dataSource={dataSource}
                title='Order Templates'
                editable={isAdmin ? editable : false}
            />
        </div>
    );
}
