import React from 'react';
import fp from 'lodash/fp';
import clsx from 'clsx';

import {IconButton, makeStyles} from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {format, parseISO} from 'date-fns/fp';
import {Clear} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    filter: {
        display: 'flex',
        '& .MuiIconButton-root': {
            padding: 0,
        },
    },
    filterValue: {
        minWidth: theme.spacing(16),
    },
}));

export function DateFilter({column, filter, onChangeFilter}) {
    const classes = useStyles();
    const rawValue = fp.getOr(null, column.field, filter);
    let value = null;
    const filterProps = {format: 'MM/dd/yyyy', ...column.filterProps};
    const {timeZone} = filterProps;

    if (rawValue) {
        value = fp.get('$gte.$date', rawValue);
        value = new Date(value);
    }

    const onChange = (date, value) => {
        console.log('set to', {date, value});
        if (date && !isNaN(date)) {
            const begin = new Date(date);
            const end = new Date(date);
            begin.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 9999);
            onChangeFilter(column.field, {
                $gte: {$date: begin.getTime()},
                $lte: {$date: end.getTime()},
            });
        }
    };

    const handleClear = () => {
        onChangeFilter(column.field, null);
    };
    const containerClass = clsx(classes.filter, value && classes.filterValue);
    console.log('DateFilter value', value);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={containerClass}>
                <KeyboardDatePicker
                    value={value}
                    onChange={onChange}
                    {...filterProps}
                />
                {rawValue && (
                    <IconButton onClick={handleClear}>
                        <Clear />
                    </IconButton>
                )}
            </div>
        </MuiPickersUtilsProvider>
    );
}

export function DateEditor({value, onChange, column}) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <KeyboardDatePicker value={value} onChange={onChange} />
            </div>
        </MuiPickersUtilsProvider>
    );
}

export default function renderDate(row, column) {
    let value = this.field ? fp.get(this.field, row.data) : null;
    if (fp.isString(value)) {
        try {
            value = parseISO(value);
        } catch (e) {
            console.error('Error parsing date', value, e);
        }
    }
    if (fp.isDate(value)) {
        try {
            value = format('MM/dd/yyyy', value);
        } catch (e) {
            console.error('Error formatting date', value, e);
            value = value.toJSON();
        }
    }
    return value;
}
