import React from 'react';
import fp from 'lodash/fp';

import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Checkbox,
    makeStyles,
} from '@material-ui/core';

import {useTableManager} from './Provider';
import FilterRow from './DTableFilter';

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
    actions: {
        display: 'flex',
    },
}));

function ColumnHeaderCell({column, sort, onChangeSort}) {
    const classes = useStyles();
    if (onChangeSort && column.sorting) {
        const active = sort && column.field && column.field === sort.field;
        const direction = active ? sort.direction : 'asc';
        return (
            <TableCell>
                <TableSortLabel
                    active={active}
                    direction={direction}
                    onClick={() => onChangeSort(column.field)}
                >
                    <span className={classes.hidden}>
                        {active
                            ? direction === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'
                            : null}
                    </span>
                    {column.title}
                </TableSortLabel>
            </TableCell>
        );
    } else {
        return <TableCell>{column.title}</TableCell>;
    }
}

function ActionHeaderCell() {
    const tm = useTableManager();
    const classes = useStyles();
    const checked = tm.allRowsSelected();
    const indeterminate = tm.someRowsSelected() && !checked;
    if (tm.options.selection) {
        return (
            <TableCell padding='checkbox'>
                <div className={classes.actions}>
                    <Checkbox
                        checked={checked}
                        indeterminate={indeterminate}
                        onChange={ev => tm.onSelectAllRows(ev.target.checked)}
                    />
                </div>
            </TableCell>
        );
    } else {
        return <TableCell padding='checkbox'>Actions</TableCell>;
    }
}

function HeaderRow() {
    const tm = useTableManager();
    const sort = tm.fetchOptions.sort;
    const onChangeSort = tm.options.sorting ? tm.onChangeSort : null;
    let row = fp.pipe([
        fp.toPairs,
        fp.map(([cid, col]) => (
            <ColumnHeaderCell
                key={cid}
                column={col}
                sort={sort}
                onChangeSort={onChangeSort}
            />
        )),
    ])(tm.columns);
    let actionsDetails = [];
    if (tm.hasActionsColumn) {
        actionsDetails.push(<ActionHeaderCell key='actions' />);
    }
    if (tm.hasDetailPanels) {
        actionsDetails.push(<TableCell key='detail' />);
    }
    if (
        tm.options.actionsColumnIndex < 0 ||
        tm.options.actionsColumnIndex > row.length
    ) {
        row = [...row, ...actionsDetails];
    } else {
        row.splice(tm.options.actionsColumnIndex, 0, ...actionsDetails);
    }

    return <TableRow>{row}</TableRow>;
}

export default function DTableHead() {
    const tm = useTableManager();
    const sort = tm.fetchOptions.sort;
    const onChangeSort = tm.options.sorting ? tm.onChangeSort : null;

    return (
        <TableHead>
            <HeaderRow />
            {tm.options.filtering && <FilterRow />}
        </TableHead>
    );
}
