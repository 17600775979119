import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    canvas: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& canvas': {
            border: '1px solid black',
        },
    },
    contextMenu: {
        zIndex: 99,
        width: 240,
        maxWidth: '100%',
        position: 'fixed',
    },
}));
