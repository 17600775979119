import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';

export const selectRuleAssignments = rule =>
    createSelector(
        ducks.jsonapi.selectObject('Assignment'),
        fp.filter(asg => asg.relationships.rule.data.id === rule?.id),
    );

const commonAttributeOrNull = (attribute, objects) => {
    // If attr is same in all objs, return attr value, else null
    const values = fp.pipe([fp.map(attribute), fp.uniq])(objects);
    if (values.length === 1) return values[0] || null;
    else return null;
};

export const selectRuleShifts = rule =>
    createSelector(
        ducks.jsonapi.selectObject('Shift'),
        fp.filter(shift => shift.relationships.rule.data.id === rule?.id),
    );

export const selectRuleFacilityAssignments = (rule, facilities) =>
    createSelector(
        selectRuleAssignments(rule),
        selectRuleShifts(rule),
        (assignments, shifts) => {
            /* Return object like
             * [
             *     {name: Fallback, id: null, owner_id: fallback_owner_id, ...},
             *     {name: Main, id: 6badf00d, owner_id...},
             *     ...
             * ]
             * (One row per shift, plus the fallback)
             */
            // There is up to 1 assignment per shift-facility, plus one for the fallback

            let index = {};
            fp.forEach(asg => {
                index = fp.set(
                    [
                        fp.getOr(null, 'relationships.facility.data.id', asg),
                        asg.relationships.shift.data.id,
                    ],
                    asg,
                    index,
                );
            }, assignments);

            fp.forEach(fb => {
                index = fp.set([fb.facility_id, null], fb, index);
            }, fp.get('attributes.fallbacks', rule));

            // Generate shift rows
            const makeShiftRow = shift => {
                const asgs = fp.map(
                    fac => fp.get([fac?.id, shift.id], index),
                    facilities,
                );
                return {
                    name: shift.attributes.name,
                    id: shift.id,
                    owner_id: commonAttributeOrNull(
                        shift.id ? 'relationships.owner.data.id' : 'owner_id',
                        asgs,
                    ),
                    provider_id: commonAttributeOrNull(
                        shift.id
                            ? 'relationships.provider.data.id'
                            : 'provider_id',
                        asgs,
                    ),
                    shift: shift, // needed for relationship links
                };
            };
            const fallbackRow = makeShiftRow({
                attributes: {name: 'Fallback'},
                id: null,
            });
            const shiftRows = fp.map(makeShiftRow, shifts);
            return [fallbackRow, ...shiftRows];
        },
    );
