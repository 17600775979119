import React, {useMemo, useState} from 'react';
import fp from 'lodash/fp';

const DialogContext = React.createContext();

export function useDialogContext() {
    return React.useContext(DialogContext);
}

export function useDialog(name) {
    const dialogRef = React.useContext(DialogContext)[name];
    if (!dialogRef) {
        throw new Error('Unknown dialog ' + name);
    }
    return dialogRef.current;
}

export default function DialogProvider({children, dialogs}) {
    const items = useMemo(
        () =>
            fp.pipe([
                fp.toPairs,
                fp.map(([name, Component]) => {
                    let item = {
                        name,
                        ref: {current: null},
                    };
                    item.element = (
                        <Component key={name} dialogRef={item.ref} />
                    );
                    return item;
                }),
            ])(dialogs),
        [dialogs],
    );

    const contextValue = fp.pipe([
        fp.map(item => [item.name, item.ref]),
        fp.fromPairs,
    ])(items);

    return (
        <DialogContext.Provider value={contextValue}>
            {fp.map('element', items)}
            {children}
        </DialogContext.Provider>
    );
}
