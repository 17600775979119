import React from 'react';
import fp from 'lodash/fp';

import {IconButton, Chip, Link} from '@material-ui/core';
import {Edit, Link as LinkIcon} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useDialog} from 'ccm/components/dialogs';
import TelecomChips from 'ccm/components/TelecomChips';
import AddressBlock from 'ccm/components/AddressBlock';
import PracticeLogo from 'ccm/components/PracticeLogo';
import PracticeConsent from 'ccm/components/PracticeConsent';

import PracticeDialog from 'ccm/components/dialogs/PracticeDialog';

import useStyles from './styles';

function PracticeUrl({practice}) {
    const customUrl = new URL(
        url_for('communicateAnon', {
            practiceSlug: fp.get('attributes.slug', practice),
        }),
        window.location.href,
    ).toString();
    const label = <Link href={customUrl}>{customUrl}</Link>;
    return <Chip icon={<LinkIcon />} label={label} />;
}
function PracticeInfo({practice}) {
    const classes = useStyles();
    return (
        <div className={classes.view}>
            <PracticeLogo practice={practice} />
            <PracticeConsent practice={practice} />
            <PracticeUrl practice={practice} />
            <AddressBlock value={fp.get('attributes.address', practice)} />
            <TelecomChips value={fp.get('attributes.telecom', practice)} />
        </div>
    );
}
export default function HomePanel({practice}) {
    const api = useApi();
    const classes = useStyles();
    const practiceDialog = useDialog(PracticeDialog);

    const editPractice = async ev => {
        let attributes = await practiceDialog(practice.attributes, {
            title: 'Edit Practice',
            submitLabel: 'Save changes',
        });
        if (!attributes) return;
        const data = {
            ...practice,
            attributes,
        };
        await api.fetchJsonApi(practice.links.self, {
            method: 'PUT',
            json: {data},
        });
    };
    const canWrite = fp.includes('Common.WRITE', practice.meta.allowed_actions);

    return (
        <>
            {canWrite && (
                <>
                    <IconButton
                        onClick={editPractice}
                        className={classes.floatRight}
                    >
                        <Edit />
                    </IconButton>
                </>
            )}
            <PracticeInfo practice={practice} />
        </>
    );
}
