import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import ContactPointChip from 'ccm/components/ContactPointChip';
import EmailChip from 'ccm/components/EmailChip';

import {usePractitioners} from 'ccm/dataSource';

const {dt2} = components;

function ProviderTelecom({value, ...props}) {
    // We are not including home phone & pager here...
    if (!value) return null;
    value = fp.filter(cp => cp.use === 'work' && cp.system !== 'pager', value);
    return (
        <>
            {fp.map(
                cp => (
                    <ContactPointChip key={cp.id} value={cp} />
                ),
                value,
            )}
        </>
    );
}
export default function PractitionersTable({patient, title, ...props}) {
    const ds = usePractitioners(patient);
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column
                title='Name'
                field='attributes.name.last'
                render={fp.get('data.attributes.name.formatted')}
            />
            <dt2.Column title='Relation' field='attributes.raw.relation' />
            <dt2.Column title='Type' field='attributes.raw.providerType' />
            <dt2.Column
                title='Address'
                field='attributes.address.oneLine'
                sorting={false}
                filtering={false}
            />
            <dt2.Column
                title='Telecom'
                field='attributes.telecom'
                sorting={false}
                filtering={false}
                component={ProviderTelecom}
            />
            <dt2.Column
                title='Email'
                field='attributes.email.email'
                render={row => {
                    const email = fp.get('data.attributes.email.email', row);
                    if (!email) return null;
                    return <EmailChip value={email} />;
                }}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
