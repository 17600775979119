import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {useGlobals} from 'ccm/components/Globals';
import AsyncDialogFilter from 'ccm/components/datatable/AsyncDialogFilter';

import {selectAllPractices} from 'ccm/lib/selectors';

const {dt2} = components;

export function PracticeFilter({column, filter, onChangeFilter, ...props}) {
    const globals = useGlobals();
    const fetchOptions = () => globals.fetchAll('facility.FacilityCollection');
    const selector = fp.pipe([selectAllPractices, fp.map('attributes.name')]);

    return (
        <AsyncDialogFilter
            title='Practice'
            column={column}
            filter={filter}
            onChangeFilter={onChangeFilter}
            selector={selector}
            fetchOptions={fetchOptions}
            {...props}
        />
    );
}

export default function PracticeColumn({
    id = 'col-practice',
    title = 'Practice',
    field = 'attributes.practice_name',
    FilterComponent = PracticeFilter,
    ...props
}) {
    return (
        <dt2.Column
            id={id}
            title={title}
            field={field}
            FilterComponent={FilterComponent}
            {...props}
        />
    );
}
