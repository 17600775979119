import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

export default function ThreadPermissions() {
    const user = useSelector(selectCurrentUser);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const hasPracticeGroup = !!fp.size(
        fp.filter(
            g => g.id.includes('practice'),
            fp.get('relationships.groups.data', user),
        ),
    );
    const hasFacilityGroup = !!fp.size(
        fp.filter(
            g => g.id.includes('facility'),
            fp.get('relationships.groups.data', user),
        ),
    );
    const hasInternalGroup = hasPracticeGroup || hasFacilityGroup;
    const hasInternalMessagingPermission =
        hasInternalGroup || fp.includes('__admin__', userinfo.scopes);
    const hasThreadCreatePermission =
        hasPracticeGroup || fp.includes('__admin__', userinfo.scopes);

    return {
        read: hasInternalMessagingPermission,
        write: hasInternalMessagingPermission,
        create: hasThreadCreatePermission,
    };
}
