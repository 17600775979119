import React, {useEffect, useState} from 'react';
import fp from 'lodash/fp';
import clsx from 'clsx';

import {
    TextField,
    InputAdornment,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {FilterList} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    filterValue: {
        minWidth: theme.spacing(16),
    },
}));

export function StringFilter({column, filter, onChangeFilter, ...props}) {
    const classes = useStyles();
    const value = fp.get(column.field, filter) || '';
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputValue(value || '');
    }, [value]);
    const acceptChange = ev => {
        console.log('AcceptChange', {inputValue, value});
        if (inputValue !== value) {
            onChangeFilter(column.field, inputValue);
        }
    };
    return (
        <TextField
            className={clsx(value && classes.filterValue)}
            value={inputValue}
            onChange={ev => setInputValue(ev.target.value)}
            onBlur={acceptChange}
            onKeyPress={ev => ev.key === 'Enter' && acceptChange()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton onClick={acceptChange}>
                            <FilterList />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}

export function StringEditor({value, error, onChange, column}) {
    const handleChange = ev => {
        onChange(ev.target.value);
    };

    return (
        <TextField
            value={value}
            error={!!error}
            helperText={error}
            onChange={handleChange}
        />
    );
}

export default function renderString(row, column) {
    let value = this.field ? fp.get(this.field, row.data) : null;
    return value;
}
