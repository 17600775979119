import React, {useRef, useMemo, useEffect} from 'react';
import {makeStyles, Button} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        position: 'relative',
    },
    progressBtn: {
        backgroundColor: 'lightyellow',
        position: 'absolute',
        right: 0,
        '&:hover': {
            backgroundColor: 'yellow',
        },
        scrollMargin: theme.spacing(10),
    },
}));

export default function SigningProgress({formState}) {
    const classes = useStyles();
    const buttonRef = useRef();

    const progressState = formState.progressState;

    const {style, label} = useMemo(() => progressState(), [progressState]);
    useEffect(() => {
        buttonRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        });
    }, [style, buttonRef]);

    return (
        <div className={classes.buttonContainer}>
            <Button
                onClick={ev => formState.handleClickNext()}
                className={classes.progressBtn}
                style={style}
                variant='contained'
                ref={buttonRef}
            >
                {label}
            </Button>
        </div>
    );
}
