import React, {useMemo, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {parseISO, subMonths} from 'date-fns';

import Chart from 'react-apexcharts';
import {Formik, Form, Field} from 'formik';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import {Paper, Button, makeStyles} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {selectHistoricalObservations} from 'ccm/duck';
import BasePatientPage from 'ccm/components/BasePatientPage';

import VITALS_CONFIG from 'ccm/components/VitalsDashboard/config';

const useStyles = makeStyles(theme => ({
    hform: {
        display: 'flex',
        justifyItems: 'center',
    },
    field: {
        margin: theme.spacing(0, 1),
    },
    paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
    },
}));

const DateRangeForm = ({onSubmit, initialValues}) => {
    const classes = useStyles();
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => (
                <Form
                    onSubmit={formikProps.handleSubmit}
                    className={classes.hform}
                >
                    <Field
                        className={classes.field}
                        label='Start'
                        format='MM/dd/yyyy'
                        component={KeyboardDatePicker}
                        name='startDate'
                    />
                    &nbsp; &mdash; &nbsp;
                    <Field
                        className={classes.field}
                        label='End'
                        format='MM/dd/yyyy'
                        component={KeyboardDatePicker}
                        name='endDate'
                    />
                    <Button
                        className={classes.field}
                        type='submit'
                        variant='contained'
                        color='primary'
                    >
                        Change
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const useDateRangeQuery = () => {
    const location = useLocation();
    return useMemo(() => {
        const q = new URLSearchParams(location.search);
        let startDate = q.get('startDate');
        let endDate = q.get('endDate');
        endDate = endDate ? parseISO(endDate) : new Date();
        startDate = startDate ? parseISO(startDate) : subMonths(endDate, 3);
        return {startDate, endDate};
    }, [location]);
};

export default function HistoryPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId, type} = useParams();
    const history = useHistory();
    const location = useLocation();
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(patient));
    const timeZone = fp.get('attributes.timeZone', rel.facility);

    const {startDate, endDate} = useDateRangeQuery();

    const vital = useMemo(() => fp.find(v => v.key === type, VITALS_CONFIG), [
        type,
    ]);
    const selectObservations = selectHistoricalObservations(
        patientId,
        {startDate, endDate},
        timeZone,
        vital.series,
    );

    const obss = useSelector(selectObservations);

    useEffect(() => {
        const url = api.url_for('patient.Patient', {patient_id: patientId});
        api.fetchJsonApi(url, {include: ['facility']});
    }, [api, patientId]);

    useEffect(() => {
        if (!patient) return;
        const url = patient.relationships.observations.links.related;
        const effectiveDateTime = JSON.stringify({
            $gte: {$date: startDate.getTime()},
            $lte: {$date: endDate.getTime()},
        });
        const code_text = JSON.stringify({$in: vital.series});
        api.fetchAllJsonApi(url, {
            filter: {'code.text': code_text, effectiveDateTime},
        });
    }, [api, patient, type, startDate, endDate, vital.series]);

    const handleSetDateRange = async value => {
        console.log('Submit', value);
        let searchParams = new URLSearchParams(location.search);
        searchParams.set('startDate', value.startDate.toISOString());
        searchParams.set('endDate', value.endDate.toISOString());
        history.push({search: searchParams.toString()});
    };

    const series = fp.map(name => {
        const data = fp.map(
            obs => ({
                x: fp.get('attributes.effectiveDateTime', obs),
                y: fp.get('attributes.value.value', obs),
            }),
            obss[name],
        );
        return {name, data};
    }, vital.series);

    const initialValues = {
        startDate,
        endDate,
    };

    return (
        <BasePatientPage title={`${vital.title} history`} patient={patient}>
            <Paper className={classes.paper}>
                <Button
                    id='btn-return-chart'
                    component={Link}
                    to={url_for('patient', {patientId: patientId})}
                >
                    Return to Dashboard
                </Button>
                <DateRangeForm
                    initialValues={initialValues}
                    onSubmit={handleSetDateRange}
                />
                <Chart
                    series={series}
                    options={{
                        xaxis: {type: 'datetime'},
                        ...vital.options,
                    }}
                    type='line'
                    width='100%'
                    height='400'
                />
            </Paper>
        </BasePatientPage>
    );
}
