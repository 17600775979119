import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {useApi} from '@arborian/narrf';
import {url_for, useQuery} from 'ccm/routes';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import BasePage from 'ccm/components/BasePage';

export default function CallbackPage() {
    const [init, setInit] = useState();
    const query = useQuery();
    const api = useApi();
    const history = useHistory();
    const [content, setContent] = useState(null);
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        async function main() {
            const email = query.get('email');
            const token = query.get('token');
            await api.fetchJson(user.relationships.emails.links.related, {
                method: 'POST',
                json: {
                    data: {
                        type: 'Email',
                        attributes: {email, token},
                    },
                },
            });
            history.push(url_for('profile'));
        }
        if (user && !init) {
            setInit(true);
            main();
        }
    }, [user, query, history, api, init, setContent]);
    return <BasePage title='Adding email...'>{content}</BasePage>;
}
