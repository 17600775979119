import React, {useState} from 'react';

import {
    Tab,
    Tabs,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import TelecomField from 'ccm/components/TelecomField';
import SecurityPolicyFields from 'ccm/components/SecurityPolicyFields';
import LinkTemplateField from 'ccm/components/LinkTemplateField';

const useStyles = makeStyles(theme => ({
    cityField: {
        maxWidth: theme.spacing(40),
    },
    stateField: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
    postalCodeField: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
}));

const defaultOptions = {
    title: 'Practice',
    submitLabel: 'Save',
};

function CoreSettingsFields() {
    const classes = useStyles();
    return (
        <>
            <Field
                component={TextField}
                label='Practice Name'
                name='name'
                fullWidth
            />
            <Field
                component={TextField}
                label='Street Address'
                name='address.line1'
                fullWidth
            />
            <Field
                component={TextField}
                label=''
                name='address.line2'
                fullWidth
            />
            <div>
                <Field
                    component={TextField}
                    label='City'
                    name='address.city'
                    className={classes.cityField}
                />
                <Field
                    component={TextField}
                    label='State'
                    name='address.state'
                    className={classes.stateField}
                />
                <Field
                    component={TextField}
                    label='Zip'
                    name='address.postalCode'
                    className={classes.postalCodeField}
                />
            </div>
            <Field component={TelecomField} label='Telecom' name='telecom' />
            <Field
                component={TextField}
                label='Email address'
                name='email.email'
                fullWidth
            />
            <Field
                component={TextField}
                label='Nickname/custom URL'
                name='slug'
                fullWidth
            />
            <Field
                component={TextField}
                label='Automated Reports Recipient Email'
                name='reports_email.email'
                helperText='If not provided, automated reports will be sent to all practice admins.'
                fullWidth
            />
            <Field
                component={TextField}
                label='STAT Scheduling Email'
                name='stat_scheduling_email.email'
                fullWidth
            />
            <Field
                component={LinkTemplateField}
                label='Link Templates'
                name='link_templates'
            />
            <Field
                component={TextField}
                label='Custom homepage URL'
                name='splash_href'
                helperText='Customize the url displayed in the home page iframe for your practice.'
                fullWidth
            />
        </>
    );
}

export default function PracticeDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [tab, setTab] = useState(0);
    const [initialValues, setInitialValues] = useState({});
    const [options, setOptions] = useState(defaultOptions);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (initialValues, options = defaultOptions) => {
        return new Promise((resolve, reject) => {
            setInitialValues(initialValues);
            setOptions(options);
            setResolve(() => resolve);
            setTab(0);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <Tabs
                                value={tab}
                                onChange={(ev, index) => setTab(index)}
                            >
                                <Tab label='Core Settings' />
                                <Tab label='Security Policy' />
                            </Tabs>
                            {tab === 0 ? (
                                <CoreSettingsFields />
                            ) : tab === 1 ? (
                                <SecurityPolicyFields />
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
