import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {CircularProgress, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {useApi} from '@arborian/narrf';

const DEFAULT_FETCH_OPTIONS = {
    page: {offset: 0, limit: 200},
};

export default function DynamicAutocomplete({
    label,
    value,
    initialInputValue,
    onChange,
    href,
    fetchOptions,
    textFieldProps,
    ...props
}) {
    const api = useApi();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(initialInputValue || '');
    const loading = open && options.length === 0;
    const [actualValue, setActualValue] = useState();
    // console.log('autocomplete', {href, options, fetchOptions});
    useEffect(() => {
        if (loading) {
            const options = fp.merge(DEFAULT_FETCH_OPTIONS, fetchOptions);
            api.fetchAllJsonApi(href, options).then(data => {
                setOptions(data);
                setActualValue(fp.find(o => o.id === value));
            });
        }
    }, [api, fetchOptions, href, loading, value]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            loading={loading}
            value={actualValue}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={(ev, value, reason) => {
                console.log('input change', reason, value);
                if (reason === 'reset' && !value) {
                    setInputValue(inputValue);
                } else {
                    setInputValue(value);
                }
            }}
            getOptionSelected={(option, value) => {
                return option.id === value.id;
            }}
            {...props}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color='inherit'
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
