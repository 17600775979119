import React, {useCallback} from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {MESSAGE_TYPES} from 'ccm/constants';
import {DateTime} from 'ccm/components/DateTime';

import MessageDetails from './MessageDetails';
import ReadStatus from './ReadStatus';
import PatientNameColumnComponent from './PatientNameColumnComponent';

const {dt2} = components;

const lookupMessageTypes = fp.pipe([
    fp.map(o => [o.value, o.label]),
    fp.fromPairs,
])(MESSAGE_TYPES);

export default function TableView({
    href,
    children,
    dataSource,
    includePatient = false,
    showAllMessages = false,
    ...props
}) {
    const onBatchChange = useCallback(
        newAttrs => (ev, rows) => dataSource.patch(newAttrs, rows),
        [dataSource],
    );

    return (
        <dt2.DataTable
            size='small'
            title={dataSource.showArchived ? 'Archived messages' : 'Inbox'}
            dataSource={dataSource}
            options={{
                selection: !showAllMessages,
                search: true,
                filtering: true,
            }}
            {...props}
        >
            {!showAllMessages && (
                <dt2.Column filtering={false} component={ReadStatus} />
            )}
            {includePatient ? (
                <dt2.Column
                    title='Patient'
                    field='attributes.patient_name_formatted'
                    component={PatientNameColumnComponent}
                    filtering={true}
                    sorting={true}
                />
            ) : null}
            {children}
            <dt2.Column
                title='Type'
                field='attributes.type'
                lookup={lookupMessageTypes}
            />
            <dt2.Column
                title='Sender'
                filtering={false}
                field='attributes.from_'
            />
            <dt2.Column
                title='Subject'
                filtering={false}
                field='attributes.subject'
            />
            <dt2.Column
                title='Sent'
                field='attributes.timestamp'
                filtering={false}
                render={row => (
                    <DateTime
                        value={fp.get('data.attributes.timestamp', row)}
                    />
                )}
            />
            <dt2.DetailPanel
                render={row => {
                    console.log('Detail', row);
                    return (
                        <MessageDetails
                            inboxMessageId={row.id}
                            showAllMessages={showAllMessages}
                        />
                    );
                }}
            />
            <dt2.Action
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            {!showAllMessages && (
                <>
                    <dt2.Action
                        icon='archive'
                        tooltip='Archive message(s)'
                        onClick={onBatchChange({deleted: true})}
                        hidden={dataSource.showArchived}
                    />
                    <dt2.Action
                        icon='unarchive'
                        tooltip='Restore message(s)'
                        onClick={onBatchChange({deleted: false})}
                        hidden={!dataSource.showArchived}
                    />
                    <dt2.Action
                        icon='markunread'
                        tooltip='Mark message(s) unread'
                        onClick={onBatchChange({read: false})}
                        hidden={dataSource.showArchived}
                    />
                    <dt2.Action
                        icon='drafts'
                        tooltip='Mark message(s) read'
                        onClick={onBatchChange({read: true})}
                        hidden={dataSource.showArchived}
                    />
                    <dt2.Action
                        free
                        onClick={() =>
                            dataSource.setShowArchived(!dataSource.showArchived)
                        }
                        icon={dataSource.showArchived ? 'inbox' : 'folder'}
                        tooltip={
                            dataSource.showArchived
                                ? 'View inbox'
                                : 'View archived messages'
                        }
                    />
                </>
            )}
        </dt2.DataTable>
    );
}
