import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import fp from 'lodash/fp';
import {useState} from 'react';
import {useSelector} from 'react-redux';

import {useApi} from '@arborian/narrf';
import {formatDate} from 'ccm/lib/helpers';
import {selectAllFacilitiesById} from 'ccm/lib/selectors';

export function PatientSelectField({label, fullWidth, field, form, ...props}) {
    const api = useApi();
    const [options, setOptions] = useState([]);
    const [helperText, setHelperText] = useState('');
    // Display validation errors
    const {name} = field;
    const {touched, errors} = form;
    const fieldTouched = fp.get(name, touched);
    const fieldError = fp.get(name, errors);
    const facilities = useSelector(selectAllFacilitiesById);

    const getOptionSelected = (o, val) => {
        return o.id === val.id;
    };

    const renderInput = props => (
        <TextField
            label={label}
            fullWidth={fullWidth}
            error={fieldTouched && !!fieldError}
            helperText={fieldError || helperText}
            {...props}
        />
    );

    const onChange = (ev, value, reason) => {
        setHelperText(formattedPatientLabel(value, facilities));
        form.setFieldValue(field.name, value);
    };

    const handleInputChange = async (ev, value, reason) => {
        if (value.length < 3 || reason !== 'input') return;
        const result = await api.fetchJsonApi(
            api.url_for('patient.PatientCollection'),
            {
                filter: {
                    _q: value,
                    active: true,
                    primary_patient_id: null,
                },
                sort: ['name.last'],
                include: ['facility'],
            },
        );
        setOptions(result.data);
    };

    let allProps = {
        ...field,
        ...props,
        renderInput,
        onChange,
    };
    allProps.value = allProps.value || '';

    return (
        <Autocomplete
            options={options}
            getOptionLabel={fp.getOr('', 'attributes.name.formatted')}
            getOptionSelected={getOptionSelected}
            onInputChange={handleInputChange}
            renderOption={patient => formattedPatientLabel(patient, facilities)}
            {...allProps}
        />
    );
}

function formattedPatientLabel(patient, facilities) {
    if (!patient) return '';
    const name = fp.getOr('', 'attributes.name.formatted', patient);
    const fac = fp.get(patient.attributes.facility_id, facilities);
    const facName = fac ? fac.attributes.label : 'No Facility';
    const dob = fp.get('attributes.dob', patient);
    const dobStr = dob ? formatDate(dob) : 'Unknown';
    return [name, dobStr, facName].join(' / ');
}
