import React from 'react';

import {HeartRateAvatar} from 'ccm/art/MedicalIcons';
import InfoCard from './InfoCard';

export default function ConditionsCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={HeartRateAvatar}
            title='Health Concerns'
            {...props}
        ></InfoCard>
    );
}
