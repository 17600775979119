import React, {useState} from 'react';

import fp from 'lodash/fp';
import {IconButton, TextField as MUITextField} from '@material-ui/core';
import {Check, Close, Edit} from '@material-ui/icons';

import {useResourceActions} from './hooks';

export default function TextField({
    resource,
    field,
    onChanged,
    iconBefore = false,
}) {
    const actions = useResourceActions(resource);
    const [_value, set_value] = useState(null);
    const [editing, setEditing] = useState(false);
    const canEdit = fp.pipe([
        fp.get('meta.allowed_actions'),
        fp.includes('Common.WRITE'),
    ])(resource);
    const value = fp.get(field, resource);

    const handleEdit = ev => {
        set_value(value);
        setEditing(true);
    };
    const handleChange = ev => {
        set_value(ev.target.value);
    };
    const handleSave = async ev => {
        await actions.putField(field, _value);
        setEditing(false);
        onChanged && onChanged();
    };
    const handleCancel = ev => setEditing(false);
    const handleKeyDown = ev => {
        if (ev.key === 'Enter') {
            handleSave(ev);
            ev.preventDefault();
        }
    };

    return editing ? (
        <span>
            <MUITextField
                value={_value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <IconButton onClick={handleSave} size='small'>
                <Check />
            </IconButton>
            <IconButton onClick={handleCancel} size='small'>
                <Close />
            </IconButton>
        </span>
    ) : (
        <span>
            {!iconBefore && value}
            {canEdit && (
                <IconButton onClick={handleEdit} size='small'>
                    <Edit />
                </IconButton>
            )}
            {iconBefore && value}
        </span>
    );
}
