import React from 'react';

import {Tooltip, IconButton} from '@material-ui/core';
import * as I from '@material-ui/icons';

export function Refresh({onClick}) {
    return (
        <Tooltip title='Refresh'>
            <IconButton onClick={onClick}>
                <I.Refresh />
            </IconButton>
        </Tooltip>
    );
}

export function Add({onClick}) {
    return (
        <Tooltip title='Add file'>
            <IconButton onClick={onClick}>
                <I.CloudUpload />
            </IconButton>
        </Tooltip>
    );
}

export function Delete({onClick}) {
    return (
        <Tooltip title='Delete file'>
            <IconButton onClick={onClick}>
                <I.Delete />
            </IconButton>
        </Tooltip>
    );
}
