import React, {useCallback} from 'react';
import fp from 'lodash/fp';

import {
    TextField as MUITextField,
    Select,
    MenuItem,
    InputLabel,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Delete, AddCircleOutline} from '@material-ui/icons';
import {PHONE_TYPES} from 'ccm/constants';

const DEFAULT_CONTACT_POINT = {
    value: 'xxx-xxx-xxxx',
    system: 'phone',
    use: 'work',
};

const useStyles = makeStyles(theme => ({
    contactPointList: {
        margin: theme.spacing(2, 0, 0, 0),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    contactPointType: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
}));

const defaultPhoneOptions = ['office', 'home', 'mobile', 'fax'];

const phoneTypes = fp.pipe([fp.map(it => [it.id, it]), fp.fromPairs])(
    PHONE_TYPES,
);

function ContactPoint({value, readOnly, onChange, onDelete, phoneOptions}) {
    const classes = useStyles();
    const handleChangeValue = ev => {
        const newValue = fp.set('value', ev.target.value, value);
        onChange(newValue);
    };
    const handleChangeType = ev => {
        const {system, use} = phoneTypes[ev.target.value];
        const newValue = {...value, system, use};
        onChange(newValue);
    };
    const handleDelete = () => onDelete(value);
    const type = fp.find(
        it => it.system === value.system && it.use === value.use,
        PHONE_TYPES,
    );
    const options = fp.map(
        option => (
            <MenuItem key={option} value={option}>
                {phoneTypes[option].label}
            </MenuItem>
        ),
        phoneOptions,
    );

    return (
        <div>
            <MUITextField
                value={fp.get('value', value)}
                onChange={handleChangeValue}
                InputProps={{readOnly}}
            />
            <Select
                className={classes.contactPointType}
                value={type.id}
                onChange={handleChangeType}
                inputProps={{readOnly}}
            >
                {options}
            </Select>
            {readOnly ? null : (
                <IconButton onClick={handleDelete}>
                    <Delete />
                </IconButton>
            )}
        </div>
    );
}

export default function TelecomField({
    field,
    form, // From Formik
    label,
    readOnly = false,
    phoneOptions = defaultPhoneOptions,
    defaultContactPoint = DEFAULT_CONTACT_POINT,
}) {
    const classes = useStyles();
    const defaultValue = [];
    let value = field.value || defaultValue;

    const addContactPoint = useCallback(() => {
        const id = '' + Math.random();
        const newValue = [...value, {id, ...defaultContactPoint}];
        console.log('Add contact', newValue);
        form.setFieldValue(field.name, newValue);
    }, [form, field, value, defaultContactPoint]);

    const handleChange = useCallback(
        itemValue => {
            let index = fp.findIndex(it => it.id === itemValue.id, value);
            let newValue = fp.set(index, itemValue, value);
            form.setFieldValue(field.name, newValue);
        },
        [form, field, value],
    );

    const handleDelete = useCallback(
        itemValue => {
            let newValue = fp.remove(item => item.id === itemValue.id, value);
            form.setFieldValue(field.name, newValue);
        },
        [form, field, value],
    );

    return (
        <div>
            <InputLabel shrink>{label}</InputLabel>
            <div className={classes.contactPointList}>
                {fp.map(
                    item => (
                        <ContactPoint
                            key={item.id}
                            value={item}
                            onChange={handleChange}
                            onDelete={handleDelete}
                            phoneOptions={phoneOptions}
                            readOnly={readOnly}
                        />
                    ),
                    value,
                )}
                {!readOnly ? (
                    <IconButton onClick={addContactPoint}>
                        <AddCircleOutline />
                    </IconButton>
                ) : null}
            </div>
        </div>
    );
}
