import Tour from './tour_base';

const TourIndivTick = new Tour({
    id: 'indivTick',
    label: 'Tour the Ticket Page',
    location_context: /ticket\/([0-9a-f]+)$/,
    group_context: /.*/,
    steps: [
        {
            content: () => (
                <>
                    <p>
                        You have the option to edit any image
                        attachment to a ticket. {' '}
                        <a
                            href='https://samepagemd.zendesk.com/hc/en-us/articles/13399646285331'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Click here to learn more!
                        </a>
                    </p>
                </>
            ),
        },
        {
            selector: '#',
            content: `
            
            `,
        },
    ],
});
export default TourIndivTick;