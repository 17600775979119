import React, {useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';
import * as h from 'ccm/lib/helpers';
import {url_for} from 'ccm/routes';

import {
    selectPracticeLookup,
    selectFacilityLookup,
} from 'ccm/lib/selectors-ccm';
import BasePage from 'ccm/components/BasePage';

const {
    DataTable,
    Column,
    Action,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

export default function CommunicatePage() {
    const tableRef = useRef();
    const api = useApi();
    const hashPrefix = 'ptTable.';
    const lookupPractice = useSelector(selectPracticeLookup);
    const lookupFacility = useSelector(selectFacilityLookup);
    const history = useHistory();

    useEffect(() => {
        api.fetchAllJsonApi(api.url_for('facility.FacilityCollection'));
        api.fetchAllJsonApi(api.url_for('practice.PracticeCollection'));
    }, [api]);

    const defaultFetchOptions = {
        filter: {'attributes.active': true},
        sort: {field: 'attributes.name.last'},
        page: {size: 20},
    };
    const fetchOptions = useLocationFetchOptions(
        defaultFetchOptions,
        hashPrefix,
    );
    const onChangeFetchOptions = o => {
        const newParams = updateLocationFetchOptions(o, hashPrefix);
        history.push('?' + newParams.toString());
    };

    const fetchPatients = useCallback(
        async fetchOptions => {
            const url = api.url_for('patient.PatientCollection');
            const filter = fp.get('filter', fetchOptions);
            const facility_id = fp.get(
                ['filter', 'relationships.facility.data.id'],
                fetchOptions,
            );
            const practice_id = fp.get(
                ['filter', 'relationships.practice.data.id'],
                fetchOptions,
            );
            if (facility_id) {
                fetchOptions = fp.set(
                    ['filter', 'relationships.facility.data.id'],
                    facility_id,
                    fetchOptions,
                );
            }
            if (practice_id) {
                fetchOptions = fp.set(
                    ['filter', 'relationships.practice.data.id'],
                    practice_id,
                    fetchOptions,
                );
            }
            console.log('FetchOptions', {
                fetchOptions,
                filter,
                facility_id,
                practice_id,
            });
            const result = await api.fetchDataTable(url, fetchOptions);
            // Filter out patients without practices
            const filteredResult = fp.filter(
                p => fp.get('relationships.practice.data.id', p),
                result.rows,
            );
            return {
                rows: filteredResult,
                count: filteredResult.length,
            };
        },
        [api],
    );

    const newSession = async (_, row) => {
        const url = api.url_for('ticketsession.TicketSessionCollection');
        const session = await api.fetchJsonApi(url, {
            method: 'POST',
            json: {data: row.data},
        });
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        history.push(enterFormFlow);
    };

    const refresh = useCallback(() => tableRef.current.fetch(), [tableRef]);

    return (
        <BasePage wide>
            <DataTable
                title='Select a Patient'
                size='small'
                data={fetchPatients}
                tableRef={tableRef}
                fetchOptions={fetchOptions}
                onChangeFetchOptions={onChangeFetchOptions}
                options={{
                    search: true,
                    filtering: true,
                    // actionsColumnIndex: -1,  // bug with datatable filters
                }}
            >
                <Column
                    title='Full Name'
                    editable={false}
                    filtering={false}
                    field='attributes.name.formatted'
                />
                <Column
                    title='Last'
                    editable={false}
                    field='attributes.name.last'
                />
                <Column
                    title='First'
                    editable={false}
                    field='attributes.name.first'
                />
                <Column
                    title='DOB'
                    type='date'
                    editable={false}
                    field='attributes.dob'
                    render={row =>
                        h.formatDateOnly(fp.get('data.attributes.dob', row))
                    }
                />
                <Column
                    title='Facility'
                    editable={false}
                    field='relationships.facility.data.id'
                    lookup={lookupFacility}
                />
                <Column
                    title='Practice'
                    field='relationships.practice.data.id'
                    lookup={lookupPractice}
                />
                <Action
                    name='refresh'
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
                <Action
                    onClick={newSession}
                    tooltip='New Ticket'
                    icon='post_add'
                />
            </DataTable>
        </BasePage>
    );
}
