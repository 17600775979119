import React, {useState} from 'react';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';
import {Button} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import CarePlanTemplateForm from 'ccm/components/forms/CarePlanTemplateForm';

const {dt2} = components;

const CPT_TABLE_OPTIONS = {
    filtering: false,
    search: true,
};

function CPTList({value, title, emptyText}) {
    return (
        <>
            <strong>{title}</strong>
            <br />
            {fp.isEmpty(value) ? (
                <>
                    {emptyText}
                    <br />
                </>
            ) : (
                <ul>
                    {fp.map(
                        it => (
                            <li key={it}>{it}</li>
                        ),
                        value,
                    )}
                </ul>
            )}
        </>
    );
}

function CarePlanTemplateDisplay({value}) {
    return (
        <div>
            <CPTList
                title='Goals'
                emptyText='No goals defined'
                value={fp.get('attributes.goals', value)}
            />
            <CPTList
                title='Interventions'
                emptyText='No interventions defined'
                value={fp.get('attributes.interventions', value)}
            />
        </div>
    );
}

function CarePlanTemplateDetail({value, editable}) {
    const api = useApi();
    const [editing, setEditing] = useState(false);
    const handleSubmit = async data => {
        await api.fetchJson(data.links.self, {
            method: 'PATCH',
            json: {data},
        });
        setEditing(false);
        return true;
    };
    return (
        <div>
            <strong>Focus</strong>: {fp.get('attributes.description', value)}
            <br />
            {editable &&
                (editing ? (
                    <Button
                        id='focusCancelBtn'
                        variant='contained'
                        onClick={() => setEditing(false)}
                    >
                        Cancel
                    </Button>
                ) : (
                    <Button
                        id='focusEditBtn'
                        variant='outlined'
                        onClick={ev => setEditing(true)}
                    >
                        <Edit /> Edit
                    </Button>
                ))}
            {editing ? (
                <CarePlanTemplateForm
                    value={value}
                    onCancel={() => setEditing(false)}
                    onSubmit={handleSubmit}
                />
            ) : (
                <CarePlanTemplateDisplay value={value} />
            )}
        </div>
    );
}

export default function CarePlanTemplateTable({
    dataSource,
    editable,
    children,
    ...props
}) {
    return (
        <dt2.DataTable
            id='CarePlanTemplateTable'
            size='small'
            dataSource={dataSource}
            editable={editable}
            options={CPT_TABLE_OPTIONS}
            {...props}
        >
            <dt2.Column title='Description' field='attributes.description' />
            <dt2.Action
                id='CarePlanTemplateTableRefreshBtn'
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            <dt2.DetailPanel
                tooltip='Details'
                render={row => (
                    <CarePlanTemplateDetail
                        value={row.data}
                        editable={editable}
                    />
                )}
            />
            {children}
        </dt2.DataTable>
    );
}
