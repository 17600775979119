import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import TelecomField from 'ccm/components/TelecomField';

const useStyles = makeStyles(theme => ({
    cityField: {
        maxWidth: theme.spacing(40),
    },
    stateField: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
    postalCodeField: {
        marginLeft: theme.spacing(1),
        width: theme.spacing(10),
    },
}));

const defaultOptions = {
    title: 'Edit Contact',
    submitLabel: 'Save',
};

export default function DirectoryContactDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const [options, setOptions] = useState(defaultOptions);
    const classes = useStyles();

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (initialValues, options = defaultOptions) => {
        return new Promise((resolve, reject) => {
            setInitialValues(initialValues);
            setOptions(options);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <Field
                                component={TextField}
                                label='Pharmacy Name'
                                name='name'
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                label='Street Address'
                                name='address.line1'
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                label=''
                                name='address.line2'
                                fullWidth
                            />
                            <div>
                                <Field
                                    component={TextField}
                                    label='City'
                                    name='address.city'
                                    className={classes.cityField}
                                />
                                <Field
                                    component={TextField}
                                    label='State'
                                    name='address.state'
                                    className={classes.stateField}
                                />
                                <Field
                                    component={TextField}
                                    label='Zip'
                                    name='address.postalCode'
                                    className={classes.postalCodeField}
                                />
                            </div>
                            <Field
                                component={TelecomField}
                                label='Telecom'
                                name='telecom'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
