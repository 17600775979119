import React, {useMemo} from 'react';
import fp from 'lodash/fp';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {makeStyles, IconButton} from '@material-ui/core';
import {Menu, MenuItem} from '@material-ui/core';
import {Chip} from '@material-ui/core';
import {AddCircleOutline} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import PatientAvatar from 'ccm/components/PatientAvatar';
import {selectParticipantsForPatient} from './ThreadParticipantsSelectField';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export function PatientNameColumnComponent({row}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data.thread));
    const href = url_for('patient', {patientId: rel.patient.id});
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Link to={href}>
                <PatientAvatar patient={rel.patient} />
            </Link>

            <Link to={href}>
                {fp.get('patient.attributes.name.formatted', rel)}
            </Link>
        </div>
    );
}

export function ParticipantsColumnComponent({row, handleAddParticipant}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data.thread));
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            {fp.map(
                user => (
                    <Chip
                        label={fp.get('attributes.name', user)}
                        key={user.id}
                    />
                ),
                rel.participants,
            )}
            <IconButton onClick={handleClick}>
                <AddCircleOutline />
            </IconButton>
            <SelectParticipantsMenu
                row={row}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleSelect={handleAddParticipant}
            />
        </div>
    );
}

const SelectParticipantsMenu = ({row, anchorEl, handleClose, handleSelect}) => {
    const patient_id = fp.get('data.thread.relationships.patient.data.id', row);
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patient_id]),
    );
    const practiceId = fp.get('relationships.practice.data.id', patient);
    const facilityId = fp.get('relationships.facility.data.id', patient);
    const selectAvailableParticipants = useMemo(
        () => selectParticipantsForPatient(practiceId, facilityId),
        [practiceId, facilityId],
    );
    const participants = useSelector(selectAvailableParticipants);

    const handleClick = (row, participantId) => {
        handleSelect(row, participantId);
        handleClose();
    };
    const menuItemHeight = 48;
    const numVisibleItems = 5.5;

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
                // Enable item scrolling
                style: {
                    maxHeight: menuItemHeight * numVisibleItems,
                },
            }}
        >
            {fp.map(
                ppt => (
                    <MenuItem
                        id={ppt.id}
                        onClick={() => handleClick(row.data.thread, ppt.id)}
                    >
                        {ppt.attributes.name}
                    </MenuItem>
                ),
                participants,
            )}
        </Menu>
    );
};
