import React from 'react';
import {useHistory} from 'react-router-dom';
import {Avatar, IconButton} from '@material-ui/core';
import fp from 'lodash/fp';

import {url_for} from 'ccm/routes';

export default function PatientAvatar({patient}) {
    const history = useHistory();
    const handleClick = () => {
        history.push(url_for('patient', {patientId: patient.id}));
    };
    return (
        <IconButton onClick={handleClick}>
            <Avatar src={fp.get('links.photo_url', patient)} />
        </IconButton>
    );
}
