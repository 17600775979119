import React, {useState} from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@material-ui/core';

import ShiftAssignmentsTable from './ShiftAssignmentsTable';
import Provider from './provider';

export default function ShiftAssignmentsDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ruleId, setRuleId] = useState(null);
    const [facilities, setFacilities] = useState(null);

    console.log('DILOG', {
        ruleId,
        facilities,
    });

    const handleClose = () => {
        setResolve(null);
        setRuleId(null);
        setFacilities(null);
    };

    dialogRef.current = ({rule, facilities}) => {
        console.log({rule, facilities});
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
            setRuleId(rule.id);
            setFacilities(facilities);
        });
    };

    return (
        <Provider ruleId={ruleId} facilities={facilities}>
            <Dialog
                id='shift-assignment-dialog'
                open={!!resolve}
                onClose={handleClose}
                maxWidth='lg'
                fullWidth
            >
                <DialogTitle>Shift Assignments</DialogTitle>
                <DialogContent>
                    <ShiftAssignmentsTable />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='primary'
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Provider>
    );
}
