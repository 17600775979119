import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import {useObservations} from 'ccm/dataSource';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

const renderValue = rowData => {
    console.log({rowData});
    let parts = [];
    let value = fp.get('data.attributes.value.value', rowData);
    let systolicValue = fp.get('data.attributes.value.systolicValue', rowData);
    let diastolicValue = fp.get('data.attributes.value.diastolicValue', rowData);
    let unit = fp.get('data.attributes.value.unit', rowData);

    if (value) {
        parts.push(value);
    }
    if (systolicValue) {
        parts.push(systolicValue);
        parts.push('/');
        parts.push(diastolicValue);
    }
    if (unit) {
        parts.push(unit);
    }
    return <>{parts.join(' ')}</>
}

function Recorded({value}) {
    const recordedBy = fp.get('data.attributes.performer.display', value);
    const recordedDate = fp.get('data.attributes.effectiveDateTime', value);
    return (
        <>
            by {recordedBy} at <DateTime value={recordedDate} />
        </>
    );
}

export default function ObservationTable({patient, title, ...props}) {
    const ds = useObservations(patient);
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column title='Type' field='attributes.code.text' />
            <dt2.Column
                title='Value'
                field='attributes.value'
                render={renderValue}
            />
            <dt2.Column
                title='Recorded'
                field='attributes.effectiveDateTime'
                type='date'
                render={row => (
                    <Recorded
                        value={row}
                    />
                )}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
