import React, {useState} from 'react';

import fp from 'lodash/fp';
import {Switch} from '@material-ui/core';

import {useResourceActions} from './hooks';

export default function SwitchField({
    resource,
    field,
    onChanged,
    onValue = true,
    offValue = false,
}) {
    const actions = useResourceActions(resource);
    const value = fp.get(field, resource) === onValue;
    const [_value, set_value] = useState(value);
    const canEdit = fp.pipe([
        fp.get('meta.allowed_actions'),
        fp.includes('Common.WRITE'),
    ])(resource);

    const handleToggle = ev => {
        const newValue = !_value;
        set_value(newValue);
        handleUpdate(newValue);
    };

    const handleUpdate = async newValue => {
        const update = newValue ? onValue : offValue;
        await actions.putField(field, update);
        onChanged && onChanged();
    };
    return (
        <span>
            <Switch
                checked={_value}
                onChange={handleToggle}
                disabled={!canEdit}
                color='primary'
            />
        </span>
    );
}
