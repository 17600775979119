import React from 'react';

import {Paper, Table, TableContainer, makeStyles} from '@material-ui/core';

import Provider from './Provider';
import DTableHead from './DTableHead';
import DTableBody from './DTableBody';
import DTableFooter from './DTableFooter';
import DTableToolbar from './DTableToolbar';

const useStyles = makeStyles(theme => ({
    tableData: {
        overflowX: 'auto',
    },
    container: {
        padding: 0,
        '& h6': {
            margin: 0,
        },
    },
}));

export default function DataTable({
    dataSource,
    title,
    children,
    options = {},
    tableRef = null,
    editable,
    id = undefined,
    ...props
}) {
    const classes = useStyles();
    return (
        <Provider
            dataSource={dataSource}
            options={options}
            tableRef={tableRef}
            editable={editable}
            id={id}
        >
            {children}
            <TableContainer component={Paper} className={classes.container}>
                <DTableToolbar title={title} />
                <div className={classes.tableData}>
                    <Table id={id} {...props}>
                        <DTableHead />
                        <DTableBody />
                    </Table>
                </div>
                <Table {...props}>
                    <DTableFooter />
                </Table>
            </TableContainer>
        </Provider>
    );
}
