import React, {
    createContext,
    useEffect,
    useContext,
    useState,
    useMemo,
} from 'react';
import fp from 'lodash/fp';

import TableManager from './tableManager';

const Context = createContext();

export const useTableManager = () => useContext(Context);

export default function Provider({
    children,
    data,
    options = {},
    editable = {},
    tableRef = null,
    initialFetchOptions, // for uncontrolled
    fetchOptions,
    onChangeFetchOptions, // for controlled
}) {
    options = fp.merge(
        {
            filtering: false,
            sorting: true,
            paging: true,
            selection: false,
            search: false,
            pageSizeOptions: [5, 10, 20],
            actionsColumnIndex: 0,
        },
        options,
    );

    const [_fetchOptions, _setFetchOptions] = useState(
        initialFetchOptions || {},
    );
    let setFetchOptions = onChangeFetchOptions;
    if (!fetchOptions) {
        fetchOptions = _fetchOptions;
        setFetchOptions = _setFetchOptions;
    }

    const [columns, setColumns] = useState({});
    const [actions, setActions] = useState({});
    const [detailPanels, setDetailPanels] = useState({}); // TODO
    const [editState, setEditState] = useState({op: null, row: null});

    const [state, setState] = useState({
        state: 'init',
        error: null,
        rows: [],
        count: 0,
    });

    const tm = useMemo(
        () =>
            new TableManager({
                data,
                options,
                editable,
                fetchOptions,
                setFetchOptions,
                columns,
                setColumns,
                actions,
                setActions,
                detailPanels,
                setDetailPanels,
                state,
                setState,
                editState,
                setEditState,
            }),
        [
            data,
            options,
            editable,
            fetchOptions,
            setFetchOptions,
            columns,
            setColumns,
            actions,
            setActions,
            detailPanels,
            setDetailPanels,
            state,
            setState,
            editState,
            setEditState,
        ],
    );
    useEffect(() => {
        tm.fetch();
    }, [fetchOptions]); // eslint-disable-line react-hooks/exhaustive-deps
    if (tableRef) {
        tableRef.current = tm;
    }
    return <Context.Provider value={tm}>{children}</Context.Provider>;
}
