import React from 'react';

import {Tooltip, IconButton} from '@material-ui/core';

import {List, ViewModule} from '@material-ui/icons';

export default function ListGridSelector({value, onChange}) {
    const toggle = () => {
        if (value === 'grid') {
            onChange('list');
        } else {
            onChange('grid');
        }
    };
    const title = value === 'grid' ? 'View as table' : 'View as grid';
    return (
        <Tooltip title={title}>
            <IconButton onClick={onChange && toggle}>
                {value === 'grid' ? <List /> : <ViewModule />}
            </IconButton>
        </Tooltip>
    );
}
