import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Chip,
    Checkbox,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    FormLabel,
    FormControlLabel,
    Grid,
    makeStyles,
} from '@material-ui/core';
import {Add, Close} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiDialogTitle-root h2': {
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
        },
    },
}));

function ProgressNotesDialog({open, onClose, value, noteTypes, onChange}) {
    const classes = useStyles();
    const optionSelected = o => fp.includes(o, value);
    const handleChange = o => ev => {
        if (ev.target.checked) {
            onChange([...value, o]);
        } else {
            onChange(fp.without([o], value));
        }
    };
    const handleChangeAll = ev => {
        if (ev.target.checked) {
            onChange(noteTypes);
        } else {
            onChange([]);
        }
    };
    const allChecked = fp.size(value) === fp.size(noteTypes);
    const someChecked = fp.size(value) > 0 && !allChecked;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialog}
            maxWidth='md'
            fullWidth
        >
            <DialogTitle onClose={onClose}>
                <span>Select progress note type(s)</span>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allChecked}
                            onChange={handleChangeAll}
                            indeterminate={someChecked}
                        />
                    }
                    label='Check/uncheck all'
                />
                <Grid container>
                    {fp.map(
                        o => (
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={optionSelected(o)}
                                            onChange={handleChange(o)}
                                        />
                                    }
                                    label={o}
                                />
                            </Grid>
                        ),
                        noteTypes,
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default function PCCProgressNoteTypesField({
    form,
    field,
    noteTypes,
    label,
    ...props
}) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleDelete = o => {
        console.log('delete', o, 'from', field.value);
        const newValue = fp.without([o], field.value);
        form.setFieldValue(field.name, newValue);
    };

    const handleChange = newValue => {
        form.setFieldValue(field.name, newValue);
    };

    return (
        <FormControl control='fieldset'>
            <FormLabel control='legend'>{label}</FormLabel>
            <ProgressNotesDialog
                open={open}
                onClose={() => setOpen(false)}
                noteTypes={noteTypes}
                value={field.value}
                onChange={handleChange}
            />
            {fp.map(
                v => (
                    <Chip key={v} label={v} onDelete={ev => handleDelete(v)} />
                ),
                field.value,
            )}
            <IconButton onClick={handleOpen}>
                <Add />
            </IconButton>
        </FormControl>
    );
}
