// TODO: refactor
import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';
import {ducks} from '@arborian/narrf';

const selectPFData = (type, sort) => ehrConnectionId =>
    createSelector(ducks.jsonapi.selectObject(type), objs => {
        let result = fp.filter(
            obj =>
                fp.get('relationships.ehr_connection.data.id', obj) ===
                ehrConnectionId,
            objs,
        );
        if (sort) {
            result = fp.sortBy(sort, result);
        }
        return result;
    });

export const selectTranscriptTypes = selectPFData(
    'PFTranscriptType',
    'attributes.data.transcriptTypeName',
);
export const selectFacilities = selectPFData(
    'PFFacility',
    'attributes.data.name',
);
export const selectEncounterTypes = selectPFData(
    'PFEncounterType',
    'attributes.data.displayOrder',
);
export const selectProviders = selectPFData(
    'PFProvider',
    'attributes.data.providerName',
);
export const selectTemplates = selectPFData(
    'PFTemplate',
    'attributes.data.name',
);

export const selectPatientsForVisits = (visits, ehr_id) =>
    createSelector(ducks.jsonapi.selectObject('Patient'), patients => {
        const primaryPatients = fp.pipe([
            fp.map('relationships.patient.data.id'),
            fp.map(pt_id => patients[pt_id]),
        ])(visits);
        const secondaryPatients = fp.pipe([
            fp.map('relationships.secondary_patients.data'),
            fp.flatten,
            fp.map('id'),
            fp.map(pt_id => patients[pt_id]),
        ])(visits);
        const allPatients = fp.concat(primaryPatients, secondaryPatients);
        console.log({
            ehr_id,
            primaryPatients,
            secondaryPatients,
            allPatients,
        });
        const result = fp.pipe([
            fp.filter(
                pt =>
                    ehr_id ===
                    fp.get('relationships.ehr_connection.data.id', pt),
            ),
            fp.uniq,
        ])(allPatients);
        return result;
    });

export const selectPatientForVisit = (visit, ehrConnectionId) =>
    createSelector(ducks.jsonapi.selectObject('Patient'), patients => {
        const primaryPatientId = fp.pipe([
            fp.get('relationships.patient.data.id'),
        ])(visit);
        const secondaryPatientIds = fp.pipe([
            fp.get('relationships.secondary_patients.data'),
            fp.map('id'),
        ])(visit);
        const allPatientIds = [primaryPatientId, ...secondaryPatientIds];
        const allPatients = fp.map(pid => patients[pid], allPatientIds);
        const result = fp.find(
            pt =>
                ehrConnectionId ===
                fp.get('relationships.ehr_connection.data.id', pt),
            allPatients,
        );
        return result;
    });
