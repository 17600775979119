import React, {useState, useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useSnackbar} from 'notistack';
import {TextField as MUITextField} from '@material-ui/core';

import {useApi} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';
import TaskLogDialog from 'ccm/components/dialogs/TaskLogDialog';

import PatientPaster from './PatientPaster';
import NotePatientsTable from './NotePatientsTable';
import NotePrepForm from './NotePrepForm';

import {selectPatientsForVisits} from './selectors';

export default function PFNotePrep({practice, ehr, visits}) {
    const api = useApi();
    const [patients, setPatients] = useState();
    const [defaultChiefComplaint, setDefaultChiefComplaint] = useState(
        'CCM and Case Review',
    );
    const ehrId = ehr?.id;
    const selector = useMemo(
        () => selectPatientsForVisits(visits, ehrId),
        [visits, ehrId],
    );
    const visitPatients = useSelector(selector);
    const {enqueueSnackbar} = useSnackbar();
    const taskLogDialog = useDialog(TaskLogDialog);

    useEffect(() => {
        const notePatients = fp.map(
            p => ({
                id: fp.get('attributes.raw.patientPracticeGuid', p),
                name: fp.get('attributes.name.formatted', p),
                dob: fp.get('attributes.dob', p),
                chiefComplaint: defaultChiefComplaint,
            }),
            visitPatients,
        );
        console.log('Set patients', {visitPatients, notePatients});
        if (notePatients.length) {
            setPatients(notePatients);
        }
    }, [api, defaultChiefComplaint, visitPatients]);

    const handleSubmit = async values => {
        const url = api.url_for('pf_plugin.NotePrep', {
            ehr_connection_id: ehr.id,
        });
        console.log('Noteprep with', {patients, values});
        try {
            const resp = await api.fetchJson(url, {
                method: 'POST',
                json: {
                    data: {
                        type: 'NotePrepBatch',
                        attributes: {
                            patients: fp.map(
                                fp.pick(['id', 'name', 'chiefComplaint']),
                                patients,
                            ),
                            ...values,
                        },
                    },
                },
            });
            console.log('Response was', resp);
            await taskLogDialog(resp.data);
        } catch (e) {
            let detail = e.toString();
            try {
                const text = await e.response.text();
                if (text) detail = text;
            } catch (ee) {
                console.error('Error getting error text');
            }
            console.error('Error creating notes');
            enqueueSnackbar(`Unable to create notes: ${detail}`, {
                perist: true,
                variant: 'error',
            });
        }
    };

    return (
        <div>
            <h4>Mass note-preparation bot</h4>
            <MUITextField
                value={defaultChiefComplaint}
                onChange={e => setDefaultChiefComplaint(e.target.value)}
                fullWidth
                label='(Default) Chief Complaint'
            />
            {!visits && (
                <PatientPaster
                    ehr={ehr}
                    value={patients}
                    onChange={setPatients}
                    defaultChiefComplaint={defaultChiefComplaint}
                />
            )}
            {!fp.isEmpty(patients) && (
                <div>
                    <NotePatientsTable
                        patients={patients}
                        setPatients={setPatients}
                        defaultChiefComplaint={defaultChiefComplaint}
                    />
                    <NotePrepForm
                        ehrConnectionId={ehr?.id}
                        onSubmit={handleSubmit}
                    />
                </div>
            )}
        </div>
    );
}
