import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import fp from 'lodash/fp';
import {
    Button,
    IconButton,
    Card,
    CardHeader,
    CardContent,
    makeStyles,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import {Event, Edit} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';

import {useApi, ducks} from '@arborian/narrf';
import {Date as DateComponent} from 'ccm/components/DateTime';

const useStyles = makeStyles(theme => ({
    blue: {
        color: theme.palette.cf.blue,
    },
    cardContent: {
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
}));

function DischargeDateDialog({
    patient,
    artifact,
    open,
    onClose,
    onSave,
    onDelete,
}) {
    const api = useApi();
    const dispatch = useDispatch();

    const handleSubmit = async values => {
        console.log('Submitting', values);
        if (artifact) {
            const data = fp.set('attributes', values, artifact);
            await api.fetchJsonApi(artifact.links.self, {
                method: 'PATCH',
                json: {data},
            });
        } else {
            await api.fetchJsonApi(
                patient.relationships.discharge_date.links.related,
                {
                    method: 'POST',
                    json: {
                        data: {
                            type: 'DischargeDate',
                            attributes: values,
                        },
                    },
                },
            );
        }
        onClose();
        return true;
    };

    const handleDelete = async () => {
        await api.fetchJsonApi(artifact.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(artifact));
        onClose();
        return true;
    };

    const initialValues = fp.getOr(
        {discharge_date: new Date()},
        'attributes',
        artifact,
    );

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <DialogContent>
                            <Field
                                component={KeyboardDatePicker}
                                label='Set Estimated Discharge Date'
                                name='discharge_date'
                                format='yyyy-MM-dd'
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Cancel</Button>
                            {artifact && (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            )}
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={formikProps.handleSubmit}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default function DischargeDateCard({patient, artifact}) {
    console.log({artifact});
    const classes = useStyles({});
    const [editing, setEditing] = useState(false);
    const dischargeDate = artifact ? (
        <DateComponent value={artifact.attributes.discharge_date} />
    ) : (
        'Not set'
    );

    const canWritePhi = fp.includes(
        'Patient.WRITE_PHI',
        fp.get('meta.allowed_actions', patient),
    );

    if (!artifact && !canWritePhi) return null;
    return (
        <>
            <DischargeDateDialog
                patient={patient}
                open={editing}
                artifact={artifact}
                onClose={ev => setEditing(false)}
            />
            <Card>
                <CardHeader
                    avatar={<Event color='primary' />}
                    disableTypography
                    title={<h6>{dischargeDate}</h6>}
                    className={classes.blue}
                    action={
                        canWritePhi && (
                            <IconButton
                                className={classes.blue}
                                onClick={ev => setEditing(true)}
                            >
                                <Edit />
                            </IconButton>
                        )
                    }
                />
                <CardContent className={classes.cardContent}>
                    <h6>Estimated Discharge Date</h6>
                </CardContent>
            </Card>
        </>
    );
}
