import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Button, makeStyles} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import {MESSAGE_TYPES} from 'ccm/constants';
import {DateTime} from 'ccm/components/DateTime';
import {useActions} from 'ccm/lib/hooks';

const useStyles = makeStyles(theme => ({
    message: {
        whiteSpace: 'pre-wrap',
    },
}));

const lookupMessageTypes = fp.pipe([
    fp.map(o => [o.value, o.label]),
    fp.fromPairs,
])(MESSAGE_TYPES);

export default function MessageDetailPanel({
    message,
    messageToolbar,
    messageActions,
}) {
    const actions = useActions();
    const classes = useStyles();
    const typeId = fp.get('attributes.type', message);
    const typeLabel = fp.get(typeId, lookupMessageTypes);
    const rel = useSelector(ducks.jsonapi.selectRelated(message));
    return (
        <div>
            {messageToolbar}
            <strong>Type:</strong> {typeLabel}
            <br />
            <strong>Sender:</strong> {fp.get('attributes.from_', message)}
            <br />
            <strong>Subject:</strong> {fp.get('attributes.subject', message)}
            <br />
            <strong>Sent:</strong>{' '}
            <DateTime value={fp.get('attributes.timestamp', message)} />
            <br />
            <p className={classes.message}>
                {fp.get('attributes.text', message)}
            </p>
            {messageActions}
            <Button
                variant='contained'
                color='primary'
                onClick={ev =>
                    actions.createQuestionFlowTicket(rel.patient, message)
                }
            >
                Ask a question
            </Button>
        </div>
    );
}
