import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

export function useResourceActions(resource) {
    const api = useApi();
    return {
        putField: (field, value) => {
            const data = fp.set(field, value, resource);
            return api.fetchJson(fp.get('links.self', resource), {
                method: 'PUT',
                json: {data},
            });
        },
    };
}
