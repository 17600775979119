import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {useApi, ducks, components} from '@arborian/narrf';

import {useListField} from 'ccm/dataSource';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    root: {
        '& h6': {
            ...theme.typography.h6,
            margin: 0,
        },
    },
}));

function SimpleList({value, title, emptyText}) {
    return (
        <>
            <strong>{title}</strong>
            <br />
            {fp.isEmpty(value) ? (
                <>
                    {emptyText}
                    <br />
                </>
            ) : (
                <ul>
                    {fp.map(
                        it => (
                            <li key={it.description}>{it.description}</li>
                        ),
                        value,
                    )}
                </ul>
            )}
        </>
    );
}

function FocusDisplay({value}) {
    return (
        <div>
            <SimpleList
                title='Goals'
                emptyText='No goals defined'
                value={fp.get('goals', value)}
            />
            <SimpleList
                title='Interventions'
                emptyText='No interventions defined'
                value={fp.get('interventions', value)}
            />
        </div>
    );
}
export default function NewFocusDialog({carePlan, actions, open, onClose}) {
    const api = useApi();
    const classes = useStyles();
    const patient = useSelector(
        ducks.jsonapi.selectObject([
            fp.get('relationships.patient.data.type', carePlan),
            fp.get('relationships.patient.data.id', carePlan),
        ]),
    );
    const [focuses, setFocuses] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const dsFocuses = useListField(focuses);

    const fetchProto = async () => {
        try {
            const resp = await api.fetchJson(
                fp.get('relationships.protoPlan.links.related', patient),
                {
                    method: 'POST',
                },
            );
            const newFocuses = fp.pipe([
                fp.get('data.attributes.focuses'),
                fp.map(f => ({id: Math.random(), ...f})),
            ])(resp);
            setFocuses(newFocuses);
        } catch (e) {
            enqueueSnackbar('Error creating proto-plan', {
                persist: false,
                variant: 'error',
            });
            setFocuses(null);
        }
    };

    useEffect(() => {
        if (patient && open && !focuses) {
            fetchProto();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, patient, open]);

    const handleClose = ev => {
        setFocuses(null);
        onClose(ev);
    };

    const handleAdd = async (ev, rows) => {
        const templates = fp.map(
            row => ({
                attributes: {
                    description: row.data.description,
                    goals: fp.map('description', row.data.goals),
                    interventions: fp.map(
                        'description',
                        row.data.interventions,
                    ),
                },
            }),
            rows,
        );
        await actions.addFocuses(templates);
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='xl'
            className={classes.root}
        >
            <DialogTitle>Add focus(es)</DialogTitle>
            <DialogContent>
                <dt2.DataTable
                    size='small'
                    dataSource={dsFocuses}
                    options={{selection: true}}
                >
                    <dt2.Column title='Description' field='description' />
                    <dt2.Column
                        title='Source'
                        render={row =>
                            fp.get('data.shared', row) && (
                                <Chip label='shared' />
                            )
                        }
                    />
                    <dt2.DetailPanel
                        tooltip='Details'
                        render={row => <FocusDisplay value={row.data} />}
                    />
                    <dt2.Action
                        tooltip='Add to care plan'
                        icon='add'
                        onClick={handleAdd}
                    />
                    <dt2.Action
                        name='refresh'
                        free
                        onClick={() => fetchProto()}
                        tooltip='Refresh'
                        icon='refresh'
                    />
                </dt2.DataTable>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
