import Tour from './tour_base';

const TourAttachPage = new Tour({
    id: 'attachPage',
    label: 'Tour the Attachments Page',
    location_context: /ticketSession\/([-0-9A-z_]+)\/form\/.*\/page\/999uploadAttachment$/,
    group_context: /.*/,
    steps: [
        {
            content: () => (
                <>
                    <p>
                        Once you upload an image, you have the
                        option to edit it. {' '}
                        <a
                            href='https://samepagemd.zendesk.com/hc/en-us/articles/13399646285331'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Click here to learn more!
                        </a>
                    </p>
                </>
            ),
        },
    ],
});
export default TourAttachPage;