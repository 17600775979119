import {useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';

const {useQueryFetchOptions, useDataActions, useReduxDataSource} = components;

const defaultFetchOptions = {
    sort: {field: 'attributes.name.last'},
    page: {number: 0, size: 20},
};

const defaultOptions = {};

export default function usePatientMappingDataSource({
    ehr,
    prefix = 'ptMap.',
    options = defaultOptions,
}) {
    const api = useApi();
    const history = useHistory();
    const [fetchOptions, setFetchOptions] = useQueryFetchOptions(
        history,
        prefix,
        defaultFetchOptions,
    );
    const href = fp.get('relationships.patients.links.related', ehr);
    const [onlyShowUnmapped, setOnlyShowUnmapped] = useState(false);

    const fetchItems = useCallback(
        async fetchOptions => {
            if (!href || !fetchOptions)
                return {
                    rows: [],
                };
            const realOptions = fp.assign(options, {
                include: ['primary_patient', 'primary_patient.ehr_connection'],
                filter: onlyShowUnmapped ? {primary_patient_id: null} : {},
            });
            const result = await api.fetchDataTable(
                href,
                fetchOptions,
                realOptions,
            );
            return result;
        },
        [api, href, options, onlyShowUnmapped],
    );
    const ds = useReduxDataSource({data: fetchItems, fetchOptions});
    const actions = useDataActions(fetchOptions, setFetchOptions);
    useEffect(fetchItems, [fetchItems]);

    return {
        ...ds,
        ...actions,
        onlyShowUnmapped,
        setOnlyShowUnmapped,
    };
}
