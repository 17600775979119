import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';

const {dt2} = components;

export default function ReportTable({dataSource}) {
    return (
        <dt2.DataTable
            dataSource={dataSource}
            size='small'
            title='Ticket Report'
            options={{sorting: false}}
        >
            <dt2.Column title='Ticket ID' field='attributes.ticket_id' />
            <dt2.Column
                title='Last Updated'
                type='date'
                field='attributes.updated'
            />
            <dt2.Column title='Facility' field='attributes.facility' />
            <dt2.Column title='Last name' field='attributes.patient_last' />
            <dt2.Column title='First name' field='attributes.patient_first' />
            <dt2.Column
                title='DOB'
                field='attributes.patient_dob'
                type='date'
                render={row =>
                    h.formatDateOnly(fp.get('data.attributes.patient_dob', row))
                }
            />
            <dt2.Column title='Type' field='attributes.ticket_type' />
            <dt2.Column title='Orders' field='attributes.orders' />
            <dt2.Action
                id='btn-report-refresh'
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            <dt2.Action
                id='btn-report-download'
                name='download'
                free
                onClick={dataSource.downloadFile}
                tooltip='Download CSV'
                icon='cloud_download'
            />
        </dt2.DataTable>
    );
}
