import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';

import {ducks} from '@arborian/narrf';

export const selectProgressNoteTypes = config =>
    createSelector(
        ducks.jsonapi.selectObject('PCCProgressNoteType'),
        fp.pipe([
            fp.filter(
                pnt =>
                    fp.get('relationships.ehr_connection.data.id', pnt) ===
                    fp.get('id', config),
            ),
            fp.filter(pnt => fp.size(pnt.attributes.sections) === 1),
            fp.sortBy('attributes.noteType'),
        ]),
    );
