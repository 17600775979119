import {useState, useMemo, useEffect} from 'react';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';
import {useResources} from 'ccm/dataSource';
import * as h from 'ccm/lib/helpers';

import NewVisitDialog from './NewVisitDialog';

const DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.name.last', direction: 'asc'},
    filter: {
        'attributes.active': true,
    },
    include: [
        'facility',
        'primary_patient',
        // 'ehr_connection',
        // 'primary_patient_ehr_connection',
        'last_visit',
        'next_visit',
    ],
    page: {number: 0, size: 20},
};

function createVisitForPatient(api, patient, attributes, relationships) {
    const patientLinkage =
        fp.get('relationships.primary_patient.data', patient) ||
        fp.pick(['type', 'id'], patient);
    const visit_date = h.normalizeDate(attributes.visit_date);
    const data = {
        type: 'Visit',
        attributes: fp.set('visit_date', visit_date, attributes),
        relationships: fp.set('patient.data', patientLinkage, relationships),
    };
    const href = api.url_for('visit.VisitCollection');
    return api.fetchJson(href, {
        method: 'POST',
        json: {data},
    });
}

export function usePracticePatients({practice, ...props}) {
    const href = fp.get('relationships.patients.links.related', practice);
    const initialFetchOptions = DEFAULT_FETCH_OPTIONS;
    const result = useResources({
        href,
        prefix: 'practicePatients.',
        initialFetchOptions,
        ...props,
    });
    return {initialFetchOptions, ...result};
}

export const usePracticePatientTags = practice => {
    const api = useApi();
    const [items, setItems] = useState([]);

    const fetch = useMemo(() => {
        async function loadTags() {
            if (!api || !practice) return;
            const href = fp.get('relationships.tags.links.related', practice);
            const res = await api.fetchAllJsonApi(href);
            setItems(res);
        }
        return loadTags;
    }, [api, practice]);

    useEffect(() => fetch(), [fetch]);
    return {fetch, items};
};

export const useActions = practice => {
    const api = useApi();
    const newVisitDialog = useDialog(NewVisitDialog, {practice});

    const actions = useMemo(
        () => ({
            createVisit: async (patient, tickets) => {
                const dialogRes = await newVisitDialog({
                    patients: [patient],
                    tickets,
                });
                if (!dialogRes) return null;
                const {attributes, relationships} = dialogRes;
                const visit = await createVisitForPatient(
                    api,
                    patient,
                    attributes,
                    relationships,
                );
                return visit;
            },
            createVisits: async (patients, tickets = []) => {
                const dialogRes = await newVisitDialog({patients, tickets});
                if (!dialogRes) return null;
                const {attributes, relationships} = dialogRes;
                const promises = fp.map(
                    pt =>
                        createVisitForPatient(
                            api,
                            pt,
                            attributes,
                            relationships,
                        ),
                    patients,
                );
                const visits = await Promise.all(promises);
                return visits;
            },
        }),
        [api, newVisitDialog],
    );

    return actions;
};
