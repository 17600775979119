import React from 'react';
import fp from 'lodash/fp';

import {components, useApi} from '@arborian/narrf';

import {useResources} from 'ccm/dataSource';
import {useDialog} from 'ccm/components/dialogs';

import VisitTypeDialog from './VisitTypeDialog';
import VisitTypeChip from './VisitTypeChip';

const {dt2} = components;

const INITIAL_FETCH_OPTIONS = {
    sort: {field: 'prefix', direction: 'asc'},
    page: {number: 0, size: 20},
};

const VTYPE_TEMPLATE = {
    type: 'VisitType',
    attributes: {
        name: 'TYPE NAME',
        minutes: '0',
        color: '#ffffff',
        backgroundColor: '#006ed2',
    },
};
export default function VisitTypeAdmin({practice}) {
    const api = useApi();
    const href = fp.get('relationships.visitTypes.links.related', practice);
    const typeDialog = useDialog(VisitTypeDialog);
    const ds = useResources({
        prefix: 'visitTypes.',
        href: href,
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
    });
    const handleRefresh = () => ds.fetch();
    const handleAdd = async () => {
        const dialogResult = await typeDialog(VTYPE_TEMPLATE);
        if (!dialogResult) return;
        await api.fetchJson(href, {
            method: 'POST',
            json: {data: dialogResult},
        });
        ds.fetch();
    };
    const handleEdit = async (ev, row) => {
        const dialogResult = await typeDialog(row.data);
        if (!dialogResult) return;
        const apiResult = await api.fetchJson(dialogResult.links.self, {
            method: 'PUT',
            json: {data: dialogResult},
        });
        console.log('VisitTypeAdmin', {dialogResult, apiResult});
    };
    const handleDelete = async (ev, row) => {
        const apiResult = await api.fetchJson(row.data.links.self, {
            method: 'DELETE',
        });
        console.log('VisitTypeAdmin', {apiResult});
        ds.fetch();
    };

    return (
        <dt2.DataTable
            options={{sortable: true, filtering: true}}
            dataSource={ds}
            title='Visit Type Admin'
        >
            <dt2.Column
                id='vtype-attr-name'
                field='attributes.name'
                title='Name'
            />
            <dt2.Column
                id='vtype-attr-minutes'
                field='attributes.minutes'
                title='Duration (minutes)'
                filtering={false}
            />
            <dt2.Column
                id='vtype-preview'
                title='Preview'
                filtering={false}
                sorting={false}
                render={row => <VisitTypeChip attrs={row.data.attributes} />}
            />
            <dt2.Action
                id='vtype-action-delete'
                onClick={handleDelete}
                icon='delete'
            />
            <dt2.Action
                id='vtype-action-edit'
                onClick={handleEdit}
                icon='edit'
            />
            <dt2.Action
                id='vtype-action-add'
                free
                onClick={handleAdd}
                icon='add'
            />
            <dt2.Action
                id='vtype-action-refresh'
                free
                onClick={handleRefresh}
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
