import React, {useRef} from 'react';
import fp from 'lodash/fp';
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {components} from '@arborian/narrf';

import {useFacilities} from 'ccm/dataSource';
import {FacilityLinkComponent} from './FacilityAdminTable';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(1),
    },
}));

const INITIAL_FETCH_OPTIONS = {
    filter: {'attributes.active': true},
    page: {number: 0, size: 20},
    sort: {field: 'attributes.name', direction: 'asc'},
};

export default function FacilityAdminStats() {
    const classes = useStyles();
    return (
        <Accordion index='facAdminStats'>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <div className={classes.title}>
                    <h6>Facility Stats</h6>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <FacilityStatsTable />
            </AccordionDetails>
        </Accordion>
    );
}

function MedsPerPatientValue({row}) {
    const value = fp.get('attributes.stats.average_meds_per_patient', row.data);
    return <p>{fp.isNumber(value) ? value.toFixed(1) : value}</p>;
}

function FacilityStatsTable({practice}) {
    const tableRef = useRef();
    const ds = useFacilities({
        type: 'Facility',
        prefix: 'facTable.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
    });
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            id='facility-stats-table'
            tableRef={tableRef}
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
        >
            <dt2.Column
                id='facName'
                title='Name'
                field='attributes.name'
                component={FacilityLinkComponent}
            />
            <dt2.Column
                id='statAvgMeds'
                title='Average Medications per Patient'
                field='attributes.stats.average_meds_per_patient'
                filtering={false}
                component={MedsPerPatientValue}
            />
            <dt2.Action
                id='table-refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
