import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';

import {ducks} from '@arborian/narrf';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from '@material-ui/core';
import useWebsocket from 'ccm/lib/websocket';

const selectTaskLogs = taskIds =>
    createSelector(ducks.jsonapi.selectObject('TaskLog'), logs =>
        fp.pipe([fp.map(tid => logs[tid]), fp.filter(t => !!t)])(taskIds),
    );

export default function TaskTable({logs}) {
    const taskIds = useMemo(() => fp.map('id', logs), [logs]);
    const tasks = useSelector(
        useMemo(() => selectTaskLogs(taskIds), [taskIds]),
    );
    useWebsocket('/tasklog', {
        onOpen: ws => {
            if (taskIds) {
                ws.send(JSON.stringify({_ids: taskIds}));
            }
        },
    });
    if (!logs) return null;
    const lastMessage = t => {
        const msg = t.attributes.messages.slice(-1);
        if (msg.length) {
            return msg[0].message;
        } else {
            return '-';
        }
    };
    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Task ID</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Last message</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fp.map(
                        t => (
                            <TableRow key={t.id}>
                                <TableCell>{t.attributes.task_id}</TableCell>
                                <TableCell>{t.attributes.notes}</TableCell>
                                <TableCell>{lastMessage(t)}</TableCell>
                                <TableCell>{t.attributes.status}</TableCell>
                            </TableRow>
                        ),
                        tasks,
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
