import React from 'react';
import fp from 'lodash/fp';

import {Button, InputAdornment, IconButton} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';

import {TextField} from 'formik-material-ui';
import {Field, FieldArray, useField} from 'formik';

export default function TextFieldList({name, addText, emptyText = '--none--'}) {
    const [field] = useField({name});
    return (
        <FieldArray name={name}>
            {({push, remove}) => (
                <>
                    {fp.isEmpty(field.value) ? (
                        <>
                            {emptyText}
                            <br />
                        </>
                    ) : (
                        <ul>
                            {fp.map(
                                index => (
                                    <Field
                                        component={TextField}
                                        name={`${name}.${index}`}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        onClick={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                    />
                                ),
                                fp.range(0, field.value.length),
                            )}
                        </ul>
                    )}
                    <Button
                        startIcon={<Add />}
                        onClick={() => push('')}
                        variant='outlined'
                    >
                        {addText}
                    </Button>
                </>
            )}
        </FieldArray>
    );
}
