import React from 'react';

import {HeartRateAvatar} from 'ccm/art/MedicalIcons';

import VitalCard from './VitalCard';

export default function HeartRateCard({patient, artifact}) {
    return (
        <VitalCard
            color='green'
            Avatar={HeartRateAvatar}
            type='heartrate'
            patient={patient}
            {...artifact.attributes}
        >
            <h6>Heart rate</h6>
        </VitalCard>
    );
}
