import React, {useState} from 'react';
import fp from 'lodash/fp';
import {Link} from 'react-router-dom';

import {Tab, Tabs} from '@material-ui/core';

import {useApi, components} from '@arborian/narrf';

import TelecomChips from 'ccm/components/TelecomChips';
import AddressBlock from 'ccm/components/AddressBlock';
import InvitationAdminTable from 'ccm/components/InvitationAdminTable';
import GroupMemberTable from 'ccm/components/GroupMemberTable';
import {usePractices} from 'ccm/dataSource';
import {url_for} from 'ccm/routes';

import {useDialog} from 'ccm/components/dialogs';
import PracticeDialog from 'ccm/components/dialogs/PracticeDialog';

const {dt2} = components;

function PracticeDetail({value}) {
    const [tab, setTab] = useState(0);
    return (
        <div>
            <h6>{fp.get('attributes.name', value)}</h6>
            <AddressBlock value={fp.get('attributes.address', value)} />
            <TelecomChips value={fp.get('attributes.telecom', value)} />
            <Tabs value={tab} onChange={(ev, index) => setTab(index)}>
                <Tab label='Authorized Users' />
                <Tab label='Invitations' />
            </Tabs>

            {tab === 0 ? (
                <GroupMemberTable protectedResource={value} />
            ) : (
                <InvitationAdminTable protectedResource={value} />
            )}
        </div>
    );
}

function PracticeLinkComponent({row}) {
    return (
        <Link to={url_for('practice', {practiceId: row.data.id})}>
            {fp.get('attributes.name', row.data)}
        </Link>
    );
}

export default function PracticeAdminTable({url}) {
    const api = useApi();
    const ds = usePractices({prefix: 'pracTable.'});
    const practiceDialog = useDialog(PracticeDialog);
    if (!url) {
        url = api.url_for('practice.PracticeCollection');
    }

    const refresh = () => ds.fetch();

    const addPractice = async () => {
        let attributes = await practiceDialog(
            {},
            {
                title: 'Add Practice',
                submitLabel: 'Create Practice',
            },
        );
        if (!attributes) return;
        const data = {
            type: 'Practice',
            attributes,
        };
        const url = api.url_for('practice.PracticeCollection');
        await api.fetchJsonApi(url, {method: 'POST', json: {data}});
        refresh();
    };
    const editPractice = async (ev, row) => {
        let attributes = await practiceDialog(row.data.attributes, {
            title: 'Edit Practice',
            submitLabel: 'Save Changes',
        });
        if (!attributes) return;
        const data = {
            ...row.data,
            attributes,
        };
        await api.fetchJsonApi(row.data.links.self, {
            method: 'PUT',
            json: {data},
        });
        refresh();
    };
    const onRowDelete = oldRow => {
        return api.fetchJsonApi(oldRow.data.links.self, {
            method: 'DELETE',
        });
    };

    return (
        <dt2.DataTable
            title='Practice Administration'
            size='small'
            dataSource={ds}
            //data={fetchPractices}
            //tableRef={tableRef}
            //fetchOptions={fetchOptions}
            //onChangeFetchOptions={setFetchOptions}
            editable={{
                // onRowUpdate,
                onRowDelete,
            }}
            options={{
                // filtering: true,
                // selection: true,
                search: true,
            }}
        >
            <dt2.Column
                title='Name'
                field='attributes.name'
                component={PracticeLinkComponent}
            />
            <dt2.Column title='Address' field='attributes.address.oneLine' />
            <dt2.Column
                title='Telecom'
                field='attributes.telecom'
                component={TelecomChips}
            />
            <dt2.Column title='Email' field='attributes.email.email' />

            <dt2.DetailPanel
                render={row => <PracticeDetail value={row.data} />}
            />
            <dt2.Action
                name='add'
                free
                onClick={addPractice}
                tooltip='Add a new practice'
                icon='add'
            />
            <dt2.Action
                name='edit'
                onClick={editPractice}
                tooltip='Edit Practice'
                icon='edit'
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
