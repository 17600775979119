import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useApi} from '@arborian/narrf';
import {url_for} from 'ccm/routes';
import BasePage from 'ccm/components/BasePage';

export default function LogoutRedirectPage() {
    const api = useApi();
    const history = useHistory();
    const {state} = useLocation();

    useEffect(() => {
        api.logout()
            .then(() => history.push(url_for('unlock'), state))
            .catch(e => {
                console.error('Error in autologout', e);
                history.push(url_for('unlock'), state);
            });
    }, [api, history, state]);
    return <BasePage title='Locking your session...' />;
}
