import React, {useCallback} from 'react';
import fp from 'lodash/fp';

import {HelpOutline} from '@material-ui/icons';
import {Tooltip, Avatar, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.main,
    },
}));

/*
<MultiStateAvatar states={[
  {value, title, className, Icon, default?}
]} value />
*/

export default function MultiStateAvatar({value, onChange, states, ...props}) {
    const classes = useStyles();

    let state = fp.find(s => fp.isEqual(s.value, value), states);

    const title = state ? state.title : 'Unknown';
    const className = state ? state.className : classes.error;
    const TheIcon = state ? state.Icon : HelpOutline;

    const onClick = useCallback(
        ev => {
            const curIndex = state
                ? fp.findIndex(fp.isEqual(state), states)
                : -1;
            const newIndex = (curIndex + 1) % states.length;
            onChange(states[newIndex].value);
        },
        [state, states, onChange],
    );

    return (
        <Tooltip title={title}>
            <Avatar
                className={className}
                onClick={onChange ? onClick : null}
                {...props}
            >
                <TheIcon />
            </Avatar>
        </Tooltip>
    );
}
