import React, {useMemo, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Chip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {ArrowDropDown} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    filter: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiIconButton-root': {
            alignSelf: 'flex-start',
        },
    },
    chiplist: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            margin: theme.spacing(0.5, 0.5, 0, 0),
        },
    },
}));

function FilterDialog({
    title,
    value,
    open,
    onClose,
    selector,
    fetchOptions,
    id,
    ...props
}) {
    const [current, setCurrent] = useState();
    const options = useSelector(selector) || [];

    useEffect(() => {
        if (!fp.isEqual(value, current)) {
            setCurrent(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, setCurrent, value]);

    useEffect(() => {
        if (open) {
            fetchOptions();
        }
    }, [open, fetchOptions]);

    const handleChange = (ev, newVal) => {
        setCurrent(newVal);
    };

    const handleOk = ev => {
        onClose(ev, 'accepted', current);
    };
    const inputId = id ? `input-${id}` : null;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title} Filter</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    id={inputId}
                    options={options}
                    defaultValue={value}
                    value={current}
                    onChange={handleChange}
                    renderInput={params => <TextField autoFocus {...params} />}
                    {...props}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleOk} variant='contained' color='primary'>
                    Update Filter
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function AsyncDialogFilter({
    column,
    filter,
    onChangeFilter,
    selector,
    fetchOptions,
    getOptionLabel = fp.identity,
    ...props
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const value = useMemo(() => fp.getOr([], [column.field], filter), [
        column.field,
        filter,
    ]);
    const handleDelete = val => ev => {
        onChangeFilter(column.field, fp.pull(val, value));
    };
    const handleClose = (ev, reason, newVal) => {
        if (newVal) {
            onChangeFilter(column.field, newVal);
        }
        setOpen(false);
    };
    const buttonId = props.id ? `btn-${props.id}` : null;
    return (
        <div className={classes.filter}>
            <div className={classes.chiplist}>
                {fp.map(
                    val => (
                        <Chip
                            size='small'
                            key={val}
                            label={getOptionLabel(val)}
                            onDelete={handleDelete(val)}
                        />
                    ),
                    value,
                )}
            </div>
            <IconButton id={buttonId} onClick={() => setOpen(true)}>
                <ArrowDropDown />
            </IconButton>
            <FilterDialog
                value={value}
                open={open}
                onClose={handleClose}
                selector={selector}
                fetchOptions={fetchOptions}
                getOptionLabel={getOptionLabel}
                {...props}
            />
        </div>
    );
}
