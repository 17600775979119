import React, {useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector, useDispatch} from 'react-redux';
import fp from 'lodash/fp';

import {useSnackbar} from 'notistack';

import {Button, Chip, IconButton, makeStyles} from '@material-ui/core';
import {AddCircleOutline, Face, Warning} from '@material-ui/icons';

import {useApi, ducks, components} from '@arborian/narrf';

import {useUsers} from 'ccm/dataSource';
import GroupChip from 'ccm/components/GroupChip';

import * as h from 'ccm/lib/helpers';
import {useDialog} from 'ccm/components/dialogs';
import GroupsDialog from 'ccm/components/dialogs/GroupsDialog';

const TOKEN_EXCHANGE = 'urn:ietf:params:oauth:grant-type:token-exchange';
const IDENTITY_TOKEN = 'urn:ietf:params:oauth:token-type:id_token';
const {dt2} = components;

const useStyles = makeStyles(theme => ({
    detail: {
        padding: theme.spacing(2),
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2, 1),
        },
    },
    groupsHeader: {
        padding: theme.spacing(2),
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 3,
        '& .MuiChip-root': {
            margin: theme.spacing(0.5),
        },
        '& .MuiIconButton-root': {
            padding: theme.spacing(1),
        },
    },
    groupsChild: {
        minWidth: 500,
    },
    scaryButton: {
        whiteSpace: 'pre-line',
    },
}));

// const INCLUDES = ['groups', 'practices', 'facilities', 'patients'];
// const DS_OPTIONS = {include: INCLUDES};

const useUserActions = () => {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const groupDialog = useDialog(GroupsDialog);
    const dispatch = useDispatch();
    const history = useHistory();
    const actions = useMemo(
        () =>
            new UserActions(
                api,
                enqueueSnackbar,
                groupDialog,
                dispatch,
                history,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [api, enqueueSnackbar, dispatch, history],
    );
    return actions;
};

class UserActions {
    constructor(api, enqueueSnackbar, groupDialog, dispatch, history) {
        this.api = api;
        this.enqueueSnackbar = enqueueSnackbar;
        this.groupDialog = groupDialog;
        this.dispatch = dispatch;
        this.history = history;
    }

    putUser = async user => {
        const rv = await this.api.fetchJsonApi(user.links.self, {
            method: 'PUT',
            json: {data: user},
        });
        return rv;
    };

    fetchUserDetails = user => {
        this.api.fetchAllJsonApi(
            fp.get('relationships.groups.links.related', user),
            {
                include: ['patient', 'practice', 'facility'],
            },
        );
        this.api.fetchAllJsonApi(
            fp.get('relationships.identities.links.related', user),
        );
    };

    sendPasswordReset = async user => {
        console.log('Request password reset for', user);
        const email = fp.get('attributes.primary_email', user);
        await this.api.fetch(this.api.url_for('auth.password_reset_request'), {
            method: 'POST',
            json: {data: {type: 'PasswordResetRequest', attributes: {email}}},
        });
        this.enqueueSnackbar(`Password reset email sent to ${email}`, {
            persist: false,
            variant: 'success',
        });
    };

    expirePassword = async user => {
        console.log('Expire password for', user);
        const email = fp.get('attributes.primary_email', user);
        const url = fp.get('links.password-expiration', user);
        await this.api.fetch(url, {method: 'PUT'});
        this.enqueueSnackbar(`Password forcibly expired for ${email}`, {
            persist: false,
            variant: 'success',
        });
    };

    deleteUser = async user => {
        await this.api.fetchJson(fp.get('links.self', user), {
            method: 'DELETE',
        });
        this.dispatch(ducks.jsonapi.deleteData(user));
    };

    setUserGroups = async (user, groups) => {
        const data = fp.set('relationships.groups.data', groups, user);

        await this.api.fetchJsonApi(user.links.self, {
            method: 'PUT',
            json: {data},
            include: ['groups'],
        });
    };

    addUserGroup = async user => {
        const newGroup = await this.groupDialog();
        if (newGroup) {
            const groups = [
                {type: 'Group', id: newGroup.id},
                ...user.relationships.groups.data,
            ];
            await this.setUserGroups(user, groups);
        }
    };

    delUserGroup = async (user, group) => {
        const groups = fp.filter(
            ug => ug.id !== group.id,
            user.relationships.groups.data,
        );
        await this.setUserGroups(user, groups);
    };

    impersonateUser = async user => {
        const sub = fp.get('attributes.sub', user);
        // Get jwks
        const jwks = await this.api.fetchJson(this.api.url_for('oauth.jwks'));
        const kid = jwks.keys[0].kid;
        // Create identity token
        const jwt = await this.api.fetchJson(
            this.api.url_for('oauth.jwt', {kid}),
            {
                method: 'POST',
                json: {
                    claims: {sub},
                },
            },
        );
        await this.history.push('/');
        await this.api.login({
            grant_type: TOKEN_EXCHANGE,
            client_id: this.api.client_id,
            subject_token: jwt.jwt,
            subject_token_type: IDENTITY_TOKEN,
        });
        window.location.reload();
    };
}

function GroupChips({user}) {
    const classes = useStyles();
    const rel = useSelector(ducks.jsonapi.selectRelated(user));
    const actions = useUserActions();

    return (
        <div className={classes.groupsChild}>
            {fp.map(
                g => (
                    <GroupChip
                        key={g.id}
                        value={g}
                        onDelete={() => actions.delUserGroup(user, g)}
                    />
                ),
                rel.groups,
            )}
            <IconButton onClick={ev => actions.addUserGroup(user)}>
                <AddCircleOutline />
            </IconButton>
        </div>
    );
}

const selectUserIdentities = userId =>
    createSelector(
        ducks.jsonapi.selectObject('Identity'),
        fp.filter(ident => ident.attributes.user_id === userId),
    );

async function fetchUserIdentity(api, url) {
    try {
        await api.fetchJson(url);
    } catch (e) {
        console.error('Error fetching identity from', url);
    }
}

function UserIdentities({user}) {
    const api = useApi();
    const idents = useSelector(selectUserIdentities(fp.get('id', user)));
    const url = fp.get('relationships.identities.links.related', user);
    useEffect(() => {
        if (url) {
            fetchUserIdentity(api, url);
        }
    }, [api, url]);
    if (!user) return null;
    return (
        <>
            {fp.map(
                ident => (
                    <Chip
                        key={ident.id}
                        label={fp.get('attributes.provider_label', ident)}
                    />
                ),
                idents,
            )}
        </>
    );
}

function UserDetail({row, ds}) {
    const classes = useStyles();
    const user = row?.data;
    const actions = useUserActions();
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    useEffect(() => actions.fetchUserDetails(user), [actions, user]);

    const deleteUser = async ev => {
        const confirmed = window.confirm(
            'Are you sure you want to permanently delete this user?',
        );
        if (!confirmed) {
            return;
        }
        await actions.deleteUser(user);
        await ds.fetch();
    };
    const allowedActions = fp.get('meta.allowed_actions', user);
    const canDelete = fp.includes('Common.ADMIN', allowedActions);
    const canImpersonate = fp.includes('__admin__', userinfo?.scopes);
    console.log({userinfo, canDelete, canImpersonate, allowedActions});
    if (!user) return null;
    return (
        <div className={classes.detail}>
            <div className={classes.chipContainer}>
                <h6>Group Permissions</h6>
                <GroupChips user={user} />
            </div>
            <div className={classes.chipContainer}>
                <h6>User Identities</h6>
                <UserIdentities user={user} />
            </div>
            {canDelete ? (
                <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<Warning />}
                    endIcon={<Warning />}
                    onClick={deleteUser}
                >
                    <div className={classes.scaryButton}>
                        Delete User Entirely
                        <br />
                        <small>This is irreversible!</small>
                    </div>
                </Button>
            ) : null}
            {canImpersonate && (
                <Button
                    variant='contained'
                    startIcon={<Face />}
                    onClick={ev => actions.impersonateUser(user)}
                >
                    Impersonate User
                </Button>
            )}
        </div>
    );
}

const renderLastPasswordChangeDate = row => {
    const value = fp.get('data.attributes.last_password_change', row);
    if (!value) return '';
    return h.formatDateOnly(value);
};

function NameBadge({row}) {
    const isAdmin = fp.includes(
        '__admin__',
        fp.get('data.attributes.cached_scopes', row),
    );
    return (
        <p>
            {fp.get('data.attributes.name', row)}
            {isAdmin && (
                <Chip
                    label='SUPER ADMIN'
                    color='secondary'
                    size='small'
                    variant='outlined'
                />
            )}
        </p>
    );
}

export default function UserAdminTable() {
    const ds = useUsers({prefix: 'userTable.'});

    const actions = useUserActions();

    const onRowUpdate = async row => {
        actions.putUser(row.data);
    };

    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            title='User Administration'
            id='table-user-admin'
            size='small'
            dataSource={ds}
            editable={{onRowUpdate}}
            options={{
                search: true,
            }}
        >
            <dt2.Column
                title='Name'
                field='attributes.name'
                component={NameBadge}
            />
            <dt2.Column
                editable={false}
                title='Email'
                field='attributes.primary_email'
            />
            <dt2.Column
                editable={false}
                type='date'
                title='Last password change'
                field='attributes.last_password_change'
                filterProps={{timeZone: 'UTC'}}
                render={renderLastPasswordChangeDate}
            />
            <dt2.Column
                editable={false}
                title='Groups + Identites'
                render={() => <>(Expand to view)</>}
            />
            <dt2.DetailPanel render={row => <UserDetail row={row} ds={ds} />} />
            <dt2.Action
                name='send-password-reset'
                icon='vpn_key'
                onClick={(ev, row) => actions.sendPasswordReset(row.data)}
                tooltip='Send user password reset email'
            />
            <dt2.Action
                name='expire-password'
                icon='schedule'
                onClick={(ev, row) => actions.expirePassword(row.data)}
                tooltip='Expire user password'
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
