import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import {components, useApi} from '@arborian/narrf';
import {useListField} from 'ccm/dataSource';
import PatientEHRComponent from 'ccm/components/PatientEHRComponent';

const {dt2} = components;

export default function DuplicatePatientDialog({dialogRef}) {
    const api = useApi();
    const [resolve, setResolve] = useState(null);
    const [patients, setPatients] = useState(null);

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
        setPatients(null);
    };

    dialogRef.current = selectedPatients => {
        return new Promise((resolve, reject) => {
            setPatients(selectedPatients);
            setResolve(() => resolve);
        });
    };

    useEffect(() => {
        fp.forEach(pt => {
            const url = fp.get(
                'relationships.ehr_connection.links.related',
                pt,
            );
            api.fetchJson(url);
        }, patients);
    }, [api, patients]);

    const ds = useListField(patients);
    const handleSelect = async (ev, row) => {
        const primaryLinkage = {
            type: row.data.type,
            id: row.data.id,
        };
        const promises = fp.map(pt => {
            if (pt.id === row.id) {
                // Primary patient -- ensure unmapped!
                return api.fetchJson(
                    pt.relationships.primary_patient.links.self,
                    {
                        method: 'PATCH',
                        json: {data: null},
                    },
                );
            } else {
                // Secondary patient -- ensure mapped!
                return api.fetchJson(
                    pt.relationships.primary_patient.links.self,
                    {
                        method: 'PATCH',
                        json: {data: primaryLinkage},
                    },
                );
            }
        }, patients);
        await Promise.all(promises);
        resolve({action: 'selected'});
        setPatients(null);
        setResolve(null);
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <DialogTitle>Choose Primary Patient</DialogTitle>
            <DialogContent>
                <p>Please choose the primary patient record below.</p>
                <p>
                    If the patient has a record in the facility EMR
                    (PointclickCare, etc.), you should select the facility EMR
                    record as the primary record.
                </p>
                {fp.size(patients) < 2 ? (
                    <Alert severity='warning'>
                        You have only selected one patient. This has no effect.
                    </Alert>
                ) : fp.size(patients) > 2 ? (
                    <Alert severity='error'>
                        You have selected {fp.size(patients)} patients here. ALL
                        patients will be mapped to the SAME prmiary patient you
                        select here.
                    </Alert>
                ) : null}
                <dt2.DataTable dataSource={ds}>
                    <dt2.Column title='Last' field='attributes.name.last' />
                    <dt2.Column title='First' field='attributes.name.first' />
                    <dt2.Column
                        title='DOB'
                        field='attributes.dob'
                        type='date'
                    />
                    <dt2.Column title='EHR' component={PatientEHRComponent} />
                    <dt2.Action
                        name='select'
                        onClick={handleSelect}
                        tooltip='Select this patient'
                        icon='check'
                    />
                </dt2.DataTable>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
