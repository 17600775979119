import React from 'react';

import {SyringeAvatar} from 'ccm/art/MedicalIcons';
import InfoCard from './InfoCard';

export default function ImmunizationsCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={SyringeAvatar}
            title='Immunizations'
            {...props}
        ></InfoCard>
    );
}
