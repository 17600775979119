import React, {useState} from 'react';
import fp from 'lodash/fp';

import {Tabs, Tab, IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

import TelecomChips from 'ccm/components/TelecomChips';
import AddressBlock from 'ccm/components/AddressBlock';
import EHRConfig from 'ccm/components/EHRConfig';
import InvitationAdminTable from 'ccm/components/InvitationAdminTable';
import GroupMemberTable from 'ccm/components/GroupMemberTable';
import DirectoryTable from 'ccm/components/DirectoryTable';

import {useDialog} from 'ccm/components/dialogs';
import FacilityDialog from 'ccm/components/dialogs/FacilityDialog';

import useStyles from './styles';
import FacilityStats from './FacilityStats';
import FacilitySchedule from './FacilitySchedule';
import PrimaryPracticeChip from './PrimaryPracticeChip';

export default function FacilityAdmin({facility}) {
    const classes = useStyles();
    const api = useApi();
    const [tab, setTab] = useState(0);
    const dialog = useDialog(FacilityDialog);
    const onEdit = async ev => {
        let attributes = await dialog(facility, {
            title: 'Edit Facility',
            submitLabel: 'Save changes',
        });
        if (!attributes) return;
        const data = {
            ...facility,
            attributes,
        };
        await api.fetchJsonApi(facility.links.self, {
            method: 'PUT',
            json: {data},
        });
    };

    return (
        <div>
            <div className={classes.hbox}>
                <div className={classes.vbox}>
                    <h5>
                        {fp.get('attributes.name', facility)}
                        <IconButton onClick={onEdit}>
                            <Edit />
                        </IconButton>
                    </h5>
                    <h6>{fp.get('attributes.org_name', facility)}</h6>
                    <AddressBlock
                        value={fp.get('attributes.address', facility)}
                    />
                    <div className={classes.chipList}>
                        <TelecomChips
                            value={fp.get('attributes.telecom', facility)}
                        />
                        <PrimaryPracticeChip
                            value={fp.get(
                                'attributes.primaryPracticeId',
                                facility,
                            )}
                        />
                    </div>
                </div>
                <FacilityStats facility={facility} />
            </div>
            <Tabs value={tab} onChange={(ev, index) => setTab(index)}>
                <Tab label='Upcoming Visits' />
                <Tab label='Authorized Users' />
                <Tab label='Invitations' />
                <Tab label='EHR Configuration' />
                <Tab label='Directory' />
            </Tabs>
            {tab === 0 ? (
                <FacilitySchedule facility={facility} />
            ) : tab === 1 ? (
                <GroupMemberTable protectedResource={facility} />
            ) : tab === 2 ? (
                <InvitationAdminTable protectedResource={facility} />
            ) : tab === 3 ? (
                <EHRConfig owner={facility} />
            ) : (
                <DirectoryTable
                    protectedResource={facility}
                    tableTitle='Facility Directory'
                    dialogTitle='Modify Contact'
                    newContact={{
                        type: 'Contact',
                        attributes: {
                            name: 'New Contact',
                        },
                        relationships: {
                            facility: {
                                data: {type: 'Facility', id: facility.id},
                            },
                        },
                    }}
                />
            )}
        </div>
    );
}
