import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField as MUITextField,
} from '@material-ui/core';
import {Autocomplete} from 'formik-material-ui-lab';

import {Formik, Form, Field} from 'formik';

import {useActiveTicketTypesLookup} from 'ccm/dataSource/allResources';

export default function TicketTypeDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const ticketTypes = useActiveTicketTypesLookup();

    const handleSubmit = values => {
        console.log('Submit value:', values.ticket_type);
        resolve(values.ticket_type);
        setResolve(null);
    };
    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = ticket =>
        new Promise((resolve, reject) => {
            setInitialValues({
                ticket_type: fp.get('attributes.data.ticket_type', ticket),
            });
            setResolve(() => resolve);
        });
    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <DialogTitle>Change Ticket Type</DialogTitle>
                        <DialogContent>
                            <Field
                                component={Autocomplete}
                                name='ticket_type'
                                options={fp.keys(ticketTypes)}
                                getOptionLabel={key => ticketTypes[key]}
                                renderInput={params => (
                                    <MUITextField
                                        label='Ticket Type'
                                        {...params}
                                    />
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Update Ticket
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
