import React from 'react';

import {useRelated} from 'ccm/dataSource';
import CarePlanTemplateTable from './CarePlanTemplateTable';
import CarePlanImporter from './CarePlanImporter';

export default function CarePlanTemplates({practice}) {
    const carePlanTemplates = useRelated(practice, 'care_plan_templates', {
        type: 'CarePlanTemplate',
    });

    const editable = {
        onRowAdd: carePlanTemplates.handleAdd,
        // Editing happens in detail panel instead
        // onRowUpdate: carePlanTemplates.handleUpdate,
        onRowDelete: carePlanTemplates.handleDelete,
    };

    return (
        <div>
            <CarePlanImporter
                id='CarePlanImporter'
                title='Care Plan Library'
                practice={practice}
                practiceDataSource={carePlanTemplates}
            />
            <CarePlanTemplateTable
                title='Care Plan Templates'
                dataSource={carePlanTemplates}
                editable={editable}
            />
        </div>
    );
}
