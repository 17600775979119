import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';
import {Formik, Form, Field, FieldArray} from 'formik';
import {TextField} from 'formik-material-ui';

const DEFAULT_OPTIONS = {
    title: 'Suggest an Orders Template',
    submitLabel: 'Submit',
    initialValues: {
        title: '',
        instructions: [],
    },
};

export default function SuggestOrdersDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    const handleSubmit = values => {
        console.log(values);
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (options = {}) => {
        return new Promise((resolve, reject) => {
            setOptions(fp.merge(DEFAULT_OPTIONS, options));
            setResolve(() => resolve);
        });
    };

    const handleAdd = arrayHelpers => ev => {
        console.log('Add', ev, arrayHelpers);
        arrayHelpers.push('new instruction');
    };
    const handleDelete = (arrayHelpers, i) => ev => {
        arrayHelpers.remove(i);
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={options.initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <Field
                                component={TextField}
                                label='Template Title'
                                name='title'
                                fullWidth
                            />
                            <List>
                                <FieldArray
                                    name='instructions'
                                    render={arrayHelpers => (
                                        <div>
                                            {fp.map(
                                                index => (
                                                    <>
                                                        <ListItem>
                                                            <Field
                                                                key={index}
                                                                component={
                                                                    TextField
                                                                }
                                                                label='Instruction text'
                                                                name={`instructions.${index}`}
                                                                fullWidth
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    onClick={handleDelete(
                                                                        arrayHelpers,
                                                                        index,
                                                                    )}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </>
                                                ),
                                                fp.range(
                                                    0,
                                                    values.instructions.length,
                                                ),
                                            )}
                                            <ListItem
                                                button
                                                onClick={handleAdd(
                                                    arrayHelpers,
                                                )}
                                            >
                                                <ListItemIcon>
                                                    <Add />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    Add Instruction
                                                </ListItemText>
                                            </ListItem>
                                        </div>
                                    )}
                                />
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
