import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Card, CardHeader, CardContent, Button, MenuItem} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

function UserPreferencesForm({submitLabel, currentUser, onSubmit}) {
    const handleSubmit = (values, actions) => {
        console.log('Submit prefs change', values);
        return onSubmit(values);
    };

    const initialValues = {
        preferences: {
            unit_system: fp.get('attributes.preferences.unit_system', currentUser),
        }
    };

    if (!currentUser) return null;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({touched, values, errors}) => (
                <Form>
                    <Field
                        component={TextField}
                        label='Unit System'
                        name='preferences.unit_system'
                        select
                        options={["imperial", "metric"]}
                        fullWidth
                    >
                        <MenuItem value='imperial'>Imperial</MenuItem>
                        <MenuItem value='metric'>Metric</MenuItem>
                    </Field>
                    <Button type='submit' variant='contained' color='primary'>
                        Update
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default function UserProfileCard() {
    const api = useApi();
    const curUser = useSelector(selectCurrentUser);

    const handleChange = useCallback(
        async attributes => {
            console.log({attributes});
            await api.fetchJsonApi(curUser.links.self, {
                method: 'PUT',
                json: {data: {type: 'User', attributes}},
            });
        },
        [api, curUser],
    );

    return (
        <Card>
            <CardHeader title='My Preferences' />
            <CardContent>
                <UserPreferencesForm
                    currentUser={curUser}
                    onSubmit={handleChange}
                />
            </CardContent>
        </Card>
    );
}
