import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {components, useApi} from '@arborian/narrf';
import * as h from 'ccm/lib/helpers';
import {useMedications, useListField} from 'ccm/dataSource';

import {useSnackbar} from 'notistack';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

const medStatusLookup = {
    ACTIVE: 'Active',
    PENDING: 'Pending',
    DISCONTINUED: 'Discontinued',
    COMPLETED: 'Completed',
};

function LastAdministration({value}) {
    if (fp.isEmpty(value)) {
        return (
            <p>
                There is no last administration data for this schedule. In fact,
                I have no idea how you even got to this page. Go away.
            </p>
        );
    }
    return (
        <div>
            <h5>Last administration</h5>
            <p>
                At <DateTime value={value.administeredDateTime} />,{' '}
                {value.administeredBy}{' '}
                {fp.get('unableToAdminister', value) ? (
                    <>
                        attempted to administer {value.administeredAmount}, but
                        was unable to
                    </>
                ) : (
                    <>administered {value.administeredAmount}</>
                )}
            </p>
        </div>
    );
}

function ScheduleDetails({patient, schedules}) {
    const curUser = useSelector(selectCurrentUser);
    const schedWithId = useMemo(() => {
        let index = 0;
        return fp.map(s => {
            ++index;
            return {
                id: index,
                ...s,
            };
        }, schedules);
    }, [schedules]);
    const ds = useListField(schedWithId);
    const shouldSeeLastAdministration = useMemo(() => {
        const patientPracticeId = fp.get(
            'relationships.practice.data.id',
            patient,
        );
        const practiceProviderGroup = `practice:${patientPracticeId}/provider`;
        const userProviderGroupIds = fp.pipe([
            fp.getOr([], 'relationships.groups.data'),
            fp.map('id'),
            fp.filter(id => id === practiceProviderGroup),
        ])(curUser);
        return !fp.isEmpty(userProviderGroupIds);
    }, [patient, curUser]);
    return (
        <dt2.DataTable dataSource={ds} size='small' title='Schedule(s)'>
            <dt2.Column title='Dose' render={row => h.renderDose(row.data)} />
            <dt2.Column title='Frequency' field='frequency' />
            <dt2.Column title='Indications' field='indications' />
            <dt2.Column title='Directions' field='directions' />
            <dt2.Column title='Schedule Type' field='type' />
            <dt2.Column
                title='Start'
                type='date'
                field='startDateTime'
                render={row => (
                    <DateTime value={fp.get('startDateTime', row.data)} />
                )}
            />
            <dt2.Column
                title='End'
                type='date'
                field='endDateTime'
                render={row => (
                    <DateTime value={fp.get('endDateTime', row.data)} />
                )}
            />
            {true && (
                <dt2.DetailPanel
                    key='lastAdministration'
                    icon='pending_actions'
                    tooltip='Last administration'
                    render={row => (
                        <LastAdministration
                            value={fp.get('lastAdministration', row.data)}
                        />
                    )}
                >
                    {row => {
                        const lastAdmin = fp.get(
                            'lastAdministration',
                            row.data,
                        );
                        if (fp.isEmpty(lastAdmin)) {
                            return {
                                disabled: true,
                                iconProps: {style: {display: 'none'}},
                            };
                        } else return {};
                    }}
                </dt2.DetailPanel>
            )}
        </dt2.DataTable>
    );
}

export default function MedicationTable({patient, title, ...props}) {
    const api = useApi();
    const ds = useMedications(patient);
    const {enqueueSnackbar} = useSnackbar();
    const practiceId = fp.get('relationships.practice.data.id', patient);

    const refresh = () => ds.fetch();

    const handleRequestRefills = async (ev, items) => {
        const data = {
            data: {
                type: 'RefillRequest',
                relationships: {
                    medications: {
                        data: fp.map(
                            it => ({type: 'Medication', id: it.id}),
                            items,
                        ),
                    },
                    patient: {data: {type: 'Patient', id: patient.id}},
                },
            },
        };
        try {
            await api.fetchJsonApi(
                api.url_for('practice.RefillRequestTicket', {
                    practice_id: practiceId,
                }),
                {
                    method: 'POST',
                    json: data,
                },
            );
            enqueueSnackbar('Medication refill request sent.', {
                persist: false,
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar(
                'Error sending medication refill request. Please use the regular ticket flow.',
                {
                    persist: true,
                    variant: 'error',
                },
            );
        }
    };

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
                selection: !!practiceId,
            }}
            title={title}
            {...props}
        >
            <dt2.Column
                title='Status'
                field='attributes.status'
                lookup={medStatusLookup}
            />
            <dt2.Column title='Name' field='attributes.description' />
            <dt2.Column title='Generic' field='attributes.generic' />
            <dt2.Column
                title='Strength'
                filtering={false}
                render={row => h.renderStrength(row.data.attributes)}
            />
            <dt2.Column
                title='Start Date'
                field='attributes.startDate'
                type='date'
                render={row => (
                    <DateTime
                        value={fp.get('attributes.startDate', row.data)}
                    />
                )}
            />
            <dt2.Column
                title='End Date'
                field='attributes.endDate'
                type='date'
                render={row => (
                    <DateTime value={fp.get('attributes.endDate', row.data)} />
                )}
            />
            <dt2.DetailPanel
                key='schedules'
                icon='schedule'
                tooltip='Administration Schedule'
                render={row => (
                    <ScheduleDetails
                        patient={patient}
                        schedules={row.data.attributes.schedules}
                    />
                )}
            >
                {row => ({
                    disabled: fp.isEmpty(
                        fp.get('attributes.schedules', row.data),
                    ),
                })}
            </dt2.DetailPanel>
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
            <dt2.Action
                name='refill'
                onClick={handleRequestRefills}
                tooltip='Request a refill'
                icon='medication'
                hidden={!practiceId}
            />
        </dt2.DataTable>
    );
}
