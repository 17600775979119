import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {useApi, ducks} from '@arborian/narrf';
import {TextField} from '@material-ui/core';
import {Autocomplete as MUIAutocomplete} from '@material-ui/lab';

import {fieldId} from 'ccm/lib/util';

const formPharmacyName = rx => {
    console.log({rx});
    const name = fp.get('attributes.name', rx);
    const address = fp.get('attributes.address.formatted', rx) || '';
    const telecom = fp.join(
        ', ',
        fp.map(tel => fp.get('value', tel), rx.attributes.telecom),
    );
    return `${name}\n${address}\n${telecom}`;
};

export function PharmacySelectField({field, form, ...props}) {
    const api = useApi();
    const ticketSession = useSelector(
        ducks.jsonapi.selectObject(['TicketSession', props.sessionId]),
    );
    const facilityId = fp.get('attributes.facility_id', ticketSession);
    const pharmacies = useSelector(ducks.jsonapi.selectObject(['Contact']));
    const options = fp.map(formPharmacyName, pharmacies);

    useEffect(() => {
        if (facilityId) {
            api.fetchJsonApi(api.url_for('contact.ContactCollection'), {
                filter: {facility_id: facilityId},
            });
        }
    }, [api, ticketSession, facilityId]);

    const renderInput = props => (
        <TextField label='Please select a pharmacy' {...props} />
    );

    const onChange = (ev, value, reason) => {
        form.setFieldValue(field.name, value);
    };

    return (
        <MUIAutocomplete
            id={fieldId('dyn', field.name)}
            options={options}
            freeSolo
            renderInput={renderInput}
            {...field}
            {...props}
            onChange={onChange}
            onInputChange={onChange}
        />
    );
}

const DynaformField = ({field, ...props}) => {
    return (
        <Field
            component={PharmacySelectField}
            name={field.id}
            label={field.label}
            caption={field.caption}
            required={field.required}
            {...props}
        />
    );
};
export default DynaformField;
