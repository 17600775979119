import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AppBar,
    Button,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    Tabs,
    Tab,
    makeStyles,
    Breadcrumbs,
    Link,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {ExpandMore, Notifications, Flag} from '@material-ui/icons';

import {ducks, useApi} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';
import TabPanel from 'ccm/components/ticket/TabPanel';

import {useTicketActions} from './ticketActions';
import TicketMenu from './TicketMenu';
import ResolveWizard from '../TicketResolveWizard/ResolveWizard';
import Acknowledge from './Acknowledge';
import Reopen from './Reopen';
import Stavatar from './Stavatar';
import PatientTags from './PatientTags';
import PCCPatientBrowser from './PCCPatientBrowser';
import TicketFormData from './TicketFormData';
import TicketHistory from './TicketHistory';
import TicketNarrative from 'ccm/components/TicketNarrative';
import PatientEhrChips from 'ccm/components/PatientEhrChips';
import DynamicAutocomplete from 'ccm/components/DynamicAutocomplete';
import Chat, {InitiateChatButton} from 'ccm/components/Chat';
import SignatureRequestButton from './SignatureRequestButton';
import {useDialog} from 'ccm/components/dialogs';
import RangeAlertsDialog from 'ccm/components/dialogs/RangeAlertsDialog';
import {selectTicketFaxOptions} from 'ccm/lib/selectors-ccm';
import {FaxOptionsField} from 'ccm/components/fields/FaxOptions';

const PROVIDERS_FETCH_OPTIONS = {
    filter: {with_roles: ['practice:.*/provider']},
    sort: ['name'],
};
const OWNERS_FETCH_OPTIONS = {
    filter: {with_roles: ['practice:.*/staff']},
    sort: ['name'],
};
const FACILITIES_FETCH_OPTIONS = {
    sort: ['name'],
};

const useStyles = makeStyles(theme => ({
    ticket: {
        '& .MuiCardHeader-root': {
            alignItems: 'flex-start',
        },
        '& .MuiCardActions-root': {
            padding: theme.spacing(0, 2, 2, 2),
        },
        '& .MuiCardHeader-title > div > .MuiChip-root': {
            margin: '1px 2px 1px 0px',
        },
    },
    chipList: {
        '& .MuiChip-root': {
            margin: theme.spacing(0, 0.5, 0.5, 0.5),
        },
    },
    success: {
        backgroundColor: 'green',
    },
    pending: {
        backgroundColor: theme.palette.secondary.main,
    },
    textArea: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        width: '100%',
        padding: 25,
        paddingTop: 20,
        border: 'none',
    },
    accordionContent: {
        width: '100%',
    },
    ticketActions: {
        justifyContent: 'flex-end',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    subheaderContainer: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
}));

const MismatchPatientWarning = () => (
    <Alert severity='warning'>
        <p>
            WARNING - PATIENT DATA MISMATCH
            <br />
            <br />
            The patient's name on this ticket does not match the name on the
            patient chart.
            <br />
            This may result in orders being issued to the wrong patient chart.
            <br />
            <br />
            Please verify data before responding to this ticket.
        </p>
    </Alert>
);

function TicketMetadata({ticket, actions}) {
    const api = useApi();
    const classes = useStyles();
    const options = useSelector(selectTicketFaxOptions(ticket));

    const facility_label = fp.get('attributes.data.facility_label', ticket);
    const owner_label = fp.get('attributes.data.owner_name', ticket);
    const provider_label = fp.get('attributes.data.provider_name', ticket);

    const handleChange = field => async (ev, value) => {
        actions.patch(ticket, {data: {[field]: value.id}});
    };

    const handleChangeFaxNumber = (ev, value, reason) => {
        const newTicket = fp.set('attributes.data.fax_number', value, ticket);
        console.log('PUT FAX', {value, newTicket});
        actions.put(newTicket);
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                Facility: {facility_label} | Owner: {owner_label} | Provider:{' '}
                {provider_label}
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.accordionContent}>
                    <DynamicAutocomplete
                        label='Facility'
                        value={
                            fp.get('attributes.data.facility_id', ticket) ||
                            null
                        }
                        onChange={handleChange('facility_id')}
                        href={api.url_for('facility.FacilityCollection')}
                        initialInputValue={fp.get(
                            'attributes.data.facility_label',
                            ticket,
                        )}
                        getOptionLabel={fp.get('attributes.name')}
                        fetchOptions={FACILITIES_FETCH_OPTIONS}
                    />
                    <DynamicAutocomplete
                        label='Owner'
                        value={
                            fp.get('attributes.data.owner_id', ticket) || null
                        }
                        onChange={handleChange('owner_id')}
                        href={api.url_for('user.Summaries')}
                        initialInputValue={fp.get(
                            'attributes.data.owner_name',
                            ticket,
                        )}
                        getOptionLabel={fp.get('attributes.name')}
                        fetchOptions={OWNERS_FETCH_OPTIONS}
                    />
                    <DynamicAutocomplete
                        label='Provider'
                        value={
                            fp.get('attributes.data.provider_id', ticket) ||
                            null
                        }
                        onChange={handleChange('provider_id')}
                        href={api.url_for('user.Summaries')}
                        initialInputValue={fp.get(
                            'attributes.data.provider_name',
                            ticket,
                        )}
                        getOptionLabel={fp.get('attributes.name')}
                        fetchOptions={PROVIDERS_FETCH_OPTIONS}
                    />
                    <FaxOptionsField
                        value={fp.getOr(
                            [],
                            'attributes.data.fax_number',
                            ticket,
                        )}
                        onChange={handleChangeFaxNumber}
                        label='Fax-Back Number(s)'
                        name='fax'
                        options={options}
                        multiple
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function TicketMainPanel({ticket}) {
    const narrative = useSelector(
        ducks.jsonapi.selectObject([
            'Narrative',
            fp.get('relationships.narrative.data.id', ticket),
        ]),
    );
    const downloadUrl = fp.get(
        'attributes.data.session_data.download_url',
        ticket,
    );
    return (
        <>
            {downloadUrl ? <audio controls src={downloadUrl} /> : null}
            <TicketNarrative value={narrative} />
        </>
    );
}

function LinkedTickets({ticket}) {
    const classes = useStyles();
    const originalId = fp.get('relationships.originalTicket.data.id', ticket);
    const originalLink =
        originalId &&
        url_for('ticket', {
            ticketid: originalId,
        });
    const followUpLinks = fp.pipe([
        fp.get('relationships.followUpTickets.data'),
        fp.map(linkage => url_for('ticket', {ticketid: linkage.id})),
    ])(ticket);

    const hasFollowUpLinks = !fp.isEmpty(followUpLinks);

    if (!originalLink && !hasFollowUpLinks) return null;
    return (
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
            {originalLink ? (
                <Link to={originalLink} component={R.Link}>
                    Original Ticket
                </Link>
            ) : (
                <p>This Ticket</p>
            )}
            {originalLink && hasFollowUpLinks && <p>This Ticket</p>}
            {hasFollowUpLinks ? (
                <div className={classes.column}>
                    {fp.map(
                        url => (
                            <Link to={url} component={R.Link}>
                                Follow-up Ticket
                            </Link>
                        ),
                        followUpLinks,
                    )}
                </div>
            ) : (
                <p>This Ticket</p>
            )}
        </Breadcrumbs>
    );
}

function Subheader({ticket}) {
    const classes = useStyles();
    const ticketTypes = useAllTicketTypesLookup();
    const ticketTypeKey = fp.get('attributes.data.ticket_type', ticket);
    const ticketTypeValue = fp.getOr('Unknown', ticketTypeKey, ticketTypes);
    return (
        <div className={classes.subheaderContainer}>
            {fp.get('attributes.priority', ticket) === 'urgent' && (
                <span>
                    <Flag color='error' size='large' />
                </span>
            )}
            <span>
                <div>Ticket type: {ticketTypeValue}</div>
                <div>
                    Ticket status: {fp.get('attributes.data.status', ticket)}
                </div>
            </span>
        </div>
    );
}

export default function Ticket({ticket, include, onChanged, chatProps}) {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState('main');
    const actions = useTicketActions(include);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const showPCC = fp.pipe([
        fp.get('attributes.data.patient_identifier'),
        fp.find(
            ident =>
                ident.system ===
                'https://arborian-health.com/coding-system/pcc/patientId',
        ),
    ])(ticket);
    const hasEHR = !fp.isEmpty(
        fp.get('attributes.data.patient_identifier', ticket),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(ticket));
    const rangeDialog = useDialog(RangeAlertsDialog);
    const hasWritePermission = fp.includes(
        'Common.WRITE',
        fp.get('meta.allowed_actions', ticket),
    );
    const [snapshotRequested, setSnapshotRequested] = useState(false);

    useEffect(() => {
        if (
            ticket &&
            hasEHR &&
            hasWritePermission &&
            !fp.get('attributes.has_ehr_snapshots', ticket) &&
            !snapshotRequested
        ) {
            console.log('updateEHRSnapshot with', {ticket, actions, hasEHR});
            actions.updateEHRSnapshot(ticket);
            setSnapshotRequested(true);
        }
    }, [
        ticket,
        actions,
        hasEHR,
        hasWritePermission,
        snapshotRequested,
        setSnapshotRequested,
    ]);

    const handleClickRangeAlerts = async ev => {
        await rangeDialog(ticket);
    };

    const title = rel.patient
        ? rel.patient.attributes.name.formatted
        : `
        ${fp.get('attributes.data.patient_last', ticket)},
        ${fp.get('attributes.data.patient_first', ticket)}
    `;

    const ticketStatus = fp.getOr('pending', 'attributes.data.status', ticket);
    const isFacAdmin = fp.includes('facility_admin', userinfo.scopes);

    // If (lab ticket) AND (user is provider AND practice admin)
    const isLabTicket =
        fp.get('attributes.data.ticket_type', ticket) === 'LABRESULTS';
    const canEditRangeAlerts =
        (fp.includes('practice_provider', userinfo.scopes) &&
            fp.includes('practice_admin', userinfo.scopes)) ||
        fp.includes('__admin__', userinfo.scopes);
    const displayRangeEditor = isLabTicket && canEditRangeAlerts;

    const fmatch =
        ticket.attributes.data.patient_first ===
        fp.get('patient.attributes.name.first', rel);
    const lmatch =
        ticket.attributes.data.patient_last ===
        fp.get('patient.attributes.name.last', rel);

    const isMismatch = rel.patient && (!fmatch || !lmatch);

    if (isFacAdmin) {
        return (
            <Card className={classes.ticket}>
                <CardHeader
                    title={title}
                    subheader={<Subheader ticket={ticket} />}
                    avatar={<Stavatar ticket={ticket} classes={classes} />}
                />
                <CardContent>
                    <TicketMainPanel ticket={ticket} />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className={classes.ticket}>
            <CardHeader
                title={
                    <>
                        <div>{title}</div>
                        <PatientTags patient={rel.patient} />
                        <div>
                            <PatientEhrChips
                                patient={rel.patient}
                                size='small'
                            />
                        </div>
                    </>
                }
                subheader={<Subheader ticket={ticket} />}
                avatar={<Stavatar ticket={ticket} classes={classes} />}
                action={
                    <>
                        <InitiateChatButton
                            open={chatProps.open}
                            handleClick={() =>
                                chatProps.setOpen(!chatProps.open)
                            }
                            hasMessages={chatProps.hasMessages}
                            hasChatPermission={chatProps.hasChatPermission}
                        />
                        {!isFacAdmin && (
                            <TicketMenu ticket={ticket} actions={actions} />
                        )}
                    </>
                }
            />

            <CardContent>
                <Chat {...chatProps} />
                {isMismatch && <MismatchPatientWarning />}
                <LinkedTickets ticket={ticket} />
                <TicketMetadata ticket={ticket} actions={actions} />
                <AppBar position='relative'>
                    <Tabs
                        variant='fullWidth'
                        value={tabValue}
                        onChange={(ev, newValue) => setTabValue(newValue)}
                    >
                        <Tab label='Main' value='main' />
                        {showPCC && <Tab label='PointClickCare' value='pcc' />}
                        <Tab label='Form data' value='form' />
                        <Tab label='Ticket activity' value='history' />
                    </Tabs>
                    {displayRangeEditor && (
                        <Button
                            style={{borderRadius: 0}}
                            startIcon={<Notifications />}
                            size='small'
                            color='default'
                            variant='contained'
                            onClick={handleClickRangeAlerts}
                        >
                            Range Alert Editor
                        </Button>
                    )}
                </AppBar>
                <TabPanel value={tabValue} index='main'>
                    <TicketMainPanel ticket={ticket} />
                </TabPanel>
                <TabPanel value={tabValue} index='pcc'>
                    <PCCPatientBrowser
                        ticket={ticket}
                        patient={rel.patient}
                        actions={actions}
                        onChanged={onChanged}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index='form'>
                    <TicketFormData
                        ticket={ticket}
                        actions={actions}
                        onChanged={onChanged}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index='history'>
                    <TicketHistory
                        ticket={ticket}
                        actions={actions}
                        onChanged={onChanged}
                    />
                </TabPanel>
            </CardContent>

            <CardActions className={classes.ticketActions}>
                {ticketStatus === 'pending' ? (
                    <>
                        <SignatureRequestButton
                            ticket={ticket}
                            actions={actions}
                        />
                        <ResolveWizard ticket={ticket} actions={actions} />
                    </>
                ) : ticketStatus === 'resolved' ? (
                    <>
                        <Reopen ticket={ticket} actions={actions} />
                        <Acknowledge ticket={ticket} actions={actions} />
                    </>
                ) : ticketStatus === 'acknowledged' ? (
                    <Reopen ticket={ticket} actions={actions} />
                ) : (
                    <span>Unknown status {ticketStatus}</span>
                )}
            </CardActions>
        </Card>
    );
}
