import React from 'react';

import {useResources} from 'ccm/dataSource';
import OrderTemplateTable from 'ccm/components/OrderTemplateTable';

const SHARED_TEMPLATE_FETCH_OPTIONS = {
    filter: {
        'attributes.practice_id': null,
    },
    page: {number: 0, size: 20},
};

export default function OrderTemplateAdminTable() {
    const link = 'order.TemplateCollection';

    const dataSource = useResources({
        link,
        prefix: 'order.',
        initialFetchOptions: SHARED_TEMPLATE_FETCH_OPTIONS,
        type: 'OrderTemplate',
    });

    const editable = {
        onRowAdd: dataSource.handleAdd,
        // Editing happens in detail panel instead
        // onRowUpdate: dataSource.handleUpdate,
        onRowDelete: dataSource.handleDelete,
    };

    return (
        <OrderTemplateTable
            title='Order Templates'
            dataSource={dataSource}
            editable={editable}
            isAdmin
        />
    );
}
