import React from 'react';

import {Paper, makeStyles} from '@material-ui/core';

import useTicketsReport from './hooks';
import ReportTypeForm from './ReportTypeForm';
import ReportTable from './ReportTable';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
}));

export default function TicketReports() {
    const classes = useStyles();
    const ds = useTicketsReport();
    return (
        <Paper className={classes.root}>
            <ReportTypeForm
                onSubmit={v => {
                    ds.setReportFilter(v);
                    return true;
                }}
            />
            <hr />
            <ReportTable dataSource={ds} />
        </Paper>
    );
}
