import React from 'react';

import {AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

import {AccordionProvider, Accordion} from 'ccm/components/TabPanels';
import PracticeUpcomingVisitsTable from './scheduling/PracticeUpcomingVisitsTable';
import VisitsCalendar from './scheduling/VisitsCalendar';
import VisitTypeAdmin from './scheduling/VisitTypeAdmin';
import VisitCreator from './scheduling/VisitCreator';
import TagAdmin from './tags/TagAdmin';

export default function SchedulingPanel({practice}) {
    const api = useApi();
    return (
        <AccordionProvider queryKey='visitTab'>
            <Accordion index='calendar'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Calendar</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <VisitsCalendar practice={practice} />
                </AccordionDetails>
            </Accordion>
            <Accordion index='vtypes'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Visit Types</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <VisitTypeAdmin practice={practice} />
                </AccordionDetails>
            </Accordion>
            <Accordion index='tags'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Patient Tags</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <TagAdmin
                        owner={practice}
                        href={api.url_for('practice.PatientTags', {
                            practice_id: practice.id,
                        })}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion index='patients'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Patients</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <VisitCreator practice={practice} />
                </AccordionDetails>
            </Accordion>
            <Accordion index='table'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Visits</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <PracticeUpcomingVisitsTable practice={practice} />
                </AccordionDetails>
            </Accordion>
        </AccordionProvider>
    );
}
