import React from 'react';
import fp from 'lodash/fp';

export default function AddressBlock({value}) {
    if (!value) return null;
    let lines = [];
    if (value.line1) {
        lines.push(value.line1);
    }
    if (value.line2) {
        lines.push(value.line2);
    }
    lines.push(`${value.city}, ${value.state} ${value.postalCode}`);
    return (
        <address>
            {fp.map(
                line => (
                    <React.Fragment key={line}>
                        {line}
                        <br />
                    </React.Fragment>
                ),
                lines,
            )}
        </address>
    );
}
