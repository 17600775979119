import React from 'react';
import fp from 'lodash/fp';

import {Hidden, makeStyles} from '@material-ui/core';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from '@material-ui/core';
import {ExpandMore, LocalHospital} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';

import {useActions} from 'ccm/lib/hooks';
import BasePage from 'ccm/components/BasePage';
import PatientFiles from 'ccm/components/PatientFiles';
import PatientSummary from 'ccm/components/PatientSummary';
import Inbox from 'ccm/components/Inbox';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.constants.drawerWidth,
        },
        display: 'flex',
    },
    contentArea: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2),
        },
        padding: theme.spacing(0, 1),
        gap: theme.spacing(1),
    },
    sidebar: {
        width: theme.constants.sidebarWidth,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
    },
    contactProviderButton: {
        padding: theme.spacing(3, 0),
        '& .MuiButton-label': {...theme.typography.h6, fontWeight: 900},
        '& .MuiButton-iconSizeLarge > :first-child': {fontSize: '2.25rem'},
    },
    patientMessageHistory: {
        '& .MuiAccordionDetails-root': {flexDirection: 'column'},
    },
}));

const ContactProviderButton = ({patient}) => {
    const classes = useStyles();
    const actions = useActions();
    const hasPractice = fp.get('relationships.practice.data.id', patient);
    if (!hasPractice) return null;
    return (
        <Button
            className={classes.contactProviderButton}
            onClick={() => actions.createQuestionFlowTicket(patient)}
            startIcon={<LocalHospital />}
            size='large'
            variant='contained'
            color='primary'
            id='btn-contact-healthcare-team'
        >
            Contact your healthcare team
        </Button>
    );
};

const PatientMessageHistory = ({patient}) => {
    const classes = useStyles();

    return (
        <Accordion className={classes.patientMessageHistory}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h6>Message History</h6>
            </AccordionSummary>
            <AccordionDetails>
                <Alert severity='info'>
                    This is a record of all messages sent to this patient's
                    chart.
                </Alert>
                <Inbox patient={patient} showAllMessages title='' />
            </AccordionDetails>
        </Accordion>
    );
};

// TODO: Fix PatientFiles so it degrades to a list and uses the datasource
export default function BasePatientPage({
    title,
    children,
    patient,
    showFiles = true,
}) {
    const classes = useStyles();
    if (!patient) return null;
    return (
        <BasePage wide patient title={title}>
            <div className={classes.root}>
                <div className={classes.contentArea}>
                    <Hidden lgUp>
                        <PatientSummary patient={patient} />
                        <ContactProviderButton patient={patient} />
                    </Hidden>
                    {children}
                    <Hidden lgUp>
                        <PatientMessageHistory patient={patient} />
                    </Hidden>
                    {showFiles ? (
                        <Hidden lgUp>
                            <br />
                            <PatientFiles patient={patient} />
                        </Hidden>
                    ) : null}
                </div>
                <Hidden mdDown>
                    <div className={classes.sidebar}>
                        <PatientSummary patient={patient} />
                        <ContactProviderButton patient={patient} />
                        <PatientMessageHistory patient={patient} />
                        {showFiles ? <PatientFiles patient={patient} /> : null}
                    </div>
                </Hidden>
            </div>
        </BasePage>
    );
}
