import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';

import {Draggable, Container} from 'react-smooth-dnd';
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    TextField,
} from '@material-ui/core';
import {Add, Delete, DragHandle, Remove} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';
import {useFetchedRelationshipReverse} from 'ccm/dataSource/misc';

const useOrderTemplateInstructions = orderTemplate =>
    useFetchedRelationshipReverse(
        orderTemplate,
        'instructions',
        'Instruction',
        'order_template',
    );

function ReadOnlyInstruction({inst}) {
    return (
        <ListItem>
            <ListItemIcon>
                <Remove />
            </ListItemIcon>
            <ListItemText primary={fp.get('attributes.text', inst)} />
        </ListItem>
    );
}

function Instruction({inst, onSelect}) {
    const api = useApi();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState(
        fp.get('attributes.text', inst),
    );
    const acceptChange = async () => {
        await api.fetchJson(inst.links.self, {
            method: 'PATCH',
            json: {data: fp.set('attributes.text', inputValue, inst)},
        });
    };
    const handleDelete = async ev => {
        await api.fetchJson(inst.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(inst));
    };

    return (
        <Draggable>
            <ListItem>
                <ListItemIcon
                    className='drag-handle'
                    id={`instructionDrag-${inst.id}`}
                >
                    <DragHandle />
                </ListItemIcon>
                <ListItemText>
                    <TextField
                        id={`instructionTextField-${inst.id}`}
                        fullWidth
                        placeholder='New instruction'
                        value={inputValue}
                        onChange={ev => setInputValue(ev.target.value)}
                        onBlur={acceptChange}
                        onKeyPress={ev => ev.key === 'Enter' && acceptChange()}
                    />
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton
                        id={`instructionDelete-${inst.id}`}
                        edge='end'
                        onClick={handleDelete}
                    >
                        <Delete />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </Draggable>
    );
}

export default function InstructionList({orderTemplate, enableAdminOptions}) {
    const api = useApi();
    const instructions = fp.sortBy(
        'attributes.position',
        useOrderTemplateInstructions(orderTemplate),
    );

    const href = fp.get(
        'relationships.instructions.links.related',
        orderTemplate,
    );
    const handleDrop = async ({removedIndex, addedIndex}) => {
        let newList = [...instructions];
        let [it] = newList.splice(removedIndex, 1);
        newList.splice(addedIndex, 0, it);
        const promises = fp.pipe([
            fp.zip(fp.range(0, instructions.length)),
            fp.map(async ([pos, inst]) => {
                if (inst.attributes.position !== pos) {
                    return await api.fetchJson(inst.links.self, {
                        method: 'PATCH',
                        json: {data: fp.set('attributes.position', pos, inst)},
                    });
                } else {
                    return inst;
                }
            }),
        ])(newList);
        await Promise.all(promises);
    };
    const handleAdd = useCallback(
        async ev => {
            await api.fetchJson(href, {
                method: 'POST',
                json: {
                    data: {
                        type: 'Instruction',
                        attributes: {
                            position: instructions.length,
                            text: '',
                        },
                    },
                },
            });
        },
        [api, href, instructions],
    );

    if (!enableAdminOptions) {
        return (
            <div>
                <List>
                    {fp.map(
                        inst => (
                            <ReadOnlyInstruction key={inst.id} inst={inst} />
                        ),
                        instructions,
                    )}
                </List>
            </div>
        );
    } else {
        return (
            <div>
                <Button
                    id='AddInstruction'
                    variant='outlined'
                    startIcon={<Add />}
                    onClick={handleAdd}
                >
                    New Instruction
                </Button>
                <List>
                    <Container
                        lockAxis='y'
                        onDrop={handleDrop}
                        dragHandleSelector='.drag-handle'
                    >
                        {fp.map(
                            inst => (
                                <Instruction key={inst.id} inst={inst} />
                            ),
                            instructions,
                        )}
                    </Container>
                </List>
            </div>
        );
    }
}
