import React, {useCallback, useMemo} from 'react';
import fp from 'lodash/fp';

import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

function getLinkage(opt) {
    const {type, id} = opt;
    return {type, id};
}

function getLinkageSelected(opt, value) {
    return fp.isEqual(getLinkage(opt), value);
}

export default function LinkageAutocompleteField({
    form,
    field,
    options,
    label,
    ...props
}) {
    const {name} = field;
    const {touched, errors, setFieldValue} = form;
    const value = useMemo(() => {
        return fp.find(
            opt => fp.isEqual(getLinkage(opt), field.value),
            options,
        );
    }, [options, field.value]);
    const handleChange = useCallback(
        (ev, value) => {
            const linkage = getLinkage(value);
            if (linkage) {
                setFieldValue(name, linkage);
            } else {
                setFieldValue(name, null);
            }
        },
        [name, setFieldValue],
    );
    const fieldTouched = fp.get(name, touched);
    const fieldError = fp.get(name, errors);

    return (
        <Autocomplete
            value={value}
            onChange={handleChange}
            options={options}
            getOptionSelected={getLinkageSelected}
            renderInput={params => (
                <TextField
                    {...params}
                    error={fieldTouched && !!fieldError}
                    helperText={fieldError}
                    label={label}
                />
            )}
            {...props}
        />
    );
}
