import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import fp from 'lodash/fp';

import {useApi, ducks} from '@arborian/narrf';

import Actions from 'ccm/lib/actions';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

import {useDialog} from 'ccm/components/dialogs';
import InvitationDialog from 'ccm/components/dialogs/InvitationDialog';
export {useCalendarEvents} from './calendarHooks';

export function useActions() {
    const dispatch = useDispatch();
    const api = useApi();
    const history = useHistory();
    const user = useSelector(selectCurrentUser);
    const invitationDialog = useDialog(InvitationDialog);
    const result = useMemo(
        () => new Actions({dispatch, api, history, user, invitationDialog}),
        [dispatch, api, history, user, invitationDialog],
    );
    return result;
}

export function useTicketSessionOrTicket() {
    // Only used by the ImageAttachment field.
    // ImageAttachment needs access to Session or Ticket for POSTs.
    const params = useParams();
    const sessionid = params.sessionid;
    const ticketid = params.ticketid;
    const session = useSelector(
        ducks.jsonapi.selectObject(['TicketSession', sessionid]),
    );
    const ticket = useSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketid]),
    );
    return session || ticket;
}

export const usePatientInvitationActions = patient => {
    // Patient invitations are complicated.
    // The following can invite to patient groups:
    //  * current admins of the patient's care group
    //  * admins for the patient's facility
    //  * admins or staff for the patient's practice
    const user = useSelector(selectCurrentUser);

    const patientId = fp.get('id', patient);
    const practiceId = fp.get('relationships.practice.data.id', patient);
    const facilityId = fp.get('relationships.facility.data.id', patient);

    const patientAdminGroupId = `patient:${patientId}/admin`;
    const patientPracticeGroups = [
        `practice:${practiceId}/admin`,
        `practice:${practiceId}/staff`,
    ];
    const patientFacilityGroup = `facility:${facilityId}/admin`;

    const allowedGroupIds = [
        patientAdminGroupId,
        ...patientPracticeGroups,
        patientFacilityGroup,
    ];
    const userGroupIds = fp.map(
        'id',
        fp.get('relationships.groups.data', user),
    );
    const matchingGroups = fp.intersection(allowedGroupIds, userGroupIds);
    if (fp.isEmpty(matchingGroups)) {
        return [];
    } else {
        return ['Common.READ', 'Common.WRITE', 'Common.CREATE'];
    }
};
