import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Save, Delete} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import * as yup from 'yup';

import {useFetchedRelationshipReverse} from 'ccm/dataSource/misc';
import {ProviderSelectField} from 'ccm/components/fields/ProviderSelect';
import VisitTicketsTable from './VisitTicketsTable';
import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';
import {useVisitTickets} from './hooks';

const defaultOptions = {
    submitLabel: 'Update Visit',
};

const schema = yup.object().shape({
    attributes: yup.object().shape({
        visit_date: yup.date(),
        reason: yup.string(),
    }),
    relationships: yup.object().shape({
        visit_type_rel: yup.object().shape({
            data: yup.object().shape({
                id: yup.string(),
                type: yup.string(),
            }),
        }),
        provider: yup.object().shape({
            data: yup.object().shape({
                id: yup.string(),
                type: yup.string(),
            }),
        }),
    }),
});

export default function VisitDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [options, setOptions] = useState(defaultOptions);
    const [practice, setPractice] = useState();
    const handleSubmit = data => {
        resolve({action: 'SUBMIT', data});
        setResolve(null);
    };

    const handleDelete = () => {
        resolve({action: 'DELETE'});
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({action: 'CANCEL'});
        setResolve(null);
    };
    const tickets = useVisitTickets(initialValues);
    const visitTypes = useFetchedRelationshipReverse(
        practice,
        'visitTypes',
        'VisitType',
        'practice',
    );

    dialogRef.current = ({visit, practice}, options = defaultOptions) => {
        return new Promise((resolve, reject) => {
            setInitialValues(visit);
            setPractice(practice);
            setOptions(options);
            setResolve(() => resolve);
            console.log('initialValues', initialValues);
        });
    };
    if (fp.isEmpty(visitTypes)) return null;

    return (
        <Dialog maxWidth='lg' fullWidth open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>
                            Visit {fp.get('attributes.patient_first', values)}{' '}
                            {fp.get('attributes.patient_last', values)} at{' '}
                            {fp.get('attributes.facility_name', values)}
                        </DialogTitle>
                        <DialogContent>
                            <Field
                                component={KeyboardDatePicker}
                                label='Visit Date'
                                name='attributes.visit_date'
                                disableToolbar
                                fullWidth
                                variant='inline'
                                format='MM/dd/yyyy'
                                margin='normal'
                            />
                            <Field
                                component={ProviderSelectField}
                                label='Provider'
                                name='relationships.provider.data'
                                practice={practice}
                                fullWidth
                            />
                            <Field
                                component={LinkageAutocompleteField}
                                label='Appointment Type'
                                name='relationships.visit_type_rel.data'
                                options={visitTypes}
                                getOptionLabel={fp.get('attributes.name')}
                                autoComplete
                                autoHighlight
                                autoSelect
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                label='Visit Reason'
                                name='attributes.reason'
                                fullWidth
                            />
                            <VisitTicketsTable value={tickets} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                startIcon={<Delete />}
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                startIcon={<Save />}
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
