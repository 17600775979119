import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {Chip} from '@material-ui/core';
import {Link} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';

const selectSecondaryPatients = patient =>
    createSelector(
        ducks.jsonapi.selectObject('Patient'),
        fp.filter(
            p =>
                fp.get('relationships.primary_patient.data.id', p) ===
                fp.get('id', patient),
        ),
    );

const selectEhrConnections = patient =>
    createSelector(
        selectSecondaryPatients(patient),
        ducks.jsonapi.selectObject('EHRConnection'),
        (secondaryPatients, ehrConnections) => {
            const patients = [patient, ...secondaryPatients];
            const ehrIds = fp.pipe([
                fp.map(fp.get('relationships.ehr_connection.data.id')),
                fp.uniq,
            ])(patients);
            return fp.filter(
                ehr => fp.includes(ehr.id, ehrIds),
                ehrConnections,
            );
        },
    );

export default function PatientEhrChips({patient, ...props}) {
    const api = useApi();
    useEffect(() => {
        const secondaryPatientsHref = fp.get(
            'relationships.secondary_patients.links.related',
            patient,
        );
        if (secondaryPatientsHref) {
            api.fetchAllJsonApi(secondaryPatientsHref, {
                include: ['ehr_connection'],
            });
        }
    }, [api, patient]);
    const ehrConnections = useSelector(selectEhrConnections(patient));
    if (!patient) return null;

    return (
        <>
            {fp.map(
                ehr => (
                    <Chip
                        key={ehr.id}
                        variant='outlined'
                        icon={<Link />}
                        style={ehr.attributes.tag_css}
                        label={ehr.attributes.tag_label}
                        {...props}
                    />
                ),
                ehrConnections,
            )}
        </>
    );
}
