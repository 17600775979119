import React from 'react';
import {useSelector} from 'react-redux';

import {ducks} from '@arborian/narrf';

import AssignmentsTable from './AssignmentsTable';

export default function RuleEditor({ruleset, ruleId}) {
    const rule = useSelector(ducks.jsonapi.selectObject(['Rule', ruleId]));
    if (rule) {
        return <AssignmentsTable ruleset={ruleset} rule={rule} />;
    } else {
        return null;
    }
}
