import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';

import {useApi, ducks} from '@arborian/narrf';

import {selectRulesetFacilities} from './routingSelectors';
import FacSelectTable from './FacSelectTable';

const useStyles = makeStyles(theme => ({
    chipList: {
        display: 'flex',
        columnGap: theme.spacing(1),
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            margin: theme.spacing(1, 0),
        },
    },
    accordionDetails: {
        display: 'block',
    },
}));

export default function RuleFacilities({ruleset}) {
    const api = useApi();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [facilityIds, setFacilityIds] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const facilitySelector = useCallback(selectRulesetFacilities(ruleset), [
        ruleset,
    ]);
    const facilities = useSelector(facilitySelector);
    const facIds = useMemo(() => fp.map('id', facilities), [facilities]);

    const handleUpdate = async showFacIds => {
        const data = fp.set('attributes.facility_ids', showFacIds, ruleset);
        dispatch(ducks.jsonapi.clearType({type: 'Rule'}));
        dispatch(ducks.jsonapi.clearType({type: 'Assignment'}));
        dispatch(ducks.jsonapi.clearType({type: 'Shift'}));
        return await api.fetchJsonApi(ruleset.links.self, {
            method: 'PATCH',
            json: {data},
            include: ['rules', 'assignments', 'shifts'],
        });
    };

    useEffect(() => {
        // For "visible" column
        setFacilityIds(fp.get('attributes.facility_ids', ruleset));
    }, [ruleset, setFacilityIds]);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h5>Available facilities: {fp.size(facIds)}</h5>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <Alert severity='info'>
                    Set facilities as "Visible" to make them available for use
                    in routing rules.
                </Alert>
                <FacSelectTable
                    facilityIds={facilityIds}
                    setFacilityIds={setFacilityIds}
                    handleUpdate={handleUpdate}
                />
            </AccordionDetails>
        </Accordion>
    );
}
