import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import fp from 'lodash/fp';

import {useApi, ducks} from '@arborian/narrf';

import MessageDetailPanel from 'ccm/components/MessageDetailPanel';

export default function MessageDetails({
    inboxMessageId,
    showAllMessages,
    ...props
}) {
    const api = useApi();
    const imsg = useSelector(
        ducks.jsonapi.selectObject(['InboxMessage', inboxMessageId]),
    );
    const msg = useSelector(
        ducks.jsonapi.selectObject(['Message', inboxMessageId]),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(imsg));
    const url = fp.get('links.self', imsg);
    const unread = !fp.get('attributes.read', imsg);
    useEffect(() => {
        if (url) {
            api.fetchJsonApi(url, {include: ['message']});
            if (unread) {
                api.fetchJson(url, {
                    method: 'PATCH',
                    json: {data: fp.set('attributes.read', true, imsg)},
                }).then(api.fetchJson(api.url_for('user.Current')));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, unread, api]);
    return (
        <MessageDetailPanel
            message={msg || fp.get('message', rel)}
            {...props}
        />
    );
}
