import React from 'react';

import {
    makeStyles,
    Card,
    CardHeader,
    CardContent,
    Paper,
    Link,
} from '@material-ui/core';
import {OpenInNew} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';

import CmsFacilitiesTable from 'ccm/components/CmsFacilitiesTable';

const useStyles = makeStyles(theme => ({
    QMParent: {
        padding: theme.spacing(2),
        gap: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    QMContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: theme.spacing(2),
    },
    ratingCard: {
        width: 200,
    },
    ratingCardContent: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    ratingLg: {
        fontSize: theme.spacing(4),
    },
    linkBox: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function CMSFacilityWidget({dataSource}) {
    const classes = useStyles();

    if (!dataSource.count) return null;
    if (dataSource.count > 1) {
        return (
            <CmsFacilitiesTable
                dataSource={dataSource}
                compact={true}
                tableOptions={{search: false, filtering: false}}
            />
        );
    } else {
        if (!dataSource.items[0]) return null;
        const cms = dataSource.items[0].data;
        return (
            <Paper className={classes.QMParent}>
                <h5>{cms.attributes.name}</h5>
                <h6>CMS Quality Measures</h6>
                <p>
                    <Link
                        href={cms.links.cms_link}
                        target='_blank'
                        className={classes.linkBox}
                    >
                        View Details &nbsp; <OpenInNew />
                    </Link>
                </p>
                <div className={classes.QMContainer}>
                    <Card className={classes.ratingCard}>
                        <CardHeader
                            className={classes.ratingCardContent}
                            title='Overall Rating'
                        />
                        <CardContent className={classes.ratingCardContent}>
                            <Rating
                                className={classes.ratingLg}
                                value={
                                    cms.attributes
                                        .quality_measure_rating_overall
                                }
                                readOnly
                            />
                        </CardContent>
                    </Card>
                    <Card className={classes.ratingCard}>
                        <CardHeader
                            className={classes.ratingCardContent}
                            title='Short-Stay Rating'
                        />
                        <CardContent className={classes.ratingCardContent}>
                            <Rating
                                className={classes.ratingLg}
                                value={
                                    cms.attributes
                                        .quality_measure_rating_short_stay
                                }
                                readOnly
                            />
                        </CardContent>
                    </Card>
                    <Card className={classes.ratingCard}>
                        <CardHeader
                            className={classes.ratingCardContent}
                            title='Long-Stay Rating'
                        />
                        <CardContent className={classes.ratingCardContent}>
                            <Rating
                                className={classes.ratingLg}
                                value={
                                    cms.attributes
                                        .quality_measure_rating_long_stay
                                }
                                readOnly
                            />
                        </CardContent>
                    </Card>
                </div>
            </Paper>
        );
    }
}
