import React, {useState} from 'react';
import fp from 'lodash/fp';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {Tab, Tabs} from '@material-ui/core';

import {components, ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useFacilities} from 'ccm/dataSource';
import TelecomChips from 'ccm/components/TelecomChips';
import AddressBlock from 'ccm/components/AddressBlock';
import InvitationAdminTable from 'ccm/components/InvitationAdminTable';
import GroupMemberTable from 'ccm/components/GroupMemberTable';

const {dt2} = components;
const INITIAL_FETCH_OPTIONS = {
    filter: {'attributes.active': true},
    page: {number: 0, size: 20},
    sort: {field: 'attributes.name', direction: 'asc'},
};

function FacilityDetail({value}) {
    const [tab, setTab] = useState(0);
    return (
        <div>
            <h6>{fp.get('attributes.name', value)}</h6>
            <AddressBlock value={fp.get('attributes.address', value)} />
            <TelecomChips value={fp.get('attributes.telecom', value)} />
            <Tabs value={tab} onChange={(ev, index) => setTab(index)}>
                <Tab label='Authorized Users' />
                <Tab label='Invitations' />
            </Tabs>

            {tab === 0 ? (
                <GroupMemberTable protectedResource={value} />
            ) : (
                <InvitationAdminTable protectedResource={value} />
            )}
        </div>
    );
}

export function FacilityLinkComponent({row}) {
    return (
        <Link to={url_for('facility', {facilityId: row.data.id})}>
            {fp.get('attributes.name', row.data)}
        </Link>
    );
}

export default function FacilityAdminTable() {
    const ds = useFacilities({
        type: 'Facility',
        prefix: 'facTable.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
    });
    const refresh = () => ds.fetch();

    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isSuperuser = userinfo && fp.includes('__admin__', userinfo.scopes);

    const tableControls = isSuperuser
        ? {
              onRowAdd: ds.handleAdd,
              //   onRowDelete: ds.handleDelete,  // Just for testing
          }
        : undefined;

    return (
        <dt2.DataTable
            title='Facility Administration'
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            editable={tableControls}
        >
            <dt2.Column
                title='Name'
                field='attributes.name'
                component={FacilityLinkComponent}
            />
            <dt2.Column title='Organization' field='attributes.org_name' />
            <dt2.Column
                title='Active'
                field='attributes.active'
                type='boolean'
                render={row =>
                    fp.get('data.attributes.active', row)
                        ? 'Active'
                        : 'Inactive'
                }
            />
            <dt2.Column
                title='Address'
                filtering={false}
                field='attributes.address.oneLine'
                editable={false}
            />
            <dt2.Column
                title='Telecom'
                field='attributes.telecom'
                filtering={false}
                component={TelecomChips}
                editable={false}
            />
            <dt2.DetailPanel
                render={row => <FacilityDetail value={row.data} />}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
