import React from 'react';
import fp from 'lodash/fp';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import PatientAvatar from 'ccm/components/PatientAvatar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function PatientNameColumnComponent({row}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data));
    const href = url_for('patient', {patientId: rel.patient.id});
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Link to={href}>
                <PatientAvatar patient={rel.patient} />
            </Link>

            <Link to={href}>
                {fp.get('patient.attributes.name.formatted', rel)}
            </Link>
        </div>
    );
}
