import {useMemo, useCallback} from 'react';
import fp from 'lodash/fp';

export function useRenderProps({children, ...props}, dependencies) {
    let overrideFunction = null;
    if (children) {
        if (fp.isFunction(children)) {
            overrideFunction = children;
        } else {
            console.error(
                'Children must be a single function returning overrides',
                {children},
            );
        }
    }
    return useCallback(
        row => {
            let result = props;
            if (overrideFunction) {
                result = fp.merge(result, overrideFunction(row, result));
            }
            return result;
        },
        [overrideFunction, ...dependencies], // eslint-disable-line react-hooks/exhaustive-deps
    );
}

export function useLocationFetchOptions(defaults = {}, prefix = '') {
    const search = window.location.search;
    return useMemo(() => {
        const params = new URLSearchParams(search);
        return locationFetchOptions(params, defaults, prefix);
    }, [search, defaults, prefix]);
}

function locationFetchOptions(params, defaultFetchOptions = {}, prefix = '') {
    let result = defaultFetchOptions;
    const rFilter = /filter\[(.*)\]/;
    const rPage = /page\[(number|size)\]/;
    for (let [key, val] of params) {
        if (key.substring(0, prefix.length) !== prefix) continue;
        key = key.substring(prefix.length);
        const filter = key.match(rFilter);
        const page = key.match(rPage);
        if (filter) {
            result = fp.set(['filter', filter[1]], JSON.parse(val), result);
        } else if (page) {
            result = fp.set(['page', page[1]], JSON.parse(val), result);
        } else if (key === 'sort') {
            if (val === '') {
                delete result.sort;
            } else if (val[0] === '-') {
                result.sort = {field: val.substring(1), direction: 'desc'};
            } else {
                result.sort = {field: val, direction: 'asc'};
            }
        } else if (key === 'q') {
            result.search = val;
        }
    }
    return result;
}

export function updateLocationFetchOptions(fetchOptions, prefix = '') {
    let params = new URLSearchParams(window.location.search);
    // Clear any filters/page options
    const rFilter = /filter\[(.*)\]/;
    const rPage = /page\[(number|size)\]/;
    let keysToDelete = [];
    for (const key of params.keys()) {
        if (key.substring(0, prefix.length) !== prefix) continue;
        const k = key.substring(prefix.length);
        if (k.match(rFilter) || k.match(rPage) || k === 'sort' || k === 'q') {
            keysToDelete.push(key);
        } else {
            debugger;
        }
    }
    fp.forEach(key => params.delete(key), keysToDelete);
    // Add fetchOptions to params
    fp.pipe([
        fp.get('filter'),
        fp.toPairs,
        fp.forEach(([fkey, fval]) =>
            params.set(`${prefix}filter[${fkey}]`, JSON.stringify(fval)),
        ),
    ])(fetchOptions);
    fp.pipe([
        fp.get('page'),
        fp.toPairs,
        fp.forEach(([fkey, fval]) =>
            params.set(`${prefix}page[${fkey}]`, JSON.stringify(fval)),
        ),
    ])(fetchOptions);
    if (fetchOptions.search) params.set(`${prefix}q`, fetchOptions.search);
    if (fetchOptions.sort) {
        const {field, direction} = fetchOptions.sort;
        if (direction === 'asc') {
            params.set(`${prefix}sort`, field);
        } else {
            params.set(`${prefix}sort`, '-' + field);
        }
    } else {
        params.set(`${prefix}sort`, '');
    }
    return params;
}
