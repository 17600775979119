import React, {useState} from 'react';

import {Dialog} from '@material-ui/core';

import {Formik} from 'formik';

export default function FormDialog({
    dialogRef,
    children,
    dialogProps = {},
    ...props
}) {
    const [resolve, setResolve] = useState(null);

    const open = () => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    const handleSubmit = values => {
        setResolve(null);
        resolve && resolve(values);
    };

    const handleCancel = () => {
        setResolve(null);
        resolve && resolve(null);
    };

    dialogRef.current = {open, handleSubmit, handleCancel};

    return (
        <Dialog open={!!resolve} onClose={handleCancel} {...dialogProps}>
            <Formik onSubmit={handleSubmit} {...props}>
                {children}
            </Formik>
        </Dialog>
    );
}
