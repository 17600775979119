import React from 'react';
import fp from 'lodash/fp';
import {makeStyles} from '@material-ui/core';
import {components} from '@arborian/narrf';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        maxWidth: '100%',
        overflow: 'auto',
        '& table tr th:first-child,td:first-child': {
            position: 'sticky',
            left: 0,
            zIndex: 2,
            backgroundColor: 'white',
        },
    },
}));

export default function FlowsheetTable({dataSource}) {
    const classes = useStyles();
    const {dates} = dataSource;
    return (
        <div className={classes.container}>
            <dt2.DataTable
                key={Math.random()}
                size='small'
                title='Flowsheet'
                dataSource={dataSource}
                options={{paging: false, search: false, filtering: false}}
            >
                <dt2.Column
                    title='Code'
                    render={r => `${r.data.code_name} (${r.data.code_value})`}
                />
                {fp.map(
                    dt => (
                        <dt2.Column field={dt} title={dt} />
                    ),
                    dates || [],
                )}
            </dt2.DataTable>
        </div>
    );
}
