import React, {useRef} from 'react';
import fp from 'lodash/fp';

import {TextareaAutosize, Paper, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    textArea: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        width: '100%',
        padding: 25,
        paddingTop: 20,
        border: 'none',
    },
}));

export default function TicketNarrative({value, classes}) {
    classes = {...useStyles(), ...classes};
    const textAreaRef = useRef(null);
    return (
        <>
            <Paper elevation={2}>
                <TextareaAutosize
                    id='narrativeTextArea'
                    ref={textAreaRef}
                    className={classes.textArea}
                    minRows={5}
                    aria-label='textarea'
                    placeholder=''
                    value={fp.get('attributes.narrative', value)}
                ></TextareaAutosize>
            </Paper>
        </>
    );
}
