import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Link} from '@material-ui/core';
import {useRelated} from 'ccm/dataSource';
import * as h from 'ccm/lib/helpers';

import {useApi, components, ducks} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';
import ConfirmationDialog from 'ccm/components/dialogs/ConfirmationDialog';

const {dt2} = components;

const DEFAULT_FETCH_OPTIONS = {
    filter: {
        'attributes.taskType': 'labResult',
    },
    page: {number: 0, size: 0},
};

function useTasks(ehrConfig, props) {
    return useRelated(ehrConfig, 'tasks', {
        prefix: 'pf-tasks.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        ...props,
    });
}

function useEHRConfig(ehrConnection) {
    const config = useSelector(
        ducks.jsonapi.selectObject(['EHRConfig', ehrConnection.id]),
    );
    return config;
}

function PatientView({task}) {
    const sDate = h.formatDateOnly(
        fp.get('attributes.patient.dateOfBirth', task),
    );
    return (
        <span>
            {fp.get('attributes.patient.name', task)} (DOB {sDate})
        </span>
    );
}

function LabLink({task}) {
    const assignedPatientPracticeGuid = fp.get(
        'attributes.assignedPatientPracticeGuid',
        task,
    );
    const href_parts = [
        'https://static.practicefusion.com/apps/ehr/index.html',
        '#/PF/charts/patients/',
        assignedPatientPracticeGuid,
        '/results/',
        task.id,
    ];
    const href = href_parts.join('');
    return (
        <Link href={href} target='_blank'>
            {fp.get('attributes.taskSummary', task)}
        </Link>
    );
}

export default function PFSignLabs({practice, ehr}) {
    const api = useApi();
    const ehrConfig = useEHRConfig(ehr);
    const dataSource = useTasks(ehrConfig);
    const confirmSign = useDialog(ConfirmationDialog);
    const handleSign = async (ev, items) => {
        if (
            await confirmSign({
                title: 'Are you sure?',
                prompt: `This will permanently finalize the selected result reports as a legal document 
                You will not be able to make edits after signing.`,
                yesLabel: 'Yes, sign all selected labs.',
                noLabel: 'Cancel',
            })
        ) {
            const promises = fp.map(
                item =>
                    api.fetch(fp.get('links.sign', item.data), {method: 'PUT'}),
                items,
            );
            await Promise.all(promises);
            dataSource.fetch();
        }
    };

    return (
        <dt2.DataTable
            size='small'
            title='Lab results to be signed'
            options={{selection: true}}
            dataSource={dataSource}
        >
            <dt2.Column title='Type' field='attributes.taskType' />
            <dt2.Column
                title='Summary'
                field='attributes.taskSummary'
                render={row => <LabLink task={row.data} />}
            />
            <dt2.Column title='Status' field='attributes.status' />
            <dt2.Column
                title='Assigned'
                field='attributes.assignedPersonName'
            />

            <dt2.Column
                title='Patient'
                render={row => <PatientView task={row.data} />}
            />
            <dt2.Action
                name='sign'
                onClick={handleSign}
                tooltip='Sign selected reports'
                icon='assignment_turned_in'
            />
            <dt2.Action
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
