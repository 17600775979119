import React, {useEffect, useState} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {useApi, ducks} from '@arborian/narrf';
import {TextField} from '@material-ui/core';
import {Autocomplete as MUIAutocomplete} from '@material-ui/lab';

import {fieldId} from 'ccm/lib/util';

const getMedName = med => {
    const desc = fp.get('attributes.description', med);
    const generic = fp.get('attributes.generic', med) || 'Unknown';
    return desc === 'scrubbed' ? generic : desc;
};

const selectPatientMeds = patientId =>
    createSelector(
        ducks.jsonapi.selectObject('Medication'),
        fp.filter(
            med => patientId === fp.get('relationships.patient.data.id', med),
        ),
    );

export function PatientMedicationSelectField({field, form, ...props}) {
    const api = useApi();
    const ticketSession = useSelector(
        ducks.jsonapi.selectObject(['TicketSession', props.sessionId]),
    );
    const patientId = fp.get('attributes.patient_id', ticketSession);
    const meds = useSelector(selectPatientMeds(patientId));
    const options = fp.map(getMedName, meds);
    const [partialItem, setParitalItem] = useState('');

    useEffect(() => {
        if (patientId) {
            api.fetchJsonApi(
                api.url_for('patient.Patient', {patient_id: patientId}),
                {
                    include: ['medications'],
                },
            );
        }
    }, [api, ticketSession, patientId]);

    const renderInput = props => (
        <TextField label='Which medication(s) do you need?' {...props} />
    );
    const onChange = (ev, value, reason) => {
        form.setFieldValue(field.name, value);
    };
    const onInputChange = (ev, value, reason) => {
        setParitalItem(value);
    };

    const handleBlur = ev => {
        if (!fp.isEmpty(partialItem)) {
            form.setFieldValue(field.name, [...field.value, partialItem]);
        }
    };

    console.log('patientMedSelect', {
        options,
        props,
        field,
    });

    return (
        <MUIAutocomplete
            id={fieldId('dyn', field.name)}
            options={options}
            freeSolo
            multiple
            renderInput={renderInput}
            {...field}
            {...props}
            onChange={onChange}
            onInputChange={onInputChange}
            onBlur={handleBlur}
        />
    );
}

const DynaformField = ({field, ...props}) => {
    return (
        <Field
            component={PatientMedicationSelectField}
            name={field.id}
            label={field.label}
            caption={field.caption}
            required={field.required}
            {...props}
        />
    );
};
export default DynaformField;
