import {combineReducers} from 'redux';

import jsonapi from './jsonapi';
import auth from './auth';

export * as auth from './auth';
export * as jsonapi from './jsonapi';

export const reducers = {jsonapi, auth};

export default combineReducers(reducers);
