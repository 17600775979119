import React, {useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Checkbox,
    Paper,
    List,
    ListItem as MUIListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from '@material-ui/core';

import Toolbar from './Toolbar';
import ListItem from './ListItem';

import {components} from '@arborian/narrf';

const {Pagination} = components;

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    flex: {
        flex: 1,
    },
}));
const inboxSort = {
    field: 'attributes.timestamp',
    direction: 'desc',
};
const pageSizeOptions = [5, 10, 20];

function SelectAllCheckbox({
    allItemsSelected,
    someItemsSelected,
    selectAllItems,
    ...props
}) {
    return (
        <Checkbox
            checked={allItemsSelected}
            indeterminate={someItemsSelected && !allItemsSelected}
            onChange={ev => selectAllItems(ev.target.checked)}
            {...props}
        />
    );
}

export default function ListView({
    dataSource,
    includePatient,
    showAllMessages = false,
}) {
    const classes = useStyles();

    useEffect(() => {
        if (!fp.isEqual(dataSource.fetchOptions.sort, inboxSort)) {
            dataSource.onChangeFetchOptions(
                fp.set('sort', inboxSort, dataSource.fetchOptions),
            );
        }
    }, [dataSource]);
    return (
        <Paper className={classes.root}>
            <Toolbar
                dataSource={dataSource}
                showAllMessages={showAllMessages}
            />
            <List>
                {dataSource.state === 'ready' &&
                !showAllMessages &&
                !fp.isEmpty(dataSource.items) ? (
                    <MUIListItem>
                        <ListItemIcon>
                            <SelectAllCheckbox
                                edge='start'
                                onClick={ev => ev.stopPropagation()}
                                allItemsSelected={dataSource.allItemsSelected}
                                someItemsSelected={dataSource.someItemsSelected}
                                selectAllItems={dataSource.selectAllItems}
                            />
                        </ListItemIcon>
                    </MUIListItem>
                ) : null}
                {dataSource.state === 'ready' &&
                fp.isEmpty(dataSource.items) ? (
                    <MUIListItem>
                        <ListItemText primary='No messages found' />
                    </MUIListItem>
                ) : null}
                {dataSource.state === 'busy' ? (
                    <MUIListItem>
                        <ListItemText primary='Fetching messages...' />
                    </MUIListItem>
                ) : null}
                {fp.map(
                    it => (
                        <ListItem
                            key={it.id}
                            item={it}
                            includePatient={includePatient}
                            showAllMessages={showAllMessages}
                        />
                    ),
                    dataSource.items,
                )}
                <MUIListItem>
                    <div className={classes.flex} />
                    <Pagination
                        count={dataSource.count}
                        page={dataSource.fetchOptions.page.number}
                        pageSize={dataSource.fetchOptions.page.size}
                        pageSizeOptions={pageSizeOptions}
                        onChangePage={dataSource.setPage}
                        onChangePageSize={dataSource.setPageSize}
                    />
                </MUIListItem>
            </List>
        </Paper>
    );
}
