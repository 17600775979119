import React, {useState, useCallback} from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core';
import {BorderColor, Description} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';
import {url_for} from 'ccm/routes';
import {useCurrentUser, useUserSignatureRequests} from 'ccm/dataSource/misc';

const useStyles = makeStyles(theme => ({
    infoBlocks: {
        margin: theme.spacing(0, 0, 0, 8),
    },
}));

function PatientBlock({patients}) {
    if (!patients) return null;
    if (patients.length === 1) {
        return <p>Patient: {fp.get('attributes.label', patients[0])}</p>;
    } else {
        return (
            <>
                <p>Patients:</p>
                <ul>
                    {fp.map(
                        pt => (
                            <li>{fp.get('attributes.label', pt)}</li>
                        ),
                        patients,
                    )}
                </ul>
            </>
        );
    }
}

function FacilityBlock({facility}) {
    if (!facility) return null;
    return <p>Facility: {fp.get('attributes.name', facility)}</p>;
}

function PracticeBlock({practice}) {
    if (!practice) return null;
    return <p>Practice: {fp.get('attributes.name', practice)}</p>;
}

function SignatureRequestBlock({request}) {
    const api = useApi();
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const onAccept = useCallback(() => {
        api.fetchJson(request.links.signature, {method: 'PUT'});
    }, [api, request]);
    const onDecline = useCallback(() => {
        api.fetchJson(request.links.signature, {method: 'DELETE'});
    }, [api, request]);
    const handleChange = useCallback(
        ev => setChecked(ev.target.checked),
        [setChecked],
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(request));
    console.log('Rel is', rel);
    return (
        <Card>
            <CardHeader
                title={<h1>Signature request</h1>}
                avatar={
                    <Avatar>
                        <BorderColor />
                    </Avatar>
                }
            />
            <CardContent>
                <strong>{fp.get('attributes.note', request)}</strong>
                <br />
                <br />
                <Button
                    variant='outlined'
                    startIcon={<Description />}
                    href={fp.get('links.blob_content', request)}
                    target='blank'
                    size='large'
                >
                    Review Document
                </Button>
                <Box className={classes.infoBlocks} color='text.secondary'>
                    <p>
                        If you decline to sign this document, you may lose the
                        ability to access the patient/facility data listed here:
                    </p>
                    <PatientBlock patients={fp.get('patients', rel)} />
                    <PracticeBlock practice={fp.get('practice', rel)} />
                    <FacilityBlock facility={fp.get('facility', rel)} />
                </Box>
                <br />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            name='agree'
                        />
                    }
                    label={`
                    By clicking 'accept and sign' below, I agree that I am
                    electronically signing the above document.
                    `}
                />
            </CardContent>
            <CardActions>
                <Button onClick={onDecline} variant='outlined'>
                    Decline
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    disabled={!checked}
                    onClick={onAccept}
                >
                    Accept and Sign
                </Button>
            </CardActions>
        </Card>
    );
}

export default function Interstitial() {
    const curUser = useCurrentUser();
    const signatureRequests = useUserSignatureRequests(curUser);
    if (!signatureRequests || !signatureRequests.length) return null;
    const label = signatureRequests.length === 1 ? 'request' : 'requests';
    return (
        <Dialog open fullScreen>
            <DialogTitle>Action Required</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        In order to continue, you'll need to respond to the
                        following {label}:
                    </p>
                    {fp.map(
                        sr => (
                            <SignatureRequestBlock request={sr} />
                        ),
                        signatureRequests,
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color='inherit'
                    component={R.Link}
                    to={url_for('logout')}
                >
                    Sign out
                </Button>
            </DialogActions>
        </Dialog>
    );
}
