import React, {useMemo, useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Select,
    MenuItem,
    Chip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {ArrowDropDown} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    filter: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiIconButton-root': {
            alignSelf: 'flex-start',
        },
    },
    chiplist: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiChip-root': {
            margin: theme.spacing(0.5, 0.5, 0, 0),
        },
    },
}));

function LookupFilterDialog({column, value, open, onClose, id, ...props}) {
    const [val, setVal] = useState();
    useEffect(() => {
        if (!fp.isEqual(value, val)) {
            setVal(value);
        }
    }, [open, value]);
    const handleChange = (ev, newVal) => {
        setVal(newVal);
    };
    const handleOk = ev => {
        onClose(ev, 'accepted', val);
    };
    const inputId = id ? `input-${id}` : null;
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{column.title} Filter</DialogTitle>
            <DialogContent>
                <Autocomplete
                    id={`${column.id}-dlg-autocomplete`}
                    multiple
                    fullWidth
                    autoComplete
                    autoHighlight
                    id={inputId}
                    options={fp.keys(column.lookup)}
                    defaultValue={value}
                    value={val}
                    onChange={handleChange}
                    getOptionLabel={o => column.lookup[o]}
                    renderInput={params => <TextField autoFocus {...params} />}
                    {...props}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} id={`${column.id}-dlg-cancel`}>
                    Cancel
                </Button>
                <Button
                    onClick={handleOk}
                    variant='contained'
                    color='primary'
                    id={`${column.id}-dlg-ok`}
                >
                    Update Filter
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function DialogLookupFilter({column, filter, onChangeFilter, ...props}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const value = useMemo(
        () => fp.getOr([], [column.field], filter),
        [column.field, filter],
    );
    const handleDelete = val => ev => {
        onChangeFilter(column.field, fp.pull(val, value));
    };
    const handleClose = (ev, reason, newVal) => {
        if (newVal) {
            onChangeFilter(column.field, newVal);
        }
        setOpen(false);
    };
    const buttonId = props.id ? `btn-${props.id}` : null;
    return (
        <div className={classes.filter}>
            <div className={classes.chiplist}>
                {fp.map(
                    val => (
                        <Chip
                            size='small'
                            key={val}
                            label={fp.get(val, column.lookup)}
                            onDelete={handleDelete(val)}
                        />
                    ),
                    value,
                )}
            </div>
            <IconButton id={buttonId} onClick={() => setOpen(true)}>
                <ArrowDropDown />
            </IconButton>
            <LookupFilterDialog
                column={column}
                value={value}
                open={open}
                onClose={handleClose}
                {...props}
            />
        </div>
    );
}

export function SelectLookupFilter({column, filter, onChangeFilter, ...props}) {
    const classes = useStyles();
    const value = fp.getOr([], [column.field], filter);
    const handleChange = ev => {
        onChangeFilter(column.field, ev.target.value);
    };
    return (
        <Select
            multiple
            value={value}
            onChange={handleChange}
            renderValue={sel => (
                <div className={classes.chiplist}>
                    {fp.map(
                        val => (
                            <Chip
                                size='small'
                                key={val}
                                label={fp.get(val, column.lookup)}
                            />
                        ),
                        sel,
                    )}
                </div>
            )}
            {...props}
        >
            {fp.pipe([
                fp.toPairs,
                fp.map(([val, label]) => (
                    <MenuItem key={val} value={val}>
                        {' '}
                        {label}{' '}
                    </MenuItem>
                )),
            ])(column.lookup)}
        </Select>
    );
}

export function AutocompleteLookupFilter({
    column,
    filter,
    onChangeFilter,
    ...props
}) {
    const value = fp.getOr([], [column.field], filter);
    const handleChange = (ev, value) => {
        onChangeFilter(column.field, value);
    };
    return (
        <Autocomplete
            multiple
            size='small'
            options={fp.keys(column.lookup)}
            value={value}
            onChange={handleChange}
            getOptionLabel={o => column.lookup[o]}
            renderInput={params => <TextField {...params} />}
            {...props}
        />
    );
}

export function SelectLookupEditor({value, onChange, column}) {
    return (
        <Select value={value} onChange={ev => onChange(ev.target.value)}>
            {fp.pipe([
                fp.toPairs,
                fp.map(([val, label]) => (
                    <MenuItem key={val} value={val}>
                        {' '}
                        {label}{' '}
                    </MenuItem>
                )),
            ])(column.lookup)}
        </Select>
    );
}

export default function renderLookup(row) {
    let value = this.field ? fp.get(this.field, row.data) : null;
    value = this.lookup[value];
    return value;
}
