import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Chip} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

export default function GroupChip({value, onDelete}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(value));
    let label = '';
    if (fp.get('attributes.object_type', value) === 'practice') {
        label = fp.get('practice.attributes.label', rel);
    } else if (fp.get('attributes.object_type', value) === 'facility') {
        label = fp.get('facility.attributes.label', rel);
    } else if (fp.get('attributes.object_type', value) === 'patient') {
        label = fp.get('patient.attributes.label', rel);
    }
    label = label + ' | ' + fp.getOr('', 'attributes.label', value);

    return <Chip label={label} onDelete={onDelete} />;
}
