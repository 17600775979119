import React, {useRef} from 'react';
import fp from 'lodash/fp';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    makeStyles,
} from '@material-ui/core';
import {Delete, CloudDownload, CloudUpload} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    cardStyle: {
        width: theme.spacing(39),
        margin: theme.spacing(1),
    },
    hidden: {
        display: 'none',
    },
    imageContainer: {
        height: '10em',
    },
    imageStyles: {
        height: 'auto',
        padding: theme.spacing(1),
    },
}));

export default function PracticeConsent({practice}) {
    const api = useApi();
    const classes = useStyles();
    const inputRef = useRef();

    const handleAdd = async ev => {
        let file = ev.currentTarget.files[0];
        let body = new FormData();
        body.append('file', file);
        await api.fetchJson(
            fp.get('relationships.consent_form.links.related', practice),
            {
                method: 'POST',
                body,
            },
        );
        inputRef.current.value = null;
    };

    const handleClickAdd = ev => {
        inputRef.current.click();
    };

    const handleDelete = async ev => {
        await api.fetchJson(
            fp.get('relationships.consent_form.links.related', practice),
            {
                method: 'DELETE',
            },
        );
        inputRef.current.value = null;
    };

    const canEdit = fp.includes(
        'Common.WRITE',
        fp.get('meta.allowed_actions', practice),
    );
    const href = fp.get('links.consent_form_url', practice);

    return (
        <div>
            <Card className={classes.cardStyle}>
                <CardHeader title='ePHI Consent Form'></CardHeader>
                {href ? (
                    <CardContent>
                        <Button
                            href={href}
                            target='_blank'
                            startIcon={<CloudDownload />}
                        >
                            {fp.get(
                                'attributes.consent_form_data.filename',
                                practice,
                            )}
                        </Button>
                    </CardContent>
                ) : null}
                {canEdit && (
                    <CardActions>
                        <Button
                            onClick={handleClickAdd}
                            startIcon={<CloudUpload />}
                        >
                            Upload new form
                        </Button>
                        {href && (
                            <Button
                                onClick={handleDelete}
                                startIcon={<Delete />}
                            >
                                Remove form
                            </Button>
                        )}
                        <input
                            type='file'
                            ref={inputRef}
                            onChange={handleAdd}
                            className={classes.hidden}
                            accept='application/pdf'
                        />
                    </CardActions>
                )}
            </Card>
        </div>
    );
}
