import React, {Fragment, useState, useEffect} from 'react';
import fp from 'lodash/fp';
import clsx from 'clsx';

import {
    IconButton,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Checkbox,
    makeStyles,
} from '@material-ui/core';
import {Refresh, Check, Close} from '@material-ui/icons';

import {useTableManager} from './Provider';
import {RowEdit, RowDelete} from './Editable';

const useStyles = makeStyles(theme => ({
    actions: {
        display: 'flex',
    },
    details: {
        display: 'flex',
    },
    disabled: {
        opacity: 0.2,
    },
}));

function ActionCell({row}) {
    const classes = useStyles();
    const tm = useTableManager();
    let actions = [];
    if (tm.options.selection) {
        actions = (
            <Checkbox
                key='selection'
                checked={row.selected}
                onChange={ev => tm.onSelectRow(row.id, ev.target.checked)}
            />
        );
    } else {
        actions = fp.map(
            act => <Fragment key={act.id}>{act.render(row)}</Fragment>,
            tm.rowActions,
        );
        if (tm.editable.onRowUpdate) {
            actions.push(<RowEdit key='rowUpdate' row={row} />);
        }
        if (tm.editable.onRowDelete) {
            actions.push(<RowDelete key='rowDelete' row={row} />);
        }
    }
    return (
        <TableCell padding='checkbox'>
            <div className={classes.actions}>{actions}</div>
        </TableCell>
    );
}

function EditActionsCell({
    submitTitle = 'Save',
    cancelTitle = 'Cancel',
    onSubmit,
    onCancel,
}) {
    const tm = useTableManager();
    const classes = useStyles();
    const colSpan = tm.hasDetailPanels ? 2 : 1;
    return (
        <TableCell colSpan={colSpan}>
            <div className={classes.actions}>
                <Tooltip title={submitTitle}>
                    <IconButton onClick={onSubmit}>
                        <Check />
                    </IconButton>
                </Tooltip>
                <Tooltip title={cancelTitle}>
                    <IconButton onClick={onCancel}>
                        <Close />
                    </IconButton>
                </Tooltip>
            </div>
        </TableCell>
    );
}

function DetailCell({row, disabled}) {
    const classes = useStyles();
    const tm = useTableManager();
    return (
        <TableCell padding='checkbox'>
            <div className={classes.actions}>
                {fp.map(
                    deet => (
                        <Fragment key={deet.id}>
                            {deet.renderIcon(
                                row,
                                disabled ? null : tm.onSelectDetail,
                            )}
                        </Fragment>
                    ),
                    tm.detailPanels,
                )}
            </div>
        </TableCell>
    );
}

function DataCell({column, row}) {
    const tm = useTableManager();
    if (column.component) {
        const value = column.field ? fp.get(column.field, row.data) : undefined;
        return (
            <TableCell>
                <column.component
                    table={tm}
                    row={row}
                    column={column}
                    value={value}
                />
            </TableCell>
        );
    } else {
        return <TableCell> {column.render(row)} </TableCell>;
    }
}

function DataRow({row, disabled}) {
    const tm = useTableManager();
    const classes = useStyles();
    let cells = fp.pipe([
        fp.toPairs,
        fp.map(([cid, col]) => <DataCell key={cid} column={col} row={row} />),
    ])(tm.columns);
    let actionsDetails = [];
    if (tm.hasActionsColumn) {
        actionsDetails.push(
            <ActionCell key='actions' row={row} disabled={disabled} />,
        );
    }
    if (tm.hasDetailPanels) {
        actionsDetails.push(
            <DetailCell key='detail' row={row} disabled={disabled} />,
        );
    }
    if (
        tm.options.actionsColumnIndex < 0 ||
        tm.options.actionsColumnIndex > row.length
    ) {
        cells = [...cells, ...actionsDetails];
    } else {
        cells.splice(tm.options.actionsColumnIndex, 0, ...actionsDetails);
    }
    let rowClass = null;
    if (tm.options.rowClass) {
        if (fp.isFunction(tm.options.rowClass)) {
            rowClass = tm.options.rowClass(row);
        } else {
            rowClass = tm.options.rowClass;
        }
    }
    return (
        <>
            <TableRow className={clsx(disabled && classes.disabled, rowClass)}>
                {cells}
            </TableRow>
            {row.detail && (
                <TableRow>
                    <TableCell colSpan={cells.length}>
                        {tm.detailPanels[row.detail].render(row)}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

function EditCell({column, row, values, errors, onChange}) {
    const EditComponent = column.EditComponent;
    const cellValue = column.field ? fp.get(column.field, values) || '' : value;
    const cellError = column.field
        ? fp.get(column.field, errors) || ''
        : errors;
    const handleChange = newValue => {
        if (column.field) {
            onChange(fp.set(column.field, newValue, values));
        } else {
            onChange(newValue);
        }
    };
    if (EditComponent) {
        return (
            <TableCell>
                <EditComponent
                    value={cellValue}
                    error={cellError}
                    onChange={handleChange}
                    row={row}
                    column={column}
                />
            </TableCell>
        );
    } else {
        return <DataCell key={column.id} column={column} row={row} />;
    }
}

function EditRow({row}) {
    const tm = useTableManager();
    const classes = useStyles();
    const [values, setValues] = useState(fp.getOr({}, 'data', row));
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setValues(fp.getOr({}, 'data', row));
    }, [row]);

    const handleSave = async () => {
        let failed = false;
        const results = fp.pipe([
            fp.map(c => {
                if (c.validate) {
                    const fieldError = c.validate(values);
                    if (fieldError) {
                        failed = true;
                    }
                    return [c.field, fieldError];
                } else {
                    return [c.field, null];
                }
            }),
            fp.fromPairs,
        ])(tm.columns);
        console.log('Error results', results);
        setErrors(results);
        if (failed) {
            return;
        }
        if (row) {
            await tm.editable.onRowUpdate({...row, data: values}, row);
        } else {
            await tm.editable.onRowAdd({data: values});
        }
        await tm.fetch();
        tm.setEditState({op: null, row: null});
    };
    const handleCancel = () => {
        tm.setEditState({op: null, row: null});
    };
    let cells = fp.pipe([
        fp.toPairs,
        fp.map(([cid, col]) => (
            <EditCell
                key={cid}
                column={col}
                row={row}
                values={values}
                errors={errors}
                onChange={setValues}
            />
        )),
    ])(tm.columns);
    if (tm.hasActionsColumn) {
        cells = [<TableCell key='_actions' />, ...cells];
    }
    if (tm.hasDetailPanels) {
        cells = [<TableCell key='_deails' />, ...cells];
    }

    return (
        <TableRow>
            {cells}
            <EditActionsCell onSubmit={handleSave} onCancel={handleCancel} />
        </TableRow>
    );
}

function DeleteRow({row}) {
    const tm = useTableManager();
    const classes = useStyles();
    const handleDelete = async () => {
        await tm.editable.onRowDelete(row);
        await tm.fetch();
        tm.setEditState({op: null, row: null});
    };
    const handleCancel = () => {
        tm.setEditState({op: null, row: null});
    };
    return (
        <TableRow>
            <TableCell colSpan={fp.size(tm.columns)}>
                <h6>Are you sure you want to delete this row?</h6>
            </TableCell>
            <EditActionsCell
                submitTitle='Yes, delete the row'
                onSubmit={handleDelete}
                onCancel={handleCancel}
            />
        </TableRow>
    );
}

export default function DTableBody() {
    const tm = useTableManager();
    const colSpan = fp.size(tm.columns) + fp.size(tm.actions);

    if (tm.state.state === 'busy') {
        return (
            <TableBody>
                <TableRow>
                    <TableCell className='DataTableCell-busy' colSpan={colSpan}>
                        Fetching data...
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    } else if (tm.state.state === 'error') {
        return (
            <TableBody>
                <TableRow>
                    <TableCell
                        className='DataTableCell-error'
                        colSpan={colSpan}
                    >
                        <div>
                            <IconButton onClick={tm.fetch}>
                                <Refresh />
                            </IconButton>
                            <span>
                                Error: {fp.get('state.error.message', tm)}
                            </span>
                        </div>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }
    return (
        <TableBody>
            {fp.map(row => {
                if (tm.editState.op === null || tm.editState.op === 'add') {
                    return <DataRow key={row.id} row={row} />;
                } else if (tm.editState.row !== row) {
                    return <DataRow disabled key={row.id} row={row} />;
                } else if (tm.editState.op === 'edit') {
                    return <EditRow key={row.id} row={row} />;
                } else if (tm.editState.op === 'delete') {
                    return <DeleteRow key={row.id} row={row} />;
                } else {
                    return (
                        <TableRow key={row.id}>
                            <TableCell>WTF!</TableCell>
                        </TableRow>
                    );
                }
            }, tm.state.rows)}
            {tm.editState.op === 'add' && <EditRow />}
        </TableBody>
    );
}
