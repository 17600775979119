import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

export default function FacilityStats({facility}) {
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isSuperuser = userinfo && fp.includes('__admin__', userinfo.scopes);
    const user = useSelector(selectCurrentUser);
    const groups = fp.get('relationships.groups.data', user);
    const hasPracticeGroup = fp.some(g => g.id.match(/^practice:/), groups);
    const hasFacilityGroup = fp.some(g => g.id.match(/^facility:/), groups);
    if (!hasPracticeGroup && !hasFacilityGroup && !isSuperuser) return null;
    const medsPerPatient = fp.get(
        'attributes.stats.average_meds_per_patient',
        facility,
    );

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                Facility Stats
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableCell>Stat</TableCell>
                        <TableCell>Value</TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <strong>Average Medications per Patient</strong>
                            </TableCell>
                            <TableCell>
                                {fp.isNumber(medsPerPatient)
                                    ? medsPerPatient.toFixed(1)
                                    : medsPerPatient}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
}
