import React from 'react';

import {Chip} from '@material-ui/core';
import {Email} from '@material-ui/icons';

export default function EmailChip({value, ...other}) {
    const href = `mailto:${value}`;
    const label = value;
    return (
        <Chip
            clickable
            icon={<Email />}
            label={label}
            component='a'
            href={href}
        />
    );
}
