import Tour from './tour_base';

const TourVitalHistory = new Tour({
    id: 'vitalsHistory',
    label: 'Tour Vitals History',
    location_context: /patient\/(.*)\/history\/(.*)$/,
    group_context: /.*/,
    steps: [
        {
            content: `
            This page allows you to chart changes in vitals 
            over time.
            `,
        },
        {
            selector: '#btn-return-chart',
            content: `
            You can return to the main chart view by clicking here
            `,
        },
    ],
});
export default TourVitalHistory;
