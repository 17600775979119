import {createAction, createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

export const NAME = 'narrf.jsonapi';

export const DATA = NAME + '/DATA';
export const DELETE = NAME + '/DELETE';
export const DIRECTORY = NAME + '/DIRECTORY';
export const CLEAR = NAME + '/CLEAR';
export const CLEAR_TYPE = NAME + '/CLEAR_TYPE';

// action creators
export const directory = createAction(DIRECTORY);
export const receiveData = createAction(DATA);
export const deleteData = createAction(DELETE);
export const clearData = createAction(CLEAR);
export const clearType = createAction(CLEAR_TYPE);

// selectors
export const selectObjects = fp.get(`${NAME}.objects`);
export const selectDirectory = state => {
    const key = `${NAME}.directory`;
    const value = fp.get(key, state);
    return value;
};

// export const selectDirectory = fp.get(`${NAME}.directory`);

export const selectObject = path => createSelector(selectObjects, fp.get(path));

export const selectRelated = obj => state => {
    const result = {};
    const rel_pairs = fp.toPairs(fp.get('relationships', obj));
    const rel_one = fp.filter(
        ([rtype, rbody]) => fp.isObject(fp.get('data', rbody)),
        rel_pairs,
    );
    const rel_many = fp.filter(
        ([rtype, rbody]) => fp.isArray(fp.get('data', rbody)),
        rel_pairs,
    );
    fp.forEach(([rtype, rbody]) => {
        const related = selectObject([rbody.data.type, rbody.data.id])(state);
        result[rtype] = related;
    }, rel_one);
    fp.forEach(([rtype, rbody]) => {
        const related = fp.pipe([
            fp.map(item => selectObject([item.type, item.id])(state)),
            fp.filter(fp.identity),
        ])(rbody.data);
        result[rtype] = related;
    }, rel_many);
    return result;
};

const initialState = {
    directory: null,
    objects: {},
};

const reduceObject = (state, data) => {
    // Sometimes we just get a data **linkage**, not an entire object with
    // attributes and/or relationships. So DON'T overwrite objects in the
    // state unless we have at least some relationships or attributes
    if (data.attributes || data.relationships) {
        return fp.set(['objects', data.type, data.id], data, state);
    } else {
        return state;
    }
};

const reduceMany = (state, items) => {
    // Sort by type
    // console.log('reduceMany', items.length);
    const toReduce = fp.pipe([
        fp.filter(item => item.attributes || item.relationships),
        // fp.sortBy(item => item.type),
        fp.groupBy(item => item.type),
        fp.toPairs,
    ])(items);
    let newState = state;
    for (var [type, groupItems] of toReduce) {
        // console.log('Merge group of', type);
        const groupItemsById = fp.pipe([
            fp.map(item => [item.id, item]),
            fp.fromPairs,
        ])(groupItems);
        const stateSlice = fp.getOr({}, ['objects', type], state);
        const merged = {...stateSlice, ...groupItemsById};
        // console.log('Merge', groupItems.length, type);
        newState = fp.set(['objects', type], merged, newState);
    }
    // console.log('reduceMany', items.length, 'done');
    return newState;
};

export default function jsonApiReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case DIRECTORY: {
            return fp.set('directory', payload, state);
        }
        case CLEAR_TYPE: {
            const {type} = payload;
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [type]: {},
                },
            };
        }
        case DELETE: {
            const {type, id} = payload;
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [type]: fp.omit(id, state.objects[type]),
                },
            };
            // return fp.omit(`objects.${payload.type}.${payload.id}`, state);
        }
        case DATA: {
            // Single object
            if (payload.data && payload.data.type) {
                state = reduceObject(state, payload.data);
            }
            // Included objects
            if (payload.included && payload.included.length) {
                /*
                console.log(
                    'Reducing',
                    payload.included.length,
                    'included objects',
                );
                */
                // state = fp.reduce(reduceObject, state, payload.included);
                state = reduceMany(state, payload.included);
                // console.log('...done.');
            }
            // Collection response
            if (payload.data && payload.data.length) {
                // console.log('Reducing', payload.data.length, 'data objects');
                // state = fp.reduce(reduceObject, state, payload.data);
                state = reduceMany(state, payload.data);
                // console.log('...done.');
            }
            return state;
        }
        case CLEAR: {
            return fp.set('objects', {}, state);
        }
        default:
            return state;
    }
}
