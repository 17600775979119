import React from 'react';
import fp from 'lodash/fp';

import {AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {AccordionProvider, Accordion} from 'ccm/components/TabPanels';
import OrderTemplates from './clinical/OrderTemplates';
import CarePlanTemplates from './clinical/CarePlanTemplates';
import LabResultsRangeConfig from './clinical/LabResultsRangeConfig';

export default function ClinicalPanel({practice}) {
    const isAdmin = fp.includes('Common.ADMIN', practice.meta.allowed_actions);
    if (!isAdmin)
        return <OrderTemplates practice={practice} isAdmin={isAdmin} />;
    return (
        <AccordionProvider queryKey='clinicalTab'>
            <Accordion index='orders'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Order Templates</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <OrderTemplates practice={practice} isAdmin={isAdmin} />
                </AccordionDetails>
            </Accordion>
            <Accordion index='carePlans'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Care Plans</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <CarePlanTemplates practice={practice} isAdmin={isAdmin} />
                </AccordionDetails>
            </Accordion>
            <Accordion index='rangeConfig'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Lab Results Range Config</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <LabResultsRangeConfig
                        practice={practice}
                        isAdmin={isAdmin}
                    />
                </AccordionDetails>
            </Accordion>
        </AccordionProvider>
    );
}
