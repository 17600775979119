import React, {useState} from 'react';

import {IconButton, Popover, makeStyles} from '@material-ui/core';
import {Info} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.cf.blue,
    },
}));

export default function InfoPopover({IconComponent = Info, children}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    return (
        <>
            <IconButton
                onClick={ev => setAnchorEl(ev.currentTarget)}
                className={classes.button}
            >
                <IconComponent />
            </IconButton>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {children}
            </Popover>
        </>
    );
}
