import React from 'react';

import {Paper} from '@material-ui/core';

import BasePage from 'ccm/components/BasePage';
import {Tabs, Tab, TabPanel, TabProvider} from 'ccm/components/TabPanels';

import PracticeAdminTable from 'ccm/components/PracticeAdminTable';
import FacilityAdminTable from 'ccm/components/FacilityAdminTable';
import FacilityAdminStats from 'ccm/components/FacilityAdminStatsTable';
import PatientAdminTable from 'ccm/components/PatientAdminTable';
import UserAdminTable from 'ccm/components/UserAdminTable';
import EhrAdminTable from 'ccm/components/EhrAdminTable';
import GroupAdminTable from 'ccm/components/GroupAdminTable';
import CarePlanTemplateAdminTable from 'ccm/components/admin/CarePlanTemplateAdminTable';
import OrderTemplateAdminTable from 'ccm/components/OrderTemplateAdminTable';
import EventLogTable from 'ccm/components/EventLogTable';

function TemplateLibraryPanel() {
    return (
        <Paper>
            <TabProvider
                orientation='vertical'
                queryKey='tab1'
                defaultTab='careplan'
            >
                <Tabs>
                    <Tab value='careplan' label='Care Plans' />
                    <Tab value='orders' label='Orders' />
                </Tabs>
                <TabPanel index='careplan'>
                    <CarePlanTemplateAdminTable />
                </TabPanel>
                <TabPanel index='orders'>
                    <OrderTemplateAdminTable />
                </TabPanel>
            </TabProvider>
        </Paper>
    );
}

export default function SiteAdminPage() {
    return (
        <BasePage wide title='Site Administration'>
            <TabProvider queryKey='tab' defaultTab='practice'>
                <Tabs>
                    <Tab value='practice' label='Practices' />
                    <Tab value='facility' label='Facilities' />
                    <Tab value='patient' label='Patients' />
                    <Tab value='user' label='Users' />
                    <Tab value='ehr' label='EHRs' />
                    <Tab value='group' label='Groups' />
                    <Tab value='library' label='Template Library' />
                    <Tab value='eventLog' label='Event Log' />
                </Tabs>
                <TabPanel index='practice'>
                    <PracticeAdminTable />
                </TabPanel>
                <TabPanel index='facility'>
                    <FacilityAdminStats />
                    <FacilityAdminTable />
                </TabPanel>
                <TabPanel index='patient'>
                    <PatientAdminTable />
                </TabPanel>
                <TabPanel index='user'>
                    <UserAdminTable />
                </TabPanel>
                <TabPanel index='ehr'>
                    <EhrAdminTable />
                </TabPanel>
                <TabPanel index='group'>
                    <GroupAdminTable />
                </TabPanel>
                <TabPanel index='library'>
                    <TemplateLibraryPanel />
                </TabPanel>
                <TabPanel index='eventLog'>
                    <EventLogTable />
                </TabPanel>
            </TabProvider>
        </BasePage>
    );
}
