import React from 'react';
import fp from 'lodash/fp';

import {List, Button, MenuItem} from '@material-ui/core';
import {Add} from '@material-ui/icons';

import {Formik, Form, Field, FieldArray, useField} from 'formik';
import {TextField} from 'formik-material-ui';

import {Date as DateComp} from 'ccm/components/DateTime';
import FocusItem from './FocusItem';

function LabeledDate({value, label}) {
    if (!value) return null;
    return (
        <>
            {label}: <DateComp value={value} />
            <br />
        </>
    );
}

function ArrayField({id, title, label, name, component, buttonText, idField}) {
    const Component = component;
    const [field, , helpers] = useField({name});
    const onAdd = arrayHelpers => ev => {
        arrayHelpers.push({
            [idField]: (100000 * Math.random()).toFixed(),
            status: 'Active',
            initiatedDate: new Date(),
            resolvedDate: null,
            targetDate: null,
        });
        helpers.setTouched(true);
    };
    return (
        <>
            <h6>{title}</h6>
            <FieldArray name={name}>
                {arrayHelpers => (
                    <div>
                        <List>
                            {fp.map(
                                index => (
                                    <Component
                                        key={index}
                                        index={index}
                                        name={name}
                                        label={label}
                                        arrayHelpers={arrayHelpers}
                                    />
                                ),
                                fp.range(0, field.value.length),
                            )}
                        </List>

                        <Button
                            id={id}
                            startIcon={<Add />}
                            onClick={onAdd(arrayHelpers)}
                            variant='outlined'
                        >
                            {buttonText}
                        </Button>
                    </div>
                )}
            </FieldArray>
        </>
    );
}

export default function FocusEditor({value, onChange}) {
    const handleSubmit = values => {
        return onChange(values);
    };

    return (
        <Formik
            initialValues={value}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {({touched, values, errors, submitForm}) => (
                <Form>
                    <small>
                        {/* #182439461
                        <LabeledDate
                            value={value.createdDate}
                            label='Created date'
                        />
                        */}
                        <LabeledDate
                            value={value.revisedDate}
                            label='Revised date'
                        />
                    </small>
                    <Field
                        id='focusEditorDescriptionField'
                        component={TextField}
                        label='Description'
                        name='description'
                        fullWidth
                    />
                    <Field
                        id='focusEditorStatusField'
                        component={TextField}
                        select
                        label='Status'
                        name='status'
                        fullWidth
                    >
                        <MenuItem value='Active'>Active</MenuItem>
                        <MenuItem value='Resolved'>Resolved</MenuItem>
                        <MenuItem value='Closed'>Closed</MenuItem>
                    </Field>
                    <ArrayField
                        id='addGoalBtn'
                        title='Goals'
                        name='goals'
                        label='Goal'
                        buttonText='Add goal'
                        component={FocusItem}
                        idField='goalId'
                    />
                    <ArrayField
                        id='addInterventionBtn'
                        title='Interventions'
                        name='interventions'
                        label='Intervention'
                        buttonText='Add intervention'
                        component={FocusItem}
                        idField='interventionId'
                    />
                    <br />
                    <div>
                        <Button id='focusEditorResetBtn' type='reset'>
                            Reset
                        </Button>
                        <Button
                            id='focusEditorSubmitBtn'
                            disabled={fp.isEmpty(touched)}
                            color='primary'
                            variant='contained'
                            type='submit'
                            onClick={submitForm}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
