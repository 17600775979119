import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {AssignmentTurnedIn, AssignmentLate} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {ducks} from '@arborian/narrf';
import * as h from 'ccm/lib/helpers';

export default function TicketAcknowledgeDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const patient = useSelector(
        ducks.jsonapi.selectObject([
            'Patient',
            fp.get('relationships.patient.data.id', ticket),
        ]),
    );
    const initialValues = useMemo(() => {
        return {
            comments: fp.get('attributes.data.comments', ticket),
        };
    }, [ticket]);

    const handleSubmit = async values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = tkt =>
        new Promise((resolve, reject) => {
            setTicket(tkt);
            setResolve(() => resolve);
        });

    const handleAcknowledge = formikProps => async ev => {
        await formikProps.setFieldValue('createTicket', false);
        return formikProps.handleSubmit();
    };

    const handleAcknowledgeWithNewTicket = formikProps => async ev => {
        await formikProps.setFieldValue('createTicket', true);
        return formikProps.handleSubmit();
    };

    return (
        <Dialog
            open={!!resolve}
            onClose={handleCancel}
            scroll='paper'
            maxWidth='xl'
            fullWidth
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <DialogTitle>Acknowledge Ticket</DialogTitle>
                        <DialogContent dividers>
                            <Alert severity='info' icon={false}>
                                <p>
                                    <b>Patient: </b>
                                    {fp.get(
                                        'attributes.name.formatted',
                                        patient,
                                    )}
                                </p>
                                <p>
                                    <b>DOB: </b>
                                    {h.formatDateOnly(
                                        fp.get('attributes.dob', patient),
                                    )}
                                </p>
                                <p>
                                    <b>Room: </b>
                                    {fp.get('attributes.room', patient)}
                                </p>
                            </Alert>
                            <br />
                            <Alert severity='warning'>
                                <p>
                                    <b>Attention:</b> Comments are added to the
                                    ticket data, but will <b>NOT</b> be sent to
                                    the provider.
                                </p>
                                <p>
                                    Please contact the healthcare provider
                                    directly if you need to send urgent
                                    information.
                                </p>
                            </Alert>
                            <Field
                                id='static_ticket_ack_comments_field'
                                name='comments'
                                component={TextField}
                                multiline
                                fullWidth
                                label='Comments'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id='static_ticket_ack_cancel_button'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                id='static_ticket_ack_followup_button'
                                variant='contained'
                                color='default'
                                onClick={handleAcknowledgeWithNewTicket(
                                    formikProps,
                                )}
                                startIcon={<AssignmentTurnedIn />}
                                name='submit'
                            >
                                Acknowledge with new communication
                            </Button>
                            <Button
                                id='static_ticket_ack_close_button'
                                variant='contained'
                                color='primary'
                                onClick={handleAcknowledge(formikProps)}
                                startIcon={<AssignmentTurnedIn />}
                                name='submit'
                            >
                                Acknowledge & Close Ticket
                            </Button>
                        </DialogActions>

                        <Menu
                            open={!!menuAnchor}
                            anchorEl={menuAnchor}
                            on
                            onClose={() => setMenuAnchor()}
                        >
                            <MenuItem
                                onClick={handleAcknowledgeWithNewTicket(
                                    formikProps,
                                )}
                            >
                                <ListItemIcon>
                                    <AssignmentLate />
                                </ListItemIcon>
                                Acknowledge & create new communication
                            </MenuItem>
                        </Menu>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
