import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';

import fp from 'lodash/fp';

import {useApi, ducks} from '@arborian/narrf';

import BasePage from 'ccm/components/BasePage';
import PatientSummary from 'ccm/components/PatientSummary';
import {CarePlanEditor} from 'ccm/components/carePlan';

export default function CarePlanPage() {
    const api = useApi();
    const {carePlanId} = useParams();
    const carePlan = useSelector(
        ducks.jsonapi.selectObject(['CarePlan', carePlanId]),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(carePlan));

    useEffect(() => {
        const href = api.url_for('careplan.Resource', {
            careplan_id: carePlanId,
        });
        api.fetchJsonApi(href, {include: ['patient']});
    }, [api, carePlanId]);

    return (
        <BasePage wide title='Care Plan Creator'>
            <PatientSummary patient={fp.get('patient', rel)} />
            <CarePlanEditor carePlan={carePlan} />
        </BasePage>
    );
}
