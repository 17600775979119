import React from 'react';

import {ThermometerAvatar} from 'ccm/art/MedicalIcons';

import VitalCard from './VitalCard';

export default function TemperatureCard({patient, artifact}) {
    console.log('Latest temp', artifact.id, artifact);
    return (
        <VitalCard
            color='green'
            Avatar={ThermometerAvatar}
            type='temperature'
            patient={patient}
            {...artifact.attributes}
        >
            <h6>Body temperature</h6>
        </VitalCard>
    );
}
