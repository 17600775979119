import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import fp from 'lodash/fp';

import {useSnackbar} from 'notistack';
import {Alert} from '@material-ui/lab';

import {useApi} from '@arborian/narrf';
import {url_for, useQuery} from 'ccm/routes';
import BasePage from 'ccm/components/BasePage';

import PasswordResetForm from 'ccm/components/forms/PasswordResetForm';

export default function PasswordResetPage() {
    const query = useQuery();
    const api = useApi();
    const [errors, setErrors] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const location = useLocation();

    const email = query.get('email');
    const token = query.get('token');
    const message =
        query.get('message') || `Reset the password for the email ${email}`;
    const old_password_sha1 = fp.get('old_password_sha1', location.state);

    useEffect(() => {
        setErrors([]);
    }, [token]);

    const handleSubmit = async (attributes, {resetForm}) => {
        try {
            await api.fetch(api.url_for('auth.password_reset'), {
                method: 'POST',
                json: {data: {type: 'PasswordReset', attributes}},
            });
            enqueueSnackbar('Password reset successfully. You can now login.', {
                persist: false,
                variant: 'success',
            });
            history.push(url_for('login'));
        } catch (e) {
            const status_int = fp.get('response.status', e);
            if (status_int === 422 || status_int === 400) {
                const json_data = await e.response.json();
                setErrors(fp.map('detail', json_data.errors));
                enqueueSnackbar('There were problems with your password', {
                    persist: true,
                    variant: 'error',
                });
                return;
            }
            const status_str = fp.getOr(
                'Unknown error',
                'response.statusText',
                e,
            );
            const msg = `Error resetting password: ${status_str}`;
            console.error(msg, e.response);
            enqueueSnackbar(msg, {
                persist: true,
                variant: 'error',
            });
        }
    };

    return (
        <BasePage title='Reset password'>
            <h3>Set password</h3>
            <p>{message}</p>
            {!fp.isEmpty(errors) ? (
                <>
                    <p>
                        Please correct the following errors with your new
                        password:
                    </p>
                    {fp.map(
                        error => (
                            <Alert severity='error'>{error}</Alert>
                        ),
                        errors,
                    )}
                </>
            ) : null}

            <PasswordResetForm
                old_password_sha1={old_password_sha1}
                onSubmit={handleSubmit}
            />
            <p>
                <small>
                    Not working? Request another reset link{' '}
                    <a href={url_for('pwreset_request')}>here</a>
                </small>
            </p>
        </BasePage>
    );
}
