import React from 'react';

import {ForbiddenAvatar} from 'ccm/art/MedicalIcons';
import InfoCard from './InfoCard';

export default function AllergiesCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={ForbiddenAvatar}
            title='Allergies'
            {...props}
        ></InfoCard>
    );
}
