import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import fp from 'lodash/fp';

import {components, useApi} from '@arborian/narrf';

import {useConditions} from 'ccm/dataSource';

const {dt2} = components;

const clinicalStatusLookup = {
    active: 'Active',
    recurrence: 'Recurrence',
    relapse: 'Relapse',
    inactive: 'Inactive',
    remission: 'Remission',
    resolved: 'Resolved',
};

const TABLE_OPTIONS = {
    search: true,
    filtering: true,
};

export default function ConditionTable({patient, title, ...props}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const [resolve, setResolve] = useState(null);
    const [conditionRequest, setConditionRequest] = useState('');
    const conditions = useConditions(patient);
    const practiceId = fp.get('relationships.practice.data.id', patient);

    const handleSubmit = async values => {
        setResolve(null);
        const data = {
            type: 'ConditionRequest',
            attributes: {
                id: patient.id,
                condition: conditionRequest,
            },
        };
        await api.fetchJsonApi(
            api.url_for('practice.ConditionRequestTicket', {
                practice_id: practiceId,
            }),
            {
                method: 'POST',
                json: {data},
            },
        );
        enqueueSnackbar('Condition notification sent.', {
            persist: false,
            variant: 'success',
        });
    };
    const handleCancel = () => {
        setConditionRequest('');
        setResolve(null);
    };

    const OpenRequestConditionDialog = () => {
        setResolve(true);
    };
    const handleConditionChange = event => {
        setConditionRequest(event.target.value);
    };

    return (
        <>
            <dt2.DataTable
                title={title}
                dataSource={conditions}
                options={TABLE_OPTIONS}
                {...props}
            >
                <dt2.Column
                    title='Rank'
                    field='attributes.raw.rankDescription'
                />
                <dt2.Column
                    title='Status'
                    field='attributes.clinicalStatus.coding.0.code'
                    lookup={clinicalStatusLookup}
                />
                <dt2.Column title='Description' field='attributes.code.text' />
                <dt2.Column
                    title='Onset'
                    field='attributes.onsetDateTime'
                    type='date'
                />
                <dt2.Column
                    title='Resolved'
                    field='attributes.abatementDateTime'
                    type='date'
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={() => conditions.fetch()}
                    tooltip='Refresh'
                    icon='refresh'
                />

                <dt2.Action
                    name='add'
                    free
                    onClick={OpenRequestConditionDialog}
                    tooltip='New health concern'
                    icon='maps_ugc'
                    iconProps={{color: 'primary'}}
                    hidden={!practiceId}
                />
            </dt2.DataTable>

            {/* Condition Request dialog */}
            <Dialog open={!!resolve} onClose={handleCancel}>
                <DialogTitle>
                    Notify of New Health Condition/Concern
                </DialogTitle>
                <DialogContent>
                    <p>
                        Please provide a description of a new health concern or
                        condition. This will be sent to your healthcare provider
                        for review.
                    </p>
                    <TextField
                        onChange={handleConditionChange}
                        multiline
                        label='Condition Description'
                        name='condition'
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
