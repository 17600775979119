import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {ducks, components} from '@arborian/narrf';
import {useGroups} from 'ccm/dataSource';

const {dt2} = components;

const LOOKUP_ROLE = {
    staff: 'Staff',
    admin: 'Admin',
    provider: 'Provider',
    member: 'Member',
    noteprep: "Note Preparer",
};

const LOOKUP_TYPE = {
    practice: 'Practice',
    facility: 'Facility',
    patient: 'Patient',
};

const INCLUDES = ['practice', 'facility', 'patient'];
const DS_OPTIONS = {include: INCLUDES};

function GroupObject({row}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data));
    if (rel.practice) {
        return fp.get('attributes.name', rel.practice);
    } else if (rel.facility) {
        return fp.get('attributes.name', rel.facility);
    } else if (rel.patient) {
        return fp.get('attributes.name.formatted', rel.patient);
    }
    return null;
}

export default function GroupAdminTable() {
    const ds = useGroups({prefix: 'groupTable.', options: DS_OPTIONS});
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            title='Group Administration'
            size='small'
            dataSource={ds}
            options={{
                //search: true,
                filtering: true,
            }}
        >
            <dt2.Column
                title='Group Type'
                field='attributes.object_type'
                lookup={LOOKUP_TYPE}
            />
            <dt2.Column
                title='Role'
                field='attributes.role'
                lookup={LOOKUP_ROLE}
            />
            <dt2.Column
                title='Object'
                filtering={false}
                field='attributes.object_id'
                component={GroupObject}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
