import React from 'react';
import fp from 'lodash/fp';

import EHRConfig from 'ccm/components/EHRConfig';
import PFEhrPanel from 'ccm/components/practiceFusion/Panel';
import {usePracticeEhr} from './hooks';

export default function EhrPanel({practice}) {
    const ehr = usePracticeEhr(practice);
    const plugin_id = ehr?.attributes.plugin_id;
    const canWrite = fp.includes(
        'Common.WRITE',
        fp.get('meta.allowed_actions', ehr),
    );
    if (plugin_id === 'pf') return <PFEhrPanel practice={practice} ehr={ehr} />;
    else {
        if (!canWrite) return null;
        else return <EHRConfig owner={practice} ehr={ehr} />;
    }
}
