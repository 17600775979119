import React, {useMemo, useEffect} from 'react';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';

import {useCMSFacilities} from 'ccm/dataSource';
import {useAllPractices} from 'ccm/dataSource/allResources';
import {Tabs, Tab, TabPanel, TabProvider} from 'ccm/components/TabPanels';

import CmsFacilityWidget from 'ccm/components/CmsFacilityWidget';

const useStyles = makeStyles(theme => ({
    iframe: {
        width: '100%',
        height: 960,
    },
    fullWidth: {width: '100%'},
}));

const useUserCmsFacilities = user => {
    const initialFetchOptions = useMemo(() => {
        const userFacIds = fp.pipe([
            fp.get('relationships.facilities.data'),
            fp.map(facRel => facRel.id),
        ])(user);
        return {
            page: {number: 0, size: 20},
            filter: {'attributes.facility_id': {$in: userFacIds}},
        };
    }, [user]);

    const dataSource = useCMSFacilities({
        initialFetchOptions,
    });

    const {state: dsState, fetch: dsFetch} = dataSource;
    useEffect(() => {
        if (dsState === 'init') {
            dsFetch();
        }
    }, [dsState, dsFetch]);

    return dataSource;
};

export default function HomePageSplash({user}) {
    const classes = useStyles();
    const practices = useAllPractices();
    const cmsFacilities = useUserCmsFacilities(user);
    const splashPractices = useMemo(
        () =>
            fp.pipe([
                fp.get('relationships.practices.data'),
                fp.map(linkage => fp.get(linkage.id, practices)),
                fp.filter(p => !!fp.get('attributes.splash_href', p)),
                fp.sortBy(prac => fp.get('attributes.name', prac)),
            ])(user),
        [practices, user],
    );
    let tabs = fp.map(
        p => ({
            index: p.id,
            label: p.attributes.name,
            el: (
                <iframe
                    title={p.attributes.name}
                    className={classes.iframe}
                    src={p.attributes.splash_href}
                />
            ),
        }),
        splashPractices,
    );
    if (cmsFacilities.count) {
        tabs.push({
            index: 'cms',
            label: 'CMS Facilities',
            el: <CmsFacilityWidget dataSource={cmsFacilities} />,
        });
    }
    if (tabs.length === 0) return null;
    else if (tabs.length === 1) return tabs[0].el;
    else
        return (
            <div className={classes.fullWidth}>
                <TabProvider queryKey='tab' defaultTab={tabs[0].index}>
                    <Tabs>
                        {fp.map(
                            t => (
                                <Tab
                                    key={t.index}
                                    value={t.index}
                                    label={t.label}
                                />
                            ),
                            tabs,
                        )}
                    </Tabs>
                    {fp.map(
                        t => (
                            <TabPanel key={t.index} index={t.index}>
                                {t.el}
                            </TabPanel>
                        ),
                        tabs,
                    )}
                </TabProvider>
            </div>
        );
}
