import Tour from './tour_base';

const TourChart = new Tour({
    id: 'chart',
    label: 'Tour the Patient Chart',
    location_context: /patient\/([0-9a-f]+)$/,
    group_context: /.*/,
    steps: [
        {
            selector: '#btn-contact-healthcare-team',
            content: `
            You can click here to send a message to 
            this patient's healthcare provider.
            `,
        },
        {
            selector: '#hdr-latest-vitals',
            content: `
            Here, you can see the latest vitals recorded
            for this patient. For more detailed information
            and history for each vital, you can click to expand
            this section.
            `,
        },
        {
            selector: '#healthInfoHeader',
            content: `
            For additional information, you can click
            through any of the buttons under "Health info"
            to navigate to their respective section of this
            patient's chart.
            `,
        },
    ],
});
export default TourChart;
