import React from 'react';

import {useSnackbar} from 'notistack';

import {useApi} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';
import TaskLogDialog from 'ccm/components/dialogs/TaskLogDialog';

import NoteVisitsTable from './NoteVisitsTable';
import NotePrepForm from './NotePrepForm';

export default function PFVisitNotePrep({practice, ehr, visits}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const taskLogDialog = useDialog(TaskLogDialog);

    const handleSubmit = async data => {
        const url = api.url_for('pf_plugin.NotePrep', {
            ehr_connection_id: ehr.id,
        });
        try {
            const resp = await api.fetchJson(url, {
                method: 'POST',
                json: {data},
            });
            await taskLogDialog(resp.data);
        } catch (e) {
            let detail = e.toString();
            try {
                const text = await e.response.text();
                if (text) detail = text;
            } catch (ee) {
                console.error('Error getting error text');
            }
            console.error('Error creating notes');
            enqueueSnackbar(`Unable to create notes: ${detail}`, {
                perist: true,
                variant: 'error',
            });
        }
    };

    return (
        <div>
            <NoteVisitsTable visits={visits} ehrConnectionId={ehr?.id} />
            <NotePrepForm
                visits={visits}
                ehrConnectionId={ehr?.id}
                onSubmit={handleSubmit}
            />
        </div>
    );
}
