import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
import * as yup from 'yup';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {useGlobals} from 'ccm/components/Globals';
import {PatientSelectField} from 'ccm/components/fields/PatientSelect';
import ThreadParticipantsSelectField from './ThreadParticipantsSelectField';

const schema = yup.object().shape({
    patient: yup.object().required().typeError('You must select a patient'),
    participant_ids: yup
        .array()
        .min(1, 'You must add participant(s)')
        .required(),
    subject: yup.string().required(),
    message: yup.string().required(),
});

const defaultValues = {
    participant_ids: [],
    patient: null,
    subject: '',
    message: '',
};

export default function NewThreadDialog({dialogRef, patient}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const globals = useGlobals();
    useEffect(() => {
        globals.fetchAll('user.Summaries');
    }, [globals]);

    const handleSubmit = values => {
        const data = {
            type: 'NewThread',
            attributes: {
                patient_id: values.patient.id,
                participant_ids: values.participant_ids,
                subject: values.subject,
                message: values.message,
            },
        };
        resolve(data);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (initialValues = defaultValues) => {
        return new Promise((resolve, reject) => {
            const values = !!patient
                ? fp.set('patient', patient, defaultValues)
                : defaultValues;
            setInitialValues(values);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>New Internal Message Thread</DialogTitle>
                        <DialogContent>
                            <Field
                                component={PatientSelectField}
                                label='Regarding Patient'
                                name='patient'
                                fullWidth
                                disabled={!!patient}
                            />
                            <Field
                                component={ThreadParticipantsSelectField}
                                label='To'
                                name='participant_ids'
                                fullWidth
                                patient={values.patient}
                            />
                            <Field
                                component={TextField}
                                label='Subject'
                                name='subject'
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                label='Message'
                                name='message'
                                fullWidth
                                multiline
                                minRows={4}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
