import React, {useRef, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Card,
    CardHeader,
    CardContent,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {
    Add,
    ChevronLeft,
    Delete,
    MoreVert,
    InsertDriveFile,
    Description,
} from '@material-ui/icons';
import {SpeedDial, SpeedDialAction} from '@material-ui/lab';
import {useApi} from '@arborian/narrf';
import {url_for} from 'ccm/routes';

import CarePlanMetadataForm from 'ccm/components/forms/CarePlanMetadataForm';

import FocusEditor from './FocusEditor';
import NewFocusDialog from './NewFocusDialog';
import MultiFocusDialog from './MultiFocusDialog';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiCard-root': {
            margin: theme.spacing(1, 0),
        },
    },
    vbox: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    hbox: {
        display: 'flex',
        flex: 1,
    },
    flex: {
        flex: 1,
    },
    fab: {
        zIndex: 1,
        position: 'fixed',
        bottom: 48,
        right: theme.spacing(2),
    },
}));

function randint() {
    return (100000 * Math.random()).toFixed();
}

function _focusFromTemplate(template) {
    const newId = randint();
    let newFocus = {
        id: newId,
        description: '',
        status: 'Active',
        goals: [],
        interventions: [],
    };
    if (!template) return newFocus;
    newFocus = fp.merge(newFocus, {
        description: template.attributes.description,
        goals: fp.map(
            it => ({
                goalId: randint(),
                status: 'Active',
                description: it,
            }),
            template.attributes.goals,
        ),
        interventions: fp.map(
            it => ({
                interventionId: randint(),
                status: 'Active',
                description: it,
            }),
            template.attributes.interventions,
        ),
    });
    return newFocus;
}

function useCarePlan(carePlan) {
    const api = useApi();

    const patch = useCallback(
        data => {
            return api.fetchJson(data.links.self, {
                method: 'PATCH',
                json: {data},
            });
        },
        [api],
    );

    const addFocuses = useCallback(
        async templates => {
            const newFocuses = fp.map(_focusFromTemplate, templates);
            const data = fp.set(
                'attributes.focuses',
                [...fp.get('attributes.focuses', carePlan), ...newFocuses],
                carePlan,
            );
            await patch(data);
        },
        [carePlan, patch],
    );

    const addFocus = useCallback(
        async template => {
            const newFocus = _focusFromTemplate(template);
            const newId = newFocus.id;
            const data = fp.set(
                'attributes.focuses',
                [...fp.get('attributes.focuses', carePlan), newFocus],
                carePlan,
            );
            await patch(data);
            const element = document.getElementById(`focus-${newId}`);
            if (element) {
                element.scrollIntoView();
            }
        },
        [patch, carePlan],
    );

    const saveFocus = useCallback(
        async values => {
            const newFocuses = fp.map(f => {
                if (f.id === values.id) {
                    return values;
                } else {
                    return f;
                }
            }, fp.get('attributes.focuses', carePlan));
            const data = fp.set('attributes.focuses', newFocuses, carePlan);
            await patch(data);
        },
        [patch, carePlan],
    );

    const removeFocus = useCallback(
        async focus => {
            const newFocus = fp.remove(
                f => f.id === focus.id,
                fp.get('attributes.focuses', carePlan),
            );
            const data = fp.set('attributes.focuses', newFocus, carePlan);
            await patch(data);
        },
        [patch, carePlan],
    );

    const newTemplate = useCallback(
        async (condition, practiceId) => {
            const data = {
                type: 'CarePlanTemplate',
                attributes: {
                    description: fp.get('attributes.code.text', condition),
                    code: fp.get('attributes.code', condition),
                },
                relationships: {
                    practice: {
                        data: {
                            type: 'Practice',
                            id: practiceId,
                        },
                    },
                },
            };
            return await api.fetchJson(
                api.url_for('careplan.TemplateCollection'),
                {
                    method: 'POST',
                    json: {data},
                },
            );
        },
        [api],
    );

    return {
        patch,
        addFocus,
        addFocuses,
        saveFocus,
        removeFocus,
        newTemplate,
    };
}

function IconMenu({icon, menuRef, children}) {
    const [anchorEl, setAnchorEl] = useState(null);
    if (menuRef) {
        menuRef.current = {
            onClose: () => setAnchorEl(null),
        };
    }
    return (
        <>
            <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
                {icon}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {children}
            </Menu>
        </>
    );
}

function FocusMenu({carePlan, focus}) {
    const menuRef = useRef();
    const actions = useCarePlan(carePlan);
    const removeFocus = async ev => {
        await actions.removeFocus(focus);
        if (menuRef.current) {
            menuRef.current.onClose();
        }
    };
    return (
        <IconMenu icon={<MoreVert />} menuRef={menuRef}>
            <MenuItem onClick={removeFocus}>
                <ListItemIcon>
                    <Delete />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
        </IconMenu>
    );
}

function Focus({carePlan, focus}) {
    const actions = useCarePlan(carePlan);
    const classes = useStyles();
    return (
        <Accordion>
            <AccordionSummary>
                <h6>Focus: {fp.get('description', focus)}</h6>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.vbox}>
                    <div className={classes.hbox}>
                        <div className={classes.flex} />
                        <FocusMenu carePlan={carePlan} focus={focus} />
                    </div>
                    <FocusEditor
                        value={focus}
                        onChange={values => actions.saveFocus(values)}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function AddFocusButton({carePlan}) {
    const actions = useCarePlan(carePlan);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleEmptyFocus = ev => {
        actions.addFocus();
    };
    const handleFocusFromTemplate = ev => {
        setDialogOpen(true);
    };
    return (
        <>
            <NewFocusDialog
                carePlan={carePlan}
                open={dialogOpen}
                actions={actions}
                onClose={() => setDialogOpen(false)}
            />
            <SpeedDial
                id='speedDialNewFocusBtn'
                FabProps={{variant: 'extended'}}
                className={classes.fab}
                open={open}
                ariaLabel='New focus'
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                icon={
                    <>
                        <Add /> New Focus
                    </>
                }
            >
                <SpeedDialAction
                    id='speedDialBlankBtn'
                    icon={<InsertDriveFile />}
                    tooltipTitle='Blank'
                    tooltipOpen
                    onClick={handleEmptyFocus}
                />
                <SpeedDialAction
                    id='speedDialTemplateBtn'
                    icon={<Description />}
                    tooltipTitle='Template'
                    tooltipOpen
                    onClick={handleFocusFromTemplate}
                />
            </SpeedDial>
        </>
    );
}

export default function CarePlanEditor({carePlan}) {
    const classes = useStyles();
    const actions = useCarePlan(carePlan);
    const [dialogOpen, setDialogOpen] = useState(false);
    const backHref = url_for('patientInfo', {
        patientId: fp.get('relationships.patient.data.id', carePlan),
        type: 'carePlans',
    });
    return (
        <div className={classes.root}>
            <MultiFocusDialog
                carePlan={carePlan}
                open={dialogOpen}
                actions={actions}
                onClose={() => setDialogOpen(false)}
            />
            <AddFocusButton carePlan={carePlan} />
            <Button
                variant='contained'
                startIcon={<ChevronLeft />}
                component={Link}
                to={backHref}
            >
                Return to care plans
            </Button>
            <Card>
                <CardHeader title='Care Plan Status & Review' />
                <CardContent>
                    <CarePlanMetadataForm
                        value={carePlan}
                        onSubmit={actions.patch}
                    />
                </CardContent>
            </Card>
            <Button
                variant='contained'
                color='primary'
                onClick={ev => setDialogOpen(true)}
            >
                Add focuses for multiple conditions
            </Button>
            <Card>
                <CardHeader title='Care Plan Focus Area(s)' />
                <CardContent>
                    {fp.pipe([
                        fp.get('attributes.focuses'),
                        fp.map(f => (
                            <Focus key={f.id} carePlan={carePlan} focus={f} />
                        )),
                    ])(carePlan)}
                </CardContent>
            </Card>
        </div>
    );
}
