import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {ducks} from '@arborian/narrf';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';
import PortalConfig from 'ccm/components/EHRConfig/PortalConfig';

import OverridesFields from './OverridesFields';
import UploadConfig from './UploadConfig';

const selectRelationshipReverse = (objectId, relationship, type) =>
    createSelector(
        ducks.jsonapi.selectObject([type]),
        fp.filter(
            item =>
                fp.get(['relationships', relationship, 'data', 'id'], item) ===
                objectId,
        ),
    );

const usePFConfigLists = config => {
    // These have already been fetched; don't do it again, just select from the state.
    const configId = config?.id;
    const selectDocumentTypes = useMemo(
        () =>
            selectRelationshipReverse(
                configId,
                'ehr_connection',
                'PFDocumentType',
            ),
        [configId],
    );
    const selectProviders = useMemo(
        () =>
            selectRelationshipReverse(configId, 'ehr_connection', 'PFProvider'),
        [configId],
    );
    const documentTypes = useSelector(selectDocumentTypes);
    const providers = useSelector(selectProviders);
    return {documentTypes, providers};
};

export default function PFConfig({config, onSubmit}) {
    const {documentTypes, providers} = usePFConfigLists(config);

    if (fp.isEmpty(documentTypes) || fp.isEmpty(providers)) {
        return (
            <div>
                <h6>PracticeFusion Configuration</h6> Loading...
            </div>
        );
    }
    return (
        <Formik enableReinitialize initialValues={config} onSubmit={onSubmit}>
            {formikProps => (
                <Form>
                    <h6>PracticeFusion Configuration</h6>
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Miscellaneous Document Type'
                        name='relationships.miscDocumentType.data'
                        options={documentTypes}
                        getOptionLabel={fp.get(
                            'attributes.data.documentTypeName',
                        )}
                    />
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Progress Note Document Type'
                        name='relationships.documentType.data'
                        options={documentTypes}
                        getOptionLabel={fp.get(
                            'attributes.data.documentTypeName',
                        )}
                    />
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Provider'
                        name='relationships.provider.data'
                        options={providers}
                        getOptionLabel={fp.get('attributes.data.providerName')}
                    />
                    <fieldset>
                        <legend>STAT Ticket Override</legend>
                        <p>Tickets marked as STAT will be uploaded as...</p>
                        <UploadConfig
                            name='attributes.stat_override'
                            documentTypes={documentTypes}
                            providers={providers}
                        />
                    </fieldset>
                    <OverridesFields
                        name='attributes.overrides'
                        documentTypes={documentTypes}
                        providers={providers}
                    />
                    <PortalConfig config={config} />
                    <Field
                        component={TextField}
                        multiline
                        label='Progress Note Template'
                        name='attributes.progressNoteTemplate'
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />

                    <div>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            Save Configuration
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
