import React from 'react';

import {Tooltip, Chip} from '@material-ui/core';
import {Home, Business, Print, Smartphone} from '@material-ui/icons';

export default function ContactPointChip({value, ...props}) {
    let icon = null;
    let title = null;
    let href = null;
    let canonical = value.canonical || value.value;
    if (value.system === 'fax') {
        icon = <Print />;
        title = 'Fax';
        href = `fax:${canonical}`;
    } else if (value.use === 'home') {
        icon = <Home />;
        title = 'Home';
        href = `tel:${canonical}`;
    } else if (value.use === 'work') {
        if (value.system === 'pager') {
            icon = <Business />;
            title = 'Pager';
            href = `tel:${canonical}`;
        } else {
            icon = <Business />;
            title = 'Office';
            href = `tel:${canonical}`;
        }
    } else if (value.use === 'mobile') {
        icon = <Smartphone />;
        title = 'Mobile';
        href = `sms:${canonical}`;
    }
    const label = value.value;

    return (
        <Tooltip title={title}>
            <Chip
                clickable
                component='a'
                icon={icon}
                label={label}
                href={href}
                {...props}
            />
        </Tooltip>
    );
}
