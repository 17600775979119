import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import Filters from './Filters';
import TableView from './TableView';
import PatientTicketTable from './PatientTicketTable';
import PatientVisitTable from './PatientVisitTable';
import {usePracticePatients, useActions} from './hooks';

const {dt2} = components;

function PatientDetail({
    practice,
    patient,
    patientDataSource,
    actions,
    showTickets,
    onChanged,
}) {
    return (
        <div>
            <PatientVisitTable practice={practice} patient={patient} />
            {showTickets && (
                <PatientTicketTable
                    patient={patient}
                    patientDataSource={patientDataSource}
                    actions={actions}
                    onChanged={onChanged}
                />
            )}
        </div>
    );
}

const mergeFetchOptions = {
    filter: {
        'attributes.active': true,
        'attributes.primary_patient_id': null,
    },
};

export default function VisitCreator({practice}) {
    const actions = useActions(practice);
    const ds = usePracticePatients({practice, mergeFetchOptions});
    const filterPrefix = 'attributes.hasTickets_';
    const handleCreate = async (ev, items) => {
        const patients = fp.map('data', items);
        const visits = await actions.createVisits(patients);
        if (visits) {
            await ds.fetch();
        }
    };
    const showTickets = !!fp.get(
        ['fetchOptions', 'filter', `${filterPrefix}since`],
        ds,
    );
    const canCreateVisit = fp.includes(
        'Practice.SCHEDULE',
        practice.meta.allowed_actions,
    );
    return (
        <div>
            <Filters filterPrefix={filterPrefix} dataSource={ds} />
            <TableView
                options={{
                    search: true,
                    selection: canCreateVisit,
                    filtering: true,
                }}
                practice={practice}
                dataSource={ds}
            >
                <dt2.DetailPanel
                    id='detail-tickets'
                    render={row => (
                        <PatientDetail
                            practice={practice}
                            patient={row.data}
                            patientDataSource={ds}
                            actions={actions}
                            onChanged={() => ds.fetch()}
                            showTickets={showTickets}
                        />
                    )}
                />
                {canCreateVisit && (
                    <dt2.Action
                        onClick={handleCreate}
                        tooltip='Schedule Visit'
                        icon='event'
                    />
                )}
            </TableView>
        </div>
    );
}
