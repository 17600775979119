import React, {useRef} from 'react';

import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {useApi, components} from '@arborian/narrf';
import {useTicketTypes} from 'ccm/dataSource';
import {useDialog} from 'ccm/components/dialogs';

import TicketTypeCreateDialog from './TicketTypeCreateDialog';
import {SwitchField, TextField} from '../editable';
const {dt2} = components;

export default function TicketTypesPanel({practice}) {
    return (
        <Accordion index='ticketTypes'>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h5>Ticket Types</h5>
            </AccordionSummary>
            <AccordionDetails>
                <TicketTypesTable practice={practice} />
            </AccordionDetails>
        </Accordion>
    );
}

function TicketTypesTable({practice}) {
    const api = useApi();
    const tableRef = useRef();
    const ds = useTicketTypes();
    const dialog = useDialog(TicketTypeCreateDialog);

    const handleCreate = async () => {
        let attributes = await dialog();
        if (!attributes) return;
        const data = {
            type: 'TicketType',
            attributes: {
                practice_id: practice.id,
                code: attributes.code,
                label: attributes.label,
            },
        };
        await api.fetchJson(api.url_for('ticketType.Collection'), {
            method: 'POST',
            json: {data},
        });
        ds.fetch();
    };

    return (
        <div>
            <dt2.DataTable
                id='ticket-types-table'
                tableRef={tableRef}
                dataSource={ds}
                options={{
                    search: true,
                    filtering: true,
                    addRowAtBottom: false,
                }}
            >
                <dt2.Column
                    title='Active'
                    field='attributes.status'
                    filtering={false}
                    render={item => (
                        <SwitchField
                            resource={item.data}
                            field='attributes.status'
                            onValue='enabled'
                            offValue='disabled'
                        />
                    )}
                />
                <dt2.Column title='Code' field='attributes.code' />

                <dt2.Column
                    title='Label'
                    field='attributes.label'
                    render={item => (
                        <TextField
                            resource={item.data}
                            field='attributes.label'
                            iconBefore
                        />
                    )}
                />

                <dt2.Column
                    title='Archive on Resolve'
                    field='attributes.archive_on_resolve'
                    filtering={false}
                    render={item => (
                        <SwitchField
                            resource={item.data}
                            field='attributes.archive_on_resolve'
                        />
                    )}
                />
                <dt2.Action
                    id='table-refresh'
                    free
                    onClick={() => ds.fetch()}
                    tooltip='Refresh'
                    icon='refresh'
                />
                <>
                    <dt2.Action
                        id='create'
                        free
                        onClick={handleCreate}
                        tooltip='Add New Type'
                        icon='add'
                    />
                </>
            </dt2.DataTable>
        </div>
    );
}
