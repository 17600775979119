import React, {useRef} from 'react';
import fp from 'lodash/fp';

import {Icon} from '@material-ui/core';
import {components} from '@arborian/narrf';

import {useFacilities} from 'ccm/dataSource';
import {useDialog} from 'ccm/components/dialogs';
import ConfirmationDialog from 'ccm/components/dialogs/ConfirmationDialog';

const {dt2} = components;

const defaultFetchOptions = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.name'},
    filter: {'attributes.active': true},
};

export default function FacSelectTable({
    facilityIds,
    setFacilityIds,
    handleUpdate,
}) {
    const tableRef = useRef();
    const ds = useFacilities({
        prefix: null,
        initialFetchOptions: defaultFetchOptions,
    });
    const confirmDelete = useDialog(ConfirmationDialog);
    const refresh = () => ds.fetch();

    const setVisible = async rows => {
        const facIds = fp.union(facilityIds, fp.map('id', rows));
        setFacilityIds(facIds);
        await handleUpdate(facIds);
        tableRef.current.onSelectAll(false); // Deselect all rows
    };

    const unsetVisible = async rows => {
        if (
            await confirmDelete({
                title: 'Are you sure?',
                prompt: `Unsetting a facility will also clear its routing rules and shift assignments.`,
                yesLabel: 'Confirm',
                noLabel: 'Cancel',
            })
        ) {
            const facIds = fp.difference(facilityIds, fp.map('id', rows));
            setFacilityIds(facIds);
            await handleUpdate(facIds);
            tableRef.current.onSelectAll(false); // Deselect all rows
        }
    };

    const indicateVisible = row => {
        const facId = fp.get('data.id', row);
        const isVisible = fp.includes(facId, facilityIds);
        return isVisible ? <Icon>check</Icon> : null;
    };

    return (
        <dt2.DataTable
            tableRef={tableRef}
            dataSource={ds}
            title='Available Facilities'
            size='small'
            options={{
                filtering: true,
                selection: true,
                search: true,
            }}
        >
            <dt2.Column title='Facility' field='attributes.name' />
            <dt2.Column title='Organization' field='attributes.org_name' />
            <dt2.Column
                title='Address'
                filtering={false}
                field='attributes.address.formatted'
            />
            <dt2.Column
                title='Visible'
                filtering={false}
                sorting={false}
                render={indicateVisible}
            />
            <dt2.Action
                id='setVisibleAction'
                name='setVisible'
                onClick={(evt, rows) => setVisible(rows)}
                tooltip='Set Visible'
                icon='visibility'
            />
            <dt2.Action
                id='unsetVisibleAction'
                name='unsetVisible'
                onClick={(evt, rows) => unsetVisible(rows)}
                tooltip='Unset Visible'
                icon='visibility_off'
            />
            <dt2.Action
                id='refresh'
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
