import React, {useRef, useCallback} from 'react';
import fp from 'lodash/fp';

import {Hidden, makeStyles} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import {useFiles} from 'ccm/dataSource';
import TableView from './TableView';
import ListView from './ListView';

const useStyles = makeStyles(theme => ({
    hidden: {display: 'none'},
}));

export default function PatientFiles({patient, ...props}) {
    const api = useApi();
    const inputRef = useRef();
    const dsFiles = useFiles(patient);
    const handleAdd = useCallback(
        async ev => {
            const url = fp.get('relationships.files.links.related', patient);
            let file = ev.currentTarget.files[0];
            let body = new FormData();
            body.append('file', file);
            await api.fetchJsonApi(url, {method: 'POST', body});
            inputRef.current.value = null;
            dsFiles.fetch();
        },
        [api, patient, dsFiles],
    );
    const handleUpload = () => inputRef.current.click();
    const dataSource = {...dsFiles, handleAdd, handleUpload};
    const classes = useStyles();
    return (
        <>
            <input
                type='file'
                ref={inputRef}
                onChange={handleAdd}
                className={classes.hidden}
            />

            <Hidden smDown lgUp>
                <TableView
                    dataSource={dataSource}
                    patient={patient}
                    {...props}
                />
            </Hidden>
            <Hidden mdUp>
                <ListView
                    dataSource={dataSource}
                    patient={patient}
                    {...props}
                />
            </Hidden>
            <Hidden mdDown>
                <ListView
                    dataSource={dataSource}
                    patient={patient}
                    {...props}
                />
            </Hidden>
        </>
    );
}
