import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

import BasePage from 'ccm/components/BasePage';
import Inbox from 'ccm/components/Inbox';
import MyInvitations from 'ccm/components/MyInvitations';
import InternalMessagingInbox from 'ccm/components/InternalMessaging';
import ThreadPermissions from 'ccm/components/InternalMessaging/Permissions';

export default function UserInboxPage() {
    const user = useSelector(selectCurrentUser);
    const hasThreadPermission = ThreadPermissions();

    return (
        <BasePage wide title='User Inbox'>
            <MyInvitations />
            {hasThreadPermission.read && (
                <InternalMessagingInbox
                    user={user}
                    isPracticeUser={hasThreadPermission.create}
                />
            )}
            <br />
            <Inbox
                href={fp.get('relationships.inbox.links.related', user)}
                includePatient
            />
        </BasePage>
    );
}
