import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    MenuItem,
    IconButton,
    TextField,
    makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import AutosuggestFetch from 'ccm/components/AutosuggestFetch';

import {useApi, ducks} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function CodeSelectDialog({dialogRef}) {
    const api = useApi();
    const [resolve, setResolve] = useState(null);
    const systems = useSelector(ducks.jsonapi.selectObject('CodeSystem'));
    const [system, setSystem] = useState(null);
    const classes = useStyles();

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = () => {
        api.fetchAllJsonApi(api.url_for('coding.SystemCollection'));
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    const handleChangeSystem = ev => {
        const sys = systems[ev.target.value];
        setSystem(sys);
    };

    const handleChangeCode = (ev, value, reason) => {
        console.log('Changing code', {value, reason});
        if (reason === 'select-option') {
            handleSubmit(value);
        }
    };

    return (
        <Dialog fullWidth maxwidth='lg' open={!!resolve} onClose={handleCancel}>
            <DialogTitle>
                Code Selector
                <IconButton
                    onClick={handleCancel}
                    className={classes.closeButton}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    select
                    fullWidth
                    label='Coding system'
                    value={fp.get('id', system)}
                    onChange={handleChangeSystem}
                >
                    {fp.map(
                        sys => (
                            <MenuItem key={sys.id} value={sys.id}>
                                {fp.get('id', sys)}
                            </MenuItem>
                        ),
                        systems,
                    )}
                </TextField>
                {system && (
                    <AutosuggestFetch
                        href={fp.get(
                            'relationships.codes.links.related',
                            system,
                        )}
                        onChange={handleChangeCode}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}
