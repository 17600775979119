import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    ListItem as MUIListItem,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import {ShortDateTime} from 'ccm/components/DateTime';

import {Delete} from './ActionButtons';

export default function ListItem({dataSource, item}) {
    const primary = fp.get('attributes.file.filename', item.data);
    const secondary = (
        <>
            Uploaded on{' '}
            <ShortDateTime
                value={fp.get('attributes.file.uploadDate', item.data)}
            />
        </>
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(item.data));
    const canDelete = fp.pipe([
        fp.get('patient.meta.allowed_actions'),
        fp.includes('Common.WRITE'),
    ])(rel);

    return (
        <MUIListItem
            dense
            button
            component='a'
            target='_blank'
            href={fp.get('data.links.download', item)}
        >
            <ListItemText primary={primary} secondary={secondary} />
            {canDelete ? (
                <ListItemSecondaryAction>
                    <Delete onClick={() => dataSource.handleDelete(item)} />
                </ListItemSecondaryAction>
            ) : null}
        </MUIListItem>
    );
}
