import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {ChromePicker} from 'react-color';

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        '& .MuiDialogTitle-root .MuiIconButton-root': {
            position: 'absolute',
            right: theme.spacing(0.5),
            top: theme.spacing(0.5),
            color: theme.palette.grey[500],
        },
    },
}));

function ColorButton({value, onChange}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                style={{backgroundColor: value}}
                onClick={() => setOpen(true)}
            >
                Shift color
            </Button>
            <Dialog
                className={classes.dialogRoot}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    Set shift color
                    <IconButton onClick={() => setOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ChromePicker color={value} onChangeComplete={onChange} />
                </DialogContent>
            </Dialog>
        </>
    );
}

function ColorField({field, form, ...other}) {
    console.log('ColorField', {field, form, ...other});
    const handleChange = value => {
        form.setFieldValue(field.name, value.hex);
    };
    return <ColorButton value={field.value} onChange={handleChange} />;
}

export default function ShiftMetadataEditorDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState({});

    const handleSubmit = values => {
        resolve({action: 'save', values});
        setResolve(null);
    };

    const handleDelete = () => {
        resolve({action: 'delete'});
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    dialogRef.current = shift => {
        return new Promise((resolve, reject) => {
            setInitialValues(shift);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>Shift Editor</DialogTitle>
                        <DialogContent>
                            <Field
                                name='attributes.color'
                                component={ColorField}
                            />
                            <Field
                                name='attributes.name'
                                component={TextField}
                                fullWidth
                                label='Shift Name'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            {initialValues.id && (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleDelete}
                                >
                                    Delete shift
                                </Button>
                            )}
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Save Shift
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
