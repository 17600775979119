import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
    Divider,
    Accordion,
    AccordionDetails,
    makeStyles,
} from '@material-ui/core';

import BasePatientPage from 'ccm/components/BasePatientPage';
import VitalsDashboard, {VitalsSummary} from 'ccm/components/VitalsDashboard';
import InfoDashboard from 'ccm/components/InfoDashboard';

import {ducks, useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    section: {
        '& header': {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        '& header hr': {
            margin: theme.spacing(2),
            flex: 1,
        },
    },
}));

export default function PatientPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId} = useParams();
    useEffect(() => {
        let url = api.url_for('patient.Patient', {patient_id: patientId});
        if (api && patientId) {
            api.fetchJson(api.url_for('log.Message'), {
                method: 'POST',
                json: {
                    event: 'patient-chart-view',
                    patient_id: patientId,
                    LOGLEVEL: 10,
                },
            });
        }
        api.fetchJsonApi(url, {
            include: [
                'facility',
                'latest_observations',
                'ehr_connection',
                'discharge_date',
                'secondary_patients',
            ],
        });
    }, [api, patientId]);

    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );

    return (
        <BasePatientPage title='Home' patient={patient}>
            <Accordion>
                <VitalsSummary patient={patient} />
                <AccordionDetails>
                    <VitalsDashboard patient={patient} />
                </AccordionDetails>
            </Accordion>
            <section className={classes.section}>
                <header id='healthInfoHeader'>
                    <h5>Health info</h5>
                    <Divider />
                </header>
                <InfoDashboard patient={patient} />
            </section>
        </BasePatientPage>
    );
}
