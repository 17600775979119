import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';

import {Paper, Button, makeStyles} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import BasePatientPage from 'ccm/components/BasePatientPage';

import Flowsheet from 'ccm/components/Flowsheet';

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
    },
}));

export default function FlowsheetPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId} = useParams();
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );

    useEffect(() => {
        const url = api.url_for('patient.Patient', {patient_id: patientId});
        api.fetchJsonApi(url, {include: ['facility']});
    }, [api, patientId]);

    return (
        <BasePatientPage title={'Flowsheet'} patient={patient}>
            <Paper className={classes.paper}>
                <Button
                    component={Link}
                    to={url_for('patient', {patientId: patientId})}
                >
                    Return to Dashboard
                </Button>
                <Flowsheet patient={patient} />
            </Paper>
        </BasePatientPage>
    );
}
