import React from 'react';

import GaugeChart from 'react-gauge-chart';

import {FlashAvatar} from 'ccm/art/MedicalIcons';
import VitalCard from './VitalCard';

export default function PainCard({patient, artifact}) {
    if (!artifact) return null;
    const title = artifact
        ? `${artifact.attributes.value.value} / 10`
        : '? / 10';
    const percent = artifact ? artifact.attributes.value.value / 10 : 0;
    return (
        <VitalCard
            color='red'
            Avatar={FlashAvatar}
            title={title}
            type='pain'
            patient={patient}
            {...artifact.attributes}
        >
            <h6>Pain level</h6>
            <div>
                <GaugeChart hideText id='painGauge' percent={percent} />
            </div>
        </VitalCard>
    );
}
