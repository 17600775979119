import React, {useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {useApi, ducks} from '@arborian/narrf';

import {TabProvider, Tabs, Tab, TabPanel} from 'ccm/components/TabPanels';

import useStyles from './styles';
import HomePanel from './HomePanel';
import DirectoryPanel from './DirectoryPanel';
import RoutingPanel from './RoutingPanel';
import UsersPanel from './UsersPanel';
import SchedulingPanel from './SchedulingPanel';
import ClinicalPanel from './ClinicalPanel';
import EhrPanel from './EhrPanel';
import FaxBackPanel from './FaxBackPanel';

const ALL_PANELS = [
    {
        id: 'home',
        Component: HomePanel,
        label: 'Home',
        permissions: ['Common.ADMIN', 'Practice.NOTE_PREP', 'Practice.SIGN'],
    },
    {
        id: 'directory',
        Component: DirectoryPanel,
        label: 'Directory',
        permissions: ['Common.ADMIN', 'Practice.NOTE_PREP', 'Practice.SIGN'],
    },
    {
        id: 'routing',
        Component: RoutingPanel,
        label: 'Ticket Routing',
        permissions: ['Common.ADMIN'],
    },
    {
        id: 'users',
        Component: UsersPanel,
        label: 'Users',
        permissions: ['Common.ADMIN'],
    },
    {
        id: 'scheduling',
        Component: SchedulingPanel,
        label: 'Scheduling',
        permissions: [
            'Common.ADMIN',
            'Practice.SCHEDULE',
            'Practice.NOTE_PREP',
        ],
    },
    {
        id: 'clinical',
        Component: ClinicalPanel,
        label: 'Clinical',
        permissions: ['Common.ADMIN', 'Common.SIGN'],
    },
    {
        id: 'ehr',
        Component: EhrPanel,
        label: 'EHR',
        permissions: ['Common.ADMIN', 'Practice.SIGN', 'Practice.NOTE_PREP'],
    },
    {
        id: 'faxback',
        Component: FaxBackPanel,
        label: 'Fax-Back Rules',
        permissions: ['Common.ADMIN'],
    },
];

export default function PracticeManagement({practiceId}) {
    const api = useApi();
    const classes = useStyles();
    const practice = useSelector(
        ducks.jsonapi.selectObject(['Practice', practiceId]),
    );
    useEffect(() => {
        api.fetchJsonApi(
            api.url_for('practice.Practice', {practice_id: practiceId}),
        );
    }, [api, practiceId]);

    if (!practice) return null;
    console.log({practice});
    const actions = practice.meta.allowed_actions;
    const showTabs =
        fp.includes('Common.ADMIN', actions) ||
        fp.includes('Practice.NOTE_PREP', actions) ||
        fp.includes('Practice.SIGN', actions);

    if (!showTabs) return <HomePanel practice={practice} />;

    const panels = fp.filter(
        panel => fp.some(perm => fp.includes(perm, actions), panel.permissions),
        ALL_PANELS,
    );

    return (
        <div className={classes.root}>
            <h5>{fp.get('attributes.name', practice)}</h5>
            <TabProvider queryKey='tab' defaultTab='home'>
                <Tabs variant='scrollable'>
                    {fp.map(
                        panel => (
                            <Tab
                                key={panel.id}
                                value={panel.id}
                                label={panel.label}
                            />
                        ),
                        panels,
                    )}
                </Tabs>
                {fp.map(
                    panel => (
                        <TabPanel key={panel.id} index={panel.id}>
                            <panel.Component practice={practice} />
                        </TabPanel>
                    ),
                    panels,
                )}
            </TabProvider>
        </div>
    );
}
