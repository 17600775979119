import React from 'react';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';

export default function VisitTypeChip({attrs, ...props}) {
    const name = fp.get('name', attrs);
    const color = fp.get('color', attrs);
    const backgroundColor = fp.get('backgroundColor', attrs);
    const style = {color, backgroundColor};
    return (
        <Button style={style} variant='contained' {...props}>
            {name}
        </Button>
    );
}
