import {useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {useApi, ducks} from '@arborian/narrf';

const SELECTION_LISTS = [
    'transcriptTypes',
    'encounterTypes',
    'documentTypes',
    'templates',
    'providers',
    'facilities',
];

export function useConfig(ehrConnection) {
    const api = useApi();
    const config = useSelector(
        ducks.jsonapi.selectObject(['EHRConfig', ehrConnection.id]),
    );

    useEffect(() => {
        if (config) return;
        const configUrl = fp.get('links.config', ehrConnection);
        if (configUrl) {
            api.fetchJsonApi(configUrl, {include: SELECTION_LISTS});
        }
    }, [api, config, ehrConnection]);
    return config;
}
