import Tour from './tour_base';

const TourPractice = new Tour({
    id: 'practice',
    label: 'Tour the Practice Page',
    location_context: /practice\/([0-9a-f]+)$/,
    group_context: /.*/,
    steps: [
        {
            content: () => (
                <>
                    <p>
                        Practices now have the option to
                        link their own Visit Satisfaction
                        surveys. {' '}
                        <a
                            href='https://samepagemd.zendesk.com/hc/en-us/articles/13341621361171'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Click here to learn more!
                        </a>
                    </p>
                </>
            ),
        },
    ],
});
export default TourPractice;