import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {IconButton, Menu, MenuItem, ListItemIcon} from '@material-ui/core';
import {
    Today,
    MoreVert,
    Home,
    Group,
    ViewColumn,
    Settings,
} from '@material-ui/icons';

import {url_for} from 'ccm/routes';

const BASE_ENTRIES = [
    {id: 'home', icon: Home, label: 'Return to patient home', route: 'patient'},
    {
        id: 'visits',
        icon: Today,
        label: 'View upcoming visits',
        route: 'patientVisits',
    },
    {
        id: 'groups',
        icon: Group,
        label: 'View care group',
        route: 'patientGroup',
    },
    {
        id: 'flowsheet',
        icon: ViewColumn,
        label: 'View flowsheet',
        route: 'flowsheet',
    },
    {
        id: 'preferences',
        icon: Settings,
        label: 'View/edit preferences',
        route: 'patientPreferences',
    },
];

export default function PatientContextMenu({patient}) {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuEntries = fp.map(
        ent => ({
            ...ent,
            url: url_for(ent.route, {patientId: patient.id}),
        }),
        BASE_ENTRIES,
    );

    return (
        <>
            <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={ev => setAnchorEl(null)}
            >
                {fp.map(
                    ent => (
                        <MenuItem
                            id={ent.id}
                            key={ent.id}
                            onClick={() => history.push(ent.url)}
                        >
                            <ListItemIcon>
                                <ent.icon />
                            </ListItemIcon>
                            {ent.label}
                        </MenuItem>
                    ),
                    menuEntries,
                )}
            </Menu>
        </>
    );
}
