import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {TaskTable} from 'ccm/components/TaskLog';

export default function TaskLogDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [logs, setLogs] = useState([]);

    const handleClose = () => {
        resolve(null);
        setResolve(null);
        setLogs([]);
    };

    dialogRef.current = logs => {
        return new Promise((resolve, reject) => {
            setLogs(logs);
            // setTab(logs[0].id);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleClose} maxWidth='lg' fullWidth>
            <DialogTitle>Task Progress</DialogTitle>
            <DialogContent>
                <TaskTable logs={logs} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
