import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {Tooltip} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';

export default function PatientVisit({patient, name}) {
    const linkage = fp.get(['relationships', name, 'data'], patient);
    const path = linkage ? [linkage.type, linkage.id] : null;
    const visit = useSelector(ducks.jsonapi.selectObject(path));
    if (!visit) return '--';
    const visitDate = fp.get('attributes.visit_date', visit);
    const providerName = fp.get('attributes.provider_name', visit);
    const reason = fp.get('attributes.reason', visit);
    return (
        <Tooltip title={`${providerName}: ${reason}`}>
            <span>{h.formatDate(visitDate)}</span>
        </Tooltip>
    );
}
