import React, {useMemo} from 'react';
import fp from 'lodash/fp';

import {Paper, useTheme, makeStyles} from '@material-ui/core';
import Chart from 'react-apexcharts';

const useStyles = makeStyles(theme => ({
    root: {},
}));

const OPTIONS = {
    xaxis: {type: 'datetime'},

    dataLabels: {
        enabled: true,
        formatter: val => `${val}%`,
    },
};

export default function TherapyPlot({therapy}) {
    const theme = useTheme();
    const classes = useStyles();
    const series = useMemo(
        () => [
            {
                name: fp.get('attributes.title', therapy),
                data: fp.pipe([
                    fp.get('attributes.log'),
                    fp.map(it => ({
                        x: it.timestamp,
                        y: it.percentage,
                    })),
                ])(therapy),
            },
        ],
        [therapy],
    );
    const options = fp.merge(OPTIONS, {
        title: {
            text: fp.get('attributes.title', therapy),
            offsetX: theme.spacing(2),
            offsetY: theme.spacing(2),
            margin: theme.spacing(3),
            style: {
                ...theme.typography.h6,
            },
        },
        subtitle: {
            text: fp.get('attributes.description', therapy),
            offsetX: theme.spacing(2),
            offsetY: theme.spacing(6),
            style: {
                ...theme.typography.p,
            },
        },
    });
    console.log('Render chart', {options, items: therapy.attributes.log});
    return (
        <Paper className={classes.root}>
            <Chart
                series={series}
                options={options}
                type='line'
                width='95%'
                height='400'
            />
        </Paper>
    );
}
