import fp from 'lodash/fp';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {ducks} from '@arborian/narrf';

import {useGlobals} from 'ccm/components/Globals';

const defaultFetchOptions = {};
export const useAllJsonApi = (
    route,
    objectType,
    fetchOptions = defaultFetchOptions,
) => {
    const globals = useGlobals();
    const result = useSelector(ducks.jsonapi.selectObject(objectType));
    useEffect(() => {
        globals.fetchAll(route, fetchOptions);
    }, [globals, route, fetchOptions]);
    return result;
};

export const useAllFacilities = () =>
    useAllJsonApi('facility.FacilityCollection', 'Facility');

export const useAllPractices = () =>
    useAllJsonApi('practice.PracticeCollection', 'Practice');

export const useAllUsers = () => useAllJsonApi('user.UserCollection', 'User');

export const useAllEHRPlugins = () => useAllJsonApi('ehr.Plugins', 'EHRPlugin');

export const useAllTicketTypes = () =>
    useAllJsonApi('ticketType.Collection', 'TicketType');

const authProviderFetchOptions = {include: ['plugin']};
export const useAllAuthProviders = () =>
    useAllJsonApi('auth.providers', 'Provider', authProviderFetchOptions);

export const useAllFacilitiesLookup = () =>
    fp.pipe([
        fp.values,
        fp.map(o => [o.id, o.attributes.name]),
        fp.concat([[null, 'No facility']]),
        fp.fromPairs,
    ])(useAllFacilities());

export const useAllPracticesLookup = () =>
    fp.pipe([
        fp.values,
        fp.map(o => [o.id, o.attributes.name]),
        fp.concat([[null, 'No practice']]),
        fp.fromPairs,
    ])(useAllPractices());

export const useAllEHRPluginsLookup = () =>
    fp.pipe([
        fp.values,
        fp.map(o => [o.id, o.attributes.label]),
        fp.concat([[null, 'No ehr']]),
        fp.fromPairs,
    ])(useAllEHRPlugins());

export const useAllTicketTypesLookup = () =>
    fp.pipe([
        fp.values,
        fp.map(o => [o.attributes.code, o.attributes.label]),
        fp.concat([[null, 'No ticket type']]),
        fp.fromPairs,
    ])(useAllTicketTypes());

export const useActiveTicketTypesLookup = () =>
    fp.pipe([
        fp.values,
        fp.filter(o => o.attributes.status.toLowerCase() === 'enabled'),
        fp.map(o => [o.attributes.code, o.attributes.label]),
        fp.concat([[null, 'No ticket type']]),
        fp.fromPairs,
    ])(useAllTicketTypes());
