import React, {useState} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {ducks} from '@arborian/narrf';

import ShiftEditor from './ShiftEditor';

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        '& .MuiDialogTitle-root .MuiIconButton-root': {
            position: 'absolute',
            right: theme.spacing(0.5),
            top: theme.spacing(0.5),
            color: theme.palette.grey[500],
        },
    },
}));

export default function ShiftDialog({dialogRef}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const [ruleId, setRuleId] = useState(null);
    const rule = useSelector(ducks.jsonapi.selectObject(['Rule', ruleId]));

    const handleClose = () => {
        setRuleId(null);
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = ruleId => {
        return new Promise((resolve, reject) => {
            setRuleId(ruleId);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog
            open={!!resolve}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            className={classes.dialogRoot}
        >
            <DialogTitle>
                {fp.get('attributes.name', rule)} Shift Editor
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ShiftEditor ruleId={ruleId} />
            </DialogContent>
        </Dialog>
    );
}
