import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Field} from 'formik';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormGroup,
    FormLabel,
} from '@material-ui/core';
import {CheckboxWithLabel} from 'formik-material-ui';
import {useFormikContext} from 'formik';

import PCCProgressNoteTypesField from './PCCProgressNoteTypesField';
import {selectProgressNoteTypes} from './pcc_selectors';
import {useFeatures} from 'ccm/lib/util';

const VITAL_FIELDS = [
    {id: 'show_pain', label: 'Display pain level?'},
    {id: 'show_temperature', label: 'Display body temperature?'},
    {id: 'show_heart_rate', label: 'Display heart rate?'},
    {id: 'show_blood_pressure', label: 'Display blood pressure?'},
    {id: 'show_blood_sugar', label: 'Display blood sugar?'},
    {id: 'show_oxygen_saturation', label: 'Display oxygen saturation?'},
    {id: 'show_respiration', label: 'Display respiration rate?'},
    {id: 'show_weight', label: 'Display weight?'},
    {id: 'show_height', label: 'Display height?'},
    {id: 'show_discharge_date', label: 'Display estimated discharge date?'},
];

const INFO_FIELDS = [
    {id: 'show_diagnostic_report', label: 'Display labs?'},
    {id: 'show_contact', label: 'Display contacts?'},
    {id: 'show_practitioner', label: 'Display practitioners?'},
    {id: 'show_condition', label: 'Display conditions?'},
    {id: 'show_medication', label: 'Display medications?'},
    {id: 'show_nutrition_order', label: 'Display nutrition orders?'},
    {id: 'show_therapy', label: 'Display therapy tracker?'},
    {id: 'show_discharge_date', label: 'Display estimated discharge date?'},
    {
        id: 'show_allergy_intolerance',
        label: 'Display allergies & intolerances?',
    },
    {id: 'show_immunization', label: 'Display immunizations?'},
    {id: 'show_care_plan', label: 'Display care plans?'},
    {id: 'show_progress_note', label: 'Display progress notes?'},
    {id: 'show_assessment', label: 'Display assessments?'},
];

function FieldGroup({label, fields}) {
    return (
        <FormControl fullWidth margin='dense' size='small'>
            <FormLabel component='legend'>{label}</FormLabel>
            <FormGroup>
                {fp.map(
                    f => (
                        <Field
                            key={f.id}
                            component={CheckboxWithLabel}
                            type='checkbox'
                            size='small'
                            name={`attributes.portal_config.${f.id}`}
                            Label={{label: f.label}}
                        />
                    ),
                    fields,
                )}
            </FormGroup>
        </FormControl>
    );
}

function InfoFields({config}) {
    const features = useFeatures();
    const fields = fp.filter(f => {
        if (
            f.id === 'show_assessment' &&
            !fp.get('enable_assessments', features)
        ) {
            return false;
        } else {
            return true;
        }
    }, INFO_FIELDS);
    const {values} = useFormikContext();
    const progressNoteTypes = useSelector(selectProgressNoteTypes(config));
    const noteTypes = fp.map('attributes.noteType', progressNoteTypes);
    console.log('Values is', values);
    return (
        <FormControl fullWidth margin='dense' size='small'>
            <FormLabel component='legend'>Info Fields</FormLabel>
            <FormGroup>
                {fp.map(
                    f => (
                        <Field
                            key={f.id}
                            component={CheckboxWithLabel}
                            type='checkbox'
                            size='small'
                            name={`attributes.portal_config.${f.id}`}
                            Label={{label: f.label}}
                        />
                    ),
                    fields,
                )}
                {fp.get(
                    'attributes.portal_config.show_progress_note',
                    values,
                ) && (
                    <Field
                        component={PCCProgressNoteTypesField}
                        label='Progress note type(s) to display'
                        name={`attributes.portal_config.note_categories`}
                        noteTypes={noteTypes}
                    />
                )}
            </FormGroup>
        </FormControl>
    );
}

export default function PortalConfig({config}) {
    return (
        <Accordion>
            <AccordionSummary>Patient portal configuration</AccordionSummary>
            <AccordionDetails>
                <FieldGroup label='Vital Fields' fields={VITAL_FIELDS} />
                <InfoFields config={config} />
                {/*
                <FieldGroup label='Info Fields' fields={INFO_FIELDS} />
                */}
            </AccordionDetails>
        </Accordion>
    );
}
