import React from 'react';
import fp from 'lodash/fp';
import {TextField} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

const RE_GUID =
    /[a-fA-f0-9]{8}-[a-fA-f0-9]{4}-[a-fA-f0-9]{4}-[a-fA-f0-9]{4}-[a-fA-f0-9]{12}/g;
export default function PatientPaster({
    ehr,
    value,
    onChange,
    defaultChiefComplaint,
}) {
    const api = useApi();
    const handleChangeTextField = async ev => {
        // const patientIds = ev.target.value.matchAll(RE_OBJECTID);
        const patientGuids = ev.target.value.matchAll(RE_GUID);
        let promises = [];
        for (var patientPracticeGuid of patientGuids) {
            console.log('Found patientPracticeGuid', ehr, patientPracticeGuid);
            promises.push(
                api.fetchJson(
                    api.url_for('pf_plugin.Patient', {
                        ehr_connection_id: ehr.id,
                        patientPracticeGuid,
                    }),
                ),
            );
        }
        const patients = await Promise.all(promises);
        console.log('Patient list is', patients);
        onChange(
            patients.map(p => ({
                id: fp.get('data.attributes.raw.patientPracticeGuid', p),
                name: fp.get('data.attributes.name.formatted', p),
                dob: fp.get('data.attributes.dob', p),
                chiefComplaint: defaultChiefComplaint,
            })),
        );
    };
    return (
        <TextField
            value='PASTE PATIENT IDS HERE'
            helperText='(IDs like 012345-6789-abcd-ef01-2345-67890abcdef0)'
            onChange={handleChangeTextField}
        />
    );
}
