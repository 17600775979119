import React, {useRef, useMemo} from 'react';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';
import {usePatients} from 'ccm/dataSource';
import {
    useAllPracticesLookup,
    useAllFacilitiesLookup,
} from 'ccm/dataSource/allResources';
import TelecomChips from 'ccm/components/TelecomChips';
import PatientTable from 'ccm/components/PatientTable';
import PatientTableActions from 'ccm/components/PatientTableActions';

const {dt2} = components;

const PATIENT_ADMIN_FETCH_OPTIONS = {
    sort: {field: 'attributes.name.last'},
    filter: {
        'attributes.active': true,
    },
    page: {number: 0, size: 20},
};

const PATIENT_ADMIN_OPTIONS = {
    include: ['ehr_connection'],
};

export default function PatientAdminTable() {
    const ds = usePatients({
        prefix: 'ptTable.',
        initialFetchOptions: PATIENT_ADMIN_FETCH_OPTIONS,
        options: PATIENT_ADMIN_OPTIONS,
    });
    const tableRef = useRef();
    const api = useApi();
    const lookupPractice = useAllPracticesLookup();
    const lookupFacility = useAllFacilitiesLookup();

    const showActive = useMemo(() => {
        return fp.getOr(true, ['filter', 'attributes.active'], ds.fetchOptions);
    }, [ds.fetchOptions]);

    const toggleShowInactive = ev => {
        ds.setFilter('attributes.active', !showActive);
    };

    const triggerPatientSync = async (ev, rows) => {
        const promises = fp.map(
            row =>
                api.fetchJsonApi(row.data.links.sync_patient, {
                    method: 'POST',
                }),
            rows,
        );
        await Promise.all(promises);
    };

    return (
        <PatientTable
            title='Patient Administration'
            dataSource={ds}
            tableRef={tableRef}
            size='small'
            options={{
                search: true,
                filtering: true,
                selection: true,
            }}
        >
            <dt2.Column
                title='DOB'
                type='date'
                editable={false}
                field='attributes.dob'
                render={row =>
                    h.formatDateOnly(fp.get('data.attributes.dob', row))
                }
            />
            <dt2.Column
                title='Telecom'
                editable={false}
                filtering={false}
                field='attributes.telecom'
                component={TelecomChips}
            />
            {/* Yes, these should be relationships. See the schemas.py for the full apology. */}
            <dt2.Column
                title='Facility'
                editable={false}
                field='attributes.facility_id'
                lookup={lookupFacility}
            />
            <dt2.Column
                title='Practice'
                field='attributes.practice_id'
                lookup={lookupPractice}
            />
            <PatientTableActions ds={ds} tableRef={tableRef} />
            <dt2.Action
                onClick={triggerPatientSync}
                tooltip='Sync patient(s) with EHR'
                icon='cloud_sync'
            />
            <dt2.Action
                name='grid'
                free
                icon={showActive ? 'person_off' : 'person'}
                tooltip={
                    showActive
                        ? 'Show only inactive patients'
                        : 'Show only active patients'
                }
                onClick={toggleShowInactive}
            />
        </PatientTable>
    );
}
