import React, {useEffect, useState, useRef} from 'react';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    TextField,
    Tooltip,
} from '@material-ui/core';
import {Send, Chat as ChatIcon, ChatOutlined} from '@material-ui/icons';

import {formatDateTime, formatShortDateTime} from 'ccm/lib/helpers';

const chatBreakpoint = 1500;
const chatRadius = 16;

const useStyles = makeStyles(theme => ({
    responsiveSideChat: {
        [theme.breakpoints.up(chatBreakpoint)]: {
            marginLeft: theme.spacing(2),
            minWidth: 360,
        },
        [theme.breakpoints.down(chatBreakpoint)]: {
            display: 'none',
        },
    },
    responsiveCondensedChat: {
        [theme.breakpoints.up(chatBreakpoint)]: {
            display: 'none',
        },
        [theme.breakpoints.down(chatBreakpoint)]: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    hidden: {
        display: 'none',
    },
    chatContainerHeader: {
        '& .MuiCardHeader-title': {
            alignSelf: 'center',
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
        },
        '& .MuiCardHeader-subheader': {
            fontSize: 14,
        },
    },
    chatContainerContentOverflow: {
        display: 'flex',
        flexDirection: 'column-reverse',
        maxHeight: 500,
        overflowY: 'auto',
    },
    chatContainerContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    messageCard: {
        flex: '1 0 auto',
        borderRadius: chatRadius,
        backgroundColor: '#f1f1f1',
    },
    messageContentContainer: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    thinHeader: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 12,
        fontFamily: 'Montserrat',
        paddingBottom: 0,
        '& .MuiCardHeader-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: theme.spacing(2),
        },
        '& .MuiCardHeader-title': {
            fontSize: 12,
            fontWeight: 'bold',
        },
    },
    thinContent: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 14,
        whiteSpace: 'pre-wrap',
        fontFamily: 'Montserrat',
        '& .MuiCardContent-root': {
            paddingBottom: 0,
        },
    },
    selfMessage: {
        backgroundColor: 'aliceblue',
    },
    // Compose Field CSS
    messageTextField: {
        '& .MuiOutlinedInput-multiline': {
            borderRadius: chatRadius,
            fontSize: 14,
            background: 'white',
        },
    },
    messageButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        gap: theme.spacing(1),
    },
    messageSendButton: {
        width: '100%',
        borderRadius: chatRadius,
    },
}));

const ChatBubble = ({user, content}) => {
    const classes = useStyles();
    const fromSelf = content.user_id === user?.id;

    const dateComponent = ts => (
        <Tooltip title={formatDateTime(ts)} arrow>
            <span className={classes.thinHeader}>
                {formatShortDateTime(ts)}
            </span>
        </Tooltip>
    );

    return (
        <Card
            className={`${classes.messageCard} ${
                fromSelf ? classes.selfMessage : null
            }`}
        >
            <CardHeader
                className={classes.thinHeader}
                title={content.user_name}
                subheader={dateComponent(content.created)}
            />
            <CardContent className={classes.messageContentContainer}>
                <span className={classes.thinContent}>{content.message}</span>
            </CardContent>
        </Card>
    );
};

const ChatComposeField = ({handleNewMessage}) => {
    const classes = useStyles();
    const [messageText, setMessageText] = useState('');

    const handleSubmit = () => {
        if (!messageText.length) return;
        handleNewMessage(messageText);
        setMessageText('');
    };
    const handleKeyDown = ev => {
        // Submit with Enter, new lines with shift+enter
        if (ev.key === 'Enter' && !ev.shiftKey) {
            handleSubmit();
        }
    };

    return (
        <div>
            <TextField
                disabled={false}
                className={classes.messageTextField}
                fullWidth
                multiline
                minRows={3}
                variant='outlined'
                id='outlined-helperText'
                label={false ? 'Thread Closed' : 'Send'}
                value={messageText}
                onChange={ev => setMessageText(ev.target.value)}
                onKeyDown={handleKeyDown}
            />
            <div className={classes.messageButtonContainer}>
                <Button
                    className={classes.messageSendButton}
                    disabled={false}
                    variant='contained'
                    color='primary'
                    endIcon={<Send />}
                    onClick={handleSubmit}
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

export default function Chat({
    open,
    messages,
    handleNewMessage,
    user,
    sidebar = false,
    hasChatPermission,
}) {
    const classes = useStyles();
    const ref = useRef();
    const behaviorClass = sidebar
        ? classes.responsiveSideChat
        : classes.responsiveCondensedChat;

    useEffect(() => {
        if (ref.current && open) {
            ref.current.scrollIntoView({
                block: 'nearest',
                inline: 'nearest',
                behavior: 'smooth',
            });
        }
    }, [messages, ref, open]);

    if (!open || !hasChatPermission) return null;
    return (
        <div className={behaviorClass}>
            <Card>
                <CardHeader
                    className={classes.chatContainerHeader}
                    title='Ticket Chat'
                    subheader={
                        "Ticket chat is only for intra-practice communication (not available to facilities). This will not be saved to the patient's chart."
                    }
                />
                <div
                    className={
                        fp.getOr(0, 'length', messages)
                            ? classes.chatContainerContentOverflow
                            : classes.hidden
                    }
                >
                    <CardContent className={classes.chatContainerContent}>
                        {fp.map(
                            message => (
                                <ChatBubble user={user} content={message} />
                            ),
                            messages,
                        )}
                        <div ref={ref}></div>
                    </CardContent>
                </div>
                <CardContent>
                    <ChatComposeField handleNewMessage={handleNewMessage} />
                </CardContent>
            </Card>
        </div>
    );
}

export function InitiateChatButton({
    open,
    handleClick,
    hasMessages,
    hasChatPermission,
}) {
    if (!hasChatPermission) return null;
    return (
        <IconButton onClick={handleClick}>
            {' '}
            <Badge
                overlap='rectangular'
                variant='dot'
                color='secondary'
                invisible={!hasMessages}
            >
                {open ? <ChatIcon /> : <ChatOutlined />}
            </Badge>
        </IconButton>
    );
}
