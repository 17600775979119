import React from 'react';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import {Tooltip} from '@material-ui/core';
import {Flag} from '@material-ui/icons';
import {components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';
import {useListField} from 'ccm/dataSource';

const {dt2} = components;

function TicketId({ticket}) {
    return (
        <Tooltip title={ticket.id}>
            <R.Link
                to={url_for('ticket', {
                    ticketid: ticket.id,
                })}
            >
                {fp.truncate({length: 10}, ticket.id)}
                {fp.get('attributes.priority', ticket.data) === 'urgent' && (
                    <Flag fontSize='small' color='error' />
                )}
            </R.Link>
        </Tooltip>
    );
}

export default function VisitTicketsTable({value, children, options = {}}) {
    const ds = useListField(value);
    const ticketTypes = useAllTicketTypesLookup();
    if (fp.isEmpty(value)) return null;
    return (
        <>
            <h3>Addressing Ticket(s)</h3>
            <dt2.DataTable dataSource={ds} options={options}>
                <dt2.Column
                    id='col-ticket-id'
                    title='Ticket ID'
                    field='attributes.id_str'
                    render={row => <TicketId ticket={row.data} />}
                />
                <dt2.Column
                    id='col-ticket-date'
                    title='Created'
                    field='attributes.data.created'
                    type='date'
                />
                <dt2.Column
                    id='col-ticket-type'
                    title='Ticket Type'
                    field='attributes.data.ticket_type'
                    lookup={ticketTypes}
                />
                <dt2.Column
                    id='col-status'
                    title='Status'
                    field='attributes.data.status'
                    sorting={false}
                />
                <dt2.Column
                    id='col-owner'
                    title='Owner'
                    field='attributes.data.owner_name'
                    sorting={false}
                />
                <dt2.Column
                    id='col-provider'
                    title='Provider'
                    field='attributes.data.provider_name'
                    sorting={false}
                />
                {children}
            </dt2.DataTable>
        </>
    );
}
