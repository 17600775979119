import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Button, Tab, Tabs} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {useApi, components, ducks} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import SecondaryPatientsTable from 'ccm/components/SecondaryPatientsTable';
import AdminMessageTable from 'ccm/components/AdminMessageTable';
import InvitationAdminTable from 'ccm/components/InvitationAdminTable';
import GroupMemberTable from 'ccm/components/GroupMemberTable';
import PatientInvitationSuggestions from 'ccm/components/PatientInvitationSuggestions';
import {usePatientInvitationActions} from 'ccm/lib/hooks';

import {useDialog} from 'ccm/components/dialogs';

import MessageCompositionDialog from 'ccm/components/dialogs/MessageCompositionDialog';
import PracticeSelectDialog from 'ccm/components/dialogs/PracticeSelectDialog';
import FacilitySelectDialog from 'ccm/components/dialogs/FacilitySelectDialog';
import DuplicatePatientDialog from 'ccm/components/dialogs/DuplicatePatientDialog';
import FileUploadDialog from 'ccm/components/dialogs/FileUploadDialog';
import MassTicketsDialog from 'ccm/components/dialogs/MassTicketsDialog';

const {dt2} = components;

function PrimaryPatientDetail({patient, handleRefresh}) {
    const api = useApi();

    useEffect(() => {
        if (!patient) return;
        api.fetchJsonApi(
            fp.get('relationships.primary_patient.links.related', patient),
            {
                options: {
                    include: ['ehr_connection'],
                },
            },
        );
    }, [api, patient]);

    const handleUnmap = async () => {
        await api.fetchJson(patient.relationships.primary_patient.links.self, {
            method: 'PATCH',
            json: {data: null}, // An empty body clears the linkage
        });
        handleRefresh();
    };

    const primaryPatientId = fp.get(
        'relationships.primary_patient.data.id',
        patient,
    );
    const primaryPatient = useSelector(
        ducks.jsonapi.selectObject(['Patient', primaryPatientId]),
    );
    const relPrimary = useSelector(ducks.jsonapi.selectRelated(primaryPatient));
    const relSecondary = useSelector(ducks.jsonapi.selectRelated(patient));
    console.log({patient, primaryPatient, relPrimary, relSecondary});
    if (!primaryPatient) return <p>No Primary Patient</p>;
    return (
        <>
            <p>
                <b>Primary:</b> {primaryPatient.attributes.name.formatted}
            </p>
            <p>
                <b>Primary EHR:</b>{' '}
                {fp.get('ehr_connection.attributes.label', relPrimary)}
            </p>
            <p>
                <b>Secondary (This Patient):</b>{' '}
                {patient.attributes.name.formatted}
            </p>
            <p>
                <b>Secondary EHR:</b>{' '}
                {relSecondary.ehr_connection.attributes.label}
            </p>
            <Button variant='contained' color='default' onClick={handleUnmap}>
                Unmap this patient
            </Button>
        </>
    );
}

function PatientMappingDetail({patient, handleRefresh}) {
    if (fp.get('relationships.primary_patient.data.id', patient)) {
        return (
            <PrimaryPatientDetail
                patient={patient}
                handleRefresh={handleRefresh}
            />
        );
    } else {
        return <SecondaryPatientsTable patient={patient} />;
    }
}

function PatientAccess({value}) {
    const [tab, setTab] = useState(0);
    const invitationActions = usePatientInvitationActions(value);

    if (
        !fp.isEmpty(invitationActions) &&
        !fp.get('relationships.primary_patient.data.id', value)
    ) {
        return (
            <div>
                <Tabs value={tab} onChange={(ev, index) => setTab(index)}>
                    <Tab label='Authorized Users' />
                    <Tab label='Invitations' />
                </Tabs>

                {tab === 0 ? (
                    <GroupMemberTable protectedResource={value} />
                ) : (
                    <>
                        <PatientInvitationSuggestions value={value} />
                        <InvitationAdminTable protectedResource={value} />
                    </>
                )}
            </div>
        );
    } else {
        return <GroupMemberTable protectedResource={value} />;
    }
}

export default function PatientTableActions({ds, tableRef}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const user = useSelector(selectCurrentUser);
    const practiceSelectDialog = useDialog(PracticeSelectDialog);
    const facilitySelectDialog = useDialog(FacilitySelectDialog);
    const duplicatePatientDialog = useDialog(DuplicatePatientDialog);
    const messageDialog = useDialog(MessageCompositionDialog);
    const uploadDialog = useDialog(FileUploadDialog);
    const ticketsDialog = useDialog(MassTicketsDialog);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isSuperuser = userinfo && fp.includes('__admin__', userinfo.scopes);

    const roles = useMemo(
        () =>
            fp.pipe([
                fp.get('relationships.groups.data'),
                fp.map(g => {
                    const m = g.id.match(/(.+):(.+)\/(.+)/);
                    return `${m[1]}-${m[3]}`;
                }),
                fp.sortBy(fp.identity),
                fp.sortedUniq,
            ])(user),
        [user],
    );

    const showDetails = useMemo(() => {
        return !fp.isEmpty(
            fp.intersection(roles, [
                'practice-staff',
                'practice-admin',
                'practice-provider',
                'facility-admin',
                'facility-staff',
            ]),
        );
    }, [roles]);

    const allowAssignPractice =
        useMemo(() => fp.includes('facility-admin', roles), [roles]) ||
        isSuperuser;

    const allowAssignFacility =
        useMemo(() => fp.includes('practice-admin', roles), [roles]) ||
        isSuperuser;
    const allowMapping =
        useMemo(() => fp.includes('practice-admin', roles), [roles]) ||
        isSuperuser;
    const allowBulkTicketCreate =
        useMemo(() => fp.includes('practice-admin', roles), [roles]) ||
        isSuperuser;

    const refresh = () => ds.fetch();

    const handleCompose = async (ev, rows) => {
        const tm = tableRef.current;
        const msg = await messageDialog({
            count: rows.length,
            total: tm.allRowsSelected() ? tm.state.count : rows.length,
        });
        if (!msg) return;
        const {includeAll, ...attributes} = msg;
        let patients = fp.map(r => r.data, rows);
        if (includeAll) {
            const fetchOptions = fp.pipe([
                api.jsonApiFromDataTableOptions,
                fp.omit(['page', 'sort']),
            ])(tableRef.current.fetchOptions);
            console.log({fetchOptions});
            patients = await api.fetchAllJsonApi(
                api.url_for('patient.PatientCollection'),
                fetchOptions,
            );
        }
        const payload = {data: {type: 'Message', attributes}};
        await Promise.all(
            fp.map(pt => {
                const url = fp.get('relationships.messages.links.related', pt);
                return api.fetchJson(url, {
                    method: 'POST',
                    json: payload,
                });
            }, patients),
        );
    };

    const handleFileUpload = async (ev, rows) => {
        const tm = tableRef.current;
        const response = await uploadDialog({
            count: rows.length,
            total: tm.allRowsSelected() ? tm.state.count : rows.length,
        });
        if (!response) return;
        console.log({response});

        let formBody = new FormData();
        formBody.append('file', response.file);

        if (response.includeAll) {
            const jsonapiOptions = api.jsonApiFromDataTableOptions(
                ds.fetchOptions,
            );
            formBody.append(
                'patient_filter',
                JSON.stringify(jsonapiOptions.filter),
            );
        } else {
            formBody.append(
                'patient_filter',
                JSON.stringify({
                    _id: JSON.stringify({
                        $in: fp.map(r => ({$oid: r.id}), rows),
                    }),
                }),
            );
        }
        await api.fetchJson(api.url_for('patient.BatchFileUpload'), {
            method: 'POST',
            body: formBody,
        });
        enqueueSnackbar('File successfully uploaded', {
            persist: false,
            variant: 'success',
        });
    };

    const createTickets = async (ev, rows) => {
        const tm = tableRef.current;
        const result = await ticketsDialog({
            count: rows.length,
            total: tm.allRowsSelected() ? tm.state.count : rows.length,
        });
        if (!result) return;
        let ehrPlugins = [];
        fp.forEach(k => {
            if (result.plugin[k]) {
                ehrPlugins = [k, ...ehrPlugins];
            }
        }, fp.keys(result.plugin));
        let data = {
            type: 'TicketBatch',
            attributes: {
                ticket_type: result.ticketType,
                ehrPlugins,
            },
        };
        if (result.includeAll) {
            const jsonapiOptions = api.jsonApiFromDataTableOptions(
                ds.fetchOptions,
            );
            data.attributes.patient_filter = jsonapiOptions.filter;
        } else {
            data.attributes.patient_filter = {
                _id: JSON.stringify({$in: fp.map(r => ({$oid: r.id}), rows)}),
            };
        }
        await api.fetchJson(api.url_for('ticket.BatchCollection'), {
            method: 'POST',
            json: {data},
        });
    };

    const assignPractices = async (ev, rows) => {
        const {action, practice} = await practiceSelectDialog();
        if (action === 'select') {
            const data = practice ? {type: 'Practice', id: practice.id} : null;
            const promises = fp.map(
                row =>
                    api.fetchJsonApi(
                        row.data.relationships.practice.links.self,
                        {
                            method: 'PATCH',
                            json: {data},
                        },
                    ),
                rows,
            );
            await Promise.all(promises);
            await refresh();
        }
    };

    const assignFacility = async (ev, rows) => {
        const {action, facility} = await facilitySelectDialog();
        if (action === 'select') {
            const data = facility ? {type: 'Facility', id: facility.id} : null;
            const promises = fp.map(
                row =>
                    api.fetchJsonApi(
                        row.data.relationships.facility.links.self,
                        {
                            method: 'PATCH',
                            json: {data},
                        },
                    ),
                rows,
            );
            await Promise.all(promises);
            await refresh();
        }
    };

    const mapPatients = async (ev, rows) => {
        const {action} = await duplicatePatientDialog(fp.map('data', rows));
        if (action === 'selected') {
            await refresh();
        }
    };

    return (
        <>
            <dt2.DetailPanel
                key='access'
                icon='security'
                tooltip='Access Control'
                render={row => <PatientAccess value={row.data} />}
            />
            {showDetails ? (
                <>
                    <dt2.DetailPanel
                        key='email-detail'
                        icon='email'
                        tooltip='Messaging'
                        render={row => <AdminMessageTable patient={row.data} />}
                    />
                    <dt2.Action
                        key='email-action'
                        name='message'
                        icon='email'
                        tooltip='Send message to selected patients'
                        onClick={handleCompose}
                    />
                </>
            ) : null}

            <>
                <dt2.Action
                    key='upload-action'
                    name='upload'
                    icon='upload'
                    tooltip='Send a file to selected patients'
                    onClick={handleFileUpload}
                />
            </>

            {allowAssignPractice ? (
                <>
                    <dt2.Action
                        key='assign-practice'
                        onClick={assignPractices}
                        tooltip='Assign practice'
                        icon='manage_accounts'
                    />
                </>
            ) : null}
            {allowAssignFacility ? (
                <>
                    <dt2.Action
                        key='assign-fac'
                        onClick={assignFacility}
                        tooltip='Assign facility'
                        icon='apartment'
                    />
                </>
            ) : null}
            {allowBulkTicketCreate ? (
                <dt2.Action
                    key='create-ticket'
                    icon='post_add'
                    tooltip='Create Tickets'
                    onClick={createTickets}
                />
            ) : null}
            {allowMapping ? (
                <>
                    <dt2.DetailPanel
                        key='map-patients-detail'
                        tooltip='Mapped Patients'
                        render={row => (
                            <PatientMappingDetail
                                patient={row.data}
                                handleRefresh={refresh}
                            />
                        )}
                    >
                        {row => ({
                            openIcon: fp.get(
                                'data.relationships.primary_patient.data.id',
                                row,
                            )
                                ? 'people'
                                : 'person',
                            icon: fp.get(
                                'data.relationships.primary_patient.data.id',
                                row,
                            )
                                ? 'people_outlined'
                                : 'person_outlined',
                        })}
                    </dt2.DetailPanel>
                    <dt2.Action
                        key='map-patients-action'
                        onClick={mapPatients}
                        tooltip='Map patients'
                        icon='merge'
                    />
                </>
            ) : null}
        </>
    );
}
