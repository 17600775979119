import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

const DEFAULT_OPTIONS = {
    title: 'Confirm',
    yesLabel: 'Confirm change',
    noLabel: 'Cancel change',
    prompt: 'Please confirm your changes.',
};

export default function ConfirmationDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    const handleYes = () => {
        resolve(true);
        setResolve(null);
    };

    const handleNo = () => {
        resolve(false);
        setResolve(null);
    };

    dialogRef.current = (options = {}) => {
        return new Promise((resolve, reject) => {
            setOptions(fp.merge(DEFAULT_OPTIONS, options));
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleNo}>
            <DialogTitle>{options.title}</DialogTitle>
            <DialogContent>
                <p>{options.prompt}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNo}>{options.noLabel}</Button>
                <Button
                    onClick={handleYes}
                    type='submit'
                    variant='contained'
                    color='primary'
                >
                    {options.yesLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
