import React from 'react';

import {RadiologyAvatar} from 'ccm/art/MedicalIcons';
import InfoCard from './InfoCard';

export default function DiagnosticReportCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={RadiologyAvatar}
            title='Labs & Radiology'
            {...props}
        ></InfoCard>
    );
}
