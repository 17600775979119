import React from 'react';

import {NotepadAvatar} from 'ccm/art/MedicalIcons';
import InfoCard from './InfoCard';

export default function ContactsCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={NotepadAvatar}
            title='Assessments'
            {...props}
        ></InfoCard>
    );
}
