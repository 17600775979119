import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';
import {parseISO} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';

import {ducks} from '@arborian/narrf';

export const NAME = 'cf';

export const selectFacilityNames = createSelector(
    ducks.jsonapi.selectObject('PCCFacility'),
    fp.pipe([
        fp.toPairs,
        fp.map(([key, value]) => [
            fp.get('attributes.pcc.facId', value),
            fp.get('attributes.pcc.facilityName', value),
        ]),
        fp.fromPairs,
    ]),
);

export const selectLatestObservations = patientId =>
    createSelector(
        ducks.jsonapi.selectObject('Observation'),
        fp.pipe([
            fp.filter(
                o => fp.get('relationships.patient.data.id', o) === patientId,
            ),
            fp.groupBy('attributes.code.text'),
            fp.toPairs,
            fp.map(([otype, obss]) => [
                otype,
                fp.maxBy('attributes.effectiveDateTime', obss),
            ]),
            fp.fromPairs,
        ]),
    );

export const selectHistoricalObservations = (
    patientId,
    dateRange,
    timeZone,
    codes,
) =>
    createSelector(
        ducks.jsonapi.selectObject('Observation'),
        fp.pipe([
            fp.values,
            fp.filter(
                o => fp.get('relationships.patient.data.id', o) === patientId,
            ),
            fp.filter(o =>
                fp.includes(fp.get('attributes.code.text', o), codes),
            ),
            fp.filter(o => {
                const startDate = zonedTimeToUtc(dateRange.startDate, timeZone);
                const endDate = zonedTimeToUtc(dateRange.endDate, timeZone);
                const recordedDate = parseISO(
                    fp.get('attributes.effectiveDateTime', o),
                );
                return startDate <= recordedDate && recordedDate <= endDate;
            }),
            fp.sortBy('attributes.effectiveDateTime'),
            fp.groupBy('attributes.code.text'),
        ]),
    );

export const selectPatientInvitations = patientId =>
    createSelector(
        ducks.jsonapi.selectObject('Invitation'),
        fp.pipe([
            fp.values,
            fp.filter(
                inv => fp.get('attributes.patient_id', inv) === patientId,
            ),
        ]),
    );

const initialState = {};

export function reducer(state = initialState, action) {
    const {type} = action;
    switch (type) {
        default:
            return state;
    }
}

export const reducers = {cf: reducer};

export default reducer;
