import React, {useEffect} from 'react';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

import {useListField} from 'ccm/dataSource';

import TableView from './TableView';
import {useInternalThreads} from './hooks';
import NewThreadDialog from './NewThreadDialog';

import {useGlobals} from 'ccm/components/Globals';
import {useDialog} from 'ccm/components/dialogs';
import ConfirmationDialog from 'ccm/components/dialogs/ConfirmationDialog';

const OPTIONS = {reversed: true};
const handleChange = (...args) => console.log(args);

export default function InternalMessagingInbox({
    user,
    isPracticeUser,
    ...props
}) {
    const api = useApi();
    const threads = useInternalThreads({user});
    const confirmLockThread = useDialog(ConfirmationDialog);
    const createThreadDialog = useDialog(NewThreadDialog);
    const globals = useGlobals();
    const ds = {
        ...useListField(threads.items, handleChange, OPTIONS),
        state: threads.state,
        fetch: (...args) => {
            return threads.fetch(...args);
        },
    };

    const createThread = async () => {
        let data = await createThreadDialog();
        if (!data) return;
        await api.fetchJsonApi(
            api.url_for('internal_messaging.ThreadCollection'),
            {
                method: 'POST',
                json: {
                    data,
                },
            },
        );
        await ds.fetch();
    };

    const lockThread = async (thread, text) => {
        if (
            await confirmLockThread({
                title: 'Close Thread Confirmation - Are you sure?',
                prompt: `This will permanently close the thread and upload 
                the messages to the patient's chart. The thread cannot be reopened.`,
                yesLabel: 'Close Thread',
                noLabel: 'Cancel',
            })
        ) {
            if (text.length) {
                await addMessage(thread, text);
            }
            const href = api.url_for('internal_messaging.ThreadResource', {
                thread_id: thread.id,
            });
            const attributes = fp.set('locked', true, thread.attributes);
            await api.fetchJsonApi(href, {
                method: 'PUT',
                json: {data: {type: thread.type, attributes}},
            });
        } else {
            return;
        }
    };

    const addParticipant = async (thread, participantId) => {
        let currentParticipants = fp.get('attributes.participant_ids', thread);
        if (currentParticipants.includes(participantId)) return;
        const data = fp.set(
            'attributes.participant_ids',
            [participantId, ...currentParticipants],
            thread,
        );
        await api.fetchJsonApi(
            api.url_for('internal_messaging.ThreadResource', {
                thread_id: thread.id,
            }),
            {
                method: 'PUT',
                json: {
                    data,
                },
            },
        );
    };

    const addMessage = async (thread, text) => {
        await api.fetchJsonApi(
            api.url_for('internal_messaging.CreateMessage', {
                thread_id: thread.id,
            }),
            {
                method: 'POST',
                json: {text},
            },
        );
        await ds.fetch(false);
    };

    useEffect(() => {
        globals.fetchAll('user.Summaries');
    }, [globals]);

    return (
        <>
            {/* <Hidden smDown> */}
            <TableView
                dataSource={ds}
                handleCreateThread={createThread}
                handleLockThread={lockThread}
                handleAddParticipant={addParticipant}
                handleAddMessage={addMessage}
                isPracticeUser={isPracticeUser}
                {...props}
            />
            {/* </Hidden> */}
            {/* <Hidden mdUp>
                <ListView
                    dataSource={ds}
                    showAllMessages={showAllMessages}
                    {...props}
                />
            </Hidden> */}
        </>
    );
}
