import {useEffect, useState, useMemo, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {startOfDay, endOfDay} from 'date-fns';

import {useApi, components} from '@arborian/narrf';

const {useQueryFetchOptions, useDataActions, useReduxDataSource} = components;

const defaultFetchOptions = {
    sort: {field: 'attributes.updated', direction: 'desc'},
    page: {number: 0, size: 5},
};

export default function useEventsDataSource() {
    const api = useApi();
    const history = useHistory();
    const [fetchOptions, setFetchOptions] = useQueryFetchOptions(
        history,
        'tkt.',
        defaultFetchOptions,
    );
    const [reportFilter, setReportFilter] = useState({
        begin: new Date(),
        end: new Date(),
        facility: [],
        ticket_type: [],
    });

    const href = useMemo(() => {
        if (api) return api.url_for('report.Ticket');
    }, [api]);

    const jsonApiFilter = useMemo(() => {
        let flt = {
            'data.updated': JSON.stringify({
                $gte: {$date: startOfDay(reportFilter.begin).getTime()},
                $lte: {$date: endOfDay(reportFilter.end).getTime()},
            }),
        };
        if (reportFilter.facility) {
            flt['data.facility_id'] = JSON.stringify({
                $in: fp.map(f => ({$oid: f.id}), reportFilter.facility),
            });
        }
        if (reportFilter.ticket_type) {
            flt['data.ticket_type'] = JSON.stringify({
                $in: reportFilter.ticket_type,
            });
        }
        console.log('Set filter', {reportFilter, flt});
        return flt;
    }, [reportFilter]);

    const fetchItems = useCallback(
        async fetchOptions => {
            if (!href || !fetchOptions)
                return {
                    rows: [],
                };
            const result = await api.fetchDataTable(href, fetchOptions, {
                filter: jsonApiFilter,
            });
            return result;
        },
        [api, jsonApiFilter, href],
    );

    const downloadFile = useCallback(async () => {
        const resp = await api.fetchJsonApi(href + '.csv', {
            method: 'POST',
            filter: jsonApiFilter,
        });
        const reportHref = fp.get(
            'data.relationships.content.links.related',
            resp,
        );
        if (reportHref) {
            window.location = reportHref;
        } else {
            console.log('Error finding report', resp);
        }
    }, [api, href, jsonApiFilter]);

    const ds = useReduxDataSource({data: fetchItems, fetchOptions});
    const actions = useDataActions(fetchOptions, setFetchOptions);
    useEffect(fetchItems, [fetchItems]);

    return {
        ...ds,
        ...actions,
        downloadFile,
        reportFilter,
        setReportFilter,
    };
}
