import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';
import {useRelated} from 'ccm/dataSource';
import {useAllFacilitiesLookup} from 'ccm/dataSource/allResources';
import FacilitySelectDialog from 'ccm/components/dialogs/FacilitySelectDialog';
import LinkSelect from './LinkSelect';

const {dt2} = components;

const PFFAC_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.data.name', direction: 'asc'},
    page: {number: 0, size: 20},
};

export default function PFFacilityMapping({ehr, config}) {
    const ds = useRelated(config, 'facilities', {
        prefix: 'pffac.',
        initialFetchOptions: PFFAC_DEFAULT_FETCH_OPTIONS,
    });
    const lookupFacility = useAllFacilitiesLookup();
    const refresh = ev => ds.fetch();
    const facilitySelectDialog = useDialog(FacilitySelectDialog);
    const getSelection = async () => {
        const {action, facility} = await facilitySelectDialog();
        return {action, spData: facility};
    };
    const getLabel = facId => fp.get(facId, lookupFacility);
    return (
        <div>
            <dt2.DataTable options={{filtering: true}} dataSource={ds}>
                <dt2.Column title='PF Name' field='attributes.data.name' />
                <dt2.Column
                    id='fac'
                    title='SP Facility'
                    filtering={false}
                    lookup={lookupFacility}
                    render={row => (
                        <LinkSelect
                            pfData={row.data}
                            linkType='facility'
                            getSelection={getSelection}
                            getLabel={getLabel}
                        />
                    )}
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </dt2.DataTable>
        </div>
    );
}
