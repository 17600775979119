import React from 'react';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';
import {AssignmentLate} from '@material-ui/icons';

export default function Reopen({ticket, actions}) {
    const reopenTicket = async () => {
        await actions.reopen(ticket);
        actions.refresh(ticket);
        return;
    };
    if (!fp.includes('Ticket.REOPEN', fp.get('meta.allowed_actions', ticket)))
        return null;
    return (
        <Button
            variant='contained'
            startIcon={<AssignmentLate />}
            onClick={() => reopenTicket()}
        >
            Reopen Ticket
        </Button>
    );
}
