import React, {useEffect} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Chip} from '@material-ui/core';

import {ducks, useApi} from '@arborian/narrf';
import {useActions} from 'ccm/lib/hooks';

const selectSuggestions = patient =>
    createSelector(ducks.jsonapi.selectObject('Suggestion'), suggestions => {
        return fp.filter(
            s => s.relationships.patient.data.id === patient.id,
            suggestions,
        );
    });

function Suggestion({patient, value, onInvited}) {
    const actions = useActions();
    const handleClick = async ev => {
        await actions.inviteUser(patient, {
            initialValues: {
                email: value.attributes.email,
            },
        });
        onInvited && onInvited();
    };
    return <Chip label={value.attributes.email} onClick={handleClick} />;
}

export default function PatientInvitationSuggestions({patient, onInvited}) {
    const api = useApi();
    const suggestions = useSelector(selectSuggestions(patient));
    const hasPermission = fp.includes(
        'Common.ADMIN',
        fp.get('meta.allowed_actions', patient),
    );
    useEffect(() => {
        if (hasPermission) {
            api.fetchAllJsonApi(
                fp.get('relationships.suggestions.links.related', patient),
            );
        }
    }, [patient, hasPermission, api]);

    if (fp.isEmpty(suggestions)) return null;
    return (
        <div>
            You may want to invite the following user(s):
            {fp.map(
                sugg => (
                    <Suggestion
                        key={sugg.id}
                        patient={patient}
                        value={sugg}
                        onInvited={onInvited}
                    />
                ),
                suggestions,
            )}
        </div>
    );
}
