import React, {useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';

import {Paper, Button, makeStyles} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import BasePatientPage from 'ccm/components/BasePatientPage';
import PatientPreferencesForm from 'ccm/components/forms/PatientPreferencesForm';

const useStyles = makeStyles(theme => ({
    hform: {
        display: 'flex',
        justifyItems: 'center',
    },
    field: {
        margin: theme.spacing(0, 1),
    },
    paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
    },
}));

export default function PatientPreferencesPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId} = useParams();
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );
    const preferences = fp.get('attributes.preferences', patient);
    useEffect(() => {
        if (!patient) {
            api.fetchJsonApi(
                api.url_for('patient.Patient', {
                    patient_id: patientId,
                }),
            );
        }
    }, [patientId, patient, api]);
    const readOnly = !fp.pipe(
        fp.get('meta.allowed_actions'),
        fp.includes('Common.WRITE'),
    )(patient);
    const handleSubmit = async values => {
        const newPatient = fp.set('attributes.preferences', values, patient);
        await api.fetchJsonApi(fp.get('links.self', newPatient), {
            method: 'PUT',
            json: {data: newPatient},
        });
    };
    return (
        <BasePatientPage title='Preferences' patient={patient}>
            <Paper className={classes.paper}>
                <h4>Email preferences</h4>
                <p>
                    Here, you can control which email address receives patient
                    satisfaction surveys. You can also opt out of receiving
                    these surveys altogether.
                </p>
                <Button
                    component={Link}
                    to={url_for('patient', {patientId: patientId})}
                    startIcon={<ArrowBack />}
                >
                    Return to Dashboard
                </Button>
                <PatientPreferencesForm
                    preferences={preferences}
                    readOnly={readOnly}
                    onSubmit={handleSubmit}
                />
            </Paper>
        </BasePatientPage>
    );
}
