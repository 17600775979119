import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {ducks} from '@arborian/narrf';

export const selectParticipantsForPatient = (practiceId, facilityId) => {
    const practice_group = `practice:${practiceId}`;
    const facility_group = `facility:${facilityId}`;
    return createSelector(
        ducks.jsonapi.selectObject('UserSummary'),
        fp.pipe([
            fp.filter(user => {
                return fp.some(gid => {
                    const [group_type] = gid.split('/');
                    return (
                        group_type === practice_group ||
                        group_type === facility_group
                    );
                }, user.attributes.group_ids);
            }),
            fp.map(user => [user.id, user]),
            fp.fromPairs,
        ]),
    );
};

export default function ThreadParticipantsSelectField({
    patient,
    label,
    fullWidth,
    field,
    form,
    ...props
}) {
    const practiceId = fp.get('relationships.practice.data.id', patient);
    const facilityId = fp.get('relationships.facility.data.id', patient);

    // Display validation errors
    const {name} = field;
    const {touched, values, errors} = form;
    const fieldTouched = fp.get(name, touched);
    const fieldError = fp.get(name, errors);

    const selectAvailableParticipants = useMemo(
        () => selectParticipantsForPatient(practiceId, facilityId),
        [practiceId, facilityId],
    );

    const participants = useSelector(selectAvailableParticipants);

    const renderInput = props => (
        <TextField
            label={label}
            fullWidth={fullWidth}
            error={fieldTouched && !!fieldError}
            helperText={fieldError}
            {...props}
        />
    );

    const onChange = (ev, value, reason) => {
        form.setFieldValue(field.name, value);
    };

    let allProps = {
        ...field,
        ...props,
        renderInput,
        onChange,
    };
    allProps.value = allProps.value || '';
    const participantIds = fp.map('id', participants);
    return (
        <Autocomplete
            multiple
            disabled={!fp.get('patient', values)}
            options={participantIds || []}
            getOptionLabel={o => {
                return participants[o].attributes.name;
            }}
            {...allProps}
        />
    );
}
