import React from 'react';
import fp from 'lodash/fp';

import {useSnackbar} from 'notistack';

import {components, useApi} from '@arborian/narrf';

import {usePracticeUpcomingVisits} from 'ccm/dataSource';

import {FacilityColumn, ProviderColumn} from 'ccm/components/columns';

import {useDialog} from 'ccm/components/dialogs';
import {VisitsTable} from 'ccm/components/VisitsTable';
import MessageCompositionDialog from 'ccm/components/dialogs/MessageCompositionDialog';
import {VisitNotePrepDialog} from 'ccm/components/practiceFusion/VisitNotePrep';

import {usePracticeEhr} from '../hooks';

const {dt2} = components;

const VISIT_OPTIONS = {
    include: ['patient', 'secondary_patients', 'facility'],
};

function PatientColumns() {
    return (
        <>
            <dt2.Column
                id='col-pt-first'
                title='Patient First'
                field='attributes.patient_first'
            />
            <dt2.Column
                id='col-pt-last'
                title='Patient Last'
                field='attributes.patient_last'
            />
            <dt2.Column
                id='col-pt-dob'
                title='Patient DOB'
                field='attributes.patient_dob'
                type='date'
            />
            <dt2.Column
                id='col-pt-room'
                title='Patient Room'
                field='attributes.patient_room'
            />
        </>
    );
}

function useHandlers({practice, ...props}) {
    const api = useApi();
    const ds = usePracticeUpcomingVisits(practice, {options: VISIT_OPTIONS});
    const messageDialog = useDialog(MessageCompositionDialog);
    // const notePrepDialog = useDialog(NotePrepDialog);
    const notePrepDialog = useDialog(VisitNotePrepDialog);
    const {enqueueSnackbar} = useSnackbar();
    const ehr = usePracticeEhr(practice);

    const getPatientMsgLinks = visits => {
        let patientMsgLinks = fp.map(
            'relationships.patient_messages.links.related',
            visits,
        );
        // De-duplicate in case patients have multiple visits.
        return fp.uniq(patientMsgLinks);
    };

    const handleDelete = async (ev, rows) => {
        const promises = fp.map(
            row =>
                api.fetch(fp.get('data.links.self', row), {method: 'DELETE'}),
            rows,
        );
        await Promise.all(promises);
        ds.fetch();
    };

    const handleCompose = async (ev, rows) => {
        console.log({rows, ds});
        const msg = await messageDialog({
            count: rows.length,
            total: ds.allItemsSelected ? ds.count : rows.length,
        });
        if (!msg) return;

        const {includeAll, ...attributes} = msg;
        console.log({attributes});
        const payload = {data: {type: 'Message', attributes}};
        let patientMsgLinks = [];

        if (includeAll) {
            // Allow admins to send message to all patients with upcoming visits
            const fetchOptions = fp.pipe([
                api.jsonApiFromDataTableOptions,
                fp.omit(['page', 'sort']),
            ])(props.dsRef.current.fetchOptions);
            let allVisits = await api.fetchAllJsonApi(
                api.url_for('visit.VisitCollection'),
                fetchOptions,
            );
            patientMsgLinks = getPatientMsgLinks(allVisits);
        } else {
            patientMsgLinks = getPatientMsgLinks(fp.map('data', rows));
        }

        await Promise.all(
            fp.map(url => {
                return api.fetchJson(url, {
                    method: 'POST',
                    json: payload,
                });
            }, patientMsgLinks),
        ).then(result => {
            enqueueSnackbar(`${result.length} message(s) sent`, {
                persist: false,
                variant: 'success',
            });
        });
    };

    const handleNotePrep = async (ev, rows) => {
        const visits = fp.map('data', rows);
        console.log('NotePrep for', {practice, ehr, visits});
        await notePrepDialog({practice, ehr, visits});
    };

    return {ds, handleDelete, handleCompose, handleNotePrep};
}

export default function PracticeUpcomingVisitsTable({
    practice,
    children,
    ...props
}) {
    const {ds, handleDelete, handleCompose, handleNotePrep} = useHandlers({
        practice,
        ...props,
    });
    const canNotePrep = fp.includes(
        'Practice.NOTE_PREP',
        practice.meta.allowed_actions,
    );
    const canSchedule = fp.includes(
        'Practice.SCHEDULE',
        practice.meta.allowed_actions,
    );
    return (
        <>
            <VisitsTable
                options={{
                    filtering: true,
                    search: true,
                    selection: true,
                }}
                dataSource={ds}
                tableRef={props.dsRef}
                {...props}
            >
                <FacilityColumn />
                <ProviderColumn />
                <PatientColumns />
                {canSchedule && (
                    <dt2.Action
                        key='delete-action'
                        name='delete'
                        icon='delete'
                        tooltip='Delete visit(s)'
                        onClick={handleDelete}
                    />
                )}
                <dt2.Action
                    key='email-action'
                    name='message'
                    icon='email'
                    tooltip='Send message to selected patients'
                    onClick={handleCompose}
                />
                {canNotePrep && (
                    <dt2.Action
                        key='noteprep-action'
                        name='noteprep'
                        icon='note_add'
                        tooltip='Prepare notes for selected patients'
                        onClick={handleNotePrep}
                    />
                )}
                {children}
            </VisitsTable>
        </>
    );
}
