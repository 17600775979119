export default class Tour {
    constructor(options) {
        const {
            id, // unique ID for this tour
            label, // label displayed in help menu
            location_context = /.*/, // regex for routes where this tour is valid
            group_context = /.*/, // regex for routes where this tour is valid
            steps, // actual steps for the tour
        } = options;
        this.id = id;
        this.label = label;
        this.location_context = location_context;
        this.group_context = group_context;
        this.steps = steps;
    }
}
