import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Checkbox,
    Toolbar,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
} from '@material-ui/core';

import {ducks, components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import PatientAvatar from 'ccm/components/PatientAvatar';
import {ShortDateTime} from 'ccm/components/DateTime';

import MessageDetails from './MessageDetails';
import {Archive, Unarchive, MarkUnread} from './ActionButtons';

const {useDataSourceContext} = components;

const useStyles = makeStyles(theme => ({
    unread: {
        '& .MuiListItemText-primary': {
            fontWeight: 900,
        },
        '& .MuiListItemText-secondary': {
            fontWeight: 900,
        },
    },
    alignRight: {
        textAlign: 'right',
    },
    flex: {
        flex: 1,
    },
}));

function MessageToolbar({item, includePatient, onClose}) {
    const ds = useDataSourceContext();
    const classes = useStyles();
    const rel = useSelector(ducks.jsonapi.selectRelated(item.data));
    const href = url_for('patient', {patientId: rel.patient.id});
    const handleMarkUnread = async () => {
        ds.patch({read: false}, item);
        onClose();
    };

    const handleArchive = async () => {
        ds.patch({read: true, deleted: true}, item);
        onClose();
    };

    const handleUnarchive = async () => {
        ds.patch({deleted: false}, item);
        onClose();
    };
    return (
        <Toolbar variant='dense'>
            {includePatient ? (
                <>
                    <PatientAvatar patient={rel.patient} />
                    <h6>
                        re:{' '}
                        <Link to={href}>
                            {fp.get('patient.attributes.name.formatted', rel)}
                        </Link>
                    </h6>
                </>
            ) : null}
            <div className={classes.flex} />
            {item.data.attributes.deleted ? (
                <Unarchive onClick={handleUnarchive} />
            ) : (
                <>
                    <MarkUnread onClick={handleMarkUnread} />
                    <Archive onClick={handleArchive} />
                </>
            )}
        </Toolbar>
    );
}

export default function InboxItem({
    item,
    includePatient,
    showAllMessages = false,
}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(item.data));
    const data = useDataSourceContext();
    const classes = useStyles();
    const primary = fp.get('attributes.subject', item.data);
    let secondary_parts = [item.data.attributes.type];
    if (includePatient) {
        secondary_parts.push(
            `re: ${fp.get('patient.attributes.name.formatted', rel)}`,
        );
    }
    secondary_parts.push(`from ${item.data.attributes.from_}`);
    const secondary = secondary_parts.join(' ');
    const readStatus = item.data.attributes.read;
    const [showDetail, setShowDetail] = useState(false);
    let iconClass = [classes.icon];
    if (showDetail) iconClass.push(classes.openIcon);

    const handleChangeSelected = ev => {
        data.selectItem(item, !item.state.selected);
    };
    const messageToolbar = (
        <MessageToolbar
            item={item}
            includePatient={includePatient}
            onClose={() => setShowDetail(false)}
        />
    );

    return (
        <>
            <ListItem
                dense
                button
                className={readStatus ? null : classes.unread}
                onClick={ev => setShowDetail(!showDetail)}
            >
                {!showAllMessages && (
                    <ListItemIcon>
                        <Checkbox
                            edge='start'
                            checked={item.state.selected}
                            onClick={ev => ev.stopPropagation()}
                            onChange={handleChangeSelected}
                        />
                    </ListItemIcon>
                )}
                <ListItemText primary={primary} secondary={secondary} />
                <div className={classes.flex} />
                <ListItemText
                    primary={
                        <ShortDateTime
                            value={fp.get('data.attributes.timestamp', item)}
                        />
                    }
                    className={classes.alignRight}
                />
            </ListItem>
            {showDetail ? (
                <MessageDetails
                    inboxMessageId={item.id}
                    showAllMessages={showAllMessages}
                    messageToolbar={messageToolbar}
                />
            ) : null}
        </>
    );
}
