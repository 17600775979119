import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';

const rowActions = createSelector(
    fp.values,
    fp.filter(a => !a.free),
);
const freeActions = createSelector(
    fp.values,
    fp.filter(a => a.free),
);
const selectedRows = createSelector(
    fp.getOr([], 'rows'),
    fp.filter(meta => meta.selected),
);

export const debounce = f => {
    let cur = null;
    return async (...args) => {
        if (cur) {
            const result = await cur;
            return result;
        } else {
            cur = f(...args);
            try {
                return await cur;
            } finally {
                cur = null;
            }
        }
    };
};

export default class TableManager {
    constructor({
        data,
        options,
        editable = {},
        fetchOptions,
        setFetchOptions,
        columns,
        setColumns,
        actions,
        setActions,
        detailPanels,
        setDetailPanels,
        state,
        setState,
        editState,
        setEditState,
    }) {
        this.data = data;
        this.options = options;
        this.editable = editable;
        this.fetchOptions = fp.merge(
            {
                filter: {},
                sort: null,
                page: {number: 0, size: options.pageSizeOptions[0]},
                search: null,
            },
            fetchOptions,
        );
        this.setFetchOptions = setFetchOptions;
        this.columns = columns;
        this.setColumns = setColumns;
        this.actions = actions;
        this.setActions = setActions;
        this.detailPanels = detailPanels;
        this.setDetailPanels = setDetailPanels;
        this.state = state;
        this.setState = setState;
        this.editState = editState;
        this.setEditState = setEditState;
    }

    fetch = debounce(async () => {
        this.setState(fp.assign(this.state, {state: 'busy', rows: []}));
        try {
            const resp = await this.data(this.fetchOptions, this);
            let rows = fp.map(
                row => ({id: row.id, data: row, selected: false, detail: null}),
                resp.rows,
            );
            this.setState(
                fp.assign(this.state, {
                    rows,
                    state: 'ready',
                    count: resp.count,
                }),
            );
        } catch (error) {
            console.error(error);
            this.setState(fp.assign(this.state, {state: 'error', error}));
        }
    });

    setColumn = (id, props) => {
        this.setColumns(fp.set([id], props));
    };

    setAction = (id, props) => {
        this.setActions(fp.set([id], props));
    };

    setDetailPanel = (id, props) => {
        this.setDetailPanels(fp.set([id], props));
    };

    beginAddRow = () => {
        if (this.editState.op) return;
        this.setEditState({op: 'add', row: null});
    };

    beginEditRow = id => {
        if (this.editState.op) return;
        this.setEditState({op: 'edit', row: id});
    };

    beginDeleteRow = id => {
        if (this.editState.op) return;
        this.setEditState({op: 'delete', row: id});
    };

    get freeActions() {
        return freeActions(this.actions);
    }

    get rowActions() {
        return rowActions(this.actions);
    }

    get selectedRows() {
        return selectedRows(this.state);
    }

    get hasActionsColumn() {
        return (
            this.options.selection ||
            this.rowActions.length > 0 ||
            !!this.editable.onRowUpdate ||
            !!this.editable.onRowDelete
        );
    }

    get hasDetailPanels() {
        return !fp.isEmpty(this.detailPanels);
    }

    someRowsSelected = () => !fp.isEmpty(this.selectedRows);
    allRowsSelected = () =>
        this.someRowsSelected() &&
        this.selectedRows.length === this.state.rows.length;

    onSelectRow = (rowId, selected) => {
        const rows = fp.map(row => {
            if (row.id === rowId) {
                return fp.set('selected', selected, row);
            } else {
                return row;
            }
        }, this.state.rows);
        this.setState({...this.state, rows});
    };
    onSelectAllRows = selected => {
        const rows = fp.map(fp.set('selected', selected), this.state.rows);
        this.setState({...this.state, rows});
    };

    onSelectDetail = (rowId, detail) => {
        const rows = fp.map(row => {
            if (row.id === rowId) {
                if (row.detail === detail) {
                    return fp.unset('detail', row);
                } else {
                    return fp.set('detail', detail, row);
                }
            } else {
                return row;
            }
        }, this.state.rows);
        this.setState({...this.state, rows});
    };

    onChangeSearch = search => {
        const fetchOptions = fp.set('page.number', 0, this.fetchOptions);
        this.setFetchOptions(fp.set('search', search, fetchOptions));
    };

    onChangePage = page => {
        this.setFetchOptions(fp.set('page.number', page, this.fetchOptions));
    };

    onChangePageSize = pageSize => {
        this.setFetchOptions(
            fp.set('page', {number: 0, size: pageSize}, this.fetchOptions),
        );
    };

    onChangeSort = field => {
        const sort = this.fetchOptions.sort;
        // Go to page 1 when changing sort
        const fetchOptions = fp.set('page.number', 0, this.fetchOptions);
        if (sort && field === sort.field) {
            if (sort.direction === 'asc') {
                this.setFetchOptions(
                    fp.set('sort.direction', 'desc', fetchOptions),
                );
            } else {
                this.setFetchOptions(fp.set('sort', null, fetchOptions));
            }
        } else {
            this.setFetchOptions(
                fp.set('sort', {field, direction: 'asc'}, fetchOptions),
            );
        }
    };

    onChangeFilter = (field, value) => {
        // Go to page 1 when changing filter
        const fetchOptions = fp.set('page.number', 0, this.fetchOptions);

        let isEmpty = false;
        if (value === '' || value === null) {
            isEmpty = true;
        } else if (fp.isArray(value) && fp.isEmpty(value)) {
            isEmpty = true;
        } else if (fp.isObject(value) && fp.isEmpty(value)) {
            isEmpty = true;
        } else if (value === undefined) {
            isEmpty = true;
        }

        if (isEmpty) {
            const newFilt = fp.unset(['filter', field], fetchOptions);
            this.setFetchOptions(newFilt);
        } else {
            this.setFetchOptions(
                fp.set(['filter', field], value, fetchOptions),
            );
        }
    };
}
