import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {useApi, ducks} from '@arborian/narrf';

import BasePage from 'ccm/components/BasePage';

import Facility from 'ccm/components/Facility';

export default function FacilityPage() {
    const api = useApi();
    const {facilityId} = useParams();
    const facility = useSelector(
        ducks.jsonapi.selectObject(['Facility', facilityId]),
    );
    useEffect(() => {
        api.fetchJsonApi(
            api.url_for('facility.Facility', {facility_id: facilityId}),
        );
    }, [api, facilityId]);

    return (
        <BasePage wide title='Facility'>
            <Facility facility={facility} />
        </BasePage>
    );
}
