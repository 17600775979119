import React from 'react';

import {components} from '@arborian/narrf';
import {useResources} from 'ccm/dataSource';
import CarePlanTemplateTable from 'ccm/components/CarePlanTemplateTable';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

const {dt2} = components;

const SHARED_TEMPLATE_FETCH_OPTIONS = {
    filter: {
        'attributes.practice_id': null,
    },
    page: {number: 0, size: 20},
};

export default function CarePlanImporter({
    title,
    practice,
    practiceDataSource,
}) {
    const link = 'careplan.TemplateCollection';
    const carePlanTemplates = useResources({
        link,
        prefix: 'cptpl.',
        initialFetchOptions: SHARED_TEMPLATE_FETCH_OPTIONS,
    });

    const handleImport = async (ev, item) => {
        await practiceDataSource.handleAdd(item);
        return true;
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h6>{title}</h6>
            </AccordionSummary>
            <AccordionDetails>
                <CarePlanTemplateTable
                    title='Available Care Plans'
                    dataSource={carePlanTemplates}
                >
                    <dt2.Action
                        name='import'
                        icon='get_app'
                        tooltip="Import this into your practice's template library"
                        onClick={handleImport}
                    />
                </CarePlanTemplateTable>
            </AccordionDetails>
        </Accordion>
    );
}
