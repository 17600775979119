import React from 'react';
import fp from 'lodash/fp';

import {Tabs, Tab} from '@material-ui/core';

import TelecomChips from 'ccm/components/TelecomChips';
import AddressBlock from 'ccm/components/AddressBlock';

import useStyles from './styles';
import PrimaryPracticeChip from './PrimaryPracticeChip';
import FacilityStats from './FacilityStats';
import FacilitySchedule from './FacilitySchedule';

export default function FacilityInfo({facility}) {
    const classes = useStyles();
    return (
        <div className={classes.view}>
            <div className={classes.hbox}>
                <div className={classes.vbox}>
                    <h3>{fp.get('attributes.name', facility)}</h3>
                    <h4>{fp.get('attributes.org_name', facility)}</h4>
                    <AddressBlock
                        value={fp.get('attributes.address', facility)}
                    />
                    <div className={classes.chipList}>
                        <TelecomChips
                            value={fp.get('attributes.telecom', facility)}
                        />
                        <PrimaryPracticeChip
                            value={fp.get(
                                'attributes.primaryPracticeId',
                                facility,
                            )}
                        />
                    </div>
                </div>
                <FacilityStats facility={facility} />
            </div>
            <Tabs value={0}>
                <Tab label='Upcoming Visits' />
            </Tabs>
            <FacilitySchedule facility={facility} />
        </div>
    );
}
