import React, {useMemo} from 'react';
import fp from 'lodash/fp';

import {
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';

import useStyles from './styles';

export default function PreviousNote({
    form,
    field,
    label,
    transcriptTypeId,
    transcriptTypes,
}) {
    console.log('PreviousNote props', {form, field, label, transcriptTypeId});
    const classes = useStyles();
    const transcriptTypeIndex = useMemo(
        () => fp.pipe([fp.map(t => [t.id, t]), fp.fromPairs])(transcriptTypes),
        [transcriptTypes],
    );
    if (!transcriptTypeId) return null;

    const handleChange = async ev => {
        const newValue = fp.set(ev.target.name, ev.target.checked, field.value);
        form.setFieldValue(field.name, newValue);
        console.log('Try to set field value', {value: field.value, newValue});
    };
    const transcriptType = transcriptTypeIndex[transcriptTypeId];
    const sections = fp.get('attributes.data.isSoapNote', transcriptType)
        ? [
              ['subjectiveNote', 'Subjective'],
              ['objectiveNote', 'Objective'],
              ['assessmentNote', 'Assessment'],
              ['planNote', 'Plan'],
          ]
        : [['simpleNote', 'Simple']];

    console.log('PrevNote', {transcriptTypeId, transcriptType, sections});

    return (
        <FormControl className={classes.checkboxList} component='fieldset'>
            <FormLabel component='legend'>
                Bring forward previous note?
            </FormLabel>
            <FormGroup>
                {fp.map(
                    ([name, label]) => (
                        <FormControlLabel
                            key={name}
                            control={
                                <Checkbox
                                    name={name}
                                    checked={fp.getOr(false, name, field.value)}
                                    onChange={handleChange}
                                />
                            }
                            label={label}
                        />
                    ),
                    sections,
                )}
            </FormGroup>
        </FormControl>
    );
}
