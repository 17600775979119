import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField as FormikTextField} from 'formik-material-ui';

const defaultOptions = {
    title: 'New Ticket Type',
    submitLabel: 'Create',
};

export default function TicketTypeCreateDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [options, setOptions] = useState(defaultOptions);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (options = defaultOptions) => {
        return new Promise(resolve => {
            setOptions(options);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={{}}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <Field
                                component={FormikTextField}
                                label='Internal System Code'
                                name='code'
                                fullWidth
                            />
                            <Field
                                component={FormikTextField}
                                label='Label'
                                name='label'
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

// End dialog ---
