import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';

const rowActions = createSelector(
    fp.values,
    fp.filter(a => !a.free),
);
const freeActions = createSelector(
    fp.values,
    fp.filter(a => a.free),
);
const selectedRows = createSelector(
    fp.getOr([], 'rows'),
    fp.filter(meta => meta.selected),
);

export const debounce = f => {
    let cur = null;
    return async (...args) => {
        if (cur) {
            const result = await cur;
            return result;
        } else {
            cur = f(...args);
            try {
                return await cur;
            } finally {
                cur = null;
            }
        }
    };
};

export default class TableManager {
    constructor({
        dataSource,
        options,
        editable = {},
        columns,
        setColumns,
        actions,
        setActions,
        detailPanels,
        setDetailPanels,
        editState,
        setEditState,
        id,
    }) {
        this.dataSource = dataSource;
        this.options = options;
        this.editable = editable;
        this.columns = columns;
        this.setColumns = setColumns;
        this.actions = actions;
        this.setActions = setActions;
        this.detailPanels = detailPanels;
        this.setDetailPanels = setDetailPanels;
        this.editState = editState;
        this.setEditState = setEditState;
        this.id = id;
    }

    fetch = () => {
        this.dataSource.fetch();
    };

    setColumn = (id, props) => {
        this.setColumns(fp.set([id], props));
    };

    setAction = (id, props) => {
        this.setActions(fp.set([id], props));
    };

    setDetailPanel = (id, props) => {
        this.setDetailPanels(fp.set([id], props));
    };

    beginAddRow = () => {
        if (this.editState.op) return;
        this.setEditState({op: 'add', row: null});
    };

    beginEditRow = id => {
        if (this.editState.op) return;
        this.setEditState({op: 'edit', row: id});
    };

    beginDeleteRow = id => {
        if (this.editState.op) return;
        this.setEditState({op: 'delete', row: id});
    };

    get freeActions() {
        return freeActions(this.actions);
    }

    get rowActions() {
        return rowActions(this.actions);
    }

    get hasActionsColumn() {
        return (
            this.options.selection ||
            this.rowActions.length > 0 ||
            !!this.editable.onRowUpdate ||
            !!this.editable.onRowDelete
        );
    }

    get hasDetailPanels() {
        return !fp.isEmpty(this.detailPanels);
    }

    someRowsSelected = () => this.dataSource.someItemsSelected;
    allRowsSelected = () => this.dataSource.allItemsSelected;
    get selectedRows() {
        return this.dataSource.selectedItems;
    }
    get fetchOptions() {
        return this.dataSource.fetchOptions;
    }
    get state() {
        return {
            state: this.dataSource.state,
            items: this.dataSource.items,
            count: this.dataSource.count,
        };
    }

    onSelect = (item, selected) => {
        return this.dataSource.selectItem(item, selected);
    };
    onSelectAll = selected => {
        return this.dataSource.selectAllItems(selected);
    };

    onSelectDetail = (item, detail) => {
        return this.dataSource.toggleItemDetail(item, detail);
    };
}
