import React from 'react';
import fp from 'lodash/fp';

import Chart from 'react-apexcharts';

export default function FlowsheetChart({dataSource}) {
    const {items, dates} = dataSource;
    const series = fp.pipe([
        fp.map(item => {
            const data = fp.pipe([
                fp.map(dt => {
                    const yval = Number.parseFloat(item.data[dt]);
                    return {x: dt, y: yval};
                }),
                fp.filter(pt => !fp.isNaN(pt.y)),
            ])(dates);
            return {name: item.data.code_name, data};
        }),
        fp.filter(points => !fp.isEmpty(points)),
    ])(items);
    return (
        <Chart
            series={series}
            options={{
                xaxis: {type: 'datetime'},
            }}
            type='line'
            width='100%'
            height='400'
        />
    );
}
