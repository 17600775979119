import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Formik, Form, Field} from 'formik';
import {lastDayOfMonth} from 'date-fns';
import {Button} from '@material-ui/core';
import {TextField} from 'formik-material-ui';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';

import PreviousNoteField from './PreviousNoteField';
import * as sel from './selectors';

export default function NotePrepForm({ehrConnectionId, onSubmit}) {
    const lastDateOfMonth = lastDayOfMonth(new Date());
    const initialValues = {
        dateOfServiceLocal: lastDateOfMonth,
        facility: null,
        transcriptType: null,
        eventType: null,
        provider: null,
        template: null,
        prev: {},
        carePlan: 'Please view Care Plan through SamePage MD Portal',
    };
    const transcriptTypes = useSelector(
        sel.selectTranscriptTypes(ehrConnectionId),
    );
    const facilities = useSelector(sel.selectFacilities(ehrConnectionId));
    const encounterTypes = useSelector(
        sel.selectEncounterTypes(ehrConnectionId),
    );
    const providers = useSelector(sel.selectProviders(ehrConnectionId));
    const templates = useSelector(sel.selectTemplates(ehrConnectionId));
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({values, handleSubmit, setFieldValue}) => (
                <Form>
                    <Field
                        name='dateOfServiceLocal'
                        component={KeyboardDatePicker}
                        fullWidth
                        label='Note Date'
                        format='yyyy-MM-dd'
                        InputLabelProps={{shrink: true}}
                    />
                    <Field
                        name='facility'
                        component={LinkageAutocompleteField}
                        label='Facility'
                        options={facilities}
                        getOptionLabel={fp.get('attributes.data.name')}
                    />
                    <Field
                        name='transcriptType'
                        component={LinkageAutocompleteField}
                        label='Chart Note Type'
                        options={transcriptTypes}
                        getOptionLabel={fp.get(
                            'attributes.data.transcriptTypeName',
                        )}
                    />
                    <Field
                        name='eventType'
                        component={LinkageAutocompleteField}
                        label='Encounter Type'
                        options={encounterTypes}
                        getOptionLabel={fp.get('attributes.data.displayName')}
                    />
                    <Field
                        name='provider'
                        component={LinkageAutocompleteField}
                        label='Provider'
                        options={providers}
                        getOptionLabel={fp.get('attributes.data.providerName')}
                    />
                    <Field
                        name='template'
                        component={LinkageAutocompleteField}
                        label='Template'
                        options={templates}
                        getOptionLabel={fp.get('attributes.data.name')}
                    />
                    <Field
                        name='prev'
                        component={PreviousNoteField}
                        label='Copy Previous Note Section'
                        transcriptTypeId={fp.get('transcriptType.id', values)}
                        transcriptTypes={transcriptTypes}
                    />
                    <Field
                        name='carePlan'
                        component={TextField}
                        label='Care plan'
                        fullWidth
                    />
                    <br />
                    <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        onClick={handleSubmit}
                    >
                        Create Encounter Notes
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
