import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {makeStyles, Button} from '@material-ui/core';
import {useApi, ducks} from '@arborian/narrf';
import {Formik, Form} from 'formik';
import {url_for} from 'ccm/routes';
import {fieldId} from 'ccm/lib/util';

import BasePage from 'ccm/components/BasePage';
import {FaxOptionsFormikField} from 'ccm/components/fields/FaxOptions';
import {selectCreateTicketFaxOptions} from 'ccm/lib/selectors-ccm';
import {Alert} from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    pageParent: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        textAlign: 'center',
        width: '50%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default function CommunicateFaxPage({match}) {
    const api = useApi();
    const classes = useStyles();
    const history = useHistory();

    const sessionid = match.params.sessionid;
    const sessionUrl = api.url_for('ticketsession.TicketSession', {
        session_id: sessionid,
    });
    const session = useSelector(
        ducks.jsonapi.selectObject(['TicketSession', sessionid]),
    );
    const facId = fp.get('attributes.facility_id', session);
    const faxOptions = useSelector(selectCreateTicketFaxOptions(facId));
    const [defaultVals, setDefaultVals] = useState({
        fax_number: [],
    });

    useEffect(() => {
        if (!session) {
            api.fetchJsonApi(sessionUrl);
        }
    }, [api, session, sessionUrl]);

    useEffect(() => {
        const asyncFunc = async () => {
            // Fetch facility contacts
            const contactUrl = api.url_for('facility.ContactCollection', {
                facility_id: facId,
            });
            await api.fetchJsonApi(contactUrl);

            const sessionFaxNumber = fp.get('attributes.fax_number', session);
            setDefaultVals({fax_number: sessionFaxNumber});
        };
        asyncFunc();
    }, [api, session, facId]);

    const handleSubmit = async (data, actions) => {
        const updateDoc = {
            type: 'TicketSession',
            attributes: {fax_number: data.fax_number},
        };
        await api.fetchJsonApi(session.links.self, {
            method: 'PUT',
            json: {data: updateDoc},
        });
        const nextPageUrl = url_for('formSubmit', {sessionid});
        history.push(nextPageUrl);
    };

    return (
        <BasePage>
            <div className={classes.pageParent}>
                <h4>Fax</h4>
                <p>Please select/input a fax number:</p>
                <Alert severity='info'>
                    The reply to this ticket will be sent to the given fax
                    number(s)
                </Alert>
                <Formik
                    initialValues={defaultVals}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <Form>
                            <FaxOptionsFormikField
                                name='fax_number'
                                label='Fax Number'
                                options={faxOptions || []}
                                multiple={true}
                                required={true}
                            />
                            <div className={classes.buttons}>
                                <Button
                                    id={fieldId('dyn', 'backButton')}
                                    onClick={() => history.go(-1)}
                                >
                                    Back
                                </Button>
                                <Button
                                    type='submit'
                                    id={fieldId('dyn', 'submitButton')}
                                    style={{margin: 20}}
                                    variant='contained'
                                >
                                    Next
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </BasePage>
    );
}
