import fp from 'lodash/fp';
import {
    format,
    startOfDay,
    add,
    parseISO,
    differenceInDays,
    differenceInYears,
} from 'date-fns';
import {formatInTimeZone, utcToZonedTime, toDate} from 'date-fns-tz';

export function normalizeDate(value) {
    // takes a JS Date and converts it to 9am in the browser's local timezone
    return add(startOfDay(value), {hours: 9});
}

export function formatDate(value, timeZone) {
    if (!value) return null;
    const date = value instanceof Date ? value : parseISO(value);
    const formatted = formatInTimeZone(date, timeZone, 'yyyy-MM-dd');
    return formatted;
}

export const formatDateOnly = dateTime => {
    let result = 'invalid date';
    try {
        if (typeof dateTime === 'string') {
            dateTime = parseISO(dateTime);
        }
        const dateTimeFakeJS = utcToZonedTime(dateTime, 'UTC');
        result = format(dateTimeFakeJS, 'MM/dd/yyyy');
    } catch (e) {
        // console.error('Error formatting datetime:', dateTime);
    }
    return result;
};

export const formatToday = () => format(new Date(), 'MM/dd/yyyy');

export function formatDateTime(value, timeZone) {
    if (!value) return null;
    const date = value instanceof Date ? value : parseISO(value);
    const formatted = formatInTimeZone(
        date,
        timeZone,
        'yyyy-MM-dd HH:mm (zzz)',
    );
    return formatted;
}

export function formatShortDateTime(value, timeZone) {
    if (!value) return null;
    timeZone = timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const date = value instanceof Date ? value : parseISO(value);
    const date = toDate(value);
    const now = new Date();
    const diffDays = differenceInDays(now, date);
    const diffYears = differenceInYears(now, date);
    const formatted =
        diffDays < 1
            ? formatInTimeZone(date, timeZone, 'hh:mm aa (zzz)')
            : diffYears < 1
            ? formatInTimeZone(date, timeZone, 'MMM d')
            : formatInTimeZone(date, timeZone, 'yyyy-M-dd');
    return formatted;
}

export function userLabel(user) {
    const label = fp.get('attributes.label', user);
    const email = fp.get('attributes.primary_email', user);
    let parts = [label];
    if (!fp.isEmpty(email)) parts.push(`<${email}>`);
    return parts.join('\n');
}

export function humanName(value) {
    if (!value) return null;
    return fp.get('name.formatted', value);
}

export function humanAge(value) {
    if (!value) return 'unknown';
    const v = value.birthDate || value.dob;
    if (!v) return 'unknown';
    const birthDate = parseISO(v);
    return differenceInYears(new Date(), birthDate);
}

export const renderStrength = attributes =>
    fp.get('strengthUOM', attributes)
        ? `${attributes.strength} ${attributes.strengthUOM}`
        : fp.get('strength', attributes);

export const renderDose = attributes =>
    fp.get('doseUOM', attributes)
        ? `${attributes.dose} ${attributes.doseUOM}`
        : fp.get('dose', attributes);

export function renderValue(vq) {
    let parts = [fp.get('value', vq), fp.get('unitText', vq)];
    return fp.filter(r => r, parts).join(' ');
}

// CREDIT TO mpen's answer at https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
}
