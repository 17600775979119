import React from 'react';
import {Button, makeStyles} from '@material-ui/core';
import {EmojiObjects} from '@material-ui/icons';
import {useSnackbar} from 'notistack';

import {useApi} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';

import SuggestOrdersDialog from './SuggestOrdersDialog';

const useStyles = makeStyles(theme => ({
    suggestButton: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'white',
    },
}));

export default function SuggestOrderTemplate({practice}) {
    const api = useApi();
    const classes = useStyles();
    const suggestionDialog = useDialog(SuggestOrdersDialog);
    const {enqueueSnackbar} = useSnackbar();

    const handleDialogSubmit = async () => {
        const values = await suggestionDialog();
        if (values) {
            await api.fetchJsonApi(
                practice.relationships.order_suggestion.links.related,
                {
                    method: 'POST',
                    json: {
                        data: {
                            type: 'OrderSuggestion',
                            attributes: {...values},
                        },
                    },
                },
            );
            enqueueSnackbar('Suggestion submitted.', {
                persist: false,
                variant: 'success',
            });
        }
    };

    if (!practice) return null;
    return (
        <>
            <Button
                className={classes.suggestButton}
                startIcon={<EmojiObjects />}
                variant='outlined'
                fullWidth
                onClick={handleDialogSubmit}
            >
                Suggest an Order Template
            </Button>
        </>
    );
}
