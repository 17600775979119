import React from 'react';
import {useField} from 'formik';
import fp from 'lodash/fp';

import {TextField, FormHelperText} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {fieldId} from 'ccm/lib/util';

export function FaxOptionsField({label, fullWidth, onChange, ...props}) {
    const requiredTextField = (inputProps, props) => {
        return (
            <TextField
                label={label}
                fullWidth={fullWidth}
                required={props.required}
                {...inputProps}
            />
        );
    };
    const renderInput = inputProps => requiredTextField(inputProps, props);
    const handleChange = (ev, value, reason) => {
        console.log('handleChange', {ev, value, reason});
        // For some weird reason, MUI sends in a remove-option event,
        // clearing the field, when we blur (click away). This was
        // setting the field to [], which is strange. Ignore that event.
        if (ev.type === 'blur' && reason === 'remove-option') return;
        value = fp.pipe(
            fp.map(v => v.split(':').slice(-1).pop()),
            fp.uniq,
        )(value);
        onChange(ev, value, reason);
    };
    const getOptionSelected = (option, value) =>
        fp.endsWith(':' + value, option);
    const allProps = {
        ...props,
        freeSolo: true,
        autoSelect: true,
        onChange: handleChange,
        renderInput,
        getOptionSelected,
    };
    return <Autocomplete {...allProps} />;
}

export function FaxOptionsFormikField(props) {
    const [field, , helpers] = useField(props);
    const handleChange = (ev, value, reason) => {
        // Value has already had the "label:" part stripped
        helpers.setValue(value);
    };
    return (
        <FaxOptionsField
            id={fieldId('dyn', field.name)}
            {...field}
            {...props}
            onChange={handleChange}
            required={props.required ? field.value.length === 0 : false}
        />
    );
}

/* FreeSolo that reformats the values before setting them.
 * Allows for some type of labeling. Expects "key:val" strings.
 */

// export function FaxOptionsFormikField({label, fullWidth, ...props}) {
//     const [field, , helpers] = useField(props);
//     const parentOnChange = fp.get('onChange', props);
//     const requiredTextField = (inputProps, props) => {
//         return (
//             <TextField
//                 label={label}
//                 fullWidth={fullWidth}
//                 required={props.required ? field.value.length === 0 : false}
//                 {...inputProps}
//             />
//         );
//     };
//     const renderInput = inputProps => requiredTextField(inputProps, props);
//     const onChange = (ev, value, reason) => {
//         value = fp.pipe(
//             fp.map(v => v.split(':').slice(-1).pop()),
//             fp.uniq,
//         )(value);
//         helpers.setValue(value);
//         if (parentOnChange) {
//             // Allows value to be passed up for autosaving
//             parentOnChange(ev, value, reason);
//         }
//     };
//     const getOptionSelected = (option, value) =>
//         fp.endsWith(':' + value, option);
//     const allProps = {
//         ...field,
//         ...props,
//         id: fieldId('dyn', field.name),
//         freeSolo: true,
//         autoSelect: true,
//         renderInput,
//         onChange,
//         getOptionSelected,
//     };
//     return <Autocomplete {...allProps} />;
// }

const DynaformField = ({field}) => {
    return (
        <>
            <FaxOptionsFormikField
                name={field.id}
                label={field.label}
                options={field.options}
                required={field.required}
                multiple={field.multiple}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
};
export default DynaformField;
