import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {createSelector} from '@reduxjs/toolkit';

import {ducks, useApi} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

const selectLinkageData = data => state => {
    if (fp.isArray(data)) {
        return fp.pipe([
            fp.map(({type, id}) =>
                ducks.jsonapi.selectObject([type, id])(state),
            ),
            fp.filter(item => !!item),
        ])(data);
    } else if (data) {
        return ducks.jsonapi.selectObject([data.type, data.id])(state) || null;
    } else {
        return null;
    }
};

const selectByBackref = (rel_type, backref, id_value) =>
    createSelector(
        ducks.jsonapi.selectObject(rel_type),
        fp.filter(
            obj =>
                fp.get(['relationships', backref, 'data', 'id'], obj) ===
                id_value,
        ),
    );

// Use for the 'forward' direction (where there is a relationship.data
// on the initial object)
export const useFetchedRelationship = (object, relationship) => {
    const api = useApi();
    const rel = fp.get(['relationships', relationship], object);
    useEffect(() => {
        // console.log('Fetching relationship', {object, relationship});
        const href = fp.get(['links', 'related'], rel);
        if (!fp.isString(href)) {
            console.log('Try to fetch non-str related', href);
        }
        if (href) api.fetchAllJsonApi(href);
    }, [api, object, rel]);
    const selector = useMemo(
        () => selectLinkageData(fp.get('data', rel)),
        [rel],
    );
    return useSelector(selector);
};

// Use for the 'reverse' direction (where there is a relationship.data
// on the **fetched** data)
export const useFetchedRelationshipReverse = (
    object,
    relationship,
    rel_type,
    backref,
) => {
    const api = useApi();
    const rel = fp.get(['relationships', relationship], object);
    useEffect(() => {
        const href = fp.get(['links', 'related'], rel);
        if (href) api.fetchAllJsonApi(href);
    }, [api, object, rel]);
    const selector = useMemo(
        () => selectByBackref(rel_type, backref, fp.get('id', object)),
        [rel_type, backref, object],
    );
    return useSelector(selector);
};

var _pendingFetchUser = null;
export const useCurrentUser = () => {
    const api = useApi();
    const curUser = useSelector(selectCurrentUser);
    useEffect(() => {
        if (api.isAuthorized() && !curUser && !_pendingFetchUser) {
            console.log('Fetching current user');
            const promise = api.fetchJson(api.url_for('user.Current'));
            _pendingFetchUser = promise;
            promise.then(e => {
                _pendingFetchUser = null;
            });
            promise.catch(e => {
                _pendingFetchUser = null;
            });
            return promise;
        }
    }, [api, curUser]);
    return curUser;
};

export const useUserTelecom = user => useFetchedRelationship(user, 'telecom');
export const useUserEmails = user => useFetchedRelationship(user, 'emails');
export const useUserSignatureBlob = user =>
    useFetchedRelationship(user, 'signature_blob_processed');
export const useUserSignatureRequests = user =>
    useFetchedRelationship(user, 'signature_requests');
export const useUserUpcomingVisits = user =>
    useFetchedRelationshipReverse(user, 'upcomingVisits', 'Visit', 'provider');
