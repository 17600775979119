import React, {useEffect, useState} from 'react';
import fp from 'lodash/fp';

import {FormControlLabel, Switch} from '@material-ui/core';

import {makeStyles} from '@material-ui/core';

import {useCurrentUser, useUserUpcomingVisits} from 'ccm/dataSource/misc';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': {
            ...theme.typography.h1,
            margin: 0,
        },
        '& h2': {
            ...theme.typography.h2,
            margin: 0,
        },
        '& h3': theme.typography.h3,
        '& h4': {
            ...theme.typography.h4,
            margin: 0,
        },
        '& h5': {
            ...theme.typography.h5,
            margin: 0,
        },
        '& h6': {
            ...theme.typography.h6,
            margin: 0,
        },
        '& p,td,th': theme.typography.body2,
        '& table': {
            width: '100%',
            borderCollapse: 'collapse',
            pageBreakAfter: 'always',
        },
        '& table thead th': {
            fontWeight: 'bolder',
            backgroundColor: '#ddd',
        },
        '& table,th,td': {
            border: '1px solid #000',
        },
        '& td.blank': {
            minWidth: theme.spacing(16),
        },
        '& tbody tr td': {
            verticalAlign: 'top',
        },
    },
    expanded: {
        '& table tbody tr': {
            height: theme.spacing(16),
        },
        '& table tbody tr.compact': {
            height: theme.spacing(4),
        },
    },
    compact: {
        '& table tbody tr': {
            // height: theme.spacing(4),
        },
    },
}));

function ExpandedHeaderColumns() {
    return (
        <>
            <th>High risk meds</th>
            <th>Subjective/Events (nursing concerns, complaints, pain?)</th>
            <th>Ambulation (PPS)</th>
            <th>Cognition (FAST)</th>
            <th>Exam Findings</th>
            <th>Orders</th>
            <th>Seen Y/N</th>
        </>
    );
}

function ExpandedBodyColumns() {
    return (
        <>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'></td>
        </>
    );
}

function FacilityRows({facilityName, items, isExpanded}) {
    const fullWidth = isExpanded ? 10 : 3;
    return (
        <>
            <tr className='compact'>
                <td colspan={fullWidth}>
                    <strong>
                        {facilityName}: {items.length} patient(s)
                    </strong>
                </td>
            </tr>
            {fp.map(
                visit => (
                    <tr key={visit.id}>
                        <td>{fp.get('attributes.patient_room', visit)}</td>
                        <td>
                            {fp.get('attributes.patient_first', visit)}{' '}
                            {fp.get('attributes.patient_last', visit)}
                        </td>
                        <td>{fp.get('attributes.reason', visit)}</td>
                        {isExpanded && <ExpandedBodyColumns />}
                    </tr>
                ),
                items,
            )}
        </>
    );
}

function DailyVisits({provider, visit_date, visits, isExpanded}) {
    const classes = useStyles();
    const prettyDate = visit_date.split('T')[0];
    const groupedVisits = fp.pipe([
        fp.groupBy('attributes.facility_name'),
        fp.toPairs,
    ])(visits);
    console.log('groupedVisits', groupedVisits);
    const className = isExpanded ? classes.expanded : classes.compact;
    return (
        <div className={className}>
            <h5>
                {fp.get('attributes.name', provider)} schedule for {prettyDate}
            </h5>
            <table>
                <thead>
                    <tr>
                        <th>
                            Facility / <br /> Room
                        </th>
                        <th>Patient</th>
                        <th>CC</th>
                        {isExpanded && <ExpandedHeaderColumns />}
                    </tr>
                </thead>
                <tbody>
                    {groupedVisits.map(
                        ([facilityName, items]) => (
                            <FacilityRows
                                facilityName={facilityName}
                                items={items}
                                isExpanded={isExpanded}
                            />
                        ),
                        groupedVisits,
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default function ProviderSchedulePage() {
    const classes = useStyles();
    const curUser = useCurrentUser();
    const visits = useUserUpcomingVisits(curUser);
    const [isExpanded, setIsExpanded] = useState(true);
    useEffect(() => {
        if (curUser) {
            window.zE('webWidget', 'hide');
        }
    }, [curUser]);
    const visitsByDate = fp.pipe([
        fp.groupBy('attributes.visit_date'),
        fp.toPairs,
    ])(visits);
    const handleChange = () => setIsExpanded(!isExpanded);
    return (
        <div className={classes.root}>
            <div className='no-print'>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isExpanded}
                            onChange={handleChange}
                            name='expanded'
                        />
                    }
                    label='Expanded Layout'
                />
            </div>

            {fp.map(
                ([visit_date, visits]) => (
                    <DailyVisits
                        key={visit_date}
                        provider={curUser}
                        visit_date={visit_date}
                        visits={visits}
                        isExpanded={isExpanded}
                    />
                ),
                visitsByDate,
            )}
        </div>
    );
}
