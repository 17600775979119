import React from 'react';
import fp from 'lodash/fp';

import {Chip} from '@material-ui/core';

export default function TagChip({tagAttrs, ...props}) {
    const name = fp.get('name', tagAttrs);
    const value = fp.get('value', tagAttrs);
    const color = fp.get('color', tagAttrs);
    const backgroundColor = fp.get('backgroundColor', tagAttrs);
    const style = {
        color,
        backgroundColor,
    };
    return <Chip style={style} label={`${name} | ${value}`} {...props} />;
}
