import React from 'react';

import {Tooltip, IconButton} from '@material-ui/core';
import * as I from '@material-ui/icons';

export function Archive({onClick}) {
    return (
        <Tooltip title='Archive'>
            <IconButton onClick={onClick}>
                <I.Archive />
            </IconButton>
        </Tooltip>
    );
}

export function Unarchive({onClick}) {
    return (
        <Tooltip title='Move to inbox'>
            <IconButton onClick={onClick}>
                <I.Unarchive />
            </IconButton>
        </Tooltip>
    );
}

export function MarkRead({onClick}) {
    return (
        <Tooltip title='Mark as read'>
            <IconButton onClick={onClick}>
                <I.Drafts />
            </IconButton>
        </Tooltip>
    );
}

export function MarkUnread({onClick}) {
    return (
        <Tooltip title='Mark as unread'>
            <IconButton onClick={onClick}>
                <I.Markunread />
            </IconButton>
        </Tooltip>
    );
}

export function Refresh({onClick}) {
    return (
        <Tooltip title='Refresh'>
            <IconButton onClick={onClick}>
                <I.Refresh />
            </IconButton>
        </Tooltip>
    );
}

export function ViewArchived({onClick}) {
    return (
        <Tooltip title='View archived messages'>
            <IconButton onClick={onClick}>
                <I.Folder />
            </IconButton>
        </Tooltip>
    );
}

export function ViewInbox({onClick}) {
    return (
        <Tooltip title='View inbox'>
            <IconButton onClick={onClick}>
                <I.Inbox />
            </IconButton>
        </Tooltip>
    );
}
