import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
} from '@material-ui/core';

import {PHONE_TYPES} from 'ccm/constants';
import {useApi} from '@arborian/narrf';

export default function AddContactPointDialog({dialogRef}) {
    const api = useApi();
    const [resolve, setResolve] = useState(null);
    const [value, setValue] = useState(null);
    const [type, setType] = useState(null);
    const [token, setToken] = useState(null);
    const [codeRequested, setCodeRequested] = useState(false);

    const handleSubmit = values => {
        const fullType = fp.find(it => it.id === type, PHONE_TYPES);
        resolve({value, token, ...fullType});
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (protectedResource, options = {}) => {
        return new Promise((resolve, reject) => {
            setValue('');
            setType('mobile');
            setToken('');
            setCodeRequested(false);
            setResolve(() => resolve);
        });
    };

    const handleChangeValue = ev => {
        setValue(ev.target.value);
    };
    const handleChangeType = ev => {
        setType(ev.target.value);
    };
    const handleChangeToken = ev => {
        setToken(ev.target.value);
    };

    const handleRequestCode = async ev => {
        const fullType = fp.find(it => it.id === type, PHONE_TYPES);
        await api.fetchJson(api.url_for('user.VerificationCode'), {
            method: 'POST',
            json: {
                data: {
                    type: 'ContactPoint',
                    attributes: {...fullType, value},
                },
            },
        });
        setCodeRequested(true);
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <DialogTitle>Add Phone Number</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        label='Phone Number'
                        value={value}
                        onChange={handleChangeValue}
                    />
                    &nbsp;
                    <TextField
                        select
                        label='Type'
                        value={type}
                        onChange={handleChangeType}
                    >
                        {fp.map(
                            t => (
                                <MenuItem key={t.id} value={t.id}>
                                    {t.label}
                                </MenuItem>
                            ),
                            PHONE_TYPES,
                        )}
                    </TextField>
                </div>
                {codeRequested ? (
                    <TextField
                        label='Verification Code'
                        value={token}
                        onChange={handleChangeToken}
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                {codeRequested ? (
                    <>
                        <Button onClick={handleRequestCode}>
                            Re-send verification code
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            variant='contained'
                            color='primary'
                        >
                            Verify & Add Number
                        </Button>
                    </>
                ) : (
                    <Button
                        onClick={handleRequestCode}
                        variant='contained'
                        color='primary'
                    >
                        Request verification code
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
