// TODO: refactor with NotePrep/...
import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
// Alternative to note prep panel -- opens note prep UI in a dialog

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import PFVisitNotePrep from './PFVisitNotePrep';

const SELECTION_LISTS = [
    'transcriptTypes',
    'encounterTypes',
    'documentTypes',
    'templates',
    'providers',
    'facilities',
];

export default function VisitNotePrepDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [practice, setPractice] = useState(null);
    const [visits, setVisits] = useState(null);
    const [ehr, setEhr] = useState(null);

    const configUrl = fp.get('links.config', ehr);
    const api = useApi();

    // Fetch the entire config
    useEffect(() => {
        if (configUrl) {
            console.log('Fetching full config from', configUrl);
            api.fetchJsonApi(configUrl, {include: SELECTION_LISTS});
        }
    }, [api, configUrl]);

    const handleClose = () => {
        setPractice(null);
        setVisits(null);
        setEhr(null);
        resolve(false);
        setResolve(null);
    };

    dialogRef.current = ({practice, ehr, visits}) => {
        return new Promise((resolve, reject) => {
            setPractice(practice);
            setVisits(visits);
            setEhr(ehr);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog fullScreen open={!!resolve} onClose={handleClose}>
            <DialogTitle>Note Preparation</DialogTitle>
            <DialogContent>
                <PFVisitNotePrep
                    practice={practice}
                    ehr={ehr}
                    visits={visits}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    type='submit'
                    variant='contained'
                    color='primary'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
