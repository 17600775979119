import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';

import {useApi, ducks, components} from '@arborian/narrf';

import {useRelated} from 'ccm/dataSource';
import TelecomChips from 'ccm/components/TelecomChips';
import AddressBlock from 'ccm/components/AddressBlock';

import {useDialog} from 'ccm/components/dialogs';
import DirectoryContactDialog from 'ccm/components/dialogs/DirectoryContactDialog';

const {dt2} = components;

export default function DirectoryTable({
    protectedResource,
    tableTitle,
    dialogTitle,
    newContact,
}) {
    const api = useApi();
    const tableRef = useRef();
    const dispatch = useDispatch();
    const dialog = useDialog(DirectoryContactDialog);
    const ds = useRelated(protectedResource, 'contacts');

    const canWrite = fp.includes(
        'Common.WRITE',
        protectedResource.meta.allowed_actions,
    );
    const handleCreate = async ev => {
        await api.fetchJson(api.url_for('contact.ContactCollection'), {
            method: 'POST',
            json: {data: newContact},
        });
        ds.fetch();
    };

    const handleEdit = async (ev, row) => {
        console.log({ev, row});
        let attributes = await dialog(row.data.attributes, {
            title: dialogTitle,
            submitLabel: 'Save changes',
        });
        console.log('GOT:', {attributes});
        if (!attributes) return;
        const data = {
            ...row.data,
            attributes,
        };
        await api.fetchJsonApi(row.data.links.self, {
            method: 'PUT',
            json: {data},
        });
    };

    const handleDelete = async (evt, row) => {
        await api.fetchJson(row.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(row.data));
        ds.fetch();
    };

    return (
        <dt2.DataTable
            id='directory-table'
            title={tableTitle}
            tableRef={tableRef}
            dataSource={ds}
            options={{
                search: true,
                filtering: true,
            }}
        >
            <dt2.Column title='Name' field='attributes.name' filtering={true} />
            <dt2.Column
                title='Phone'
                field='attributes.telecom'
                filtering={false}
                component={TelecomChips}
            />
            <dt2.Column
                title='Address'
                field='attributes.address'
                filtering={false}
                component={AddressBlock}
            />
            <dt2.Action
                free
                onClick={() => ds.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            {canWrite && (
                <>
                    <dt2.Action
                        free
                        onClick={handleCreate}
                        tooltip='Add Contact'
                        icon='add'
                    />
                    <dt2.Action
                        id='directory-table-edit'
                        onClick={handleEdit}
                        tooltip='Edit Contact'
                        icon='edit'
                    >
                        {row => ({
                            hidden: !fp.get('data.attributes.editable', row),
                        })}
                    </dt2.Action>
                    <dt2.Action
                        id='directory-table-delete'
                        onClick={handleDelete}
                        tooltip='Delete Contact'
                        icon='delete'
                    >
                        {row => ({
                            hidden: !fp.get('data.attributes.editable', row),
                        })}
                    </dt2.Action>
                </>
            )}
        </dt2.DataTable>
    );
}
