import fp from 'lodash/fp';
import * as dfp from 'date-fns/fp';

export function titleAccessor(visit) {
    const attr = fp.get('attributes', visit);
    return `${attr.patient_last}, ${attr.patient_first}`;
}

export const startAccessor = fp.pipe([
    fp.get('attributes.visit_date'),
    dfp.parseISO,
]);

export const endAccessor = fp.pipe([
    fp.get('attributes.visit_date'),
    dfp.parseISO,
    dfp.add({minutes: 30}),
]);

export const allDayAccessor = () => true;

export const resourceAccessor = fp.get('relationships.facility.data.id');

export const resourceIdAccessor = fp.get('id');

export const resourceTitleAccessor = fp.get('attributes.name');
