export const MESSAGE_TYPES = [
    {value: 'message', label: 'Message'},
    {value: 'incident-report', label: 'Incident report'},
    {value: 'chart-update', label: 'Chart update'},
];

export const PHONE_TYPES = [
    {id: 'office', label: 'Office', system: 'phone', use: 'work'},
    {id: 'home', label: 'Home', system: 'phone', use: 'home'},
    {id: 'mobile', label: 'Mobile', system: 'phone', use: 'mobile'},
    {id: 'fax', label: 'Fax', system: 'fax', use: 'work'},
];
