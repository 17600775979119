import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Menu,
    MenuItem,
    makeStyles,
} from '@material-ui/core';
import {Chip, IconButton} from '@material-ui/core';
import {Add, ExpandMore} from '@material-ui/icons';
import * as h from 'ccm/lib/helpers';

import {useShiftAssignmentContext} from './context';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiChip-root': {
            padding: theme.spacing(3, 0),
        },
        '& .MuiChip-label': {
            whiteSpace: 'pre-wrap',
        },
    },
}));

function Assignment({shift, role}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        addOwnerDialog,
        addProviderDialog,
        fallbackUser,
        shiftUser,
        setFallbackAssignments,
        setShiftAssignments,
    } = useShiftAssignmentContext();

    const addDialog = role === 'owner' ? addOwnerDialog : addProviderDialog;
    const user = shift ? shiftUser(shift, role) : fallbackUser(role);
    const label = h.userLabel(user);

    const handleAdd = async () => {
        closeMenu();
        const {action, value} = await addDialog();
        console.log({action, value});
        if (action === 'select') {
            if (shift === null) {
                return setFallbackAssignments(value, role);
            } else {
                return setShiftAssignments(value, shift, role);
            }
        }
    };

    const handleDelete = () => {
        closeMenu();
        if (shift === null) {
            return setFallbackAssignments(null, role);
        } else {
            return setShiftAssignments(null, shift, role);
        }
    };

    const openMenu = evt => {
        setAnchorEl(evt.currentTarget);
    };

    const closeMenu = evt => {
        setAnchorEl(null);
    };

    return (
        <>
            {user === null ? (
                <IconButton onClick={handleAdd}>
                    <Add />
                </IconButton>
            ) : user ? (
                <Chip label={label} onDelete={handleDelete} />
            ) : (
                <>
                    <Button
                        onClick={openMenu}
                        variant='outlined'
                        endIcon={<ExpandMore />}
                    >
                        Multiple
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={closeMenu}
                    >
                        <MenuItem onClick={handleAdd}>Assign to all</MenuItem>
                        <MenuItem onClick={handleDelete}>Unassign all</MenuItem>
                    </Menu>
                </>
            )}
        </>
    );
}

export default function ShiftAssignmentsTable() {
    const classes = useStyles();
    const {shifts} = useShiftAssignmentContext();
    return (
        <Table className={classes.root}>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Fallback</TableCell>
                    {fp.map(
                        shift => (
                            <TableCell>{shift.attributes.name}</TableCell>
                        ),
                        shifts,
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Owner</TableCell>
                    <TableCell>
                        <Assignment shift={null} role='owner' />
                    </TableCell>
                    {fp.map(
                        shift => (
                            <TableCell>
                                <Assignment shift={shift} role='owner' />
                            </TableCell>
                        ),
                        shifts,
                    )}
                </TableRow>
                <TableRow>
                    <TableCell>Provider</TableCell>
                    <TableCell>
                        <Assignment shift={null} role='provider' />
                    </TableCell>
                    {fp.map(
                        shift => (
                            <TableCell>
                                <Assignment shift={shift} role='provider' />
                            </TableCell>
                        ),
                        shifts,
                    )}
                </TableRow>
            </TableBody>
        </Table>
    );
}
