import {useEffect, useMemo} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {useApi, ducks} from '@arborian/narrf';

const selectVisitFacilities = visits =>
    createSelector(ducks.jsonapi.selectObject('Facility'), facs => {
        if (!visits || !facs) return [];
        return fp.pipe(
            fp.map(fp.get('relationships.facility.data.id')),
            fp.uniq,
            fp.map(facid => facs[facid]),
            fp.filter(fac => !!fac),
        )(visits);
    });

const selectVisitTickets = visit =>
    createSelector(
        ducks.jsonapi.selectObject('Ticket'),
        tickets =>
            tickets &&
            fp.pipe([
                fp.get('relationships.addressing_tickets.data'),
                fp.map(link => tickets[link.id]),
            ])(visit),
    );

export const useVisitTickets = visit => {
    const api = useApi();
    const selector = useMemo(() => selectVisitTickets(visit), [visit]);
    const tickets = useSelector(selector);
    useEffect(() => {
        api.fetchAllJsonApi(
            fp.get('relationships.addressing_tickets.links.related', visit),
        );
    }, [api, visit]);
    return tickets;
};

export const useVisitFacilities = visits => {
    const selector = useMemo(() => selectVisitFacilities(visits), [visits]);
    return useSelector(selector);
};
