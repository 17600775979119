import React, {useState} from 'react';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
} from '@material-ui/core';
import {Autocomplete} from 'formik-material-ui-lab';
import {Formik, Form, Field} from 'formik';

const _getOptionSelected = (option, value) => option?.id === value?.id;

function ItemSelect({
    label,
    name,
    touched,
    errors,
    options,
    getOptionLabel,
    getOptionSelected = _getOptionSelected,
    ...props
}) {
    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    autoFocus
                    errors={touched && touched[name] && !!errors[name]}
                    helperText={errors && errors[name]}
                />
            )}
            {...props}
        />
    );
}

export default function AutocompleteDialog({
    dialogRef,
    options,
    title = 'Select Item',
    label = 'Item',
    submitLabel = 'Select',
    ...props
}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState();

    const handleSubmit = values => {
        resolve({action: 'select', value: values.value});
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    dialogRef.current = () => {
        return new Promise((resolve, reject) => {
            setInitialValues({value: null});
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel} maxWidth='sm' fullWidth>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContent>
                            <Field
                                name='value'
                                component={ItemSelect}
                                options={options}
                                label={label}
                                fullWidth
                                {...props}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
