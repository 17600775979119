import React, {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useApi, ducks, components} from '@arborian/narrf';
import {useListField} from 'ccm/dataSource';
import ProgressIcon from 'ccm/components/ProgressIcon';
import TherapyProgressPlot from './TherapyProgressPlot';

import {Tabs, Tab} from '@material-ui/core';

const {dt2} = components;

const TABLE_OPTIONS = {
    filtering: false,
    paging: false,
    addRowAtBottom: false,
};
function useTherapyProgress(therapy) {
    const api = useApi();
    const handleChange = useCallback(
        async log => {
            const data = fp.set('attributes.log', log, therapy);
            const resp = await api.fetchJson(therapy.links.self, {
                method: 'PATCH',
                json: {data},
            });
            return resp;
        },
        [api, therapy],
    );
    const ds = useListField(therapy.attributes.log, handleChange, {
        reversed: true,
    });
    return ds;
}

function TherapyProgressTable({title, dataSource}) {
    return (
        <dt2.DataTable
            size='small'
            dataSource={dataSource}
            options={TABLE_OPTIONS}
            title={title}
            editable={{
                onRowAdd: dataSource.handleAdd,
                onRowDelete: dataSource.handleDelete,
            }}
        >
            <dt2.Column
                title='Progress'
                field='percentage'
                component={ProgressIcon}
            />
            <dt2.Column
                title='Updated'
                field='timestamp'
                type='date'
                editable={false}
            />
            <dt2.Action
                free
                icon='refresh'
                onClick={() => dataSource.fetch()}
            />
        </dt2.DataTable>
    );
}

export default function TherapyDetail({canWritePhi, therapy}) {
    const [tab, setTab] = useState(0);
    const realTherapy = useSelector(
        ducks.jsonapi.selectObject(['Therapy', therapy.id]),
    );
    const dsTherapy = useTherapyProgress(realTherapy);
    const title = (
        <>
            <h6>{fp.get('attributes.title', therapy)}</h6>
            <p>
                <small>{fp.get('attributes.description', therapy)}</small>
            </p>
        </>
    );
    return (
        <div>
            {canWritePhi ? (
                <>
                    <Tabs value={tab} onChange={(e, index) => setTab(index)}>
                        <Tab label='Chart' />
                        <Tab label='Table' />
                    </Tabs>
                    {tab === 0 && <TherapyProgressPlot therapy={therapy} />}
                    {tab === 1 && (
                        <TherapyProgressTable
                            title={title}
                            dataSource={dsTherapy}
                        />
                    )}
                </>
            ) : (
                <TherapyProgressPlot therapy={therapy} />
            )}
        </div>
    );
}
