import React from 'react';
import fp from 'lodash/fp';

import {Button, FormControl, FormLabel} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';

import PortalConfig from './PortalConfig';

const FLAGS = [
    {id: 'allergy_intolerance', label: 'allergy added/changed'},
    {id: 'condition', label: 'condition added/changed'},
    {id: 'medication', label: 'medication added/changed'},
    {id: 'observation', label: 'vital(s) added/changed'},
];

function Notifications({prefix, label, progressNoteTypes}) {
    return (
        <FormControl control='fieldset'>
            <FormLabel control='legend'>{label}</FormLabel>
            {fp.map(
                f => (
                    <Field
                        key={f.id}
                        component={CheckboxWithLabel}
                        type='checkbox'
                        name={`attributes.${prefix}.${f.id}`}
                        Label={{label: f.label}}
                    />
                ),
                FLAGS,
            )}
        </FormControl>
    );
}

export default function CollainConfig({config, onSubmit}) {
    return (
        <Formik enableReinitialize initialValues={config} onSubmit={onSubmit}>
            {({touched, values, errors}) => (
                <Form>
                    <h6>Collain Configuration</h6>
                    <Notifications
                        prefix='notify_patient_on'
                        label='Notify patient when...'
                    />
                    <Notifications
                        prefix='create_ticket_on'
                        label='Create provider ticket when...'
                    />
                    <PortalConfig config={config} />
                    <div>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            Save Configuration
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
