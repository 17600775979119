import React, {useState, useEffect, useMemo, useCallback} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import {
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Tooltip,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import {ducks} from '@arborian/narrf';
import {createSelector} from '@reduxjs/toolkit';

import {selectAsList} from 'ccm/lib/selectors';
import {fieldId} from 'ccm/lib/util';
import {useGlobals} from 'ccm/components/Globals';

const useStyles = makeStyles(theme => ({
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        height: '100%',
    },
    ordersEditorContainer: {
        minHeight: 0, // Let it flex grow
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 auto',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    transferPanel: {
        flex: '1 1 50%',
        minHeight: 0,
        '& .MuiList-root': {
            overflow: 'auto',
            padding: theme.spacing(1),
        },
        '& fieldset': {
            ...theme.typography.body1,
            position: 'absolute',
            top: -5,
            left: 0,
            right: 0,
            bottom: 0,
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: 4,
            '& legend': {
                display: 'block',
                height: 11,
                lineHeight: '11px',
                fontSize: '0.75em',
                '& span': {
                    color: theme.palette.text.primary,
                    opacity: 0.7,
                    padding: '0px 5px',
                    display: 'inline-block',
                },
            },
        },
    },
    ordersListContainer: {
        overflowY: 'scroll',
    },
    textFieldContainer: {
        flex: '1 1 50%',
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiInputBase-input': {
            height: '100%!important',
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiInputBase-root': {
                height: '100%',
            },
            '& .MuiInputBase-input': {
                overflowY: 'scroll!important',
            },
            '& .MuiOutlinedInput-multiline': {
                padding: '8px 0px 8px 16px',
            },
        },
    },
    textFieldDanger: {
        '& fieldset': {
            borderColor: 'red',
        },
        '&:hover fieldset': {
            borderColor: 'red!important',
        },
    },
    ordersAlert: {
        '& .MuiAlert-message': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
}));

const selectSortedOrders = createSelector(
    selectAsList('OrderTemplate'),
    fp.pipe([
        fp.filter(ot => !!fp.get('relationships.practice.data.id', ot)),
        fp.sortBy('attributes.title'),
    ]),
);

const selectAllInstructions = createSelector(
    ducks.jsonapi.selectObject('OrderTemplate'),
    selectAsList('Instruction'),
    (oIndex, iList) =>
        fp.pipe([
            fp.filter(inst => !!fp.get('relationships.practice.data.id', inst)),
            fp.sortBy(['attributes.order_id', 'attributes.position']),
            fp.map(i => {
                const o = fp.get(
                    i.relationships.order_template.data.id,
                    oIndex,
                );
                return {
                    instruction_id: i.id,
                    instruction_text: i.attributes.text,
                    order_id: i.relationships.order_template.data.id,
                    order_title: o && o.attributes.title,
                };
            }),
        ])(iList),
);

const selectAllInstructionsIndex = createSelector(
    selectAllInstructions,
    fp.pipe([fp.map(i => [i.instruction_id, i]), fp.fromPairs]),
);

function OrderItems({title, instructions, onClick}) {
    return (
        <>
            <ListSubheader disableSticky>{title}</ListSubheader>
            <List dense disablePadding>
                {fp.map(
                    instruction => (
                        <InstructionItem
                            key={instruction.instruction_id}
                            instruction={instruction}
                            onClick={onClick}
                            id={fieldId(
                                'instruction',
                                instruction.instruction_id,
                            )}
                        />
                    ),
                    instructions,
                )}
            </List>
        </>
    );
}

function InstructionItem({instruction, onClick}) {
    const handleClick = (ev, ...args) => {
        onClick([instruction.instruction_id]);
    };
    return (
        <ListItem
            button
            onClick={handleClick}
            value={instruction}
            id={fieldId('instruction', instruction.instruction_id)}
        >
            <ListItemText primary={instruction.instruction_text} />
        </ListItem>
    );
}

function TransferPanelLeft({items, onSelect, ordersCategory}) {
    const classes = useStyles();
    const sortedItems = useMemo(
        () => fp.pipe([fp.toPairs, fp.sortBy((key, val) => key)])(items),
        [items],
    );
    const flatItems = useMemo(() => fp.flatMap(i => i, items), [items]);

    return (
        <FormControl
            className={classes.transferPanel}
            size='small'
            variant='outlined'
        >
            <fieldset>
                <legend>
                    <span>Order Templates</span>
                </legend>
            </fieldset>
            <List dense>
                {ordersCategory
                    ? fp.map(
                          instruction => (
                              <InstructionItem
                                  key={instruction.instruction_id}
                                  instruction={instruction}
                                  onClick={onSelect}
                              />
                          ),
                          flatItems,
                      )
                    : fp.map(
                          ([title, instructions]) => (
                              <OrderItems
                                  key={title}
                                  title={title}
                                  instructions={instructions}
                                  onClick={onSelect}
                              />
                          ),
                          sortedItems,
                      )}
            </List>
        </FormControl>
    );
}

function insertTextAtCursor(text, setText) {
    var el = document.getElementById('orders-field');
    if (!el) return;
    var val = el.value;
    if (
        typeof el.selectionStart == 'number' &&
        typeof el.selectionEnd == 'number'
    ) {
        const endIndex = el.selectionEnd;
        el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
        setText(el.value);
        el.selectionStart = el.selectionEnd = endIndex + text.length;
    }
}

export default function OrdersEditor({text, setText}) {
    const classes = useStyles();
    const globals = useGlobals();
    const hasOrders = fp.trim(text).length !== 0;

    const [ordersCategory, setOrdersCategories] = useState('');
    useEffect(() => {
        globals.fetchAll('order.TemplateCollection', {
            filter: {practice_id: JSON.stringify({$ne: null})},
            page: {limit: 200},
        });
        globals.fetchAll('order.InstructionCollection', {
            filter: {practice_id: JSON.stringify({$ne: null})},
            page: {limit: 200},
        });
    }, [globals]);

    const allOrders = useSelector(selectSortedOrders);
    const allInstructions = useSelector(selectAllInstructions);
    const instructionsIndex = useSelector(selectAllInstructionsIndex);

    console.log({allOrders, allInstructions, instructionsIndex});

    const instructionsLeft = useMemo(() => {
        return fp.pipe([
            fp.filter(i => !ordersCategory || i.order_id === ordersCategory.id),
            fp.groupBy(i => i.order_title),
        ])(allInstructions);
    }, [allInstructions, ordersCategory]);

    const handleOrdersChange = event => {
        let order = event.target.value;
        setOrdersCategories(order);
    };

    const pushToOrderText = useCallback(
        ids => {
            const value = [
                '',
                ...fp.map(id => instructionsIndex[id].instruction_text, ids),
                '',
            ].join('\n');
            insertTextAtCursor(value, setText);
        },
        [instructionsIndex, setText],
    );

    const handleSelect = useCallback(
        ids => {
            pushToOrderText(ids);
        },
        [pushToOrderText],
    );

    const handleTextEdit = ev => {
        setText(ev.target.value);
    };

    return (
        <div className={classes.contentContainer}>
            <FormControl margin='dense' size='small'>
                <InputLabel>Filter Order Templates</InputLabel>
                <Select
                    id='static_orders_category_filter_select'
                    value={ordersCategory}
                    onChange={handleOrdersChange}
                    label='Orders Categories'
                >
                    <MenuItem value=''>All</MenuItem>
                    {allOrders.map(order => (
                        <MenuItem
                            key={order.id}
                            value={order}
                            id={fieldId('orders', order.id)}
                        >
                            {order.attributes.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={classes.ordersEditorContainer}>
                <TransferPanelLeft
                    items={instructionsLeft}
                    ordersCategory={ordersCategory}
                    onSelect={handleSelect}
                />
                <TextField
                    className={[
                        classes.textFieldContainer,
                        hasOrders ? null : classes.textFieldDanger,
                    ].join(' ')}
                    id='orders-field'
                    label='Ticket Orders'
                    multiline
                    variant='outlined'
                    value={text ?? ''}
                    onChange={handleTextEdit}
                />
            </div>
            <OrdersAlert hasOrders={hasOrders} />
        </div>
    );
}

const OrdersAlert = ({hasOrders}) => {
    const classes = useStyles();
    const text = hasOrders
        ? `Modifying orders here will NOT update orders previously set
    on documents/images.`
        : `Ticket has no orders. You must provide orders to continue resolving this ticket.`;
    const severity = hasOrders ? 'info' : 'warning';

    return (
        <Tooltip className={classes.ordersAlert} title={text}>
            <Alert severity={severity}>{text}</Alert>
        </Tooltip>
    );
};
