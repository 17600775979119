import React from 'react';

import {
    makeStyles,
    Button,
    Paper,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from '@material-ui/core';

import BasePage from 'ccm/components/BasePage';
import {url_for} from 'ccm/routes';

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
        padding: 16,
        [theme.breakpoints.down('xs')]: {
            // width: 400,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 40,
    },
    logo: {
        width: '80%',
        height: 'auto',
    },
    cardsContainer: {
        display: 'flex',
        '& .MuiCard-root': {
            flex: 1,
            margin: theme.spacing(1),
            height: 300,
        },
        '& .MuiCardContent-root': {
            height: 150,
        },
    },
}));

export default function HomePage() {
    const classes = useStyles();

    return (
        <BasePage>
            <header className={classes.header}>
                <Paper className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src='/img/tcpa_slim.png'
                        alt='Logo'
                    />
                    <img
                        className={classes.logo}
                        src='/img/SamePageMDLogo-2.png'
                        alt='Logo'
                    />
                </Paper>
                <h4>The TCPA communications tool is now SamePageMD!</h4>
            </header>
            <div className={classes.cardsContainer}>
                <Card>
                    <CardHeader
                        title='New User?'
                        subheader='You need to register first'
                    />
                    <CardContent>
                        <p>
                            If you've used the TCPA communication tool, you'll
                            still need to register a new account with
                            SamePageMD.
                        </p>
                    </CardContent>
                    <CardActions>
                        <Button
                            color='primary'
                            size='large'
                            variant='contained'
                            href={url_for('register')}
                        >
                            Register
                        </Button>
                    </CardActions>
                </Card>
                <Card>
                    <CardHeader
                        title='Returning User?'
                        subheader='Already have a SamePage Account?'
                    />
                    <CardContent>
                        <p>You're all set! Just sign in below.</p>
                    </CardContent>
                    <CardActions>
                        <Button
                            color='primary'
                            size='large'
                            variant='contained'
                            href={url_for('login')}
                        >
                            Sign In
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </BasePage>
    );
}
