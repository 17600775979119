import React, {useMemo, useEffect} from 'react';
import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

import {Autocomplete} from 'formik-material-ui-lab';
import {TextField} from '@material-ui/core';

import {ducks, useApi} from '@arborian/narrf';

const selectPracticeProviders = practiceId =>
    createSelector(ducks.jsonapi.selectObject('UserSummary'), users => {
        const groupId = `practice:${practiceId}/provider`;
        return fp.filter(user => {
            const match = fp.pipe(
                fp.get('attributes.group_ids'),
                fp.includes(groupId),
            )(user);
            return !!match;
        }, users);
    });

export default function ProviderSelect({
    practice,
    label,
    name,
    touched,
    errors,
    ...props
}) {
    const api = useApi();
    useEffect(() => {
        const url = fp.get('relationships.providers.links.related', practice);
        if (url) {
            api.fetchAllJsonApi(url);
        }
    }, [api, practice]);
    const providerSelector = useMemo(
        () => selectPracticeProviders(fp.get('id', practice)),
        [practice],
    );
    const providers = fp.values(useSelector(providerSelector));
    console.log('All providers is', providers);
    return (
        <Autocomplete
            options={providers}
            getOptionLabel={fp.get('attributes.name')}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    errors={touched && touched[name] && !!errors[name]}
                    helperText={errors && errors[name]}
                />
            )}
            {...props}
        />
    );
}
