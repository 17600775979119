import React from 'react';
import {useField} from 'formik';

import {TextField, FormHelperText} from '@material-ui/core';

import {Autocomplete as MUIAutocomplete} from '@material-ui/lab';

import {fieldId} from 'ccm/lib/util';

export function AutocompleteField({label, fullWidth, ...props}) {
    const [field, , helpers] = useField(props);
    const renderInput = props => (
        <TextField label={label} fullWidth={fullWidth} {...props} />
    );
    const onChange = (ev, value, reason) => {
        helpers.setValue(value);
    };
    let allProps = {
        ...field,
        ...props,
        renderInput,
        onChange,
    };
    allProps.value = allProps.value || '';

    return <MUIAutocomplete id={fieldId('dyn', props.name)} {...allProps} />;
}

const getOptionLabel = o => o.label;
const getOptionSelected = (o, val) => {
    return o.value === val.value;
};

const DynaformField = ({field}) => {
    return (
        <>
            <AutocompleteField
                name={field.id}
                label={field.label}
                options={field.options}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                required={field.required}
                multiple={field.multiple}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
};
export default DynaformField;
