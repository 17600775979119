import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {useApi} from '@arborian/narrf';

import {selectHealthcareProviders} from 'ccm/lib/selectors';

function getLinkage(opt) {
    const {type, id} = opt;
    return {type, id};
}

function getLinkageSelected(opt, value) {
    return fp.isEqual(getLinkage(opt), value);
}

export function ProviderSelectField({
    practice,
    label,
    fullWidth,
    field,
    form,
    ...props
}) {
    const api = useApi();
    const providers = useSelector(selectHealthcareProviders);
    const providerIndex = useMemo(
        () => fp.pipe([fp.map(p => [p.id, p]), fp.fromPairs])(providers),
        [providers],
    );
    const options = fp.map(getLinkage, providers);

    useEffect(() => {
        const url = fp.get('relationships.providers.links.related', practice);
        api.fetchAllJsonApi(url);
    }, [api, practice]);

    const renderInput = props => (
        <TextField label={label} fullWidth={fullWidth} {...props} />
    );

    const onChange = (ev, value, reason) => {
        form.setFieldValue(field.name, value);
    };

    let allProps = {
        ...field,
        ...props,
        renderInput,
        onChange,
    };
    allProps.value = allProps.value || '';

    const getOptionLabel = linkage => {
        const label = fp.getOr(
            '',
            [linkage.id, 'attributes', 'name'],
            providerIndex,
        );
        return label;
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getLinkageSelected}
            // providers || []}
            // getOptionLabel={fp.get('attributes.name')}
            // getOptionSelected={getOptionSelected}
            {...allProps}
        />
    );
}
