import React from 'react';
import fp from 'lodash/fp';

import VitalCard from './VitalCard';
import {DropAvatar} from 'ccm/art/MedicalIcons';

export default function BloodPressureCard({patient, artifacts}) {
    const systolic = fp.find(
        v => v.attributes.code.text === 'BLOODPRESSURE_SYSTOLIC',
        artifacts,
    );
    const diastolic = fp.find(
        v => v.attributes.code.text === 'BLOODPRESSURE_DIASTOLIC',
        artifacts,
    );
    let parts = [];
    let unit = null;
    if (systolic) {
        parts.push(fp.get('attributes.value.value', systolic));
        unit = fp.get('attributes.value.unit', systolic);
    } else {
        return null;
    }
    parts.push('/');
    if (diastolic) {
        parts.push(fp.get('attributes.value.value', diastolic));
    }
    if (unit) {
        parts.push(unit);
    }
    const title = parts.join(' ');

    //    const title = `${pcc.systolicValue} / ${pcc.diastolicValue} ${pcc.unit}`;
    return (
        <VitalCard
            title={title}
            color='blue'
            Avatar={DropAvatar}
            type='bloodPressure'
            patient={patient}
            {...systolic.attributes}
        >
            <h6>Blood pressure</h6>
        </VitalCard>
    );
}
