import React from 'react';

import {WindAvatar} from 'ccm/art/MedicalIcons';

import VitalCard from './VitalCard';

export default function OxygenSaturationCard({patient, artifact}) {
    return (
        <VitalCard
            color='blue'
            Avatar={WindAvatar}
            type='oxygenSaturation'
            patient={patient}
            {...artifact.attributes}
        >
            <h6>Oxygen saturation</h6>
        </VitalCard>
    );
}
