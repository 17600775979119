import {useCallback, useEffect, useMemo, useState} from 'react';
import fp from 'lodash/fp';

import {jsonapi} from '../ducks';

const EMPTY = {};

export function useDataSource({data, fetchOptions}) {
    const [state, setState] = useState('init');
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);

    const fetch = useCallback(
        async (clearFirst = true) => {
            if (clearFirst) {
                setState('busy');
                setItems([]);
            }
            try {
                const resp = await data(fetchOptions);
                setItems(resp.rows);
                setState('ready');
                setCount(resp.count);
            } catch (error) {
                console.error(error);
                setState('error');
                setError(error);
            }
        },
        [data, fetchOptions, setState, setError, setItems, setCount],
    );

    useEffect(() => {
        if (fetch && state !== 'busy' && state !== 'init') {
            fetch();
        }
    }, [fetch]);

    return {
        fetch,
        fetchOptions,
        state,
        items,
        error,
        count,
    };
}

const selectItemsFromState = items => state => {
    return fp.map(it => jsonapi.selectObject([it.type, it.id])(state), items);
};

// DS which always uses data from the redux store for items
export function useReduxDataSource(props) {
    const result = useDataSource(props);
    const selectItems = useMemo(
        () => selectItemsFromState(result.items),
        [result.items],
    );
    const items = useSelector(selectItems);
    return {...result, items};
}

// DS that adds selection & detail panels to other datasources
export function useTableDataSource(ds) {
    const [selected, setSelected] = useState(EMPTY);
    const [detail, setDetail] = useState(EMPTY);

    const {items: dsItems, state: dsState} = ds;

    const items = useMemo(() => {
        let newItemIds = new Set();
        // Build items list with selected flag & detail
        const result = fp.map(item => {
            newItemIds.add(item.id);
            return {
                id: item.id,
                data: item,
                state: {
                    selected: fp.getOr(false, item.id, selected),
                    detail: fp.getOr(null, item.id, detail),
                },
            };
        }, dsItems);
        return result;
    }, [dsItems, selected, detail]);

    const selectItem = useCallback(
        (item, itemIsSelected) =>
            setSelected(fp.set(item.id, itemIsSelected, selected)),
        [selected],
    );

    const selectAllItems = useCallback(
        isSelected =>
            setSelected(
                fp.pipe([fp.map(item => [item.id, isSelected]), fp.fromPairs])(
                    dsItems,
                ),
            ),
        [dsItems],
    );

    const toggleItemDetail = useCallback(
        (item, itemDetail) => {
            const oldDetail = fp.get(item.id, detail);
            const newDetail = oldDetail === itemDetail ? null : itemDetail;
            setDetail(fp.set(item.id, newDetail, detail));
        },
        [detail],
    );

    const selectedItems = useMemo(
        () => fp.filter(it => it.state.selected, items),
        [items],
    );

    const someItemsSelected = useMemo(
        () => selectedItems.length > 0,
        [selectedItems],
    );

    const allItemsSelected = useMemo(
        () =>
            selectedItems.length
                ? selectedItems.length === items.length
                : false,
        [selectedItems],
    );

    return {
        ...ds,
        items,
        selectedItems,
        selectItem,
        selectAllItems,
        someItemsSelected,
        allItemsSelected,
        toggleItemDetail,
    };
}
