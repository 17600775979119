import {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useApi, ducks} from '@arborian/narrf';

export const usePracticeEhr = practice => {
    const api = useApi();
    useEffect(() => {
        api.fetchJsonApi(
            fp.get('relationships.ehr_connection.links.related', practice),
        );
    }, [api, practice]);
    const selector = useMemo(
        () => ducks.jsonapi.selectRelated(practice),
        [practice],
    );
    const rel = useSelector(selector);
    return rel?.ehr_connection;
};
