import CarePlansCard from './CarePlansCard';
import ConditionsCard from './ConditionsCard';
import MedicationsCard from './MedicationsCard';
import AllergiesCard from './AllergiesCard';
import ImmunizationsCard from './ImmunizationsCard';
import DiagnosticReportCard from './DiagnosticReportCard';
import ProgressNotesCard from './ProgressNotesCard';
import PractitionersCard from './PractitionersCard';
import ContactsCard from './ContactsCard';
import NutritionOrdersCard from './NutritionOrdersCard';
import TherapyCard from './TherapyCard';
import AssessmentCard from './AssessmentCard';

import ConditionTable from './ConditionTable';
import MedicationTable from './MedicationTable';
import AllergyTable from './AllergyTable';
import ImmunizationTable from './ImmunizationTable';
import DiagnosticReportTable from './DiagnosticReportTable';
import CarePlansTable from './CarePlanTable';
import ProgressNotesTable from './ProgressNotesTable';
import ContactsTable from './ContactsTable';
import PractitionersTable from './PractitionersTable';
import NutritionTable from './NutritionTable';
import TherapyTable from './TherapyTable';
import AssessmentsTable from './AssessmentsTable';

const INFO_CONFIG = [
    {
        key: 'diagnosticReports',
        title: 'Labs & radiology',
        filter: 'show_diagnostic_report',
        component: DiagnosticReportCard,
        table: DiagnosticReportTable,
    },
    {
        key: 'medications',
        title: 'Medications',
        filter: 'show_medication',
        component: MedicationsCard,
        table: MedicationTable,
    },
    {
        key: 'carePlans',
        title: 'Care plans',
        filter: 'show_care_plan',
        component: CarePlansCard,
        table: CarePlansTable,
    },
    {
        key: 'progressNotes',
        title: 'Progress notes',
        filter: 'show_progress_note',
        component: ProgressNotesCard,
        table: ProgressNotesTable,
    },
    {
        key: 'therapy',
        title: 'Therapy Progress',
        filter: 'show_therapy',
        component: TherapyCard,
        table: TherapyTable,
    },
    {
        key: 'allergies',
        title: 'Allergies & intolerances',
        filter: 'show_allergy_intolerance',
        component: AllergiesCard,
        table: AllergyTable,
    },
    {
        key: 'conditions',
        title: 'Health concerns',
        filter: 'show_condition',
        component: ConditionsCard,
        table: ConditionTable,
    },
    {
        key: 'immunizations',
        title: 'Immunizations',
        filter: 'show_immunization',
        component: ImmunizationsCard,
        table: ImmunizationTable,
    },
    {
        key: 'practitioners',
        title: 'Healthcare providers',
        filter: 'show_practitioner',
        component: PractitionersCard,
        table: PractitionersTable,
    },
    {
        key: 'contacts',
        title: 'Patient contacts',
        filter: 'show_contact',
        component: ContactsCard,
        table: ContactsTable,
    },
    {
        key: 'nutrition',
        title: 'Nutrition',
        filter: 'show_nutrition_order',
        component: NutritionOrdersCard,
        table: NutritionTable,
    },
    {
        key: 'assessment',
        title: 'Assessments',
        filter: 'show_assessment',
        component: AssessmentCard,
        table: AssessmentsTable,
    },
];

export default INFO_CONFIG;
