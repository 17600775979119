import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import * as yup from 'yup';
import {Autocomplete} from 'formik-material-ui-lab';

const INITIAL_VALUES = {tag: ''};

const tagSchema = yup.object().shape({
    tag: yup.string().matches(/^[^:]+:[^:]+$/, 'Tag format must be NAME:VALUE'),
});

export default function AddTagDialog({dialogRef, tags}) {
    const [resolve, setResolve] = useState(null);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    if (dialogRef) {
        dialogRef.current = () => {
            return new Promise((resolve, reject) => {
                setResolve(() => resolve);
            });
        };
    }
    const tagLabels = fp.map('attributes.label', tags);

    return (
        <Dialog open={!!resolve} onClose={handleCancel} maxWidth='sm' fullWidth>
            <Formik
                enableReinitialize
                initialValues={INITIAL_VALUES}
                onSubmit={handleSubmit}
                validationSchema={tagSchema}
            >
                {({touched, values, errors, setFieldValue}) => (
                    <Form>
                        <DialogTitle>Add Tag</DialogTitle>
                        <DialogContent>
                            <Field
                                name='tag'
                                freeSolo
                                component={Autocomplete}
                                options={tagLabels}
                                renderInput={params => (
                                    <TextField
                                        label='Tag'
                                        {...params}
                                        errors={
                                            touched &&
                                            touched.tag &&
                                            !!errors.tag
                                        }
                                        helperText={errors && errors.tag}
                                        onBlur={ev => {
                                            setFieldValue(
                                                'tag',
                                                ev.target.value,
                                            );
                                        }}
                                    />
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Add Tag
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
