import fp from 'lodash/fp';
import * as Yup from 'yup';

function fieldSchema(field) {
    let result = Yup.mixed();
    if (field.required) {
        result = result.required('required');
    }
    if (field.initialValue === null || field.nullable) {
        result = result.nullable();
    }
    return result;
}

export function buildSchema(formDef) {
    const fields = fp.pipe([
        fp.map(field => [field.id, fieldSchema(field)]),
        fp.fromPairs,
    ])(formDef.fields);
    return Yup.object().shape(fields);
}
