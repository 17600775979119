import {fabric} from 'fabric';

export const trash = ctx => ev => {
    console.log('Trash some stuff');
    const {editor} = ctx;
    editor.deleteSelected();
};

export const rotate = (ctx, degrees) => ev => {
    const {canvas, isSafari, setRotatedBlob} = ctx;
    if (!acceptRotationWarning(ctx)) return;
    canvas.backgroundImage.clone(newImage => {
        newImage.rotate(degrees);
        const url = newImage.toDataURL();
        // setCurrentCrop(url);
        canvas.clear();
        canvas.setBackgroundImage(url, () => {
            if (isSafari) {
                // Address "Maximum Limit" issue in Safari
                canvas.enableRetinaScaling = false;
            }
            canvas.setWidth(canvas.backgroundImage.width);
            canvas.setHeight(canvas.backgroundImage.height);
            canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
            canvas.calcOffset();
            canvas.renderAll();
            // Save blob
            canvas.toCanvasElement().toBlob(blob => {
                setRotatedBlob(blob);
            }, 'image/png');
        });
    });
};

export const startCrop = ctx => ev => {
    const {canvas, setCropArea, setIsCropping} = ctx;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const selectionWidth = canvas.width * 0.75;
    const selectionHeight = canvas.height * 0.75;
    let element = new fabric.Rect({
        top: centerX - selectionWidth / 2,
        left: centerY - selectionHeight / 2,
        width: selectionWidth,
        height: selectionHeight,
        fill: 'rgba(0,0,0,0)',
        strokeWidth: 3,
        stroke: 'black',
        strokeDashArray: [5, 5],
    });
    element.on('modified', options => {
        console.log(options.target.width, options.target.height);
        console.log(options, canvas.getZoom());
    });
    setCropArea(element);
    setIsCropping(true);
    canvas.add(element);
};

export const completeCrop = ctx => ev => {
    const {canvas, cropArea, setCropArea, setIsCropping} = ctx;
    // Delete the rect
    canvas.remove(cropArea);

    // Apply the crop
    const url = canvas.toDataURL({
        left: cropArea.left,
        top: cropArea.top,
        width: cropArea.width * cropArea.scaleX,
        height: cropArea.height * cropArea.scaleY,
    });
    canvas.clear();

    canvas.setBackgroundImage(
        url,
        () => {
            canvas.setWidth(cropArea.width * cropArea.scaleX);
            canvas.setHeight(cropArea.height * cropArea.scaleY);
            canvas.calcOffset();
            canvas.renderAll();
        },
        {crossOrigin: 'Anonymous'},
    );

    // Clean up
    setCropArea();
    setIsCropping(false);
};

export const toggleClickMode = (ctx, mode) => ev => {
    const {clickMode, setClickMode, resetClickMode} = ctx;
    if (clickMode?.mode === mode) {
        resetClickMode();
    } else {
        setClickMode({mode});
    }
};

export const toggleDrawingMode = ctx => ev => {
    const {canvas, clickMode, setClickMode, resetClickMode} = ctx;
    // Disable drawing mode
    if (clickMode?.mode === 'drawing') {
        console.log('Stop drawing');
        resetClickMode();
    } else {
        console.log('Start drawing');
        setClickMode({mode: 'drawing'});
        canvas.isDrawingMode = true;
        var defaultBrush = new fabric.PencilBrush(canvas);
        defaultBrush.color = 'black';
        defaultBrush.width = 2;
        canvas.freeDrawingBrush = defaultBrush;
    }
};

export const toggleErasingMode = ctx => ev => {
    const {canvas, clickMode, setClickMode, resetClickMode} = ctx;
    // Disable drawing mode
    if (clickMode?.mode === 'erasing') {
        console.log('Stop erasing');
        resetClickMode();
    } else {
        console.log('Start erasing');
        setClickMode({mode: 'erasing'});
        canvas.isDrawingMode = true;
        var eraserBrush = new fabric.PencilBrush(canvas);
        eraserBrush.color = 'white';
        eraserBrush.width = 30;
        canvas.freeDrawingBrush = eraserBrush;
    }
};

export const save = (ctx, onSave) => ev => {
    console.log('About to save', ctx, onSave);
    const {canvas, rotatedBlob} = ctx;
    // Breaks canvas size on mobile
    canvas.enableRetinaScaling = false;
    // Reset zoom + pan
    canvas.setWidth(canvas.backgroundImage.width);
    canvas.setHeight(canvas.backgroundImage.height);
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.calcOffset();
    canvas.renderAll();
    // Deselect all objects
    canvas.discardActiveObject().renderAll();
    const cObj = canvas.toDatalessObject();
    if (!onSave) return;
    canvas.toCanvasElement().toBlob(blob => {
        onSave(blob, cObj, rotatedBlob);
    }, 'image/png');
};

export const menuDelete = ctx => event => {
    const {canvas, resetClickMode} = ctx;

    canvas.getActiveObjects().forEach(obj => {
        canvas.remove(obj);
    });
    canvas.discardActiveObject().renderAll();
    resetClickMode();
};
export const menuRaise = ctx => event => {
    const {canvas, resetClickMode} = ctx;
    const allObjects = canvas.getObjects();
    canvas.getActiveObjects().forEach(obj => {
        obj.bringForward(allObjects);
    });
    canvas.discardActiveObject().renderAll();
    resetClickMode();
};
export const menuLower = ctx => event => {
    const {canvas, resetClickMode} = ctx;
    const allObjects = canvas.getObjects();
    canvas.getActiveObjects().forEach(obj => {
        obj.sendBackwards(allObjects);
    });
    canvas.discardActiveObject().renderAll();

    resetClickMode();
};
export const menuSendFront = ctx => event => {
    const {canvas, resetClickMode} = ctx;
    const allObjects = canvas.getObjects();
    canvas.getActiveObjects().forEach(obj => {
        obj.bringToFront(allObjects);
    });
    canvas.discardActiveObject().renderAll();
    resetClickMode();
};
export const menuSendBack = ctx => event => {
    const {canvas, resetClickMode} = ctx;
    const allObjects = canvas.getObjects();
    canvas.getActiveObjects().forEach(obj => {
        obj.sendToBack(allObjects);
    });
    canvas.discardActiveObject().renderAll();
    resetClickMode();
};
export const keyDown = ctx => event => {
    const {canvas, resetClickMode} = ctx;
    const deleteKey = 8;
    if (event.keyCode === deleteKey) {
        const objs = canvas.getActiveObjects();
        canvas.remove(...objs);
    }
    resetClickMode();
};

const acceptRotationWarning = ctx => {
    const {canvas, seenRotationWarning, setSeenRotationWarning} = ctx;
    const warningText =
        'Warning: Rotating will clear all annotations. Do you want to continue?';
    const canvasHasAnnotations = canvas.getObjects().length;
    if (!seenRotationWarning && canvasHasAnnotations) {
        if (window.confirm(warningText)) {
            setSeenRotationWarning(true);
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
};
