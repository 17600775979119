import React from 'react';
import {Container} from 'react-smooth-dnd';

import {List} from '@material-ui/core';

export default function SortableList({children, dragHandleSelector, onDrop}) {
    return (
        <List>
            <Container
                lockAxis='y'
                onDrop={onDrop}
                dragHandleSelector={dragHandleSelector}
            >
                {children}
            </Container>
        </List>
    );
}
