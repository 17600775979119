import React from 'react';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';
import {useAllJsonApi} from 'ccm/dataSource/allResources';

import PortalConfig from '../PortalConfig';

import OverridesFields from './OverridesFields';

const useAllNoteTypes = () =>
    useAllJsonApi('chartpath_plugin.CPNoteTypes', 'CPNoteType');

export default function PFConfig({config, onSubmit}) {
    const noteTypes = fp.sortBy('label', useAllNoteTypes());

    if (fp.isEmpty(noteTypes) || !config) return null;
    console.log({config, noteTypes});

    return (
        <Formik enableReinitialize initialValues={config} onSubmit={onSubmit}>
            {formikProps => (
                <Form>
                    <h6>ChartPath Configuration</h6>
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Note Type'
                        name='relationships.noteType.data'
                        options={noteTypes}
                        getOptionLabel={fp.get('attributes.label')}
                    />
                    <OverridesFields
                        name='attributes.overrides'
                        noteTypes={noteTypes}
                    />
                    <Field
                        name='attributes.sftp_config.hostname'
                        label='SFTP Hostname'
                        component={TextField}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <Field
                        name='attributes.sftp_config.port'
                        label='SFTP port'
                        component={TextField}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <Field
                        name='attributes.sftp_config.username'
                        label='SFTP Username'
                        component={TextField}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <Field
                        name='attributes.sftp_config.password'
                        label='SFTP Password'
                        component={TextField}
                        type='password'
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <PortalConfig config={config} />
                    <div>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            Save Configuration
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
