import React, {useCallback, useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {Icon, IconButton, Tooltip, makeStyles} from '@material-ui/core';
import {Settings} from '@material-ui/icons';

import {useTableManager} from './Provider';
import {useRenderProps} from './utils';

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
}));

export default function DTableAction({
    children,
    icon,
    onClick,
    tooltip,
    iconProps,
    free = false,
    disabled = false,
    hidden = false,
    id = undefined,
}) {
    const tm = useTableManager();
    const classes = useStyles();
    const [_id] = useState(() => Math.random().toString());
    if (!id) id = _id;

    const renderProps = useRenderProps(
        {
            children,
            icon,
            onClick,
            tooltip,
            disabled,
            hidden,
            iconProps,
        },
        [children, icon, onClick, tooltip, disabled, hidden, iconProps],
    );
    /*
    let overrideFunction = null;
    if (children) {
        if (children.length === 1 && fp.isFunction(children[0])) {
            overrideFunction = children[0];
        } else {
            console.error(
                'Children must be a single function returning overrides',
                {children},
            );
        }
    }
    */

    const render = useCallback(
        data => {
            let props = renderProps(data);
            /*
            let renderProps = {
                icon,
                onClick,
                tooltip,
                disabled,
                hidden,
                iconProps,
            };
            if (overrideFunction) {
                renderProps = fp.merge(
                    renderProps,
                    overrideFunction(data, renderProps),
                );
            }
            */
            if (!props.icon) {
                props.icon = <Settings {...props.iconProps} />;
            }
            const _id = data && data.id ? `${data.id}-${id}` : id;
            let result = (
                <IconButton
                    id={_id}
                    disabled={props.disabled}
                    className={props.hidden ? classes.hidden : null}
                    onClick={ev => props.onClick(ev, data)}
                >
                    {fp.isString(props.icon) ? (
                        <Icon {...props.iconProps}>{icon}</Icon>
                    ) : fp.isFunction(props.icon) ? (
                        props.icon(data)
                    ) : (
                        props.icon
                    )}
                </IconButton>
            );
            if (!props.disabled && props.tooltip) {
                result = <Tooltip title={props.tooltip}>{result}</Tooltip>;
            }
            return result;
        },
        [renderProps, classes.hidden],
    );

    useEffect(() => {
        tm.setAction(id, {id, free, render});
    }, [id, free, render]); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
}
