import React, {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Link} from 'react-router-dom';

import {IconButton, Menu, MenuItem, ListItemIcon} from '@material-ui/core';
import {
    Archive,
    Assignment,
    CallSplit,
    Directions,
    Face,
    FileCopy,
    Flag,
    Label,
    Launch,
    MoreVert,
    PictureAsPdf,
    Print,
    Refresh,
    ScreenShare,
    StopScreenShare,
} from '@material-ui/icons';

import {url_for} from 'ccm/routes';
import {ducks} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';

import TicketFaxDialog from 'ccm/components/dialogs/TicketFaxDialog';
import PromptDialog from 'ccm/components/dialogs/PromptDialog';
import PatientSelectDialog from 'ccm/components/dialogs/PatientSelectDialog';
import TicketTypeDialog from 'ccm/components/dialogs/TicketTypeDialog';
import ProgressNoteUploadDialog from 'ccm/components/dialogs/ProgressNoteUploadDialog';
import TicketSplitDialog from 'ccm/components/dialogs/TicketSplitDialog';

export default function TicketMenu({ticket, actions}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const faxTicketDialog = useDialog(TicketFaxDialog);
    const promptDialog = useDialog(PromptDialog);
    const patientSelect = useDialog(PatientSelectDialog);
    const ticketTypeSelect = useDialog(TicketTypeDialog);
    const progressNoteUpload = useDialog(ProgressNoteUploadDialog);
    const ticketSplitDialog = useDialog(TicketSplitDialog);
    const rel = useSelector(ducks.jsonapi.selectRelated(ticket));

    const handleChangePatient = useCallback(async () => {
        setAnchorEl(null);
        const {action, value} = await patientSelect();
        if (action === 'select') {
            await actions.setPatient(ticket, value);
        }
    }, [actions, ticket, patientSelect]);

    const handleChangeTicketType = useCallback(async () => {
        setAnchorEl(null);
        const ticketType = await ticketTypeSelect(ticket);
        console.log({ticketType});
        if (ticketType) {
            await actions.setTicketType(ticket, ticketType);
        }
    }, [actions, ticket, ticketTypeSelect]);

    const handleToggleUrgent = useCallback(async () => {
        setAnchorEl(null);
        await actions.toggleUrgent(ticket);
    }, [actions, ticket]);

    const handleUpdateEHRSnapshotData = async ev => {
        setAnchorEl(null);
        await actions.updateEHRSnapshot(ticket);
    };

    const handleCreateProgressNote = async ev => {
        setAnchorEl(null);
        const ehrs = await progressNoteUpload(ticket);
        if (fp.isEmpty(ehrs)) return;
        await actions.createProgressNote(ticket, ehrs);
        await actions.refresh(ticket);
    };

    const handleDownloadPdf = ev => {
        setAnchorEl(null);
        window.open(ticket.links.pdf);
    };

    const handleFaxTicketMenu = async ev => {
        setAnchorEl(null);
        const fax = await faxTicketDialog(ticket);
        if (fax) {
            await actions.sendFax(ticket, fax);
            await actions.refresh(ticket);
        }
    };

    const handleRouteTicket = async ev => {
        await actions.route(ticket);
        if (fp.get('attributes.disable_auto_route', ticket)) {
            // Re-enable auto-routing if a ticket is manually routed.
            await actions.enable_auto_route(ticket);
        }
        setAnchorEl(null);
    };

    const handleEnableAutoRouting = async ev => {
        await actions.enable_auto_route(ticket);
        setAnchorEl(null);
    };

    const handleDisableAutoRouting = async ev => {
        await actions.disable_auto_route(ticket);
        setAnchorEl(null);
    };

    const handleClone = async ev => {
        setAnchorEl(null);
        const response = await promptDialog({
            title: 'Duplicate this ticket',
            defaultValue: '1',
            prompt: 'How many duplicates do you want to create?',
            inputLabel: 'Number of copies',
            submitLabel: 'Create duplicate ticket(s)',
        });
        const n = Number.parseInt(response);
        await actions.clone(ticket, n);
    };

    const handleSplitTicket = async ev => {
        setAnchorEl(null);
        const response = await ticketSplitDialog(ticket);
        console.log('Split dialog returned', response);
        if (response.action === 'split') {
            const data = {
                newTickets: fp.map(
                    t => ({
                        attachmentIds: fp.map('id', t.attachments),
                    }),
                    response.newTickets,
                ),
            };
            await actions.split(ticket, data);
        }
    };

    const handleArchive = async ev => {
        await actions.archive(ticket);
        setAnchorEl(null);
    };

    const handleUnarchive = async ev => {
        await actions.unarchive(ticket);
        setAnchorEl(null);
    };

    const hasEHR = !fp.isEmpty(
        fp.get('attributes.data.patient_identifier', ticket),
    );

    return (
        <>
            <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={ev => setAnchorEl(null)}
            >
                {rel.patient ? (
                    <MenuItem
                        component={Link}
                        to={url_for('patient', {
                            patientId: rel.patient.id,
                        })}
                    >
                        <ListItemIcon>
                            <Launch />
                        </ListItemIcon>
                        View patient portal
                    </MenuItem>
                ) : null}
                <MenuItem onClick={handleChangePatient}>
                    <ListItemIcon>
                        <Face />
                    </ListItemIcon>
                    Assign / change patient
                </MenuItem>
                <MenuItem onClick={handleChangeTicketType}>
                    <ListItemIcon>
                        <Label />
                    </ListItemIcon>
                    Change Ticket Type
                </MenuItem>
                <MenuItem onClick={handleToggleUrgent}>
                    <ListItemIcon>
                        <Flag />
                    </ListItemIcon>
                    Toggle "Urgent" Status
                </MenuItem>
                {hasEHR && (
                    <MenuItem
                        key='ehr_refresh'
                        onClick={handleUpdateEHRSnapshotData}
                    >
                        <ListItemIcon>
                            <Refresh />
                        </ListItemIcon>
                        Update data from EHR
                    </MenuItem>
                )}
                <MenuItem key='pcc_pn' onClick={handleCreateProgressNote}>
                    <ListItemIcon>
                        <Assignment />
                    </ListItemIcon>
                    Upload to EHR / Create Progress Note
                </MenuItem>
                <MenuItem onClick={handleDownloadPdf}>
                    <ListItemIcon>
                        <PictureAsPdf />
                    </ListItemIcon>
                    Download PDF
                </MenuItem>
                <MenuItem onClick={handleFaxTicketMenu}>
                    <ListItemIcon>
                        <Print />
                    </ListItemIcon>
                    Fax Ticket
                </MenuItem>
                <MenuItem onClick={handleRouteTicket}>
                    <ListItemIcon>
                        <Directions />
                    </ListItemIcon>
                    Re-route ticket
                </MenuItem>
                {ticket.attributes.disable_auto_route ? (
                    <MenuItem onClick={handleEnableAutoRouting}>
                        <ListItemIcon>
                            <ScreenShare />
                        </ListItemIcon>
                        Enable automatic routing
                    </MenuItem>
                ) : (
                    <MenuItem onClick={handleDisableAutoRouting}>
                        <ListItemIcon>
                            <StopScreenShare />
                        </ListItemIcon>
                        Disable automatic routing
                    </MenuItem>
                )}

                <MenuItem onClick={handleClone}>
                    <ListItemIcon>
                        <FileCopy />
                    </ListItemIcon>
                    Duplicate this ticket
                </MenuItem>
                <MenuItem onClick={handleSplitTicket}>
                    <ListItemIcon>
                        <CallSplit />
                    </ListItemIcon>
                    Split this ticket
                </MenuItem>
                {ticket.attributes.archived ? (
                    <MenuItem onClick={handleUnarchive}>
                        <ListItemIcon>
                            <Archive />
                        </ListItemIcon>
                        Unarchive this ticket
                    </MenuItem>
                ) : (
                    <MenuItem onClick={handleArchive}>
                        <ListItemIcon>
                            <Archive />
                        </ListItemIcon>
                        Archive this ticket
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
