import React, {useRef} from 'react';
import fp from 'lodash/fp';

import {
    Card,
    CardMedia,
    CardActions,
    CardActionArea,
    Button,
    makeStyles,
} from '@material-ui/core';
import {CloudUpload} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        width: theme.spacing(39),
        margin: theme.spacing(1),
    },
    hidden: {
        display: 'none',
    },
    imageContainer: {
        height: '10em',
    },
    imageStyles: {
        height: 'auto',
        padding: theme.spacing(1),
    },
}));

export default function PracticeLogo({practice}) {
    const api = useApi();
    const classes = useStyles();
    const inputRef = useRef();

    const handleAdd = async (ev) => {
        let file = ev.currentTarget.files[0];
        let body = new FormData();
        body.append('file', file);
        await api.fetchJson(
            fp.get('relationships.logo.links.related', practice),
            {
                method: 'POST',
                body,
            }
        );
        inputRef.current.value = null;
    };

    const handleClick = (ev) => {
        inputRef.current.click();
    };

    const canEdit = fp.includes(
        'Common.WRITE',
        fp.get('meta.allowed_actions', practice)
    );

    return (
        <div>
            <Card className={classes.cardStyle}>
                <CardActionArea className={classes.imageContainer}>
                    <CardMedia
                        className={classes.imageStyles}
                        component="img"
                        image={fp.get('links.logo_url', practice)}
                    />
                </CardActionArea>
                {canEdit && (
                    <CardActions>
                        <Button onClick={handleClick}>
                            <CloudUpload />
                        </Button>
                        <input
                            type="file"
                            ref={inputRef}
                            onChange={handleAdd}
                            className={classes.hidden}
                            accept="image/png, image/jpeg"
                        />
                    </CardActions>
                )}
            </Card>
        </div>
    );
}
