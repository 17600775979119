import React from 'react';
import fp from 'lodash/fp';

import {Button} from '@material-ui/core';
import {AssignmentInd} from '@material-ui/icons';

import {useDialog} from 'ccm/components/dialogs';

import ResolveWizardDialog from './ResolveWizardDialog';

export default function ResolveWizard({ticket, actions}) {
    const wizardDialog = useDialog(ResolveWizardDialog);
    const hasSignatureRequest = !!fp.get(
        'relationships.signature_form.data',
        ticket,
    );
    const handleOpen = async () => {
        await wizardDialog(ticket.id, actions);
    };
    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={handleOpen}
                startIcon={<AssignmentInd />}
            >
                {hasSignatureRequest && 'Sign &'} Resolve Ticket
            </Button>
        </>
    );
}
