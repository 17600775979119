import React, {useState} from 'react';
import moment from 'moment';

import fp from 'lodash/fp';
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {strWithNewline, useFeatures} from 'ccm/lib/util';

import ObservationTable from 'ccm/components/InfoDashboard/ObservationTable';
import ConditionTable from 'ccm/components/InfoDashboard/ConditionTable';
import MedicationTable from 'ccm/components/InfoDashboard/MedicationTable';
import AllergyTable from 'ccm/components/InfoDashboard/AllergyTable';
import ImmunizationTable from 'ccm/components/InfoDashboard/ImmunizationTable';
import DiagnosticReportTable from 'ccm/components/InfoDashboard/DiagnosticReportTable';
import ProgressNotesTable from 'ccm/components/InfoDashboard/ProgressNotesTable';
import NutritionTable from 'ccm/components/InfoDashboard/NutritionTable';
import AssessmentsTable from 'ccm/components/InfoDashboard/AssessmentsTable';

const useStyles = makeStyles(theme => ({
    textArea: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        width: '100%',
        padding: 25,
        border: 'none',
    },
    buttonArea: {
        textAlign: 'center',
    },
    button: {
        margin: 10,
    },
}));

function PatientDataPanel({rel, title, expanded, handleExpand, children}) {
    return (
        <Accordion expanded={expanded === rel} onChange={handleExpand(rel)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${rel}-panel-content`}
                id={`${rel}-panel-header`}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {expanded === rel ? children : null}
            </AccordionDetails>
        </Accordion>
    );
}

export default function PCCPatientBrowser({ticket, patient, actions, classes}) {
    classes = {...useStyles(), ...classes};
    const [expanded, setExpanded] = useState('');
    const features = useFeatures();

    const handleExpand = panel => async (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        let reviewedMsg = `Provider reviewed PointClickCare ${panel}`;
        if (
            isExpanded &&
            !(ticket.attributes.data.notes || '').includes(reviewedMsg)
        ) {
            let nowStr = moment().format('YYYY-MM-DD hh:mm a');
            addToTicket(`${nowStr}: ${reviewedMsg}`);
        }
    };

    const addToTicket = data => {
        console.log('Add data to ticket', data, ticket);
        let notes = strWithNewline(ticket.attributes.data.notes || '');
        notes += data;
        return actions.patch(ticket, {data: {notes}});
    };

    if (!patient) return null;
    return (
        <div>
            <PatientDataPanel
                rel='observations'
                title='Vitals'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <ObservationTable
                    options={{search: true}}
                    patient={patient}
                    title='Vitals/Observations'
                />
            </PatientDataPanel>

            <PatientDataPanel
                rel='medications'
                title='Medications'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <MedicationTable
                    options={{search: true}}
                    patient={patient}
                    title='Medications'
                />
            </PatientDataPanel>

            <PatientDataPanel
                rel='conditions'
                title='Conditions'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <ConditionTable
                    options={{search: true}}
                    patient={patient}
                    title='Conditions'
                />
            </PatientDataPanel>

            <PatientDataPanel
                rel='allergies'
                title='Allergies'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <AllergyTable
                    options={{search: true}}
                    patient={patient}
                    title='Allergies'
                />
            </PatientDataPanel>
            <PatientDataPanel
                rel='nutrition_orders'
                title='Nutrition'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <NutritionTable
                    options={{search: true}}
                    patient={patient}
                    title='Nutrition'
                />
            </PatientDataPanel>

            <PatientDataPanel
                rel='labs'
                title='Labs & Radiology'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <DiagnosticReportTable
                    options={{search: true}}
                    patient={patient}
                    title='Labs & Radiology'
                />
            </PatientDataPanel>

            <PatientDataPanel
                rel='immunizations'
                title='Immunizations'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <ImmunizationTable
                    options={{search: true}}
                    patient={patient}
                    title='Immunizations'
                />
            </PatientDataPanel>

            <PatientDataPanel
                rel='progress_notes'
                title='Progress Notes'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <ProgressNotesTable
                    options={{search: true}}
                    unfiltered={true}
                    patient={patient}
                    title='Progress Notes'
                />
            </PatientDataPanel>

            {fp.get('enable_assessments', features) && (
                <PatientDataPanel
                    rel='assessments'
                    title='Assessments'
                    expanded={expanded}
                    handleExpand={handleExpand}
                >
                    <AssessmentsTable
                        options={{search: false}}
                        patient={patient}
                        title='Assessments'
                    />
                </PatientDataPanel>
            )}
        </div>
    );
}
