import React, {useMemo} from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';
import {useRelated} from 'ccm/dataSource';
import * as h from 'ccm/lib/helpers';
import AutocompleteDialog from 'ccm/components/dialogs/AutocompleteDialog';
import {useProviders} from './hooks';
import LinkSelect from './LinkSelect';

const {dt2} = components;

const PFPROVIDER_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.data.name', direction: 'asc'},
    page: {number: 0, size: 20},
};

export default function ProviderMapping({ehr, config, practice}) {
    const ds = useRelated(config, 'providers', {
        prefix: 'pfprovider.',
        initialFetchOptions: PFPROVIDER_DEFAULT_FETCH_OPTIONS,
    });
    const users = useProviders(practice);
    const lookupUser = useMemo(
        () =>
            fp.pipe([fp.map(u => [u.id, h.userLabel(u)]), fp.fromPairs])(users),
        [users],
    );
    const dialogProps = useMemo(
        () => ({
            title: 'Select SamePage Provider',
            label: 'User',
            options: users,
            getOptionLabel: h.userLabel,
        }),
        [users],
    );

    const dialog = useDialog(AutocompleteDialog, dialogProps);

    const getSelection = async () => {
        const {action, value} = await dialog();
        return {action, spData: value};
    };
    const getLabel = userId => fp.get(userId, lookupUser);
    const refresh = ev => ds.fetch();
    return (
        <div>
            <dt2.DataTable dataSource={ds}>
                <dt2.Column
                    title='PF Name'
                    field='attributes.data.providerName'
                />
                <dt2.Column
                    title='SP User'
                    filtering={false}
                    render={row => (
                        <LinkSelect
                            pfData={row.data}
                            linkType='user'
                            getSelection={getSelection}
                            getLabel={getLabel}
                        />
                    )}
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </dt2.DataTable>
        </div>
    );
}
