import React from 'react';
import {Link} from 'react-router-dom';

import {Button, makeStyles} from '@material-ui/core';

import {url_for} from 'ccm/routes';

const useStyles = makeStyles(theme => ({
    button: {
        display: 'flex',
        // justifyContent: 'center',
        backgroundColor: 'white',
        paddingLeft: theme.spacing(4),
        '& h6': {
            flex: 3,
            textAlign: 'left',
            color: props => theme.palette.cf[props.color],
        },
    },
    iconContainer: {
        flex: 1,
    },
}));

export default function InfoCard({
    title,
    color,
    children,
    Avatar,
    patient,
    type,
    ...props
}) {
    const classes = useStyles({color});
    if (!patient) return null;
    return (
        <Button
            fullWidth
            size='large'
            variant='outlined'
            className={classes.button}
            component={Link}
            to={url_for('patientInfo', {patientId: patient.id, type})}
            {...props}
        >
            <div className={classes.iconContainer}>
                <Avatar color={color} />
            </div>
            <h6>{title}</h6>
        </Button>
    );
}
