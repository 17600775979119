import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {AssignmentInd} from '@material-ui/icons';

import SignatureForm, {useFormState} from 'ccm/components/SignatureForm';

export default function SignatureRequestEditDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [ticketActions, setTicketActions] = useState(null);
    const [isEditing, setIsEditing] = useState(null);
    const [title, setTitle] = useState('Sign Ticket?');
    const formState = useFormState(ticket, ticketActions);

    const resetForm = formState.reset;
    useEffect(() => resetForm(), [resetForm]);

    const handleSubmit = async () => {
        await formState.save();
        resolve(true);
        setResolve(null);
        setTicket(null);
        setTicketActions(null);
    };

    const handleSave = async () => {
        await formState.save();
        resolve(null);
        setResolve(null);
        setTicket(null);
        setTicketActions(null);
    };

    const handleCancel = async () => {
        await formState.reset();
        resolve(null);
        setResolve(null);
        setTicket(null);
        setTicketActions(null);
    };

    dialogRef.current = (tkt, tktActions, isEditing) =>
        new Promise((resolve, reject) => {
            setIsEditing(isEditing);
            setTicket(tkt);
            setTitle(isEditing ? 'Signature Request' : 'Sign Ticket?');
            setTicketActions(tktActions);
            setResolve(() => resolve);
        });

    return (
        <>
            <Dialog
                open={!!resolve}
                onClose={handleCancel}
                scroll='paper'
                fullScreen
                disableEnforceFocus={true}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent dividers>
                    <SignatureForm
                        formState={formState}
                        isEditing={isEditing}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                        onClick={handleSave}
                        variant='contained'
                        color={isEditing ? 'primary' : 'default'}
                    >
                        Save
                    </Button>
                    {isEditing ? null : (
                        <Button
                            onClick={handleSubmit}
                            startIcon={<AssignmentInd />}
                            name='submit'
                            value='button1'
                            disabled={!formState.complete}
                            variant='contained'
                            color='primary'
                        >
                            Resolve Ticket
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
