import React, {useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';

import {Paper, Button, makeStyles} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import BasePatientPage from 'ccm/components/BasePatientPage';

import INFO_CONFIG from 'ccm/components/InfoDashboard/config';

const useStyles = makeStyles(theme => ({
    hform: {
        display: 'flex',
        justifyItems: 'center',
    },
    field: {
        margin: theme.spacing(0, 1),
    },
    paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
    },
}));

export default function InfoPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId, type} = useParams();
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );
    const infoConfig = fp.find(i => i.key === type, INFO_CONFIG);
    console.log('INfoPage', {type, infoConfig, INFO_CONFIG});

    useEffect(() => {
        let url = api.url_for('patient.Patient', {patient_id: patientId});
        api.fetchJsonApi(url, {include: ['facility']});
        // , 'photo']});
    }, [api, patientId]);
    if (!infoConfig) return null;

    const Component = infoConfig.table;
    const title = infoConfig.title;

    return (
        <BasePatientPage title={title} patient={patient}>
            <Paper className={classes.paper}>
                <Button
                    component={Link}
                    to={url_for('patient', {patientId: patientId})}
                    startIcon={<ArrowBack />}
                >
                    Return to Dashboard
                </Button>
                <Component patient={patient} title={title} />
            </Paper>
        </BasePatientPage>
    );
}
