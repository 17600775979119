import {useState, useCallback, useEffect, useMemo} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {parseISO, subMonths} from 'date-fns';

import {useApi, components} from '@arborian/narrf';

const {useDataSource} = components;

const FETCH_OPTIONS = {
    page: {number: 0, size: 1000},
};

function useFilter() {
    const location = useLocation();
    return useMemo(() => {
        const q = new URLSearchParams(location.search);
        let begin = q.get('begin');
        let end = q.get('end');
        let codes = q.get('codes');
        end = end ? parseISO(end) : new Date();
        begin = begin ? parseISO(begin) : subMonths(end, 3);
        codes = codes ? codes.split(',') : [];
        return {begin, end, codes};
    }, [location]);
}

export default function useFlowsheet(patient) {
    const api = useApi();
    const location = useLocation();
    const filter = useFilter();
    const history = useHistory();
    const [dates, setDates] = useState();
    const [codes, setCodes] = useState();

    const setFilter = useCallback(
        value => {
            let searchParams = new URLSearchParams(location.search);
            searchParams.set('begin', value.begin.toISOString());
            searchParams.set('end', value.end.toISOString());
            const codes = fp.pipe(
                fp.map('code_value'),
                fp.join(','),
            )(value.codes);
            searchParams.set('codes', codes);
            history.push({search: searchParams.toString()});
        },
        [location, history],
    );

    const fetchItems = useCallback(
        async _fetchOptions => {
            const url = fp.get(
                'relationships.flowsheet.links.related',
                patient,
            );
            const resp = await api.fetchJsonApi(url, {
                filter: {
                    begin: filter.begin.toISOString(),
                    end: filter.end.toISOString(),
                    codes: filter.codes ? filter.codes.join(',') : '',
                },
            });
            if (!resp) {
                return {rows: [], count: 0};
            }
            let items = fp.pipe(
                fp.zip(resp.data.attributes.codes),
                fp.map(([code, item]) => ({
                    id: code.code_value,
                    ...code,
                    ...item,
                })),
            )(resp.data.attributes.data);
            setDates(resp.data.attributes.dates);
            setCodes(resp.data.attributes.codes);
            return {
                rows: items,
                count: items.length,
            };
        },
        [api, filter, patient],
    );

    const ds = useDataSource({data: fetchItems, fetchOptions: FETCH_OPTIONS});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(ds.fetch, [filter]);

    return {
        ...ds,
        dates,
        codes,
        filter,
        setFilter,
    };
}
