import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Card,
    CardHeader,
    CardContent,
    FormGroup,
    FormControl,
    FormLabel,
    makeStyles,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Field} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';

import {selectTicketPatients} from 'ccm/lib/selectors-ccm';

const useStyles = makeStyles(theme => ({
    root: {
        '& fieldset': {
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 4,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            '& legend': {
                padding: theme.spacing(0, 1),
            },
        },
    },
}));

function UnmappedAlert({ehrConnection, plural}) {
    const ehrLabel = fp.get('attributes.label', ehrConnection);
    return (
        <Alert severity='warning'>
            <strong>Unmapped Patient Warning</strong> <br />
            No mapping exists for
            {plural
                ? " one or more ticket's patient "
                : " this ticket's patient "}
            in <em>{ehrLabel}</em>, so the note can be uploaded but will not be
            linked to a particular patient chart.
        </Alert>
    );
}

export default function ProgressNoteUploadFields({ehrConnections, ticket}) {
    const classes = useStyles();
    const patients = useSelector(selectTicketPatients(ticket));
    const ehrConnectionsWithStatus = useMemo(
        () =>
            fp.map(ehrConnection => {
                const isMapped = !!fp.find(
                    pt =>
                        fp.get('relationships.ehr_connection.data.id', pt) ===
                        ehrConnection.id,
                    patients,
                );
                return {
                    ehrConnection,
                    isMapped,
                    canUploadUnmapped: fp.get(
                        'attributes.canUploadUnmapped',
                        ehrConnection,
                    ),
                    canUploadTicketPdf: fp.get(
                        'attributes.canUploadTicketPdf',
                        ehrConnection,
                    ),
                    canUploadProgress: fp.get(
                        'attributes.canUploadProgress',
                        ehrConnection,
                    ),
                };
            }, ehrConnections),
        [ehrConnections, patients],
    );
    return (
        <Card className={classes.root}>
            <CardHeader title='Uploads' />
            <CardContent>
                <FormGroup>
                    {fp.map(
                        ({
                            ehrConnection,
                            isMapped,
                            canUploadUnmapped,
                            canUploadTicketPdf,
                            canUploadProgress,
                        }) =>
                            isMapped || canUploadUnmapped ? (
                                <FormControl component='fieldset'>
                                    <FormLabel component='legend'>
                                        {fp.get(
                                            'attributes.label',
                                            ehrConnection,
                                        )}
                                    </FormLabel>
                                    {canUploadProgress && (
                                        <Field
                                            component={CheckboxWithLabel}
                                            type='checkbox'
                                            size='small'
                                            name={
                                                'upload.progressNoteEHRs.' +
                                                fp.get('id', ehrConnection)
                                            }
                                            Label={{
                                                label: 'Progress Note',
                                            }}
                                        />
                                    )}
                                    {canUploadTicketPdf && (
                                        <Field
                                            component={CheckboxWithLabel}
                                            type='checkbox'
                                            size='small'
                                            name={
                                                'upload.documentUploadEHRs.' +
                                                fp.get('id', ehrConnection)
                                            }
                                            Label={{label: 'PDF Document'}}
                                        />
                                    )}
                                    {!isMapped && (
                                        <UnmappedAlert
                                            ehrConnection={ehrConnection}
                                            patients={patients}
                                            plural={
                                                fp.isArray(ticket) &&
                                                ticket.length > 1
                                            }
                                        />
                                    )}
                                </FormControl>
                            ) : null,
                        ehrConnectionsWithStatus,
                    )}
                    <Field
                        component={CheckboxWithLabel}
                        type='checkbox'
                        size='small'
                        name='upload.patientFilesUpload'
                        Label={{
                            label: "Upload to Patient's SamePageMD Files",
                        }}
                    />
                </FormGroup>
            </CardContent>
        </Card>
    );
}
