import React, {useEffect, useRef} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Add, Delete, Edit} from '@material-ui/icons';

import {ducks, useApi} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import SignatureImageEditorDialog from 'ccm/components/dialogs/SignatureImageEditorDialog';

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
}));

/*
const SIGNATURE_CONTROLS = new ControlFlags({
    trash: false,
    brush: false,
    eraser: false,
    text: false,
    date: false,
    check: false,
    signature: false,
    rotate: true,
    crop: true,
});
*/

export default function SignatureCard() {
    const classes = useStyles();
    const api = useApi();
    const user = useSelector(selectCurrentUser);
    const dialog = useDialog(SignatureImageEditorDialog);
    const inputRef = useRef();
    const signatureRel = fp.get('relationships.signature_blob', user);
    const processedSignatureRel = fp.get(
        'relationships.signature_blob_processed',
        user,
    );
    const signatureBlob = useSelector(
        ducks.jsonapi.selectObject([
            fp.get('data.type', signatureRel),
            fp.get('data.id', signatureRel),
        ]),
    );
    const processedSignatureBlob = useSelector(
        ducks.jsonapi.selectObject([
            fp.get('data.type', processedSignatureRel),
            fp.get('data.id', processedSignatureRel),
        ]),
    );
    useEffect(() => {
        if (fp.get('data', signatureRel)) {
            api.fetchJsonApi(fp.get('links.related', signatureRel));
        }
    }, [api, signatureRel]);

    useEffect(() => {
        if (fp.get('data', processedSignatureRel)) {
            api.fetchJsonApi(fp.get('links.related', processedSignatureRel));
        }
    }, [api, processedSignatureRel]);

    const handleClick = ev => {
        inputRef.current.click();
    };

    const handleAdd = async ev => {
        let file = ev.currentTarget.files[0];
        let body = new FormData();
        body.append('file', file);
        await api.fetchJsonApi(
            fp.get('relationships.signature_blob.links.related', user),
            {method: 'PUT', body},
        );
        inputRef.current.value = null;
        await api.fetchJson(fp.get('links.self', user));
    };

    const handleDelete = async ev => {
        await api.fetchJsonApi(fp.get('links.related', signatureRel), {
            method: 'DELETE',
        });
        await api.fetchJson(fp.get('links.self', user));
    };

    const handleEdit = async ev => {
        const {action, ...result} = await dialog(original_content_href);
        console.log('Edited and got', action, result);
        if (action === 'CANCEL') return;
        else if (action === 'SAVE_CLOSE') {
            let body = new FormData();
            body.append('file', result.blob);

            await api.fetchJsonApi(fp.get('links.related', signatureRel), {
                method: 'PUT',
                body,
            });
            await api.fetchJson(fp.get('links.self', user));
        }
    };

    const original_content_href = fp.get(
        'relationships.content.links.related',
        signatureBlob,
    );
    const processed_content_href = fp.get(
        'relationships.content.links.related',
        processedSignatureBlob,
    );

    if (!user) return null;
    return (
        <Card>
            <CardHeader title='My Signature' />
            <CardContent>
                <input
                    id='signatureInput'
                    type='file'
                    ref={inputRef}
                    onChange={handleAdd}
                    className={classes.hidden}
                    accept='image/png, image/jpeg'
                />
                {signatureBlob ? (
                    <>
                        <IconButton onClick={handleDelete}>
                            <Delete />
                        </IconButton>
                        <IconButton onClick={handleEdit}>
                            <Edit />
                        </IconButton>
                        <img src={original_content_href} alt='signature box' />
                        <fieldset>
                            <legend>Processed Signature</legend>
                            <img
                                src={processed_content_href}
                                alt='processed signature preview'
                            />
                        </fieldset>
                    </>
                ) : (
                    <IconButton onClick={handleClick}>
                        <Add />
                    </IconButton>
                )}
            </CardContent>
        </Card>
    );
}
