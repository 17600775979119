import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Close} from '@material-ui/icons';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';

import useStyles from './styles';
import {selectUnsignedAttachmentBlob} from './selectors';

export default function AttachmentZoomDialog({dialogRef}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const [attachment, setAttachment] = useState(null);

    dialogRef.current = attachment => {
        return new Promise((resolve, reject) => {
            setAttachment(attachment);
            setResolve(() => resolve);
        });
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    const blob = useSelector(
        useMemo(
            () => selectUnsignedAttachmentBlob(fp.get('id', attachment)),
            [attachment],
        ),
    );

    return (
        <Dialog open={!!resolve} onClose={handleCancel} maxWidth='md' fullWidth>
            <DialogTitle>
                {fp.get('attributes.filename', attachment)}
                <IconButton
                    aria-label='close'
                    className={classes.dialogCloseButton}
                    onClick={handleCancel}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <img
                    className={classes.dialogImg}
                    src={fp.get('relationships.content.links.related', blob)}
                    alt={fp.get('attributes.filename', attachment)}
                    title={fp.get('attributes.filename', attachment)}
                />
            </DialogContent>
        </Dialog>
    );
}
