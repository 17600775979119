import React from 'react';
import fp from 'lodash/fp';

import {AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {AccordionProvider, Accordion} from 'ccm/components/TabPanels';

import EHRConfig from 'ccm/components/EHRConfig';
import PFSignLab from './SignLab';
import PFNotePrep from './NotePrep';
import PFOnboardPatients from './PFOnboardPatients';
import {FacilityMapping, ProviderMapping, VisitTypeMapping} from './Mapping';
import {useConfig} from './hooks';

export default function PFEhrPanel({practice, ehr, isAdmin}) {
    const config = useConfig(ehr);

    const canConfigure = fp.includes('Common.WRITE', ehr.meta.allowed_actions);
    const canSign = fp.includes('Practice.SIGN', ehr.meta.allowed_actions);
    const canNotePrep = fp.includes(
        'Practice.NOTE_PREP',
        practice.meta.allowed_actions,
    );

    return (
        <AccordionProvider queryKey='ehrTab'>
            {canConfigure && (
                <>
                    <Accordion index='config'>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h5>EHR Configuration</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EHRConfig owner={practice} ehr={ehr} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion index='patient'>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h5>Onboard Patients</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PFOnboardPatients ehr={ehr} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion index='facility'>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h5>Map Facilities</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FacilityMapping ehr={ehr} config={config} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion index='provider'>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h5>Map Providers</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProviderMapping
                                ehr={ehr}
                                config={config}
                                practice={practice}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion index='visitTypes'>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h5>Map Visit Types</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <VisitTypeMapping
                                ehr={ehr}
                                config={config}
                                practice={practice}
                            />
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
            {canSign && (
                <Accordion index='signLabs'>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <h5>Sign Pending Labs</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PFSignLab practice={practice} ehr={ehr} />
                    </AccordionDetails>
                </Accordion>
            )}
            {canNotePrep && (
                <Accordion index='notePrep'>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <h5>Note Preparation</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PFNotePrep practice={practice} ehr={ehr} />
                    </AccordionDetails>
                </Accordion>
            )}
        </AccordionProvider>
    );
}
