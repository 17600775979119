import React from 'react';
import {ReactSVG} from 'react-svg';

import {Avatar as MUIAvatar, makeStyles} from '@material-ui/core';

import {Restaurant} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    icon: {
        width: 32,
        height: 32,
        '& path': {
            fill: props => theme.palette.cf[props.color],
        },
        '& circle': {
            fill: props => theme.palette.cf[props.color],
        },
    },
    avatar: {
        backgroundColor: props => theme.palette.cf[props.color] + '20',
    },
}));

export const Avatar = ({src, ...props}) => {
    const classes = useStyles(props);
    return (
        <MUIAvatar variant='rounded' className={classes.avatar} {...props}>
            <ReactSVG src={src} className={classes.icon} {...props} />
        </MUIAvatar>
    );
};

export const NutritionAvatar = props => {
    const classes = useStyles(props);
    return (
        <MUIAvatar variant='rounded' className={classes.avatar} {...props}>
            <Restaurant className={classes.icon} {...props} />
        </MUIAvatar>
    );
};

export const ForbiddenAvatar = props => (
    <Avatar src='/svg/freepik/forbidden.svg' {...props} />
);

export const FlashAvatar = props => (
    <Avatar src='/svg/smashicons/flash.svg' {...props} />
);

export const ThermometerAvatar = props => (
    <Avatar src='/svg/smashicons/medical/thermometer.svg' {...props} />
);

export const HeartRateAvatar = props => (
    <Avatar src='/svg/smashicons/medical-filled/cardiogram.svg' {...props} />
);

export const WindAvatar = props => (
    <Avatar src='/svg/freepik/weather/020-wind-2.svg' {...props} />
);

export const LabsAvatar = props => (
    <Avatar src='/svg/freepik/testing-tube.svg' {...props} />
);

export const RadiologyAvatar = props => (
    <Avatar src='/svg/freepik/x-ray.svg' {...props} />
);

export const HeightAvatar = props => (
    <Avatar src='/svg/freepik/height.svg' {...props} />
);

export const WeightAvatar = props => (
    <Avatar src='/svg/freepik/weight-scale.svg' {...props} />
);

export const SyringeAvatar = props => (
    <Avatar src='/svg/smashicons/medical-filled/syringe.svg' {...props} />
);

export const PillAvatar = props => (
    <Avatar src='/svg/smashicons/medical-filled/pill.svg' {...props} />
);

export const DropAvatar = props => (
    <Avatar src='/svg/freepik/drop.svg' {...props} />
);

export const NotepadAvatar = props => (
    <Avatar src='/svg/smashicons/medical-filled/notepad.svg' {...props} />
);

export const StethoscopeAvatar = props => (
    <Avatar src='/svg/smashicons/medical-filled/stethoscope.svg' {...props} />
);

export const TeamAvatar = props => (
    <Avatar src='/svg/smashicons/team.svg' {...props} />
);
