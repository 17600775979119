import React from 'react';

import {AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {AccordionProvider, Accordion} from 'ccm/components/TabPanels';
import InvitationAdminTable from 'ccm/components/InvitationAdminTable';
import GroupMemberTable from 'ccm/components/GroupMemberTable';

export default function UsersPanel({practice}) {
    return (
        <AccordionProvider queryKey='userTab'>
            <Accordion index='members'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Practice Users</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <GroupMemberTable protectedResource={practice} />
                </AccordionDetails>
            </Accordion>
            <Accordion index='invitations'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <h5>Practice Invitations</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <InvitationAdminTable protectedResource={practice} />;
                </AccordionDetails>
            </Accordion>
        </AccordionProvider>
    );
}
