import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {DragHandle, Delete} from '@material-ui/icons';
import {
    IconButton,
    ListItemIcon,
    ListItemSecondaryAction,
} from '@material-ui/core';

import useStyles from './styles';
import {useDialog} from 'ccm/components/dialogs';
import {selectUnsignedAttachmentBlob} from './selectors';
import AttachmentZoomDialog from './AttachmentThumbnailDialog';

export default function AttachmentThumbnail({
    attachment,
    onDelete,
    onDragStart,
    onDragOver,
    onDrop,
}) {
    const classes = useStyles();
    const openZoomDialog = useDialog(AttachmentZoomDialog);
    const handleDragStart = ev => {
        onDragStart(ev, attachment);
    };
    const handleDelete = ev => {
        onDelete(ev, attachment);
    };
    const blob = useSelector(
        useMemo(
            () => selectUnsignedAttachmentBlob(attachment.id),
            [attachment],
        ),
    );
    const handleZoom = () => {
        openZoomDialog(attachment);
    };
    return (
        <div
            draggable={!!onDragStart}
            onDragStart={handleDragStart}
            className={classes.item}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onClick={handleZoom}
        >
            <div className={classes.itemHeader}>
                {onDragStart && (
                    <ListItemIcon>
                        <DragHandle />
                    </ListItemIcon>
                )}
                {onDelete && (
                    <ListItemSecondaryAction>
                        <IconButton onClick={handleDelete}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </div>
            <div className={classes.itemThumbnail}>
                <img
                    className={classes.thumbnail}
                    src={fp.get('relationships.content.links.related', blob)}
                    alt={fp.get('attributes.filename', attachment)}
                    title={fp.get('attributes.filename', attachment)}
                />
            </div>
        </div>
    );
}
