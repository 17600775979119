import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form} from 'formik';

import ProgressNoteUploadFields from 'ccm/components/ProgressNoteUploadFields';
import {selectEHRConnections} from 'ccm/lib/selectors-ccm';

export default function ProgressNoteUploadDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ticket, setTicket] = useState(null);
    const ehrConnections = useSelector(selectEHRConnections(ticket));

    const handleSubmit = values => {
        const progressNoteEHRs = fp.pipe([
            fp.get('upload.progressNoteEHRs'),
            fp.toPairs,
            fp.filter(([k, v]) => v),
            fp.map(([k, v]) => k),
        ])(values);
        const documentUploadEHRs = fp.pipe([
            fp.get('upload.documentUploadEHRs'),
            fp.toPairs,
            fp.filter(([k, v]) => v),
            fp.map(([k, v]) => k),
        ])(values);
        const resolution = {
            progressNoteEHRs,
            documentUploadEHRs,
            patientFilesUpload: values.upload.patientFilesUpload,
        };
        resolve(resolution);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    const initialValues = useMemo(() => {
        const progressNoteEHRs = fp.pipe([
            fp.map(conn => [conn.id, conn.attributes.canUploadProgress]),
            fp.fromPairs,
        ])(ehrConnections);
        const documentUploadEHRs = fp.pipe([
            fp.map(conn => [conn.id, conn.attributes.canUploadTicketPdf]),
            fp.fromPairs,
        ])(ehrConnections);
        return {
            upload: {
                progressNoteEHRs,
                documentUploadEHRs,
                patientFilesUpload: false,
            },
        };
    }, [ehrConnections]);

    dialogRef.current = tkt => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
            setTicket(tkt);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>Upload Progress Note(s)</DialogTitle>
                        <DialogContent>
                            <p>
                                Upload a progress note for this ticket to the
                                following EHR(s):
                            </p>
                            <ProgressNoteUploadFields
                                ehrConnections={ehrConnections}
                                ticket={ticket}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Upload Progress Note(s)
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
