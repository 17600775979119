import {createAction, createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

export const NAME = 'narrf.auth';

export const USERINFO = NAME + '/USERINFO';
export const LOGIN = NAME + '/LOGIN';
export const LOGOUT = NAME + '/LOGOUT';

// action creators
export const userinfo = createAction(USERINFO);
export const login = createAction(LOGIN);
export const logout = createAction(LOGOUT);

// selectors
export const selectAuthInfo = fp.get(NAME);
export const selectUserinfo = createSelector(
    selectAuthInfo,
    fp.get('userinfo'),
);
export const selectAccessToken = createSelector(
    selectAuthInfo,
    fp.get('accessToken'),
);

const initialState = {
    accessToken: null,
    userinfo: null,
};

export default function authReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case LOGIN:
            return fp.set(`accessToken`, payload, state);
        case LOGOUT:
            return initialState;
        case USERINFO:
            return fp.set('userinfo', payload, state);
        default:
            return state;
    }
}
