import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    MenuItem,
    IconButton,
    Button,
    makeStyles,
} from '@material-ui/core';
import {Edit} from '@material-ui/icons';

import {useApi, ducks, components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useRelated} from 'ccm/dataSource';
import {selectPatientArtifacts} from 'ccm/lib/selectors';
import CarePlanTemplateTable from 'ccm/components/CarePlanTemplateTable';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    root: {
        '& h6': {
            ...theme.typography.h6,
            margin: 0,
        },
    },
}));

function conditionLabel(cond) {
    const text = fp.get('attributes.code.text', cond);
    const codes = fp.map(c => c.code, fp.get('attributes.code.coding', cond));
    // console.log('conditionLabel', {cond, text, codes});
    if (fp.isEmpty(codes)) {
        return text;
    } else {
        return text + '(' + codes.join(', ') + ')';
    }
}

function ConditionSelector({patient, value, onChange}) {
    const api = useApi();
    const [anchorEl, setAnchorEl] = useState(null);
    const options = useSelector(
        selectPatientArtifacts(patient.id, 'Condition'),
    );
    useEffect(() => {
        api.fetchAllJsonApi(
            fp.get('relationships.conditions.links.related', patient),
        );
    }, [api, patient]);

    const handleSelect = item => {
        onChange(item);
        setAnchorEl(null);
    };

    return (
        <div>
            <strong>Condition</strong>:{' '}
            {value ? conditionLabel(value) : 'Select a condition'}
            <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
                <Edit />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {fp.map(
                    o => (
                        <MenuItem key={o.id} onClick={ev => handleSelect(o)}>
                            {conditionLabel(o)}
                        </MenuItem>
                    ),
                    options,
                )}
            </Menu>
        </div>
    );
}

export default function NewFocusDialog({carePlan, actions, open, onClose}) {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const rel = useSelector(ducks.jsonapi.selectRelated(carePlan));
    const relPatient = useSelector(
        ducks.jsonapi.selectRelated(fp.get('patient', rel)),
    );
    const [condition, setCondition] = useState(null);
    const templates = useRelated(condition, 'care_plan_templates', {
        type: 'CarePlanTemplate',
    });
    const templates_fetch = templates.fetch;

    useEffect(() => {
        if (rel.patient) {
            api.fetchJson(
                fp.get('relationships.practice.links.related', rel.patient),
            );
        }
    }, [api, rel.patient]);

    useEffect(() => {
       templates_fetch()
    }, [condition, templates_fetch]);

    const addFocus = async (ev, row) => {
        await actions.addFocus(row.data);
    };
    const newTemplate = async () => {
        const resp = await actions.newTemplate(
            condition,
            relPatient.practice.id,
        );
        return editTemplate(null, resp);
    };
    const editTemplate = async (ev, row) => {
        const href = url_for('carePlanTemplates', {
            practiceId: relPatient.practice.id,
        });
        const searchParams = new URLSearchParams();
        const query = fp.pipe(
            fp.get('data.attributes.description'),
            fp.split(' '),
            fp.filter(word => !word.match(/^\d*$/)),
            fp.join(' '),
        )(row);
        searchParams.set('cpt.q', query);
        history.push(href + '?' + searchParams);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='xl'
            className={classes.root}
        >
            <DialogTitle>New focus</DialogTitle>
            <DialogContent>
                <p>
                    Create a new care plan focus by selecting one of this
                    patient's conditions below
                </p>
                <p>
                    Alternatively, you can search for a condition using the
                    search field. If you search, we will search{' '}
                    <strong>all</strong> care plan templates for your practice.
                    If you do not search, we will only display care plan
                    templates that exactly match the coding for the patient's
                    selected condition.
                </p>
                <ConditionSelector
                    patient={rel.patient}
                    value={condition}
                    onChange={c => setCondition(c)}
                />
                <CarePlanTemplateTable
                    dataSource={templates}
                    title='Matching templates'
                >
                    <dt2.Action
                        free
                        icon='add'
                        tooltip='Create new template based on this condition'
                        onClick={newTemplate}
                    />
                    <dt2.Action
                        icon='add'
                        tooltip='Add this template as a focus'
                        onClick={addFocus}
                    />
                    <dt2.Action
                        icon='edit'
                        tooltip='Edit this template'
                        onClick={editTemplate}
                    />
                </CarePlanTemplateTable>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
