import React from 'react';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';
import {useResources} from 'ccm/dataSource';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import OrderTemplateTable from './OrderTemplateTable';

const {dt2} = components;

const SHARED_TEMPLATE_FETCH_OPTIONS = {
    filter: {
        'attributes.practice_id': null,
    },
    page: {number: 0, size: 20},
};

export default function OrderImporter({title, practice, practiceDataSource}) {
    const api = useApi();
    const link = 'order.TemplateCollection';
    const dataSource = useResources({
        link,
        prefix: 'order.',
        initialFetchOptions: SHARED_TEMPLATE_FETCH_OPTIONS,
    });

    const handleImport = async (ev, item) => {
        console.log('Import', {item});
        const sharedOrderTemplate = item.data;
        const sharedInstructionsPromise = api.fetchJson(
            fp.get(
                'relationships.instructions.links.related',
                sharedOrderTemplate,
            ),
        );
        const newOrderTemplate = await practiceDataSource.handleAdd(item);
        const sharedInstructions = await sharedInstructionsPromise;
        const newOrderInstruction = fp.get(
            'data.relationships.instructions.links.related',
            newOrderTemplate,
        );
        const promises = fp.map(async data => {
            await api.fetchJson(newOrderInstruction, {
                method: 'POST',
                json: {data},
            });
        }, sharedInstructions.data);
        await Promise.all(promises);
        return true;
    };

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h6>{title}</h6>
            </AccordionSummary>
            <AccordionDetails>
                <OrderTemplateTable
                    title='Available Order Templates'
                    dataSource={dataSource}
                >
                    <dt2.Action
                        name='import'
                        icon='get_app'
                        tooltip="Import this into your practice's template library"
                        onClick={handleImport}
                    />
                </OrderTemplateTable>
            </AccordionDetails>
        </Accordion>
    );
}
