import React from 'react';
import fp from 'lodash/fp';

import FacilityInfo from './FacilityInfo';
import FacilityAdmin from './FacilityAdmin';

export default function Facility({facility}) {
    if (!facility) return null;
    const allowEdit = fp.includes(
        'Common.ADMIN',
        facility.meta.allowed_actions,
    );

    return allowEdit ? (
        <FacilityAdmin facility={facility} />
    ) : (
        <FacilityInfo facility={facility} />
    );
}
