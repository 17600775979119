import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Button,
} from '@material-ui/core';
import {Mail} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';

import {MESSAGE_TYPES} from 'ccm/constants';

const defaultMessage = {
    type: 'message',
    subject: '',
    text: '',
    includeAll: false,
};

export default function MessageCompositionDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState(defaultMessage);
    const [patient, setPatient] = useState(null);
    const [count, setCount] = useState(null);
    const [total, setTotal] = useState(null);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
        setPatient(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
        setPatient(null);
    };

    dialogRef.current = ({patient = null, count = 1, total = 1}) => {
        return new Promise((resolve, reject) => {
            setPatient(patient);
            setCount(count);
            setTotal(total);
            setInitialValues(initialValues);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>
                            Compose message to{' '}
                            {patient
                                ? patient.attributes.name.formatted
                                : `${count} Patient(s)`}
                        </DialogTitle>
                        <DialogContent>
                            <Field
                                component={TextField}
                                fullWidth
                                select
                                label='Message type'
                                name='type'
                            >
                                {fp.map(
                                    o => (
                                        <MenuItem key={o.value} value={o.value}>
                                            {o.label}
                                        </MenuItem>
                                    ),
                                    MESSAGE_TYPES,
                                )}
                            </Field>
                            <Field
                                component={TextField}
                                name='subject'
                                fullWidth
                                label='Subject'
                            />
                            <Field
                                component={TextField}
                                multiline
                                fullWidth
                                rows={6}
                                name='text'
                                label='Message text'
                            />
                            {total > count ? (
                                <>
                                    <p>
                                        You selected {count} patients, but{' '}
                                        {total} patients fit your search
                                        criteria.
                                    </p>
                                    <Field
                                        component={CheckboxWithLabel}
                                        type='checkbox'
                                        name='includeAll'
                                        Label={{
                                            label:
                                                'Include all matching patients',
                                        }}
                                    />
                                </>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                startIcon={<Mail />}
                                variant='contained'
                                color='primary'
                            >
                                Send message
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
