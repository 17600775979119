import React, {useState, useMemo} from 'react';
import fp from 'lodash/fp';
import {useDispatch} from 'react-redux';

import * as dfn from 'date-fns/fp';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';

import {useApi, components, ducks} from '@arborian/narrf';

import {useRelated} from 'ccm/dataSource';
import {useDialog} from 'ccm/components/dialogs';
import {VisitsTable} from 'ccm/components/VisitsTable';
import {FacilityColumn, ProviderColumn} from 'ccm/components/columns';
import VisitDialog from '../VisitDialog';

const {dt2} = components;

const DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.visit_date'},
};

function usePatientVisits(patient, visitFilters) {
    const mergeFetchOptions = useMemo(() => {
        const minDate = dfn.startOfDay(visitFilters.minDate);
        const maxDate = dfn.endOfDay(visitFilters.maxDate);
        return {
            filter: {
                'attributes.visitDate': {
                    $gte: {$date: minDate.getTime()},
                    $lte: {$date: maxDate.getTime()},
                },
            },
        };
    }, [visitFilters]);
    console.log('usePatientVisits', {mergeFetchOptions});
    return useRelated(patient, 'visits', {
        type: 'ProviderVisit',
        prefix: 'pt-visits.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        mergeFetchOptions,
    });
}

function VisitFilters({value, onChange}) {
    const {minDate, maxDate} = value;
    const handleChange = field => (dtValue, sValue) => {
        if (!isNaN(dtValue)) {
            onChange(fp.set(field, dtValue, value));
        }
    };
    return (
        <div>
            <KeyboardDatePicker
                label='Min date'
                format='MM/dd/yyyy'
                value={minDate}
                onChange={handleChange('minDate')}
            />
            &nbsp;
            <KeyboardDatePicker
                label='Max date'
                format='MM/dd/yyyy'
                value={maxDate}
                onChange={handleChange('maxDate')}
            />
        </div>
    );
}

function VisitButton({practice, visit, onChanged}) {
    const api = useApi();
    const visitDialog = useDialog(VisitDialog);
    const dispatch = useDispatch();
    const handleClick = async () => {
        const {action, data} = await visitDialog({practice, visit});
        if (action === 'CANCEL') return;
        else if (action === 'DELETE') {
            await api.fetchJson(fp.get('links.self', visit), {
                method: 'DELETE',
            });
            dispatch(ducks.jsonapi.deleteData(visit));
            onChanged && onChanged();
        } else if (action === 'SUBMIT') {
            const {type, links, attributes, relationships} = data;
            const fixedRelationships = fp.pipe([
                fp.toPairs,
                fp.map(([k, v]) => {
                    console.log(k, v);
                    if (v.data) {
                        return [k, {data: {id: v.data.id, type: v.data.type}}];
                    }
                    return null;
                }),
                fp.filter(item => !!item),
                fp.fromPairs,
            ])(relationships);
            console.log({fixedRelationships});

            await api.fetchJson(links.self, {
                method: 'PUT',
                json: {
                    data: {
                        type,
                        attributes,
                        relationships,
                        // relationships: fixedRelationships,
                    },
                },
            });
            onChanged && onChanged();
        }
    };
    return (
        <IconButton onClick={handleClick}>
            <Edit />
        </IconButton>
    );
}

export default function PatientVisitTable({practice, patient}) {
    const now = new Date();
    const initialVisitFilters = {
        minDate: dfn.subMonths(7, now),
        maxDate: dfn.addMonths(7, now),
    };
    const fmt = dt => {
        try {
            return dfn.format('P')(dt);
        } catch (e) {
            return 'Invalid date';
        }
    };
    const [visitFilters, setVisitFilters] = useState(initialVisitFilters);
    const dsVisit = usePatientVisits(patient, visitFilters);
    const title = `Showing visits between ${fmt(visitFilters.minDate)} 
        and ${fmt(visitFilters.maxDate)}
        `;
    console.log('PatientVisitTable', {visitFilters, title});
    return (
        <div>
            <VisitFilters value={visitFilters} onChange={setVisitFilters} />
            <VisitsTable title={title} dataSource={dsVisit}>
                <dt2.Column
                    id='col-act'
                    filtering={false}
                    sorting={false}
                    title='Actions'
                    render={row => (
                        <VisitButton
                            practice={practice}
                            visit={row.data}
                            onChanged={() => dsVisit.fetch()}
                        />
                    )}
                />
                <ProviderColumn />
                <FacilityColumn />
            </VisitsTable>
        </div>
    );
}
