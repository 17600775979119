import React from 'react';
import fp from 'lodash/fp';

import ContactPointChip from 'ccm/components/ContactPointChip';

export default function TelecomChips({value}) {
    if (!value) return null;
    return (
        <>
            {fp.map(
                cp => (
                    <ContactPointChip key={cp.id} value={cp} />
                ),
                value,
            )}
        </>
    );
}
