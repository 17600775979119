import React from 'react';
import fp from 'lodash/fp';

import {Chip, IconButton} from '@material-ui/core';
import {Add} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

function linkedId(pfData, linkType) {
    const pattern = `^${linkType}:(.+)$`;
    for (const linkage of fp.get('relationships.samepage_links.data', pfData)) {
        const m = linkage.id.match(pattern);
        if (m) return m[1];
    }
}

export default function LinkSelect({pfData, linkType, getSelection, getLabel}) {
    const api = useApi();
    const id = linkedId(pfData, linkType);
    const label = getLabel(id);
    const handleAdd = async ev => {
        const {action, spData} = await getSelection();
        if (action === 'select') {
            console.log('Selected', {pfData, spData});
            const linkage = {
                data: [{type: 'SamepageLink', id: `${linkType}:${spData.id}`}],
            };
            await api.fetchJson(
                fp.get('relationships.samepage_links.links.self', pfData),
                {
                    method: 'POST',
                    json: linkage,
                },
            );
            await api.fetchJson(fp.get('links.self', pfData));
        }
    };
    const handleDelete = async ev => {
        const linkage = {
            data: [{type: 'SamepageLink', id: `${linkType}:${id}`}],
        };
        await api.fetchJson(
            fp.get('relationships.samepage_links.links.self', pfData),
            {
                method: 'DELETE',
                json: linkage,
            },
        );
        await api.fetchJson(fp.get('links.self', pfData));
    };
    return (
        <>
            {id ? (
                <Chip label={label} onDelete={handleDelete} />
            ) : (
                <IconButton onClick={handleAdd}>
                    <Add />
                </IconButton>
            )}
        </>
    );
}
