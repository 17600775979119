import React from 'react';
import fp from 'lodash/fp';
import {useDispatch} from 'react-redux';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Chip,
    IconButton,
} from '@material-ui/core';
import {Add} from '@material-ui/icons';

import {useCurrentUser, useUserEmails} from 'ccm/dataSource/misc';
import {ducks, useApi} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';
import AddEmailDialog from 'ccm/components/dialogs/AddEmailDialog';

function EmailInfo({user, email}) {
    const api = useApi();
    const dispatch = useDispatch();
    const isPrimary = email.attributes.email === user.attributes.primary_email;
    const handleDelete = async ev => {
        await api.fetchJson(email.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(email));
    };
    const handleMakePrimary = async ev => {
        await api.fetchJson(email.links.self, {
            method: 'PATCH',
            json: {data: fp.set('attributes.is_primary', true, email)},
        });
        api.fetchJson(user.links.self);
    };
    console.log('EmailChip', {user, email});
    return (
        <div>
            <Chip
                label={email.attributes.email}
                onDelete={isPrimary ? null : handleDelete}
                color={isPrimary ? 'primary' : undefined}
            />
            {isPrimary ? null : (
                <Button onClick={handleMakePrimary}>Make Primary</Button>
            )}
        </div>
    );
}

export default function EmailsCard() {
    const api = useApi();
    const user = useCurrentUser();
    const emails = useUserEmails(user);
    console.log('EmailsCard', {user, emails});
    const addEmail = useDialog(AddEmailDialog);
    const handleAdd = async ev => {
        let resp = await addEmail();
        if (!resp) return;
        console.log('Add email', ev, resp);
        resp = await api.fetchJson(user.relationships.emails.links.related, {
            method: 'POST',
            json: {data: {type: 'Email', attributes: {email: resp.email}}},
        });
        console.log('API response', resp);
    };
    if (!user) return null;
    return (
        <Card>
            <CardHeader title='My Email Addresses' />
            <CardContent>
                <h6>Primary Email Address: {user.attributes.primary_email}</h6>
                {fp.map(
                    em => (
                        <EmailInfo key={em.id} user={user} email={em} />
                    ),
                    emails,
                )}
                <IconButton onClick={handleAdd}>
                    <Add />
                </IconButton>
            </CardContent>
        </Card>
    );
}
