import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {Drafts, Markunread} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

export default function ReadStatus({row}) {
    const imsg = useSelector(
        ducks.jsonapi.selectObject(['InboxMessage', row.id]),
    );
    return fp.get('attributes.read', imsg) ? <Drafts /> : <Markunread />;
}
