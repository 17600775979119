import React from 'react';

import {HeightAvatar} from 'ccm/art/MedicalIcons';

import VitalCard from './VitalCard';

export default function HeightCard({patient, artifact}) {
    return (
        <VitalCard
            color='blue'
            Avatar={HeightAvatar}
            type='height'
            patient={patient}
            {...artifact.attributes}
        >
            <h6>Height</h6>
        </VitalCard>
    );
}
