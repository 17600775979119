import React, {useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import {useApi} from '@arborian/narrf';
import {url_for} from 'ccm/routes';

export default function AutoLogout({timeout}) {
    const api = useApi();
    const history = useHistory();
    const location = useLocation();

    const onIdle = useCallback(() => {
        if (api.isAuthorized()) {
            const state = {next: location};
            history.push(url_for('logoutRedirect'), state);
        }
    }, [api, history, location]);
    return <IdleTimer timeout={timeout} onIdle={onIdle} />;
}
