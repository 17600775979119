import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    attachmentList: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: theme.spacing(32),
        border: '1px solid black',
    },
    itemHeader: {
        display: 'flex',
        position: 'relative',
        padding: theme.spacing(2),
    },
    thumbnail: {
        maxWidth: theme.spacing(32),
        maxHeight: theme.spacing(32),
    },
    leftPane: {
        flexBasis: '50%',
        border: '1px solid grey',
        overflowY: 'scroll',
    },
    rightPane: {
        flexBasis: '50%',
        border: '1px solid grey',
        overflowY: 'scroll',
    },
    newTicket: {
        borderBottom: '1px solid black',
        display: 'flex',
    },
    dropHere: {
        padding: theme.spacing(4),
        // width: theme.spacing(16),
        // height: theme.spacing(16),
        backgroundColor: '#aaa',
    },
    dialogCloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    dialogImg: {
        maxWidth: '100%',
    },
}));
