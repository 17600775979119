import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Formik, Form, Field} from 'formik';
import {Autocomplete} from 'formik-material-ui-lab';
import {TextField as MUITextField, Button} from '@material-ui/core';
import {ducks, useApi} from '@arborian/narrf';

import {selectUsersWithGroup} from 'ccm/lib/selectors';

export default function FallbacksForm({practice, ruleset}) {
    const api = useApi();
    const fallback = fp.get('attributes.fallback', ruleset);
    const initialValues = {
        owner: useSelector(
            ducks.jsonapi.selectObject(['User', fp.get('owner_id', fallback)]),
        ),
        provider: useSelector(
            ducks.jsonapi.selectObject([
                'User',
                fp.get('provider_id', fallback),
            ]),
        ),
    };
    const ownerGroupId = `practice:${practice.id}/staff`;
    const providerGroupId = `practice:${practice.id}/provider`;
    const owners = useSelector(selectUsersWithGroup(ownerGroupId));
    const providers = useSelector(selectUsersWithGroup(providerGroupId));

    const handleSubmit = async values => {
        const newGlobalFallback = {
            facility_id: null,
            owner_id: values.owner.id,
            provider_id: values.provider.id,
        };
        const newRuleset = fp.set(
            'attributes.fallback',
            newGlobalFallback,
            ruleset,
        );
        console.log({ruleset, newRuleset, values});
        let resp = await api.fetchJson(ruleset.links.self, {
            method: 'PATCH',
            json: {data: newRuleset},
        });
        console.log({resp});
    };

    if (!ruleset) return null;
    if (!owners?.length || !providers?.length) return null;
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <Field
                        id='fallback-owner-field'
                        fullWidth
                        name='owner'
                        component={Autocomplete}
                        options={owners}
                        getOptionLabel={fp.get('attributes.name')}
                        renderInput={params => (
                            <MUITextField
                                label='Owner'
                                error={
                                    formikProps.touched['owner'] &&
                                    !!formikProps.errors['owner']
                                }
                                helperText={formikProps.errors['owner']}
                                {...params}
                            />
                        )}
                    />
                    <Field
                        id='fallback-provider-field'
                        fullWidth
                        name='provider'
                        component={Autocomplete}
                        options={providers}
                        getOptionLabel={fp.get('attributes.name')}
                        renderInput={params => (
                            <MUITextField
                                label='Provider'
                                error={
                                    formikProps.touched['provider'] &&
                                    !!formikProps.errors['provider']
                                }
                                helperText={formikProps.errors['provider']}
                                {...params}
                            />
                        )}
                    />
                    <br />
                    <Button
                        id='set-fallbacks-btn'
                        type='submit'
                        variant='contained'
                        color='primary'
                    >
                        Set Fallbacks
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
