import React from 'react';
import fp from 'lodash/fp';

import {Field, useFormikContext} from 'formik';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

export default function SecurityPolicyFields() {
    const f = useFormikContext();
    const maxAge = fp.get('security_policy.password_max_age_days', f.values);
    const hasMaxAge = fp.isNumber(maxAge);
    const handleCheckbox = ev => {
        if (ev.target.checked) {
            f.setFieldValue('security_policy.password_max_age_days', 180);
        } else {
            f.setFieldValue('security_policy.password_max_age_days', '');
        }
    };
    return (
        <>
            <p>
                The following polices will be enforced when your users next
                change their password. These policies will not affect users who
                use other systems (PointClickCare, Google, etc.) to log in to
                SamePage, MD
            </p>

            <FormControlLabel
                fullWidth
                control={
                    <Checkbox checked={hasMaxAge} onChange={handleCheckbox} />
                }
                label='Set a maximum password age'
            />
            <Field
                component={TextField}
                disabled={!hasMaxAge}
                name='security_policy.password_max_age_days'
                label='Password Maximum Age (in days)'
                type='number'
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Field
                component={TextField}
                name='security_policy.password_min_length'
                label='Password Minimum Length'
                type='number'
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    );
}
