import {useState, useEffect, useCallback} from 'react';
import fp from 'lodash/fp';
import {useHistory} from 'react-router-dom';

import {useApi, components} from '@arborian/narrf';

const {useQueryFetchOptions, useDataActions, useReduxDataSource} = components;

const defaultFetchOptions = {
    sort: {field: 'attributes.timestamp', direction: 'desc'},
    page: {number: 0, size: 5},
    filter: {'attributes.thread_id': null},
};

const defaultOptions = {};

export default function useInboxDataSource({includePatient, ...props}) {
    const api = useApi();
    const history = useHistory();
    let {
        href = null,
        prefix = 'inbox.',
        options = defaultOptions,
        initialFetchOptions = defaultFetchOptions,
    } = props;
    const [fetchOptions, setFetchOptions] = useQueryFetchOptions(
        history,
        prefix,
        initialFetchOptions,
    );
    const [showArchived, setShowArchived] = useState(false);
    const fetchItems = useCallback(
        async fetchOptions => {
            if (!href) {
                return {
                    rows: [],
                };
            }
            const realOptions = fp.assign(options, {
                include: includePatient ? ['patient'] : undefined,
                filter: {deleted: showArchived},
            });
            const result = await api.fetchDataTable(
                href,
                fetchOptions,
                realOptions,
            );
            return result;
        },
        [api, href, options, showArchived, includePatient],
    );
    const ds = useReduxDataSource({data: fetchItems, fetchOptions});
    const actions = useDataActions(fetchOptions, setFetchOptions);

    const patch = useCallback(
        async (newAttrs, itemOrItems) => {
            const items = fp.isArray(itemOrItems) ? itemOrItems : [itemOrItems];
            let promises = fp.map(it => {
                let attributes = fp.merge(it.data.attributes, newAttrs);
                return api.fetch(it.data.links.self, {
                    method: 'PATCH',
                    json: {data: fp.set('attributes', attributes, it.data)},
                });
            }, items);
            await Promise.all(promises);
            api.fetchJson(api.url_for('user.Current'));
            await ds.fetch();
        },
        [api, ds],
    );

    useEffect(() => {
        ds.fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [href, showArchived]);

    return {
        ...ds,
        ...actions,
        patch,
        showArchived,
        setShowArchived,
        ...props,
    };
}
