import React from 'react';
import fp from 'lodash/fp';

import {AppBar, IconButton, Toolbar} from '@material-ui/core';
import {Delete} from '@material-ui/icons';

import useStyles from './styles';
import AttachmentThumbnail from './AttachmentThumbnail';

function fixOrdinals(atts) {
    return atts.map((att, i) => fp.set('ordinal', i, att));
}

export default function NewTicket({id, attachments, onChange, onDelete}) {
    const classes = useStyles();

    const handleDeleteTicket = ev => {
        onDelete(ev, id);
    };

    const handleDragStart = (ev, att) => {
        ev.dataTransfer.clearData();
        ev.dataTransfer.setData('application/json', JSON.stringify(att));
        ev.dataTransfer.dropEffect = 'move';
    };

    const handleDragOver = ev => {
        ev.preventDefault();
    };

    const handleDropAtt = att => ev => {
        ev.preventDefault();
        const newAtt = JSON.parse(ev.dataTransfer.getData('application/json'));
        const oldAtt = attachments[newAtt.ordinal];
        let newAttachments = attachments.toSpliced(att.ordinal, 0, newAtt);
        console.log('Inserted', newAtt, 'into', newAttachments);
        if (newAtt.ticketId === id) {
            // Is a move; we need to delete the old one
            newAttachments = fp.remove(a => a === oldAtt, newAttachments);
        }
        console.log('Deleted old', oldAtt, 'from', newAttachments);
        newAtt.ticketId = id;
        onChange({id, attachments: fixOrdinals(newAttachments)});
    };

    const handleDropNew = ev => {
        // Append to this ticket
        ev.preventDefault();
        const newAtt = JSON.parse(ev.dataTransfer.getData('application/json'));
        newAtt.ticketId = id;
        newAtt.ordinal = attachments.length;
        onChange({id, attachments: [...attachments, newAtt]});
    };

    const handleDeleteAtt = att => ev => {
        const newAttachments = fixOrdinals(
            attachments.toSpliced(att.ordinal, 1),
        );
        if (attachments.length - 1 !== newAttachments.length) {
            console.log('DANGER!', {att, attachments, newAttachments});
        }
        onChange({id, attachments: newAttachments});
    };

    return (
        <div>
            <AppBar position='relative' color='default'>
                <Toolbar>
                    <h3>New ticket #{id + 1}</h3>
                    <IconButton edge='end' onClick={handleDeleteTicket}>
                        <Delete />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.attachmentList}>
                {fp.map(
                    att => (
                        <AttachmentThumbnail
                            attachment={att}
                            key={att.ordinal}
                            onDragStart={handleDragStart}
                            onDelete={handleDeleteAtt(att)}
                            onDragOver={handleDragOver}
                            onDrop={handleDropAtt(att)}
                        />
                    ),
                    attachments,
                )}
                <div
                    className={classes.dropHere}
                    onDragOver={handleDragOver}
                    onDrop={handleDropNew}
                >
                    Drop here
                </div>
            </div>
        </div>
    );
}
