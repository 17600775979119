import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Grid} from '@material-ui/core';

import {selectPortalConfig} from 'ccm/lib/selectors-ccm';
import INFO_CONFIG from './config';

export default function InfoDashboard({patient}) {
    const portalConfig = useSelector(selectPortalConfig(patient));
    const info_cards = fp.filter(
        it => fp.get(it.filter, portalConfig),
        INFO_CONFIG,
    );
    return (
        <Grid container spacing={2}>
            {fp.map(
                v => (
                    <Grid key={v.key} item xs={12} md={6}>
                        <v.component
                            id={`btn-${v.key}`}
                            patient={patient}
                            type={v.key}
                        />
                    </Grid>
                ),
                info_cards,
            )}
        </Grid>
    );
}
