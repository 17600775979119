import React from 'react';

import {Tooltip, IconButton} from '@material-ui/core';
import {AddBox, Edit, DeleteOutline} from '@material-ui/icons';

import {useTableManager} from './Provider';

export function RowAdd({}) {
    const tm = useTableManager();
    const disabled = tm.editState.op !== null;
    return (
        <Tooltip title='Add'>
            <IconButton onClick={tm.beginAddRow} disabled={disabled}>
                <AddBox />
            </IconButton>
        </Tooltip>
    );
}

export function RowEdit({row}) {
    const tm = useTableManager();
    const disabled = tm.editState.op !== null;
    return (
        <Tooltip title='Edit'>
            <IconButton
                onClick={() => tm.beginEditRow(row)}
                disabled={disabled}
            >
                <Edit />
            </IconButton>
        </Tooltip>
    );
}

export function RowDelete({row}) {
    const tm = useTableManager();
    const disabled = tm.editState.op !== null;
    return (
        <Tooltip title='Delete'>
            <IconButton
                onClick={() => tm.beginDeleteRow(row)}
                disabled={disabled}
            >
                <DeleteOutline />
            </IconButton>
        </Tooltip>
    );
}
