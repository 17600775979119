import React from 'react';
import {Link} from 'react-router-dom';

import {makeStyles, Button} from '@material-ui/core';

import BasePage from 'ccm/components/BasePage';
import {fieldId} from 'ccm/lib/util';
import {url_for} from 'ccm/routes';

const useStyles = makeStyles(theme => ({
    successFrame: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: 300,
    },
}));

export default function SuccessPage() {
    const classes = useStyles();

    return (
        <BasePage>
            <div className={classes.successFrame}>
                <h4>Ticket Submitted</h4>
                <p>Your communication was successfully submitted.</p>
                <div>
                    <Button
                        id={fieldId('dyn', 'homeButton')}
                        component={Link}
                        to={url_for('home')}
                        variant='contained'
                    >
                        Back Home
                    </Button>
                </div>
            </div>
        </BasePage>
    );
}
