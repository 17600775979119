import React, {useRef, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {useHistory} from 'react-router-dom';

import {Chip} from '@material-ui/core';

import {ducks, components} from '@arborian/narrf';
import {useActions} from 'ccm/lib/hooks';

const {
    DataTable,
    Column,
    Action,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const defaultFetchOptions = {page: {size: 20}};

function InvitationUser({row, column}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data));
    const resource = {...row.data, relationships: rel};
    const value = fp.get(column.field, resource);
    if (value) {
        return `${value.attributes.name} <${value.attributes.primary_email}>`;
    } else {
        return null;
    }
}

function InvitationRoles({row, column}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data));
    const resource = {...row.data, relationships: rel};
    const value = fp.get(column.field, resource);
    return (
        <>
            {fp.map(
                g => (
                    <Chip key={g.id} label={g.attributes.label} />
                ),
                value,
            )}
        </>
    );
}

export default function InvitationAdminTable({
    title,
    protectedResource,
    tableRef,
}) {
    const actions = useActions();
    const _tableRef = useRef();
    tableRef = tableRef || _tableRef;
    const history = useHistory();
    const optionsPrefix = `invitationTable[${protectedResource.group_prefix}]`;
    const fetchOptions = useLocationFetchOptions(
        defaultFetchOptions,
        optionsPrefix,
    );
    const onChangeFetchOptions = o => {
        const newParams = updateLocationFetchOptions(o, optionsPrefix);
        history.push('?' + newParams.toString());
    };

    const refresh = useCallback(() => tableRef.current.fetch(), [tableRef]);
    const fetchItems = fp.partial(actions.fetchInvitationsTable, [
        protectedResource,
    ]);

    const hasPermission =
        fp.includes(
            'Patient.ACCESS_INVITES',
            protectedResource.meta.allowed_actions,
        ) ||
        fp.includes('Common.ADMIN', protectedResource.meta.allowed_actions);
    if (!hasPermission) {
        return (
            <>
                <br />
                <h6>Only admins may send invitations.</h6>
            </>
        );
    }

    const handleAdd = async ev => {
        await actions.inviteUser(protectedResource);
        refresh();
    };
    const handleDelete = row => actions.deleteResource(row.data);
    const handleResend = (ev, row) => actions.putResource(row.data);
    const editable = {
        onRowDelete: handleDelete,
    };

    return (
        <DataTable
            title={title}
            size='small'
            tableRef={tableRef}
            editable={editable}
            data={fetchItems}
            fetchOptions={fetchOptions}
            onChangeFetchOptions={onChangeFetchOptions}
            options={{
                search: true,
            }}
        >
            <Column
                title='Created by'
                field='relationships.from_user'
                component={InvitationUser}
            />
            <Column
                title='Sent to'
                field='relationships.to_user'
                component={InvitationUser}
            />
            <Column title='Sent to email' field='attributes.to_email' />
            <Column
                title='Roles'
                field='relationships.groups'
                component={InvitationRoles}
            />
            <Column title='Expiration' field='attributes.expires' type='date' />
            <Action
                name='invite'
                free
                onClick={handleAdd}
                tooltip='Invite a new group member'
                icon='add'
            />
            <Action
                onClick={handleResend}
                tooltip='Re-send an existing invitation'
                icon='send'
            />
            <Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </DataTable>
    );
}
