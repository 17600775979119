import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Tooltip} from '@material-ui/core';
import {ducks, components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';
import {useListField} from 'ccm/dataSource';

import {selectPatientForVisit} from './selectors';

const {dt2} = components;

function VisitPatient({visit, ehrConnectionId}) {
    const selector = useMemo(
        () => selectPatientForVisit(visit, ehrConnectionId),
        [visit, ehrConnectionId],
    );
    const pt = useSelector(selector);
    const pfId = fp.get('attributes.raw.patientPracticeGuid', pt);
    if (!pt) return null;
    return (
        <Tooltip title={`PF ID=${pfId}`}>
            <span>
                {fp.get('attributes.name.formatted', pt)} (DOB{' '}
                {h.formatDateOnly(fp.get('attributes.dob', pt))})
            </span>
        </Tooltip>
    );
}

function VisitProvider({visit}) {
    const providerId = fp.get('relationships.provider.data.id', visit);
    const provider = useSelector(
        ducks.jsonapi.selectObject(['UserSummary', providerId]),
    );
    return fp.get('attributes.name', provider);
}

function VisitFacility({visit}) {
    const facilityId = fp.get('relationships.facility.data.id', visit);
    const facility = useSelector(
        ducks.jsonapi.selectObject(['Facility', facilityId]),
    );
    return fp.get('attributes.name', facility);
}

export default function NoteVisitsTable({visits, ehrConnectionId}) {
    const patientsList = useListField(visits, (...args) => {
        console.log('List change', args);
    });

    return (
        <dt2.DataTable size='small' dataSource={patientsList}>
            <dt2.Column
                id='patient'
                title='Patient'
                render={row => (
                    <VisitPatient
                        visit={row.data}
                        ehrConnectionId={ehrConnectionId}
                    />
                )}
            />
            <dt2.Column
                id='date'
                title='Date'
                field='attributes.visit_date'
                type='date'
            />
            <dt2.Column
                id='provider'
                title='Provider'
                render={row => <VisitProvider visit={row.data} />}
            />
            <dt2.Column
                id='facility'
                title='Facility'
                render={row => <VisitFacility visit={row.data} />}
            />
            <dt2.Column
                id='chiefComplaint'
                title='Chief Complaint'
                field='attributes.reason'
            />
        </dt2.DataTable>
    );
}
