import React from 'react';
import {FIELD_COMPONENTS} from './constants';

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    fieldSpacing: {
        padding: theme.spacing(2),
    },
}));

export default function DynaField({field, sessionId}) {
    const Component = FIELD_COMPONENTS[field.fieldType];
    const classes = useStyles();

    if (!Component) {
        console.error('Unknown fieldType', field.fieldType);
        return null;
    }
    return (
        <div className={classes.fieldSpacing}>
            <Component field={field} sessionId={sessionId} />
        </div>
    );
}
