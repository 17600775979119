import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import BasePatientPage from 'ccm/components/BasePatientPage';
import {PatientUpcomingVisitsTable} from 'ccm/components/VisitsTable';

import {ducks, useApi} from '@arborian/narrf';

export default function PatientPage() {
    const api = useApi();
    const {patientId} = useParams();
    const [init, setInit] = useState(false);
    useEffect(() => {
        if (!init) {
            setInit(true);
            let url = api.url_for('patient.Patient', {patient_id: patientId});
            api.fetchJsonApi(url, {
                include: ['facility', 'upcomingVisits'],
            });
        }
    }, [api, patientId, init, setInit]);

    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );
    console.log('protected_resource:', patient);

    return (
        <BasePatientPage title='Visits' patient={patient} showFiles={false}>
            <PatientUpcomingVisitsTable patient={patient} />
        </BasePatientPage>
    );
}
