import React from 'react';
import fp from 'lodash/fp';
import {Formik, Form, Field} from 'formik';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import {Button, makeStyles} from '@material-ui/core';
import {startOfMonth, endOfMonth} from 'date-fns';

import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';
import {FacilitySelect, TicketTypeSelect} from 'ccm/components/formikFields';

const initialValues = {
    begin: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
    ticket_type: [],
    facility: [],
};

const useStyles = makeStyles(theme => ({
    fields: {
        display: 'flex',
        '& .MuiInputBase-root': {
            marginRight: theme.spacing(1),
        },
    },
}));

function FormikForm({touched, values, errors, setFieldValue, setFieldTouched}) {
    const classes = useStyles();
    const allTicketTypes = useAllTicketTypesLookup();
    const selectAllTicketTypes = ev => {
        console.log('Set ticket_type to', fp.keys(allTicketTypes));
        setFieldValue('ticket_type', fp.keys(allTicketTypes));
    };
    const selectNoTicketTypes = ev => {
        setFieldValue('ticket_type', []);
    };
    return (
        <Form>
            <div className={classes.fields}>
                <Field
                    id='fld-report-begin'
                    label='Begin'
                    name='begin'
                    format='yyyy-MM-dd'
                    component={KeyboardDatePicker}
                />
                <Field
                    id='fld-report-end'
                    label='End'
                    name='end'
                    format='yyyy-MM-dd'
                    component={KeyboardDatePicker}
                />
            </div>
            <Field
                id='fld-report-facility'
                fullWidth
                label='Facility'
                name='facility'
                touched={touched}
                errors={errors}
                component={FacilitySelect}
                multiple
            />
            <Field
                id='fld-report-ticket-type'
                fullWidth
                label='Ticket Type'
                name='ticket_type'
                touched={touched}
                errors={errors}
                component={TicketTypeSelect}
                multiple
            />
            <div className={classes.fieldActions}>
                <Button onClick={selectAllTicketTypes}>Select all</Button>
                <Button onClick={selectNoTicketTypes}>Select none</Button>
            </div>
            <Button type='submit' variant='contained' color='primary'>
                Generate Report
            </Button>
        </Form>
    );
}

export default function ReportTypeForm({onSubmit}) {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            component={FormikForm}
        />
    );
}
