import React, {useMemo} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';

import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Toolbar,
    Hidden,
    makeStyles,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {ducks} from '@arborian/narrf';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {url_for} from 'ccm/routes';
import {fieldId} from 'ccm/lib/util';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.constants.drawerWidth,
    },
    logo: {
        height: 48,
    },
}));

function MenuEntry({route, icon, primary, secondary}) {
    return (
        <ListItem
            id={fieldId('menu', route)}
            button
            component={R.Link}
            to={url_for(route)}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
    );
}

function useUserPermissions() {
    const user = useSelector(selectCurrentUser);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    return useMemo(() => {
        const isSuperuser =
            userinfo && fp.includes('__admin__', userinfo.scopes);
        const isAnonymous = !userinfo;
        const groups = fp.get('relationships.groups.data', user);
        const hasPatientGroup = fp.some(g => g.id.match(/^patient:/), groups);
        const hasPracticeGroup = fp.some(g => g.id.match(/^practice:/), groups);
        const hasFacilityGroup = fp.some(g => g.id.match(/^facility:/), groups);
        const hasPatients =
            isSuperuser ||
            hasPatientGroup ||
            hasPracticeGroup ||
            hasFacilityGroup;
        const hasPractice = isSuperuser || hasPracticeGroup;
        const hasFacility = isSuperuser || hasFacilityGroup;
        const hasTickets = isSuperuser || hasPracticeGroup || hasFacilityGroup;

        return {
            isSuperuser,
            isAnonymous,
            hasPatients,
            hasPractice,
            hasFacility,
            hasTickets,
        };
    }, [user, userinfo]);
}

function MenuItems() {
    const perm = useUserPermissions();

    if (perm.isAnonymous) {
        return (
            <List>
                <MenuEntry icon={<I.Home />} route='home' primary='Home' />
            </List>
        );
    }

    return (
        <List>
            <MenuEntry icon={<I.Home />} route='home' primary='Home' />
            {perm.hasPatients && (
                <MenuEntry
                    icon={<I.Face />}
                    route='patients'
                    primary='Patients'
                />
            )}
            {perm.hasPractice && (
                <MenuEntry
                    icon={<I.LocalHospital />}
                    route='practices'
                    primary='Practices'
                />
            )}
            {perm.hasFacility && (
                <MenuEntry
                    icon={<I.Apartment />}
                    route='facilities'
                    primary='Facilities'
                />
            )}
            {perm.hasFacility && (
                <MenuEntry
                    icon={<I.Speed />}
                    route='facilitiesCms'
                    primary='CMS Facilities'
                />
            )}
            {perm.hasTickets && (
                <MenuEntry
                    icon={<I.Dashboard />}
                    route='dashboard'
                    primary='Dashboard'
                />
            )}
            {perm.isSuperuser && (
                <>
                    <MenuEntry
                        icon={<I.SupervisorAccount />}
                        route='siteAdmin'
                        primary='Site Admin'
                    />
                    {/*
                    <MenuEntry
                        icon={<I.SupervisorAccount />}
                        route='visits'
                        primary='Visits'
                    />
                    */}
                </>
            )}
        </List>
    );
}

function PatientNavDrawer({open, onClose}) {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <Drawer
                    variant='persistent'
                    classes={{paper: classes.drawer}}
                    open
                >
                    <Toolbar>
                        <img
                            alt='logo'
                            src='/img/SamePageMDLogo-2.png'
                            className={classes.logo}
                        />
                    </Toolbar>
                    <Divider />
                    <MenuItems />
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    classes={{paper: classes.drawer}}
                    open={open}
                    onClose={onClose}
                >
                    <MenuItems />
                </Drawer>
            </Hidden>
        </>
    );
}

function LegacyNavDrawer({patient, open, onClose}) {
    return (
        <Drawer open={open} onClose={onClose}>
            <MenuItems />
        </Drawer>
    );
}

export default function NavDrawer({patient, open, onClose}) {
    return patient ? (
        <PatientNavDrawer open={open} onClose={onClose} />
    ) : (
        <LegacyNavDrawer open={open} onClose={onClose} />
    );
}
