import React from 'react';

import {useSelector} from 'react-redux';

import BasePage from 'ccm/components/BasePage';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import UserProfile from 'ccm/components/UserProfile';

export default function ProfilePage() {
    const curUser = useSelector(selectCurrentUser);
    return (
        <BasePage wide title='User Profile'>
            <UserProfile user={curUser} />
        </BasePage>
    );
}
