import {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import fp from 'lodash/fp';
import {useSnackbar} from 'notistack';

import {useApi, ducks} from '@arborian/narrf';

class TicketActions {
    constructor({api, include, enqueueSnackbar, history, dispatch}) {
        this.api = api;
        this.include = include;
        this.enqueueSnackbar = enqueueSnackbar;
        this.history = history;
        this.dispatch = dispatch;
    }

    async success(message) {
        this.enqueueSnackbar(message, {persist: false, variant: 'success'});
    }

    async error(message, err) {
        message = await this.formatErrorMessage(message, err);
        console.error(message, err);
        this.enqueueSnackbar(message, {persist: false, variant: 'error'});
    }

    async warning(message, err, persist = false) {
        message = await this.formatErrorMessage(message, err);
        console.warn(message, err);
        this.enqueueSnackbar(message, {
            persist: persist,
            variant: 'warning',
            action: '',
        });
    }

    batch = ({success_message, error_message}) => {
        return new TicketActionBatch({success_message, error_message, ...this});
    };

    async formatErrorMessage(message, err) {
        const err_code = fp.get('response.status', err);
        let detail = null;
        if (err_code) {
            detail = `${err_code}`;
            detail += ' ' + (await err.text());
            message = `${message}: API error: ${detail}`;
        }
        return message;
    }

    refresh = async ticket => {
        return await this.api.fetchJsonApi(ticket.links.self, {
            include: this.include,
        });
    };

    resolve = async (ticket, formValues) => {
        const data = {
            type: 'TicketResolution',
            attributes: formValues,
        };
        await this.api.fetchJsonApi(
            ticket.relationships.resolution.links.related,
            {
                method: 'POST',
                json: {data},
            },
        );
    };

    acknowledge = async (ticket, {comments}) => {
        const data = {
            type: 'TicketAck',
            attributes: {
                comments,
            },
        };
        await this.api.fetchJsonApi(
            ticket.relationships.acknowledgement.links.related,
            {
                method: 'POST',
                json: {data},
            },
        );
    };

    reopen = async ticket => {
        await this.api.fetchJsonApi(ticket.relationships.reopen.links.related, {
            method: 'POST',
        });
    };

    put = async (ticket, options = {}) => {
        console.log('PUT', ticket);
        // Like patch below, but expects a fully populated new ticket (minus relationships) as its arg
        const {
            success_message = 'Ticket updated',
            error_message = 'Error updating ticket',
            precondition_failed_message = 'Warning: Ticket information out-of-date.\n\nPlease review the updated ticket and try again.',
        } = options;
        const newTicket = ticket;
        // const newTicket = fp.omit(['relationships', 'meta'], ticket);
        // console.log('New ticket', newTicket);
        try {
            await this.api.fetchJsonApi(ticket.links.self, {
                method: 'PUT',
                include: this.include,
                json: {data: newTicket},
            });
            await this.success(success_message);
        } catch (err) {
            if (err.response.status === 409) {
                this.refresh(ticket);
                await this.warning(precondition_failed_message, null, true);
            } else {
                await this.error(error_message, err);
            }
        }
    };

    patch = async (ticket, attributes, options = {}) => {
        const {type, id} = ticket;
        const {
            success_message = 'Ticket updated',
            error_message = 'Error updating ticket',
            precondition_failed_message = 'Warning: Ticket information out-of-date.\n\nPlease review the updated ticket and try again.',
        } = options;
        console.log('Before merge', {
            ticketAttributes: ticket.attributes,
            attributes,
        });
        attributes = fp.merge(ticket.attributes, attributes);
        console.log('After merge', attributes);
        try {
            await this.api.fetchJsonApi(ticket.links.self, {
                method: 'PATCH',
                include: this.include,
                json: {data: {type, id, attributes}},
            });
            await this.success(success_message);
        } catch (err) {
            if (err.response.status === 409) {
                this.refresh(ticket);
                await this.warning(precondition_failed_message, null, true);
            } else {
                await this.error(error_message, err);
            }
        }
    };

    sendFax = async (ticket, fax_numbers) => {
        if (!fax_numbers || !fax_numbers.length) {
            this.error('No fax number(s) specified.');
            return;
        }
        try {
            await this.api.fetchJson(ticket.relationships.fax.links.related, {
                method: 'POST',
                json: {fax_numbers},
            });
            const numbers = fp.join(', ', fax_numbers);
            await this.success(`Fax(es) scheduled to be sent to ${numbers}.`);
        } catch (err) {
            await this.error('Unable to fax ticket', err);
        }
    };

    route = async ticket => {
        await this.api.fetchJson(ticket.links.route, {method: 'POST'});
        await this.success('Ticket re-routed');
    };

    clone = async (ticket, n) => {
        if (n < 1) {
            return;
        }
        await this.api.fetchJsonApi(ticket.links.clone, {
            method: 'POST',
            json: {n},
        });
        await this.refresh(ticket);
        if (n > 1) {
            await this.success(`${n} duplicate tickets created`);
        } else {
            await this.success('Duplicate ticket created');
        }
    };

    split = async (ticket, data) => {
        const n = data.newTickets.length;
        if (n < 1) {
            return;
        }
        await this.api.fetchJsonApi(ticket.links.split, {
            method: 'POST',
            json: data,
        });
        await this.refresh(ticket);
        await this.success(`Split ticket into ${n} new ticket(s)`);
    };

    updateEHRSnapshot = async ticket => {
        try {
            await this.api.fetchJsonApi(ticket.links['snapshot_ehr'], {
                method: 'POST',
                include: this.include,
            });
            await this.success('EHR data updated.');
        } catch (err) {
            await this.error('Error updating data from EHR', err);
        }
    };

    uploadPCC = async ticket => {
        try {
            await this.api.fetchJson(ticket.links.upload_pcc, {
                method: 'POST',
            });
            await this.success('Ticket uploaded to PointClickCare.');
        } catch (err) {
            await this.error('Error uploading ticket to PointClickCare', err);
        }
    };

    uploadPF = async ticket => {
        try {
            await this.api.fetchJson(ticket.links.upload_pf, {
                method: 'POST',
            });
            await this.success('Ticket uploaded to Practice Fusion.');
        } catch (err) {
            await this.error('Error uploading ticket to Practice Fusion', err);
        }
    };

    archive = async ticket => {
        const {type, id} = ticket;
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {data: {id, type, attributes: {archived: true}}},
            });
            await this.success('Ticket archived.');
        } catch (err) {
            await this.error('Error archiving ticket', err);
        }
    };

    unarchive = async ticket => {
        const {type, id} = ticket;
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {data: {id, type, attributes: {archived: false}}},
            });
            await this.success('Ticket un-archived.');
        } catch (err) {
            await this.error('Error archiving ticket', err);
        }
    };

    createProgressNote = async (
        ticket,
        {documentUploadEHRs, progressNoteEHRs, patientFilesUpload},
    ) => {
        try {
            await this.api.fetchJson(
                ticket.relationships.progressNotes.links.related,
                {
                    method: 'POST',
                    json: {
                        documentUploadEHRs,
                        progressNoteEHRs,
                        patientFilesUpload,
                    },
                },
            );
        } catch (err) {
            await this.error('Error creating progress note(s)', err);
        }
    };

    setPatient = async (ticket, patient) => {
        console.log('setPatient', ticket, patient);
        const data = patient ? {type: patient.type, id: patient.id} : null;
        try {
            await this.api.fetchJson(ticket.relationships.patient.links.self, {
                method: 'PATCH',
                json: {data},
            });
            await this.refresh(ticket);
            await this.success('Patient updated');
        } catch (error) {
            console.error('Error updating patient', error);
            await this.error('Patient update failed', error);
        }
    };

    setTicketType = async (ticket, ticket_type) => {
        const {type, id} = ticket;
        const data = fp.merge(ticket.attributes.data, {ticket_type});
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {
                    data: {
                        id,
                        type,
                        attributes: {data},
                    },
                },
            });
            await this.success('Ticket Type Updated.');
        } catch (err) {
            await this.error('Error updating ticket', err);
        }
    };

    toggleUrgent = async ticket => {
        const {type, id} = ticket;
        try {
            const newPriority =
                fp.get('attributes.priority', ticket) === 'normal'
                    ? 'urgent'
                    : 'normal';
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {data: {id, type, attributes: {priority: newPriority}}},
            });
            await this.success('Ticket updated.');
        } catch (err) {
            await this.error('Error updating ticket', err);
        }
    };

    disable_auto_route = async ticket => {
        const {type, id} = ticket;
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {
                    data: {id, type, attributes: {disable_auto_route: true}},
                },
            });
            await this.success('Automatic routing disabled.');
        } catch (err) {
            await this.error('Error disabling automatic ticket routing', err);
        }
    };

    enable_auto_route = async ticket => {
        const {type, id} = ticket;
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {
                    data: {id, type, attributes: {disable_auto_route: false}},
                },
            });
            await this.success('Automatic routing enabled.');
        } catch (err) {
            await this.error('Error enabling automatic ticket routing', err);
        }
    };

    create_signature_request = async ticket => {
        try {
            const signatureForm = fp.get(
                'relationships.signature_form',
                ticket,
            );
            await this.api.fetchJsonApi(signatureForm.links.related, {
                method: 'POST',
                include: ['pages'],
            });
            const resp = await this.refresh(ticket);
            return resp;
        } catch (err) {
            await this.error('Unable to create signature request', err);
        }
    };
    delete_signature_request = async ticket => {
        try {
            const signatureForm = fp.get(
                'relationships.signature_form',
                ticket,
            );
            await this.api.fetchJsonApi(signatureForm.links.related, {
                method: 'DELETE',
            });
            await this.refresh(ticket);
            this.dispatch(ducks.jsonapi.deleteData(signatureForm.data));
        } catch (err) {
            await this.error('Unable to create signature request', err);
        }
    };
}

class TicketActionBatch extends TicketActions {
    constructor({success_message, error_message, ...args}) {
        super(args);
        this.success_message = success_message;
        this.error_message = error_message;
        this._num_success = this._num_error = 0;
    }

    async success(message) {
        console.log(message);
        this._num_success++;
    }

    async error(message, error) {
        console.error(await this.formatErrorMessage(message, error), error);
        this._num_error++;
    }

    async flush() {
        if (this._num_success && !this._num_error) {
            await super.success(this.success_message);
        } else if (this._num_error) {
            const total = this._num_error + this._num_success;
            await super.error(
                `${this.error_message} (${this._num_error}/${total} errors)`,
            );
        }
        this._num_success = this._num_error = 0;
    }
}

export function useTicketActions(include = []) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();

    const ticketActions = useMemo(
        () =>
            new TicketActions({
                api,
                include,
                enqueueSnackbar,
                history,
                dispatch,
            }),
        [api, include, enqueueSnackbar, history, dispatch],
    );

    return ticketActions;
}
