import React, {useMemo} from 'react';
import fp from 'lodash/fp';

import {Chip} from '@material-ui/core';

import {
    SignalCellular0Bar,
    SignalCellular1Bar,
    SignalCellular2Bar,
    SignalCellular3Bar,
    SignalCellular4Bar,
} from '@material-ui/icons';

const DEFAULT_BREAKPOINTS = [100, 75, 50, 0];
const ICONS = [
    SignalCellular4Bar,
    SignalCellular3Bar,
    SignalCellular2Bar,
    SignalCellular1Bar,
    SignalCellular0Bar,
];

export default function ProgressIcon({value, breakpoints}) {
    if (!breakpoints) breakpoints = DEFAULT_BREAKPOINTS;

    const pct = `${value}%`;
    const Result = useMemo(() => {
        let Result = SignalCellular0Bar;

        fp.pipe([
            fp.zip(ICONS),
            fp.forEach(([Icon, b]) => {
                if (value >= b) {
                    Result = Icon;
                    return false; // stops iteration
                }
            }),
        ])(breakpoints);
        return Result;
    }, [value, breakpoints]);

    return <Chip icon={<Result />} label={pct} variant='outlined' />;
}
