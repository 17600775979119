import React from 'react';

import {Tabs, Tab, TabPanel, TabProvider} from 'ccm/components/TabPanels';

import BasePage from 'ccm/components/BasePage';
import TicketsTable from 'ccm/components/TicketsTable';
import TicketReports from 'ccm/components/TicketReports';

export default function TicketsDashboardPage() {
    return (
        <BasePage wide title='Dashboard'>
            <TabProvider queryKey='tab' defaultTab='my'>
                <Tabs variant='scrollable'>
                    <Tab value='my' label='My Tickets' />
                    <Tab value='all' label='All Tickets' />
                    <Tab value='unassigned' label='Unassigned Tickets' />
                    <Tab value='reports' label='Reports' />
                </Tabs>
                <TabPanel index='my'>
                    <TicketsTable tab='my' />
                </TabPanel>
                <TabPanel index='all'>
                    <TicketsTable tab='all' />
                </TabPanel>
                <TabPanel index='unassigned'>
                    <TicketsTable tab='unassigned' />
                </TabPanel>
                <TabPanel index='reports'>
                    <TicketReports />
                </TabPanel>
            </TabProvider>
        </BasePage>
    );
}
