import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    filters: {
        '& form': {
            width: '100%',
        },
    },
    actions: {
        margin: theme.spacing(1, 0),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    scheduleCalendar: {
        '& .rbc-time-content': {
            visibility: 'hidden',
            height: 0,
        },
    },
    monthView: {
        height: 500,
    },
}));
