import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import TelecomChips from 'ccm/components/TelecomChips';

import {useContacts} from 'ccm/dataSource';

const {dt2} = components;

export default function ContactsTable({patient, title, ...props}) {
    const ds = useContacts(patient);
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column
                title='Name'
                field='attributes.name.last'
                render={fp.get('data.attributes.name.formatted')}
            />
            <dt2.Column
                title='Address'
                field='attributes.address.oneLine'
                filtering={false}
                sorting={false}
            />
            <dt2.Column
                title='Telecom'
                filtering={false}
                sorting={false}
                field='attributes.telecom'
                component={TelecomChips}
            />
            <dt2.Column title='Email' field='attributes.email.email' />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
