import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

import {components} from '@arborian/narrf';
import {usePractices} from 'ccm/dataSource';
import PracticeTable from 'ccm/components/PracticeTable';

const {dt2} = components;

export default function PracticeSelectDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const dsPractice = usePractices({
        prefix: null,
        initialFetchOptions: {
            sort: {field: 'attributes.name'},
            page: {number: 0, size: 5},
        },
    });

    const handleSelect = (ev, row) => {
        if (row) {
            resolve({action: 'select', practice: row.data});
        } else {
            resolve({action: 'select', practice: null});
        }
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    dialogRef.current = initialValues => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <DialogTitle>Practice Selection</DialogTitle>
            <DialogContent>
                <PracticeTable dataSource={dsPractice}>
                    <dt2.Action
                        name='select'
                        onClick={handleSelect}
                        tooltip='Select this practice'
                        icon='check'
                    />
                    <dt2.Action
                        free // free actions dont' send a row
                        name='deselect'
                        onClick={handleSelect}
                        tooltip='De-select any practice'
                        icon='cancel'
                    />
                </PracticeTable>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
