import React, {createContext, useState, useEffect, useCallback} from 'react';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

const Context = createContext();

var _pending = {};

const _fetchValue = async (api, route) => {
    await api.bootstrap();
    const pendingFetch = fp.get([route], _pending);
    if (pendingFetch) {
        const resp = await pendingFetch;
        return resp;
    } else {
        const promise = api.fetchJson(api.url_for(route));
        _pending[route] = promise;
        try {
            const resp = await promise;
            return resp;
        } catch (e) {
            delete _pending[route];
            return null;
        }
    }
};

export const useUrl = route => {
    const api = useApi();
    const {values, setValues} = React.useContext(Context);

    const value = fp.get([route], values);

    const setValue = useCallback(
        newValue =>
            setValues(values => {
                const oldValue = fp.get([route], values);
                if (fp.isEqual(newValue, oldValue)) {
                    return values;
                }
                const newValues = fp.set([route], newValue, values);
                return newValues;
            }),
        [route, setValues],
    );

    const fetchValue = useCallback(async () => {
        const value = await _fetchValue(api, route);
        setValue(value);
    }, [api, route, setValue]);

    useEffect(() => {
        if (fp.isUndefined(value)) {
            fetchValue();
        }
    }, [value, fetchValue]);

    return value;
};

export function ConstantUrlsProvider({children}) {
    const [values, setValues] = useState({});

    const value = {
        values,
        setValues,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
}
