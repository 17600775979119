import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Tooltip, Button, IconButton, useMediaQuery} from '@material-ui/core';
import {PostAdd} from '@material-ui/icons';

import {components, useApi} from '@arborian/narrf';

import {url_for, useQuery} from 'ccm/routes';
import {fieldId} from 'ccm/lib/util';
import * as h from 'ccm/lib/helpers';
import {
    selectCurrentUser,
    selectPracticeLookup,
    selectFacilityLookup,
} from 'ccm/lib/selectors-ccm';

import {usePatients} from 'ccm/dataSource';
import BasePage from 'ccm/components/BasePage';
import PatientCards from 'ccm/components/PatientCards';
import PatientTable from 'ccm/components/PatientTable';
import PatientTableActions from 'ccm/components/PatientTableActions';

import {useGlobals} from 'ccm/components/Globals';
import {useDialog} from 'ccm/components/dialogs';
import UnlistedPatientDialog from 'ccm/components/dialogs/UnlistedPatientDialog';

const {dt2} = components;

function NewTicket({row}) {
    const api = useApi();
    const history = useHistory();
    const handleClick = async ev => {
        const url = api.url_for('ticketsession.TicketSessionCollection');
        const session = await api.fetchJsonApi(url, {
            method: 'POST',
            json: {data: row.data},
        });
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        history.push(enterFormFlow);
    };
    if (!fp.get('relationships.practice.data.id', row.data)) return null;
    const primary_patient_id = fp.get(
        'relationships.primary_patient.data.id',
        row.data,
    );

    return primary_patient_id ? (
        <Link to={url_for('patient', {patientId: primary_patient_id})}>
            Primary
        </Link>
    ) : (
        <Tooltip title='Create ticket'>
            <IconButton
                onClick={handleClick}
                id={fieldId('newTicket', row.data.id)}
            >
                <PostAdd />
            </IconButton>
        </Tooltip>
    );
}

function TableView({
    dataSource,
    activeOnly,
    onChangeListGrid,
    onChangeActiveOnly,
}) {
    const api = useApi();
    const tableRef = useRef();
    const user = useSelector(selectCurrentUser);
    const lookupPractice = useSelector(selectPracticeLookup);
    const lookupFacility = useSelector(selectFacilityLookup);
    const globals = useGlobals();

    const roles = useMemo(
        () =>
            fp.pipe([
                fp.get('relationships.groups.data'),
                fp.map(g => {
                    const m = g.id.match(/(.+):(.+)\/(.+)/);
                    return `${m[1]}-${m[3]}`;
                }),
                fp.sortBy(fp.identity),
                fp.sortedUniq,
            ])(user),
        [user],
    );

    const showDetails = useMemo(() => {
        return !fp.isEmpty(
            fp.intersection(roles, [
                'practice-staff',
                'practice-admin',
                'practice-provider',
                'facility-admin',
                'facility-staff',
            ]),
        );
    }, [roles]);

    useEffect(() => {
        if (showDetails) {
            globals.fetchAll('facility.FacilityCollection');
            globals.fetchAll('practice.PracticeCollection');
        }
    }, [api, globals, showDetails]);

    const options = useMemo(() => {
        return {
            search: true,
            filtering: showDetails,
            selection: showDetails,
        };
    }, [showDetails]);

    return (
        <PatientTable
            id='patients'
            tableRef={tableRef}
            options={options}
            dataSource={dataSource}
        >
            <dt2.Column
                id='dob'
                title='DOB'
                type='date'
                field='attributes.dob'
                filterProps={{timeZone: 'UTC'}}
                render={row =>
                    h.formatDateOnly(fp.get('data.attributes.dob', row))
                }
            />
            {showDetails ? (
                <>
                    {/* Yes, these should be relationships. See the schemas.py for the full apology. */}
                    <dt2.Column
                        id='facility'
                        title='Facility'
                        editable={false}
                        field='attributes.facility_id'
                        lookup={lookupFacility}
                    />
                    <dt2.Column
                        id='practice'
                        title='Practice'
                        field='attributes.practice_id'
                        lookup={lookupPractice}
                    />
                </>
            ) : null}
            <dt2.Column
                filtering={false}
                sorting={false}
                component={NewTicket}
            />
            <PatientTableActions ds={dataSource} tableRef={tableRef} />
            <dt2.Action
                name='grid'
                free
                onClick={ev => onChangeListGrid('grid')}
                icon='grid_view'
                tooltip='View as grid'
            />
            <dt2.Action
                name='activeOnly'
                free
                onClick={ev => onChangeActiveOnly(true)}
                hidden={activeOnly}
                icon='filter_alt'
                tooltip='Show only active patients'
            />
            <dt2.Action
                name='allPatients'
                free
                onClick={ev => onChangeActiveOnly(false)}
                hidden={!activeOnly}
                icon='select_all'
                tooltip='Show all patients'
            />
        </PatientTable>
    );
}

const dsHasNonQueryFilters = ds => {
    let result = false;
    fp.pipe([
        fp.get('fetchOptions.filter'),
        fp.toPairs,
        fp.forEach(([key, value]) => {
            if (key === '_q') return;
            if (key === 'attributes.active' && value === true) return;
            result = true;
            return false;
        }),
    ])(ds);
    return result;
};

export default function PatientsPage() {
    const api = useApi();
    const history = useHistory();
    const query = useQuery();
    const [activeOnly, setActiveOnly] = useState(true);
    const ds = usePatients({activeOnly});
    const narrow = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const unlistedPatientDialog = useDialog(UnlistedPatientDialog);

    const listGrid = useMemo(() => {
        const queryView = query.get('view');
        if (queryView) return queryView;
        if (narrow) return 'grid';
        if (dsHasNonQueryFilters(ds) || ds.count > 5) {
            return 'list';
        } else {
            return 'grid';
        }
    }, [query, ds, narrow]);

    const setPatientView = useCallback(
        value => {
            query.set('view', value);
            history.push('?' + query.toString());
        },
        [query, history],
    );

    const handleUnlistedPatient = async ev => {
        const result = await unlistedPatientDialog();
        if (!result) return;
        const session = await api.fetchJson(
            api.url_for('ticketsession.UnlistedPatientTicketSession'),
            {
                method: 'POST',
                json: {
                    facility_id: result.facility.id,
                    practice_id: result.practice.id,
                },
            },
        );
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        history.push(enterFormFlow);
    };

    return (
        <BasePage wide title='Patients'>
            <div>
                {listGrid === 'grid' ? (
                    <PatientCards
                        dataSource={ds}
                        onChangeListGrid={setPatientView}
                    />
                ) : (
                    <TableView
                        dataSource={ds}
                        onChangeListGrid={setPatientView}
                        activeOnly={activeOnly}
                        onChangeActiveOnly={setActiveOnly}
                    />
                )}
                <Button
                    id={fieldId('newTicket', 'unlisted')}
                    startIcon={<PostAdd />}
                    size='small'
                    onClick={handleUnlistedPatient}
                >
                    Create ticket for unlisted patient
                </Button>
            </div>
        </BasePage>
    );
}
