import React from 'react';
import fp from 'lodash/fp';

import {
    makeStyles,
    Checkbox,
    Link,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core';

import {Alert} from '@material-ui/lab';
import {fieldId, useConstants} from 'ccm/lib/util';

const useStyles = makeStyles(theme => ({
    root: {
        '& p': {
            ...theme.typography.body1,
        },
        '& .MuiFormControlLabel-root': {
            ...theme.typography.body1,
        },
    },
}));

export default function ConsentFormAlert({practices, value, onChange}) {
    const classes = useStyles();
    const constants = useConstants();
    const practicesWithConsentForms = fp.filter(
        p => fp.get('links.consent_form_url', p),
        practices,
    );
    const defaultConsent = constants?.default_consent;
    const defaultConsentBlobContent = fp.get(
        'blob.data.relationships.content.links.related',
        defaultConsent,
    );
    const consentLink = defaultConsent ? (
        <Link
            href={defaultConsentBlobContent}
            // href={defaultConsent.blob.data.relationships.content.links.related}
            target='_blank'
        >
            {defaultConsent.title}
        </Link>
    ) : (
        // This is a fallback and should never happen unless we forget to set the
        // default consent.
        <>
            the SamePageMD{' '}
            <Link href='/tos.html' target='_blank'>
                terms of service
            </Link>{' '}
            and{' '}
            <Link href='/privacy.html' target='_blank'>
                privacy policy
            </Link>
        </>
    );

    const agreementLabel = fp.isEmpty(practicesWithConsentForms) ? (
        <>
            I consent to transmit protected health information electronically as
            covered in the {consentLink}.
        </>
    ) : (
        'I agree to the above consent agreements.'
    );
    return (
        <Alert variant='outlined' severity='info'>
            <div className={classes.root}>
                <p>
                    By using SamePageMD, you are consenting to transmit
                    protected health information electronically as covered in
                    the SamePageMD terms of service and privacy policy.
                </p>

                {!fp.isEmpty(practicesWithConsentForms) && (
                    <ul>
                        {fp.map(
                            p => (
                                <li>
                                    <a
                                        href={fp.get(
                                            'links.consent_form_url',
                                            p,
                                        )}
                                    >
                                        Review the consent agreement for{' '}
                                        {fp.get('attributes.name', p)}
                                    </a>
                                </li>
                            ),
                            practicesWithConsentForms,
                        )}
                    </ul>
                )}

                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={fieldId('consent', 'agree')}
                                checked={value}
                                onChange={onChange}
                                name='checked'
                            />
                        }
                        label={agreementLabel}
                    />
                </FormGroup>
            </div>
        </Alert>
    );
}
