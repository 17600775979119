import React, {useState, useEffect} from 'react';

import {lighten} from '@material-ui/core/styles/colorManipulator';
import {
    IconButton,
    Toolbar as MUIToolbar,
    TextField,
    InputAdornment,
    makeStyles,
} from '@material-ui/core';
import {Clear, Search} from '@material-ui/icons';

import {Add, Refresh} from './ActionButtons';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: theme.spacing(0, 1, 0, 3),
    },
    flex: {
        flex: 1,
    },
    selecting: {
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
}));

function SearchField({value, onChange}) {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputValue(value || '');
    }, [value]);
    const acceptChange = ev => onChange(inputValue);
    return (
        <TextField
            value={inputValue}
            onChange={ev => setInputValue(ev.target.value)}
            onBlur={acceptChange}
            onKeyPress={ev => ev.key === 'Enter' && acceptChange()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        {value ? (
                            <IconButton onClick={e => onChange(null)}>
                                <Clear />
                            </IconButton>
                        ) : (
                            <IconButton onClick={acceptChange}>
                                <Search />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default function Toolbar({dataSource}) {
    const classes = useStyles();
    let toolbarClasses = [classes.toolbar];
    if (dataSource.selectedItems.length > 0) {
        toolbarClasses.push(classes.selecting);
    }
    return (
        <MUIToolbar className={toolbarClasses}>
            {dataSource.selectedItems.length > 0 ? (
                <h6>{dataSource.selectedItems.length}</h6>
            ) : (
                <h6>Files</h6>
            )}
            <div className={classes.flex} />
            <SearchField
                value={dataSource.fetchOptions.search}
                onChange={dataSource.setSearch}
            />
            <Add onClick={dataSource.handleUpload} />
            <Refresh onClick={dataSource.fetch} />
        </MUIToolbar>
    );
}
