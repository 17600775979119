import React from 'react';
import * as yup from 'yup';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {Button} from '@material-ui/core';

const initialValues = {
    old_password: '',
    password: '',
    password2: '',
};

const validationSchema = yup.object().shape({
    old_password: yup.string().required(),
    password: yup
        .string()
        .min(6)
        .required()
        .notOneOf(
            [yup.ref('old_password'), null],
            'Cannot re-use old password',
        ),
    password2: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords don't match"),
});

export default function PasswordChangeForm({submitLabel, onSubmit}) {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({touched, values, errors}) => (
                <Form>
                    <Field
                        component={TextField}
                        type='password'
                        label='Old Password'
                        name='old_password'
                        fullWidth
                    />
                    <Field
                        component={TextField}
                        type='password'
                        label='Password'
                        name='password'
                        fullWidth
                    />
                    <Field
                        component={TextField}
                        type='password'
                        label='Password (again)'
                        name='password2'
                        fullWidth
                    />
                    <Button type='submit' variant='contained' color='primary'>
                        Change Password
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
