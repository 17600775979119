import React, {useState, useEffect, useCallback} from 'react';
import fp from 'lodash/fp';
import {IconButton, Icon, Tooltip, makeStyles} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';

import {useTableManager} from './Provider';
import {useRenderProps} from './utils';

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
    icon: {
        transition: 'all ease 200ms',
    },
    openIcon: {
        transform: 'rotate(90deg)',
    },
}));

function makeIcon(icon, defaultIcon, iconProps) {
    if (!icon) {
        return defaultIcon;
    } else if (fp.isString(icon)) {
        return <Icon {...iconProps}>{icon}</Icon>;
    } else {
        return icon;
    }
}

export default function DTableDetail({
    id,
    children,
    icon,
    openIcon,
    tooltip,
    iconProps,
    render,
    disabled = false,
}) {
    const tm = useTableManager();
    const classes = useStyles();
    const [_id] = useState(() => Math.random().toString());
    if (!id) id = _id;

    const renderProps = useRenderProps(
        {
            children,
            icon,
            openIcon,
            tooltip,
            iconProps,
            render,
            disabled,
        },
        [children, icon, openIcon, tooltip, iconProps, render, disabled],
    );

    const renderIcon = useCallback(
        (row, onSelectDetail) => {
            let props = renderProps(row);
            const isOpen = row.detail === id;
            const closedIcon = makeIcon(
                props.icon,
                <ChevronRight {...props.iconProps} />,
                props.iconProps,
            );
            const openIcon = makeIcon(
                props.openIcon,
                closedIcon,
                props.iconProps,
            );
            const buttonClass =
                isOpen && !props.openIcon ? classes.openIcon : null;
            let result = isOpen ? openIcon : closedIcon;
            result = (
                <IconButton
                    className={buttonClass + ' ' + classes.icon}
                    onClick={e => onSelectDetail(row.id, id)}
                    disabled={props.disabled}
                >
                    {result}
                </IconButton>
            );
            if (!props.disabled && props.tooltip) {
                result = <Tooltip title={props.tooltip}>{result}</Tooltip>;
            }
            return result;
        },
        [id, classes.icon, classes.openIcon, renderProps],
    );

    useEffect(() => {
        tm.setDetailPanel(id, {
            id,
            children,
            icon,
            tooltip,
            iconProps,
            render,
            disabled,
            renderIcon,
        });
    }, [id, children, icon, tooltip, iconProps, render, renderIcon, disabled]); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
}
