import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {IconButton, Tooltip} from '@material-ui/core';
import {AddCircleOutline} from '@material-ui/icons';

import {useApi, ducks, components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';
import {url_for} from 'ccm/routes';
import {useAllFacilitiesLookup} from 'ccm/dataSource/allResources';
import {useDialog} from 'ccm/components/dialogs';
import PatientTable from 'ccm/components/PatientTable';

import {usePracticePatientTags} from './hooks';
import PatientVisit from './PatientVisit';
import AddTagDialog from '../../tags/AddTagDialog';
import TagChip from '../../tags/TagChip';

const {dt2} = components;

function PrimaryPatient({patient}) {
    const linkage = fp.get('relationships.primary_patient.data', patient);
    const pp = useSelector(
        ducks.jsonapi.selectObject([
            fp.get('type', linkage),
            fp.get('id', linkage),
        ]),
    );
    const ehr_linkage = fp.get(
        'relationships.primary_patient_ehr_connection.data',
        patient,
    );
    const pp_ehr = useSelector(
        ducks.jsonapi.selectObject([
            fp.get('type', ehr_linkage),
            fp.get('id', ehr_linkage),
        ]),
    );
    const url = url_for('patient', {patientId: fp.get('id', pp)});
    if (!pp) return null;
    const ehrLabel = fp.get('attributes.label', pp_ehr);
    return (
        <Tooltip title={ehrLabel}>
            <Link to={url}>{fp.get('attributes.name.formatted', pp)}</Link>
        </Tooltip>
    );
}

function PatientTags({patient, canEdit, addDialog}) {
    const api = useApi();
    const practiceId = fp.get('attributes.practice_id', patient);
    const tags = fp.pipe([
        fp.get('attributes.tags'),
        fp.filter(t => t.id.startsWith(`${practiceId}:`)),
        fp.sortBy('id'),
    ])(patient);
    const refreshPatient = async () =>
        api.fetchJsonApi(patient.links.self, {
            include: ['last_visit', 'next_visit'],
        });
    const handleAdd = async () => {
        const resp = await addDialog();
        if (!resp) return;
        const tag = fp.get('tag', resp); // tag is just name:label
        if (fp.isEmpty(tag)) return;
        await api.fetchJson(
            fp.get('relationships.tagRelPractice.links.self', patient),
            {
                method: 'POST',
                json: {
                    data: [{type: 'Tag', id: `${practiceId}:${tag}`}],
                },
            },
        );
        await refreshPatient();
    };
    const handleDelete = async tag => {
        await api.fetchJson(
            fp.get('relationships.tagRelPractice.links.self', patient),
            {
                method: 'DELETE',
                json: {data: [{type: 'Tag', id: tag.id}]},
            },
        );
        await refreshPatient();
    };
    return (
        <span>
            {fp.map(
                t => (
                    <TagChip
                        key={t.id}
                        tagAttrs={t}
                        onDelete={canEdit ? ev => handleDelete(t) : null}
                    />
                ),
                tags,
            )}
            {canEdit && (
                <IconButton onClick={handleAdd}>
                    <AddCircleOutline />
                </IconButton>
            )}
        </span>
    );
}

async function UpsertTagToPatient(api, patient, tag) {
    const practiceId = fp.get('attributes.practice_id', patient);
    return await api.fetchJson(
        fp.get('relationships.tagRelPractice.links.self', patient),
        {
            method: 'POST',
            json: {
                data: [{type: 'Tag', id: `${practiceId}:${tag}`}],
            },
        },
    );
}

export default function PracticePatientTable({
    dataSource,
    practice,
    children,
    showTickets,
    options,
}) {
    const api = useApi();
    const lookupFacility = useAllFacilitiesLookup();
    const {items: tags} = usePracticePatientTags(practice);
    const addDialog = useDialog(AddTagDialog, {tags});
    const tagLookup = fp.pipe([
        fp.map(t => [t.attributes.label, t.attributes.label]),
        fp.fromPairs,
    ])(tags);
    const canEdit = fp.includes('Common.WRITE', practice.meta.allowed_actions);
    const handleBulkAdd = async (ev, patientRows) => {
        let attributes = await addDialog();
        const tag = fp.get('tag', attributes);
        if (fp.isEmpty(tag)) return;
        const promises = fp.map(
            patient => UpsertTagToPatient(api, patient.data, tag),
            patientRows,
        );
        await Promise.all(promises);
        dataSource.fetch();
    };
    return (
        <>
            <PatientTable
                id='practicePatients'
                title='Practice Patients'
                size='small'
                options={options}
                dataSource={dataSource}
                showAvatar={false}
            >
                <dt2.Column
                    id='col-dob'
                    title='DOB'
                    type='date'
                    field='attributes.dob'
                    filterProps={{timeZone: 'UTC'}}
                    render={row =>
                        h.formatDateOnly(fp.get('data.attributes.dob', row))
                    }
                />
                <dt2.Column
                    id='col-fac'
                    title='Facility'
                    editable={false}
                    field='attributes.facility_id'
                    lookup={lookupFacility}
                />
                <dt2.Column
                    title='Primary patient'
                    filtering={false}
                    render={row => <PrimaryPatient patient={row.data} />}
                />
                <dt2.Column
                    title='Last visit'
                    filtering={false}
                    render={row => (
                        <PatientVisit patient={row.data} name='last_visit' />
                    )}
                />
                <dt2.Column
                    title='Next visit'
                    filtering={false}
                    render={row => (
                        <PatientVisit patient={row.data} name='next_visit' />
                    )}
                />
                <dt2.Column
                    title='Tags'
                    field='attributes.tags'
                    lookup={tagLookup}
                    render={row => (
                        <PatientTags
                            addDialog={addDialog}
                            patient={row.data}
                            canEdit={canEdit}
                        />
                    )}
                />
                <dt2.Action
                    onClick={handleBulkAdd}
                    tooltip='Apply Tag'
                    icon='local_offer'
                />
                {children}
            </PatientTable>
        </>
    );
}
