import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';

import {useApi, components, ducks} from '@arborian/narrf';

import {useProgressNotes} from 'ccm/dataSource';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    details: {
        padding: theme.spacing(1),
        '& section p': {
            whiteSpace: 'pre-line',
        },
    },
}));

function ProgressNoteDetails({artifact}) {
    const classes = useStyles();
    const sections = fp.get('attributes.sections', artifact);
    return (
        <div className={classes.details}>
            {fp.map(
                s => (
                    <section key={s.name}>
                        <h6>{s.name}</h6>
                        <p>{s.value}</p>
                    </section>
                ),
                sections,
            )}
        </div>
    );
}

export default function ProgressNotesTable({
    patient,
    title,
    unfiltered = false,
    ...props
}) {
    const api = useApi();
    const ehrConnectionId = fp.get(
        'relationships.ehr_connection.data.id',
        patient,
    );
    const ehrConnection = useSelector(
        ducks.jsonapi.selectObject(['EHRConnection', ehrConnectionId]),
    );
    const ehrConfig = useSelector(
        ducks.jsonapi.selectObject(['EHRConfig', fp.get('id', ehrConnection)]),
    );

    useEffect(() => {
        const connectionUrl = fp.get(
            'relationships.ehr_connection.links.related',
            patient,
        );
        if (connectionUrl && !ehrConnection) {
            api.fetchJson(connectionUrl);
        }
    }, [api, patient, ehrConnection]);

    useEffect(() => {
        const configUrl = fp.get('links.config', ehrConnection);
        if (configUrl && !ehrConfig) {
            api.fetchJson(configUrl);
        }
    }, [api, ehrConnection, ehrConfig]);

    const noteCategories = fp.getOr(
        [],
        'attributes.portal_config.note_categories',
        ehrConfig,
    );
    const mergeFetchOptions =
        !unfiltered && noteCategories.length
            ? {
                  filter: {
                      'attributes.progressNoteType': {
                          $in: noteCategories,
                      },
                  },
              }
            : undefined;
    const initialFetchOptions = {
        page: {number: 0, size: 20},
        sort: {field: 'attributes.effectiveDateTime', direction: 'desc'},
    };
    const ds = useProgressNotes(patient, {
        mergeFetchOptions,
        initialFetchOptions,
    });

    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column
                title='Effective Date'
                field='attributes.effectiveDateTime'
                type='date'
                render={row => (
                    <DateTime
                        value={fp.get('attributes.effectiveDateTime', row.data)}
                    />
                )}
            />
            <dt2.Column title='Type' field='attributes.progressNoteType' />
            <dt2.DetailPanel
                render={row => {
                    console.log('Render detail', row);
                    return <ProgressNoteDetails artifact={row.data} />;
                }}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
