import React, {useRef} from 'react';

import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';
import {Alert} from '@material-ui/lab';

import {useApi, ducks, components} from '@arborian/narrf';

import {useRelated} from 'ccm/dataSource';

const {dt2} = components;

export default function FaxBackPanel({practice}) {
    const api = useApi();
    const tableRef = useRef();
    const dispatch = useDispatch();
    const ds = useRelated(practice, 'faxBackRules');

    const canWrite = fp.includes('Common.WRITE', practice.meta.allowed_actions);

    const handleCreate = async row => {
        const data = {
            type: 'FaxBackRule',
            attributes: {
                practice_id: practice.id,
                label: row.data.attributes.label,
                from_number: {
                    ...row.data.attributes.from_number,
                    system: 'fax',
                    use: 'work',
                },
                to_number: {
                    ...row.data.attributes.to_number,
                    system: 'fax',
                    use: 'work',
                },
            },
        };
        await api.fetchJson(api.url_for('faxRule.Collection'), {
            method: 'POST',
            json: {data},
        });
        ds.fetch();
    };

    const handleEdit = async (row, oldRow) => {
        await api.fetchJsonApi(row.data.links.self, {
            method: 'PUT',
            json: {data: row.data},
        });
    };

    const handleDelete = async row => {
        await api.fetchJson(row.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(row.data));
        ds.fetch();
    };

    const editable = canWrite
        ? {
              onRowAdd: handleCreate,
              onRowUpdate: handleEdit,
              onRowDelete: handleDelete,
          }
        : null;
    return (
        <div>
            <Alert severity='info'>
                <p>
                    When faxes are received from the "From Number", tickets will
                    be faxed back to the "To Number" by default.
                </p>
            </Alert>
            <dt2.DataTable
                id='fax-back-table'
                title='Fax-Back Rules'
                tableRef={tableRef}
                dataSource={ds}
                options={{
                    search: false,
                    filtering: false,
                }}
                editable={editable}
            >
                <dt2.Column title='Label' field='attributes.label' />
                <dt2.Column
                    title='From Number'
                    field='attributes.from_number.value'
                />
                <dt2.Column
                    title='To Number'
                    field='attributes.to_number.value'
                />
                <dt2.Action
                    id='table-refresh'
                    free
                    onClick={() => ds.fetch()}
                    tooltip='Refresh'
                    icon='refresh'
                />
                {canWrite && false && (
                    <>
                        <dt2.Action
                            id='table-add'
                            free
                            onClick={handleCreate}
                            tooltip='Add Rule'
                            icon='add'
                        />
                        <dt2.Action
                            id='table-edit'
                            onClick={handleEdit}
                            tooltip='Edit Rule'
                            icon='edit'
                        ></dt2.Action>
                        <dt2.Action
                            id='table-delete'
                            onClick={handleDelete}
                            tooltip='Delete Rule'
                            icon='delete'
                        ></dt2.Action>
                    </>
                )}
            </dt2.DataTable>
        </div>
    );
}
