import React from 'react';
import fp from 'lodash/fp';

import {TableRow, TableCell, makeStyles} from '@material-ui/core';
import {useTableManager} from './Provider';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiInputBase-root': {
            fontSize: '0.875rem',
        },
    },
}));

function FilterCell({column, filter, onChangeFilter}) {
    const tm = useTableManager();
    const idField = `${tm.id}-${column.id}-filter`;
    const FilterComponent = column.FilterComponent;
    if (!onChangeFilter || !column.filtering) return <TableCell />;
    return (
        <TableCell>
            <FilterComponent
                id={idField}
                column={column}
                filter={filter}
                onChangeFilter={onChangeFilter}
            />
        </TableCell>
    );
}

export default function FilterRow() {
    const tm = useTableManager();
    const classes = useStyles();
    const onChangeFilter = tm.options.filtering
        ? tm.dataSource.setFilter
        : null;
    return (
        <TableRow className={classes.root}>
            {tm.hasActionsColumn ? <TableCell /> : null}
            {tm.hasDetailPanels ? <TableCell /> : null}
            {fp.pipe([
                fp.toPairs,
                fp.map(([cid, col]) => (
                    <FilterCell
                        key={cid}
                        column={col}
                        filter={tm.fetchOptions.filter}
                        onChangeFilter={onChangeFilter}
                    />
                )),
            ])(tm.columns)}
        </TableRow>
    );
}
