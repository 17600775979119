import React from 'react';

import {components} from '@arborian/narrf';

import TelecomChips from 'ccm/components/TelecomChips';

const {dt2} = components;

export default function PracticeTable({dataSource, children}) {
    return (
        <dt2.DataTable
            size='small'
            dataSource={dataSource}
            options={{search: true}}
        >
            <dt2.Column title='Name' field='attributes.name' />
            <dt2.Column title='Address' field='attributes.address.oneLine' />
            <dt2.Column
                title='Telecom'
                field='attributes.telecom'
                component={TelecomChips}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            {children}
        </dt2.DataTable>
    );
}
