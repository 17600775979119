import {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectUsersWithGroup} from 'ccm/lib/selectors';
import {useApi, ducks} from '@arborian/narrf';
import fp from 'lodash/fp';

import {
    selectRuleShifts,
    selectRuleFacilityAssignments,
    selectRuleAssignments,
} from './shiftAssignmentSelectors';

export const useRule = ruleId => {
    const selector = useMemo(
        () => ducks.jsonapi.selectObject(['Rule', ruleId]),
        [ruleId],
    );
    return useSelector(selector);
};

export const useRuleShifts = rule => {
    const selector = useMemo(() => selectRuleShifts(rule), [rule]);
    return useSelector(selector);
};

export const useRuleFacilityAssignments = (rule, facilities) => {
    const selector = useMemo(
        () => selectRuleFacilityAssignments(rule, facilities),
        [rule, facilities],
    );
    return useSelector(selector);
};

export const useRuleAssignments = rule => {
    const selector = useMemo(() => selectRuleAssignments(rule), [rule]);
    return useSelector(selector);
};

export const usePractice = rule => {
    const practiceId = fp.get('relationships.practice.data.id', rule);
    const selector = useMemo(
        () => ducks.jsonapi.selectObject(['Practice', practiceId]),
        [practiceId],
    );
    return useSelector(selector);
};

export const usePracticeUsers = (practice, role) => {
    const api = useApi();
    const groupId = `practice:${practice?.id}/${role}`;

    const providersHref = fp.get(
        'relationships.providers.links.related',
        practice,
    );
    const staffHref = fp.get('relationships.staff.links.related', practice);
    const href = role === 'provider' ? providersHref : staffHref;

    useEffect(() => {
        api.fetchAllJsonApi(href);
    }, [api, href]);

    const selector = useMemo(() => selectUsersWithGroup(groupId), [groupId]);
    return useSelector(selector);
};
