import React from 'react';

import {TeamAvatar} from 'ccm/art/MedicalIcons';
import InfoCard from './InfoCard';

export default function ContactsCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={TeamAvatar}
            title='Patient Contacts'
            {...props}
        ></InfoCard>
    );
}
