import React, {useRef, useMemo, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import fp from 'lodash/fp';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar';
import {IconButton} from '@material-ui/core';
import {Refresh} from '@material-ui/icons';
import {Alert, AlertTitle} from '@material-ui/lab';
import * as locales from 'date-fns/locale';
import {add, format, parse, startOfWeek, getDay} from 'date-fns';

import {util, ducks, useApi} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';

import {useVisitFacilities} from '../hooks';
import VisitDialog from '../VisitDialog';

import useStyles from './styles';
import * as accessors from './accessors';
import VisitsFilter from './VisitsFilter';

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

export default function VisitsCalendar({practice}) {
    const api = useApi();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState('week');
    const [facColumns, setFacColumns] = useState(false);
    const visitDialog = useDialog(VisitDialog);
    const [visits, setVisits] = useState([]);
    const [fetchOptions, setFetchOptions] = useState({});
    const [fetchState, setFetchState] = useState('init');

    const calRef = useRef();
    const calendar = calRef.current;

    const dateRange = util.useMemoDebugger(() => {
        if (!calendar) return {start: null, end: null};
        const ViewComponent = calendar.getViews()[view];
        const localizer = calendar.props.localizer;
        const range = ViewComponent.range(date, {localizer});
        if (fp.isArray(range)) {
            return {
                start: range[0],
                end: add(range[range.length - 1], {days: 1}),
            };
        } else {
            return range;
        }
    }, [date, view, calendar]);

    const all_visits_url = fp.get(
        'relationships.allVisits.links.related',
        practice,
    );
    const fetchVisits = useMemo(
        () => async () => {
            if (dateRange.start === null) return;
            setFetchState('busy');
            setVisits([]);
            try {
                const dateFilter = {
                    $gte: {$date: dateRange.start},
                    $lte: {$date: dateRange.end},
                };
                const fullFetchOptions = fp.pipe([
                    fp.set(
                        ['filter', 'attributes.visit_date'],
                        dateFilter,
                        // JSON.stringify(dateFilter),
                    ),
                    fp.set('page.size', 200),
                ])(fetchOptions);
                const jsonapiOptions =
                    api.jsonApiFromDataTableOptions(fullFetchOptions);
                console.log('fetching visits', {
                    all_visits_url,
                    fetchOptions,
                    fullFetchOptions,
                    jsonapiOptions,
                });

                const result = await api.fetchAllJsonApi(
                    all_visits_url,
                    jsonapiOptions,
                );
                setFetchState('ready');
                setVisits(result || []);
            } catch (e) {
                setFetchState('error');
                throw e;
            }
        },
        [api, all_visits_url, fetchOptions, dateRange],
    );

    useEffect(() => {
        fetchVisits();
    }, [fetchVisits]);

    const facilities = useVisitFacilities(visits);
    const resources = facColumns ? facilities : null;
    console.log('Render calendar', {dateRange, resources});
    const min = new Date();
    const className =
        view === 'month'
            ? `${classes.scheduleCalendar} ${classes.monthView}`
            : classes.scheduleCalendar;

    const handleSelectEvent = async event => {
        const visit = event;
        console.log('select visit', visit);
        // const {action, data} = await dialogRef.current({practice, visit});
        const {action, data} = await visitDialog({practice, visit});
        if (action === 'CANCEL') return;
        else if (action === 'DELETE') {
            await api.fetchJson(fp.get('links.self', visit), {
                method: 'DELETE',
            });
            dispatch(ducks.jsonapi.deleteData(visit));
        } else if (action === 'SUBMIT') {
            const {type, links, attributes, relationships} = data;
            const fixedRelationships = fp.pipe([
                fp.toPairs,
                fp.map(([k, v]) => {
                    console.log(k, v);
                    if (v.data) {
                        return [k, {data: {id: v.data.id, type: v.data.type}}];
                    }
                    return null;
                }),
                fp.filter(item => !!item),
                fp.fromPairs,
            ])(relationships);
            console.log({fixedRelationships});

            await api.fetchJson(links.self, {
                method: 'PUT',
                json: {
                    data: {
                        type,
                        attributes,
                        relationships,
                        // relationships: fixedRelationships,
                    },
                },
            });
            await fetchVisits();
        }
    };

    const getEventProps = ev => {
        return {
            style: {
                color: fp.get('attributes.visit_type.color', ev),
                backgroundColor: fp.get(
                    'attributes.visit_type.backgroundColor',
                    ev,
                ),
            },
        };
    };

    return (
        <div>
            <VisitsFilter
                fetchOptions={fetchOptions}
                setFetchOptions={setFetchOptions}
                facColumns={facColumns}
                setFacColumns={setFacColumns}
                practice={practice}
                onRefresh={fetchVisits}
            />
            {fetchState === 'busy' ? (
                <Alert severity='info'>Fetching visits...</Alert>
            ) : fetchState === 'error' ? (
                <Alert severity='error'>
                    <AlertTitle>Error fetching visits</AlertTitle>
                    Click to retry:
                    <IconButton onClick={fetchVisits}>
                        <Refresh />
                    </IconButton>
                </Alert>
            ) : fetchState === 'ready' ? null : (
                <Alert severity='info'>
                    Click to fetch visits:
                    <IconButton onClick={fetchVisits}>
                        <Refresh />
                    </IconButton>
                </Alert>
            )}
            <Calendar
                ref={calRef}
                className={className}
                view={view}
                onView={setView}
                views={['month', 'week', 'day', 'agenda']}
                localizer={localizer}
                date={date}
                min={min}
                max={min}
                events={visits}
                onNavigate={setDate}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={getEventProps}
                resources={resources}
                {...accessors}
            />
        </div>
    );
}
