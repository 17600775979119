import React, {useEffect, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    makeStyles,
    Tooltip,
} from '@material-ui/core';
import {Edit, Check, Close, Add, DeleteForever} from '@material-ui/icons';

import {components, useApi, ducks} from '@arborian/narrf';
import {useListField} from 'ccm/dataSource';
import {fieldId} from 'ccm/lib/util';

const {dt2} = components;
const useStyles = makeStyles(theme => ({
    rangeObject: {
        '& .MuiTextField-root': {width: theme.spacing(10)},
    },
}));

function useTicketRangeSettings(ticket) {
    const api = useApi();
    const practice_id = fp.get('relationships.practice.data.id', ticket);
    const ticketCodes = useMemo(
        () =>
            fp.pipe([
                fp.get('attributes.data.session_data.event_data.testSet'),
                fp.map('results'),
                fp.flatten,
                fp.map('code.coding'),
                fp.flatten,
                fp.map(code => ({practice_id, ...code})),
            ])(ticket),
        [ticket, practice_id],
    );

    useEffect(() => {
        const rangeUrl =
            ticket &&
            api.url_for('practice.CodeRangeCollection', {
                practice_id,
            });

        fp.pipe([
            // Load codes & ranges into redux store
            fp.groupBy('system'),
            fp.toPairs,
            fp.forEach(([systemid, items]) => {
                console.log({systemid, items});
                const values = fp.map('code', items);
                const codeUrl = api.url_for('coding.Collection', {systemid});
                const codeFilter = {value: JSON.stringify({$in: values})};
                const rangeFilter = {...codeFilter, systemid};

                api.fetchAllJsonApi(rangeUrl, {filter: rangeFilter});
                api.fetchAllJsonApi(codeUrl, {filter: codeFilter});
            }),
        ])(ticketCodes);
    }, [api, ticketCodes, ticket, practice_id]);

    console.log({ticket, ticketCodes});
    return useListField(ticketCodes);
}

const selectPracticeCodeRange = (practice_id, system_id, value) =>
    createSelector(
        ducks.jsonapi.selectObject('ValueRange'),
        fp.pipe([
            fp.filter(
                range =>
                    fp.get('attributes.practice_id', range) === practice_id &&
                    fp.get('relationships.code.data.id', range) ===
                        `${system_id}/${value}`,
            ),
            fp.head,
        ]),
    );

const selectCode = (system_id, value) =>
    ducks.jsonapi.selectObject(['Code', `${system_id}/${value}`]);

const DEFAULT_RANGE = {
    type: 'ValueRange',
    attributes: {range_min: '', range_max: ''},
};

function RangeObjectEditForm({value, onSubmit, onCancel, onDelete}) {
    const classes = useStyles();
    const initialValues = value ? value : DEFAULT_RANGE;

    return (
        <div className={classes.rangeObject}>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({touched, values, errors, handleSubmit}) => (
                    <Form>
                        <Field
                            component={TextField}
                            id={fieldId('rangeObject', 'min')}
                            label='Min'
                            name='attributes.range_min'
                        />

                        {' \u2014 '}
                        <Field
                            component={TextField}
                            id={fieldId('rangeObject', 'max')}
                            label='Max'
                            name='attributes.range_max'
                        />
                        <Tooltip title='Save'>
                            <IconButton
                                id={fieldId('rangeObject', 'submit')}
                                onClick={handleSubmit}
                            >
                                <Check />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Cancel'>
                            <IconButton
                                id={fieldId('rangeObject', 'cancel')}
                                onClick={onCancel}
                            >
                                <Close />
                            </IconButton>
                        </Tooltip>
                        {value && (
                            <Tooltip title='Delete'>
                                <IconButton
                                    id={fieldId('rangeObject', 'delete')}
                                    onClick={onDelete}
                                >
                                    <DeleteForever />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

function CodeRange({row}) {
    const api = useApi();
    const dispatch = useDispatch();
    const [editing, setEditing] = useState(false);

    const rangeObject = useSelector(
        selectPracticeCodeRange(
            row.data.practice_id,
            row.data.system,
            row.data.code,
        ),
    );

    const code = useSelector(selectCode(row.data.system, row.data.code));

    const handleSubmit = async values => {
        console.log({values});
        if (rangeObject) {
            // PATCH
            const response = await api.fetchJsonApi(
                fp.get('links.self', values),
                {
                    json: {data: values},
                    method: 'PATCH',
                },
            );
            console.log(response);
        } else {
            // POST
            const url = api.url_for('practice.CodeRangeCollection', {
                practice_id: fp.get('data.practice_id', row),
            });
            await api.fetchJsonApi(url, {
                method: 'POST',
                json: {
                    data: {
                        ...values,
                        relationships: {
                            code: {
                                data: {
                                    type: 'Code',
                                    id: `${row.data.system}/${row.data.code}`,
                                },
                            },
                        },
                    },
                },
            });
        }
        setEditing(false);
    };

    const handleCancel = () => setEditing(false);

    const handleDelete = () => {
        api.fetchJsonApi(rangeObject.links.self, {method: 'DELETE'});
        setEditing(false);
        dispatch(ducks.jsonapi.deleteData(rangeObject));
    };

    return editing ? (
        <RangeObjectEditForm
            value={rangeObject}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onDelete={handleDelete}
        />
    ) : rangeObject ? (
        <>
            {rangeObject.attributes.range_min}-
            {rangeObject.attributes.range_max}
            <IconButton onClick={() => setEditing(true)}>
                <Edit />
            </IconButton>
        </>
    ) : code ? (
        <Tooltip title='Add New Range Alert'>
            <IconButton onClick={() => setEditing(true)}>
                <Add />
            </IconButton>
        </Tooltip>
    ) : (
        <span>Invalid code</span>
    );
}

function RangeConfigTable({dataSource}) {
    return (
        <dt2.DataTable
            dataSource={dataSource}
            options={{
                search: false,
                filtering: false,
                sorting: false,
                paging: false,
            }}
        >
            <dt2.Column
                title='Code'
                render={row => `${row.data.system} ${row.data.code}`}
            />
            <dt2.Column title='Description' field='display' />
            <dt2.Column title='Range' component={CodeRange} />
        </dt2.DataTable>
    );
}
export default function RangeAlertsDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ticket, setTicket] = useState(null);

    const ds = useTicketRangeSettings(ticket);

    const handleDialogClose = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    dialogRef.current = ticket => {
        setTicket(ticket);
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    if (!ticket) return null;
    return (
        <Dialog
            open={!!resolve}
            onClose={handleDialogClose}
            maxWidth='lg'
            fullWidth
        >
            <DialogTitle>Set Alert Ranges</DialogTitle>
            <DialogContent>
                <RangeConfigTable dataSource={ds} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
