import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {DialogContent, DialogActions, Button, Tooltip} from '@material-ui/core';

import OrdersEditor from './OrdersEditor';

export default function OrdersStep({
    ticket,
    actions,
    onNext,
    onPrevious,
    onCancel,
}) {
    const orders = fp.getOr('', 'attributes.data.orders', ticket);
    const hasSignatureRequest = !!fp.get(
        'relationships.signature_form.data',
        ticket,
    );
    const [text, setText] = useState('');

    useEffect(() => {
        setText(orders);
    }, [orders, setText]);

    const hasOrders = fp.trim(text).length !== 0;

    const handleNext = async () => {
        if (text !== null && text !== orders) {
            await actions.patch(ticket, {data: {orders: text}});
        }
        onNext();
    };

    return (
        <>
            <DialogContent dividers>
                <OrdersEditor text={text} setText={setText} orders={orders} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Close</Button>
                {hasSignatureRequest && (
                    <Button variant='contained' onClick={onPrevious}>
                        Back
                    </Button>
                )}
                <Tooltip
                    title={!hasOrders ? 'Provide orders to continue' : ''}
                    arrow
                >
                    <span>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleNext}
                            disabled={!hasOrders}
                        >
                            Next
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </>
    );
}
