import PainCard from './PainCard';
import TemperatureCard from './TemperatureCard';
import HeartRateCard from './HeartRateCard';
import BloodPressureCard from './BloodPressureCard';
import BloodSugarCard from './BloodSugarCard';
import OxygenSaturationCard from './OxygenSaturationCard';
import WeightCard from './WeightCard';
import HeightCard from './HeightCard';
import RespirationCard from './RespirationCard';
import DischargeDateCard from './DischargeDateCard';

const VITALS_CONFIG = [
    {
        key: 'pain',
        filter: 'show_pain',
        component: PainCard,
        title: 'Pain level',
        series: ['PAINLEVEL'],
        options: {
            yaxis: {
                max: 10,
            },
        },
    },
    {
        key: 'discharge_date',
        filter: 'show_discharge_date',
        component: DischargeDateCard,
        title: 'Estimated Discharge Date',
        // series: ['HEIGHT'], // <--breaks if I set to DISCHARGE_DATE
        // series maps to coding.py for query on observations. 
        // These are all observations, not artifacts. 
        // How to make this work?
    },
    {
        key: 'temperature',
        filter: 'show_temperature',
        component: TemperatureCard,
        title: 'Body temperature',
        series: ['TEMPERATURE'],
    },
    {
        key: 'heartrate',
        filter: 'show_heart_rate',
        component: HeartRateCard,
        title: 'Heart rate',
        series: ['HEARTRATE'],
    },
    {
        key: 'bloodPressure',
        filter: 'show_blood_pressure',
        component: BloodPressureCard,
        title: 'Blood pressure',
        series: ['BLOODPRESSURE_SYSTOLIC', 'BLOODPRESSURE_DIASTOLIC'],
    },
    {
        key: 'bloodSugar',
        filter: 'show_blood_sugar',
        component: BloodSugarCard,
        title: 'Blood sugar',
        series: ['BLOODSUGAR'],
    },
    {
        key: 'oxygenSaturation',
        filter: 'show_oxygen_saturation',
        component: OxygenSaturationCard,
        title: 'Oxygen saturation',
        series: ['OXYGENSATURATION'],
    },
    {
        key: 'respirations',
        filter: 'show_respiration',
        component: RespirationCard,
        title: 'Respiration rate',
        series: ['RESPIRATIONS'],
    },
    {
        key: 'weight',
        filter: 'show_weight',
        component: WeightCard,
        title: 'Weight',
        series: ['WEIGHT'],
    },
    {
        key: 'height',
        filter: 'show_height',
        component: HeightCard,
        title: 'Height',
        series: ['HEIGHT'],
    },
];

export default VITALS_CONFIG;
