import React, {useEffect, useState} from 'react';

import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    Tooltip,
} from '@material-ui/core';
import {Lock, Send} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';
import {formatDateTime, formatShortDateTime} from 'ccm/lib/helpers';

const useStyles = makeStyles(theme => ({
    threadContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    messageCard: {
        borderRadius: 16,
        backgroundColor: '#f1f1f1',
    },
    messageHeader: {
        paddingBottom: 0,
        '& .MuiCardHeader-content': {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
        },
        '& .MuiCardHeader-title': {
            fontSize: '1rem',
            fontWeight: 'bold',
        },
    },
    messageContent: {
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiCardContent-root:last-child': {
            paddingBottom: 0,
        },
    },
    messageTextField: {
        '& .MuiOutlinedInput-multiline': {
            borderRadius: 16,
        },
    },
    messageComposeContainer: {
        paddingBottom: theme.spacing(2),
    },
    messageButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        gap: theme.spacing(1),
    },
    thin: {
        marginTop: 0,
        marginBottom: 0,
    },
}));

const MessageComponent = ({message}) => {
    const classes = useStyles();

    const dateComponent = ts => (
        <Tooltip title={formatDateTime(ts)} arrow>
            <p className={classes.thin}>{formatShortDateTime(ts)}</p>
        </Tooltip>
    );

    return (
        <Card className={classes.messageCard}>
            <CardHeader
                className={classes.messageHeader}
                title={message.attributes.from_}
                subheader={dateComponent(message.attributes.timestamp)}
            />
            <CardContent>
                <p className={classes.thin}>{message.attributes.text}</p>
            </CardContent>
        </Card>
    );
};

const ComposeMessageComponent = ({
    thread,
    handleAddMessage,
    handleLockThread,
    isPracticeUser,
}) => {
    const classes = useStyles();
    const [messageText, setMessageText] = useState('');

    const handleSubmit = async () => {
        if (!messageText.length) return;
        await handleAddMessage(thread, messageText);
        setMessageText('');
    };

    return (
        <div className={classes.messageComposeContainer}>
            <TextField
                disabled={thread.attributes.locked}
                className={classes.messageTextField}
                fullWidth
                multiline
                minRows={4}
                variant='outlined'
                id='outlined-helperText'
                label={
                    thread.attributes.locked
                        ? 'Thread Closed'
                        : 'Compose a message'
                }
                value={messageText}
                onChange={ev => setMessageText(ev.target.value)}
            />
            <div className={classes.messageButtonContainer}>
                {isPracticeUser && (
                    <Button
                        disabled={thread.attributes.locked}
                        variant='contained'
                        color='secondary'
                        startIcon={<Lock />}
                        onClick={() => handleLockThread(thread, messageText)}
                    >
                        Close Thread
                    </Button>
                )}
                <Button
                    disabled={thread.attributes.locked}
                    variant='contained'
                    color='primary'
                    endIcon={<Send />}
                    onClick={handleSubmit}
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

async function openThread(api, tableRef, row) {
    // Mark all unread messages as read
    const promises = fp.pipe([
        fp.filter(msg => !fp.get('attributes.read', msg)),
        fp.map(msg =>
            api.fetchJsonApi(fp.get('links.self', msg), {
                method: 'PATCH',
                json: {data: fp.set('attributes.read', true, msg)},
                include: ['thread', 'patient', 'thread.participants'],
            }),
        ),
    ])(row.data.messages);
    await Promise.all(promises);
}

export default function ThreadDetails({
    tableRef,
    row,
    handleLockThread,
    handleAddMessage,
    isPracticeUser,
    ...props
}) {
    const api = useApi();
    const classes = useStyles();
    const threadInfo = fp.get('data', row);
    const unreadCount = fp.get('unread', threadInfo);

    useEffect(() => {
        if (unreadCount) {
            openThread(api, tableRef, row);
        }
    }, [api, tableRef, unreadCount, row]);

    if (!row) return null;
    return (
        <div className={classes.threadContainer}>
            {fp.map(
                message => (
                    <MessageComponent message={message} />
                ),
                threadInfo.messages,
            )}
            <ComposeMessageComponent
                thread={threadInfo.thread}
                handleAddMessage={handleAddMessage}
                handleLockThread={handleLockThread}
                isPracticeUser={isPracticeUser}
            />
        </div>
    );
}
