import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {reverse} from 'named-urls';

const routeList = [
    {name: 'test', path: '/test'},
    {name: 'home', path: '/'},
    {name: 'about', path: '/about'},
    {name: 'staticAbout', path: '/about.html'},
    {name: 'login', path: '/login'},
    {name: 'unlock', path: '/unlock'},
    {name: 'callback', path: '/callback'},
    {name: 'logout', path: '/logout'},
    {name: 'register', path: '/register'},
    {name: 'pwreset_request', path: '/pwreset_request'},
    {name: 'pwreset', path: '/pwreset'},
    {name: 'profile', path: '/profile'},
    {name: 'inbox', path: '/userInbox'},
    {name: 'siteAdmin', path: '/siteAdmin'},
    {name: 'patients', path: '/patient'},
    {name: 'providerSchedule', path: '/provider/:providerId/schedule'},
    {name: 'patient', path: '/patient/:patientId'},
    {name: 'patientGroup', path: '/patient/:patientId/group'},
    {name: 'flowsheet', path: '/patient/:patientId/flowsheet'},
    {name: 'observationHistory', path: '/patient/:patientId/history/:type'},
    {name: 'patientInfo', path: '/patient/:patientId/info/:type'},
    {name: 'carePlan', path: '/patient/:patientId/carePlan/:carePlanId'},
    {name: 'patientVisits', path: '/patient/:patientId/upcoming-visits'},
    {name: 'patientPreferences', path: '/patient/:patientId/preferences'},

    {name: 'practices', path: '/practice'},
    {name: 'practice', path: '/practice/:practiceId'},

    {name: 'facilities', path: '/facility'},
    {name: 'facility', path: '/facility/:facilityId'},
    {name: 'facilitiesCms', path: '/cmsFacility'},

    // {name: 'visits', path: '/visit'},

    //{name: 'ehrs', path: '/ehr'},
    //{name: 'ehr', path: '/ehr/:ehrId'},

    {name: 'tickets', path: '/ticket'},
    {name: 'logoutRedirect', path: '/logoutRedirect'},
    {name: 'emailVerification', path: '/verify-email'},

    // Communication tool
    {name: 'communicate', path: '/communicate'},
    {name: 'communicateAnon', path: '/communicate/:practiceSlug'},
    {
        name: 'dynaform',
        path: '/ticketSession/:sessionid/form/:formid/page/:pageid',
    },
    {name: 'formFax', path: '/ticketSession/:sessionid/fax'},
    {name: 'formSubmit', path: '/ticketSession/:sessionid/submit'},
    {name: 'formSuccess', path: '/ticketSession/:sessionid/success'},
    {name: 'dashboard', path: '/ticket'},
    {name: 'ticket', path: '/ticket/:ticketid'},

    // Temporary TCPA Landing Page
    {name: 'tcpaLanding', path: '/tcpaLanding'},
    {name: 'aauthRedirect', path: '/aauthRedirect/:providerId'},
];

export const routes = fp.transform(
    (acc, r) => {
        acc[r.name] = r;
    },
    {},
    routeList,
);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const routeByType = fp.transform(
    routeList,
    (acc, r) => {
        if (r.type) {
            acc[r.type] = r;
        }
    },
    {},
);

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params);
    } catch (e) {
        console.error('Error looking up route', route, params);
        throw e;
    }
};

export default routes;
