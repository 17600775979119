import React, {useCallback} from 'react';
import fp from 'lodash/fp';

import {
    TextField as MUITextField,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {Delete, AddCircleOutline} from '@material-ui/icons';
import {useConstants} from 'ccm/lib/util';

const DEFAULT_LINK_TEMPLATE = {
    key: '',
    value: '',
};

const useStyles = makeStyles(theme => ({
    linkTemplateList: {
        margin: theme.spacing(2, 0, 0, 0),
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    linkTemplateType: {
        marginRight: theme.spacing(1),
    },
}));

function LinkTemplate({value, readOnly, onChange, onDelete}) {
    const classes = useStyles();
    const constants = useConstants();

    const handleChangeValue = ev => {
        const newValue = fp.set('value', ev.target.value, value);
        onChange(newValue);
    };
    const handleChangeKey = ev => {
        const key = ev.target.value;
        const newValue = {...value, key};
        onChange(newValue);
    };
    const handleDelete = () => onDelete(value);

    const options = fp.pipe([
        fp.get('linkTemplateTypes'),
        fp.toPairs,
        fp.map(kv => (
            <MenuItem key={kv[0]} value={kv[0]}>
                {kv[1]}
            </MenuItem>
        )),
    ])(constants);

    return (
        <div>
            <Select
                className={classes.linkTemplateType}
                value={fp.get('key', value)}
                onChange={handleChangeKey}
                inputProps={{readOnly}}
            >
                {options}
            </Select>
            <MUITextField
                value={fp.get('value', value)}
                onChange={handleChangeValue}
                InputProps={{readOnly}}
            />
            {readOnly ? null : (
                <IconButton onClick={handleDelete}>
                    <Delete />
                </IconButton>
            )}
        </div>
    );
}

export default function LinkTemplateField({
    field,
    form, // From Formik
    label,
    readOnly = false,
    defaultLinkTemplate = DEFAULT_LINK_TEMPLATE,
}) {
    const classes = useStyles();
    const defaultValue = [];
    let value = field.value || defaultValue;

    const addLinkTemplate = useCallback(() => {
        const id = '' + Math.random();
        const newValue = [...value, {id, ...defaultLinkTemplate}];
        form.setFieldValue(field.name, newValue);
    }, [form, field, value, defaultLinkTemplate]);

    const handleChange = useCallback(
        itemValue => {
            let index = fp.findIndex(it => it.id === itemValue.id, value);
            let newValue = fp.set(index, itemValue, value);
            form.setFieldValue(field.name, newValue);
        },
        [form, field, value],
    );

    const handleDelete = useCallback(
        itemValue => {
            let newValue = fp.remove(item => item.id === itemValue.id, value);
            form.setFieldValue(field.name, newValue);
        },
        [form, field, value],
    );

    return (
        <div>
            <InputLabel shrink>
                {label}
                <Tooltip
                    title={`If you set a visit satisfaction survey link here, an email
                      will go out to each patient (with a survey email address
                      configured) the day after their visit with a link to the
                      survey.`}
                >
                    <u>&nbsp;*&nbsp;</u>
                </Tooltip>
            </InputLabel>
            <div className={classes.linkTemplateList}>
                {fp.map(
                    item => (
                        <LinkTemplate
                            key={item.id}
                            value={item}
                            onChange={handleChange}
                            onDelete={handleDelete}
                            readOnly={readOnly}
                        />
                    ),
                    value,
                )}
                {!readOnly ? (
                    <IconButton onClick={addLinkTemplate}>
                        <AddCircleOutline />
                    </IconButton>
                ) : null}
            </div>
        </div>
    );
}
