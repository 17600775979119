import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {parseISO} from 'date-fns';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Chip,
    makeStyles,
} from '@material-ui/core';
import {ducks, useApi} from '@arborian/narrf';

import ConsentFormAlert from 'ccm/components/ConsentFormAlert';
import {useCurrentUser} from 'ccm/dataSource/misc';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1, 0),
    },
}));

function GroupChip({group_id}) {
    const group = useSelector(ducks.jsonapi.selectObject(['Group', group_id]));
    const rel = useSelector(ducks.jsonapi.selectRelated(group));
    const resource = rel.practice || rel.facility || rel.patient;
    let resource_label = fp.get('attributes.label', resource);
    const label = group
        ? `${group.attributes.label} for ${resource_label}`
        : group_id;
    return <Chip label={label} />;
}

export function InvitationCard({inv, onAccept, onDecline}) {
    const api = useApi();
    const expires = parseISO(fp.get('attributes.expires', inv));
    const [checked, setChecked] = useState(false);
    const handleChange = ev => {
        setChecked(ev.target.checked);
    };

    useEffect(() => {
        if (inv && api) {
            api.fetchJson(
                fp.get('relationships.practice_consent.links.related', inv),
            );
        }
    }, [api, inv]);

    const rel = useSelector(ducks.jsonapi.selectRelated(inv));
    console.log({inv, rel});
    return (
        <Card>
            <CardHeader title='You have been invited to access health data' />
            <CardContent>
                <p>
                    Accepting this invitation will grant you the following
                    permissions:
                </p>
                <div>
                    {fp.map(
                        gid => (
                            <GroupChip key={gid} group_id={gid} />
                        ),
                        fp.get('attributes.group_ids', inv),
                    )}
                </div>
                <p>
                    Please note that this invitation expires on{' '}
                    {expires.toLocaleString()}
                </p>
                <p>
                    If you would like to accept this invitation, click the
                    accept button below. If you would like to decline the
                    invitation, click on the decline button below.
                </p>
                <ConsentFormAlert
                    practices={rel.practice_consent}
                    value={checked}
                    onChange={handleChange}
                />
            </CardContent>
            <CardActions>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={onDecline}
                >
                    Decline Invitation
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!checked}
                    onClick={onAccept}
                >
                    Accept Invitation
                </Button>
            </CardActions>
        </Card>
    );
}

export const invitationActions = {
    fetch: async (api, user) => {
        const url = fp.get('relationships.invitations.links.related', user);
        api.fetchAllJsonApi(url, {
            include: [
                'groups',
                'groups.practice',
                'groups.facility',
                'groups.patient',
            ],
        });
    },
    accept: async (api, invitation) => {
        await api.fetchJson(invitation.relationships.acceptance.links.related, {
            method: 'POST',
        });
        await api.fetchJsonApi(api.url_for('user.Current'), {
            include: ['invitations'],
        });
    },
    decline: async (api, invitation) => {
        await api.fetchJson(invitation.links.self, {method: 'DELETE'});
        await api.fetchJsonApi(api.url_for('user.Current'), {
            include: ['invitations'],
        });
    },
};

export default function MyInvitations() {
    const api = useApi();
    const curUser = useCurrentUser();
    const classes = useStyles();

    const rel = useSelector(ducks.jsonapi.selectRelated(curUser));
    useEffect(() => {
        invitationActions.fetch(api, curUser);
    }, [api, curUser]);
    if (!rel.invitations || rel.invitations.length === 0) return null;
    return (
        <div className={classes.root}>
            {fp.map(
                inv => (
                    <InvitationCard
                        key={inv.id}
                        inv={inv}
                        onDecline={() => invitationActions.decline(api, inv)}
                        onAccept={() => invitationActions.accept(api, inv)}
                        rel={rel}
                    />
                ),
                rel.invitations,
            )}
        </div>
    );
}
