import React, {useRef, useEffect, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useField} from 'formik';
import fp from 'lodash/fp';

import {
    IconButton,
    FormControl,
    FormLabel,
    FormHelperText,
    Card,
    CardMedia,
    CardHeader,
    CardActionArea,
    CardContent,
    TextField,
    Button,
    Grid,
    makeStyles,
    Dialog,
    DialogTitle,
    LinearProgress,
    Tooltip,
} from '@material-ui/core';
import {Attachment, Delete, Edit} from '@material-ui/icons';

import {createSelector} from '@reduxjs/toolkit';

import {useApi, ducks} from '@arborian/narrf';

import {useTicketSessionOrTicket} from 'ccm/lib/hooks';
import {useDialog} from 'ccm/components/dialogs';
import {fieldId, enumerate} from 'ccm/lib/util';
import AttachmentImageEditorDialog from 'ccm/components/dialogs/AttachmentImageEditorDialog';

const useStyles = makeStyles(theme => ({
    centered: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        maxWidth: 440,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    loadingCard: {
        width: 440,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    hidden: {
        display: 'none',
    },
    loadingDialog: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
    },
}));

const ImageEditorButton = ({
    ordinal,
    onEdit,
    allowEditor,
    hasSignatureRequest,
}) => {
    if (hasSignatureRequest && allowEditor) {
        return (
            <Tooltip title='Image editing is unavailable when a signature request is present.'>
                <span>
                    <IconButton
                        disabled
                        id={fieldId('dyn', 'editButton', ordinal)}
                        onClick={onEdit}
                    >
                        <Edit />
                    </IconButton>
                </span>
            </Tooltip>
        );
    } else {
        return (
            allowEditor && (
                <IconButton
                    id={fieldId('dyn', 'editButton', ordinal)}
                    onClick={onEdit}
                >
                    <Edit />
                </IconButton>
            )
        );
    }
};

function ImageAttachment({
    item,
    ordinal,
    blobs,
    onEdit,
    onChange,
    onDelete,
    hasSignatureRequest,
}) {
    const api = useApi();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const content_href = fp.get(
        'current.relationships.content.links.related',
        blobs,
    );
    const allowEditor = useMemo(() => {
        return ['.png', '.jpg', 'jpeg', '.bmp'].some(ext =>
            item.filename.endsWith(ext),
        );
    }, [item]);

    useEffect(() => {
        if (!item) return;
        const promises = fp.pipe([
            fp.uniq,
            fp.filter(blob_id => !fp.isEmpty(blob_id)),
            fp.map(blob_id =>
                api.url_for('attachment.TicketAttachmentBlob', {
                    attachment_id: item.id,
                    blob_id,
                }),
            ),
            fp.map(api.fetchJson),
        ])([
            item.original_blob_id,
            item.annotated_blob_id,
            item.signed_blob_id,
        ]);
        Promise.all(promises).then(() => setLoading(false));
    }, [api, item]);

    const showImage =
        content_href &&
        fp.startsWith(
            'image/',
            fp.get('attributes.contentType', blobs.current),
        );
    const filename = fp.get('filename', item);

    if (loading) {
        return (
            <Card className={classes.loadingCard} fullWidth>
                <CardHeader title='Attachment loading...' />
                <LinearProgress fullWidth />
            </Card>
        );
    }
    return (
        <Card className={classes.card}>
            <CardHeader
                action={
                    <>
                        <ImageEditorButton
                            ordinal={ordinal}
                            onEdit={onEdit}
                            allowEditor={allowEditor}
                            hasSignatureRequest={hasSignatureRequest}
                        />
                        <IconButton
                            id={fieldId('dyn', 'deleteButton', ordinal)}
                            onClick={onDelete}
                        >
                            <Delete />
                        </IconButton>
                    </>
                }
                title={
                    <Button href={content_href} target='_blank'>
                        {filename}
                    </Button>
                }
            />
            {showImage && (
                <CardActionArea href={content_href} target='_blank'>
                    <CardMedia
                        image={content_href}
                        component='img'
                        title={filename}
                        alt={filename}
                    />
                </CardActionArea>
            )}
            <CardContent>
                <TextField
                    id={fieldId('dyn', 'attachmentComment', ordinal)}
                    value={item.comment}
                    fullWidth
                    label='Attachment description'
                    onChange={ev =>
                        onChange(fp.set('comment', ev.target.value, item))
                    }
                />
            </CardContent>
        </Card>
    );
}

export const selectAttachmentBlobs = attachments =>
    createSelector(ducks.jsonapi.selectObject('Blob'), blobs => {
        return fp.pipe([
            fp.map(att => {
                if (blobs) {
                    const original = blobs[att.original_blob_id];
                    const annotated = blobs[att.annotated_blob_id];
                    const signed = blobs[att.signed_blob_id];
                    const current = signed || annotated || original;
                    return [att.id, {original, annotated, signed, current}];
                } else {
                    return [
                        att.id,
                        {
                            original: null,
                            annotated: null,
                            signed: null,
                            current: null,
                        },
                    ];
                }
            }),
            fp.fromPairs,
        ])(attachments);
    });

// Component that acts like a formik field (value/onChange)
export function ImageList({value, onChange, ...props}) {
    const api = useApi();
    const inputRef = useRef();
    const classes = useStyles();
    const session = useTicketSessionOrTicket();
    const [open, setOpen] = useState(false);
    const [currentOrdinal, setCurrentOrdinal] = useState(null);

    const hasSignatureRequest = !!fp.get(
        'relationships.signature_form.data',
        session,
    );
    const blobs = useSelector(selectAttachmentBlobs(value));

    const src = useMemo(() => {
        if (currentOrdinal === null) return null;
        const att = value[currentOrdinal];
        const {original} = blobs[att.id];
        return fp.get('relationships.content.links.related', original);
    }, [currentOrdinal, value, blobs]);

    const annotations = useMemo(() => {
        if (currentOrdinal === null) return null;
        const att = value[currentOrdinal];
        return fp.get('annotations', att);
    }, [currentOrdinal, value]);

    const dialog = useDialog(AttachmentImageEditorDialog, {src, annotations});

    const handleAdd = async ev => {
        setOpen(true);
        let file = ev.currentTarget.files[0];
        let body = new FormData();
        body.append('file', file);
        let resp = await api.fetchJsonApi(
            session.relationships.attachments.links.related,
            {method: 'POST', body},
        );
        let newAttachments = [];
        resp.data.forEach(attachment => {
            const attachmentData = {
                id: attachment.id,
                ...attachment.attributes,
            };
            newAttachments.push(attachmentData);
        });
        onChange([...value, ...newAttachments]);
        inputRef.current.value = null;
        setOpen(false);
    };

    const handleChange = item => {
        onChange(
            fp.map(it => {
                if (it.id === item.id) {
                    return item;
                } else {
                    return it;
                }
            }, value),
        );
    };

    const handleDelete = async obj => {
        const href = api.url_for('attachment.TicketAttachment', {
            attachment_id: obj.id,
        });
        await api.fetchJsonApi(href, {method: 'DELETE'});
        const newval = fp.remove(v => v.id === obj.id, value);
        onChange(newval);
    };

    const handleEdit = (att, ordinal) => async ev => {
        while (true) {
            setCurrentOrdinal(ordinal);
            const {action, ...other} = await dialog(src, annotations);
            if (action === 'CANCEL') return;
            // Save image/attachments
            const {blob, cObj, rotatedBlob} = other;
            await handleSave(att, blob, cObj, rotatedBlob);
            if (action === 'SAVE_NEXT') {
                ordinal = (ordinal + 1) % value.length;
                att = value[ordinal];
            } else if (action === 'SAVE_CLOSE') {
                break;
            }
        }
    };

    const handleSave = async (att, editedBlob, canvasJson, rotatedBlob) => {
        const annotations = fp.unset('backgroundImage', canvasJson);
        const annotationsChanged =
            att.annotations !== JSON.stringify(annotations);
        if (!annotationsChanged && !rotatedBlob) {
            return; // nothing to save
        }
        let body = new FormData();
        if (rotatedBlob) {
            // Replace original blob with rotated version
            body.append('overwrite', true);
            body.append('replacement_file', rotatedBlob);
        }
        body.append('file', editedBlob);
        body.append('annotations', JSON.stringify(annotations));
        body.append('comment', fp.get('comment', att));

        const attachmentHref = api.url_for('attachment.TicketAttachment', {
            attachment_id: att.id,
        });
        const new_attachment = await api.fetchJsonApi(attachmentHref, {
            method: 'PATCH',
            body,
        });
        onChange(
            fp.map(it => {
                if (it.id === att.id) {
                    return {id: att.id, ...new_attachment.data.attributes};
                } else {
                    return it;
                }
            }, value),
        );
        // Re-fetch the session data (if present) with the new attachment data
        const sessionUrl = fp.get('links.self', session);
        if (sessionUrl) {
            api.fetchJsonApi(sessionUrl);
        }
    };

    const handleClick = ev => {
        inputRef.current.click();
    };

    return (
        <div>
            <div className={classes.attachmentList}>
                <LoadingDialog open={open} />
                {fp.map(({value, ordinal}) => {
                    return (
                        <ImageAttachment
                            key={value.id}
                            ordinal={ordinal}
                            blobs={blobs[value.id]}
                            item={value}
                            onEdit={handleEdit(value, ordinal)}
                            onChange={handleChange}
                            onDelete={() => handleDelete(value)}
                            hasSignatureRequest={hasSignatureRequest}
                        />
                    );
                }, enumerate(value))}
            </div>
            <Grid container justifyContent='center' alignItems='center'>
                <Grid>
                    <Button
                        id={fieldId('dyn', 'attachButton')}
                        onClick={handleClick}
                        variant='outlined'
                    >
                        <Attachment /> Add
                    </Button>
                    <input
                        id={fieldId('dyn', 'fileInput')}
                        type='file'
                        ref={inputRef}
                        onChange={handleAdd}
                        className={classes.hidden}
                        accept='image/png, image/jpeg, application/pdf'
                        {...props}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export function ImageAttachmentField({label, ...props}) {
    const classes = useStyles();
    const [field, , helpers] = useField(props);

    const handleChange = newValue => {
        helpers.setValue(newValue);
    };

    return (
        <FormControl className={classes.centered}>
            <FormLabel>{label}</FormLabel>
            <ImageList {...props} {...field} onChange={handleChange} />
        </FormControl>
    );
}

const DynaformField = ({field}) => {
    return (
        <>
            <ImageAttachmentField
                name={field.id}
                label={field.label}
                required={field.required}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
};
export default DynaformField;

const LoadingDialog = ({open}) => {
    const classes = useStyles();
    return (
        <Dialog open={open}>
            <DialogTitle>
                <div className={classes.loadingDialog}>
                    <h3>Uploading Attachment...</h3>
                </div>
            </DialogTitle>
            <LinearProgress />
        </Dialog>
    );
};
