import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    MenuItem,
    IconButton,
    Button,
} from '@material-ui/core';
import {Edit, Delete} from '@material-ui/icons';

import {Formik, Form, Field, useField} from 'formik';
import {TextField} from 'formik-material-ui';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';

import {Date as DateComp} from 'ccm/components/DateTime';

function LabeledDate({value, label}) {
    if (!value) return null;
    return (
        <>
            {label}: <DateComp value={value} />
            <br />
        </>
    );
}
function FocusItemDialog({label, value, open, onClose, onSave, onDelete}) {
    const handleSubmit = async values => {
        console.log('Submitting', values);
        onSave(fp.cloneDeep(values));
        onClose();
        return true;
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={value}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <DialogTitle>{label}</DialogTitle>

                        <DialogContent>
                            <Field
                                id='FocusItemDescriptionField'
                                component={TextField}
                                multiline
                                label='Description'
                                name='description'
                                fullWidth
                                InputLabelProps={{shrink: true}}
                            />
                            <Field
                                id='FocusItemStatusField'
                                component={TextField}
                                select
                                label='Status'
                                name='status'
                                fullWidth
                                InputLabelProps={{shrink: true}}
                            >
                                <MenuItem id='OptionActive' value='Active'>
                                    Active
                                </MenuItem>
                                <MenuItem id='OptionResolved' value='Resolved'>
                                    Resolved
                                </MenuItem>
                                <MenuItem id='OptionClosed' value='Closed'>
                                    Closed
                                </MenuItem>
                            </Field>
                            <Field
                                id='FocusItemInitiatedDateField'
                                component={KeyboardDatePicker}
                                label='Initiated date'
                                name={`initiatedDate`}
                                format='yyyy-MM-dd'
                                fullWidth
                            />
                            <Field
                                id='FocusItemTargetDateField'
                                component={KeyboardDatePicker}
                                label='Target date'
                                name={`targetDate`}
                                format='yyyy-MM-dd'
                                fullWidth
                            />
                            <Field
                                id='FocusItemResolvedDateField'
                                component={KeyboardDatePicker}
                                label='Resolved date'
                                name={`resolvedDate`}
                                format='yyyy-MM-dd'
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button id='FocusItemCancelBtn' onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                id='FocusItemDeleteBtn'
                                variant='contained'
                                color='secondary'
                                startIcon={<Delete />}
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                            <Button
                                id='FocusItemSaveBtn'
                                variant='contained'
                                color='primary'
                                onClick={formikProps.handleSubmit}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default function FocusItem({index, name, label, arrayHelpers}) {
    const [field, , helpers] = useField({name});
    const value = field.value[index];
    const [editing, setEditing] = useState(false);

    const handleSave = values => {
        arrayHelpers.replace(index, values);
        helpers.setTouched(true);
    };
    const handleDelete = () => {
        arrayHelpers.remove(index);
        helpers.setTouched(true);
    };

    return (
        <>
            <FocusItemDialog
                open={editing}
                value={value}
                onClose={ev => setEditing(false)}
                onSave={handleSave}
                onDelete={handleDelete}
            />
            <ListItem>
                <ListItemText
                    primary={
                        <>
                            <strong>{fp.get('status', value)}</strong>:{' '}
                            {fp.get('description', value)}
                        </>
                    }
                    secondary={
                        <>
                            <LabeledDate
                                value={fp.get('initiatedDate', value)}
                                label='Initiated'
                            />
                            <LabeledDate
                                value={fp.get('targetDate', value)}
                                label='Target'
                            />
                            <LabeledDate
                                value={fp.get('resolvedDate', value)}
                                label='Resolved'
                            />
                        </>
                    }
                />
                <ListItemSecondaryAction>
                    <IconButton
                        id={`${name}${index}EditBtn`}
                        onClick={ev => setEditing(true)}
                    >
                        <Edit />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
}
