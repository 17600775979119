import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {AccordionSummary, Grid, makeStyles} from '@material-ui/core';

import {selectLatestObservations} from 'ccm/duck';
import {selectPortalConfig, selectDischargeDate} from 'ccm/lib/selectors-ccm';
import DischargeDateCard from './DischargeDateCard';

import VITALS_CONFIG from './config';

const useStyles = makeStyles(theme => ({
    vitalsSummary: {
        '& .MuiAccordionSummary-content': {
            display: 'block',
        },
    },
}));

export function VitalsSummary({patient}) {
    const classes = useStyles();
    const portalConfig = useSelector(selectPortalConfig(patient));
    const obss = useSelector(selectLatestObservations(patient.id));

    const vitals = useMemo(
        () =>
            fp.pipe([
                fp.filter(vt => fp.get(vt.filter, portalConfig)),
                fp.map(vt => ({
                    key: vt.key,
                    title: vt.title,
                    component: vt.component,
                    artifacts: fp.map(s => fp.get(s, obss), vt.series),
                })),
                fp.filter(v => fp.every(fp.identity, v.artifacts)),
            ])(VITALS_CONFIG),
        [obss, portalConfig],
    );
    const vitalsText = fp.pipe([
        fp.filter(v => !fp.isEmpty(fp.get('artifacts', v))),
        fp.filter(art => !fp.isEmpty(art)),
        fp.map(vital => {
            const vitalName = fp.get('title', vital);
            const value = fp.get(
                'artifacts.0.attributes.value.valueString',
                vital,
            );
            return (
                <>
                    <strong>{vitalName}:</strong> {value}
                </>
            );
        }),
    ])(vitals);
    return (
        <AccordionSummary className={classes.vitalsSummary}>
            <h5 id='hdr-latest-vitals'>Latest Vitals</h5>
            {!fp.isEmpty(vitalsText) &&
                vitalsText.reduce((prev, curr) => [prev, ' | ', curr])}
        </AccordionSummary>
    );
}

export default function VitalsDashboard({patient}) {
    const portalConfig = useSelector(selectPortalConfig(patient));
    const obss = useSelector(selectLatestObservations(patient.id));
    const dischargeDate = useSelector(selectDischargeDate(patient.id));

    const vitals = useMemo(
        () =>
            fp.pipe([
                fp.filter(vt => fp.get(vt.filter, portalConfig)),
                fp.map(vt => ({
                    key: vt.key,
                    title: vt.title,
                    component: vt.component,
                    artifacts: fp.map(s => fp.get(s, obss), vt.series),
                })),
                fp.filter(v => fp.every(fp.identity, v.artifacts)),
            ])(VITALS_CONFIG),
        [obss, portalConfig],
    );
    const painVital = fp.find(v => v.key === 'pain', vitals);

    if (!portalConfig) return null;
    const showPainDischarge =
        (painVital && portalConfig.show_pain) ||
        portalConfig.show_discharge_date;

    return (
        <Grid container spacing={2}>
            {showPainDischarge && (
                <Grid item xs={12} sm={6} md={4}>
                    {painVital && (
                        <>
                            <painVital.component
                                patient={patient}
                                title={painVital.title}
                                artifact={painVital.artifacts[0]}
                                artifacts={painVital.artifacts}
                            />
                            <br />
                        </>
                    )}
                    <DischargeDateCard
                        patient={patient}
                        artifact={dischargeDate}
                    />
                </Grid>
            )}
            <Grid item container spacing={2} sm={6} md={8}>
                {fp.pipe(
                    fp.filter(
                        v => v.key !== 'pain' && v.key !== 'discharge_date',
                    ),
                    fp.map(v => (
                        <Grid key={v.key} item xs={12} md={6}>
                            <v.component
                                patient={patient}
                                title={v.title}
                                artifact={v.artifacts[0]}
                                artifacts={v.artifacts}
                            />
                        </Grid>
                    )),
                )(vitals)}
            </Grid>
        </Grid>
    );
}
