import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import sha1 from 'sha1';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import BasePage from 'ccm/components/BasePage';

import PccLoginButton from 'ccm/components/PccLoginButton';
import BaseLoginButton from 'ccm/components/BaseLoginButton';
import WebAuthnButton from 'ccm/components/WebAuthnButton';
import PasswordLoginForm from 'ccm/components/forms/PasswordLoginForm';

const useStyles = makeStyles(theme => ({
    root: {},
    buttonContainer: {
        flex: 1,
        justifyContent: 'center',
    },
}));

export default function UnlockPage() {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const history = useHistory();
    const classes = useStyles();
    const [providers, setProviders] = useState();
    const {state} = useLocation();
    const [loginError, setLoginError] = useState(false);

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
            state,
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            console.log('Got error, trying direct redirect');
            window.location = authorizeLink;
        }
    }, [api, state, setProviders]);

    const handlePasswordLogin = useCallback(
        async values => {
            try {
                setLoginError(false);
                await api.loginSrp(values.username, values.password);
                history.push(url_for('home'));
            } catch (err) {
                if (err.response && err.response.status === 200) {
                    // Narrf login throws an error with a JSON body if it
                    // receives a 200 response to login that does not
                    // contain an access_token (this is how we detect
                    // expired passwords in SP)
                    const data = await err.json();
                    console.log('Got the response', data);
                    const state_obj = {
                        old_password_sha1: sha1(values.password),
                    };
                    console.log('Pushing', data.next, state_obj);
                    history.push(data.next, state_obj);
                } else {
                    console.log('Some weird login error', {
                        err,
                        response: err.response,
                    });
                }

                setLoginError(true);
                console.log('Login Error:', err, err.response);
            }
        },
        [api, history],
    );

    useEffect(() => {
        if (userinfo && state && state.next) {
            history.push(state.next);
        }
        if (!providers) {
            fetchProviders();
        }
    }, [history, state, userinfo, providers, fetchProviders]);

    const pcc = fp.find(p => p.attributes.plugin_id === 'pcc', providers);
    const nonPcc = fp.filter(p => p.attributes.plugin_id !== 'pcc', providers);

    return (
        <BasePage title='Session Locked'>
            <br />
            <Alert severity='info'>
                Your session has been locked due to inactivity. Please login to
                resume using SamePageMD.
            </Alert>
            <br />
            <div className={classes.buttonContainer}>
                <WebAuthnButton />
                <PccLoginButton provider={pcc} intent='login' />
                {fp.map(
                    p => (
                        <BaseLoginButton
                            key={p.id}
                            provider={p}
                            state={state}
                            intent='login'
                        >
                            Sign in with {p.attributes.label}
                        </BaseLoginButton>
                    ),
                    nonPcc,
                )}
            </div>
            <br />
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    Sign-in with Email & Password
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {loginError && (
                            <Alert severity='error'>
                                Sign-in failed - Please confirm that you are
                                using the correct sign-in method & credentials.
                            </Alert>
                        )}
                        <PasswordLoginForm onSubmit={handlePasswordLogin} />
                        <div>
                            <p>
                                <small>
                                    Forgot your password? Reset it{' '}
                                    <a href={url_for('pwreset_request')}>
                                        here
                                    </a>
                                </small>
                            </p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <br />
        </BasePage>
    );
}
