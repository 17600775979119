import React, {Fragment, useEffect, useState} from 'react';
import fp from 'lodash/fp';
import clsx from 'clsx';

import {
    TextField,
    InputAdornment,
    IconButton,
    Toolbar,
    makeStyles,
} from '@material-ui/core';
import {Search, Clear} from '@material-ui/icons';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import {useTableManager} from './Provider';
import {RowAdd} from './Editable';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        padding: theme.spacing(0, 1, 0, 3),
    },
    flex: {
        flex: 1,
    },
    selecting: {
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
}));

function Actions({selectedRows, freeActions, rowActions, editable}) {
    return (
        <>
            {selectedRows.length > 0
                ? fp.map(
                      act => (
                          <Fragment key={act.id}>
                              {act.render(selectedRows)}
                          </Fragment>
                      ),
                      rowActions,
                  )
                : null}
            {fp.map(
                act => (
                    <Fragment key={act.id}>{act.render()}</Fragment>
                ),
                freeActions,
            )}
            {editable.onRowAdd && <RowAdd />}
        </>
    );
}

function SearchField({search, onChangeSearch, ...props}) {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputValue(search || '');
    }, [search]);
    const acceptChange = ev => onChangeSearch(inputValue);
    return (
        <TextField
            value={inputValue}
            onChange={ev => setInputValue(ev.target.value)}
            onBlur={acceptChange}
            onKeyPress={ev => ev.key === 'Enter' && acceptChange()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        {search ? (
                            <IconButton onClick={e => onChangeSearch(null)}>
                                <Clear />
                            </IconButton>
                        ) : (
                            <IconButton onClick={acceptChange}>
                                <Search />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}

export default function DTableToolbar({title}) {
    const tm = useTableManager();
    const classes = useStyles();
    if (
        !tm.someRowsSelected() &&
        !tm.options.search &&
        !title &&
        fp.isEmpty(tm.freeActions) &&
        !tm.editable.onAddRow
    )
        return null;
    return (
        <Toolbar
            className={clsx(
                classes.toolbar,
                tm.someRowsSelected() && classes.selecting,
            )}
        >
            {tm.someRowsSelected() ? (
                <h6>{tm.selectedRows.length} row(s) selected</h6>
            ) : (
                <h6>{title}</h6>
            )}
            <div className={classes.flex} />
            {tm.options.search && (
                <SearchField
                    id={tm.id && `${tm.id}-search`}
                    search={tm.fetchOptions.search}
                    onChangeSearch={tm.dataSource.setSearch}
                />
            )}
            <Actions
                selectedRows={tm.selectedRows}
                freeActions={tm.freeActions}
                rowActions={tm.rowActions}
                editable={tm.editable}
            />
        </Toolbar>
    );
}
