import React, {useState, useEffect} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

import {useGlobals} from 'ccm/components/Globals';
import OrdersEditor from 'ccm/components/TicketResolveWizard/OrdersEditor';

export default function OrdersToolDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const globals = useGlobals();
    const [text, setText] = useState('');

    dialogRef.current = ticketOrders => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
            setText(ticketOrders);
        });
    };
    useEffect(() => {
        globals.fetchAll('order.TemplateCollection', {
            filter: {practice_id: JSON.stringify({$ne: null})},
            page: {limit: 200},
        });
        globals.fetchAll('order.InstructionCollection', {
            filter: {practice_id: JSON.stringify({$ne: null})},
            page: {limit: 200},
        });
    }, [globals]);

    const handleSubmit = () => {
        resolve({action: 'submit', text});
        setResolve(null);
        setText('');
    };

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
        setText('');
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel} fullScreen>
            <DialogTitle>Add Orders</DialogTitle>
            <DialogContent>
                <OrdersEditor text={text} setText={setText} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    type='submit'
                    variant='contained'
                    color='primary'
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
