import React from 'react';

import DirectoryTable from 'ccm/components/DirectoryTable';

export default function DirectoryPanel({practice}) {
    return (
        <DirectoryTable
            protectedResource={practice}
            tableTitle='Practice Directory'
            dialogTitle='Modify Contact'
            newContact={{
                type: 'Contact',
                attributes: {
                    name: 'New Contact',
                },
                relationships: {
                    practice: {
                        data: {type: 'Practice', id: practice.id},
                    },
                },
            }}
        />
    );
}
