import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        '& .MuiAccordionDetails-root > div': {
            width: '100%',
        },
    },
    floatRight: {
        float: 'right',
    },
}));
