import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import * as yup from 'yup';

import {components} from '@arborian/narrf';

import {ProviderSelectField} from 'ccm/components/fields/ProviderSelect';
import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';

import {useListField} from 'ccm/dataSource';
import {useFetchedRelationshipReverse} from 'ccm/dataSource/misc';

import PatientVisit from './PatientVisit';
import VisitTicketsTable from '../VisitTicketsTable';

const {dt2} = components;

const schema = yup.object().shape({
    attributes: yup.object().shape({
        visit_date: yup.date(),
        reason: yup.string(),
    }),
    relationships: yup.object().shape({
        addressing_visits: yup.object().shape({
            data: yup.array().of(
                yup.object().shape({
                    id: yup.string(),
                    type: yup.string(),
                }),
            ),
        }),
        visit_type_rel: yup.object().shape({
            data: yup.object().shape({
                id: yup.string(),
                type: yup.string(),
            }),
        }),
        provider: yup.object().shape({
            data: yup.object().shape({
                id: yup.string(),
                type: yup.string(),
            }),
        }),
    }),
});

function PatientInfoTable({patients}) {
    const ds = useListField(patients);
    return (
        <>
            <h3>Patients</h3>
            <dt2.DataTable dataSource={ds}>
                <dt2.Column title='Last' field='attributes.name.formatted' />
                <dt2.Column title='DOB' field='attributes.dob' type='date' />
                <dt2.Column
                    title='Last visit'
                    filter={false}
                    render={row => (
                        <PatientVisit patient={row.data} name='last_visit' />
                    )}
                />
                <dt2.Column
                    title='Next visit'
                    filter={false}
                    render={row => (
                        <PatientVisit patient={row.data} name='next_visit' />
                    )}
                />
            </dt2.DataTable>
        </>
    );
}

export default function NewVisitDialog({practice, dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [patients, setPatients] = useState();
    const [tickets, setTickets] = useState();
    const visitTypes = useFetchedRelationshipReverse(
        practice,
        'visitTypes',
        'VisitType',
        'practice',
    );
    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = ({patients, tickets = []}) => {
        console.log('Tickets is', tickets);
        return new Promise((resolve, reject) => {
            const ticketLinkage = fp.map(fp.pick(['id', 'type']), tickets);
            const relationships = {
                addressing_tickets: {data: ticketLinkage},
            };
            const attributes = {
                visit_date: new Date(),
                reason: '',
            };
            setInitialValues({attributes, relationships});
            setPatients(patients);
            setTickets(tickets);
            setResolve(() => resolve);
        });
    };
    if (fp.isEmpty(visitTypes)) return null;

    return (
        <Dialog maxWidth='lg' fullWidth open={!!resolve} onClose={handleCancel}>
            <DialogTitle>Schedule Visits</DialogTitle>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogContent>
                            <Field
                                component={KeyboardDatePicker}
                                label='Visit Date'
                                name='attributes.visit_date'
                                disableToolbar
                                fullWidth
                                variant='inline'
                                format='MM/dd/yyyy'
                                margin='normal'
                            />
                            <Field
                                component={ProviderSelectField}
                                label='Provider'
                                name='relationships.provider.data'
                                practice={practice}
                                fullWidth
                            />
                            <Field
                                component={LinkageAutocompleteField}
                                label='Appointment Type'
                                name='relationships.visit_type_rel.data'
                                options={visitTypes}
                                getOptionLabel={fp.get('attributes.name')}
                                autoComplete
                                autoHighlight
                                autoSelect
                                fullWidth
                            />
                            <Field
                                component={TextField}
                                label='Visit Reason'
                                name='attributes.reason'
                                fullWidth
                            />
                            <PatientInfoTable patients={patients} />
                            <VisitTicketsTable value={tickets} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Schedule Visit(s)
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
