import React from 'react';
import * as yup from 'yup';

import {Button} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {useQuery} from 'ccm/routes';

const INITIAL_VALUES = {
    password: '',
    password2: '',
};

const validationSchema = yup.object().shape({
    password: yup.string().min(6).required(),
    password2: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords don't match"),
});

export default function PasswordResetForm({old_password_sha1, onSubmit}) {
    const query = useQuery();

    const token = query.get('token');
    let initialValues = {
        ...INITIAL_VALUES,
        token,
    };
    if (old_password_sha1) {
        initialValues.old_password_sha1 = old_password_sha1;
    }

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {({touched, values, errors}) => (
                <Form>
                    {old_password_sha1 && (
                        <Field type='hidden' name='old_password_sha1' />
                    )}
                    <Field
                        component={TextField}
                        type='password'
                        label='Password'
                        name='password'
                        fullWidth
                    />
                    <Field
                        component={TextField}
                        type='password'
                        label='Password (again)'
                        name='password2'
                        fullWidth
                    />
                    <Field type='hidden' name='token' />
                    <br />
                    <br />

                    <Button type='submit' variant='contained' color='primary'>
                        Set your password
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
