import React, {useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Paper,
    List,
    ListItem as MUIListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core';

import {components} from '@arborian/narrf';

import Toolbar from './Toolbar';
import ListItem from './ListItem';

const {Pagination} = components;

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    flex: {flex: 1},
}));
const pageSizeOptions = [5, 10, 20];

export default function ListView({dataSource}) {
    const classes = useStyles();
    useEffect(() => {
        if (dataSource.state === 'init') {
            dataSource.fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource.state, dataSource.fetch]);
    return (
        <Paper className={classes.root}>
            <Toolbar dataSource={dataSource} />
            <List>
                {dataSource.state === 'ready' &&
                fp.isEmpty(dataSource.items) ? (
                    <MUIListItem>
                        <ListItemText primary='No files uploaded' />
                    </MUIListItem>
                ) : null}
                {dataSource.state === 'busy' ? (
                    <MUIListItem>
                        <ListItemText primary='Files messages...' />
                    </MUIListItem>
                ) : null}
                {fp.map(
                    it => (
                        <ListItem
                            key={it.id}
                            item={it}
                            dataSource={dataSource}
                        />
                    ),
                    dataSource.items,
                )}
                <MUIListItem>
                    <div className={classes.flex} />
                    <Pagination
                        count={dataSource.count}
                        page={dataSource.fetchOptions.page.number}
                        pageSize={dataSource.fetchOptions.page.size}
                        pageSizeOptions={pageSizeOptions}
                        onChangePage={dataSource.setPage}
                        onChangePageSize={dataSource.setPageSize}
                    />
                </MUIListItem>
            </List>
        </Paper>
    );
}
