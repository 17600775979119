import React from 'react';

import {components, useApi} from '@arborian/narrf';

import {useResources} from 'ccm/dataSource';
import {useDialog} from 'ccm/components/dialogs';
import TagDialog from './TagDialog';
import TagChip from './TagChip';

const {dt2} = components;

const INITIAL_FETCH_OPTIONS = {
    sort: {field: 'prefix', direction: 'asc'},
    page: {number: 0, size: 20},
};

const TAG_TEMPLATE = {
    type: 'Tag',
    attributes: {
        name: 'TAGNAME',
        value: 'TAGVALUE',
        color: '#000000',
        backgroundColor: '#e0e0e0',
    },
};

export default function TagAdmin({owner, href}) {
    const api = useApi();
    const tagDialog = useDialog(TagDialog);
    const ds = useResources({
        prefix: 'tags.',
        href: href,
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
    });
    const handleRefresh = () => ds.fetch();
    const handleAdd = async ev => {
        const result = await tagDialog(TAG_TEMPLATE);
        if (!result) return;
        await api.fetchJsonApi(href, {
            method: 'POST',
            json: {data: result},
        });
        ds.fetch();
    };
    const handleUpdate = async (ev, row) => {
        const result = await tagDialog(row.data);
        if (!result) return;
        const newTag = await api.fetchJsonApi(href, {
            method: 'POST',
            json: {data: result},
        });
        console.log('newTag', newTag);
        if (newTag.data.id !== row.id) {
            ds.fetch();
        }
    };
    const handleDelete = async (ev, row) => {
        console.log('handleDelete', {ev, row});
        await api.fetchJsonApi(href, {
            method: 'DELETE',
            json: {data: row.data},
        });
        ds.fetch();
    };
    return (
        <dt2.DataTable
            options={{sortable: true, filtering: true}}
            dataSource={ds}
            title='Tag Admin'
        >
            <dt2.Column
                id='tag-attr-name'
                field='attributes.name'
                title='Name'
            />
            <dt2.Column
                id='tag-attr-value'
                field='attributes.value'
                title='Value'
            />
            <dt2.Column
                id='tag-render'
                title='Preview'
                filtering={false}
                sorting={false}
                render={row => (
                    <TagChip
                        tagAttrs={row.data.attributes}
                        onDelete={() => {}}
                    />
                )}
            />
            <dt2.Action
                id='tag-action-delete'
                onClick={handleDelete}
                icon='delete'
            />
            <dt2.Action
                id='tag-action-edit'
                onClick={handleUpdate}
                icon='edit'
            />
            <dt2.Action
                id='tag-action-add'
                free
                onClick={handleAdd}
                icon='add'
            />
            <dt2.Action
                id='tag-action-refresh'
                free
                onClick={handleRefresh}
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
