import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Paper,
    Toolbar,
    InputAdornment,
    IconButton,
    TextField,
    makeStyles,
} from '@material-ui/core';
import {Search, Clear, Refresh} from '@material-ui/icons';

import {components} from '@arborian/narrf';

import ListGridSelector from 'ccm/components/ListGridSelector';
import PatientCard from 'ccm/components/PatientCard';

const {Pagination} = components;
const pageSizeOptions = [5, 10, 20];

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiToolbar-root': {
            justifyContent: 'flex-end',
        },
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    noCards: {
        margin: theme.spacing(2),
    },
    toolbar: {
        display: 'flex',
        padding: theme.spacing(1),
        '& .MuiTextField-root': {
            flex: 1,
        },
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

function NoPatients() {
    const classes = useStyles();
    return (
        <div className={classes.noCards}>
            <h4>No Patients Available</h4>
            <p>
                You do not currently have permission to view any patient charts
                matching your search.
            </p>
            <p>
                To obtain access, you must ask an administrator to invite you to
                view their health data.
            </p>
        </div>
    );
}

function SearchField({search, onChangeSearch, ...props}) {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputValue(search || '');
    }, [search]);
    const acceptChange = ev => onChangeSearch(inputValue);
    return (
        <TextField
            value={inputValue}
            onChange={ev => setInputValue(ev.target.value)}
            onBlur={acceptChange}
            onKeyPress={ev => ev.key === 'Enter' && acceptChange()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        {search ? (
                            <IconButton onClick={e => onChangeSearch(null)}>
                                <Clear />
                            </IconButton>
                        ) : (
                            <IconButton onClick={acceptChange}>
                                <Search />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}
export default function PatientCards({dataSource, onChangeListGrid}) {
    const classes = useStyles();

    useEffect(() => {
        if (dataSource.state === 'init') {
            dataSource.fetch();
        }
    }, [dataSource]);

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <SearchField
                    search={dataSource.fetchOptions.search || ''}
                    onChangeSearch={dataSource.setSearch}
                />
                <IconButton onClick={() => dataSource.fetch()}>
                    <Refresh />
                </IconButton>
                <ListGridSelector value={'grid'} onChange={onChangeListGrid} />
            </Toolbar>

            <div className={classes.cardContainer}>
                {dataSource.state === 'ready' ? (
                    fp.isEmpty(dataSource.items) ? (
                        <NoPatients />
                    ) : (
                        fp.map(
                            item => (
                                <PatientCard
                                    key={item.id}
                                    patient={item.data}
                                />
                            ),
                            dataSource.items,
                        )
                    )
                ) : (
                    <div className={classes.noCards}>
                        <p>Fetching Patients...</p>
                    </div>
                )}
            </div>

            <div className={classes.paginationContainer}>
                <Pagination
                    count={dataSource.count}
                    page={dataSource.fetchOptions.page.number}
                    pageSize={dataSource.fetchOptions.page.size}
                    pageSizeOptions={pageSizeOptions}
                    onChangePage={dataSource.setPage}
                    onChangePageSize={dataSource.setPageSize}
                />
            </div>
        </Paper>
    );
}
