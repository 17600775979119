import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {Avatar, Tooltip, makeStyles} from '@material-ui/core';
import {
    AssignmentTurnedIn,
    AssignmentLate,
    Assignment,
    Block,
    Face,
    Publish,
    Print,
    PrintDisabled,
    HelpOutline,
    Archive,
    Inbox,
} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    stavatar: {
        display: 'flex',
        margin: theme.spacing(1, 0),
        '& .MuiAvatar-root': {
            margin: theme.spacing(0, 0.25),
        },
    },
    success: {
        backgroundColor: theme.palette.success.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    pccGreen: {
        backgroundColor: '#8EC428',
    },
    pfBlue: {
        backgroundColor: '#00A3FB',
    },
}));

function TicketStatusAvatar({ticket, classes}) {
    var title, className, Icon;
    const ticketStatus = fp.get('attributes.data.status', ticket);

    if (ticketStatus === 'resolved') {
        title = 'Resolved';
        className = classes.warning;
        Icon = Assignment;
    } else if (ticketStatus === 'acknowledged') {
        title = 'Acknowledged';
        className = classes.success;
        Icon = AssignmentTurnedIn;
    } else {
        title = 'Pending';
        className = classes.error;
        Icon = AssignmentLate;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

function PNStatusAvatar({ticket, classes}) {
    var className, Icon;
    const progressNoteStatus = fp.get(
        'attributes.progress_note_status',
        ticket,
    );
    var title = '';
    if (progressNoteStatus === 'complete') {
        className = classes.success;
        title = 'All progress notes uploaded';
        Icon = Publish;
    } else if (progressNoteStatus === 'partial') {
        className = classes.warning;
        title = 'Some progress notes uploaded';
        Icon = Publish;
    } else {
        className = classes.error;
        title = 'No progress notes uploaded';
        Icon = Block;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

function ArchiveStatusAvatar({ticket, classes}) {
    var title, className, Icon;
    const archived = fp.get('attributes.archived', ticket);

    if (archived) {
        title = 'Archived';
        className = classes.default;
        Icon = Archive;
    } else {
        title = 'Active';
        className = classes.success;
        Icon = Inbox;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

function FaxStatusAvatar({ticket, classes}) {
    var className, Icon;
    const faxSuccesses = fp.get('attributes.fax_successes', ticket);
    const faxNumbers = fp.pipe([
        fp.get('attributes.data.fax_number'),
        fp.map(num => {
            let result = num.replace(/\D/g, '');
            if (result[0] !== '1') {
                result = '1' + result;
            }
            return result;
        }),
    ])(ticket);
    const sentOriginal = fp.intersection(faxNumbers, faxSuccesses);

    const title = `${fp.size(sentOriginal)} / ${fp.size(
        faxNumbers,
    )} requested fax(es) sent`;
    if (fp.get('attributes.fax_status', ticket) === 'complete') {
        className = classes.success;
        Icon = Print;
    } else if (fp.get('attributes.fax_status', ticket) === 'partial') {
        className = classes.warning;
        Icon = Print;
    } else if (fp.get('attributes.fax_status', ticket) === 'none') {
        className = classes.error;
        Icon = PrintDisabled;
    } else {
        className = classes.error;
        Icon = HelpOutline;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

export default function Stavatar({ticket, classes}) {
    classes = {...classes, ...useStyles()};
    const rel = useSelector(ducks.jsonapi.selectRelated(ticket));
    return (
        <div className={classes.stavatar}>
            <Avatar src={fp.get('patient.links.photo_url', rel)}>
                <Face />
            </Avatar>
            <TicketStatusAvatar ticket={ticket} classes={classes} />
            <PNStatusAvatar ticket={ticket} classes={classes} />
            <ArchiveStatusAvatar ticket={ticket} classes={classes} />
            <FaxStatusAvatar ticket={ticket} classes={classes} />
        </div>
    );
}
