import React, {useState} from 'react';
import fp from 'lodash/fp';
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {useApi} from '@arborian/narrf';

const throttleWithOptions = fp.throttle.convert({fixed: false});

const fetchOptions = throttleWithOptions(
    1000,
    async (api, href, query, {setLoading, setOptions}) => {
        setLoading(true);
        const result = await api.fetchJsonApi(
            href,
            {
                filter: {
                    _q: query,
                },
            },
            {leading: false},
        );
        setOptions(result.data);
        setLoading(false);
    },
);

const _getOptionLabel = option => {
    return `${fp.get('attributes.value', option)}: ${fp.get(
        'attributes.description',
        option,
    )}`;
};

export default function AutosuggestFetch({
    href,
    onChange,
    value,
    getOptionSelected,
    getOptionLabel = _getOptionLabel,
    ...props
}) {
    const api = useApi();
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState();
    const [options, setOptions] = useState();

    const onInputChange = (ev, value, reason) => {
        setInputValue(value);
        return fetchOptions(api, href, value, {setLoading, setOptions});
    };

    return (
        <Autocomplete
            debug
            value={value}
            loading={loading}
            options={options || []}
            filterOptions={x => x}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderInput={params => <TextField {...params} {...props} />}
        />
    );
}
