import {useState, useCallback} from 'react';

import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

import fp from 'lodash/fp';

import {useApi, ducks} from '@arborian/narrf';

const inboxMsgFetchOptions = {
    sort: {field: 'attributes.timestamp', direction: 'desc'},
    filter: {thread_id: JSON.stringify({$ne: null})},
    include: ['thread', 'patient', 'thread.participants'],
};

const selectInternalThreads = createSelector(
    ducks.jsonapi.selectObject('InboxMessage'),
    ducks.jsonapi.selectObject('InternalMessageThread'),
    ducks.jsonapi.selectObject('UserSummary'),
    (inboxMessages, threads, users) => {
        const messagesByThread = fp.groupBy(
            fp.get('relationships.thread.data.id'),
            inboxMessages,
        );
        return fp.pipe([
            fp.filter(t => t.attributes.locked !== true),
            fp.map(t => {
                var unread = 0;
                const messages = fp.getOr([], t.id, messagesByThread);
                const total = messages.length;
                const participants = fp.map(
                    linkage => fp.get(linkage.id, users),
                    t.relationships.participants.data,
                );
                for (var msg of messages) {
                    if (!fp.get('attributes.read', msg)) {
                        unread += 1;
                    }
                }
                return {
                    id: t.id,
                    thread: t,
                    unread,
                    total,
                    messages,
                    participants,
                };
            }),
            fp.sortBy(fp.get('messages.0.attributes.timestamp')),
        ])(threads);
    },
);

export function useInternalThreads({user}) {
    const api = useApi();
    const href = fp.get('relationships.inbox.links.related', user);
    const [state, setState] = useState('init');
    const threads = useSelector(selectInternalThreads);
    const fetch = useCallback(async () => {
        if (href) {
            setState('busy');
            try {
                await api.fetchAllJsonApi(href, inboxMsgFetchOptions);
                setState('ready');
            } catch (e) {
                setState('error');
            }
        }
    }, [api, href]);
    const exposedState = threads.length ? 'ready' : state;
    return {
        items: threads,
        fetch,
        state: exposedState,
    };
}
