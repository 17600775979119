import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {
    Dialog,
    DialogTitle,
    Step,
    Stepper,
    StepLabel,
    useMediaQuery,
    makeStyles,
} from '@material-ui/core';
import {ducks} from '@arborian/narrf';

import SignatureStep from './SignatureStep';
import OrdersStep from './OrdersStep';
import ResolveStep from './ResolveStep';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiStepper-root': {padding: 0},
        '& .MuiDialogTitle-root': {padding: theme.spacing(1, 3)},
        '& .MuiDialogContent-root': {padding: theme.spacing(1, 3)},
    },
}));

const STEPS = {
    signature: {
        label: 'Sign Document(s)',
        render: props => <SignatureStep {...props} />,
    },
    orders: {
        label: 'Add Orders',
        render: props => <OrdersStep {...props} />,
    },
    resolve: {
        label: 'Resolve',
        render: props => <ResolveStep {...props} />,
    },
};

export default function ResolveWizardDialog({dialogRef}) {
    const classes = useStyles();
    const screenXS = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const screenSmallVert = useMediaQuery('(max-height:800px)');
    const small = screenXS || screenSmallVert;
    const [resolve, setResolve] = useState(null);
    const [ticketId, setTicketId] = useState(null);
    const [actions, setActions] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const ticket = useSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketId]),
    );
    const hasSignatureRequest = !!fp.get(
        'relationships.signature_form.data',
        ticket,
    );

    const stepIds = useMemo(() => {
        return hasSignatureRequest
            ? ['signature', 'orders', 'resolve']
            : ['orders', 'resolve'];
    }, [hasSignatureRequest]);

    const activeStepId = stepIds[activeStep];

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
        setActiveStep(0);
    };

    const handleNext = () => {
        const nextStep = (activeStep + 1) % fp.size(stepIds);
        setActiveStep(nextStep);
    };

    const handlePrevious = () => {
        const nextStep = Math.max(activeStep - 1, 0);
        setActiveStep(nextStep);
    };

    dialogRef.current = (tktId, actions) =>
        new Promise((resolve, reject) => {
            setTicketId(tktId);
            setActions(actions);
            setResolve(() => resolve);
        });

    return (
        <Dialog
            open={!!resolve}
            onClose={handleCancel}
            scroll='paper'
            maxWidth='xl'
            fullWidth
            fullScreen
            disableEnforceFocus={true}
            className={classes.root}
        >
            <DialogTitle>
                {small ? (
                    <Stepper activeStep={activeStep}>
                        <Step>
                            <StepLabel>{STEPS[activeStepId].label}</StepLabel>
                        </Step>
                    </Stepper>
                ) : (
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {fp.map(
                            stepId => (
                                <Step key={stepId}>
                                    <StepLabel>{STEPS[stepId].label}</StepLabel>
                                </Step>
                            ),
                            stepIds,
                        )}
                    </Stepper>
                )}
            </DialogTitle>
            {STEPS[activeStepId].render({
                ticket: ticket,
                actions: actions,
                onNext: handleNext,
                onPrevious: handlePrevious,
                onCancel: handleCancel,
            })}
        </Dialog>
    );
}
