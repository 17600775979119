import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {Button, makeStyles} from '@material-ui/core';
import {Fingerprint} from '@material-ui/icons';

import {useApi, lib} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    root: {
        width: 244,
        height: 40,
        marginTop: 6,
        marginBottom: 6,
        marginRight: 4,
    },
    avatar: {
        width: 16,
        height: 16,
    },
}));

export default function WebAuthnButton() {
    const api = useApi();
    const classes = useStyles();
    const history = useHistory();
    const {state} = useLocation();
    const credentialId = localStorage.getItem(`${api.client_id}.webauthn`);
    let next = fp.getOr('/', 'next', state);
    const handleClick = async ev => {
        const tok = await lib.webauthn.authenticate(api, credentialId);
        console.log('Got token response', tok);
        history.push(next);
    };

    if (!credentialId) return null;
    return (
        <Button
            disableRipple
            classes={classes}
            onClick={handleClick}
            startIcon={<Fingerprint />}
            variant='outlined'
        >
            Fingerprint/FaceID
        </Button>
    );
}
