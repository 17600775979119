import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {ducks} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';

export const useTimeZone = () => {
    const {patientId, ticketid} = useParams();
    const patientTz = usePatientTimeZone(patientId);
    const ticketTz = useTicketTimeZone(ticketid);
    return patientTz || ticketTz;
};

const usePatientTimeZone = patientId => {
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(patient));
    return fp.get('attributes.time_zone', rel.facility);
};

const useTicketTimeZone = ticketId => {
    const ticket = useSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketId]),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(ticket));
    return fp.get('attributes.time_zone', rel.facility);
};

export function Date({value}) {
    const timeZone = useTimeZone();
    try {
        return <>{h.formatDate(value, timeZone)}</>;
    } catch (e) {
        return <>&mdash;</>;
    }
}

export function DateTime({value}) {
    const timeZone = useTimeZone();
    try {
        return <>{h.formatDateTime(value, timeZone)}</>;
    } catch (e) {
        return <>&mdash;</>;
    }
}

export function ShortDateTime({value}) {
    const timeZone = useTimeZone();
    try {
        return <>{h.formatShortDateTime(value, timeZone)}</>;
    } catch (e) {
        return <>&mdash;</>;
    }
}
