import React from 'react';
import {useField} from 'formik';

import {TextField, FormHelperText} from '@material-ui/core';

import {Autocomplete} from '@material-ui/lab';

import {fieldId} from 'ccm/lib/util';

/* FreeSolo is a version of the Autocomplete, but all options must be
 * strings and the user is allowed to type in things that are not on the list
 */

export function FreeSoloField({label, fullWidth, ...props}) {
    const [field, meta, helpers] = useField(props);
    const required = props.required;
    const renderInput = props => {
        const error = meta.error === 'required';
        return (
            <TextField
                label={label}
                fullWidth={fullWidth}
                helperText={error ? 'required' : null}
                required={required}
                error={error}
                {...props}
            />
        );
    };
    const onChange = (ev, value, reason) => {
        console.log('Changed:', value);
        helpers.setValue(value);
    };
    let allProps = {
        ...field,
        ...props,
        freeSolo: true,
        autoSelect: true,
        renderInput,
        onChange,
    };
    allProps.value = allProps.value || (props.multiple ? [] : '');
    return <Autocomplete id={fieldId('dyn', field.name)} {...allProps} />;
}

const DynaformField = ({field}) => {
    return (
        <>
            <FreeSoloField
                name={field.id}
                label={field.label}
                options={field.options}
                required={field.required}
                multiple={field.multiple}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
};
export default DynaformField;
