import React, {useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormGroup,
    Button,
    TextField as MUITextField,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';
import {Autocomplete} from 'formik-material-ui-lab';

import {useApi, ducks} from '@arborian/narrf';

import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';

export default function MassTicketsDialog({dialogRef}) {
    const api = useApi();
    const [resolve, setResolve] = useState(null);
    const [count, setCount] = useState(null);
    const [total, setTotal] = useState(null);
    const ticketTypes = useAllTicketTypesLookup();
    const ehrPlugins = useSelector(ducks.jsonapi.selectObject('EHRPlugin'));
    const initialValues = useMemo(() => {
        let result = {includeAll: false, ticketType: 'OTHER', plugin: {}};
        fp.forEach(p => {
            result.plugin[p.id] = false;
        }, ehrPlugins);
        return result;
    }, [ehrPlugins]);

    useEffect(() => {
        if (api && api.isAuthorized()) {
            api.fetchJson(api.url_for('ehr.Plugins'));
        }
    }, [api]);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = ({count = 1, total = 1}) => {
        return new Promise((resolve, reject) => {
            setCount(count);
            setTotal(total);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>
                            Mass Ticket Creation ("the bot")
                        </DialogTitle>
                        <DialogContent>
                            <Field
                                component={Autocomplete}
                                fullWidth
                                autoComplete
                                autoHighlight
                                name='ticketType'
                                options={fp.keys(ticketTypes)}
                                getOptionLabel={o => ticketTypes[o]}
                                renderInput={params => (
                                    <MUITextField
                                        label='Ticket Type'
                                        {...params}
                                    />
                                )}
                            />
                            <FormGroup>
                                <legend>
                                    Upload progress notes to the following EHRs:
                                </legend>
                                {fp.map(
                                    plugin => (
                                        <Field
                                            component={CheckboxWithLabel}
                                            type='checkbox'
                                            size='small'
                                            name={`plugin.${fp.get(
                                                'id',
                                                plugin,
                                            )}`}
                                            Label={{
                                                label: fp.get(
                                                    'attributes.label',
                                                    plugin,
                                                ),
                                            }}
                                        />
                                    ),
                                    ehrPlugins,
                                )}
                            </FormGroup>
                            {total > count ? (
                                <>
                                    <p>
                                        You selected {count} patients, but{' '}
                                        {total} patients fit your search
                                        criteria.
                                    </p>
                                    <Field
                                        component={CheckboxWithLabel}
                                        type='checkbox'
                                        name='includeAll'
                                        Label={{
                                            label: 'Include all matching patients',
                                        }}
                                    />
                                </>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Create tickets
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
