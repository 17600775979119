import React, {useMemo} from 'react';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import {Tooltip} from '@material-ui/core';
import {Flag} from '@material-ui/icons';

import {useApi, components} from '@arborian/narrf';
import {useResources} from 'ccm/dataSource';
import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';
import {url_for} from 'ccm/routes';

const {dt2} = components;

const initialFetchOptions = {
    page: {number: 0, size: 20},
    sort: {field: 'attributes.data.created', direction: 'desc'},
};

export default function PatientTicketTable({
    patient,
    patientDataSource,
    actions,
    onChanged,
}) {
    const api = useApi();
    const pdsFilters = fp.get('fetchOptions.filter', patientDataSource);
    const mergeFetchOptions = useMemo(() => {
        const ticketTypes = fp.getOr(
            null,
            ['attributes.hasTickets_ofType'],
            pdsFilters,
        );
        const ticketsSince = fp.getOr(
            null,
            ['attributes.hasTickets_since'],
            pdsFilters,
        );
        let filter = {};
        if (ticketTypes !== null)
            filter['attributes.data.ticket_type'] = ticketTypes;
        if (ticketsSince !== null)
            filter['attributes.data.created'] = {
                $gt: ticketsSince,
            };
        return {
            filter,
        };
    }, [pdsFilters]);

    const primary_patient_id = fp.getOr(
        patient.id,
        'relationships.primary_patient.data.id',
        patient,
    );

    const ds = useResources({
        href: api.url_for('patient.PatientTickets', {
            patient_id: primary_patient_id,
        }),
        initialFetchOptions,
        mergeFetchOptions,
    });

    const handleCreateVisit = async (ev, rows) => {
        console.log('createVisit', {patient, rows});
        const tickets = fp.map('data', rows);
        const visit = await actions.createVisit(patient, tickets);
        if (visit) {
            onChanged && onChanged();
        }
    };

    const ticketTypes = useAllTicketTypesLookup();
    return (
        <dt2.DataTable
            id='patient-tickets'
            title='Patient tickets'
            size='small'
            dataSource={ds}
            options={{selection: true}}
        >
            <dt2.Column
                id='col-ticket-id'
                title='Ticket ID'
                field='attributes.id_str'
                sorting={false}
                render={row => (
                    <Tooltip title={row.id}>
                        <R.Link
                            to={url_for('ticket', {
                                ticketid: row.id,
                            })}
                        >
                            {fp.truncate({length: 10}, row.id)}
                            {fp.get('attributes.priority', row.data) ===
                                'urgent' && (
                                <Flag fontSize='small' color='error' />
                            )}
                        </R.Link>
                    </Tooltip>
                )}
            />
            <dt2.Column
                id='col-ticket-date'
                title='Created'
                field='attributes.data.created'
                type='date'
            />
            <dt2.Column
                id='col-ticket-type'
                title='Ticket Type'
                field='attributes.data.ticket_type'
                lookup={ticketTypes}
            />
            <dt2.Column
                id='col-status'
                title='Status'
                field='attributes.data.status'
                sorting={false}
            />
            <dt2.Column
                id='col-owner'
                title='Owner'
                field='attributes.data.owner_name'
                sorting={false}
            />
            <dt2.Column
                id='col-provider'
                title='Provider'
                field='attributes.data.provider_name'
                sorting={false}
            />
            <dt2.Action
                name='create-visit'
                tooltip='Create visit addressing selected ticket(s)'
                icon='event'
                onClick={handleCreateVisit}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={() => ds.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
