import React, {useEffect, useState} from 'react';
import fp from 'lodash/fp';
import {
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Switch,
    Tooltip,
} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';

import SignatureForm, {useFormState} from 'ccm/components/SignatureForm';
import useStyles from './styles';

function Toggle({label, value, onChange}) {
    return (
        <FormControlLabel
            control={<Switch checked={value} onChange={onChange} />}
            label={label}
        />
    );
}

export default function SignatureStep({ticket, actions, onNext, onCancel}) {
    const classes = useStyles();
    const formState = useFormState(ticket, actions);
    const [busy, setBusy] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const resetForm = formState.reset;
    useEffect(() => resetForm(), [resetForm]);

    const handleSave = async () => {
        setBusy(true);
        try {
            await formState.save();
        } finally {
            setBusy(false);
        }
    };

    const handleNext = async () => {
        await handleSave();
        onNext();
    };

    const handleChange = async () => {
        if (isEditing) {
            await formState.save();
        }
        setIsEditing(!isEditing);
    };

    const unsignedFields = fp.pipe([
        fp.map(page => fp.get('attributes.fields', page)),
        fp.flatten,
        fp.filter(field => !fp.get('filled', field)),
        fp.size,
    ])(formState.pages);

    return (
        <>
            {busy && (
                <div className={classes.overlay}>
                    <Alert severity='success'>
                        <AlertTitle>Saving...</AlertTitle>
                    </Alert>
                    <div className={classes.disabled}></div>
                </div>
            )}
            {isEditing && (
                <Alert severity='info'>
                    <AlertTitle>Edit mode active</AlertTitle>
                    Make your changes to the signature request here. When
                    finished, toggle the 'Edit Signature Request' button and
                    your changes to the form will be automatically saved.
                </Alert>
            )}
            <DialogContent dividers>
                <Toggle
                    label='Edit Signature Request'
                    value={isEditing}
                    onChange={handleChange}
                />
                <SignatureForm formState={formState} isEditing={isEditing} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Close</Button>
                <Button
                    onClick={handleSave}
                    variant='contained'
                    color='primary'
                >
                    Save Progress
                </Button>
                <Tooltip
                    title={
                        !formState.complete
                            ? `Complete ${unsignedFields} remaining field(s) to continue`
                            : ''
                    }
                    arrow
                >
                    <span>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleNext}
                            disabled={!formState.complete}
                        >
                            Next
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </>
    );
}
