import React from 'react';
import fp from 'lodash/fp';

import {useSnackbar} from 'notistack';

import {useApi} from '@arborian/narrf';
import BasePage from 'ccm/components/BasePage';

import PasswordResetRequestForm from 'ccm/components/forms/PasswordResetRequestForm';

export default function CallbackPage() {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmit = async (attributes, {resetForm}) => {
        try {
            await api.fetch(api.url_for('auth.password_reset_request'), {
                method: 'POST',
                json: {data: {type: 'PasswordResetRequest', attributes}},
            });
            enqueueSnackbar('Password reset email sent', {
                persist: false,
                variant: 'success',
            });
            resetForm();
        } catch (e) {
            const status = fp.getOr('Unknown error', 'response.statusText', e);
            const msg = `Error sending password reset request: ${status}`;
            console.error(msg, e.response);
            enqueueSnackbar(msg, {
                persist: true,
                variant: 'error',
            });
        }
    };

    return (
        <BasePage title='Reset password'>
            <h3>Reset your password</h3>
            <p>
                Enter the email address associated with your account to request
                that your password be reset.
            </p>
            <PasswordResetRequestForm onSubmit={handleSubmit} />
        </BasePage>
    );
}
