import React, {useState} from 'react';
import fp from 'lodash/fp';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar';
import {CircularProgress} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import * as dfn from 'date-fns';
import * as dfp from 'date-fns/fp';
import * as locales from 'date-fns/locale';

import {useCalendarEvents} from 'ccm/lib/hooks';
import useStyles from './styles';

const localizer = dateFnsLocalizer({...dfn, locales});

const ACCESSORS = {
    titleAccessor: fp.get('attributes.facility_name'),
    startAccessor: fp.pipe([fp.get('attributes.visit_date'), dfp.parseISO]),
    endAccessor: fp.pipe([
        fp.get('attributes.visit_date'),
        dfp.parseISO,
        dfp.add({minutes: 30}),
    ]),
    allDayAccessor: () => true,
};

export default function ProviderVisitsCalendar({provider}) {
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [view, setView] = useState('month');
    const className = view === 'month' ? classes.calMonth : null;
    const href = fp.get('relationships.facilityVisits.links.related', provider);
    const filterName = 'attributes.visit_date';
    const {calRef, events, fetchState} = useCalendarEvents({
        href,
        filterName,
        date,
        view,
    });

    const min = new Date();
    console.log('Calendar events', {events, href, fetchState});
    return (
        <>
            {fetchState === 'busy' && (
                <Alert severity='info'>
                    <AlertTitle>Fetching visits...</AlertTitle>
                    <CircularProgress />
                </Alert>
            )}
            {fetchState === 'error' && (
                <Alert severity='error'>Error fetching visits</Alert>
            )}
            <Calendar
                ref={calRef}
                className={className}
                view={view}
                onView={setView}
                views={['month', 'week', 'day', 'agenda']}
                localizer={localizer}
                date={date}
                min={min}
                max={min}
                events={events}
                onNavigate={setDate}
                // onSelectEvent={handleSelectEvent}
                // eventPropGetter={getEventProps}
                // resources={resources}
                {...ACCESSORS}
            />
        </>
    );
}
