import React, {useCallback} from 'react';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {
    TextField as MUITextField,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';

import {fieldId} from 'ccm/lib/util';

function TextField({
    field,
    form, // From Formik
    label,
    caption,
    options,
    select,
    required,
    image,
}) {
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);
    const defaultValue = '';

    let img = null;
    if (image) {
        try {
            const images = require.context('../../images', true);
            img = images('./' + image).default;
        } catch {}
    }

    return (
        <React.Fragment>
            <img alt='' src={img} />
            <MUITextField
                id={fieldId('dyn', field.name)}
                value={field.value || defaultValue}
                select={select}
                required={required}
                fullWidth
                error={!!(touched && errors)}
                helperText={touched && errors}
                label={label}
                onChange={useCallback(
                    ev => {
                        form.setFieldValue(field.name, ev.target.value);
                    },
                    [form, field],
                )}
            >
                {fp.map(
                    option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ),
                    options,
                )}
            </MUITextField>
            {caption ? <FormHelperText>{caption}</FormHelperText> : null}
        </React.Fragment>
    );
}

const DynaformField = ({field}) => {
    return (
        <Field
            component={TextField}
            name={field.id}
            label={field.label}
            caption={field.caption}
            options={field.options}
            select={field.select}
            required={field.required}
            multiple={field.multiple}
            image={field.image}
        />
    );
};
export default DynaformField;
