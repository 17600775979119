import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';

const defaultValues = {
    type: 'PatientFile',
    // attachment fields?
    includeAll: false,
};

export default function FileUploadDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState(defaultValues);
    const [patient, setPatient] = useState(null);
    const [count, setCount] = useState(null);
    const [total, setTotal] = useState(null);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
        setPatient(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
        setPatient(null);
    };

    dialogRef.current = ({patient = null, count = 1, total = 1}) => {
        return new Promise((resolve, reject) => {
            setPatient(patient);
            setCount(count);
            setTotal(total);
            setInitialValues(initialValues);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors, setFieldValue}) => (
                    <Form>
                        <DialogTitle>
                            Upload file to{' '}
                            {patient
                                ? patient.attributes.name.formatted
                                : `${count} Patient(s)`}
                        </DialogTitle>

                        <DialogContent>
                            <input
                                id='file'
                                name='file'
                                type='file'
                                onChange={event => {
                                    setFieldValue(
                                        'file',
                                        event.currentTarget.files[0],
                                    );
                                }}
                            />

                            {total > count ? (
                                <>
                                    <p>
                                        You selected {count} patients, but{' '}
                                        {total} patients fit your search
                                        criteria.
                                    </p>
                                    <Field
                                        component={CheckboxWithLabel}
                                        type='checkbox'
                                        name='includeAll'
                                        Label={{
                                            label:
                                                'Include all matching patients',
                                        }}
                                    />
                                </>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
