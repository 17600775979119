import React, {createContext, useContext} from 'react';
import {useHistory} from 'react-router-dom';

import {
    Tabs as MUITabs,
    Accordion as MUIAccordion,
    makeStyles,
} from '@material-ui/core';

import {useQuery} from 'ccm/routes';
export {Tab} from '@material-ui/core';

const Context = createContext();

const useStyles = makeStyles(theme => ({
    horizontal: {
        // just use default styling
    },
    vertical: {
        flexGrow: 1,
        display: 'flex',
        '& .MuiTabs-root': {
            borderRight: `1 px solid ${theme.palette.divider}`,
        },
    },
}));

export const useTabContext = () => useContext(Context);

export const useQueryTabControl = (queryKey, options) => {
    const {defaultTab} = options;
    const history = useHistory();
    const query = useQuery();

    const onChange = (ev, index) => {
        if (index) {
            query.set(queryKey, index);
        } else {
            query.delete(queryKey);
        }
        history.push('?' + query.toString());
    };

    const value = query.get(queryKey) || defaultTab;

    return {value, onChange, options};
};

export function TabProvider({
    queryKey,
    defaultTab,
    orientation = 'horizontal',
    children,
    ...props
}) {
    const tab = useQueryTabControl(queryKey, {defaultTab, orientation});
    const classes = useStyles();

    return (
        <Context.Provider value={tab}>
            <div
                className={
                    orientation === 'vertical'
                        ? classes.vertical
                        : classes.horizontal
                }
                {...props}
            >
                {children}
            </div>
        </Context.Provider>
    );
}

export function Tabs({control, children, ...props}) {
    const ctx = useTabContext();
    if (!control) control = ctx;
    return (
        <MUITabs
            value={control.value}
            onChange={control.onChange}
            orientation={control.options.orientation}
            {...props}
        >
            {children}
        </MUITabs>
    );
}

export function TabPanel({index, control, children}) {
    const ctx = useTabContext();
    if (!control) control = ctx;
    if (index === control.value) {
        return <>{children}</>;
    } else {
        return null;
    }
}

export function Accordion({index, ...props}) {
    const ctx = useTabContext();
    const handleChange = ev => {
        if (index === ctx.value) {
            ctx.onChange(ev, null);
        } else {
            ctx.onChange(ev, index);
        }
    };
    return (
        <MUIAccordion
            expanded={index === ctx.value}
            onChange={handleChange}
            {...props}
        />
    );
}

export const AccordionProvider = TabProvider;
