import React from 'react';
import fp from 'lodash/fp';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    IconButton,
} from '@material-ui/core';
import {ExpandMore, Refresh} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {CheckboxWithLabel} from 'formik-material-ui';

import {FacilitySelect, ProviderSelect} from 'ccm/components/formikFields';
import useStyles from './styles';

const INITIAL_FILTERS = {
    practice: null,
    providers: [],
    facilities: [],
    facColumns: false,
};

export default function VisitsFilter({
    fetchOptions,
    setFetchOptions,
    practice,
    facColumns,
    setFacColumns,
    onRefresh,
}) {
    const classes = useStyles();
    const handleSubmit = async (values, form) => {
        let newFetchOptions = fp.pipe(
            fp.unset(['filter', 'attributes.facility_id']),
            fp.unset(['filter', 'attributes.provider_user_id']),
        )(fetchOptions);
        if (!fp.isEmpty(values.facilities)) {
            newFetchOptions = fp.set(
                ['filter', 'attributes.facility_id'],
                fp.map('id', values.facilities),
                newFetchOptions,
            );
        }
        if (!fp.isEmpty(values.providers)) {
            newFetchOptions = fp.set(
                ['filter', 'attributes.provider_user_id'],
                fp.map('id', values.providers),
                newFetchOptions,
            );
        }
        console.log('Setting filters', {
            fetchOptions,
            newFetchOptions,
        });
        setFacColumns(values.facColumns);
        setFetchOptions(newFetchOptions);
    };
    const handleClearFilters = resetForm => {
        resetForm();
        setFetchOptions({});
    };
    const handleRefresh = ev => {
        ev.preventDefault();
        ev.stopPropagation();
        onRefresh && onRefresh();
    };
    const initialValues = fp.set('facColumns', facColumns, INITIAL_FILTERS);
    return (
        <Accordion className={classes.filters}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h6>
                    Visit Filters
                    <IconButton onClick={handleRefresh}>
                        <Refresh />
                    </IconButton>
                </h6>
            </AccordionSummary>
            <AccordionDetails>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({touched, values, errors, handleSubmit, resetForm}) => (
                        <Form onSubmit={handleSubmit}>
                            <Field
                                name='facilities'
                                label='Facilities'
                                component={FacilitySelect}
                                fullWidth
                                multiple
                            />
                            <Field
                                name='providers'
                                label='Providers'
                                component={ProviderSelect}
                                practice={practice}
                                fullWidth
                                multiple
                            />
                            <Field
                                name='facColumns'
                                Label={{
                                    label: 'Show facility columns on day/week views?',
                                }}
                                component={CheckboxWithLabel}
                            />
                            <div className={classes.actions}>
                                <Button
                                    onClick={ev =>
                                        handleClearFilters(resetForm)
                                    }
                                >
                                    Clear Filters
                                </Button>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    onClick={handleSubmit}
                                >
                                    Set Filters
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </AccordionDetails>
        </Accordion>
    );
}
