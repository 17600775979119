import React from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {makeStyles, Link} from '@material-ui/core';
import {OpenInNew} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import {components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    linkBox: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function NameColumnComponent({row}) {
    return (
        <Link
            component={R.Link}
            to={url_for('facility', {
                facilityId: row.data.attributes.facility_id,
            })}
        >
            {fp.get('attributes.name', row.data)}
        </Link>
    );
}

function StarRatingComponent(value) {
    return <Rating value={value} readOnly />;
}

function CMSLink({row}) {
    const classes = useStyles();
    const href = row.data.links.cms_link;
    if (!href) return null;
    return (
        <Link href={href} target='_blank' className={classes.linkBox}>
            Details &nbsp; <OpenInNew fontSize='small' />
        </Link>
    );
}

export default function CmsFacilitiesTable({
    dataSource,
    tableOptions,
    compact = false,
}) {
    const refresh = () => dataSource.fetch();

    return (
        <dt2.DataTable
            id='cms-facility-table'
            title='Latest CMS Quality Ratings'
            size='small'
            dataSource={dataSource}
            options={{search: true, filtering: true, ...tableOptions}}
        >
            <dt2.Column
                title='CMS Facility'
                field='attributes.name'
                component={NameColumnComponent}
            />

            {!compact && <dt2.Column title='CCN' field='attributes.cms_ccn' />}
            {!compact && (
                <dt2.Column
                    title='Address'
                    field='attributes.address.oneLine'
                    filtering={false}
                />
            )}
            <dt2.Column
                title='Overall Quality Rating'
                field='attributes.quality_measure_rating_overall'
                filtering={false}
                sorting={false}
                render={row =>
                    StarRatingComponent(
                        fp.get(
                            'data.attributes.quality_measure_rating_overall',
                            row,
                        ),
                    )
                }
            />
            <dt2.Column
                title='Short-Stay Rating'
                field='attributes.quality_measure_rating_short_stay'
                filtering={false}
                sorting={false}
                render={row =>
                    StarRatingComponent(
                        fp.get(
                            'data.attributes.quality_measure_rating_short_stay',
                            row,
                        ),
                    )
                }
            />
            <dt2.Column
                title='Long-Stay Rating'
                field='attributes.quality_measure_rating_long_stay'
                filtering={false}
                sorting={false}
                render={row =>
                    StarRatingComponent(
                        fp.get(
                            'data.attributes.quality_measure_rating_long_stay',
                            row,
                        ),
                    )
                }
            />
            <dt2.Column
                title='CMS Ratings'
                filtering={false}
                sorting={false}
                component={CMSLink}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
