import {useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {useApi, components} from '@arborian/narrf';

const {useQueryFetchOptions, useDataActions, useReduxDataSource} = components;

const defaultFetchOptions = {
    // filter: {},
    // sort: {},
    page: {number: 0, size: 20},
};

const defaultOptions = {};

export default function useGroupMembers(protectedResource, props = {}) {
    let {
        prefix = `mbrs`,
        options = defaultOptions,
        initialFetchOptions = defaultFetchOptions,
    } = props;
    const api = useApi();
    const history = useHistory();
    const [fetchOptions, setFetchOptions] = useQueryFetchOptions(
        history,
        prefix,
        initialFetchOptions
    );

    const fetchItems = useCallback(
        async (fetchOptions) => {
            if (!fetchOptions) return {rows: []};
            const url = api.url_for('user.UserCollection');
            const allOptions = {
                ...options,
                filter: {
                    group_ids: JSON.stringify({
                        $regex: protectedResource.meta.group_regexp,
                    }),
                },
            };
            console.log('Fetch', {url, fetchOptions, allOptions});
            const result = await api.fetchDataTable(
                url,
                fetchOptions,
                allOptions
            );
            return result;
        },
        [api, protectedResource, options]
    );
    const ds = useReduxDataSource({data: fetchItems, fetchOptions});
    const actions = useDataActions(fetchOptions, setFetchOptions);

    useEffect(fetchItems, [fetchItems]);

    return {...ds, ...actions};
}
