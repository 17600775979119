import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {Button, Card, CardMedia} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import fp from 'lodash/fp';
import {Formik, Form} from 'formik';
import {createSelector} from '@reduxjs/toolkit';

import {useApi, ducks} from '@arborian/narrf';
import {url_for} from 'ccm/routes';
import {AutocompleteField} from 'ccm/components/fields/Autocomplete';
import ConsentFormAlert from 'ccm/components/ConsentFormAlert';
import BasePage from 'ccm/components/BasePage';

const useStyles = makeStyles((theme) => ({
    pre: {
        textAlign: 'left',
    },
    formContainer: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingTop: '2em',
    },
    formTitle: {
        textAlign: 'center',
        paddingBottom: '1em',
    },
    formShape: {
        flex: 1,
    },
    buttonBar: {
        textAlign: 'center',
    },
    fieldSpacing: {
        padding: 10,
    },
    cardFrame: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(6),
    },
    imageContainer: {
        maxWidth: '30em',
    },
    imageStyles: {
        height: 'auto',
        padding: theme.spacing(1),
    },
}));

const selectPractice = (practiceSlug) =>
    createSelector(
        ducks.jsonapi.selectObject('Practice'),
        fp.find((p) => fp.get('attributes.slug', p) === practiceSlug)
    );

function FacilitySelectField({options, ...props}) {
    const selectorOptions = useMemo(
        () =>
            fp.pipe([
                fp.sortBy('attributes.label'),
                fp.map((obj) => ({value: obj.id, label: obj.attributes.label})),
            ])(options),
        [options]
    );
    return (
        <AutocompleteField
            options={selectorOptions}
            getOptionLabel={fp.get('label')}
            {...props}
        />
    );
}

export default function CommunicateAnonPage() {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    let {practiceSlug} = useParams();
    const practice = useSelector(selectPractice(practiceSlug));
    const logoUrl = fp.get('links.logo_url', practice);
    const [facilities, setFacilities] = useState();
    const [checked, setChecked] = useState(false);
    const handleChange = (ev) => {
        setChecked(ev.target.checked);
    };
    useEffect(() => {
        api.fetchJsonApi(
            api.url_for('practice.PracticePublic', {slug: practiceSlug})
        )
            .then((resp) => {
                return api.fetchAllJsonApi(
                    resp.data.relationships.facilities.links.related
                );
            })
            .then((response) => setFacilities(response));
    }, [api, practiceSlug]);

    const newSession = async (formData, props) => {
        const url = api.url_for('ticketsession.AnonTicketSessionCollection');
        const session = await api.fetchJsonApi(url, {
            method: 'POST',
            json: {
                facility_id: formData.facility.value,
                session_data: {practiceSlug},
            },
        });
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        history.push(enterFormFlow);
    };

    const handleSubmit = async (data, formikProps) => {
        await newSession(data, formikProps);
    };
    if (!practice)
        return (
            <BasePage>
                <h4 className={classes.cardFrame}>404 - Practice not found</h4>
            </BasePage>
        );
    return (
        <BasePage>
            <div className={classes.body}>
                <Formik
                    initialValues={{facility: null}}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {(formikProps) => (
                        <div className={classes.formContainer}>
                            <div className={classes.cardFrame}>
                                {logoUrl ? (
                                    <Card className={classes.imageContainer}>
                                        <CardMedia
                                            className={classes.imageStyles}
                                            component="img"
                                            image={logoUrl}
                                        />
                                    </Card>
                                ) : (
                                    <h3>
                                        {fp.get('attributes.name', practice)}
                                    </h3>
                                )}
                            </div>
                            <h4 className={classes.formTitle}>
                                Choose a Facility:
                            </h4>
                            <Form
                                className={classes.formShape}
                                onSubmit={formikProps.handleSubmit}
                            >
                                <FacilitySelectField
                                    fullWidth
                                    options={facilities || []}
                                    name="facility"
                                    label="Select a Facility"
                                    required={true}
                                />
                                <div className={classes.buttonBar}>
                                    <Button onClick={() => history.go(-1)}>
                                        Back
                                    </Button>
                                    <Button
                                        className={classes.createButton}
                                        type="submit"
                                        variant="contained"
                                        style={{margin: 20}}
                                        disabled={!checked}
                                    >
                                        Next
                                    </Button>
                                </div>
                                <br />
                                <ConsentFormAlert
                                    practices={[practice]}
                                    value={checked}
                                    onChange={handleChange}
                                />
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </BasePage>
    );
}
