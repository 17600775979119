import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    form: {
        padding: theme.spacing(1),
    },
    checkboxList: {
        width: '100%',
        display: 'flex',
    },
    buttonSpacing: {
        marginTop: 14,
    },
}));
