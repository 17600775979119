import React from 'react';
import fp from 'lodash/fp';

import {Link, useTheme, useMediaQuery} from '@material-ui/core';

import {components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

function Filename({value, row}) {
    return (
        <Link target='_blank' href={fp.get('data.links.download', row)}>
            {value}
        </Link>
    );
}

function Filesize({value}) {
    return h.humanFileSize(value);
}

export default function TableView({patient, dataSource}) {
    const theme = useTheme();
    const narrow = useMediaQuery(theme.breakpoints.down('xs'));

    const refresh = () => dataSource.fetch();

    return (
        <dt2.DataTable
            title='Files'
            size='small'
            dataSource={dataSource}
            editable={{onRowDelete: dataSource.handleDelete}}
            options={{search: true}}
        >
            <dt2.Column
                title='Filename'
                field='attributes.file.filename'
                component={Filename}
            />
            <dt2.Column
                title='Size'
                field='attributes.file.length'
                component={Filesize}
            />
            {!narrow ? (
                <dt2.Column
                    title='Upload Date'
                    field='attributes.file.uploadDate'
                    component={DateTime}
                />
            ) : null}
            <dt2.Action
                free
                tooltip='Upload a file'
                icon='cloud_upload'
                onClick={() => dataSource.handleUpload()}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
