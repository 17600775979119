import React from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import PatientAvatar from 'ccm/components/PatientAvatar';

const {dt2} = components;

function PatientAvatarColumnComponent({row}) {
    return <PatientAvatar patient={row.data} />;
}

function PatientFirstNameColumnComponent({row}) {
    return (
        <Link to={url_for('patient', {patientId: row.data.id})}>
            {fp.get('attributes.name.first', row.data)}
        </Link>
    );
}

function PatientLastNameColumnComponent({row}) {
    return (
        <Link to={url_for('patient', {patientId: row.data.id})}>
            {fp.get('attributes.name.last', row.data)}
        </Link>
    );
}

const DEFAULT_OPTIONS = {search: true, filtering: true};
const DEFAULT_FETCH_OPTIONS = {page: {size: 20}};

export default function PatientTable({
    tableRef = null,
    options = DEFAULT_OPTIONS,
    dataSource = null,
    href = null,
    include,
    children,
    prefix = 'pt.',
    initialFetchOptions = DEFAULT_FETCH_OPTIONS,
    showAvatar = true,
    ...props
}) {
    return (
        <dt2.DataTable
            size='small'
            dataSource={dataSource}
            tableRef={tableRef}
            options={options}
            {...props}
        >
            {showAvatar && (
                <dt2.Column
                    id='avatar'
                    filtering={false}
                    sorting={false}
                    component={PatientAvatarColumnComponent}
                />
            )}
            <dt2.Column
                id='last'
                title='Last'
                filtering={true}
                sorting={true}
                editable={false}
                field='attributes.name.last'
                component={PatientLastNameColumnComponent}
            />
            <dt2.Column
                id='first'
                title='First'
                filtering={true}
                sorting={true}
                editable={false}
                field='attributes.name.first'
                component={PatientFirstNameColumnComponent}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            {children}
        </dt2.DataTable>
    );
}
