import React, {useState} from 'react';

import useFlowsheet from './hooks';
import FlowsheetForm from './FlowsheetForm';
import FlowsheetTable from './FlowsheetTable';
import FlowsheetChart from './FlowsheetChart';
import {Tabs, Tab} from '@material-ui/core';

export default function Flowsheet({patient}) {
    const ds = useFlowsheet(patient);
    const [tab, setTab] = useState(0);
    return (
        <div>
            <FlowsheetForm dataSource={ds} />
            <Tabs value={tab} onChange={(e, index) => setTab(index)}>
                <Tab label='Table' />
                <Tab label='Chart' />
            </Tabs>
            {tab === 0 && <FlowsheetTable dataSource={ds} />}
            {tab === 1 && <FlowsheetChart dataSource={ds} />}
        </div>
    );
}
