import React, {useReducer, useState, useMemo, useRef, useEffect} from 'react';
import fp from 'lodash/fp';

const DialogContext = React.createContext();

export function useDialogContext() {
    return React.useContext(DialogContext);
}

const defaultProps = {};
export function useDialog(Component, props = defaultProps) {
    const {dispatch, state} = React.useContext(DialogContext);
    const [id] = useState(Math.random());
    const dialogRef = useRef();
    const [_props, set_props] = useState({});
    // If props are used, make sure we don't re-render
    // when they don't **really** change
    useEffect(() => {
        if (!fp.isEqual(props, _props)) set_props(props);
    }, [props, _props, set_props]);
    const dlg = useMemo(
        () => ({id, dialogRef, Component, props: _props}),
        [id, dialogRef, Component, _props],
    );
    useEffect(() => {
        dispatch({type: 'add', dlg});
        return () => dispatch({type: 'del', dlg});
    }, [dispatch, dlg]);
    return (...args) => {
        try {
            return dialogRef.current(...args);
        } catch (e) {
            console.error('Cannot open dialog', Component, 'in', state);
            throw e;
        }
    };
}

function reducer(state, action) {
    const {type, dlg} = action;
    if (type === 'add') {
        const current = fp.get(dlg.id, state);
        if (current !== dlg) {
            return fp.set(dlg.id, dlg, state);
        } else {
            return state;
        }
    } else if (type === 'del') {
        return fp.unset(dlg.id, state);
    }
}

export default function DialogProvider({children, dialogs}) {
    const [state, dispatch] = useReducer(reducer, {});
    const contextValue = {dispatch, state};

    return (
        <DialogContext.Provider value={contextValue}>
            {children}
            {fp.map(
                dlg => (
                    <dlg.Component
                        key={dlg.id}
                        dialogRef={dlg.dialogRef}
                        {...dlg.props}
                    />
                ),
                state,
            )}
        </DialogContext.Provider>
    );
}
