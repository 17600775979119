import React, {useMemo} from 'react';
import fp from 'lodash/fp';

import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';

import useStyles from './styles';
import CredentialsCard from './CredentialsCard';
import UserProfileCard from './UserProfileCard';
import EmailsCard from './EmailsCard';
import TelecomCard from './TelecomCard';
import SignatureCard from './SignatureCard';
import UserPreferencesCard from './UserPreferencesCard';
import ProviderSchedule from './ProviderSchedule';

const userIsProvider = user => {
    return !fp.pipe(
        fp.get('relationships.groups.data'),
        fp.filter(data => /^practice:(.*)\/provider/.test(data.id)),
        fp.isEmpty,
    )(user);
};

export default function UserProfile({user}) {
    const classes = useStyles();
    const isProvider = useMemo(() => userIsProvider(user), [user]);
    return (
        <>
            <Accordion className={classes.accordion}>
                <AccordionSummary>
                    <h6>Basic Profile</h6>
                    <small>Name, email, phone, and login credentials</small>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.accordionContent}>
                        <UserProfileCard />
                        <CredentialsCard />
                        <EmailsCard />
                        <TelecomCard />
                        <SignatureCard />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary>
                    <h6>Unit Display</h6>
                    <small>Display metric or imperial units by default</small>
                </AccordionSummary>
                <AccordionDetails>
                    <UserPreferencesCard />
                </AccordionDetails>
            </Accordion>
            {isProvider && (
                <Accordion className={classes.accordion}>
                    <AccordionSummary>
                        <h6>Provider Schedule</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProviderSchedule provider={user} />
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
}
