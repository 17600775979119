import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import {useNutrition} from 'ccm/dataSource';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

const statusLookup = {
    active: 'Active',
    'on hold': 'On Hold',
};

function NutritionDetail({artifact}) {
    const oralDiet = fp.get('attributes', artifact);
    const fluidConsistency = fp.get('fluidConsistencyType', oralDiet);
    const additionalDirections = fp.get('additionalDirections', oralDiet);
    const texture = fp.get('texture', oralDiet);
    return (
        <div>
            <h6>Details</h6>
            {fluidConsistency ? (
                <p>Fluid consistency: {fluidConsistency}</p>
            ) : null}
            {texture ? <p>Texture: {texture}</p> : null}
            {additionalDirections ? <p>{additionalDirections}</p> : null}
        </div>
    );
}

export default function NutritionTable({patient, title, ...props}) {
    const ds = useNutrition(patient);
    const refresh = () => ds.fetch();
    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column title='Type' field='attributes.type' />
            <dt2.Column
                title='Status'
                field='attributes.status'
                lookup={statusLookup}
            />
            <dt2.Column
                title='Order Date'
                field='attributes.orderDateTime'
                render={row => (
                    <DateTime
                        value={fp.get('attributes.orderDateTime', row.data)}
                    />
                )}
            />
            <dt2.Column
                title='Start'
                field='attributes.startDateTime'
                render={row => (
                    <DateTime
                        value={fp.get(
                            'attributes.startDateTime',
                            row.data,
                        )}
                    />
                )}
            />
            <dt2.Column
                title='End'
                field='attributes.endDateTime'
                render={row => (
                    <DateTime
                        value={fp.get(
                            'attributes.endDateTime',
                            row.data,
                        )}
                    />
                )}
            />
            <dt2.Column title='Ordered By' field='attributes.orderedBy' />
            <dt2.DetailPanel
                render={row => {
                    return <NutritionDetail artifact={row.data} />;
                }}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
