import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';

import {Card, CardHeader, Chip, makeStyles} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import {ducks} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import * as h from 'ccm/lib/helpers';
import {TextField} from 'ccm/components/editable';
import PatientAvatar from 'ccm/components/PatientAvatar';
import PatientContextMenu from 'ccm/components/PatientContextMenu';

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.cf.blue + '0c',
    },
    infoButton: {
        width: '100%',
    },
    truncated: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divider: {
        margin: theme.spacing(1),
    },
    primarySecondary: {
        margin: theme.spacing(1),
        '& .MuiChip-root': {
            margin: theme.spacing(1),
        },
    },
}));

const selectSecondaryPatients = patient =>
    createSelector(
        ducks.jsonapi.selectObject('Patient'),
        fp.pipe([
            fp.filter(
                pt =>
                    fp.get('relationships.primary_patient.data.id', pt) ===
                    patient.id,
            ),
            fp.filter(pt =>
                fp.includes('Common.READ', fp.get('meta.allowed_actions', pt)),
            ),
        ]),
    );

const selectPrimaryPatient = patient =>
    createSelector(
        ducks.jsonapi.selectObject([
            'Patient',
            fp.get('relationships.primary_patient.data.id', patient),
        ]),
        ppt => {
            if (
                fp.includes('Common.READ', fp.get('meta.allowed_actions', ppt))
            ) {
                return ppt;
            } else {
                return null;
            }
        },
    );

function PatientRoom({patient}) {
    return (
        <>
            <strong>Room:</strong>{' '}
            <TextField resource={patient} field='attributes.room' />
        </>
    );
}

export default function PatientSummary({patient}) {
    const classes = useStyles();
    const primaryPatient = useSelector(selectPrimaryPatient(patient));
    const secondaryPatients = useSelector(selectSecondaryPatients(patient));
    const history = useHistory();
    if (!patient) return null;
    const name = h.humanName(patient.attributes);
    const age = h.humanAge(patient.attributes);

    const gotoPatient = pt => {
        history.push(url_for('patient', {patientId: pt.id}));
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={<PatientAvatar patient={patient} />}
                title={<h6>{name}</h6>}
                subheader={
                    <span>
                        Age: <strong>{age}</strong>&nbsp; Gender:{' '}
                        <strong>{fp.get('attributes.gender', patient)}</strong>
                        <br />
                        <PatientRoom patient={patient} />
                        {patient.attributes.sync_status === 'SYNCED' ? null : (
                            <Alert severity='warning'>
                                This data may not be up-to-date. Refreshing the
                                page may help. The displayed data has a status
                                of {patient.attributes.sync_status}
                            </Alert>
                        )}
                    </span>
                }
                action={<PatientContextMenu patient={patient} />}
            />
            {primaryPatient ? (
                <div className={classes.primarySecondary}>
                    <p>This patient is linked to the (primary) chart: </p>
                    <Chip
                        clickable
                        label={fp.get(
                            'attributes.name.formatted',
                            primaryPatient,
                        )}
                        onClick={ev => gotoPatient(primaryPatient)}
                    />
                </div>
            ) : null}
            {!fp.isEmpty(secondaryPatients) ? (
                <div className={classes.primarySecondary}>
                    <p>
                        Additional information may be found on the following
                        linked (secondary) chart(s):
                    </p>
                    {fp.map(
                        pt => (
                            <Chip
                                clickable
                                label={fp.get('attributes.name.formatted', pt)}
                                onClick={ev => gotoPatient(pt)}
                            />
                        ),
                        secondaryPatients,
                    )}
                </div>
            ) : null}
        </Card>
    );
}
