import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';

import TicketSplitter from 'ccm/components/TicketSplitter';

export default function TicketSplitDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [newTickets, setNewTickets] = useState([]);

    const handleSubmit = async () => {
        resolve({action: 'split', newTickets});
        setResolve(null);
        setTicket(null);
    };

    const handleCancel = async () => {
        resolve({action: 'cancel'});
        setResolve(null);
        setTicket(null);
    };

    useEffect(() => {
        setNewTickets([]);
    }, [resolve]);

    dialogRef.current = ticket =>
        new Promise((resolve, reject) => {
            setTicket(ticket);
            setResolve(() => resolve);
        });

    return (
        <>
            <Dialog
                open={!!resolve}
                onClose={handleCancel}
                scroll='paper'
                fullScreen
                disableEnforceFocus={true}
            >
                <DialogTitle>Split Ticket</DialogTitle>

                <DialogContent dividers>
                    <DialogContentText>
                        <p>
                            Create new follow-up tickets to this ticket and
                            dragging the attachments to the new tickets being
                            created.
                        </p>
                        <p>
                            The newly-created tickets will be identical to this
                            ticket in all respects except for their attachments.
                        </p>
                    </DialogContentText>
                    <TicketSplitter
                        ticket={ticket}
                        newTickets={newTickets}
                        setNewTickets={setNewTickets}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        variant='contained'
                        color='primary'
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
