import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField as MUITextField,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {Autocomplete} from 'formik-material-ui-lab';

import ConsentFormAlert from 'ccm/components/ConsentFormAlert';

import {useApi, ducks} from '@arborian/narrf';

const DEFAULT_OPTIONS = {
    title: 'Invitation',
    submitLabel: 'Invite User',
    initialValues: {
        email: '',
        roles: [],
    },
};

export default function InvitationDialog({dialogRef}) {
    const api = useApi();
    const [resolve, setResolve] = useState(null);
    const [protectedResource, setProtectedResource] = useState(null);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);
    const [checked, setChecked] = useState(false);
    const [practiceLinkage, setPracticeLinkage] = useState();

    useEffect(() => {
        if (practiceLinkage) {
            const url = api.url_for('practice.Practice', {
                practice_id: practiceLinkage.id,
            });
            api.fetchJson(url);
        }
    }, [practiceLinkage, api]);

    const practice = useSelector(
        ducks.jsonapi.selectObject(['Practice', fp.get('id', practiceLinkage)]),
    );

    const handleChange = ev => {
        setChecked(ev.target.checked);
    };

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (protectedResource, options = {}) => {
        return new Promise((resolve, reject) => {
            setProtectedResource(protectedResource);
            setOptions(fp.merge(DEFAULT_OPTIONS, options));
            setResolve(() => resolve);
            if (protectedResource.type === 'Practice') {
                const {type, id} = protectedResource;
                setPracticeLinkage({type, id});
            } else if (protectedResource.type === 'Patient') {
                setPracticeLinkage(
                    fp.get('relationships.practice.data', protectedResource),
                );
            } else {
                setPracticeLinkage(null);
            }
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={options.initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <Field
                                component={TextField}
                                label="User's email"
                                name='email'
                                fullWidth
                            />
                            <Field
                                component={Autocomplete}
                                multiple
                                name='roles'
                                fullWidth
                                options={protectedResource.meta.roles}
                                getOptionLabel={o => o.label}
                                renderInput={props => (
                                    <MUITextField
                                        {...props}
                                        label="User's roles"
                                    />
                                )}
                            />
                            <br />
                            <ConsentFormAlert
                                practices={[practice]}
                                value={checked}
                                onChange={handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                disabled={!checked}
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
