import React from 'react';
import fp from 'lodash/fp';
import {List, ListItem, makeStyles} from '@material-ui/core';

import {randomString} from 'ccm/lib/util';
import * as f from './signatureFields';

const useStyles = makeStyles(theme => ({
    toolImg: {
        height: theme.spacing(8),
    },
}));

function ToolItem({toolType, imgSrc}) {
    const classes = useStyles();
    const handleDragStart = ev => {
        const targetRect = ev.target.getBoundingClientRect();
        const offset = {
            x: (ev.clientX - targetRect.left) / targetRect.width,
            y: (ev.clientY - targetRect.top) / targetRect.height,
        };
        console.log('DragStart', {target: ev.target, targetRect, offset});

        const field = {
            id: randomString(),
            type: toolType,
            offset,
        };
        const fieldJson = JSON.stringify(field);
        ev.dataTransfer.dropEffect = 'copy';
        ev.dataTransfer.clearData();
        ev.dataTransfer.setData('application/json', fieldJson);
        ev.nativeEvent.dataTransfer.setData('application/json', fieldJson);
    };
    return (
        <ListItem draggable={true} onDragStart={handleDragStart}>
            <img alt={toolType} src={imgSrc} className={classes.toolImg} />
        </ListItem>
    );
}

export default function ToolsDrawer() {
    const classes = useStyles();

    return (
        <List classes={classes.tools}>
            {fp.pipe([
                fp.toPairs,
                fp.map(([key, FieldClass]) => (
                    <ToolItem
                        key={key}
                        toolType={key}
                        imgSrc={FieldClass.imgSrc}
                    />
                )),
            ])(f.TOOLS)}
        </List>
    );
}
