import React from 'react';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {Button} from '@material-ui/core';

import {useListField} from 'ccm/dataSource';
import CodeableConceptTable from 'ccm/components/CodeableConceptTable';
import {TextFieldList} from 'ccm/components/formikFields';

const CCT_OPTIONS = {
    paging: false,
    filtering: false,
};

function CodeableConceptTableFieldShim({field, form, ...props}) {
    const handleChange = async values => {
        form.setFieldValue(field.name, values);
    };
    const codes = useListField(field.value, handleChange);
    return <CodeableConceptTable dataSource={codes} options={CCT_OPTIONS} />;
}

function CodeField({name, field, form, ...props}) {
    return (
        <div>
            <Field
                id={`codeTextField${name}`}
                component={TextField}
                name={`${name}.text`}
                label='Code text'
                fullWidth
            />
            <Field
                id={`codingField${name}`}
                component={CodeableConceptTableFieldShim}
                name={`${name}.coding`}
                title='Coding'
                size='small'
            />
        </div>
    );
}

export default function CarePlanTemplateForm({value, onSubmit}) {
    const handleSubmit = values => {
        console.log('Submit careplan template', values);
        onSubmit(values);
    };
    return (
        <Formik
            initialValues={value}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {({touched, values, errors}) => (
                <Form>
                    <Field
                        id='descriptionField'
                        component={TextField}
                        label='Description'
                        name='attributes.description'
                        fullWidth
                        InputLabelProps={{shrink: true}}
                    />
                    <h6>Goals</h6>
                    <TextFieldList
                        id='goalsList'
                        name='attributes.goals'
                        addText='New goal'
                        emptyText='No goals defined'
                    />
                    <h6>Interventions</h6>
                    <TextFieldList
                        id='interventionsList'
                        name='attributes.interventions'
                        addText='New intervention'
                        emptyText='No interventions defined'
                    />
                    <h6>Coding</h6>
                    <p>
                        The codes below are used to match patient diagnoses
                        (conditions) with this care plan template
                    </p>
                    <CodeField name='attributes.code' />
                    {/*
                    <Field
                        component={CodeField}
                        label='Applicable Codes'
                        name='attributes.code'
                    />
                    */}
                    <Button
                        id='savePlanTemplateBtn'
                        type='submit'
                        variant='contained'
                        color='primary'
                    >
                        Save Plan Template
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
