import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useSnackbar} from 'notistack';

import {components, useApi} from '@arborian/narrf';

import {
    useAllUpcomingVisits,
    useFacilityUpcomingVisits,
    usePracticeUpcomingVisits,
    useProviderUpcomingVisits,
    usePatientUpcomingVisits,
} from 'ccm/dataSource';

import {
    FacilityColumn,
    PracticeColumn,
    ProviderColumn,
} from 'ccm/components/columns';
import {url_for} from 'ccm/routes';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {useActions} from 'ccm/lib/hooks';
import * as h from 'ccm/lib/helpers';

import {useDialog} from 'ccm/components/dialogs';
import MessageCompositionDialog from 'ccm/components/dialogs/MessageCompositionDialog';
import PatientSelectDialog from 'ccm/components/dialogs/PatientSelectDialog';
import VisitTypeChip from 'ccm/components/PracticeManagement/scheduling/VisitTypeAdmin/VisitTypeChip';

const {dt2} = components;

const VISIT_OPTIONS = {
    include: ['patient', 'practice', 'facility'],
};

const VISIT_TABLE_OPTIONS = {
    filtering: true,
    search: true,
};

function PatientColumns() {
    return (
        <>
            <dt2.Column
                id='col-pt-first'
                title='Patient First'
                field='attributes.patient_first'
            />
            <dt2.Column
                id='col-pt-last'
                title='Patient Last'
                field='attributes.patient_last'
            />
            <dt2.Column
                id='col-pt-dob'
                title='Patient DOB'
                field='attributes.patient_dob'
                type='date'
            />
            <dt2.Column
                id='col-pt-room'
                title='Patient Room'
                field='attributes.patient_room'
            />
        </>
    );
}

export function VisitsTable({dataSource, children, dsRef, ...props}) {
    if (dsRef) {
        console.log('VisitsTable', dsRef);
        dsRef.current = dataSource;
    }
    const options = fp.get('options', props) || VISIT_TABLE_OPTIONS;
    return (
        <dt2.DataTable
            id='table-visits'
            title='Upcoming visits'
            size='small'
            dataSource={dataSource}
            options={options}
            {...props}
        >
            <dt2.Column
                id='col-visit-date'
                title='Visit Date'
                field='attributes.visit_date'
                type='date'
                filterProps={{timeZone: 'UTC'}}
                render={row =>
                    h.formatDateOnly(fp.get('data.attributes.visit_date', row))
                }
            />
            <dt2.Column
                id='col-visit-type'
                title='Type'
                field='attributes.visit_type.name'
                filtering={false}
                sorting={false}
                render={row => (
                    <VisitTypeChip attrs={row.data.attributes.visit_type} />
                )}
            />
            {children}
            <dt2.Column
                id='col-visit-reason'
                title='Reason'
                field='attributes.reason'
            />
            <dt2.Action
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}

export function PracticeUpcomingVisitsTable({practice, children, ...props}) {
    const api = useApi();
    const ds = usePracticeUpcomingVisits(practice, {options: VISIT_OPTIONS});
    const messageDialog = useDialog(MessageCompositionDialog);
    const {enqueueSnackbar} = useSnackbar();

    const getPatientMsgLinks = visits => {
        let patientMsgLinks = fp.map(
            'relationships.patient_messages.links.related',
            visits,
        );
        // De-duplicate in case patients have multiple visits.
        return fp.uniq(patientMsgLinks);
    };

    const handleDelete = async (ev, rows) => {
        const promises = fp.map(
            row =>
                api.fetch(fp.get('data.links.self', row), {method: 'DELETE'}),
            rows,
        );
        await Promise.all(promises);
        ds.fetch();
    };

    const handleCompose = async (ev, rows) => {
        console.log({rows});
        const ref = props.dsRef.current;
        const msg = await messageDialog({
            count: rows.length,
            total: ref.allRowsSelected() ? ref.state.count : rows.length,
        });
        if (!msg) return;

        const {includeAll, ...attributes} = msg;
        console.log({attributes});
        const payload = {data: {type: 'Message', attributes}};
        let patientMsgLinks = [];

        if (includeAll) {
            // Allow admins to send message to all patients with upcoming visits
            const fetchOptions = fp.pipe([
                api.jsonApiFromDataTableOptions,
                fp.omit(['page', 'sort']),
            ])(props.dsRef.current.fetchOptions);
            let allVisits = await api.fetchAllJsonApi(
                api.url_for('visit.VisitCollection'),
                fetchOptions,
            );
            patientMsgLinks = getPatientMsgLinks(allVisits);
        } else {
            patientMsgLinks = getPatientMsgLinks(fp.map('data', rows));
        }

        await Promise.all(
            fp.map(url => {
                return api.fetchJson(url, {
                    method: 'POST',
                    json: payload,
                });
            }, patientMsgLinks),
        ).then(result => {
            enqueueSnackbar(`${result.length} message(s) sent`, {
                persist: false,
                variant: 'success',
            });
        });
    };

    return (
        <VisitsTable
            options={{
                filtering: true,
                search: true,
                selection: true,
            }}
            dataSource={ds}
            tableRef={props.dsRef}
            {...props}
        >
            <FacilityColumn />
            <ProviderColumn />
            <PatientColumns />
            <dt2.Action
                key='delete-action'
                name='delete'
                icon='delete'
                tooltip='Delete visit(s)'
                onClick={handleDelete}
            />
            <dt2.Action
                key='email-action'
                name='message'
                icon='email'
                tooltip='Send message to selected patients'
                onClick={handleCompose}
            />
            {children}
        </VisitsTable>
    );
}

export function FacilityUpcomingVisitsTable({facility, children, ...props}) {
    const ds = useFacilityUpcomingVisits(facility, {options: VISIT_OPTIONS});
    const api = useApi();
    const actions = useActions();
    const patientSelect = useDialog(PatientSelectDialog);

    const createScheduleQuestionTicket = async (evt, row) => {
        const patientId = fp.get('data.relationships.patient.data.id', row);
        if (patientId) {
            const patient = await api.fetchJsonApi(
                api.url_for('patient.Patient', {patient_id: patientId}),
            );
            actions.createSchedulingQuestionTicket(patient.data, row);
        } else {
            const {action, value} = await patientSelect();
            console.log({action, value});
            if (action === 'cancel') return;
            actions.createSchedulingQuestionTicket(value, row);
        }
    };

    return (
        <VisitsTable dataSource={ds} {...props}>
            <PracticeColumn />
            <ProviderColumn />
            <PatientColumns />
            {children}
            <dt2.Action
                id='no-patient-schedule-question'
                free
                icon='transfer_within_a_station'
                tooltip='Request an additional visit'
                onClick={createScheduleQuestionTicket}
            />
            <dt2.Action
                id='patient-schedule-question'
                icon='mail'
                tooltip='Submit a question about this visit'
                onClick={createScheduleQuestionTicket}
            />
        </VisitsTable>
    );
}

export function ProviderUpcomingVisitsTable({provider, children, ...props}) {
    const ds = useProviderUpcomingVisits(provider, {options: VISIT_OPTIONS});
    const user = useSelector(selectCurrentUser);
    const openSchedule = ev => {
        window.open(url_for('providerSchedule', {providerId: user.id}));
    };
    return (
        <VisitsTable dataSource={ds} {...props}>
            <FacilityColumn />
            <PatientColumns />
            {children}
            <dt2.Action
                id='action-schedule'
                free
                icon='printer'
                tooltip='Open a printable view'
                onClick={openSchedule}
            />
        </VisitsTable>
    );
}

export function PatientUpcomingVisitsTable({patient, children, ...props}) {
    const ds = usePatientUpcomingVisits(patient, {options: VISIT_OPTIONS});
    return (
        <VisitsTable dataSource={ds} {...props}>
            <PracticeColumn />
            <ProviderColumn />
            {children}
        </VisitsTable>
    );
}

export default function AdminVisitsTable({children, ...props}) {
    const ds = useAllUpcomingVisits({options: VISIT_OPTIONS});

    return (
        <VisitsTable dataSource={ds} {...props}>
            <FacilityColumn />
            <PracticeColumn />
            <ProviderColumn />
            <PatientColumns />
            {children}
        </VisitsTable>
    );
}
