import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {useApi, ducks} from '@arborian/narrf';
import {components} from '@arborian/narrf';

import AutosuggestFetch from 'ccm/components/AutosuggestFetch';

import {useCodeRange} from 'ccm/dataSource';

const {dt2} = components;

function CodeViewComponent({row}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(row.data));
    if (!rel.code) return null;
    return (
        <>
            {rel.code.attributes.systemid} {rel.code.attributes.value}
            <br />
            <small>{rel.code.attributes.description}</small>
        </>
    );
}

function CodeSelectorComponent({value, error, onChange, column}) {
    const api = useApi();
    const realValue = useSelector(
        ducks.jsonapi.selectObject([value.type, value.id]),
    );

    const handleChangeCode = (ev, value, reason) => {
        if (reason === 'select-option') {
            onChange(fp.pick(['id', 'type'], value));
        }
    };

    const href = api.url_for('coding.Collection', {systemid: 'LOINC'});

    const getOptionSelected = (option, value) => {
        return option.type === value.type && option.id === value.id;
    };

    return (
        <AutosuggestFetch
            href={href}
            onChange={handleChangeCode}
            value={realValue}
            getOptionLabel={code => `${code.id} ${code.attributes.description}`}
            getOptionSelected={getOptionSelected}
        />
    );
}

const CODE_RANGE_OPTIONS = {
    include: ['code'],
};

export default function LabResultsRangeConfig({practice}) {
    const ds = useCodeRange(practice, {options: CODE_RANGE_OPTIONS});
    const refresh = () => ds.fetch();

    const onRowAdd = row => {
        const codeLinkage = fp.pipe([
            fp.get('data.relationships.code.data'),
            fp.pick(['id', 'type']),
        ])(row);
        const newRow = fp.set('data.relationships.code.data', codeLinkage, row);
        return ds.handleAdd(newRow);
    };

    const onRowUpdate = row => {
        const codeLinkage = fp.pipe([
            fp.get('data.relationships.code.data'),
            fp.pick(['id', 'type']),
        ])(row);
        const newRow = fp.set('data.relationships.code.data', codeLinkage, row);
        return ds.handleUpdate(newRow);
    };

    return (
        <div>
            <dt2.DataTable
                title='Lab results value ranges'
                dataSource={ds}
                editable={{
                    onRowAdd,
                    onRowUpdate,
                    onRowDelete: ds.handleDelete,
                }}
                options={{
                    filtering: false,
                    selection: false,
                    search: false,
                }}
            >
                <dt2.Column
                    title='Code'
                    field='relationships.code.data'
                    filtering={false}
                    component={CodeViewComponent}
                    EditComponent={CodeSelectorComponent}
                />
                <dt2.Column
                    title='Min'
                    field='attributes.range_min'
                    filtering={false}
                />
                <dt2.Column
                    title='Max'
                    field='attributes.range_max'
                    filtering={false}
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </dt2.DataTable>
        </div>
    );
}
