import React, {Fragment, useState} from 'react';
import fp from 'lodash/fp';

import {
    Button,
    TableFooter,
    TableRow,
    TableCell,
    IconButton,
    Hidden,
    Menu,
    MenuItem,
    makeStyles,
} from '@material-ui/core';
import {
    ArrowDropDown,
    FirstPage,
    LastPage,
    ChevronLeft,
    ChevronRight,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    pagination: {
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        '& .MuiTablePagination-spacer': {
            flex: 1,
        },
        '& .MuiButton-root': {
            textTransform: 'none',
        },
        borderBottom: 'none',
    },
}));

function PageSizeSelect({value, options, onChange}) {
    const [anchorEl, setAnchorEl] = useState();
    const handleClick = e => {
        setAnchorEl();
        onChange(e.target.value);
    };
    return (
        <>
            <Button
                onClick={ev => setAnchorEl(ev.currentTarget)}
                endIcon={<ArrowDropDown />}
            >
                {value} rows
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {fp.map(
                    o => (
                        <MenuItem key={o} value={o} onClick={handleClick}>
                            {o}
                        </MenuItem>
                    ),
                    options,
                )}
            </Menu>
        </>
    );
}

export default function Pagination({
    count,
    page,
    pageSize,
    pageSizeOptions,
    onChangePage,
    onChangePageSize,
}) {
    const firstItem = 1 + page * pageSize;
    const lastItem = Math.min(firstItem + pageSize - 1, count);
    const lastPage = Math.max(0, Math.ceil(count / pageSize) - 1);

    const onFirst = () => onChangePage(0);
    const onLast = () => onChangePage(lastPage);
    const onNext = () => onChangePage(page + 1);
    const onPrev = () => onChangePage(page - 1);

    return (
        <div className='MuiTablePagination-root'>
            <PageSizeSelect
                value={pageSize}
                options={pageSizeOptions}
                onChange={onChangePageSize}
            />
            <Hidden smUp>
                <br />
            </Hidden>
            <IconButton onClick={onFirst} disabled={page === 0}>
                <FirstPage />
            </IconButton>
            <IconButton onClick={onPrev} disabled={page === 0}>
                <ChevronLeft />
            </IconButton>
            {firstItem}-{lastItem} of {count}
            <IconButton onClick={onNext} disabled={page >= lastPage}>
                <ChevronRight />
            </IconButton>
            <IconButton onClick={onLast} disabled={page >= lastPage}>
                <LastPage />
            </IconButton>
        </div>
    );
}
