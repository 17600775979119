import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import {useAllergies} from 'ccm/dataSource';

const {dt2} = components;

const clinicalStatusLookup = {
    ACTIVE: 'Active',
    RECURRENCE: 'Recurrence',
    RELAPSE: 'Relapse',
    INACTIVE: 'Inactive',
    REMISSION: 'Remission',
    RESOLVED: 'Resolved',
};

const severityLookup = {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
    Unknown: 'Unknown',
};

export default function AllergyTable({patient, title, ...props}) {
    const ds = useAllergies(patient);
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column
                title='Status'
                field='attributes.clinicalStatus.text'
                lookup={clinicalStatusLookup}
            />
            <dt2.Column
                title='Category'
                field='attributes.category'
                filtering={false}
            />
            <dt2.Column
                title='Severity'
                field='attributes.severity'
                lookup={severityLookup}
            />
            <dt2.Column title='Allergen' field='attributes.allergen' />
            <dt2.Column
                title='Onset'
                field='attributes.onsetDate'
                type='date'
            />
            <dt2.Column
                title='Resolved'
                field='attributes.resolvedDate'
                type='date'
            />
            <dt2.Column
                title='Reaction(s)'
                field='attributes.reactions'
                render={r => {
                    const reactions = fp.getOr(
                        [],
                        'attributes.reactions',
                        r.data,
                    );
                    console.log({reactions});
                    return reactions.join(', ');
                }}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
