import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    ColCentered: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    StepContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ThumbnailContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    Thumbnail: {
        maxWidth: 120,
    },
    ThumbnailPlaceholder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 120,
        height: 160,
    },
    OrdersContainer: {
        whiteSpace: 'pre',
    },
    disabled: {
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        opacity: 0.75,
        zIndex: 9999998,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999998,
        '& .MuiAlert-root': {
            padding: theme.spacing(3),
        },
    },
    ResolveStep: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& .MuiCard-root': {
            margin: theme.spacing(1, 0),
        },
    },
}));
