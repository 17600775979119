import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';
import {createSelector} from '@reduxjs/toolkit';
import {selectPatientArtifacts} from 'ccm/lib/selectors';

export const selectFacilityLookup = createSelector(
    ducks.jsonapi.selectObject('Facility'),
    fp.pipe([
        fp.values,
        fp.map(o => [o.id, o.attributes.name]),
        fp.concat([[null, 'No Facility']]),
        fp.fromPairs,
    ]),
);

export const selectPracticeLookup = createSelector(
    ducks.jsonapi.selectObject('Practice'),
    fp.pipe([
        fp.values,
        fp.map(o => [o.id, o.attributes.name]),
        fp.concat([[null, 'No Practice']]),
        fp.fromPairs,
    ]),
);

export const selectPracticePairs = createSelector(
    ducks.jsonapi.selectObject('Practice'),
    fp.pipe([
        fp.values,
        fp.map(o => [o.id, o.attributes.name]),
        fp.concat([[null, 'No Practice']]),
    ]),
);

export const selectCurrentUser = createSelector(
    ducks.auth.selectUserinfo,
    ducks.jsonapi.selectObject('User'),
    (ui, users) => {
        return fp.pipe([
            fp.values,
            fp.filter(u => ui && fp.get('attributes.sub', u) === ui.sub),
            fp.first,
        ])(users);
    },
);

export const selectPortalConfig = object => state => {
    const linkage = fp.get('relationships.ehr_connection.data', object);
    if (!linkage) return null;
    const ehrConn = ducks.jsonapi.selectObject([linkage.type, linkage.id])(
        state,
    );
    return ehrConn ? ehrConn.attributes.portal_config : null;
};

export const selectDischargeDate = patientId =>
    createSelector(
        selectPatientArtifacts(patientId, 'DischargeDate'),
        fp.first,
    );

// return list of patient and secondaries
export const selectTicketPatients = ticket_or_tickets =>
    createSelector(ducks.jsonapi.selectObject('Patient'), patients => {
        const tickets = fp.isArray(ticket_or_tickets)
            ? ticket_or_tickets
            : [ticket_or_tickets];
        const ticket_patient_ids = fp.uniq(
            fp.map('relationships.patient.data.id', tickets),
        );
        const result = fp.filter(pt => {
            const primary_patient_id = fp.get(
                'relationships.primary_patient.data.id',
                pt,
            );
            return (
                fp.find(id => id === pt.id, ticket_patient_ids) ||
                fp.find(id => id === primary_patient_id, ticket_patient_ids)
            );
        }, patients);
        return result;
    });

export const selectEHRConnections = ticket_or_tickets =>
    createSelector(
        selectTicketPatients(ticket_or_tickets),
        ducks.jsonapi.selectObject('EHRConnection'),
        (patients, connections) => {
            const tickets = fp.isArray(ticket_or_tickets)
                ? ticket_or_tickets
                : [ticket_or_tickets];
            if (!connections) return [];

            // Start with any of the ticket(s) practice's EHRs
            const ticket_practice_ids = fp.uniq(
                fp.map('relationships.practice.data.id', tickets),
            );
            const practice_ehr_ids = fp.pipe([
                fp.filter(conn => {
                    const conn_practice_id = fp.get(
                        'relationships.practice.data.id',
                        conn,
                    );
                    if (!conn_practice_id) return false;
                    const found = fp.find(
                        id => id === conn_practice_id,
                        ticket_practice_ids,
                    );
                    return !!found;
                }),
                fp.map('id'),
            ])(connections);

            // Now add any patients' ehr connections
            const patient_ehr_ids = fp.pipe([
                fp.map('relationships.ehr_connection.data.id'),
                fp.filter(fp.identity),
            ])(patients);

            const result = fp.pipe([
                fp.map(id => connections[id]),
                fp.filter(fp.identity),
                fp.uniq,
            ])([...practice_ehr_ids, ...patient_ehr_ids]);
            return result;
        },
    );

export const getFaxNumbers = object =>
    fp.pipe([
        fp.get('attributes.telecom'),
        fp.filter(cp => cp.system === 'fax'),
        fp.map(cp => ({...cp, name: object.attributes.name})),
    ])(object);

const formatFaxOptions = fp.pipe([
    fp.map(getFaxNumbers),
    fp.flatten,
    fp.sortBy('name'),
    fp.map(cp => `${cp.name}:${cp.value}`),
]);

export const selectTicketFaxOptions = ticket =>
    createSelector(
        ducks.jsonapi.selectObject('Contact'),
        ducks.jsonapi.selectObject('Facility'),
        ducks.jsonapi.selectObject('Practice'),
        (contacts, facilities, practices) => {
            if (!ticket) return [];
            const facilityId = fp.get('relationships.facility.data.id', ticket);
            const practiceId = fp.get('relationships.practice.data.id', ticket);
            const facility = fp.get(facilityId, facilities);
            const practice = fp.get(practiceId, practices);
            let result = fp.pipe([
                fp.filter(
                    contact =>
                        fp.get('relationships.facility.data.id', contact) ===
                            facilityId ||
                        fp.get('relationships.practice.data.id', contact) ===
                            practiceId,
                ),
                formatFaxOptions,
            ])(contacts);
            if (facility) {
                result = [...formatFaxOptions([facility]), ...result];
            }
            if (practice) {
                result = [...formatFaxOptions([practice]), ...result];
            }
            return result;
        },
    );

export const selectCreateTicketFaxOptions = facilityId =>
    createSelector(
        ducks.jsonapi.selectObject('Contact'),
        fp.pipe([
            fp.filter(
                contact =>
                    fp.get('relationships.facility.data.id', contact) ===
                    facilityId,
            ),
            formatFaxOptions,
        ]),
    );
