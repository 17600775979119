import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {Button} from '@material-ui/core';

import {useApi, ducks, components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {useRelated} from 'ccm/dataSource';

const {dt2} = components;

const DEFAULT_FETCH_OPTIONS = {
    page: {number: 0, size: 20},
    include: ['sp_patient'],
};

function PatientLink({patientId}) {
    const pt = useSelector(ducks.jsonapi.selectObject(['Patient', patientId]));
    if (!pt) return '--';
    return (
        <Link to={url_for('patient', {patientId})}>
            {fp.get('attributes.name.last', pt)},{' '}
            {fp.get('attributes.name.first', pt)}
        </Link>
    );
}

function PatientSearchForm({initialValues, onSubmit}) {
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => (
                <Form>
                    <h6>Patient search</h6>
                    <Field
                        component={TextField}
                        label='Patient first or last name'
                        name='firstOrLastName'
                        fullWidth
                        InputLableProps={{shrink: true}}
                    />
                    <br />
                    <br />
                    <div>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            Search
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default function PFOnboardPatients({ehr}) {
    const api = useApi();
    const config = useSelector(
        ducks.jsonapi.selectObject(['EHRConfig', ehr.id]),
    );
    const handleSubmit = async values => {
        let newFetchOptions = fp.set('page.number', 0, ds.fetchOptions);
        for (const [k, v] of Object.entries(values)) {
            newFetchOptions = fp.set(
                ['filter', `attributes.${k}`],
                v,
                newFetchOptions,
            );
        }
        ds.setFetchOptions(newFetchOptions);
    };
    const ds = useRelated(config, 'patients', {
        prefix: 'pt.',
        initialFetchOptions: DEFAULT_FETCH_OPTIONS,
        type: 'PFPatient',
    });
    const {fetch: dsFetch} = ds;
    useEffect(() => dsFetch(), [dsFetch]);
    const handleOnboard = item => {
        const url = fp.get('relationships.patients.links.related', config);
        return api.fetchJson(url, {
            method: 'POST',
            json: {
                data: {type: 'PFPatient', id: item.id},
            },
        });
    };
    const initialValues = fp.pipe([
        fp.get('fetchOptions.filter'),
        fp.toPairs,
        fp.map(([k, v]) => {
            const parts = k.split('.', 2);
            if (parts.length === 2) return [parts[1], v];
            else return null;
        }),
        fp.filter(x => !!x),
        fp.fromPairs,
    ])(ds);

    return (
        <div>
            <p>
                Here, you can search for PracticeFusion patients that are not
                yet in SamePage and onboard them.
            </p>
            <PatientSearchForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
            <dt2.DataTable
                title='PracticeFusion Patients'
                size='small'
                dataSource={ds}
            >
                <dt2.Column title='First' field='attributes.data.firstName' />
                <dt2.Column title='Last' field='attributes.data.lastName' />
                <dt2.Column
                    title='DOB'
                    field='attributes.data.birthDate'
                    type='date'
                />
                <dt2.Column
                    title='SamePage Patient'
                    render={item => (
                        <PatientLink
                            patientId={fp.get(
                                'data.relationships.sp_patient.data.id',
                                item,
                            )}
                        />
                    )}
                />
                <dt2.Action
                    name='onboard'
                    onClick={(ev, item) => handleOnboard(item)}
                    tooltip='Onboard Patient'
                    icon='add'
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={() => ds.fetch()}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </dt2.DataTable>
        </div>
    );
}
