import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Card,
    CardHeader,
    CardContent,
    Tooltip,
    makeStyles,
} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import useWebsocket from 'ccm/lib/websocket';
import {formatDateTime, formatShortDateTime} from 'ccm/lib/helpers';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiCard-root': {
            margin: theme.spacing(0, 0, 0.5, 0),
        },
        '& .MuiCardHeader-root': {
            padding: theme.spacing(1, 1, 0, 1),
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(0, 1, 1, 1),
        },
        '& .MuiCardHeader-subheader': {
            fontSize: 14,
        },
    },
}));

function Date({ts}) {
    return (
        <Tooltip title={formatDateTime(ts)} arrow>
            <span>{formatShortDateTime(ts)}</span>
        </Tooltip>
    );
}

const wsOptions = {};
export default function TaskLog({value}) {
    const tasklogid = fp.get('id', value);
    const classes = useStyles();
    useWebsocket(`/tasklog/${tasklogid}`, wsOptions);
    const tasklog = useSelector(
        ducks.jsonapi.selectObject(['TaskLog', tasklogid]),
    );
    if (!value) return null;
    const task_name = fp.get('attributes.task_name', tasklog) || '--';
    const name = task_name.split('.').slice(-1)[0];
    return (
        <div className={classes.root}>
            <h4>
                {name}: {tasklog.attributes.status}
            </h4>
            {fp.map(
                msg => (
                    <Card>
                        <CardHeader
                            key={msg.created}
                            subheader={<Date ts={msg.created} />}
                        />
                        <CardContent>{msg.message}</CardContent>
                    </Card>
                ),
                tasklog.attributes.messages,
            )}
        </div>
    );
}
