import React from 'react';
import fp from 'lodash/fp';

import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core';

import {useApi, components} from '@arborian/narrf';
import * as h from 'ccm/lib/helpers';
import PatientEHRComponent from 'ccm/components/PatientEHRComponent';

import {useRelated} from 'ccm/dataSource';

const {dt2} = components;
const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
}));

export default function SecondaryPatientsTable({patient}) {
    const api = useApi();
    const classes = useStyles();
    const ds = useRelated(patient, 'secondary_patients', 'pt-secondary.');

    const handleUnmap = async (ev, row) => {
        const url = fp.get(
            'relationships.primary_patient.links.self',
            row.data,
        );

        await api.fetchJson(url, {
            method: 'PATCH',
            json: {data: null},
        });
        ds.fetch();
    };
    console.log('datasource', ds);

    return (
        <>
            {fp.isEmpty(ds.items) ? (
                <Alert severity='info'>
                    There are no patients mapped to this primary patient
                </Alert>
            ) : null}
            <div className={fp.isEmpty(ds.items) ? classes.hidden : null}>
                <dt2.DataTable
                    dataSource={ds}
                    options={{
                        search: false,
                        filtering: false,
                        paging: false,
                    }}
                >
                    <dt2.Column title='Last' field='attributes.name.last' />
                    <dt2.Column title='First' field='attributes.name.first' />
                    <dt2.Column
                        title='DOB'
                        field='attributes.dob'
                        type='date'
                        render={row =>
                            h.formatDateOnly(fp.get('data.attributes.dob', row))
                        }
                    />
                    <dt2.Column title='EHR' component={PatientEHRComponent} />
                    <dt2.Action
                        name='select'
                        onClick={handleUnmap}
                        tooltip='Unmap this patient'
                        icon='call_split'
                    />
                    <dt2.Action
                        free
                        onClick={() => ds.fetch()}
                        tooltip='Refresh'
                        icon='refresh'
                    />
                </dt2.DataTable>
            </div>
        </>
    );
}
