import React, {useEffect} from 'react';
import * as R from 'react-router-dom';
import {Link} from '@material-ui/core';

import {useApi} from '@arborian/narrf';
import {url_for, useQuery} from 'ccm/routes';
import BasePage from 'ccm/components/BasePage';

/* This page redirects users to an appropriate Aauth authorize link
 * (based on the plugin_id in the path)
 * e.g. /aauthRedirect/google or /aauthRedirect/pcc
 */

export default function AauthRedirectPage() {
    const api = useApi();
    const {state} = R.useLocation();
    const {providerId} = R.useParams();
    const {intent = 'login'} = useQuery();

    useEffect(() => {
        if (api) {
            const redirect_uri = new URL('/callback', window.location.href);
            const authorizeLink = api.authorizeLink({
                redirect_uri,
                provider_id: providerId,
                intent,
                state,
            });
            window.location = authorizeLink;
        }
    }, [providerId, intent, api, state]);

    return (
        <BasePage title='Redirecting you to the login page...'>
            <p>
                If you are not redirected within 10 seconds, please{' '}
                <Link component={R.Link} to={url_for('login')}>
                    click here to login
                </Link>{' '}
                or{' '}
                <Link component={R.Link} to={url_for('register')}>
                    click here to sign up
                </Link>{' '}
                if this is your first time using SamePage, MD.
            </p>
        </BasePage>
    );
}
