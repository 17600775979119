import fp from 'lodash/fp';
import {fabric} from 'fabric';

import * as h from 'ccm/lib/helpers';

export const fieldFromFieldData = (fieldData, options) => {
    const FieldClass = TOOLS[fieldData.type];
    const fld = new FieldClass(fieldData, options);
    return fld;
};

export class Field {
    static UNFILLED_BACKGROUND = 'lightyellow';

    constructor(data) {
        this.data = data;
    }

    get progressLabel() {
        return 'Next';
    }

    draw(canvas) {
        // override
    }

    click(ev) {
        // override
    }

    scaleElement(el) {
        // Proportionally scale element to barely fit in field box
        if (!this.data.coords.width) return el;
        const scaleX = this.data.coords.width / el.width;
        const scaleY = this.data.coords.height / el.height;
        const scale = Math.min(scaleX, scaleY);
        el.set({scaleX: scale, scaleY: scale});
        return el;
    }

    toJSON(el) {
        return {
            ...this.data,
            coords: {
                top: el.top,
                left: el.left,
                width: el.getScaledWidth(),
                height: el.getScaledHeight(),
            },
        };
    }
}

class SignatureField extends Field {
    static imgSrc = '/img/signatureBox.png';

    get progressLabel() {
        if (this.data.filled) {
            return 'Next';
        } else {
            return 'Sign';
        }
    }

    draw(canvas) {
        return new Promise((resolve, reject) => {
            if (this.data.filled) {
                fabric.Image.fromURL(
                    this.data.value,
                    el => {
                        el.set({
                            field: this,
                            left: this.data.coords.left,
                            top: this.data.coords.top,
                        });
                        this.scaleElement(el);
                        canvas.add(el);
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            } else {
                fabric.Image.fromURL(
                    SignatureField.imgSrc,
                    el => {
                        el.set({
                            field: this,
                            backgroundColor: Field.UNFILLED_BACKGROUND,
                            left: this.data.coords.left,
                            top: this.data.coords.top,
                        });
                        this.scaleElement(el);
                        canvas.add(el);
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev) {
        const el = ev.element;
        if (this.data.filled) {
            // "Unsign"
            this.data.filled = false;
            this.data.value = null;
        } else {
            this.data.filled = true;
            this.data.value = ev.canvas.formState.signatureUrl;
        }
        ev.canvas.remove(el);
        const newEl = await this.draw(ev.canvas);
        newEl.set({top: el.top, left: el.left});
        if (this.data.filled) {
            ev.canvas.formState.handleClickNext([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
        } else {
            ev.canvas.formState.setCurrent([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
            ev.canvas.setActiveObject(newEl);
        }
        this.scaleElement(newEl);
        return newEl;
    }
}

class DateField extends Field {
    static imgSrc = '/img/dateBox.png';

    get progressLabel() {
        if (this.data.filled) {
            return 'Next';
        } else {
            return 'Enter date';
        }
    }

    draw(canvas) {
        return new Promise((resolve, reject) => {
            if (this.data.filled) {
                var el = new fabric.Textbox(
                    this.data.value || 'NO DATE ENTERED',
                    {
                        field: this,
                        fontSize: 40,
                        editable: false,
                        left: this.data.coords.left,
                        top: this.data.coords.top,
                    },
                );
                el.setControlsVisibility({
                    mt: false,
                    mb: false,
                });
                this.scaleElement(el);
                canvas.add(el);
                resolve(el);
            } else {
                fabric.Image.fromURL(
                    DateField.imgSrc,
                    el => {
                        el.set({
                            field: this,
                            backgroundColor: Field.UNFILLED_BACKGROUND,
                            left: this.data.coords.left,
                            top: this.data.coords.top,
                        });
                        this.scaleElement(el);
                        canvas.add(el);
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev) {
        const el = ev.element;
        if (this.data.filled) {
            // "Unsign"
            this.data.filled = false;
            this.data.value = null;
        } else {
            this.data.filled = true;
            this.data.value = h.formatToday();
        }
        ev.canvas.remove(el);
        const newEl = await this.draw(ev.canvas);
        newEl.set({top: el.top, left: el.left});
        if (this.data.filled) {
            ev.canvas.formState.handleClickNext([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
        } else {
            ev.canvas.formState.setCurrent([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
            ev.canvas.setActiveObject(newEl);
        }
        return this.scaleElement(newEl);
    }
}

class TextboxField extends Field {
    static imgSrc = '/img/textBox.png';

    constructor(data) {
        super(
            fp.merge(
                {
                    value: 'ENTER TEXT',
                },
                data,
            ),
        );
    }

    get progressLabel() {
        if (this.data.filled) {
            return 'Next';
        } else {
            return 'Enter text';
        }
    }

    draw(canvas) {
        return new Promise((resolve, reject) => {
            if (this.data.filled) {
                var el = new fabric.Textbox(
                    this.data.value || 'NO TEXT ENTERED',
                    {
                        field: this,
                        top: this.data.coords.top,
                        left: this.data.coords.left,
                        width: this.data.coords.width,
                        fontSize: 40,
                        editable: true,
                    },
                );
                el.setControlsVisibility({
                    mt: false,
                    mb: false,
                });
                el.on('editing:exited', () => {
                    this.handleEditingExited(el, canvas, canvas.formState);
                });
                canvas.add(el);
                resolve(el);
            } else {
                fabric.Image.fromURL(
                    TextboxField.imgSrc,
                    el => {
                        el.set({
                            field: this,
                            backgroundColor: Field.UNFILLED_BACKGROUND,
                            left: this.data.coords.left,
                            top: this.data.coords.top,
                        });
                        this.scaleElement(el);
                        canvas.add(el);
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev) {
        if (this.data.filled) {
            ev.canvas.formState.setCurrent([
                ev.canvas.pageState.pageNumber,
                ev.element,
            ]);
            return ev.element;
        }
        this.data.filled = true;
        ev.canvas.remove(ev.element);
        const newEl = await this.draw(ev.canvas);
        newEl.set({top: newEl.top, left: newEl.left});
        newEl.enterEditing();
        newEl.selectAll();
        ev.canvas.formState.setCurrent([ev.canvas.pageState.pageNumber, newEl]);
        ev.canvas.setActiveObject(newEl);
        return newEl;
    }

    toJSON(el) {
        const result = super.toJSON(el);
        return fp.set('value', el.text, result);
    }

    handleEditingExited(el, canvas, formState) {
        if (el.text.trim() === '') {
            this.data.filled = false;
            this.data.value = null;
            canvas.remove(el);
            this.draw(canvas);
        } else {
            formState.checkComplete();
        }
    }
}

class CheckboxField extends Field {
    static imgSrc = '/img/checkBox.png';

    constructor(data) {
        super(
            fp.merge(
                {
                    coords: {
                        width: 30,
                        height: 30,
                    },
                },
                data,
            ),
        );
    }

    get progressLabel() {
        if (this.data.filled) {
            if (this.data.value === 'true') {
                return 'Un-check';
            } else {
                return 'Next';
            }
        } else {
            return 'Check';
        }
    }

    draw(canvas) {
        return new Promise((resolve, reject) => {
            const box = new fabric.Rect({
                fill: null,
                stroke: 'black',
                strokeWidth: 3,
                field: this,
                ...this.data.coords,
            });
            box.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
            });

            if (this.data.filled && this.data.value === 'true') {
                const check = new fabric.Path(
                    'M 125 150 L 150 175 L 200 125 L 150 175 z',
                    {
                        field: this,
                        strokeWidth: 20,
                        stroke: 'black',
                        top: this.data.coords.top,
                        left: this.data.coords.left,
                    },
                );
                const scale =
                    (0.9 * box.getScaledHeight()) / check.getScaledHeight();
                check.set({
                    scaleX: scale,
                    scaleY: scale,
                });
                // check.scale(0.4);
                // this.scaleElement(check, 1.0);
                const grp = new fabric.Group([box, check], {field: this});
                canvas.add(grp);
                resolve(grp);
            } else {
                if (!this.data.filled) {
                    box.set({backgroundColor: Field.UNFILLED_BACKGROUND});
                }
                canvas.add(box);
                resolve(box);
            }
        });
    }

    async click(ev) {
        // UNSET => TRUE => FALSE => UNSET
        const el = ev.element;
        if (!this.data.filled) {
            this.data.filled = true;
            this.data.value = 'true';
        } else if (this.data.value === 'true') {
            this.data.value = 'false';
        } else {
            this.data.filled = false;
            this.data.value = null;
        }
        el.canvas.remove(el);
        const newEl = await this.draw(ev.canvas);
        newEl.set({top: el.top, left: el.left});
        if (this.data.filled) {
            ev.canvas.formState.handleClickNext([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
        } else {
            ev.canvas.formState.setCurrent([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
            ev.canvas.setActiveObject(newEl);
        }
        return newEl;
    }
}

class OrdersField extends Field {
    static imgSrc = '/img/ordersBox.png';

    constructor(data, options) {
        super(
            fp.merge(
                {
                    value: 'ENTER ORDERS',
                },
                data,
            ),
        );
        this.ordersDialog = options.ordersDialog;
    }

    get progressLabel() {
        if (this.data.filled) {
            return 'Next';
        } else {
            return 'Enter orders';
        }
    }

    draw(canvas) {
        return new Promise((resolve, reject) => {
            if (this.data.filled) {
                var el = new fabric.Textbox(
                    this.data.value || 'NO ORDERS ENTERED',
                    {
                        field: this,
                        top: this.data.coords.top,
                        left: this.data.coords.left,
                        width: this.data.coords.width,
                        fontSize: 40,
                        editable: true,
                    },
                );
                el.setControlsVisibility({
                    mt: false,
                    mb: false,
                });
                el.on('editing:exited', () => {
                    this.handleEditingExited(el, canvas, canvas.formState);
                });
                canvas.add(el);
                resolve(el);
            } else {
                fabric.Image.fromURL(
                    OrdersField.imgSrc,
                    el => {
                        el.set({
                            field: this,
                            backgroundColor: Field.UNFILLED_BACKGROUND,
                            left: this.data.coords.left,
                            top: this.data.coords.top,
                        });
                        this.scaleElement(el);
                        canvas.add(el);
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev) {
        const el = ev.element;
        const {action, text} = await this.ordersDialog(
            ev.canvas.formState.ticketOrders,
        );
        if (action === 'cancel') return;
        ev.canvas.formState.setTicketOrders(text);
        this.data.filled = !fp.isEmpty(text);
        this.data.value = text;
        ev.canvas.remove(el);
        const newEl = await this.draw(ev.canvas);
        newEl.set({top: el.top, left: el.left});
        if (this.data.filled) {
            ev.canvas.formState.handleClickNext([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
        } else {
            ev.canvas.formState.setCurrent([
                ev.canvas.pageState.pageNumber,
                newEl,
            ]);
            ev.canvas.setActiveObject(newEl);
        }
        return this.scaleElement(newEl);
    }

    toJSON(el) {
        const result = super.toJSON(el);
        return fp.set('value', el.text, result);
    }

    handleEditingExited(el, canvas, formState) {
        if (el.text.trim() === '') {
            this.data.filled = false;
            this.data.value = null;
            canvas.remove(el);
            this.draw(canvas);
        } else {
            formState.checkComplete();
        }
    }
}

export const TOOLS = {
    SIGNATURE: SignatureField,
    DATE: DateField,
    TEXTBOX: TextboxField,
    ORDERSBOX: OrdersField,
    CHECKBOX: CheckboxField,
};
