import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';
import {useImmunizations} from 'ccm/dataSource';

import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

const consentStatusLookup = {
    TBD: 'TBD',
    Consented: 'Consented',
    Refused: 'Refused',
    Historical: 'Historical',
    notEligible: 'Not Eligible',
};

export default function ImmunizationTable({patient, title, ...props}) {
    const ds = useImmunizations(patient);
    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column title='Name' field='attributes.raw.immunization' />
            <dt2.Column
                title='Consent'
                field='attributes.raw.consentStatus'
                lookup={consentStatusLookup}
            />
            <dt2.Column
                title='Created'
                field='attributes.raw.createdDateTime'
                type='date'
                render={row => (
                    <DateTime
                        value={fp.get('attributes.raw.createdDateTime', row.data)}
                    />
                )}
            />
            <dt2.Column
                title='Administered'
                field='attributes.raw.administrationDateTime'
                type='date'
                render={row => (
                    <DateTime value={fp.get('attributes.raw.administrationDateTime', row.data)} />
                )}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
