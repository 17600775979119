import React, {useState, useEffect, useCallback} from 'react';
import {useFabricJSEditor} from 'fabricjs-react';

import {EditorContext} from './context';

export * as buttons from './Buttons';
export * as actions from './actions';
export {useEditorContext} from './context';
export {default as ContextMenu} from './ContextMenu';
export {default as Canvas} from './Canvas';

export default function Provider({children}) {
    const [id] = useState(Math.random());
    const [canvas, setCanvas] = useState(null);
    // drawing, panning, eraser, etc.
    const [clickMode, setClickMode] = useState(null);
    const [seenRotationWarning, setSeenRotationWarning] = useState(false);
    const [rotatedBlob, setRotatedBlob] = useState();
    const [isCropping, setIsCropping] = useState(false);
    const [cropArea, setCropArea] = useState();
    const [isDirty, setIsDirty] = useState(false);
    const isSafari = navigator.userAgent.includes('Safari');
    const resetClickMode = useCallback(() => {
        setClickMode(null);
        if (canvas) {
            canvas.selection = canvas.isDrawingMode = canvas.isDragging = false;
        }
    }, [setClickMode, canvas]);
    const _setClickMode = useCallback(
        x => {
            resetClickMode();
            setClickMode(x);
        },
        [resetClickMode, setClickMode],
    );
    const {onReady, editor} = useFabricJSEditor();

    const fCanvas = editor?.canvas;
    useEffect(() => {
        setCanvas(fCanvas);
    }, [fCanvas]);

    const value = {
        id,
        canvas,
        editor,
        onReady,
        clickMode,
        setClickMode: _setClickMode,
        resetClickMode,
        seenRotationWarning,
        setSeenRotationWarning,
        rotatedBlob,
        setRotatedBlob,
        isCropping,
        setIsCropping,
        isDirty,
        setIsDirty,
        cropArea,
        setCropArea,
        isSafari,
    };
    return (
        <EditorContext.Provider value={value}>
            {children}
        </EditorContext.Provider>
    );
}
