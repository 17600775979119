import React from 'react';

import {DropAvatar} from 'ccm/art/MedicalIcons';

import VitalCard from './VitalCard';

export default function BloodSugarCard({patient, artifact}) {
    return (
        <VitalCard
            color='blue'
            Avatar={DropAvatar}
            type='bloodSugar'
            patient={patient}
            {...artifact.attributes}
        >
            <h6>Blood sugar</h6>
        </VitalCard>
    );
}
