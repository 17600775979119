import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Typography, Table, TableRow, TableCell, Paper} from '@material-ui/core';

import {useApi, components, ducks} from '@arborian/narrf';

import {useAssessments} from 'ccm/dataSource';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

function renderAnswer(response) {
    return (
        <TableRow style={{backgroundColor: 'whitesmoke'}}>
            <TableCell>Response:</TableCell>
            <TableCell align='right'>
                <strong>Text:</strong> {response.responseText || 'None'} -{' '}
                <strong>Value:</strong> {response.responseValue || 'None'}
            </TableCell>
        </TableRow>
    );
}

function renderQuestion(question) {
    const defaultAnswer = {responseText: 'None', responseValue: 'None'};
    console.log(question.responses.length);
    return (
        <>
            <TableRow>
                <TableCell>Question:</TableCell>
                <TableCell align='right'>{question.questionText}</TableCell>
            </TableRow>
            {question.responses.length
                ? fp.map(response => renderAnswer(response), question.responses)
                : renderAnswer(defaultAnswer)}
        </>
    );
}

function renderQuestionGroup(group) {
    return (
        <>
            <Typography variant='overline'>
                Group {group.groupNumber}: {group.groupTitle}
            </Typography>
            <Paper variant='outlined'>
                <Table size='small'>
                    {fp.map(
                        question => renderQuestion(question),
                        group.assessmentResponses,
                    )}
                </Table>
            </Paper>
            <br />
        </>
    );
}

function renderSectionTable(section) {
    return (
        <div>
            <Typography variant='h6'>Section: {section.sectionCode}</Typography>
            <Typography variant='caption'>
                <div>Status: {section.sectionStatus}</div>
                <div>By: {section.completedBy}</div>
                <div>
                    Date: <DateTime value={section.completedDate} />
                </div>
            </Typography>
            <hr />
            {fp.map(
                group => renderQuestionGroup(group),
                section.assessmentQuestionGroups,
            )}
        </div>
    );
}

function AssessmentDetails({artifact}) {
    const sections = fp.get('attributes.sections', artifact);
    return <div>{fp.map(s => renderSectionTable(s), sections)}</div>;
}

export default function AssessmentsTable({patient, title, ...props}) {
    const api = useApi();
    const ehrConnectionId = fp.get(
        'relationships.ehr_connection.data.id',
        patient,
    );
    const ehrConnection = useSelector(
        ducks.jsonapi.selectObject(['EHRConnection', ehrConnectionId]),
    );
    const ehrConfig = useSelector(
        ducks.jsonapi.selectObject(['EHRConfig', fp.get('id', ehrConnection)]),
    );
    useEffect(() => {
        const connectionUrl = fp.get(
            'relationships.ehr_connection.links.related',
            patient,
        );
        if (connectionUrl && !ehrConnection) {
            api.fetchJson(connectionUrl);
        }
    }, [api, patient, ehrConnection]);
    useEffect(() => {
        const configUrl = fp.get('links.config', ehrConnection);
        if (configUrl && !ehrConfig) {
            api.fetchJson(configUrl);
        }
    }, [api, ehrConnection, ehrConfig]);

    const initialFetchOptions = {
        page: {number: 0, size: 20},
        sort: {field: 'attributes.assessmentRefDate', direction: 'desc'},
    };
    const ds = useAssessments(patient, {initialFetchOptions});
    const refresh = async () => {
        await api.fetchJson(fp.get('links.assessment_refresh', patient), {
            method: 'POST',
        });
        await ds.fetch();
    };

    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column
                title='Created'
                field='attributes.assessmentRefDate'
                type='date'
                render={row => (
                    <DateTime
                        value={fp.get('attributes.assessmentRefDate', row.data)}
                    />
                )}
            />
            <dt2.Column
                title='Description'
                field='attributes.assessmentDescription'
                filtering={true}
            />
            <dt2.Column
                title='Score'
                field='attributes.assessmentScore'
                filtering={false}
            />
            <dt2.DetailPanel
                render={row => {
                    return <AssessmentDetails artifact={row.data} />;
                }}
            />
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
