import React, {useRef, useCallback} from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter} from 'react-router-dom';
import {MuiThemeProvider, CssBaseline, IconButton} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import {Provider as ReduxProvider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsAdapter from '@date-io/date-fns';

import {reducer, ApiProvider, ErrorBoundary} from '@arborian/narrf';
import DialogProvider from 'ccm/components/dialogs';
import GlobalsProvider from 'ccm/components/Globals';
import {logError} from 'ccm/lib/util';
import Router from 'ccm/components/Router';
import {TourProvider} from 'ccm/components/Tour';
import {ConstantUrlsProvider} from 'ccm/components/ConstantUrls';
import theme from 'ccm/theme';
import '@fontsource/montserrat';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const store = configureStore({reducer});
const history = createBrowserHistory();

function App() {
    console.log({API_ROOT});
    const notistackRef = useRef();
    const handleDismiss = useCallback(
        key => {
            notistackRef.current.closeSnackbar(key);
        },
        [notistackRef],
    );
    const dismissAction = useCallback(
        key => (
            <IconButton onClick={() => handleDismiss(key)}>
                <Cancel />
            </IconButton>
        ),
        [handleDismiss],
    );
    return (
        <ErrorBoundary fallback={errorScreen} onError={logError}>
            <ReduxProvider store={store}>
                <ApiProvider
                    ui_home='/'
                    ui_login='/login'
                    history={history}
                    api_root={API_ROOT}
                    client_id={null} // loaded from /legacy/constants
                    onError={console.log}
                >
                    <CssBaseline />
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                            <SnackbarProvider
                                ref={notistackRef}
                                action={dismissAction}
                                anchorOrigin={{
                                    horizontal: 'left',
                                    vertical: 'top',
                                }}
                            >
                                <ConstantUrlsProvider>
                                    <GlobalsProvider>
                                        <BrowserRouter>
                                            <DialogProvider>
                                                <TourProvider>
                                                    <Router history={history} />
                                                </TourProvider>
                                            </DialogProvider>
                                        </BrowserRouter>
                                    </GlobalsProvider>
                                </ConstantUrlsProvider>
                            </SnackbarProvider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </ApiProvider>
            </ReduxProvider>
        </ErrorBoundary>
    );
}

export const errorScreen = () => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <h2>Something went wrong.</h2>
        <p>
            Please refresh the page and try again. If this issue continues,
            please contact{' '}
            <a href='mailto:techsupport@tcpamd.com'>techsupport@tcpamd.com</a>.
        </p>
    </div>
);

export default App;
