import React from 'react';

import {Hidden} from '@material-ui/core';

import {useMessages} from 'ccm/dataSource';

import TableView from './TableView';
import ListView from './ListView';
import useInboxDataSource from './hooks';

const INITIAL_FETCH_OPTIONS = {
    sort: {field: 'attributes.timestamp', direction: 'desc'},
    page: {number: 0, size: 5},
    filter: {'attributes.thread_id': null},
};

export default function Inbox({
    patient = null,
    showAllMessages = false,
    ...props
}) {
    const dsMessages = useMessages(patient, {
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
    });
    const dsInbox = useInboxDataSource(props);
    const ds = showAllMessages ? dsMessages : dsInbox;

    return (
        <>
            <Hidden smDown>
                <TableView
                    dataSource={ds}
                    showAllMessages={showAllMessages}
                    {...props}
                />
            </Hidden>
            <Hidden mdUp>
                <ListView
                    dataSource={ds}
                    showAllMessages={showAllMessages}
                    {...props}
                />
            </Hidden>
        </>
    );
}
