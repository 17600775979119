import {useState, useCallback, useEffect} from 'react';
import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

import {ducks, useApi} from '@arborian/narrf';

import {useCurrentUser} from 'ccm/dataSource/misc';
import {useTicketActions} from 'ccm/components/ticket';

const DEFAULT_PROGRESS_STATE = {label: 'Start', style: {}};

export const useFormState = (ticket, ticketActions) => {
    const api = useApi();
    const actions = useTicketActions();

    const signatureUrl = useSignatureUrl(api);
    const pages = useFormPages(api, ticket);
    const [complete, setComplete] = useState(false);
    const [canvases, setCanvases] = useState();
    const [current, setCurrent] = useState();
    const [ticketOrders, setTicketOrders] = useState(
        fp.getOr(null, 'attributes.data.orders', ticket),
    );
    const registerCanvas = useCallback(
        (pageNumber, canvas) => {
            setCanvases(fp.set(pageNumber, canvas));
        },
        [setCanvases],
    );
    const reset = useCallback(() => {
        setComplete(true);
        setCanvases([]);
        setCurrent(null);
    }, [setCanvases, setComplete]);

    const patchTicketOrders = useCallback(async () => {
        if (
            ticketOrders !== null &&
            ticketOrders !== ticket.attributes.data.orders
        ) {
            return await actions.patch(ticket, {data: {orders: ticketOrders}});
        }
    }, [actions, ticket, ticketOrders]);

    const save = useCallback(async () => {
        await Promise.all(canvases.map(c => c.pageState.saveCanvas()));
        await patchTicketOrders();
        return await ticketActions.refresh(ticket);
    }, [canvases, ticket, ticketActions, patchTicketOrders]);

    const checkComplete = useCallback(() => {
        const _complete = allFieldsComplete(canvases);
        if (_complete !== complete) setComplete(_complete);
    }, [canvases, complete, setComplete]);
    const handleClickNext = useCallback(
        newCurrent => {
            _handleClickNext(newCurrent || current, setCurrent, canvases);
        },
        [current, setCurrent, canvases],
    );
    const progressState = useCallback(
        () => _progressState(current, canvases),
        [current, canvases],
    );

    return {
        ticket,
        signatureUrl,
        pages,
        complete,
        setComplete,
        current,
        setCurrent,
        canvases,
        registerCanvas,
        checkComplete,
        reset,
        save,
        handleClickNext,
        progressState,
        ticketOrders,
        setTicketOrders,
    };
};

export const useSignatureUrl = api => {
    const [signatureUrl, setSignatureUrl] = useState();
    const curUser = useCurrentUser();

    // Fetch the signature URL
    useEffect(() => {
        if (!curUser) return;
        const blobHref = fp.get(
            'relationships.signature_blob_processed.links.related',
            curUser,
        );
        if (!blobHref) return;
        api.fetchJsonApi(blobHref).then(resp => {
            setSignatureUrl(
                fp.get('relationships.content.links.related', resp.data),
            );
        });
    }, [api, curUser]);
    return signatureUrl;
};

const useFormPages = (api, ticket) => {
    // Fetch the pages
    useEffect(() => {
        const sigFormUrl = fp.get(
            'relationships.signature_form.links.related',
            ticket,
        );
        if (!sigFormUrl) return;
        api.fetchJsonApi(sigFormUrl, {include: ['pages']});
    }, [api, ticket]);

    const ticketRel = useSelector(ducks.jsonapi.selectRelated(ticket));
    const signatureForm = ticketRel?.signature_form;
    const pages = useSelector(selectFormPages(signatureForm));
    return pages;
};

const selectFormPages = form =>
    createSelector(
        ducks.jsonapi.selectObject(['SignatureFormPage']),
        allPages => {
            const linkage = form ? form.relationships.pages.data : [];
            return linkage.map(d => allPages[d.id]);
        },
    );

const allFieldsComplete = canvases => {
    for (var c of canvases) {
        for (var el of c.getObjects()) {
            if (!el.field.data.filled) {
                console.log('Form is not complete because of', el.field);
                return false;
            }
        }
    }
    return true;
};

function _handleClickNext(current, setCurrent, canvases) {
    const next = _getNext(current, canvases);
    if (!next) return;
    const [nextPageNumber, nextElement] = next;
    const nextCanvas = canvases[nextPageNumber];
    if (!nextCanvas) return;
    for (var i = 0; i < canvases.length; i++) {
        if (i !== nextPageNumber) {
            const canvas = canvases[i];
            canvas.discardActiveObject();
            canvas.requestRenderAll();
        }
    }
    nextCanvas.setActiveObject(nextElement);
    nextCanvas.requestRenderAll();
    setCurrent(next);
}

function _progressState(current, canvases) {
    if (!current) return DEFAULT_PROGRESS_STATE;
    const [curPageNumber, el] = current;
    let offset = 0;
    for (var pgNo = 0; pgNo < curPageNumber; pgNo++) {
        const c = canvases[pgNo];
        // pg.canvas.discardActiveObject();
        offset += c.height;
    }
    const curCanvas = canvases[curPageNumber];
    if (!curCanvas) return DEFAULT_PROGRESS_STATE;
    console.log('_progressState', {current, canvases, curCanvas});
    const top = offset + el.top * curCanvas.getZoom();
    return {
        label: el.field.progressLabel,
        style: {top},
    };
}

function _getNext(current, canvases) {
    let foundCurrent = current ? false : true;
    let first = null;
    console.log('_getNext', {current, canvases});
    for (var canvas of canvases) {
        const pageState = canvas.pageState;
        const pageObjects = _getSortedObjects(canvas);
        console.log('_getNext', {pageState, pageObjects, first, foundCurrent});
        for (var obj of pageObjects) {
            if (!first) {
                first = [pageState.pageNumber, obj];
            }
            if (foundCurrent) {
                console.log('Found current, new next is', obj);
                return [pageState.pageNumber, obj];
            }
            if (current[0] === pageState.pageNumber && current[1] === obj) {
                foundCurrent = true;
            }
        }
    }
    return first;
}

function _getSortedObjects(canvas) {
    const objects = canvas.getObjects();
    const sortedObjects = objects.toSorted((a, b) => {
        if (a.top === b.top) {
            return a.left - b.left;
        } else {
            return a.top - b.top;
        }
    });
    return sortedObjects;
}
