import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Card, CardHeader, CardContent, Button} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

function UserProfileForm({submitLabel, currentUser, onSubmit}) {
    const handleSubmit = (values, actions) => {
        console.log('Submit profile change', values);
        return onSubmit(values);
    };

    const initialValues = {
        name: fp.get('attributes.name', currentUser),
    };

    if (!currentUser) return null;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({touched, values, errors}) => (
                <Form>
                    <Field
                        component={TextField}
                        type='text'
                        label='Name'
                        name='name'
                        fullWidth
                    />
                    <Button type='submit' variant='contained' color='primary'>
                        Update
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default function UserProfileCard() {
    const api = useApi();
    const curUser = useSelector(selectCurrentUser);

    const handleChange = useCallback(
        async attributes => {
            console.log({attributes});
            await api.fetchJsonApi(curUser.links.self, {
                method: 'PUT',
                json: {data: {type: 'User', attributes}},
            });
        },
        [api, curUser],
    );

    return (
        <Card>
            <CardHeader title='My Info' />
            <CardContent>
                <UserProfileForm
                    currentUser={curUser}
                    onSubmit={handleChange}
                />
            </CardContent>
        </Card>
    );
}
