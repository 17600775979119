import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    accordion: {
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        '& .MuiAccordionDetails-root': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiTabs-root': {
            width: '100%',
        },
        '& .MuiCard-root': {
            margin: theme.spacing(1, 0),
        },
    },
    calMonth: {
        height: 500,
    },
}));
