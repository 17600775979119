import React from 'react';
import fp from 'lodash/fp';

import {Autocomplete} from 'formik-material-ui-lab';
import {TextField} from '@material-ui/core';

import {useAllFacilities} from 'ccm/dataSource/allResources';

export default function FacilitySelect({
    label,
    name,
    touched,
    errors,
    ...props
}) {
    const facilities = fp.values(useAllFacilities());
    return (
        <Autocomplete
            options={facilities}
            getOptionLabel={fp.get('attributes.name')}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    errors={touched && touched[name] && !!errors[name]}
                    helperText={errors && errors[name]}
                />
            )}
            {...props}
        />
    );
}
