import React from 'react';
import fp from 'lodash/fp';
import {Field} from 'formik';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormGroup,
    FormLabel,
} from '@material-ui/core';
import {CheckboxWithLabel} from 'formik-material-ui';

const VITAL_FIELDS = [
    {id: 'show_pain', label: 'Display pain level?'},
    {id: 'show_temperature', label: 'Display body temperature?'},
    {id: 'show_heart_rate', label: 'Display heart rate?'},
    {id: 'show_blood_pressure', label: 'Display blood pressure?'},
    {id: 'show_blood_sugar', label: 'Display blood sugar?'},
    {id: 'show_oxygen_saturation', label: 'Display oxygen saturation?'},
    {id: 'show_respiration', label: 'Display respiration rate?'},
    {id: 'show_weight', label: 'Display weight?'},
    {id: 'show_height', label: 'Display height?'},
    {id: 'show_discharge_date', label: 'Display estimated discharge date?'},
];

const INFO_FIELDS = [
    {id: 'show_diagnostic_report', label: 'Display labs?'},
    {id: 'show_contact', label: 'Display contacts?'},
    {id: 'show_practitioner', label: 'Display practitioners?'},
    {id: 'show_condition', label: 'Display conditions?'},
    {id: 'show_medication', label: 'Display medications?'},
    {id: 'show_nutrition_order', label: 'Display nutrition orders?'},
    {id: 'show_therapy', label: 'Display therapy tracker?'},
    {id: 'show_discharge_date', label: 'Display estimated discharge date?'},
    {
        id: 'show_allergy_intolerance',
        label: 'Display allergies & intolerances?',
    },
    {id: 'show_immunization', label: 'Display immunizations?'},
    {id: 'show_care_plan', label: 'Display care plans?'},
    {id: 'show_progress_note', label: 'Display progress notes?'},
];

function FieldGroup({label, fields}) {
    return (
        <FormControl fullWidth margin='dense' size='small'>
            <FormLabel component='legend'>{label}</FormLabel>
            <FormGroup>
                {fp.map(
                    f => (
                        <Field
                            key={f.id}
                            component={CheckboxWithLabel}
                            type='checkbox'
                            size='small'
                            name={`attributes.portal_config.${f.id}`}
                            Label={{label: f.label}}
                        />
                    ),
                    fields,
                )}
            </FormGroup>
        </FormControl>
    );
}

export default function PortalConfig({config}) {
    return (
        <Accordion>
            <AccordionSummary>Patient portal configuration</AccordionSummary>
            <AccordionDetails>
                <FieldGroup label='Vital Fields' fields={VITAL_FIELDS} />
                <FieldGroup label='Info Fields' fields={INFO_FIELDS} />
            </AccordionDetails>
        </Accordion>
    );
}
