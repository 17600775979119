import React, {useCallback, useMemo} from 'react';
import {ShiftAssignmentContext} from './context';
import {
    useRule,
    useRuleAssignments,
    useRuleShifts,
    usePractice,
    usePracticeUsers,
} from './hooks';
import AutocompleteDialog from 'ccm/components/dialogs/AutocompleteDialog';
import {useDialog} from 'ccm/components/dialogs';
import fp from 'lodash/fp';
import {useApi} from '@arborian/narrf';
import * as h from 'ccm/lib/helpers';

const OWNER_DIALOG_OPTIONS = {
    title: 'Select Ticket Owner',
    label: 'User',
    submitLabel: 'Select',
    getOptionLabel: h.userLabel,
};

const PROVIDER_DIALOG_OPTIONS = {
    title: 'Select Ticket Provider',
    label: 'User',
    submitLabel: 'Select',
    getOptionLabel: h.userLabel,
};

const EMPTY_ASSIGNMENT = {owner: null, provider: null};

export default function Provider({children, ruleId, facilities}) {
    const api = useApi();
    const rule = useRule(ruleId);
    const assignments = useRuleAssignments(rule);
    const shifts = useRuleShifts(rule);
    const practice = usePractice(rule);
    const owners = usePracticeUsers(practice, 'staff');
    const providers = usePracticeUsers(practice, 'provider');
    const ownerDialogOptions = useMemo(
        () => ({
            options: owners,
            ...OWNER_DIALOG_OPTIONS,
        }),
        [owners],
    );
    const providerDialogOptions = useMemo(
        () => ({
            options: providers,
            ...PROVIDER_DIALOG_OPTIONS,
        }),
        [providers],
    );
    const addOwnerDialog = useDialog(AutocompleteDialog, ownerDialogOptions);
    const addProviderDialog = useDialog(
        AutocompleteDialog,
        providerDialogOptions,
    );
    const assignmentsByShiftFacility = useMemo(() => {
        let result = {};
        fp.forEach(asg => {
            const shiftId = fp.get('relationships.shift.data.id', asg);
            const facId = fp.get('relationships.facility.data.id', asg) || null;
            const core = {
                owner: fp.get('relationships.owner.data.id', asg) || null,
                provider: fp.get('relationships.provider.data.id', asg) || null,
            };
            result = fp.set([shiftId, facId], core, result);
        }, assignments);
        return result;
    }, [assignments]);

    const ruleFallbackIndex = useMemo(
        () =>
            fp.pipe([
                fp.get('attributes.fallbacks'),
                fp.map(fb => [fb.facility_id, fb]),
                fp.fromPairs,
            ])(rule),
        [rule],
    );

    const fallbackUser = useCallback(
        role => {
            // Returns user, null, or undefined
            const fallbackForFac = fac =>
                fp.find(
                    fb => fb.facility_id === fac.id,
                    rule.attributes.fallbacks,
                ) || {owner_id: null, provider_id: null};
            const values = fp.pipe([
                fp.map(fallbackForFac),
                fp.map(`${role}_id`),
                fp.uniq,
            ])(facilities);
            const userId = values.length === 1 ? values[0] : undefined;
            if (!userId) return userId;
            const options = role === 'owner' ? owners : providers;
            return fp.find(o => o.id === userId, options);
        },
        [facilities, rule, owners, providers],
    );

    const shiftUser = useCallback(
        (shift, role) => {
            // Returns user, null, or undefined
            const assignmentsByFacility = assignmentsByShiftFacility[shift.id];
            const values = fp.pipe([
                fp.map(
                    fac =>
                        fp.get(fac.id, assignmentsByFacility) ||
                        EMPTY_ASSIGNMENT,
                ),
                fp.map(role),
                fp.uniq,
            ])(facilities);
            const userId = values.length === 1 ? values[0] : undefined;
            if (!userId) return userId;
            const options = role === 'owner' ? owners : providers;
            return fp.find(o => o.id === userId, options);
        },
        [facilities, owners, providers, assignmentsByShiftFacility],
    );

    const setFallbackAssignments = useCallback(
        (user, role) => {
            const userId = user ? user.id : null;

            fp.forEach(fac => {
                const current = fp.get(fac.id, ruleFallbackIndex) || {
                    facility_id: fac.id,
                    owner_id: null,
                    provider_id: null,
                };
                ruleFallbackIndex[fac.id] = fp.set(
                    `${role}_id`,
                    userId,
                    current,
                );
            }, facilities);
            const newFallbacks = fp.values(ruleFallbackIndex);

            return api.fetchJson(rule.links.self, {
                method: 'PATCH',
                json: {
                    data: fp.set('attributes.fallbacks', newFallbacks, rule),
                },
            });
        },
        [facilities, rule, api, ruleFallbackIndex],
    );

    const setShiftAssignments = useCallback(
        (user, shift, role) => {
            const userId = user ? user.id : null;
            const newAssignments = fp.map(fac => {
                const newCore = fp.set(
                    role,
                    userId,
                    fp.get([shift.id, fac.id], assignmentsByShiftFacility) ||
                        EMPTY_ASSIGNMENT,
                );
                const resource = {
                    type: 'Assignment',
                    relationships: {
                        facility: {
                            data: fac.id
                                ? {id: fac.id, type: 'Facility'}
                                : null,
                        },
                        shift: {data: {id: shift.id, type: 'Shift'}},
                        owner: {
                            data: newCore.owner
                                ? {type: 'User', id: newCore.owner}
                                : null,
                        },
                        provider: {
                            data: newCore.provider
                                ? {type: 'User', id: newCore.provider}
                                : null,
                        },
                    },
                };
                return resource;
            }, facilities);

            return api.fetchJson(
                shift.relationships.assignments.links.related,
                {method: 'POST', json: {data: newAssignments}},
            );
        },
        [facilities, api, assignmentsByShiftFacility],
    );

    const value = {
        rule,
        facilities,
        assignments,
        shifts,
        practice,
        owners,
        providers,
        addOwnerDialog,
        addProviderDialog,
        fallbackUser,
        shiftUser,
        setFallbackAssignments,
        setShiftAssignments,
    };

    return (
        <ShiftAssignmentContext.Provider value={value}>
            {children}
        </ShiftAssignmentContext.Provider>
    );
}
