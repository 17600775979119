import React from 'react';

import {components} from '@arborian/narrf';
import InstructionList from 'ccm/components/InstructionList';

const {dt2} = components;

export default function OrderTemplateTable({
    dataSource,
    isAdmin,
    children,
    ...props
}) {
    return (
        <dt2.DataTable
            id='OrderTemplateTable'
            dataSource={dataSource}
            {...props}
        >
            <dt2.Column id='col-title' title='Title' field='attributes.title' />
            <dt2.Action
                id='OrderTemplateTable-action-refresh'
                name='refresh'
                free
                onClick={() => dataSource.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            <dt2.DetailPanel
                tooltip='Instructions'
                render={row => (
                    <InstructionList
                        orderTemplate={row.data}
                        enableAdminOptions={!!props.editable}
                    />
                )}
            />
            {children}
        </dt2.DataTable>
    );
}
