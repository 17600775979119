import React, {useState, useEffect} from 'react';

import {lighten} from '@material-ui/core/styles/colorManipulator';
import {
    IconButton,
    Toolbar,
    TextField,
    InputAdornment,
    makeStyles,
} from '@material-ui/core';
import {Clear, Search} from '@material-ui/icons';

import {
    MarkUnread,
    MarkRead,
    Archive,
    Unarchive,
    Refresh,
    ViewInbox,
    ViewArchived,
} from './ActionButtons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(0, 1, 0, 3),
    },
    flex: {
        flex: 1,
    },
    selecting: {
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
}));

function SearchField({value, onChange}) {
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputValue(value || '');
    }, [value]);
    const acceptChange = (ev) => onChange(inputValue);
    return (
        <TextField
            value={inputValue}
            onChange={(ev) => setInputValue(ev.target.value)}
            onBlur={acceptChange}
            onKeyPress={(ev) => ev.key === 'Enter' && acceptChange()}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {value ? (
                            <IconButton onClick={(e) => onChange(null)}>
                                <Clear />
                            </IconButton>
                        ) : (
                            <IconButton onClick={acceptChange}>
                                <Search />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
}

function SelectionActions({dataSource}) {
    return (
        <>
            {dataSource.showArchived ? (
                <Unarchive
                    onClick={() =>
                        dataSource.patch(
                            {deleted: false},
                            dataSource.selectedItems
                        )
                    }
                />
            ) : (
                <Archive
                    onClick={() =>
                        dataSource.patch(
                            {read: true, deleted: true},
                            dataSource.selectedItems
                        )
                    }
                />
            )}
            <MarkUnread
                onClick={() =>
                    dataSource.patch({read: false}, dataSource.selectedItems)
                }
            />
            <MarkRead
                onClick={() =>
                    dataSource.patch({read: true}, dataSource.selectedItems)
                }
            />
        </>
    );
}

export default function InboxToolbar({dataSource, showAllMessages = false}) {
    const classes = useStyles();
    const title = showAllMessages
        ? 'Messages'
        : dataSource.showArchived
        ? 'Archive'
        : 'Inbox';
    let toolbarClasses = [classes.toolbar];
    if (dataSource.selectedItems.length > 0) {
        toolbarClasses.push(classes.selecting);
    }
    return (
        <Toolbar className={toolbarClasses}>
            {dataSource.selectedItems.length > 0 ? (
                <h6>{dataSource.selectedItems.length}</h6>
            ) : (
                <h6>{title}</h6>
            )}
            <div className={classes.flex} />
            {dataSource.selectedItems.length > 0 ? (
                <SelectionActions dataSource={dataSource} />
            ) : (
                <SearchField
                    value={dataSource.fetchOptions.search}
                    onChange={dataSource.setSearch}
                />
            )}
            <Refresh onClick={dataSource.fetch} />
            {!showAllMessages &&
                (dataSource.showArchived ? (
                    <ViewInbox
                        onClick={() => dataSource.setShowArchived(false)}
                    />
                ) : (
                    <ViewArchived
                        onClick={() => dataSource.setShowArchived(true)}
                    />
                ))}
        </Toolbar>
    );
}
