import {useEffect, useMemo} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {useApi, ducks} from '@arborian/narrf';

const selectProviders = practiceId =>
    createSelector(
        ducks.jsonapi.selectObject('UserSummary'),
        fp.filter(u =>
            fp.contains(
                `practice:${practiceId}/provider`,
                fp.get('attributes.group_ids', u),
            ),
        ),
    );

export function useProviders(practice) {
    const api = useApi();
    const selector = useMemo(() => selectProviders(practice?.id), [practice]);
    const providers = useSelector(selector);
    useEffect(() => {
        const href = fp.get('relationships.providers.links.related', practice);
        api.fetchAllJsonApi(href);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practice]);
    return providers;
}
