import React, {useMemo, useCallback} from 'react';
import fp from 'lodash/fp';
import {Formik, Form} from 'formik';

import {
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Typography,
    Divider,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ExpandMore} from '@material-ui/icons';

import ReadOnlyChip from 'ccm/components/ticket/ReadOnlyChip';

import DynaField from './DynaField';
import {buildSchema} from './schemas';

const useStyles = makeStyles(theme => ({
    pre: {
        textAlign: 'left',
    },
    formParent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    formShape: {
        width: '100%',
    },
    fieldSpacing: {
        padding: 10,
    },
    fieldSet: {
        borderWidth: 0,
    },
}));

export default function DynaFormExpansionPanel({
    form,
    session,
    formid,
    pageid,
    expanded,
    onChangeExpanded,
    handleTicketChange,
    readOnly,
}) {
    const classes = useStyles();
    const pageData = fp.get(pageid, form);
    const schema = useMemo(() => buildSchema(pageData), [pageData]);
    const initialValues = useMemo(() => {
        const defaultFieldValues = fp.pipe([
            fp.map(field => [field.id, field.initialValue]),
            fp.fromPairs,
        ])(pageData.attributes.page.fields);
        return {...defaultFieldValues, ...session};
    }, [pageData, session]);

    const submitHandler = useCallback(
        (data, formikProps) => {
            handleTicketChange(data);
        },
        [handleTicketChange],
    );

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={submitHandler}
        >
            {({errors, handleSubmit, handleReset}) => (
                <Form className={classes.formShape}>
                    <Accordion
                        name='editor'
                        key={pageData.attributes.page.pageid}
                        expanded={!!expanded}
                        onChange={onChangeExpanded}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='editor-content'
                            id='editor-header'
                        >
                            <Typography className={classes.heading}>
                                {pageData.attributes.page.title}
                            </Typography>
                            {readOnly ? <ReadOnlyChip /> : null}
                        </AccordionSummary>
                        <AccordionDetails className={classes.formParent}>
                            <fieldset
                                className={classes.fieldSet}
                                disabled={readOnly}
                            >
                                {fp.map(
                                    field => (
                                        <DynaField
                                            key={field.id}
                                            field={field}
                                        />
                                    ),
                                    pageData.attributes.page.fields,
                                )}
                            </fieldset>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button
                                size='small'
                                color='primary'
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </AccordionActions>
                    </Accordion>
                </Form>
            )}
        </Formik>
    );
}
