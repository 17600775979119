import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {useApi, ducks, components} from '@arborian/narrf';

import {MESSAGE_TYPES} from 'ccm/constants';
import MessageDetailPanel from 'ccm/components/MessageDetailPanel';
import {DateTime} from 'ccm/components/DateTime';
import * as h from 'ccm/lib/helpers';
import {useMessages} from 'ccm/dataSource';
import {url_for} from 'ccm/routes';
import NewThreadDialog from './InternalMessaging/NewThreadDialog';
import ThreadPermissions from 'ccm/components/InternalMessaging/Permissions';

import {useDialog} from 'ccm/components/dialogs';

import ConfirmationDialog from 'ccm/components/dialogs/ConfirmationDialog';
import MessageCompositionDialog from 'ccm/components/dialogs/MessageCompositionDialog';

const {dt2} = components;

const lookupMessageTypes = fp.pipe([
    fp.map(o => [o.value, o.label]),
    fp.fromPairs,
])(MESSAGE_TYPES);

export default function AdminMessageTable({patient}) {
    const api = useApi();
    const history = useHistory();
    const tableRef = useRef();
    const dispatch = useDispatch();
    const confirmDelete = useDialog(ConfirmationDialog);
    const messageComposition = useDialog(MessageCompositionDialog);
    const messages = useMessages(patient, {prefix: null});
    const createThreadDialog = useRef();
    const hasThreadPermission = ThreadPermissions();

    const onRowDelete = async row => {
        if (
            await confirmDelete({
                title: 'Confirm Delete?',
                prompt: `This will delete all copies of this message, system-wide.
            This action is irrevocable; once the message is gone, it's GONE.
            `,
                yesLabel: 'Yes, delete it right now, forever.',
                noLabel: 'Cancel',
            })
        ) {
            await api.fetchJson(row.data.links.self, {method: 'DELETE'});
            dispatch(ducks.jsonapi.deleteData(row.data));
        }
    };

    const handleCompose = async ev => {
        const message = await messageComposition({patient});
        if (message) {
            console.log('Send message', message);
            const {includeAll, ...attributes} = message;
            const url = fp.get('relationships.messages.links.related', patient);
            const data = {
                type: 'Message',
                attributes,
            };
            const resp = await api.fetchJson(url, {
                method: 'POST',
                json: {data},
            });
            console.log('Post returned', resp);
            messages.fetch();
        }
    };

    const handleCreateThread = async ev => {
        let data = await createThreadDialog.current();
        if (!data) return;
        await api.fetchJsonApi(
            api.url_for('internal_messaging.ThreadCollection'),
            {
                method: 'POST',
                json: {
                    data,
                },
            },
        );
        history.push(url_for('inbox'));
    };

    return (
        <>
            <NewThreadDialog dialogRef={createThreadDialog} patient={patient} />
            <dt2.DataTable
                title={h.humanName(fp.get('attributes.pcc', patient))}
                tableRef={tableRef}
                dataSource={messages}
                options={{
                    search: true,
                    filtering: true,
                }}
                editable={{onRowDelete}}
            >
                <dt2.Column
                    title='Type'
                    field='attributes.type'
                    lookup={lookupMessageTypes}
                />
                <dt2.Column title='Sender' field='attributes.from_' />
                <dt2.Column title='Subject' field='attributes.subject' />
                <dt2.Column
                    title='Sent'
                    field='attributes.timestamp'
                    filtering={false}
                    render={row => (
                        <DateTime
                            value={fp.get('data.attributes.timestamp', row)}
                        />
                    )}
                />
                <dt2.DetailPanel
                    render={row => {
                        return <MessageDetailPanel message={row.data} />;
                    }}
                />
                <dt2.Action
                    free
                    onClick={() => messages.fetch()}
                    tooltip='Refresh'
                    icon='refresh'
                />
                {hasThreadPermission.create && (
                    <dt2.Action
                        free
                        onClick={handleCreateThread}
                        tooltip='Create Internal Message'
                        icon='message'
                    />
                )}
                <dt2.Action
                    free
                    onClick={handleCompose}
                    tooltip='Compose'
                    icon='add'
                />
            </dt2.DataTable>
        </>
    );
}
