import React, {useRef, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Link, useHistory} from 'react-router-dom';

import {useApi, ducks, components} from '@arborian/narrf';

import {url_for} from 'ccm/routes';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';

import BasePage from 'ccm/components/BasePage';
import TelecomChips from 'ccm/components/TelecomChips';

const {
    DataTable,
    Column,
    Action,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const defaultFetchOptions = {
    // filter: {},
    // sort: {},
    page: {size: 20},
};

function NameColumnComponent({row}) {
    return (
        <Link to={url_for('facility', {facilityId: row.data.id})}>
            {fp.get('attributes.name', row.data)}
        </Link>
    );
}

export default function FacilitiesPage() {
    const api = useApi();
    const history = useHistory();
    const tableRef = useRef();
    const curUser = useSelector(selectCurrentUser);
    const rel = useSelector(ducks.jsonapi.selectRelated(curUser));
    const hashPrefix = 'pracTable.';
    const facilityUrl = fp.get(
        'relationships.facilities.links.related',
        curUser,
    );

    const fetchOptions = useLocationFetchOptions(
        defaultFetchOptions,
        hashPrefix,
    );
    const onChangeFetchOptions = useCallback(
        o => {
            const newParams = updateLocationFetchOptions(o, hashPrefix);
            history.push('?' + newParams.toString());
        },
        [history, hashPrefix],
    );

    const fetchFacilities = useCallback(
        async fetchOptions => {
            if (!facilityUrl) return {data: [], page: 0, totalCount: 0};
            const result = await api.fetchDataTable(facilityUrl, fetchOptions);
            console.log('... result was', result);
            return result;
        },
        [api, facilityUrl],
    );

    const refresh = useCallback(() => tableRef.current.fetch(), [tableRef]);

    useEffect(() => {
        if (rel.facilities && rel.facilities.length === 1) {
            history.push(
                url_for('facility', {facilityId: rel.facilities[0].id}),
            );
        }
    }, [rel, history]);

    return (
        <BasePage wide title='Facilities'>
            <DataTable
                title='Facilities'
                size='small'
                data={fetchFacilities}
                tableRef={tableRef}
                fetchOptions={fetchOptions}
                onChangeFetchOptions={onChangeFetchOptions}
                options={{search: true}}
            >
                <Column
                    title='Name'
                    field='attributes.name'
                    component={NameColumnComponent}
                />
                <Column title='Organization' field='attributes.org_name' />
                <Column title='Address' field='attributes.address.oneLine' />
                <Column
                    title='Telecom'
                    field='attributes.telecom'
                    component={TelecomChips}
                />
                <Column title='Email' field='attributes.email.email' />

                <Action
                    name='refresh'
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </DataTable>
        </BasePage>
    );
}
