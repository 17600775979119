import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Formik, Form, Field} from 'formik';
import {Button} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';

import PreviousNoteField from './PreviousNoteField';
import * as sel from './selectors';

export default function NotePrepForm({ehrConnectionId, onSubmit, visits}) {
    const initialValues = useMemo(
        () => ({
            type: 'VisitNotePrepBatch',
            attributes: {
                prev: {},
                carePlan: 'Please view Care Plan through SamePage MD Portal',
            },
            relationships: {
                transcriptType: {data: null},
                eventType: {data: null},
                template: {data: null},
                visits: {data: fp.map(fp.pick(['id', 'type']), visits)},
            },
        }),
        [visits],
    );
    const transcriptTypes = useSelector(
        sel.selectTranscriptTypes(ehrConnectionId),
    );
    const encounterTypes = useSelector(
        sel.selectEncounterTypes(ehrConnectionId),
    );
    const templates = useSelector(sel.selectTemplates(ehrConnectionId));
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({values, handleSubmit, setFieldValue}) => (
                <Form>
                    <Field
                        name='relationships.transcriptType.data'
                        component={LinkageAutocompleteField}
                        label='Chart Note Type'
                        options={transcriptTypes}
                        getOptionLabel={fp.get(
                            'attributes.data.transcriptTypeName',
                        )}
                    />
                    <Field
                        name='relationships.eventType.data'
                        component={LinkageAutocompleteField}
                        label='Encounter Type'
                        options={encounterTypes}
                        getOptionLabel={fp.get('attributes.data.displayName')}
                    />
                    <Field
                        name='relationships.template.data'
                        component={LinkageAutocompleteField}
                        label='Template'
                        options={templates}
                        getOptionLabel={fp.get('attributes.data.name')}
                    />
                    <Field
                        name='attributes.prev'
                        component={PreviousNoteField}
                        label='Copy Previous Note Section'
                        transcriptTypeId={fp.get(
                            'relationships.transcriptType.data.id',
                            values,
                        )}
                        transcriptTypes={transcriptTypes}
                    />
                    <Field
                        name='attributes.carePlan'
                        component={TextField}
                        label='Care plan'
                        fullWidth
                    />
                    <br />
                    <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        onClick={handleSubmit}
                    >
                        Create Encounter Notes
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
