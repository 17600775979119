import React from 'react';
import fp from 'lodash/fp';

import {
    Divider,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    IconButton,
    TextField,
} from '@material-ui/core';
import {Add, DragHandle, Delete} from '@material-ui/icons';
import {Draggable} from 'react-smooth-dnd';
import {Field, FieldArray, useFormikContext} from 'formik';
import {Autocomplete} from 'formik-material-ui-lab';

import LinkageAutocompleteField from 'ccm/components/LinkageAutocompleteField';
import SortableList from 'ccm/components/SortableList';
import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';

function OverrideField({value, name, ticketTypes, noteTypes, onDelete}) {
    return (
        <Draggable>
            <ListItem button>
                <ListItemIcon className='drag-handle'>
                    <DragHandle />
                </ListItemIcon>
                <ListItemText>
                    <Field
                        component={Autocomplete}
                        fullWidth
                        autoComplete
                        autoHighlight
                        name={`${name}.ticketType`}
                        options={fp.keys(ticketTypes)}
                        getOptionLabel={o => ticketTypes[o]}
                        renderInput={params => (
                            <TextField label='Ticket Type' {...params} />
                        )}
                    />
                    <Field
                        autoComplete
                        autoHighlight
                        autoSelect
                        fullWidth
                        component={LinkageAutocompleteField}
                        label='Note Type'
                        name={`${name}.noteType`}
                        options={noteTypes}
                        getOptionLabel={fp.get('attributes.label')}
                    />
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton edge='end' onClick={onDelete}>
                        <Delete />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </Draggable>
    );
}

export default function OverridesFields({name = 'overrides', noteTypes}) {
    const ticketTypes = useAllTicketTypesLookup();
    const {values} = useFormikContext();
    const value = fp.getOr([], name, values);
    const handleAdd = arrayHelpers => ev => {
        console.log('Add override', arrayHelpers);
        arrayHelpers.push({
            ticketType: 'OTHER',
            noteType: fp.pick(['type', 'id'], noteTypes[0]),
        });
    };
    const handleDelete = (arrayHelpers, i) => ev => {
        arrayHelpers.remove(i);
    };
    const handleDrop =
        arrayHelpers =>
        async ({removedIndex, addedIndex}) => {
            console.log('Drop', {removedIndex, addedIndex});
            const item = arrayHelpers.remove(removedIndex);
            arrayHelpers.insert(addedIndex, item);
        };
    console.log({values, value, ticketTypes});
    return (
        <fieldset>
            <legend>Note type overrides</legend>
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <SortableList
                        dragHandleSelector='.drag-handle'
                        onDrop={handleDrop(arrayHelpers)}
                    >
                        {fp.map(
                            i => (
                                <OverrideField
                                    key={i}
                                    name={`${name}.${i}`}
                                    value={value[i]}
                                    onDelete={handleDelete(arrayHelpers, i)}
                                    ticketTypes={ticketTypes}
                                    noteTypes={noteTypes}
                                />
                            ),
                            fp.range(0, value.length),
                        )}
                        <ListItem button onClick={handleAdd(arrayHelpers)}>
                            <ListItemIcon>
                                <Add />
                            </ListItemIcon>
                            <ListItemText>New Override</ListItemText>
                        </ListItem>
                    </SortableList>
                )}
            />
        </fieldset>
    );
}
