import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles, Button, Paper} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Security, Mail} from '@material-ui/icons';
import {ducks, useApi} from '@arborian/narrf';

import BasePage from 'ccm/components/BasePage';
import {url_for} from 'ccm/routes';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {invitationActions} from 'ccm/components/MyInvitations';
import HomePageSplash from 'ccm/components/HomePageSplash';

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
        padding: 16,
        [theme.breakpoints.down('xs')]: {
            // width: 400,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 40,
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    createButton: {
        marginBottom: 40,
        padding: '16px 40px',
        fontSize: 16,
    },
    staffButton: {
        margin: 10,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(6),
        width: '100%',
    },
}));

function LoginButton() {
    const classes = useStyles();
    return (
        <Button
            component={Link}
            to={{
                pathname: url_for('login'),
                // state: {next: url_for('communicate')},
                state: {next: url_for('patients')},
            }}
            startIcon={<Security />}
            className={classes.staffButton}
            color='primary'
            variant='contained'
            size='large'
        >
            Start
        </Button>
    );
}

function StartButton() {
    const classes = useStyles();
    return (
        <Button
            component={Link}
            to={url_for('patients')}
            startIcon={<Security />}
            className={classes.staffButton}
            color='primary'
            variant='contained'
            size='large'
        >
            Start
        </Button>
    );
}

function NoGroupsAlert({user}) {
    const api = useApi();
    useEffect(() => {
        invitationActions.fetch(api, user);
    }, [api, user]);
    const rel = useSelector(ducks.jsonapi.selectRelated(user));
    const noInvitations = !rel.invitations || rel.invitations.length === 0;
    if (noInvitations) {
        return (
            <Alert severity='info'>
                <AlertTitle>No Patients Assigned</AlertTitle>
                <p>
                    To use SamePageMD, your healthcare provider must add
                    patients to your account. Please contact your healthcare
                    provider directly to request access to a patient.
                </p>
                <p>
                    If you recieved an invitation email, please ensure you have
                    registered for SamePageMD with the same email address to
                    accept the invitation.
                </p>
            </Alert>
        );
    } else {
        return (
            <>
                <Alert severity='info'>
                    <AlertTitle>Patients Available</AlertTitle>
                    <p>
                        You have pending invitations. To access patient charts,
                        please accept an invitation in your inbox.
                    </p>
                    <Button
                        variant='contained'
                        color='primary'
                        component={Link}
                        to={url_for('inbox')}
                        startIcon={<Mail />}
                    >
                        View your Inbox
                    </Button>
                </Alert>
            </>
        );
    }
}

export default function HomePage() {
    const classes = useStyles();
    const user = useSelector(selectCurrentUser);
    const userHasNoGroups =
        user && fp.isEmpty(fp.get('relationships.groups.data', user));
    const isAnonUser = !user;

    return (
        <BasePage>
            <header className={classes.header}>
                <Paper className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src='/img/SamePageMDLogo-2.png'
                        alt='Logo'
                    />
                </Paper>
                {userHasNoGroups ? (
                    <h3>Welcome to SamePageMD</h3>
                ) : (
                    <h3>Communicate with Us</h3>
                )}
            </header>
            <div className={classes.body}>
                {isAnonUser ? (
                    <LoginButton />
                ) : userHasNoGroups ? (
                    <NoGroupsAlert user={user} />
                ) : (
                    <div className={classes.contentContainer}>
                        <StartButton />
                        <HomePageSplash user={user} />
                    </div>
                )}
            </div>
        </BasePage>
    );
}
