import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

const DEFAULT_OPTIONS = {
    title: 'Prompt',
    defaultValue: '',
    prompt: 'Please provide a response.',
    inputLabel: null,
    submitLabel: 'Submit',
};

export default function PromptDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [options, setOptions] = useState(DEFAULT_OPTIONS);
    const [initialValues, setInitialValues] = useState();

    const handleSubmit = values => {
        resolve(values.value);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = (options = {}) => {
        return new Promise((resolve, reject) => {
            const realOptions = fp.merge(DEFAULT_OPTIONS, options);
            setOptions(realOptions);
            setInitialValues({value: realOptions.defaultValue});
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>{options.title}</DialogTitle>
                        <DialogContent>
                            <p>{options.prompt}</p>
                            <Field
                                component={TextField}
                                fullWidth
                                label={options.inputLabel}
                                name='value'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                {options.submitLabel}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
