import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import * as dfp from 'date-fns/fp';

import {useApi, ducks} from '@arborian/narrf';

const selectLinkages = linkages => state =>
    fp.map(
        lnk => ducks.jsonapi.selectObject([lnk.type, lnk.id])(state),
        linkages,
    );

const EMPTY = {};
const MARGIN = {days: 1};

export const useCalendarEvents = ({
    // calRef, // ref to BigCalendar component
    href, // base href to retrieve data
    fetchOptions = EMPTY, // jsonapi fetch options
    filterName, // filter[filerName] URL param
    margin = MARGIN, // something like {days: 1}
    date, // Calendar's current date
    view, // Calendar's current view
}) => {
    const api = useApi();
    const [calendar, setCalendar] = useState(null);
    const calRef = useCallback(
        cal => {
            if (cal) setCalendar(cal);
        },
        [setCalendar],
    );

    const [fetchState, setFetchState] = useState('init');
    const [linkages, setLinkages] = useState([]);

    const dateFilterValue = useMemo(() => {
        if (!calendar) return null;
        const ViewComponent = calendar.getViews()[view];
        const localizer = calendar.props.localizer;
        const range = ViewComponent.range(date, {localizer});
        if (fp.isArray(range)) {
            const start = dfp.sub(margin, range[0]);
            const end = dfp.add(margin, range[range.length - 1]);
            return {$gte: {$date: start}, $lte: {$date: end}};
        } else {
            return {$gte: {$date: range.start}, $lte: {$date: range.end}};
        }
    }, [calendar, date, view, margin]);

    const fetchEvents = useMemo(
        () => async () => {
            if (!dateFilterValue) return;
            setFetchState('busy');
            setLinkages([]);
            try {
                const fullFetchOptions = fp.pipe([
                    fp.set(['filter', filterName], dateFilterValue),
                    fp.set('page.size', 200),
                ])(fetchOptions);
                const jsonapiOptions =
                    api.jsonApiFromDataTableOptions(fullFetchOptions);
                const result = await api.fetchAllJsonApi(href, jsonapiOptions);
                setLinkages(fp.map(fp.pick(['id', 'type']), result));
                setFetchState('ready');
            } catch (e) {
                setFetchState('error');
                throw e;
            }
        },
        [api, href, fetchOptions, dateFilterValue, filterName],
    );

    useEffect(fetchEvents, [fetchEvents]);

    const events = useSelector(selectLinkages(linkages)) || [];
    return {calRef, fetchState, events};
};
