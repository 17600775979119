import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import fp from 'lodash/fp';

import {
    Card,
    CardHeader,
    CardActions,
    Button,
    makeStyles,
} from '@material-ui/core';

import {url_for} from 'ccm/routes';
import * as h from 'ccm/lib/helpers';
import PatientAvatar from 'ccm/components/PatientAvatar';
import CreateTicketIconButton from 'ccm/components/CreateTicketIconButton';

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: theme.spacing(32),
        margin: theme.spacing(2, 0),
        backgroundColor: theme.palette.cf.blue + '0c',
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(2),
        },
    },
}));

export default function PatientCard({patient}) {
    const classes = useStyles();

    const name = useMemo(() => patient.attributes.name.formatted, [patient]);
    const age = useMemo(() => h.humanAge(patient.attributes), [patient]);
    const action = useMemo(() => {
        if (fp.get('relationships.practice.data', patient)) {
            return <CreateTicketIconButton patient={patient} />;
        } else {
            return null;
        }
    }, [patient]);

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={<PatientAvatar patient={patient} />}
                // title={<strong>{name}</strong>}
                title={name}
                subheader={
                    <span>
                        Age: <strong>{age}</strong>&nbsp; Gender:{' '}
                        <strong>
                            {fp.get('attributes.pcc.gender', patient)}
                        </strong>
                    </span>
                }
                action={action}
            />
            <CardActions>
                <Button
                    to={url_for('patient', {patientId: patient.id})}
                    component={Link}
                    fullWidth
                    color='primary'
                    variant='contained'
                >
                    View Details
                </Button>
            </CardActions>
        </Card>
    );
}
