import React from 'react';
import Tour from './tour_base';
import TourChart from './tour_chart';
import TourVitalHistory from './tour_vital_history';
import TourUserProfile from './tour_profile';
import TourTicketDashboard from './tour_tickdash';
import TourPractice from './tour_practice';
import TourAttachPage from './tour_attachpage';
import TourIndivTick from './tour_indivtick';

const tours = [
    TourChart,
    TourVitalHistory,
    TourUserProfile,
    TourTicketDashboard,
    TourPractice,
    TourAttachPage,
    TourIndivTick,
];
export const releaseNotes = new Tour({
    id: '20240211',
    steps: [
        {
            // TODO: change link to actual release notes
            content: () => (
                <>
                    <p>
                        See the latest release notes in our{' '}
                        <a
                            href='https://samepagemd.zendesk.com/hc/en-us/articles/11483871652883'
                            target='_blank'
                            rel='noreferrer'
                        >
                            KnowledgeBase
                        </a>
                    </p>
                </>
            ),
        },
    ],
});

export default tours;
