import React from 'react';
import {useSelector} from 'react-redux';

import {Tooltip, Chip} from '@material-ui/core';
import {LocalHospital} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

import {useGlobals} from 'ccm/components/Globals';

export default function PrimaryPracticeChip({value}) {
    const globals = useGlobals();
    globals.fetchAll('practice.PracticeCollection');
    const practice = useSelector(
        ducks.jsonapi.selectObject(['Practice', value]),
    );
    return (
        <Tooltip title='Primary Practice'>
            <Chip
                icon={<LocalHospital />}
                label={
                    practice ? practice.attributes.label : 'No Primary Practice'
                }
            />
        </Tooltip>
    );
}
