import React, {useState, useMemo, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import UriTemplate from 'uritemplate';

import {
    IconButton,
    Badge,
    Menu,
    MenuItem,
    ListItemText,
} from '@material-ui/core';

import {HelpOutline} from '@material-ui/icons';
import {useTour} from '@reactour/tour';

import {useApi} from '@arborian/narrf';
import {useDialog} from 'ccm/components/dialogs';

import {useFeatures} from 'ccm/lib/util';
import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import tours from './tours';
import ReleaseNotesDialog from './ReleaseNotesDialog';

function TourMenuItem({tour, onClick}) {
    return (
        <MenuItem id={`tour-${tour.tour.id}`} onClick={onClick}>
            <Badge
                color='secondary'
                variant='dot'
                overlap='rectangular'
                invisible={tour.seen}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
            >
                {tour.tour.label}
            </Badge>
        </MenuItem>
    );
}

export const useReleaseNotes = () => {
    const api = useApi();
    const [releaseNotes, setReleaseNotes] = useState({
        metadata: {latestVersion: ''},
        releaseNotes: '',
    });
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        const fetchReleaseNotes = async () => {
            const value = await api.fetchJson(
                api.url_for('release_notes.root'),
            );
            setReleaseNotes(value);
        };
        fetchReleaseNotes();
    }, [user, api]);
    return releaseNotes;
};

export default function TourMenu() {
    const api = useApi();
    const features = useFeatures();
    const releaseNotes = useReleaseNotes();
    const releaseNotesDialog = useDialog(ReleaseNotesDialog);
    const [anchorEl, setAnchorEl] = useState(null);
    const location = useLocation();
    const user = useSelector(selectCurrentUser);
    const {setSteps, setCurrentStep, setIsOpen} = useTour();

    const latestSeen = fp.get('metadata.latest_version', releaseNotes);
    const latestReleaseNotes = fp.get(
        'attributes.latest_release_notes_seen',
        user,
    );
    const seenTours = fp.get('attributes.tours_completed', user);
    const newReleaseNotes = latestSeen > latestReleaseNotes;

    const handleReleaseNotesViewed = useCallback(() => {
        if (newReleaseNotes) {
            api.fetchJson(fp.get('links.releaseNotes', user), {
                method: 'PATCH',
                json: {latestSeen: latestSeen},
            });
        }
    }, [newReleaseNotes, latestSeen, user, api]);

    const displayReleaseNotes = useCallback(() => {
        releaseNotesDialog(releaseNotes);
        handleReleaseNotesViewed();
    }, [releaseNotesDialog, handleReleaseNotesViewed, releaseNotes]);

    const startTour = tour => {
        setSteps(tour.steps);
        setCurrentStep(0);
        setIsOpen(true);
        setAnchorEl(null);
        const tpl = fp.get('links.tours', user);
        const url = UriTemplate.parse(tpl).expand({tour_id: tour.id});

        api.fetchJson(url, {method: 'PATCH', json: {seen: true}});
    };

    useEffect(() => {
        if (newReleaseNotes) {
            displayReleaseNotes();
        }
    }, [newReleaseNotes, displayReleaseNotes]);

    // Using user and location, show context-relevant tours only.
    // Also indicate whether the tour has been seen or not
    const availableTours = useMemo(() => {
        return fp.pipe([
            fp.filter(t => location.pathname.match(t.location_context)),
            fp.filter(t => {
                const matchingGroupIds = fp.pipe([
                    fp.get('relationships.groups.data'),
                    fp.map('id'),
                    fp.filter(gid => gid.match(t.group_context)),
                ])(user);
                return !fp.isEmpty(matchingGroupIds);
            }),
            fp.map(tour => {
                const seen = !!fp.find(s => s === tour.id, seenTours);
                return {tour, seen};
            }),
        ])(tours);
    }, [location, user, seenTours]);

    const someToursNew = fp.any(at => !at.seen, availableTours);
    if (!fp.get('product_tour', features)) return null;
    return (
        <>
            <IconButton
                id='start-tour'
                edge='end'
                onClick={ev => setAnchorEl(ev.currentTarget)}
            >
                <Badge
                    variant='dot'
                    overlap='rectangular'
                    color='secondary'
                    invisible={!newReleaseNotes && !someToursNew}
                >
                    <HelpOutline />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={ev => setAnchorEl(null)}
            >
                <MenuItem
                    id='whats-new'
                    key='whats-new'
                    onClick={ev => displayReleaseNotes()}
                >
                    <Badge
                        color='secondary'
                        overlap='rectangular'
                        variant='dot'
                        invisible={!newReleaseNotes}
                        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                    >
                        <ListItemText>See what's new</ListItemText>
                    </Badge>
                </MenuItem>
                {fp.map(
                    tour => (
                        <TourMenuItem
                            key={tour.tour.id}
                            tour={tour}
                            onClick={ev => startTour(tour.tour)}
                        />
                    ),
                    availableTours,
                )}
            </Menu>
        </>
    );
}
