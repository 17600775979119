import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

export default function PatientEHRComponent({row}) {
    const ehr_id = fp.get('relationships.ehr_connection.data.id', row.data);
    const ehr = useSelector(
        ducks.jsonapi.selectObject(['EHRConnection', ehr_id]),
    );
    return <span>{fp.get('attributes.label', ehr)}</span>;
}
