import React from 'react';
import {useParams} from 'react-router-dom';

import BasePage from 'ccm/components/BasePage';

import PracticeManagement from 'ccm/components/PracticeManagement';

export default function PracticePage() {
    const {practiceId} = useParams();
    return (
        <BasePage wide title='Practice Management'>
            <PracticeManagement practiceId={practiceId} />
        </BasePage>
    );
}
