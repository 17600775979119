import React, {useState, useEffect} from 'react';
import {Field} from 'formik';
import fp from 'lodash/fp';

import {TextField, FormHelperText, makeStyles} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import ImageMapper from 'react-img-mapper';

import {fieldId} from 'ccm/lib/util';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
}));

function ImageMap({
    form,
    field, // Formik
    label,
    caption,
    options,
    select,
    required,
    multiple,
    image,
    areas,
}) {
    const classes = useStyles();
    const [fieldValue, setFieldValue] = useState(field.value || []);

    useEffect(() => {
        setFieldValue(field.value || []);
    }, [field.value]);

    let img = null;
    if (image) {
        try {
            const images = require.context('../../images', true);
            img = images('./' + image);
        } catch (e) {
            console.error('Error getting image', e);
        }
    }

    let [areaName, setAreaName] = useState(field.value || caption || '');
    const handleEnterArea = area => {
        setAreaName(area.value);
    };
    const handleLeaveArea = area => {
        setAreaName(caption);
    };
    const renderInput = props => (
        <TextField label={label} fullWidth={true} {...props} />
    );

    const onChange = (ev, value, reason) => {
        form.setFieldValue(field.name, value);
    };
    const getWidth = img => {
        return img.naturalWidth;
    };
    const getHeight = img => {
        return img.naturalHeight;
    };

    return (
        <>
            <div className={classes.root}>
                {areaName ? <FormHelperText>{areaName}</FormHelperText> : null}
                <ImageMapper
                    src={img}
                    width={getWidth}
                    height={getHeight}
                    map={{name: 'imgMap', areas}}
                    onMouseEnter={handleEnterArea}
                    onMouseLeave={handleLeaveArea}
                    onClick={ev =>
                        onChange(
                            ev,
                            fp.uniq([...field.value, ev.value]),
                            'click',
                        )
                    }
                />
                <Autocomplete
                    freeSolo
                    autoSelect
                    id={fieldId('dyn', field.name)}
                    name={field.name}
                    label={label}
                    options={[]}
                    required={required}
                    value={fieldValue}
                    renderInput={renderInput}
                    onChange={onChange}
                    multiple
                    fullWidth
                />
            </div>
        </>
    );
}

const DynaformField = ({field}) => {
    return (
        <Field
            component={ImageMap}
            name={field.id}
            label={field.label}
            caption={field.caption}
            select={field.select}
            required={field.required}
            multiple={field.multiple}
            image={field.image}
            areas={field.areas}
        />
    );
};
export default DynaformField;
