import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {FabricJSCanvas} from 'fabricjs-react';
import {fabric} from 'fabric';

import useStyles from './styles';
import {useEditorContext} from './context';
import {keyDown} from './actions';

const TEXT_SCALE_PCT = 0.03;
const MIN_FONT_SIZE = 20;
const VERTICAL_CONTROLS_HEIGHT = 60;

const translateEventCoords = event => {
    var clientX = event.clientX;
    var clientY = event.clientY;
    if (event.type === 'touchmove') {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
    }
    return {x: clientX, y: clientY};
};

const addCheckmark = (event, canvas) => {
    console.log('check', event);
    const element = new fabric.Path(
        // Checkmark path
        'M 125 150 L 150 175 L 200 125 L 150 175 z',
    );
    element.set({strokeWidth: 20, stroke: 'black'});
    const scaleValue = 0.3;
    element.scale(scaleValue);
    element.set(findCenterCoords(element, event, canvas));
    canvas.add(element);
};
const addSignature = (event, canvas, signatureSrc) => {
    console.log('Adding signature', signatureSrc);
    fabric.Image.fromURL(
        signatureSrc,
        function (element) {
            // Scale signature to match document size
            const signatureScalePct = 0.3;
            const desiredWidth =
                canvas.backgroundImage.width * signatureScalePct;
            const scaleRatio = desiredWidth / element.width;
            element.set({scaleX: scaleRatio, scaleY: scaleRatio});
            // Add signature object to canvas
            element.set(findCenterCoords(element, event, canvas));
            canvas.add(element);
        },
        {crossOrigin: 'Anonymous'},
    );
};
const addDate = (event, canvas) => {
    const fontSize = fp.max([
        canvas.backgroundImage.width * TEXT_SCALE_PCT,
        MIN_FONT_SIZE,
    ]);
    const today = new Date();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const year = today.getFullYear();
    const element = new fabric.Textbox(`${month}/${date}/${year}`, {
        editable: true,
        fontSize: fontSize,
        width: 80,
    });
    element.set(findCenterCoords(element, event, canvas));
    console.log('OBJECT', canvas.toDatalessObject());
    element.hiddenTextareaContainer = canvas.lowerCanvasEl.parentNode;
    canvas.add(element);
};
const addTextbox = (event, canvas) => {
    const fontSize = fp.max([
        canvas.backgroundImage.width * TEXT_SCALE_PCT,
        MIN_FONT_SIZE,
    ]);
    const element = new fabric.Textbox('ADD TEXT', {
        fontSize: fontSize,
        // width: 150,
        editable: true,
    });
    element.set(findCenterCoords(element, event, canvas));
    element.hiddenTextareaContainer = canvas.lowerCanvasEl.parentNode;
    canvas.add(element);
    // Enter editing mode
    const obj = fp.last(canvas.getObjects('textbox'));
    obj.enterEditing();
    obj.selectAll();
    return obj;
};
const findClickCoords = (event, canvas) => {
    const pointer = canvas.getPointer(event);
    return {left: pointer.x, top: pointer.y};
};

const findCenterCoords = (element, event, canvas) => {
    // Supports scaled imgs also
    const clickLoc = findClickCoords(event, canvas);
    const left = clickLoc.left - element.getScaledWidth() / 2;
    const top = clickLoc.top - element.getScaledHeight() / 2;
    return {left, top};
};

const initializeCanvas = async (canvas, src, annotations) => {
    canvas.fireRightClick = true;
    const toRemove = canvas.getObjects();
    if (toRemove.length) {
        console.log('Removing objects', toRemove);
        canvas.remove(...toRemove);
    }
    try {
        if (!!annotations) {
            const res = await loadAnnotations(canvas, annotations);
            console.log('Loaded annotations', res);
        }
    } catch (e) {
        console.error('Error loading annotations', e);
    }
    try {
        const res = await setBackgroundImage(canvas, src);
        console.log('Set bg img', src, res);
    } catch (e) {
        console.error('Error loading bg image', e);
    }
    try {
        const res = setCanvasZoom(canvas);
        console.log('Set canvas zoom', res);
    } catch (e) {
        console.error('Error setting canvas zoom', e);
    }
    canvas.renderAll();
};

const loadAnnotations = (canvas, annotations) => {
    console.log('promising to load annotations');
    return new Promise((resolve, reject) => {
        console.log('o promises');
        canvas.loadFromJSON(annotations, resolve);
    });
};

const setBackgroundImage = (canvas, src) => {
    return new Promise((resolve, reject) => {
        canvas.setBackgroundImage(src, resolve, {crossOrigin: 'anonymous'});
    });
};

const setCanvasZoom = canvas => {
    const controlsHeight = VERTICAL_CONTROLS_HEIGHT;
    const canvasMaxHeight = window.innerHeight - controlsHeight;
    const canvasMaxWidth = window.innerWidth;
    const canvasWidth = Math.min(canvas.backgroundImage.width, canvasMaxWidth);
    const canvasHeight = Math.min(
        canvas.backgroundImage.height,
        canvasMaxHeight,
    );
    canvas.setWidth(canvasWidth);
    canvas.setHeight(canvasHeight);

    // Zoom out the canvas if image size is too large.
    if (canvas.backgroundImage.width > canvasWidth) {
        let zoomOutValue = canvasWidth / canvas.backgroundImage.width;
        canvas.setZoom(zoomOutValue);
    }
    canvas.calcOffset();
    canvas.renderAll();
};

export default function Canvas({src, annotations, signatureSrc, ...props}) {
    const classes = useStyles();
    const [transformXY, setTransformXY] = useState([0, 0]);
    const [pinchZoomDistance, setPinchZoomDistance] = useState(0);
    const ctx = useEditorContext();
    const {canvas, onReady, clickMode, setClickMode, resetClickMode} = ctx;

    const handleReady = (...args) => {
        console.log('FabricJS Canvas onReady called with', args);
        const res = onReady(...args);
        console.log('FabricJS Canvas onReady returned', res);
        return res;
    };

    useEffect(() => {
        if (!canvas || !src) return;
        console.log('Initialize canvas', {
            src,
            annotations,
            canvas,
        });
        resetClickMode();
        initializeCanvas(canvas, src, annotations);
        return () => {
            console.log('Unmount canvas', canvas);
        };
    }, [canvas, annotations, src, resetClickMode]);

    const validTranslation = vpt => !isNaN(vpt[4]) && !isNaN(vpt[5]);

    const validViewportTransform = () => {
        /**
         * Canvas returns NaN translation values when starting touch events,
         * which breaks panning.
         * We store the most recent (valid) translation coords, and substitute
         * them for any NaN values recieved.
         */
        var vpt = canvas.viewportTransform;
        if (validTranslation(vpt)) {
            var transX = vpt[4];
            var transY = vpt[5];
            // Store the valid translation
            setTransformXY([transX, transY]);
        } else {
            // Use previously stored translation
            [transX, transY] = transformXY;
        }
        vpt[4] = transX;
        vpt[5] = transY;
        return vpt;
    };

    const handleStartPanning = event => {
        event.preventDefault();
        if (event.button !== 0 && event.type !== 'touchstart') return; // Prevent right click panning
        if (clickMode) return;
        const obj = canvas.findTarget(event);
        if (!obj) {
            // Start panning when user clicks on open canvas area
            const coords = translateEventCoords(event);
            setClickMode({mode: 'panning', coords});
            // setPanningEnabled([coords.x, coords.y]);
            canvas.isDragging = true;
            canvas.selection = false;
        }
    };

    const handlePanning = event => {
        event.preventDefault();
        if (!clickMode || clickMode.mode !== 'panning') return;
        const {x, y} = clickMode.coords;
        // const [lastPosX, lastPosY] = panningEnabled;
        const coords = translateEventCoords(event);
        var vpt = validViewportTransform();
        vpt[4] += coords.x - x; // Translate X
        vpt[5] += coords.y - y; // Translate Y
        canvas.requestRenderAll();
        setClickMode({mode: 'panning', coords});
    };

    const handleStopPanning = event => {
        event.preventDefault();
        resetClickMode();
        canvas.setViewportTransform(canvas.viewportTransform);
        canvas.isDragging = false;
        canvas.selection = true;
    };

    const handleZoomWheel = event => {
        event.preventDefault();
        event.stopPropagation();
        const delta = event.deltaY;
        const zoom = canvas.getZoom();
        let zoomDelta = zoom * 0.999 ** delta;
        // Recommended zoom constraints:
        if (zoomDelta > 20) zoomDelta = 20;
        if (zoomDelta < 0.1) zoomDelta = 0.1;
        // Focus zooming on cursor location:
        const pointerLoc = canvas.getPointer(event, {ignoreZoom: true});
        canvas.zoomToPoint(pointerLoc, zoomDelta);
    };

    const handlePinchZoom = event => {
        const currentZoom = canvas.getZoom();
        const x = [event.touches[0].clientX, event.touches[1].clientX];
        const y = [event.touches[0].clientY, event.touches[1].clientY];
        var midpoint = new fabric.Point(fp.mean(x), fp.mean(y));

        // Determine pinch direction and distance
        const a = x[0] - x[1];
        const b = y[0] - y[1];
        const distance = Math.hypot(a, b);
        const zoomSpeed = 10;
        const distanceDelta = (pinchZoomDistance - distance) * zoomSpeed;
        setPinchZoomDistance(distance);

        let zoomDelta = currentZoom * 0.999 ** distanceDelta;
        // Recommended zoom constraints:
        if (zoomDelta > 20) zoomDelta = 20;
        if (zoomDelta < 0.1) zoomDelta = 0.1;
        canvas.zoomToPoint(midpoint, zoomDelta);
    };

    const handleContextMenu = event => {
        event.stopPropagation();
        event.preventDefault();
        const obj = canvas.findTarget(event);
        if (!obj) {
            resetClickMode();
            return;
        }
        canvas.setActiveObject(obj);
        canvas.renderAll();
        setClickMode({
            mode: 'contextMenu',
            location: {
                left: event.clientX,
                top: event.clientY,
            },
        });
    };

    const handleTouchStart = event => {
        event.stopPropagation();
        event.preventDefault();
        if (event.touches.length === 1) {
            handleStartPanning(event);
        } else {
            handlePinchZoom(event);
        }
    };

    const handleTouchMove = event => {
        event.preventDefault();
        if (event.touches.length === 1) {
            handlePanning(event);
        } else {
            event.stopPropagation(); // Prevent fabricJS selector
            handlePinchZoom(event);
        }
    };

    const handleCanvasClick = event => {
        if (!clickMode) return;
        const {mode, ...detail} = clickMode;
        const clickedObj = canvas.findTarget(event);
        switch (mode) {
            case 'panning':
                handleStopPanning(event);
                break;
            case 'checking':
                addCheckmark(event, canvas);
                break;
            case 'signing':
                addSignature(event, canvas, signatureSrc);
                resetClickMode();
                break;
            case 'texting':
                resetClickMode();
                const obj = addTextbox(event, canvas);
                setClickMode({
                    mode: 'editingText',
                    obj: obj,
                });
                break;
            case 'dating':
                addDate(event, canvas);
                resetClickMode();
                break;
            case 'contextMenu':
                resetClickMode();
                break;
            case 'editingText':
                console.log('Click while editing text', clickMode);
                if (clickedObj !== detail.obj) {
                    resetClickMode();
                    detail.obj.exitEditing();
                }
                break;
            default:
                resetClickMode();
                break;
        }
    };

    return (
        <div
            className={classes.canvas}
            onClick={handleCanvasClick}
            onMouseDown={handleStartPanning}
            onMouseMove={handlePanning}
            onWheel={handleZoomWheel}
            onContextMenu={handleContextMenu}
            // Touch Events
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleCanvasClick}
            // Keyboard -- in dialogs this doesn't work, so
            // you need to attach an onKeyDown in the dialog
            // itself.
            onKeyDown={keyDown(ctx)}
            {...props}
        >
            <FabricJSCanvas onReady={handleReady} />
        </div>
    );
}
