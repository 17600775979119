import Tour from './tour_base';

const TourTicketDashboard = new Tour({
    id: 'ticketDashboard',
    label: 'Tour the Ticket Dashboard',
    location_context: /ticket$/,
    group_context: /.*/,
    steps: [
        {
            selector: '#status_filter',
            content: `
            By clicking to toggle these buttons, you can
            filter tickets based on their status.
            `,
        },
        {
            selector: '#statusStatus',
            content: `
            You can click this button to toggle between
            tickets that are Pending/Resolved,
            Pending only, Resolved only,
            or Acknowledged.
            `,
        },
        {
            selector: '#progressNoteStatus',
            content: `
            You can click this button to toggle between
            tickets that have NO uploaded progress notes,
            tickets with SOME uploaded progress notes,
            or tickets with ALL uploaded progress notes.
            Clicking to a gray button turns off filtering
            based on progress notes.
            `,
        },
        {
            selector: '#archivedStatus',
            content: `
            You can click this button to toggle between
            tickets that are active or tickets that are
            resolved.
            `,
        },
        {
            selector: '#faxStatus',
            content: `
            You can click this button to toggle between
            tickets with an unknown fax status, tickets
            for which no faxes have been sent, tickets
            for which some faxes have been sent, or tickets
            for which all faxes have been sent. Clicking
            to a gray button turns off all filtering based
            on fax status.
            `,
        },
    ],
});
export default TourTicketDashboard;
