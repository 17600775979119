import React, {useState} from 'react';

import {
    AppBar,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    Toolbar,
    Tooltip,
    makeStyles,
} from '@material-ui/core';
import {Close, Save, ArrowRightAlt} from '@material-ui/icons';
import fp from 'lodash/fp';

import {useCurrentUser, useUserSignatureBlob} from 'ccm/dataSource/misc';
import ImageEditorProvider, {
    Canvas,
    ContextMenu,
    buttons,
    actions,
    useEditorContext,
} from 'ccm/components/ImageEditor';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiAppBar-root': {
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialogActions-root': {
            // Vertical Toolbar
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'end',
            gap: 4,
            position: 'fixed',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        },
    },
    iconContainer: {
        position: 'relative',
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    smallIcon: {
        position: 'absolute',
        width: theme.spacing(2),
        height: theme.spacing(2),
    },
}));

function SaveNext() {
    const classes = useStyles();
    const topLeft = {top: 0, left: 0};
    const bottomRight = {bottom: 0, right: 0};
    return (
        <div className={classes.iconContainer}>
            <Save className={classes.smallIcon} style={topLeft} />
            <ArrowRightAlt className={classes.smallIcon} style={bottomRight} />
        </div>
    );
}

export function AttachmentImageEditorDialog({dialogRef, src, annotations}) {
    const classes = useStyles();
    const [resolve, setResolve] = useState(null);
    const curUser = useCurrentUser();
    const signatureBlob = useUserSignatureBlob(curUser);
    const signatureSrc = fp.get(
        'relationships.content.links.related',
        signatureBlob,
    );
    const ctx = useEditorContext();

    const handleClose = () => {
        resolve({action: 'CANCEL'});
        setResolve(null);
    };

    const handleSave = (blob, cObj, rotatedBlob) => {
        resolve({action: 'SAVE_CLOSE', blob, cObj, rotatedBlob});
        setResolve(null);
    };

    const handleSaveNext = (blob, cObj, rotatedBlob) => {
        resolve({action: 'SAVE_NEXT', blob, cObj, rotatedBlob});
        // setResolve(null);  Do not close dialog
    };

    dialogRef.current = () => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog
            open={!!resolve}
            fullScreen
            // tabindex='0'
            disableEnforceFocus={true}
            className={classes.root}
            onKeyDown={actions.keyDown(ctx)}
            onClose={handleClose}
        >
            <AppBar>
                <Toolbar>
                    <Tooltip title='Cancel/discard changes'>
                        <IconButton edge='start' onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {src && (
                    <Canvas
                        src={src}
                        signatureSrc={signatureSrc}
                        annotations={annotations}
                    />
                )}
                <ContextMenu />
            </DialogContent>
            <DialogActions>
                <buttons.TrashButton />
                <buttons.BrushButton />
                <buttons.EraserButton />
                <buttons.TextButton />
                <buttons.DateButton />
                <buttons.CheckButton />
                <buttons.SignatureButton />
                <buttons.RotateLeftButton />
                <buttons.RotateRightButton />
                <buttons.SaveButton
                    title='Save and close image'
                    onSave={handleSave}
                />
                <buttons.SaveButton
                    title='Save and next'
                    onSave={handleSaveNext}
                    icon={<SaveNext />}
                />
            </DialogActions>
        </Dialog>
    );
}

export default function AttachmentImageEditorDialogWrapper(props) {
    return (
        <ImageEditorProvider>
            <AttachmentImageEditorDialog {...props} />
        </ImageEditorProvider>
    );
}
