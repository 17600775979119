import React, {
    createContext,
    useEffect,
    useContext,
    useState,
    useMemo,
} from 'react';
import fp from 'lodash/fp';

import TableManager from './tableManager';

const Context = createContext();

export const useTableManager = () => useContext(Context);
const DEFAULT_OPTIONS = {
    filtering: false,
    sorting: true,
    paging: true,
    selection: false,
    search: false,
    pageSizeOptions: [5, 10, 20],
    actionsColumnIndex: 0,
    addRowAtBottom: true,
};

export default function Provider({
    children,
    dataSource,
    options = {},
    editable = {},
    tableRef = null,
    id = undefined,
}) {
    options = fp.merge(DEFAULT_OPTIONS, options);

    const [columns, setColumns] = useState({});
    const [actions, setActions] = useState({});
    const [detailPanels, setDetailPanels] = useState({});
    const [editState, setEditState] = useState({op: null, row: null});
    useEffect(() => {
        if (dataSource.state === 'init') {
            dataSource.fetch();
        }
    }, [dataSource.state, dataSource.fetch]);

    const tm = useMemo(
        () =>
            new TableManager({
                dataSource,
                options,
                editable,
                columns,
                setColumns,
                actions,
                setActions,
                detailPanels,
                setDetailPanels,
                editState,
                setEditState,
                id,
            }),
        [
            dataSource,
            options,
            editable,
            columns,
            setColumns,
            actions,
            setActions,
            detailPanels,
            setDetailPanels,
            editState,
            setEditState,
            id,
        ],
    );
    if (tableRef) {
        tableRef.current = tm;
    }
    return <Context.Provider value={tm}>{children}</Context.Provider>;
}
