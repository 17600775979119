import React from 'react';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {Button} from '@material-ui/core';

import {fieldId} from 'ccm/lib/util';

const initialValues = {
    username: '',
    password: '',
};

export default function PasswordLogin({onSubmit}) {
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({touched, values, errors}) => (
                <Form>
                    <Field
                        component={TextField}
                        id={fieldId('passwordLogin', 'username')}
                        label='Email'
                        name='username'
                        fullWidth
                    />
                    <Field
                        component={TextField}
                        type='password'
                        id={fieldId('passwordLogin', 'password')}
                        label='Password'
                        name='password'
                        fullWidth
                    />
                    <Button
                        id={fieldId('passwordLogin', 'submit')}
                        type='submit'
                        variant='contained'
                        color='primary'
                    >
                        Sign in with password
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
