import React, {useMemo} from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {useFetchedRelationshipReverse} from 'ccm/dataSource/misc';
import {useDialog} from 'ccm/components/dialogs';
import {useRelated} from 'ccm/dataSource';
import AutocompleteDialog from 'ccm/components/dialogs/AutocompleteDialog';
import LinkSelect from './LinkSelect';

const {dt2} = components;

const PFVISITTYPE_DEFAULT_FETCH_OPTIONS = {
    sort: {field: 'attributes.data.name', direction: 'asc'},
    page: {number: 0, size: 20},
};

const useLookup = (items, key) => {
    return useMemo(
        () =>
            fp.pipe([fp.map(item => [fp.get(key, item), item]), fp.fromPairs])(
                items,
            ),
        [items, key],
    );
};

export default function PFVisitTypeMapping({practice, ehr, config}) {
    const ds = useRelated(config, 'appointmentTypes', {
        prefix: 'pfAptType.',
        initialFetchOptions: PFVISITTYPE_DEFAULT_FETCH_OPTIONS,
    });
    const visitTypes = useFetchedRelationshipReverse(
        practice,
        'visitTypes',
        'VisitType',
        'practice',
    );
    const visitTypesLookup = useLookup(visitTypes, 'id');
    const refresh = ev => ds.fetch();
    const dialogProps = useMemo(
        () => ({
            title: 'Select Visit Type',
            label: 'Visit Type',
            options: visitTypes,
            getOptionLabel: fp.get('attributes.name'),
        }),
        [visitTypes],
    );
    const dialog = useDialog(AutocompleteDialog, dialogProps);
    const getSelection = async () => {
        const {action, value} = await dialog();
        console.log('Dialog returned', {action, value});
        return {action, spData: value};
    };
    const getLabel = vtId =>
        fp.get([vtId, 'attributes', 'name'], visitTypesLookup);
    console.log('visitTypes', {visitTypesLookup, ds});
    return (
        <div>
            <dt2.DataTable options={{filtering: true}} dataSource={ds}>
                <dt2.Column title='PF Name' field='attributes.data.name' />
                <dt2.Column
                    id='col-visit-type'
                    title='SP Visit Type'
                    filtering={false}
                    render={row => (
                        <LinkSelect
                            pfData={row.data}
                            linkType='visit_type'
                            getSelection={getSelection}
                            getLabel={getLabel}
                        />
                    )}
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </dt2.DataTable>
        </div>
    );
}
