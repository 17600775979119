import React from 'react';
import {Formik, Form, Field} from 'formik';
import * as yup from 'yup';

import {CheckboxWithLabel, TextField} from 'formik-material-ui';

import {Button} from '@material-ui/core';

const validationSchema = yup.object().shape({
    email: yup.string().required().email(),
    receive_satisfaction_surveys: yup.bool(),
});

export default function PatientPreferencesForm({
    preferences,
    readOnly,
    onSubmit,
}) {
    return (
        <Formik
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={preferences}
            validationSchema={validationSchema}
        >
            {({touched, values, errors, handleSubmit}) => (
                <Form>
                    <Field
                        component={TextField}
                        label='Email address'
                        name='email'
                        readOnly={readOnly}
                        fullWidth
                    />
                    <Field
                        component={CheckboxWithLabel}
                        type='checkbox'
                        Label={{label: 'Receive satisfaction surveys'}}
                        name='receive_satisfaction_surveys'
                        readOnly={readOnly}
                        fullWidth
                    />
                    <br />
                    <br />

                    <Button
                        disabled={readOnly}
                        type='submit'
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                    >
                        Update preferences
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
