import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField as MUITextField,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {Autocomplete} from 'formik-material-ui-lab';

import {useApi} from '@arborian/narrf';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import {fieldId} from 'ccm/lib/util';

export default function UnlistedPatientDialog({dialogRef}) {
    const api = useApi();
    const user = useSelector(selectCurrentUser);
    const [resolve, setResolve] = useState(null);
    const [practices, setPractices] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const available_facs_url = fp.get(
        'relationships.available_facilities.links.related',
        user,
    );
    const available_pracs_url = fp.get(
        'relationships.available_practices.links.related',
        user,
    );

    useEffect(() => {
        if (!user || !resolve) return;
        const promises = [
            api.fetchAllJsonApi(available_facs_url),
            api.fetchAllJsonApi(available_pracs_url),
        ];
        Promise.all(promises).then(responses => {
            const [r_facs, r_pracs] = responses;
            setPractices(r_pracs);
            setFacilities(r_facs);
        });
    }, [
        api,
        user,
        resolve,
        available_facs_url,
        available_pracs_url,
        setPractices,
        setFacilities,
    ]);
    const initialValues = {};

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = () => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({touched, values, errors}) => (
                    <Form>
                        <DialogTitle>Create Ticket</DialogTitle>
                        <DialogContent>
                            <p>
                                Please select the patient's facility and
                                physician:
                            </p>
                            <Field
                                component={Autocomplete}
                                id={fieldId('unlistedPatient', 'facility')}
                                label='Facility'
                                name='facility'
                                options={facilities}
                                getOptionLabel={fp.get('attributes.name')}
                                renderInput={props => (
                                    <MUITextField {...props} label='Facility' />
                                )}
                                fullWidth
                            />
                            <Field
                                component={Autocomplete}
                                id={fieldId('unlistedPatient', 'practice')}
                                label='Physician'
                                name='practice'
                                options={practices}
                                getOptionLabel={fp.get('attributes.name')}
                                renderInput={props => (
                                    <MUITextField
                                        {...props}
                                        label='Physician'
                                    />
                                )}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Create ticket
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
