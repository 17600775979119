import React from 'react';
import fp from 'lodash/fp';
import downloadjs from 'downloadjs';

import {components} from '@arborian/narrf';
import {useDiagnosticReports} from 'ccm/dataSource';

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

const {dt2} = components;

function TestPanel({panelName, results}) {
    return (
        <div>
            <h6>{panelName}</h6>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Observation</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Reference</TableCell>
                        <TableCell>Comment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fp.map(
                        result => (
                            <TableRow key={result.code}>
                                <TableCell>{result.code.text}</TableCell>
                                <TableCell>
                                    {result.value.valueString}
                                </TableCell>
                                <TableCell>{result.referenceRange}</TableCell>
                                <TableCell>{result.comment}</TableCell>
                            </TableRow>
                        ),
                        results,
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

function TestSet({testSet}) {
    return (
        <>
            {fp.pipe([
                fp.toPairs,
                fp.map(([i, test]) => <TestPanel key={i} {...test} />),
            ])(testSet)}
        </>
    );
}

export default function DiagnosticReportTable({title, patient, ...props}) {
    const ds = useDiagnosticReports(patient);
    const refresh = () => ds.fetch();

    const downloadFile = (ev, row) => {
        downloadjs(fp.get('data.links.download', row));
    };
    return (
        <dt2.DataTable
            dataSource={ds}
            size='small'
            options={{
                search: true,
                filtering: true,
            }}
            title={title}
            {...props}
        >
            <dt2.Column title='Name' field='attributes.code.text' />
            <dt2.Column title='Status' field='attributes.status' />
            <dt2.Column
                title='Effective'
                field='attributes.effectiveDateTime'
                type='date'
            />
            <dt2.DetailPanel
                render={row => (
                    <TestSet testSet={row.data.attributes.testSet} />
                )}
            />
            <dt2.Action
                icon='cloud_download'
                tooltip='Download file'
                onClick={downloadFile}
            >
                {(row, props) => ({
                    ...props,
                    disabled: !fp.get('data.links.download', row),
                })}
            </dt2.Action>
            <dt2.Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
