import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import * as h from 'ccm/lib/helpers';
import {useListField} from 'ccm/dataSource';

import {useDialog} from 'ccm/components/dialogs';
import PromptDialog from 'ccm/components/dialogs/PromptDialog';

const {dt2} = components;

export default function NotePatientsTable({
    patients,
    setPatients,
    defaultChiefComplaint,
}) {
    const prompt = useDialog(PromptDialog);
    const patientsList = useListField(patients, (...args) => {
        console.log('List change', args);
    });

    const handleSetChiefComplaint = async (ev, selection) => {
        const answer = await prompt({
            title: 'Set chief complaint',
            defaultValue: defaultChiefComplaint,
            prompt: '',
            submitLabel: 'Update',
        });
        if (!answer) return;
        console.log('handleSetChiefComplaint', {selection, answer});
        const updates = fp.pipe([
            fp.map(row => [row.id, fp.set('chiefComplaint', answer, row.data)]),
            fp.fromPairs,
        ])(selection);
        console.log('Updates', updates);
        const newPatients = patients.map(p => fp.get(p.id, updates) || p);
        setPatients(newPatients);
    };

    const handleDeleteRows = async (ev, selection) => {
        const deletions = fp.pipe([
            fp.map(row => [row.id, true]),
            fp.fromPairs,
        ])(selection);
        const newPatients = patients.filter(p => !fp.get(p.id, deletions));
        setPatients(newPatients);
    };
    return (
        <dt2.DataTable
            size='small'
            dataSource={patientsList}
            title='Selected Patients'
            options={{
                selection: true,
            }}
        >
            <dt2.Column id='name' title='Name' field='name' />
            <dt2.Column id='id' title='ID' field='id' />
            <dt2.Column
                id='dob'
                editable={false}
                title='DOB'
                field='dob'
                type='date'
                render={row => h.formatDateOnly(fp.get('data.dob', row))}
            />
            <dt2.Column
                id='chiefComplaint'
                title='Chief Complaint'
                field='chiefComplaint'
            />
            <dt2.Action
                name='delete'
                onClick={handleDeleteRows}
                tooltip='Remove patients from list'
                icon='delete'
            />
            <dt2.Action
                name='set-cc'
                onClick={handleSetChiefComplaint}
                tooltip='Set Chief Complaint'
                icon='medical_services'
            />
        </dt2.DataTable>
    );
}
