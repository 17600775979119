import React from 'react';
import * as R from 'react-router-dom';
import {
    AppBar as MUIAppBar,
    IconButton,
    Toolbar,
    Link,
    makeStyles,
    Hidden,
} from '@material-ui/core';

import {url_for} from 'ccm/routes';
import AuthInfo from 'ccm/components/AuthInfo';
import {Menu} from '@material-ui/icons';
import {TourMenu} from 'ccm/components/Tour';

const useStyles = makeStyles(theme => ({
    title: {
        margin: 0,
    },
    logo: {
        height: 48,
        marginRight: 8,
    },
    patientAppBar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.constants.drawerWidth,
        },
        width: undefined,
    },
}));

function PatientAppBar({onToggleDrawer, title, subtitle}) {
    const classes = useStyles();
    return (
        <MUIAppBar
            position='static'
            color='inherit'
            className={classes.patientAppBar}
        >
            <Toolbar>
                <IconButton
                    id='toggle-drawer'
                    edge='start'
                    onClick={onToggleDrawer}
                    color='inherit'
                >
                    <Menu />
                </IconButton>
                <h6 className={classes.title}>
                    {title} {subtitle && ` | ${subtitle}`}
                </h6>
                <div style={{flex: 1}} />
                <AuthInfo />
                <TourMenu />
            </Toolbar>
        </MUIAppBar>
    );
}

function LegacyAppBar({onToggleDrawer, title, subtitle}) {
    const classes = useStyles();

    return (
        <MUIAppBar position='static' color='inherit'>
            <Toolbar>
                <IconButton
                    id='toggle-drawer'
                    edge='start'
                    onClick={onToggleDrawer}
                    color='inherit'
                >
                    <Menu />
                </IconButton>
                <Hidden smDown>
                    <Link component={R.Link} to={url_for('home')}>
                        {/* <div style={{marginLeft: 10, marginRight: 20}}>
                            <h4>Same Page MD</h4>
                        </div> */}
                        <img
                            className={classes.logo}
                            alt='logo'
                            src='/img/SamePageMDLogo-2.png'
                        />
                    </Link>
                </Hidden>
                <h6 className={classes.title}>
                    {title} {subtitle && ` | ${subtitle}`}
                </h6>
                <div style={{flex: 1}} />
                <AuthInfo />
                <TourMenu />
            </Toolbar>
        </MUIAppBar>
    );
}

export default function AppBar({patient, ...props}) {
    return patient ? <PatientAppBar {...props} /> : <LegacyAppBar {...props} />;
}
