import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {useGlobals} from 'ccm/components/Globals';
import AsyncDialogFilter from 'ccm/components/datatable/AsyncDialogFilter';

import {selectAllUsers, selectHealthcareProviders} from 'ccm/lib/selectors';

const {dt2} = components;

export function UserFilter({
    column,
    filter,
    onChangeFilter,
    selector = selectAllUsers,
    ...props
}) {
    const globals = useGlobals();
    const fetchOptions = () => globals.fetchAll('user.Summaries');
    return (
        <AsyncDialogFilter
            column={column}
            filter={filter}
            onChangeFilter={onChangeFilter}
            selector={selector}
            fetchOptions={fetchOptions}
            {...props}
        />
    );
}

export function ProviderFilter(props) {
    const selector = fp.pipe([
        selectHealthcareProviders,
        fp.sortBy('attributes.name'),
        fp.map('attributes.name'),
    ]);
    return <UserFilter title='Provider' selector={selector} {...props} />;
}

export function UserColumn({
    id = 'col-user',
    title = 'User',
    field = 'attributes.name',
    FilterComponent = UserFilter,
    ...props
}) {
    return (
        <dt2.Column
            id={id}
            title={title}
            field={field}
            FilterComponent={FilterComponent}
            {...props}
        />
    );
}

export function ProviderColumn({
    id = 'col-provider',
    title = 'Provider',
    field = 'attributes.provider_name',
    FilterComponent = ProviderFilter,
    ...props
}) {
    return (
        <UserColumn
            id={id}
            title={title}
            field={field}
            FilterComponent={FilterComponent}
            {...props}
        />
    );
}

export default UserColumn;
