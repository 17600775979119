import TextField from 'ccm/components/fields/TextField';
import MultilineTextField from 'ccm/components/fields/MultilineTextField';
import Autocomplete from 'ccm/components/fields/Autocomplete';
import FreeSolo from 'ccm/components/fields/FreeSolo';
import ImageMap from 'ccm/components/fields/ImageMap';
import ImageAttachment from 'ccm/components/fields/ImageAttachment';
import RadioTable from 'ccm/components/fields/RadioTable';
import RadioGroup from 'ccm/components/fields/RadioGroup';
import RadioRow from 'ccm/components/fields/RadioRow';
import CheckboxGroup from 'ccm/components/fields/CheckboxGroup';
import DateField from 'ccm/components/fields/DateField';
import PatientMedicationSelectField from 'ccm/components/fields/PatientMedicationSelect';
import PharmacySelectField from 'ccm/components/fields/PharmacySelect';

export const FIELD_COMPONENTS = {
    autocomplete: Autocomplete,
    freesolo: FreeSolo,
    image: ImageAttachment,
    text: TextField,
    multilineText: MultilineTextField,
    date: DateField,
    radio: RadioGroup,
    radioRow: RadioRow,
    radioTable: RadioTable,
    checkbox: CheckboxGroup,
    imageMap: ImageMap,
    medSelect: PatientMedicationSelectField,
    pharmacySelect: PharmacySelectField,
};
