import fp from 'lodash/fp';
import {createSelector} from '@reduxjs/toolkit';

import {ducks} from '@arborian/narrf';

export const selectTicketAttachments = ticket =>
    createSelector(
        ducks.jsonapi.selectObject(['TicketAttachment']),
        storeAttachments => {
            console.log('Get ticket attachments', ticket, storeAttachments);
            return fp.pipe([
                fp.get('attributes.data.session_data.attachments'),
                fp.map(att => fp.get(att?.id, storeAttachments)),
                fp.filter(att => !!att),
            ])(ticket);
        },
    );

export const selectAttachmentBlob = attachmentId =>
    createSelector(
        ducks.jsonapi.selectObject(['TicketAttachment', attachmentId]),
        ducks.jsonapi.selectObject(['Blob']),
        (attachment, blobs) => {
            const blobId = fp.get('relationships.blob.data.id', attachment);
            const blob = blobs[blobId];
            return blob;
        },
    );

export const selectUnsignedAttachmentBlob = attachmentId =>
    createSelector(
        ducks.jsonapi.selectObject(['TicketAttachment', attachmentId]),
        ducks.jsonapi.selectObject(['Blob']),
        (attachment, blobs) => {
            const blobId = fp.get(
                'relationships.unsigned_blob.data.id',
                attachment,
            );
            const blob = blobs[blobId];
            return blob;
        },
    );
