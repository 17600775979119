import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {ducks, useApi} from '@arborian/narrf';

import {ExpansionPanel as DynaFormExpansionPanel} from 'ccm/components/DynaForm';

export default function TicketFormData({ticket, actions, readOnly}) {
    const api = useApi();
    const [expanded, setExpanded] = useState({});

    useEffect(() => {
        api.fetchJsonApi(ticket.relationships.form.links.related);
    }, [api, ticket.relationships.form.links.related]);

    const handleTicketChange = async session_data => {
        console.log('Ticket changing', ticket, session_data);
        await actions.put(
            fp.set('attributes.data.session_data', session_data, ticket),
        );
        // await actions.patch(ticket, {data: {session_data}});
        setExpanded({});
    };
    const formPages = useSelector(ducks.jsonapi.selectObject('FormPage'));
    const completedPages = fp.get('attributes.data.completed_pages', ticket);
    const session = fp.get('attributes.data.session_data', ticket);
    const formid = fp.get('attributes.data.formid', ticket);
    const setPageExpanded = page => (ev, isExpanded) => {
        setExpanded({...expanded, [page]: isExpanded});
    };
    return (
        <>
            {fp.pipe([
                fp.keys,
                fp.sortBy(id => id),
                fp.filter(pageId => completedPages.includes(pageId)),
                fp.map(pageId => (
                    <DynaFormExpansionPanel
                        key={pageId}
                        form={formPages}
                        session={session}
                        formid={formid}
                        pageid={pageId}
                        expanded={expanded[pageId]}
                        onChangeExpanded={setPageExpanded(pageId)}
                        handleTicketChange={handleTicketChange}
                        readOnly={
                            readOnly || ticket.attributes.status === 'resolved'
                        }
                    />
                )),
            ])(formPages)}
        </>
    );
}
