import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

export const selectShiftIndex = createSelector(
    ducks.jsonapi.selectObject('Shift'),
    fp.pipe([fp.groupBy('relationships.rule.data.id')]),
);

export const selectRulesetFacilities = ruleset => {
    const facIds = fp.get('attributes.facility_ids', ruleset);
    return createSelector(
        ducks.jsonapi.selectObject('Facility'),
        fp.pipe([
            fp.filter(fac => fp.includes(fac.id, facIds)),
            fp.sortBy(fac => fac.attributes.name.toLowerCase()),
        ]),
    );
};

export const selectShiftAssignmentIndex = createSelector(
    ducks.jsonapi.selectObject('Assignment'),
    asgs => {
        let result = {};
        fp.forEach(asg => {
            result = fp.set(
                [
                    // i.e. set('facId.shiftId', asg, result)
                    fp.getOr(null, 'relationships.facility.data.id', asg),
                    fp.get('relationships.shift.data.id', asg),
                ],
                asg,
                result,
            );
        }, asgs);
        return result;
    },
);

export const selectRuleIndex = createSelector(
    ducks.jsonapi.selectObject('Rule'),
    fp.pipe([
        fp.sortBy('attributes.ordinal'),
        fp.groupBy('attributes.escalation'),
    ]),
);
