import React from 'react';
import fp from 'lodash/fp';

import {Autocomplete} from 'formik-material-ui-lab';
import {TextField} from '@material-ui/core';

import {useAllTicketTypesLookup} from 'ccm/dataSource/allResources';

export default function TicketTypeSelect({
    label,
    name,
    touched,
    errors,
    ...props
}) {
    const types = useAllTicketTypesLookup();
    return (
        <Autocomplete
            options={fp.keys(types)}
            getOptionLabel={value => types[value]}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        errors={touched && touched[name] && !!errors[name]}
                        helperText={errors && errors[name]}
                    />
                );
            }}
            {...props}
        />
    );
}
