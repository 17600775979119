import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {makeStyles, Tabs, Tab, Paper} from '@material-ui/core';
import {RecentActors, PriorityHigh} from '@material-ui/icons';
import {Alert, AlertTitle} from '@material-ui/lab';

import {useApi, ducks} from '@arborian/narrf';
import {useGlobals} from 'ccm/components/Globals';
import {RuleList, RuleEditor, RuleFacilities, FallbacksForm} from './routing';
import TicketTypesPanel from './TicketTypesPanel';

const useStyles = makeStyles(theme => ({
    panel: {
        '& p': {
            padding: theme.spacing(1),
        },
    },
    paddedPanel: {
        padding: theme.spacing(2, 1, 1, 2),
    },
    routingPanels: {
        '& > div': {
            margin: theme.spacing(1, 0),
        },
        '& > div:first-child': {
            margin: theme.spacing(0, 0, 1, 0),
        },
    },
}));

function FallbacksPanel({practice, ruleset}) {
    const classes = useStyles();
    return (
        <Paper className={classes.paddedPanel}>
            <h5>Global Fallbacks</h5>
            <p>
                If no other rules match, tickets will be routed to this
                owner/provider.
            </p>
            <FallbacksForm practice={practice} ruleset={ruleset} />
        </Paper>
    );
}

function RuleListPanel({ruleset, onSelect, onDeleted}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (ev, val) => {
        onSelect(null);
        setValue(val);
    };
    return (
        <Paper className={classes.panel}>
            <Tabs value={value} onChange={handleChange}>
                <Tab
                    id='tab-normal-rules'
                    icon={<RecentActors />}
                    label='Normal Rules'
                />
                <Tab
                    id='tab-esc-rules'
                    icon={<PriorityHigh />}
                    label='Escalation Rules'
                />
            </Tabs>
            <div>
                {value === 0 ? (
                    <Alert severity='info'>
                        <AlertTitle>
                            These rules define how new tickets are routed.
                        </AlertTitle>
                        Rules are evaluated from top to bottom until a matching
                        assignment is found.
                    </Alert>
                ) : (
                    <Alert severity='info'>
                        <AlertTitle>
                            These rules define how tickets are reassigned due to
                            inactivity.
                        </AlertTitle>
                        Rules are evaluated from top to bottom until a matching
                        assignment is found.
                    </Alert>
                )}
                <RuleList
                    ruleset={ruleset}
                    onSelect={onSelect}
                    onDeleted={onDeleted}
                    escalation={value === 0 ? false : true}
                    include={['shifts']}
                />
            </div>
        </Paper>
    );
}

export default function TicketRouting({practice}) {
    const api = useApi();
    const [ruleId, setRuleId] = useState();
    const rulesetId = fp.get('relationships.ruleset.data.id', practice);
    const ruleset = useSelector(
        ducks.jsonapi.selectObject(['RuleSet', rulesetId]),
    );
    const globals = useGlobals();
    const classes = useStyles();

    useEffect(() => {
        globals.fetchAll('facility.FacilityCollection');
        api.fetchAllJsonApi(
            fp.get('relationships.staff.links.related', practice),
        );
        api.fetchAllJsonApi(
            fp.get('relationships.providers.links.related', practice),
        );
    }, [api, practice, globals]);

    useEffect(() => {
        if (practice) {
            api.fetchJsonApi(practice.relationships.ruleset.links.related, {
                include: ['rules', 'assignments', 'shifts'],
            });
        }
    }, [api, practice]);

    const handleDeleted = deletedRule => {
        if (ruleId === deletedRule.id) {
            setRuleId(null);
        }
    };
    if (!practice) return null;
    return (
        <div className={classes.routingPanels}>
            <TicketTypesPanel practice={practice} />
            {/* <br /> */}
            <FallbacksPanel practice={practice} ruleset={ruleset} />
            <RuleFacilities ruleset={ruleset} />
            <RuleListPanel
                ruleset={ruleset}
                onSelect={setRuleId}
                onDeleted={handleDeleted}
            />
            {ruleId && <RuleEditor ruleset={ruleset} ruleId={ruleId} />}
        </div>
    );
}
