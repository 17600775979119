import React, {useMemo} from 'react';
import fp from 'lodash/fp';
import {Button, TextField} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import {Autocomplete} from 'formik-material-ui-lab';

export default function FlowsheetForm({dataSource}) {
    const handleSubmit = async values => {
        dataSource.setFilter(values);
    };
    const availableCodes = useMemo(() => dataSource.codes || [], [
        dataSource.codes,
    ]);
    const codes = useMemo(
        () =>
            fp.filter(
                c => fp.contains(c.code_value, dataSource.filter.codes),
                availableCodes,
            ),
        [dataSource.filter, availableCodes],
    );

    const initialValues = {...dataSource.filter, codes};

    const getOptionSelected = (option, value) => {
        return option.code_value === value.code_value;
    };
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <Field
                        label='Begin'
                        format='MM/dd/yyyy'
                        component={KeyboardDatePicker}
                        name='begin'
                    />
                    &nbsp; &mdash; &nbsp;
                    <Field
                        label='End'
                        format='MM/dd/yyyy'
                        component={KeyboardDatePicker}
                        name='end'
                    />
                    <br />
                    <Field
                        component={Autocomplete}
                        fullWidth
                        autoComplete
                        autoHighlight
                        multiple
                        name='codes'
                        options={availableCodes}
                        getOptionLabel={c => `${c.code_name} (${c.code_value})`}
                        getOptionSelected={getOptionSelected}
                        renderInput={params => (
                            <TextField label='Codes' {...params} />
                        )}
                    />
                    <Button type='submit' variant='contained' color='primary'>
                        Set Filters
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
