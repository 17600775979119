import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
    hbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'flex-end',
        alignItems: 'flex-start',
    },
    vbox: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-start',
    },
    chipList: {
        display: 'flex',
        flexDirection: 'row',
    },
    view: {
        '& address': {
            ...theme.typography.h5,
        },
    },
    scheduleView: {
        padding: theme.spacing(2),
    },
    scheduleSwitch: {
        margin: theme.spacing(0, 0, 1, 0),
    },
    calMonth: {
        height: 500,
    },
}));
