import React from 'react';

import {useResources} from 'ccm/dataSource';
import CarePlanTemplateTable from 'ccm/components/CarePlanTemplateTable';

const SHARED_TEMPLATE_FETCH_OPTIONS = {
    filter: {
        'attributes.practice_id': null,
    },
    page: {number: 0, size: 20},
};

export default function CarePlanTemplateAdminTable() {
    const link = 'careplan.TemplateCollection';

    const carePlanTemplates = useResources({
        link,
        prefix: 'cptpl.',
        type: "CarePlanTemplate",
        initialFetchOptions: SHARED_TEMPLATE_FETCH_OPTIONS,
    });

    const editable = {
        onRowAdd: carePlanTemplates.handleAdd,
        // Editing happens in detail panel instead
        // onRowUpdate: carePlanTemplates.handleUpdate,
        onRowDelete: carePlanTemplates.handleDelete,
    };

    return (
        <CarePlanTemplateTable
            title='Care Plan Templates'
            dataSource={carePlanTemplates}
            editable={editable}
        />
    );
}
