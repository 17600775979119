import React from 'react';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';
import {useEhrs} from 'ccm/dataSource';
import {
    useAllPracticesLookup,
    useAllFacilitiesLookup,
    useAllEHRPluginsLookup,
} from 'ccm/dataSource/allResources';

const {dt2} = components;

const INCLUDES = [];
const DS_OPTIONS = {include: INCLUDES};

export default function EhrAdminTable() {
    const api = useApi();
    const ds = useEhrs({prefix: 'ehrTable.', options: DS_OPTIONS});
    const refresh = () => ds.fetch();
    const lookupPractice = useAllPracticesLookup();
    const lookupFacility = useAllFacilitiesLookup();
    const lookupPlugins = useAllEHRPluginsLookup();

    const handleUpdate = (ev, item) => {
        console.log('Update', item);
        api.fetch(fp.get('data.links.update', item), {method: 'POST'});
    };

    return (
        <dt2.DataTable
            title='EHR Administration'
            size='small'
            dataSource={ds}
            options={{
                search: true,
                filtering: true,
            }}
        >
            <dt2.Column
                title='EHR Type'
                field='attributes.plugin_id'
                lookup={lookupPlugins}
            />
            <dt2.Column title='EHR Label' field='attributes.label' />
            {/* Yes, these should be relationships. See the schemas.py for the full apology. */}
            <dt2.Column
                title='Facility'
                editable={false}
                field='relationships.facility.data.id'
                lookup={lookupFacility}
            />
            <dt2.Column
                title='Practice'
                field='relationships.practice.data.id'
                lookup={lookupPractice}
            />
            <dt2.Action
                id='update'
                name='update'
                onClick={handleUpdate}
                tooltip='Update connection'
                icon='update'
            />
            <dt2.Action
                id='refresh'
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </dt2.DataTable>
    );
}
