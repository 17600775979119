import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';
import {createSelector} from '@reduxjs/toolkit';

export const selectAsList = type =>
    createSelector(ducks.jsonapi.selectObject(type), fp.values);

export const selectRolesByName = createSelector(
    selectAsList('Role'),
    fp.pipe([fp.map(role => [role.attributes.name, role]), fp.fromPairs]),
);

export const selectPatientArtifacts = (patientId, type) =>
    createSelector(
        selectAsList(type),
        fp.pipe([
            fp.filter(art => art.type === type),
            fp.filter(art => art.relationships.patient.data.id === patientId),
        ]),
    );

export const selectProviders = selectAsList('Provider');
export const selectRoles = selectAsList('Role');
export const selectFacilityFaxes = selectAsList('FacilityFax');

export const selectAllFacilities = createSelector(
    selectAsList('Facility'),
    fp.sortBy('attributes.name'),
);

export const selectAllPractices = createSelector(
    selectAsList('Practice'),
    fp.sortBy('attributes.name'),
);

export const selectAllFacilitiesById = createSelector(
    selectAllFacilities,
    fp.pipe([fp.map(f => [f.id, f]), fp.fromPairs]),
);

export const selectFacilities = createSelector(
    selectAllFacilities,
    fp.filter(f => f.id !== '__all__'),
);

export const selectAvailableFacilities = createSelector(
    // Dead code.
    // Broken for non-admins in SamePage; needs to use groups
    ducks.auth.selectUserinfo,
    selectFacilities,
    (ui, facs) => {
        const userScopes = fp.getOr([], 'scopes', ui);
        const userFacs = fp.getOr([], 'facilities', ui);
        if (
            fp.contains('__all__', userFacs) ||
            fp.contains('__admin__', userScopes)
        )
            return facs;
        return fp.filter(f => fp.contains(f.id, userFacs), facs);
    },
);

export const selectProgressNotesByFac = createSelector(
    ducks.jsonapi.selectObject('ProgressNoteType'),
    ducks.jsonapi.selectObject('PCCFacility'),
    (noteTypes, pccFacs) => {
        const facMap = fp.fromPairs(
            fp.map(f => [f.attributes.pcc.facId, f.id])(pccFacs),
        );
        const grouped = fp.pipe([
            fp.map(n => [n.id, {tcpaFacId: facMap[n.attributes.facId], ...n}]),
            fp.fromPairs,
            fp.groupBy(n => n.tcpaFacId),
        ])(noteTypes);
        return grouped;
    },
);

export const debugPipe = stages => x => {
    console.log('Enter pipeline with', x);
    fp.forEach(stage => {
        x = stage(x);
        console.log('... => ', x);
    }, stages);
    return x;
};

export const selectAllUsers = createSelector(
    selectAsList('User'),
    fp.sortBy('attributes.name'),
);

export const selectUsersWithGroup = groupid =>
    createSelector(
        selectAsList('UserSummary'),
        fp.pipe([
            fp.filter(user => {
                return fp.some(
                    gid => fp.includes(groupid, gid),
                    fp.get('attributes.group_ids', user),
                );
            }),
            fp.sortBy(u => fp.lowerCase(fp.get('attributes.name', u))),
        ]),
    );

export const selectTicketOwners = selectUsersWithGroup('staff');
export const selectHealthcareProviders = selectUsersWithGroup('provider');

export const selectAvailableFacilitiesLookup = createSelector(
    selectAllFacilities,
    fp.pipe([fp.values, fp.map(o => [o.id, o.attributes.name]), fp.fromPairs]),
);

export const selectTicketOwnersLookup = createSelector(
    selectTicketOwners,
    fp.pipe([fp.values, fp.map(o => [o.id, o.attributes.name]), fp.fromPairs]),
);

export const selectHealthcareProvidersLookup = createSelector(
    selectHealthcareProviders,
    fp.pipe([fp.values, fp.map(o => [o.id, o.attributes.name]), fp.fromPairs]),
);

export const selectCurrentSub = createSelector(
    ducks.auth.selectUserinfo,
    ducks.jsonapi.selectObject('Sub'),
    (ui, subs) => fp.get(ui.sub, subs),
);

export const selectTicketComments = ticket_id =>
    createSelector(ducks.jsonapi.selectObject('TicketComment'), all_comments =>
        fp.filter(c => c.attributes.ticket_id === ticket_id, all_comments),
    );
