import React from 'react';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {KeyboardDatePicker} from 'formik-material-ui-pickers';

import {Button, MenuItem} from '@material-ui/core';

export default function CarePlanMetadataForm({value, onSubmit}) {
    const handleSubmit = async values => {
        console.log('Submit careplan template', values);
        onSubmit && onSubmit(values);
        return true;
    };
    return (
        <Formik
            initialValues={value}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {({touched, values, errors}) => (
                <Form>
                    {/* */}
                    <Field
                        component={TextField}
                        label='Status'
                        name='attributes.status'
                        InputLabelProps={{shrink: true}}
                        select
                    >
                        <MenuItem value='Draft'>Draft</MenuItem>
                        <MenuItem value='Active'>Active</MenuItem>
                        <MenuItem value='Closed'>Closed</MenuItem>
                    </Field>{' '}
                    <Field
                        id='nextReviewDateField'
                        component={KeyboardDatePicker}
                        label='Next review date'
                        name='attributes.nextReviewDate'
                        format='yyyy-MM-dd'
                    />
                    <br />
                    <br />
                    <Button
                        id='carePlanMetadataFormSaveBtn'
                        type='submit'
                        variant='contained'
                        color='primary'
                    >
                        Save
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
