import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Add} from '@material-ui/icons';
import {Button} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import useStyles from './styles';
import {selectTicketAttachments} from './selectors';
import AttachmentThumbnail from './AttachmentThumbnail';
import NewTicket from './NewTicket';

export default function TicketSplitter({ticket, newTickets, setNewTickets}) {
    const api = useApi();
    const classes = useStyles();

    useEffect(() => {
        if (!ticket) return;
        return api.fetchJsonApi(
            fp.get('relationships.attachments.links.related', ticket),
            {
                include: ['unsigned_blob'],
            },
        );
    }, [api, ticket]);

    const attachments = useSelector(
        useMemo(() => selectTicketAttachments(ticket), [ticket]),
    );

    const handleNewTicket = () => {
        const newTicket = {
            id: newTickets.length,
            attachments: [],
        };
        setNewTickets([...newTickets, newTicket]);
    };

    const handleDragStart = (ev, att) => {
        console.log('Start drag', ev, att);
        ev.dataTransfer.clearData();
        ev.dataTransfer.setData('application/json', JSON.stringify(att));
        ev.dataTransfer.dropEffect = 'copy';
    };

    const handleChange = ticket => {
        console.log('handleChange', ticket, newTickets);
        setNewTickets(fp.set(ticket.id, ticket), newTickets);
    };

    const handleDeleteTicket = ticketId => {
        const tkts = newTickets.toSpliced(ticketId, 1);
        for (let i = 0; i < tkts.length; i++) {
            tkts[i].id = i;
            tkts[i].attachments = fp.map(
                fp.set('ticketId', i),
                tkts[i].attachments,
            );
        }
        setNewTickets(tkts);
    };

    return (
        <div className={classes.root}>
            <div className={classes.leftPane}>
                <h4>Original Ticket Attachments</h4>
                <div className={classes.attachmentList}>
                    {fp.map(
                        att => (
                            <AttachmentThumbnail
                                key={att.id}
                                attachment={att}
                                onDragStart={handleDragStart}
                            />
                        ),
                        attachments,
                    )}
                </div>
            </div>
            <div className={classes.rightPane}>
                <h4>New Tickets to Create</h4>
                {fp.map(
                    tkt => (
                        <NewTicket
                            key={tkt.id}
                            id={tkt.id}
                            attachments={tkt.attachments}
                            onChange={handleChange}
                            onDelete={handleDeleteTicket}
                        />
                    ),
                    newTickets,
                )}
                <Button startIcon={<Add />} onClick={handleNewTicket}>
                    Create new
                </Button>
            </div>
        </div>
    );
}
