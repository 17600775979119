import React, {useCallback, useState} from 'react';
import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    Button,
    Paper,
    Popper,
    makeStyles,
} from '@material-ui/core';
import {useApi, components} from '@arborian/narrf';

import {useTicketHistory, useTicketEvents} from 'ccm/dataSource';
import {DateTime} from 'ccm/components/DateTime';

const {dt2} = components;

const MessageText = ({value}) => (
    <p style={{'white-space': 'pre-wrap'}}>{value}</p>
);

const useStyles = makeStyles(theme => ({
    popperPaper: {
        padding: theme.spacing(1),
        '& code': {
            whiteSpace: 'pre-wrap',
        },
    },
}));

function TicketEventActions({event}) {
    const api = useApi();
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState(null);
    const classes = useStyles();
    const message = fp.get('attributes.message', event);
    const isFaxSuccess =
        fp.get('attributes.event', event) === 'ticket.fax' &&
        !!message.match(/Fax success/);
    const handleGetFaxStatus = async ev => {
        try {
            const resp = await api.fetchJson(fp.get('links.fax_status', event));
            console.log('Status', resp);
            setStatus(JSON.stringify(resp, null, 2));
        } catch (e) {
            setStatus(`Error getting fax status: ${e}`);
        }
        setAnchorEl(ev.target);
    };
    const handleClose = () => setAnchorEl(null);
    if (isFaxSuccess) {
        return (
            <>
                <Popper open={!!anchorEl} anchorEl={anchorEl}>
                    <Paper className={classes.popperPaper}>
                        <code>{status}</code>
                        <br />
                        <Button onClick={handleClose}>Close</Button>
                    </Paper>
                </Popper>
                <Button variant='outlined' onClick={handleGetFaxStatus}>
                    Details
                </Button>
            </>
        );
    }
    console.log('TicketEvent', {event, isFaxSuccess});
    return null;
}

export default function TicketHistoryTable({ticket, actions}) {
    const api = useApi();
    const ticketHistory = useTicketHistory(ticket);
    const ticketEvents = useTicketEvents(ticket);

    const refresh = useCallback(() => ticketHistory.fetch(), [ticketHistory]);

    const handleRestore = useCallback(
        async (event, rowData) => {
            try {
                await api.fetchJsonApi(
                    rowData.data.relationships.restore.links.related,
                    {
                        method: 'POST',
                        include: actions.include,
                    },
                );
                actions.success('Ticket restored to previous version');
            } catch (err) {
                actions.error('Ticket restore failed', err);
            }
            refresh();
        },
        [api, refresh, actions],
    );

    return (
        <div>
            <Accordion>
                <AccordionSummary>Ticket Data Changes</AccordionSummary>
                <dt2.DataTable
                    dataSource={ticketHistory}
                    options={{search: false}}
                >
                    <dt2.Column
                        title='Date'
                        field='attributes.created'
                        render={row => (
                            <DateTime
                                value={fp.get('data.attributes.created', row)}
                            />
                        )}
                    />
                    <dt2.Column
                        title='Changes'
                        field='attributes.message'
                        render={row => (
                            <MessageText
                                value={fp.get('data.attributes.message', row)}
                            />
                        )}
                    />
                    <dt2.Action
                        name='refresh'
                        free
                        onClick={() => ticketHistory.fetch()}
                        tooltip='Refresh'
                        icon='refresh'
                    />
                    <dt2.Action
                        name='Restore'
                        onClick={handleRestore}
                        tooltip='Restore this version'
                        icon='history'
                    />
                </dt2.DataTable>
            </Accordion>
            <Accordion>
                <AccordionSummary>Progress Notes & Faxes</AccordionSummary>
                <dt2.DataTable
                    dataSource={ticketEvents}
                    options={{search: false}}
                >
                    <dt2.Column
                        title='Date'
                        field='attributes.timestamp'
                        type='date'
                        render={row => (
                            <DateTime
                                value={fp.get('data.attributes.timestamp', row)}
                            />
                        )}
                    />
                    <dt2.Column title='Event' field='attributes.event' />
                    <dt2.Column title='Message' field='attributes.message' />
                    <dt2.Column
                        id='event-actions'
                        render={row => <TicketEventActions event={row.data} />}
                    />
                    <dt2.Action
                        name='refresh'
                        free
                        onClick={() => ticketEvents.fetch()}
                        tooltip='Refresh'
                        icon='refresh'
                    />
                </dt2.DataTable>
            </Accordion>
        </div>
    );
}
