import React from 'react';
import {components} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';

import CodeselectDialog from 'ccm/components/dialogs/CodeselectDialog';

const {dt2} = components;

export default function CodeableConceptTable({dataSource, ...props}) {
    const dialog = useDialog(CodeselectDialog);

    const handleAdd = async () => {
        const value = await dialog();
        if (value) {
            dataSource.handleAdd({
                data: {
                    system: value.attributes.systemid,
                    code: value.attributes.value,
                    display: value.attributes.description,
                },
            });
        }
        console.log('Got value', value);
    };
    return (
        <dt2.DataTable
            dataSource={dataSource}
            editable={{
                //onRowAdd: dataSource.handleAdd,
                //onRowUpdate: dataSource.handleUpdate,
                onRowDelete: dataSource.handleDelete,
            }}
            {...props}
        >
            <dt2.Column title='Coding System' field='system' />
            <dt2.Column title='Code' field='code' />
            <dt2.Column title='Display' field='display' />
            {/*
            <dt2.Column
                title='Raw'
                render={row => <pre>{JSON.stringify(row.data, null, 2)}</pre>}
            />
            */}
            <dt2.Action free icon='add' onClick={handleAdd} />
            <dt2.Action
                free
                icon='refresh'
                onClick={() => dataSource.fetch()}
            />
        </dt2.DataTable>
    );
}
