import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import {useSnackbar} from 'notistack';

import BasePage from 'ccm/components/BasePage';

import {useApi} from '@arborian/narrf';

import {url_for} from 'ccm/routes';

import PccLoginButton from 'ccm/components/PccLoginButton';
import BaseLoginButton from 'ccm/components/BaseLoginButton';
import PasswordRegistrationForm from 'ccm/components/forms/PasswordRegistrationForm';

const useStyles = makeStyles(theme => ({
    root: {},
    buttonContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    loginButton: {
        width: 244,
        height: 40,
        marginTop: 6,
        marginBottom: 6,
        marginRight: 4,
    },
}));

export default function RegisterPage() {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [providers, setProviders] = useState();

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            console.log('Got error, trying direct redirect');
            window.location = authorizeLink;
        }
    }, [api, setProviders]);

    useEffect(() => {
        if (!providers) {
            fetchProviders();
        }
    }, [providers, fetchProviders]);

    const reportError = useCallback(
        error => {
            console.log({error});
            let errorMsg = `ERROR - Unable to complete your registration: ${fp.getOr(
                'Unknown error',
                'message',
                error,
            )}`;
            if (fp.get('response.status', error) === 409) {
                errorMsg =
                    'ERROR - An account already exists for this user. Please sign in instead.';
            }

            enqueueSnackbar(errorMsg, {
                persist: false,
                variant: 'error',
            });
        },
        [enqueueSnackbar],
    );

    const handlePasswordRegistration = useCallback(
        async ({name, email, password}) => {
            console.log('Try to register', name, email, password);
            try {
                const resp = await api.fetch(api.url_for('auth.registration'), {
                    method: 'POST',
                    json: {
                        data: {
                            type: 'Registration',
                            attributes: {name, email, password},
                        },
                    },
                });
                console.log('Response was', resp);
            } catch (error) {
                reportError(error);
                throw error;
            }
            try {
                await api.loginSrp(email, password);
                history.push(url_for('profile'));
            } catch (error) {
                reportError(error);
                throw error;
            }
        },
        [api, history, reportError],
    );
    const pcc = fp.find(p => p.attributes.plugin_id === 'pcc', providers);
    const nonPcc = fp.filter(p => p.attributes.plugin_id !== 'pcc', providers);

    return (
        <BasePage title='Register'>
            <div className={classes.root}>
                <Alert severity='info'>
                    If you use PointClickCare or Google Credentials, please
                    choose the appropriate registration method below.
                </Alert>
                <br />
                <div className={classes.buttonContainer}>
                    {fp.get('attributes.allow_signup', pcc) && (
                        <PccLoginButton provider={pcc} intent='register' />
                    )}
                    {fp.pipe([
                        fp.filter('attributes.allow_signup'),
                        fp.filter(p => p.attributes.plugin_id !== 'password'),
                        fp.map(p => (
                            <BaseLoginButton provider={p} intent='register'>
                                Sign up with {p.attributes.label}
                            </BaseLoginButton>
                        )),
                    ])(nonPcc)}
                </div>
                <br />
                <br />
                <br />
                <br />
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        If you don't use any of the above methods, you may also
                        register with an email & password
                    </AccordionSummary>
                    <AccordionDetails>
                        <PasswordRegistrationForm
                            submitLabel='Sign up with password'
                            onSubmit={handlePasswordRegistration}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </BasePage>
    );
}
