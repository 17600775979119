import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {ducks, useApi, components} from '@arborian/narrf';

import {Menu, MenuItem, Chip, IconButton} from '@material-ui/core';
import {AddCircleOutline} from '@material-ui/icons';

import {useGroupMembers} from 'ccm/dataSource';

const {dt2} = components;

const selectResourceGroups = (resource) =>
    createSelector(
        ducks.jsonapi.selectObject('Group'),
        fp.filter((g) => g.id.match(new RegExp(resource.meta.group_regexp)))
    );

function UserRolesEditor({user, groups, onAdd, onDelete}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleAdd = (group) => {
        onAdd(user, group);
        setAnchorEl(null);
    };
    const handleDelete = (group) => {
        onDelete(user, group);
    };
    const userGroups = fp.filter(
        (g) => fp.find((ug) => ug.id === g.id, user.relationships.groups.data),
        groups
    );
    return (
        <>
            {fp.map(
                (g) => (
                    <Chip
                        key={g.id}
                        label={g.attributes.label}
                        onDelete={onDelete && ((ev) => handleDelete(g))}
                    />
                ),
                userGroups
            )}
            {onAdd ? (
                <IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
                    <AddCircleOutline />
                </IconButton>
            ) : null}
            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {fp.map(
                    (g) => (
                        <MenuItem
                            key={g.id}
                            value={g.id}
                            onClick={(ev) => handleAdd(g)}
                        >
                            {g.attributes.label}
                        </MenuItem>
                    ),
                    groups
                )}
            </Menu>
        </>
    );
}

export default function GroupMemberTable({title, protectedResource}) {
    const api = useApi();
    const dataSource = useGroupMembers(protectedResource, {
        prefix: `userGroupTable[${protectedResource.meta.group_prefix}]`,
    });

    const groups = useSelector(selectResourceGroups(protectedResource));
    useEffect(() => {
        const url = fp.get(
            'relationships.groups.links.related',
            protectedResource
        );
        api.fetchJson(url);
    }, [api, protectedResource]);

    const refresh = useCallback(() => dataSource.fetch(), [dataSource]);

    const handleAddGroup = useCallback(
        async (user, group) => {
            const url = group.relationships.members.links.self;
            await api.fetchJson(url, {
                method: 'POST',
                json: {data: [{type: 'User', id: user.id}]},
            });
            await api.fetchJson(user.links.self);
        },
        [api]
    );

    const handleDeleteGroup = useCallback(
        async (user, group) => {
            console.log('Delete', {user, group});
            const url = group.relationships.members.links.self;
            await api.fetchJson(url, {
                method: 'DELETE',
                json: {data: [{type: 'User', id: user.id}]},
            });
            await api.fetchJson(user.links.self);
        },
        [api]
    );

    const isAdmin =
        fp.indexOf('Common.ADMIN', protectedResource.meta.allowed_actions) !==
        -1;
    const MemberRolesComponent = useCallback(
        ({row}) =>
            isAdmin ? (
                <UserRolesEditor
                    user={row.data}
                    groups={groups}
                    onAdd={handleAddGroup}
                    onDelete={handleDeleteGroup}
                />
            ) : (
                <UserRolesEditor
                    user={row.data}
                    // protectedResource={protectedResource}
                />
            ),
        [isAdmin, groups, handleAddGroup, handleDeleteGroup]
    );

    return (
        <dt2.DataTable
            dataSource={dataSource}
            title={title}
            size="small"
            options={{
                search: true,
            }}
        >
            <dt2.Column editable={false} title="Name" field="attributes.name" />
            <dt2.Column
                editable={false}
                title="Email"
                field="attributes.primary_email"
            />
            <dt2.Column
                title="Role(s)"
                component={MemberRolesComponent}
                EditComponent={MemberRolesComponent}
            />
            <dt2.Action
                name="refresh"
                free
                onClick={refresh}
                tooltip="Refresh"
                icon="refresh"
            />
        </dt2.DataTable>
    );
}
