import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';

import {selectCurrentUser} from 'ccm/lib/selectors-ccm';
import AppBar from 'ccm/components/AppBar';
import NavDrawer from 'ccm/components/NavDrawer';
import AutoLogout from 'ccm/components/AutoLogout';
import Footer from 'ccm/components/Footer';
import Interstitial from 'ccm/components/Interstitial';

import useGlobalStyles from 'ccm/globalStyles';

const useStyles = makeStyles(theme => ({
    contentContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 850,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },
    contentContainerWide: {
        padding: theme.spacing(2),
    },
    spacer: {
        height: theme.spacing(4),
    },
}));

export default function BasePage({
    title,
    subtitle,
    wide,
    patient,
    classes = {},
    children,
}) {
    classes = {...useGlobalStyles(), ...useStyles(), ...classes};
    const [drawerOpen, setDrawerOpen] = useState(false);
    const contentContainerClass = wide
        ? classes.contentContainerWide
        : classes.contentContainer;
    let idleTimeout = 1000 * 60 * 15;

    const curUser = useSelector(selectCurrentUser);

    // Zendesk Widget Values
    useEffect(() => {
        window.zE(() => {
            window.$zopim(() => {
                window.$zopim.livechat.setGreetings({
                    offline: 'Tech Support',
                });
                window.$zopim.livechat.offlineForm.setGreetings(
                    'IMPORTANT: For healthcare questions, use the "Contact your Healthcare Provider" button in SamePageMD.\n\nThis message will be sent to tech support.',
                );
                if (curUser) {
                    const userId = fp.get('id', curUser);
                    window.$zopim.livechat.setEmail(
                        fp.get('attributes.primary_email', curUser),
                    );
                    window.$zopim.livechat.setName(
                        fp.get('attributes.name', curUser),
                    );
                    if (userId) {
                        // Will create 'null' tags if no userId
                        window.$zopim.livechat.addTags(`uid_${userId}`);
                    }
                    window.$zopim.livechat.window.setTitle('Tech Support');
                }
            });
        });
    }, [curUser]);

    useEffect(() => {
        if (title) {
            document.title = `${title} | SamePage MD`;
        } else {
            document.title = '| SamePage MD';
        }
    }, [title]);
    return (
        <div className={classes.root}>
            <AutoLogout timeout={idleTimeout} />
            <Interstitial />
            <NavDrawer
                patient={patient}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            />
            <AppBar
                title={title}
                subtitle={subtitle}
                patient={patient}
                onToggleDrawer={() => setDrawerOpen(!drawerOpen)}
            />
            <div className={contentContainerClass}>
                {children}
                <div className={classes.spacer} />
                <Footer />
            </div>
        </div>
    );
}
