import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import BasePage from 'ccm/components/BasePage';
import {useApi, ducks} from '@arborian/narrf';

import DynaForm from 'ccm/components/DynaForm';

export default function DynaFormPage({match}) {
    const api = useApi();

    const {formid, pageid, sessionid} = match.params;
    const sessionUrl = api.url_for('ticketsession.TicketSession', {
        session_id: sessionid,
    });
    const formPageUrl = api.url_for('form.Form', {
        form_id: formid,
        page_id: pageid,
    });

    const formPage = useSelector(
        ducks.jsonapi.selectObject(['FormPage', pageid]),
    );
    const session = useSelector(
        ducks.jsonapi.selectObject(['TicketSession', sessionid]),
    );

    useEffect(() => {
        if (!formPage) {
            api.fetchJsonApi(formPageUrl);
        }
        if (!session) {
            api.fetchJsonApi(sessionUrl);
        }
    }, [api, formPage, session, formPageUrl, sessionUrl]);

    return (
        <BasePage>
            <DynaForm formPage={formPage} session={session} />
        </BasePage>
    );
}
