import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';
import {url_for} from 'ccm/routes';

const getStaffGroupIds = user => {
    const staff_group_re = /^(facility|practice):/;
    const result = fp.pipe([
        fp.get('relationships.groups.data'),
        fp.map('id'),
        fp.filter(gid => staff_group_re.test(gid)),
    ])(user);
    return result;
};

export default class Actions {
    constructor({dispatch, api, history, invitationDialog, user}) {
        this._dispatch = dispatch;
        this._api = api;
        this._history = history;
        this._user = user;
        this._invitationDialog = invitationDialog;
    }

    putResource = async resource => {
        console.log('putResource', resource);
        await this._api.fetchJson(resource.links.self, {
            method: 'PUT',
            json: {data: resource},
        });
    };
    deleteResource = async resource => {
        await this._api.fetchJson(resource.links.self, {method: 'DELETE'});
        this._dispatch(ducks.jsonapi.deleteData(resource));
    };

    createTicket = async patient => {
        const url = this._api.url_for('ticketsession.TicketSessionCollection');
        const session = await this._api.fetchJsonApi(url, {
            method: 'POST',
            json: {data: {...patient}},
        });
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        this._history.push(enterFormFlow);
    };

    createQuestionFlowTicket = async (patient, message = null) => {
        const url = this._api.url_for('ticketsession.TicketSessionCollection');
        let session = await this._api.fetchJsonApi(url, {
            method: 'POST',
            json: {
                data: {...patient},
            },
        });
        const userStaffGroupIds = getStaffGroupIds(this._user);
        let attributes = {
            formid: fp.isEmpty(userStaffGroupIds) ? '2' : '1',
            session_data: session.data.attributes.session_data,
        };
        if (message) {
            const originalTicketId = fp.get(
                'relationships.ticket.data.id',
                message,
            );
            let updates = {
                session_data: {
                    re_message: message.attributes,
                },
            };
            if (originalTicketId) {
                updates.session_data.originalTicket = {id: originalTicketId};
            }
            attributes = fp.merge(attributes, updates);
        }
        session = await this._api.fetchJsonApi(session.links.self, {
            method: 'PUT',
            json: {data: {type: session.data.type, attributes}},
        });
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        this._history.push(enterFormFlow);
    };

    createSchedulingQuestionTicket = async (patient, visit) => {
        const url = this._api.url_for('ticketsession.TicketSessionCollection');
        let session = await this._api.fetchJsonApi(url, {
            method: 'POST',
            json: {
                data: {...patient},
            },
        });

        let attributes = {
            formid: '4',
            session_data: session.data.attributes.session_data,
        };
        let updates = {
            session_data: {
                submitType: 'scheduling_question',
                visit: fp.get('data.attributes', visit),
            },
        };
        attributes = fp.merge(attributes, updates);

        session = await this._api.fetchJsonApi(session.links.self, {
            method: 'PUT',
            json: {data: {type: session.data.type, attributes}},
        });
        const enterFormFlow = url_for('dynaform', {
            sessionid: session.data.id,
            formid: session.data.attributes.formid,
            pageid: session.data.attributes.next_page,
        });
        this._history.push(enterFormFlow);
    };

    fetchInvitationsTable = async (protectedResource, fetchOptions) => {
        const url = this._api.url_for('invitation.InvitationCollection');
        const result = await this._api.fetchDataTable(url, fetchOptions, {
            filter: {
                group_ids: JSON.stringify({
                    $regex: protectedResource.meta.group_regexp,
                }),
            },
            include: ['from_user', 'to_user', 'groups'],
        });
        return result;
    };

    inviteUser = async (protectedResource, options) => {
        const data = await this._invitationDialog(protectedResource, options);
        if (!data) return;
        const url = this._api.url_for('invitation.InvitationCollection');
        const group_ids = fp.map(
            role =>
                `${protectedResource.meta.object_type}:${protectedResource.id}/${role.id}`,
            data.roles,
        );

        return await this._api.fetchJson(url, {
            method: 'POST',
            json: {
                data: {
                    type: 'Invitation',
                    attributes: {to_email: data.email, group_ids},
                },
            },
        });
    };
}
