import React from 'react';
import fp from 'lodash/fp';

import {components} from '@arborian/narrf';

import {useEventLog} from 'ccm/dataSource';

const {dt2} = components;

const options = {
    include: ['user'],
};

const eventTypesArray = [
    'NO MESSAGE',
    'begin_batch_upload',
    'begin_batch_upload_item',
    'begin_cleanup-practicefusion-labs',
    'begin_collain-sync',
    'begin_create_tickets',
    'begin_create_tickets_item',
    'begin_delete-user',
    'begin_delete-user-global-fallbacks',
    'begin_delete-user-inbox',
    'begin_delete-user-invitations',
    'begin_delete-user-rule-assignments',
    'begin_delete-user-rule-fallbacks',
    'begin_delete-user-sub',
    'begin_delete-user-tickets',
    'begin_handle_pcc_webhook',
    'begin_load_visits',
    'begin_notification_tasks._send_event',
    'begin_register-pcc-user',
    'begin_run_report',
    'begin_test-abort',
    'begin_{event}',
    'deprecated-patient-mapping',
    'error_cleanup-practicefusion-labs',
    'error_collain-sync',
    'error_create_tickets_item',
    'error_delete-user',
    'error_delete-user-sub',
    'error_handle_pcc_webhook',
    'error_load_visits',
    'error_notification_tasks._send_event',
    'error_register-pcc-user',
    'error_test-abort',
    'invitation.create',
    'invitation.delete',
    'patient-chart-view',
    'request',
    'success_batch_upload',
    'success_batch_upload_item',
    'success_cleanup-practicefusion-labs',
    'success_collain-sync',
    'success_create_tickets',
    'success_create_tickets_item',
    'success_delete-user',
    'success_delete-user-global-fallbacks',
    'success_delete-user-inbox',
    'success_delete-user-invitations',
    'success_delete-user-rule-assignments',
    'success_delete-user-rule-fallbacks',
    'success_delete-user-sub',
    'success_delete-user-tickets',
    'success_handle_pcc_webhook',
    'success_load_visits',
    'success_notification_tasks._send_event',
    'success_register-pcc-user',
    'success_run_report',
    'ticket.acknowledged',
    'ticket.call-completed',
    'ticket.created',
    'ticket.fax',
    'ticket.progress_note',
    'ticket.reopened',
    'ticket.resolved',
    'ticket.updated',
];
const methodTypesArray = ['GET', 'PUT', 'PATCH', 'POST', 'DELETE'];

const arrayToLookup = fp.pipe([fp.map((s) => [s, s]), fp.fromPairs]);

const eventTypesLookup = arrayToLookup(eventTypesArray);
const methodTypesLookup = arrayToLookup(methodTypesArray);

export default function EventLogTable() {
    const ds = useEventLog({options});

    const refresh = () => ds.fetch();

    return (
        <dt2.DataTable
            title="Event Log"
            size="small"
            dataSource={ds}
            options={{
                filtering: true,
                selection: false,
                search: false,
            }}
        >
            <dt2.Column
                title="Event ID"
                field="attributes._id"
                filtering={false}
            />
            <dt2.Column
                title="Timestamp"
                field="attributes.timestamp"
                type="date"
                filtering={false}
                sorting={false}
            />
            <dt2.Column
                title="Type"
                field="attributes.event"
                filtering={true}
                lookup={eventTypesLookup}
                sorting={false}
            />
            <dt2.Column
                title="Method"
                field="attributes.context.method"
                filtering={true}
                lookup={methodTypesLookup}
                sorting={false}
            />
            <dt2.Column
                title="Path"
                field="attributes.context.path"
                filtering={false}
                sorting={false}
            />
            <dt2.Column
                title="User"
                field="attributes.context.userinfo.name"
                filtering={false}
                sorting={false}
            />
            <dt2.Column
                title="User Email"
                field="attributes.context.userinfo.email"
                filtering={false}
                sorting={false}
            />
            {/* Email is in different places for different events ? */}
            <dt2.Column
                title="Email"
                field="attributes.context.email"
                filtering={false}
                sorting={false}
            />
            <dt2.Action
                name="refresh"
                free
                onClick={refresh}
                tooltip="Refresh"
                icon="refresh"
            />
        </dt2.DataTable>
    );
}
