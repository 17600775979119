import React, {useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import fp from 'lodash/fp';

import {Tabs, Tab} from '@material-ui/core';

import BasePatientPage from 'ccm/components/BasePatientPage';
import InvitationAdminTable from 'ccm/components/InvitationAdminTable';
import GroupMemberTable from 'ccm/components/GroupMemberTable';
import PatientInvitationSuggestions from 'ccm/components/PatientInvitationSuggestions';
import {usePatientInvitationActions} from 'ccm/lib/hooks';

import {ducks, useApi} from '@arborian/narrf';

export default function PatientGroupPage() {
    const api = useApi();
    const tableRef = useRef();
    const {patientId} = useParams();
    const [init, setInit] = useState(false);
    const [tab, setTab] = useState(0);
    useEffect(() => {
        if (!init) {
            setInit(true);
            let url = api.url_for('patient.Patient', {patient_id: patientId});
            api.fetchJsonApi(url, {
                include: ['facility'],
                // TODO: , 'photo', 'latest_observations'],
            });
        }
    }, [api, patientId, init, setInit]);

    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );

    const invitationActions = usePatientInvitationActions(patient);

    console.log('protected_resource:', patient);
    const handleInvited = ev => tableRef.current.fetch();
    if (fp.isEmpty(invitationActions))
        return (
            <BasePatientPage
                title='Care Group'
                patient={patient}
                showFiles={false}
            >
                <GroupMemberTable protectedResource={patient} />
            </BasePatientPage>
        );
    return (
        <BasePatientPage title='Care Group' patient={patient} showFiles={false}>
            <Tabs value={tab} onChange={(ev, index) => setTab(index)}>
                <Tab label='Authorized Users' />
                <Tab label='Invitations' />
            </Tabs>

            {tab === 0 ? (
                <GroupMemberTable protectedResource={patient} />
            ) : (
                <>
                    <PatientInvitationSuggestions
                        patient={patient}
                        onInvited={handleInvited}
                    />
                    <InvitationAdminTable
                        protectedResource={patient}
                        tableRef={tableRef}
                    />
                </>
            )}
        </BasePatientPage>
    );
}
