import React from 'react';
import fp from 'lodash/fp';

import {Alert} from '@material-ui/lab';

import {components} from '@arborian/narrf';
import {useTherapies} from 'ccm/dataSource';
import ProgressIcon from 'ccm/components/ProgressIcon';
import TherapyDetail from './TherapyDetail';

const {dt2} = components;

const THERAPY_TABLE_OPTIONS = {
    search: true,
    filtering: false,
};

export default function TherapyTable({patient, title, ...props}) {
    const ds = useTherapies(patient);
    const canWritePhi = fp.includes(
        'Patient.WRITE_PHI',
        fp.get('meta.allowed_actions', patient),
    );
    const editable = canWritePhi
        ? {
              onRowAdd: ds.handleAdd,
              onRowUpdate: ds.handleUpdate,
              onRowDelete: ds.handleDelete,
          }
        : undefined;

    return (
        <>
            {ds.count === 0 && (
                <Alert severity='info'>
                    No progress recorded. Please contact your therapist (not
                    your doctor) for therapy information.
                </Alert>
            )}
            <dt2.DataTable
                size='small'
                dataSource={ds}
                title={title}
                options={THERAPY_TABLE_OPTIONS}
                editable={editable}
            >
                <dt2.Column title='Title' field='attributes.title' />
                <dt2.Column
                    title='Description'
                    field='attributes.description'
                />
                <dt2.Column
                    title='Progress'
                    field='attributes.progress.percentage'
                    component={ProgressIcon}
                    editable={false}
                />
                <dt2.Column
                    title='Updated'
                    field='attributes.progress.timestamp'
                    type='date'
                    editable={false}
                />
                <dt2.Action
                    name='refresh'
                    free
                    onClick={() => ds.fetch()}
                    tooltip='Refresh'
                    icon='refresh'
                />
                <dt2.DetailPanel
                    tooltip='Progress log'
                    render={row => (
                        <TherapyDetail
                            canWritePhi={canWritePhi}
                            therapy={row.data}
                        />
                    )}
                />
            </dt2.DataTable>
        </>
    );
}
