import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react';

import {usePageState} from './pageState';

const useStyles = makeStyles(theme => ({
    canvasContainer: {
        width: '100%',
    },
    canvas: {
        border: '1px solid black',
    },
}));

export default function SignaturePage({
    page,
    pageNumber,
    isEditing,
    formState,
}) {
    const classes = useStyles();
    const {editor, onReady} = useFabricJSEditor();
    const canvas = editor?.canvas;
    const pageState = usePageState(formState, page, pageNumber, canvas);

    useEffect(() => {
        if (canvas && pageState) {
            canvas.pageState = pageState;
        }
    }, [canvas, pageState]);

    useEffect(() => {
        if (canvas && formState) {
            canvas.formState = formState;
        }
    }, [canvas, formState]);

    const signFormActions = {
        onClick: pageState.handleClick,
        onTouchEnd: pageState.handleClick,
    };

    const designFormActions = {
        onDrop: pageState.handleDrop,
        onKeyDown: pageState.handleKeyDown,
    };

    const actions = isEditing ? designFormActions : signFormActions;
    return (
        <div
            className={classes.canvasContainer}
            tabIndex={pageNumber}
            {...actions}
        >
            <FabricJSCanvas className={classes.canvas} onReady={onReady} />
        </div>
    );
}
