import React from 'react';
import {Switch, Route} from 'react-router-dom';

import {ApiRoute} from '@arborian/narrf';

import routes from 'ccm/routes';

import TestPage from 'ccm/pages/TestPage';

// New CCM stuff
import HomePage from 'ccm/pages/HomePage';
import AboutPage from 'ccm/pages/AboutPage';
import LoginPage from 'ccm/pages/LoginPage';
import UnlockPage from 'ccm/pages/UnlockPage';
import LogoutPage from 'ccm/pages/LogoutPage';
import LogoutRedirectPage from 'ccm/pages/LogoutRedirectPage';
import RegisterPage from 'ccm/pages/RegisterPage';
import CallbackPage from 'ccm/pages/CallbackPage';
import PasswordResetRequestPage from 'ccm/pages/PasswordResetRequestPage';
import PasswordResetPage from 'ccm/pages/PasswordResetPage';
import EmailVerificationPage from 'ccm/pages/EmailVerificationPage';

import ProfilePage from 'ccm/pages/ProfilePage';
import UserInboxPage from 'ccm/pages/UserInboxPage';
import SiteAdminPage from 'ccm/pages/SiteAdminPage';
import PatientsPage from 'ccm/pages/PatientsPage';
import PatientPage from 'ccm/pages/PatientPage';
import PatientVisitsPage from 'ccm/pages/PatientVisitsPage';
import PatientGroupPage from 'ccm/pages/PatientGroupPage';
import FlowsheetPage from 'ccm/pages/FlowsheetPage';
import PatientInfoPage from 'ccm/pages/PatientInfoPage';
import ObservationHistoryPage from 'ccm/pages/ObservationHistoryPage';
import CarePlanPage from 'ccm/pages/CarePlanPage';
import PatientPreferencesPage from 'ccm/pages/PatientPreferencesPage';

// Practice Pages
import PracticesPage from 'ccm/pages/PracticesPage';
import PracticePage from 'ccm/pages/PracticePage';

import FacilitiesPage from 'ccm/pages/FacilitiesPage';
import FacilityPage from 'ccm/pages/FacilityPage';
import FacilitiesCmsPage from 'ccm/pages/FacilitiesCmsPage';

import ProviderSchedulePage from 'ccm/pages/ProviderSchedulePage';

// Communication Tool
import CommunicatePage from 'ccm/pages/CommunicatePage';
import CommunicateAnonPage from 'ccm/pages/CommunicateAnonPage';
import DynaFormPage from 'ccm/pages/DynaFormPage';
import CommunicateFaxPage from 'ccm/pages/CommunicateFaxPage';
import CommunicateSubmitPage from 'ccm/pages/CommunicateSubmitPage';
import CommunicateSuccessPage from 'ccm/pages/CommunicateSuccessPage';
import TicketsDashboardPage from 'ccm/pages/TicketsDashboardPage';
import TicketPage from 'ccm/pages/TicketPage';

// Temporary TCPA Landing Page
import TCPALandingPage from 'ccm/pages/TCPALanding';
import AauthRedirectPage from 'ccm/pages/AauthRedirectPage';

const StaticRoute = path => <Route path={path} />;

export default function Router() {
    return (
        // <BrowserRouter>
        <Switch>
            <ApiRoute
                exact
                authorized
                path={routes.test.path}
                component={TestPage}
            />
            <ApiRoute exact path={routes.home.path} component={HomePage} />
            <ApiRoute exact path={routes.about.path} component={AboutPage} />
            <StaticRoute path='/about.html' />
            <ApiRoute exact path={routes.login.path} component={LoginPage} />
            <ApiRoute exact path={routes.unlock.path} component={UnlockPage} />
            <ApiRoute exact path={routes.logout.path} component={LogoutPage} />
            <ApiRoute
                exact
                path={routes.logoutRedirect.path}
                component={LogoutRedirectPage}
            />
            <ApiRoute
                exact
                path={routes.register.path}
                component={RegisterPage}
            />
            <ApiRoute
                exact
                path={routes.callback.path}
                component={CallbackPage}
            />
            <ApiRoute
                exact
                path={routes.pwreset_request.path}
                component={PasswordResetRequestPage}
            />
            <ApiRoute
                exact
                path={routes.pwreset.path}
                component={PasswordResetPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.emailVerification.path}
                component={EmailVerificationPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.profile.path}
                component={ProfilePage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.inbox.path}
                component={UserInboxPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.siteAdmin.path}
                component={SiteAdminPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.facilities.path}
                component={FacilitiesPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.facility.path}
                component={FacilityPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.facilitiesCms.path}
                component={FacilitiesCmsPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.providerSchedule.path}
                component={ProviderSchedulePage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.practices.path}
                component={PracticesPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.practice.path}
                component={PracticePage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.patients.path}
                component={PatientsPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.patient.path}
                component={PatientPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.patientGroup.path}
                component={PatientGroupPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.flowsheet.path}
                component={FlowsheetPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.patientVisits.path}
                component={PatientVisitsPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.observationHistory.path}
                component={ObservationHistoryPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.patientInfo.path}
                component={PatientInfoPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.carePlan.path}
                component={CarePlanPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.patientPreferences.path}
                component={PatientPreferencesPage}
            />
            {/* Communication Tool */}
            <ApiRoute
                authorized
                exact
                path={routes.communicate.path}
                component={CommunicatePage}
            />
            <ApiRoute
                exact
                path={routes.communicateAnon.path}
                component={CommunicateAnonPage}
            />
            <ApiRoute
                exact
                path={routes.dynaform.path}
                component={DynaFormPage}
            />
            <ApiRoute
                exact
                path={routes.formFax.path}
                component={CommunicateFaxPage}
            />
            <ApiRoute
                exact
                path={routes.formSubmit.path}
                component={CommunicateSubmitPage}
            />
            <ApiRoute
                exact
                path={routes.formSuccess.path}
                component={CommunicateSuccessPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.dashboard.path}
                component={TicketsDashboardPage}
            />
            <ApiRoute
                exact
                authorized
                path={routes.ticket.path}
                component={TicketPage}
            />
            {/* Temporary TCPA Landing Page */}
            <ApiRoute
                exact
                path={routes.tcpaLanding.path}
                component={TCPALandingPage}
            />
            <ApiRoute
                exact
                path={routes.aauthRedirect.path}
                component={AauthRedirectPage}
            />
        </Switch>
        // </BrowserRouter>
    );
}
